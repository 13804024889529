import { Component, Input } from '@angular/core';
import { Model } from '../../../services/model';
import { getFilteredShortcuts } from '../../../common/settingsUtils';

@Component({
  selector: 'kbd-shrtcts',
  templateUrl: 'kbd-shortcuts.pug',
  styleUrls: ['kbd-shortcuts.scss'],
})
export class KbdShortcuts {
  shortcuts: string[] = [];
  constructor(private model: Model) {
  }
  @Input()
  set id(value: string) {
    this.shortcuts = getFilteredShortcuts(this.model, value) || [];
  }
}
