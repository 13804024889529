import { Component } from '@angular/core';
import { storyprotocolIcon } from '../../../common/icons';

@Component({
  selector: 'story-protocol-registered',
  styleUrls: ['story-protocol-registered.scss'],
  templateUrl: 'story-protocol-registered.pug',
})
export class StoryProtocolRegistered {
  readonly storyprotocolIcon = storyprotocolIcon;
}
