import { Injectable, Injector, OnDestroy } from '@angular/core';
import { SplashScreenId } from '../common/interfaces';
import { Constructor } from '../common/typescript-utils';

// - input data is stored in service to provide single source of truth (bc splash screen can only be 1 at once)
// - handle all closing mechanics (confirms/cancels) in regular angular destructor
export interface ParticularSplashScreen extends OnDestroy {
  canOpen(injector: Injector): boolean;
}

@Injectable()
export class SplashScreenService {
  splashScreenOutlet: any; // component declared in portal, can't declare type here
  openSplashScreen(_id: SplashScreenId, _data: any) { }
  closeSplashScreen() { }
  get currentComponent(): Constructor<ParticularSplashScreen> | undefined {
    return undefined;
  }
  get inputData() {
    return { };
  }
}
