import { storageSetBoolean } from 'magma/services/storage';

export function routeToProject(teamSlug: string, projectId: string | undefined) {
  if (typeof window !== 'undefined') {
    const upgradeStorage = window.location.href.includes('upgrade-storage');
    if (upgradeStorage) {
      storageSetBoolean('open-upgrade-storage', true);
    }
  }
  return projectId ? ['/s', teamSlug, projectId] : ['/s', teamSlug];
}

export function pathToProject(teamSlug: string, projectId: string) {
  return routeToProject(teamSlug, projectId).join('/');
}
