import {
  BitmapData, BlurShader, CompositeOp, CopyMode, Cursor, CursorsMode, CursorType, defaultDrawingPermissions, DrawDrawingOptions, Drawing, DrawingDataFlags,
  DrawOptions, ExtraLoader, HistoryBufferEntry, IErrorReporter, IFiltersValues, IRenderer, Layer, LAYER_MODES, Mask, Mat2d, Mat4, PerspectiveGridLayer, PerspectiveGridLayerData, Point, Poly, Rect,
  RendererApi, RendererParams, RendererSettings, Shader, Texture, TextureFormat, ToolId, ToolSurface, TriangleBatch, User, Vec2, Viewport, WebGLResources
} from '../common/interfaces';
import { applyTransform, createCanvas, cropImage, cropImageData, defaultImageLoaders, getContext2d, getFallbackCursorsImage, loadPNGCancellable, loadPNGSupported, loadToolFonts, textWidth } from '../common/canvasUtils';
import { findByLocalId, findIndexById, getPixelRatio, removeAtFast } from '../common/utils';
import { findById, invalidEnum, quadraticEasing, removeItem } from '../common/baseUtils';
import { clamp, distance, round5, deg2rad } from '../common/mathUtils';
import { drawCropLabel, fixLayerRect, smoothScalingWebgl, truncateName } from './renderer';
import { isLayerVisible, isPerspectiveGridLayer, isTextLayer, layerChanged, loadLayerImages, redrawLayerThumb, shouldRedrawLayerThumb } from '../common/layer';
import {
  allocBuffer, bindRenderTarget, bindTexture, clearBoundTextureRect, clearTexture, clearTextureRect, copyTextureRect, createEmptyTexture,
  createShaderProgram, createSpriteTexture, createWebGLShader, deleteBuffer, deleteTexture, drawMesh, ensureDrawingTiles, findMultipleOf256, findPowerOf2, getAllLayersRect, getWebGLContext, initCoeffs, initUniforms, iterateTiles, Mesh, resizeTexture, setTextureTileSize, textureToCanvas,
  unbindRenderTarget, unbindTexture, releaseTiles, switchToFallbackRendererIfNeeded, texSubImage2D, texSubImage2DImage
} from './webgl';
import { shaders as shaderSources, vertexShader as vertexSource } from '../common/shaders';
import { colorFromRGBA, colorToFloatArray, colorToFloats, colorToRGBA, parseColor, rgbToGray } from '../common/color';
import { createBatch, createBuffer, flushBatch, pushAntialiasedLine, pushAntialiasedQuad, pushQuad, pushQuad2, pushQuad4, pushQuadTransformed, pushQuadXXYY, pushTransfomedAntialiasedLine, pushTransfomedAntialiasedRect, pushTransformedQuad, pushQuadXXYYTransformed, releaseBatch } from './webglBatch';
import { absoluteDocumentToDocumentRect, absoluteDocumentToDocuemnt, createViewport, createViewportMatrix2d, createViewportMatrix4, documentToScreenPoint, documentToScreenPoints, screenToDocumentRect, screenToDocumentPoint, documentToAbsoluteDocumentRect, documentToAbsoluteDocument } from '../common/viewport';
import {
  createRect, clipRect, isRectEmpty, rectContainsXY, rectsIntersection, copyRect, setRect, cloneRect, addRect,
  intersectRect, resetRect, haveNonEmptyIntersection, rectToString, rectIncludesRect, outsetRect, roundRectToGrid, integerizeRect, rectsEqual, clipSurfaceToLimits
} from '../common/rect';
import { resetSurface, getSurfaceBounds, isSurfaceEmpty, getTransformBounds, getTransformOrigin, rectToBounds, transformBounds, hasZeroTransform } from '../common/toolSurface';
import { LassoSelectionTool } from '../common/tools/lassoSelectionTool';
import { MAX_RECT, CURSOR_AVATAR_LARGE_HEIGHT, CURSOR_VIDEO_HEIGHT, DEFAULT_FONT, LAYER_THUMB_SIZE, MB, SEQUENCE_THUMB_HEIGHT, SEQUENCE_THUMB_WIDTH, SHOW_CURSOR_UNMOVING_TIMEOUT, SHOW_CURSORS, TRANSPARENT, USER_CURSOR_RADIUS, USER_NAME_HEIGHT, USER_NAME_OFFSET, WHITE, WHITE_FLOAT, FALLBACK_CURSORS, FALLBACK_CURSOR_SISE, FALLBACK_CURSORS_OFFSETS, GAUSSIAN_BLUR_MAX, MAX_LAYER_SIZE } from '../common/constants';
import { SelectionTool } from '../common/tools/selectionTool';
import { CircleSelectionTool } from '../common/tools/circleSelectionTool';
import { PERSPECTIVE_GRID_BB_LINE_WIDTH_DEFAULT, PERSPECTIVE_GRID_BB_LINE_WIDTH_HOVER, PERSPECTIVE_GRID_COLOR_ARRAY_NORMAL, PERSPECTIVE_GRID_COLOR_ARRAY_NORMAL_SEMI, PERSPECTIVE_GRID_COLOR_ARRAY_SELECTED, PERSPECTIVE_GRID_HANDLE_LINE_WIDTH_BOLD, PERSPECTIVE_GRID_HANDLE_LINE_WIDTH_LIGHT, PERSPECTIVE_GRID_HANDLE_LINE_WIDTH_MEDIUM, PERSPECTIVE_GRID_HANDLE_RADIUS, PERSPECTIVE_GRID_PLANE_GEOMETRY_STRETCH, PerspectiveGridBoundingBoxState, PerspectiveGridTool, PerspectiveGridVanishingPointState, calculatePerspectiveGridData, perspectiveGridToDocument } from '../common/tools/perspectiveGridTool';
import { pushLineEllipse, pushLineRect, pushPoly, pushPolygon, pushRectOutline } from './webglBatchUtils';
import { createBrushTexture, createWebGLRenderingContext, getShader } from './webglRenderingContext';
import { cloneMask, createMask, cutMaskFromRect, fillMask, isMaskEmpty, isMaskingWholeRect, rectMaskIntersectionBoundsInt, transformAndClipMask, transformMask } from '../common/mask';
import { copyMat2d, createMat2d, getMat2dX, getMat2dY, identityMat2d, invertMat2d, isMat2dIdentity, isMat2dIntegerTranslation, multiplyMat2d, rotateMat2d, scaleMat2d, translateAbsoluteMatToDrawingMat2d, translateMat2d } from '../common/mat2d';
import { createMat4, fromYRotationMat4, identityMat4, lookAtMat4, multiplyMat4, perspectiveMat4, scaleMat4, translateMat4 } from '../common/mat4';
import { logAction } from '../common/actionLog';
import { cloneBounds, createVec2, createVec2FromValues, outsetBounds, transformVec2ByMat2d } from '../common/vec2';
import { getLayerRect, isLayerEmpty, layerHasNonEmptyToolSurface } from '../common/layerUtils';
import { copyPoint, createPoint, rectToPoints, setPoint } from '../common/point';
import { drawDebugAllocatedTextures, drawDebugLayerBounds, drawDebugMarkers } from './webglDebug';
import { hasDrawingRole } from '../common/userRole';
import { get } from '../common/xhr';
import { readOBJ } from '../common/obj';
import { isChromeOS, isiOS, isSafari } from '../common/userAgentUtils';
import { DRAW_TEXTURE_RECT, drawTextareaTextureRect, preprocessTextLayersForDrawing, shouldRenderTextareaBaselineIndicator, shouldRenderTextareaBoundaries, shouldRenderTextareaControlPoints, shouldRenderTextareaCursor, shouldRenderTextareaOverflowIndicator, TextTool, TextToolMode } from '../common/tools/textTool';
import { AutoWidthTextarea, Textarea, TEXTAREA_BOUNDARIES_COLOR, TEXTAREA_HOVERED_BOUNDARIES_WIDTH, TextareaType, TEXTAREA_SELECTION_RECT_COLOR, TEXTAREA_UNHOVERED_BOUNDARIES_WIDTH, getBaselineIndicatorAlignmentSquareSize, TEXTAREA_OVERFLOW_INDICATOR_SQUARE_SIZE, TEXTAREA_OVERFLOW_INDICATOR_PLUS_THICKNESS, TEXTAREA_OVERFLOW_INDICATOR_PLUS_SIZE, TEXTAREA_OVERFLOW_INDICATOR_RED, TEXTAREA_BASELINE_INDICATOR_HOVERED_LINE_THICKNESS, TEXTAREA_BASELINE_INDICATOR_UNHOVERED_LINE_THICKNESS, MIN_TEXTAREA_CURSOR_WIDTH, MAX_TEXTAREA_CURSOR_WIDTH } from '../common/text/textarea';
import { cacheTextareaInLayer, ReadyTextLayer, shouldCacheTextareaInLayer, shouldDrawTextarea } from '../common/text/text-utils';
import { toolIncompatibleWithPerspectiveGridLayer, toolIncompatibleWithTextLayers } from '../common/update';
import { AiTool } from '../common/tools/aiTool';
import { getCurveValues } from '../common/curves';
import { AI_BOUNDING_BOX_COLOR_1_ACTIVE, AI_BOUNDING_BOX_COLOR_2_ACTIVE, AI_BOUNDING_BOX_MASK_ALPHA, AI_SELECTION_COLOR_1_FLOAT, AI_SELECTION_COLOR_2_FLOAT } from '../common/aiInterfaces';
import { DEBUG_DRAW_ALLOCATED_TEXTURES, DEBUG_DRAW_LAYER_RECTS } from '../common/settings';
import { applyGaussianBlur } from '../common/gaussianBlur';
import { clipToDrawingRect } from '../common/drawing';
import { CROP_BACKDROP_COLOR_FLOAT, CROP_LABEL_HEIGTH, CROP_LABEL_ICON_WIDTH, CROP_LABEL_WIDTH, CROP_SELECTION_COLOR_1_FLOAT, CROP_SELECTION_COLOR_2_FLOAT, CROP_SELECTION_COLOR_ERROR_FLOAT, CropOverlay, CropTool, CropToolState } from '../common/tools/cropTool';
import { getSpritesBitmap, SPRITE_SCALE, SpriteIcon, spriteIcons } from './sprite';
import { getMaxLayerHeight, getMaxLayerWidth } from '../common/drawingUtils';
import { benchmark, drawBenchmark } from '../common/benchmark';
import { brushShapesMap } from '../common/shapes';
import { BaseBrushTool } from '../common/tools/baseBrushTool';
import { RandomState, randomFloat, randomSeed } from '../common/random';
import { Editor } from './editor';

// debug switches
const REPORT_SLOW = DEVELOPMENT && false;

// everything breaks if textures are not square on IE11
const IS_IE11 = typeof navigator !== 'undefined' && /Trident\/7/.test(navigator.userAgent);

const MAX_VIEWPORT_RECT = createRect(-400_000, -400_000, 800_000, 800_000);
const MIN_TEXTURE_SIZE = 256;
const USE_FAST_DRAWING = true;
const TEXTURE_POOL_SIZE = SERVER ? 5 : 5;
const cursorColor = colorToFloatArray(0x808080ff);
const transparentColor = colorToFloatArray(TRANSPARENT);
const tempMat4 = createMat4();
const tempMat = createMat2d();
const tempMat2 = createMat2d();
const tempVec = createVec2();
const tempRect = createRect(0, 0, 0, 0);
const tempPt = createPoint(0, 0);
const tempBounds = [createPoint(0, 0), createPoint(0, 0), createPoint(0, 0), createPoint(0, 0)];
const tempBoundsVec: Vec2[] = [createVec2FromValues(0, 0), createVec2FromValues(0, 0), createVec2FromValues(0, 0), createVec2FromValues(0, 0)];
const tempViewport = createViewport(0, 0, 1, 0, false);
const emptySelection = createMask();


let drawingBackgroundString: string | undefined = undefined;
let drawingBackground = colorToFloatArray(TRANSPARENT);
let backgroundString: string | undefined = undefined;
let background = colorToFloatArray(TRANSPARENT);
let lastFast = false;
let lastSeed = 0;
let lastRandomNumber1 = 0;
let lastRandomNumber2 = 0;
let lastAngleJitter = 0;
let loseContext: WEBGL_lose_context | null = null;
let meshes: Mesh[] | undefined;
let meshesRotation = 0;

let noSelfVideoTime = 0;

export function identityViewMatrix(width: number, height: number) {
  tempViewport.width = tempViewport.contentWidth = width;
  tempViewport.height = tempViewport.contentHeight = height;
  return createViewportMatrix4(tempMat4, tempViewport);
}

export function transformMatrixForSize(width: number, height: number) {
  identityMat4(tempMat4);
  translateMat4(tempMat4, tempMat4, -1, -1, 0);
  scaleMat4(tempMat4, tempMat4, 2 / width, 2 / height, 1);
  return tempMat4;
}

// NOTE: make sure this is called AFTER frame buffer is bound
export function frameBufferTransform(webgl: WebGLResources) {
  if (webgl.frameBufferWidth === -1) throw new Error('Frame buffer is not bound');
  return transformMatrixForSize(webgl.frameBufferWidth, webgl.frameBufferHeight);
}

function parseDrawingBackground(color: string | undefined) {
  if (drawingBackgroundString !== color) {
    drawingBackgroundString = color;
    colorToFloats(drawingBackground, parseColor(drawingBackgroundString || ''));
  }
  return drawingBackground;
}

function parseBackground(color: string | undefined) {
  if (backgroundString !== color) {
    backgroundString = color;
    colorToFloats(background, parseColor(backgroundString || ''));
  }
  return background;
}

function getPresentedVideoFrames(videoElement: HTMLVideoElement): number {
  const quality = videoElement.getVideoPlaybackQuality();
  return quality.totalVideoFrames;
}

// when disabled - screen blinking on Chrome OS
// when enabled - performance issues on iOS14 and some other mobile devices
export const preserveDrawingBuffer = isChromeOS;

export class WebGLRenderer implements IRenderer {
  name: RendererApi = 'webgl';
  canvas: HTMLCanvasElement | undefined = undefined;
  private webgl: WebGLResources | undefined = undefined;
  private sharedGL = false;
  constructor(public id: string, private errorReporter: IErrorReporter) { }
  addRedrawRect(_user: User, targetDirtyRect: Rect, _options: DrawOptions) {
    if (!preserveDrawingBuffer) {
      addRect(targetDirtyRect, MAX_RECT);
      return true;
    }
    return false;
  }
  setLevelOfDetail(drawing: Drawing, lod: number) {
    if (!this.webgl) throw new Error(`WebGL not initialized (${this.id})`);
    drawing.lod = lod;
    resetRect(drawing.renderedRect);

    setTextureTileSize(this.webgl, drawing);
    ensureDrawingTiles(this.webgl, drawing);

    if (DEVELOPMENT && !TESTS) {
      const dataSize = drawing.w * drawing.h * 4;
      const column = drawing.tiles.tiles;
      let scaledTilesDataSize = 0;
      for (let x = 0; x < column.length; x++) {
        const row = column[x];
        for (let y = 0; y < row.length; y++) {
          const tile = row[y];
          if (tile) scaledTilesDataSize += tile.textureRect.w / lod * tile.textureRect.h / lod * 4;
        }
      }
      console.log(`setLevelOfDetail ${lod} tile: ${drawing.tileSize}px tileMargin: ${drawing.tileMarginSize}px dataSize: ${(dataSize / MB).toFixed(2)}MB scaledTilesDataSize: ${(scaledTilesDataSize / MB).toFixed()}MB efective use: ${(scaledTilesDataSize * 100 / dataSize).toFixed(2)}%`);
    }
  }
  private getGL(): WebGLResources {
    if (!this.webgl) throw new Error(`WebGL not initialized (${this.id})`);
    return this.webgl;
  }
  // for debug
  loseContext() {
    if (DEVELOPMENT) {
      if (loseContext) {
        loseContext.restoreContext();
        loseContext = null;
      } else if (this.webgl) {
        loseContext = this.webgl.gl.getExtension('WEBGL_lose_context')!;
        loseContext.loseContext();
      }
    }
  }
  // for debug
  canvases() {
    if (DEVELOPMENT && this.webgl) {
      return [
        ...this.webgl.allocatedTextures,
        ...this.webgl.textures.map((texture, i) => ({ texture, id: `free-${i}` })),
      ];
    } else {
      return [];
    }
  }
  stats() {
    if (this.webgl) {
      const { textures, allocatedTextures } = this.webgl;
      let bytes = 0;
      for (const texture of allocatedTextures) bytes += texture.width * texture.height * 4;
      for (const texture of textures) bytes += texture.width * texture.height * 4;
      return `${Math.floor(bytes / MB)} MB (${allocatedTextures.length}+${textures.length}) [${lastFast ? 'fast' : 'slow'}]`;
    } else {
      return '...';
    }
  }
  params(): RendererParams {
    return {
      maxTextureSize: this.webgl?.params.maxTextureSize ?? 0,
      maxTextureUnits: this.webgl?.params.maxTextureUnits ?? 0,
    };
  }
  isWebgl2() {
    return !!this.webgl?.webgl2;
  }
  init(canvas?: HTMLCanvasElement, gg?: WebGLResources) {
    if (canvas) {
      this.release();
      this.canvas = canvas;
      this.webgl = initializeWebGL(canvas, gg);
      this.name = this.webgl.webgl2 ? 'webgl2' : 'webgl';
      this.sharedGL = !!gg;
    }

    // 3D model test
    if (DEVELOPMENT && typeof window !== 'undefined' && false) {
      void get<string>('/tests/fox.obj', 'text').then(obj => {
        const model = readOBJ(obj);
        const gl = this.webgl!.gl;
        meshes = model.parts.map(part => {
          const vertexBuffer = createBuffer(gl, part.vertices);
          const indexBuffer = createBuffer(gl, part.indices, true);
          return { vertexBuffer, indexBuffer, elements: part.indices.length };
        });
      });
    }

    loadToolFonts()
      .then(() => this.webgl && resetRect(this.webgl.cropLabelTextureRect)) // trigger redraw
      .catch(e => DEVELOPMENT && console.error(e));
  }
  release() {
    if (this.webgl) {
      releaseWebGL(this.webgl, this.sharedGL);
      this.webgl = undefined;
    }
  }
  releaseTemp() {
  }
  releaseLayer(layer: Layer | undefined) {
    if (layer) releaseLayer(this.getGL(), layer);
  }
  releaseDrawing(drawing: Drawing) {
    releaseTiles(this.getGL(), drawing.tiles);

    for (const layer of drawing.layers) {
      this.releaseLayer(layer);
    }
  }
  // used in paintbucket tool
  getDrawingImageData(drawing: Drawing, flags: DrawingDataFlags) {
    const webgl = this.getGL();
    const textureWidth = findTextureWidth(webgl, drawing.w);
    const textureHeight = findTextureHeight(webgl, drawing.h);
    const temp = getTexture(webgl, textureWidth, textureHeight, 'temp-drawing-data');

    try {
      if (flags === DrawingDataFlags.NoBackground) {
        drawing = { ...drawing, background: '' };
      }

      this._drawDrawing(webgl, drawing, temp, drawing);
      return getImageData(webgl, temp, createRect(0, 0, drawing.w, drawing.h));
    } finally {
      releaseTexture(webgl, temp);
    }
  }
  // used in paintbucket tool and filter
  getLayerImageData(layer: Layer, rect: Rect) {
    if (isRectEmpty(rect)) throw Error('Empty rect');
    const webgl = this.getGL();
    const imageData = this.createImageData(rect.w, rect.h, undefined);

    const r = rectsIntersection(rect, layer.rect);
    if (layer.texture && !isRectEmpty(r)) {
      const temp = getTexture(webgl, rect.w, rect.h, 'temp-layer-data', true);
      try {
        const dx = Math.max(0, layer.rect.x - rect.x);
        const dy = Math.max(0, layer.rect.y - rect.y);
        copyTextureRect(webgl, layer.texture, temp, r.x - layer.textureX, r.y - layer.textureY, r.w, r.h, dx, dy);
        readPixels(webgl, temp, toUint8(imageData.data), createRect(0, 0, rect.w, rect.h));
      } finally {
        releaseTexture(webgl, temp);
      }
    }

    return imageData;
  }
  // used for exporting to PSD
  getDrawingThumbnail(drawing: Drawing, maxSize: number): HTMLCanvasElement {
    const webgl = this.getGL();
    const { gl } = webgl;
    let width = 0, height = 0;
    let scale = 1;

    if (drawing.w > drawing.h) {
      width = maxSize;
      scale = width / drawing.w;
      height = Math.max(Math.floor(drawing.h * (scale)), 1);
    } else {
      height = maxSize;
      scale = height / drawing.h;
      width = Math.max(Math.floor(drawing.w * (scale)), 1);
    }

    const temp = createEmptyTexture(webgl, width, height);

    this._drawDrawing(webgl, drawing, temp, drawing, drawing, 1 / scale);

    const canvas = textureToCanvas(webgl, temp);
    deleteTexture(gl, temp);
    return canvas;
  }
  // used in worker service (testing in dev)
  _getLayerRawDataTest(layer: Layer) {
    if (!layer.texture || isRectEmpty(layer.rect)) throw new Error('Layer is empty');

    const webgl = this.getGL();
    const { gl } = webgl;
    let { x, y, w, h } = layer.rect;

    // TEMP: we had non-integer values in layer.rect
    w = Math.floor(w);
    h = Math.floor(h);

    const data = new Uint8Array(w * h * 4);
    bindRenderTarget(webgl, layer.texture);
    gl.readPixels(x - layer.textureX, y - layer.textureY, w, h, gl.RGBA, gl.UNSIGNED_BYTE, data);
    unbindRenderTarget(webgl);
    return { width: w, height: h, data, premultiplied: true };
  }
  // used in worker service and psd sync
  getLayerRawData(layer: Layer): BitmapData {
    if (!layer.texture || isRectEmpty(layer.rect)) throw new Error('Layer is empty');

    const webgl = this.getGL();
    const { w, h } = layer.rect;
    const data = new Uint8Array(w * h * 4);

    readPixels(webgl, layer.texture, data, layer.rect, layer.textureX, layer.textureY);
    return { width: w, height: h, data };
  }
  // used in paste / paintbucket
  createImageData(width: number, height: number, data: Uint8ClampedArray | undefined): ImageData {
    return { width, height, data: data ?? allocUint8ClampedArray(width, height), colorSpace: 'srgb' };
  }
  // used in paste
  putImage(user: User, image: HTMLImageElement | HTMLCanvasElement | ImageBitmap, rect: Rect) {
    if (image.width !== rect.w || image.height !== rect.h) throw new Error(`Invalid size for image data (${image.width}x${image.height} rect: ${rectToString(rect)})`);
    const webgl = this.getGL();
    const { gl } = webgl;

    ensureSurface(webgl, user.surface, rect, user.localId);

    if (SERVER) {
      // used in tests
      if (!('__raw' in image)) throw new Error('Not supported on server');
      const raw = (image as any).__raw as BitmapData;
      texSubImage2D(gl, user.surface.texture, rect.x - user.surface.textureX, rect.y - user.surface.textureY, raw.width, raw.height, raw.data);
    } else {
      texSubImage2DImage(gl, user.surface.texture, rect.x - user.surface.textureX, rect.y - user.surface.textureY, image);
    }

    if (user.surface.layer) redrawLayerThumb(user.surface.layer);
  }
  copyLayerToSurface(layer: Layer, surface: ToolSurface, rect: Rect) {
    const webgl = this.getGL();
    if (!layer.texture) throw new Error('Layer Missing Texture');
    ensureSurface(webgl, surface, rect, 0);
    const r = rectsIntersection(rect, layer.rect);
    if (isRectEmpty(r)) return;
    const dx = Math.max(0, layer.rect.x - rect.x);
    const dy = Math.max(0, layer.rect.y - rect.y);
    copyTextureRect(webgl, layer.texture, surface.texture!, r.x - layer.textureX, r.y - layer.textureY, r.w, r.h, dx, dy);
  }
  // used in paste / paintbucket
  putImageData({ surface, localId }: User, image: ImageData, rect: Rect) {
    if (image.width !== rect.w || image.height !== rect.h) throw new Error(`Invalid size for image data (${image.width}x${image.height} rect: ${rectToString(rect)})`);
    const webgl = this.getGL();

    ensureSurface(webgl, surface, rect, localId);
    texSubImage2D(webgl.gl, surface.texture!, rect.x - surface.textureX, rect.y - surface.textureY, image.width, image.height, toUint8(image.data));

    if (surface.layer) redrawLayerThumb(surface.layer);
  }
  // for debug
  textureToCanvas(texture: Texture) {
    return textureToCanvas(this.getGL(), texture);
  }
  loadLayerImages(drawing: Drawing, extraLoader?: ExtraLoader, onProgress?: (progress: number) => void, ignoreErrors = false) {
    if (loadPNGSupported()) {
      return loadLayerImages(
        drawing, [{ name: 'png', load: loadPNGCancellable }],
        (layer, img) => this.initLayerFromBitmap(layer, img, drawing), onProgress, ignoreErrors);
    } else {
      return loadLayerImages(
        drawing, defaultImageLoaders(extraLoader),
        (layer, img) => this.initLayer(layer, img, drawing), onProgress, ignoreErrors);
    }
  }
  releaseUserCanvas({ surface }: User) {
    if (surface.layer && !isSurfaceEmpty(surface)) {
      redrawLayerThumb(surface.layer, true);
    }

    releaseSurface(this.webgl, surface);
  }
  initLayer(layer: Layer, image: HTMLImageElement | ImageBitmap, drawingRect: Rect) {
    const webgl = this.getGL();
    const { gl } = webgl;

    if (!image) return;

    fixLayerRect(layer, image, this.errorReporter);

    try {
      let data: HTMLImageElement | ImageBitmap | HTMLCanvasElement = image;

      if (layer.rect.w > MAX_LAYER_SIZE || layer.rect.h > MAX_LAYER_SIZE) {
        const before = cloneRect(layer.rect);
        clipSurfaceToLimits(layer.rect, drawingRect, MAX_LAYER_SIZE, MAX_LAYER_SIZE);
        this.errorReporter.reportError(`Fixing layer rect (too big layer data)`, undefined, {
          beforeRect: before,
          afterRect: cloneRect(layer.rect),
          image: { type: image.constructor.name, width: image.width, height: image.height }
        });

        data = cropImage(image, (before.x - layer.rect.x), (before.y - layer.rect.y), layer.rect.w, layer.rect.h);
      }

      ensureLayerTexture(webgl, undefined, layer, layer.rect);
      texSubImage2DImage(gl, layer.texture, layer.rect.x - layer.textureX, layer.rect.y - layer.textureY, data);
    } catch (e) {
      // TEMP: testing
      logAction(`initLayer failed (error: ${e.message}, ctor: ${image?.constructor?.name}, src: ${(image as any)?.src})`);
      throw e;
    }
  }
  // this method modifies passed `bitmap`
  initLayerFromBitmap(layer: Layer, image: BitmapData, drawingRect: Rect) {
    const webgl = this.getGL();
    const { gl } = webgl;

    if (!image) return;

    fixLayerRect(layer, image, this.errorReporter);

    if (!image.premultiplied) {
      premultiply(image.data);
      image.premultiplied = true;
    }

    let data = image;
    if (layer.rect.w > MAX_LAYER_SIZE || layer.rect.h > MAX_LAYER_SIZE) {
      const before = cloneRect(layer.rect);
      clipSurfaceToLimits(layer.rect, drawingRect, MAX_LAYER_SIZE, MAX_LAYER_SIZE);
      data = cropImageData(image, (layer.rect.x - before.x), (layer.rect.y - before.y), layer.rect.w, layer.rect.h);
    }

    ensureLayerTexture(webgl, undefined, layer, layer.rect);
    texSubImage2D(gl, layer.texture, layer.rect.x - layer.textureX, layer.rect.y - layer.textureY, image.width, image.height, data.data, false, false);
  }
  // used in history
  createSurface(id: string, width: number, height: number) {
    const webgl = this.getGL();

    let w = Math.max(MIN_TEXTURE_SIZE, findPowerOf2(width));
    let h = Math.max(MIN_TEXTURE_SIZE, findPowerOf2(height));
    if (IS_IE11) w = h = Math.max(w, h);

    return getTexture(webgl, w, h, id);
  }
  // used in history
  releaseSurface(texture: Texture | HTMLCanvasElement | undefined): undefined {
    if (texture && 'handle' in texture) {
      return releaseTexture(this.webgl, texture);
    } else {
      DEVELOPMENT && !TESTS && console.warn('releasing non-texture surface');
      return undefined;
    }
  }
  // used in history
  copyToSnapshot(src: Texture, dst: Texture, sx: number, sy: number, w: number, h: number, dx: number, dy: number) {
    const webgl = this.getGL();

    // if we don't have enough texture just clear rect in dst texture and copy existing part of src texture
    if (sx < 0 || sy < 0 || (sx + w) > src.width || (sy + h) > src.height) {
      clearTextureRect(webgl, dst, dx, dy, w, h);

      if (sx < 0) {
        w += sx;
        dx -= sx;
        sx = 0;
      }
      if (sy < 0) {
        h += sy;
        dy -= sy;
        sy = 0;
      }
      w = Math.min(w, src.width - sx);
      h = Math.min(h, src.height - sy);
    }

    if (w > 0 && h > 0) {
      copyTextureRect(webgl, src, dst, sx, sy, w, h, dx, dy);
    }
  }
  // used in history
  restoreSnapshotToLayer(entry: HistoryBufferEntry | undefined, layer: Layer, layerRect: Rect) {
    const webgl = this.getGL();

    if (isRectEmpty(layerRect)) {
      releaseLayer(webgl, layer);
      return;
    }

    ensureLayerTexture(webgl, undefined, layer, layerRect);

    if (entry) {
      let { sheet, x, y, rect } = entry;

      // rect can exceed layer.texture bounds
      let dx = rect.x - layer.textureX;
      let dy = rect.y - layer.textureY;
      let { w, h } = rect;
      if (dx < 0) {
        w += dx;
        x -= dx;
        dx = 0;
      }
      if (dy < 0) {
        h += dy;
        y -= dy;
        dy = 0;
      }
      w = Math.min(w, layer.texture!.width - dx);
      h = Math.min(h, layer.texture!.height - dy);

      if (w > 0 && h > 0) {
        copyTextureRect(webgl, sheet.surface as Texture, layer.texture!, x, y, w, h, dx, dy);
      }
    }

    this.layerChanged(layer);
  }
  // used in history
  restoreSnapshotToTool({ x, y, rect, sheet }: HistoryBufferEntry, user: User) {
    const webgl = this.getGL();
    ensureSurface(webgl, user.surface, rect, user.localId);
    copyTextureRect(webgl, sheet.surface as Texture, user.surface.texture!, x, y, rect.w, rect.h, rect.x - user.surface.textureX, rect.y - user.surface.textureY);
  }
  // used in copy and save, returns data for full size or selection bounds
  getLayerSnapshot(layer: Layer, selection?: Mask, outBounds?: Rect) {
    const webgl = this.getGL();
    return getLayerSnapshot(webgl, layer, selection, outBounds);
  }
  // used in copy, save
  getDrawingSnapshot(drawing: Drawing, selection?: Mask) {
    const webgl = this.getGL();
    const bounds = selection ? selection.bounds : drawing;

    if (isRectEmpty(bounds)) return undefined;
    clipToDrawingRect(bounds, drawing);

    const { x, y, w, h } = bounds;

    let textureWidth = findTextureWidth(webgl, bounds.w);
    let textureHeight = findTextureHeight(webgl, bounds.h);
    if (IS_IE11) textureWidth = textureHeight = Math.max(textureWidth, textureHeight);

    const canvas = createCanvas(bounds.w, bounds.h);
    const context = getContext2d(canvas);

    const temp = getTexture(webgl, textureWidth, textureHeight, 'temp-drawing-snapshot', false);
    const temp2 = getTexture(webgl, textureWidth, textureHeight, 'temp2-drawing-snapshot', false);

    try {
      this._drawDrawing(webgl, drawing, temp2, bounds);

      let src: Texture;

      if (selection) {
        const mask = createMaskTexture(webgl, selection, x, y, textureWidth, textureHeight);
        // TODO: in-place mask out using correct blend-mode (need to account for background)
        bindRenderTarget(webgl, temp, parseDrawingBackground(drawing.background));
        drawMasked(webgl, temp2, mask, 0, 'mask', 0, 0, w, h, 0, 0, 0, 0);
        unbindRenderTarget(webgl);
        src = temp;
      } else {
        src = temp2;
      }

      const data = context.createImageData(bounds.w, bounds.h);
      readPixels(webgl, src, toUint8(data.data), createRect(0, 0, bounds.w, bounds.h));
      context.putImageData(data, 0, 0);

      if (TESTS) (canvas as any).__raw = { width: canvas.width, height: canvas.height, data: toUint8(data.data) };
    } finally {
      releaseTexture(webgl, temp);
      releaseTexture(webgl, temp2);
    }

    return canvas;
  }
  // used in save (psd)
  getDrawingCanvasForImageData(drawing: Drawing) {
    return this.getDrawingSnapshot(drawing);
  }
  // used in save (psd)
  getLayerCanvasForImageData(layer: Layer) {
    if (isLayerEmpty(layer)) return { rect: createRect(0, 0, 0, 0), canvas: undefined };

    const webgl = this.getGL();
    const rect = cloneRect(getLayerRect(layer));
    return { rect, canvas: createFakeCanvas(webgl, layer, rect) };
  }
  // this will not release surface as regular commitTool
  commitToolOnLayer(user: User, layer: Layer, lockOpacity: boolean) {
    const webgl = this.getGL();
    this.commit(webgl, user, layer, lockOpacity);
  }
  commitTool(user: User, lockOpacity: boolean) {
    if (!user.activeLayer) throw new Error('No active layer');
    if (DEVELOPMENT && user.surface.context) throw new Error('Tool context not released');

    const webgl = this.getGL();
    this.commit(webgl, user, user.activeLayer, lockOpacity);

    releaseSurface(webgl, user.surface);
  }
  commitToolTransform(user: User) {
    const webgl = this.getGL();
    const surface = user.surface;

    if (!surface.layer) throw new Error('Missing surface layer');
    if (DEVELOPMENT && surface.context) throw new Error('Tool context not released');

    if (!isSurfaceEmpty(surface)) {
      this.commit(webgl, user, surface.layer, false);
    }
    transformAndClipMask(user.selection, surface);
    releaseSurface(webgl, surface);
  }
  // Assumes no active tool surfaces
  mergeLayers(drawingBounds: Rect, src: Layer, dst: Layer, clip: boolean) {
    if (layerHasTool(src) || layerHasTool(dst)) throw new Error('Cannot merge layers with active tool');

    const webgl = this.getGL();

    if (
      src.texture && src.opacity !== 0 && !isRectEmpty(src.rect) &&
      !(clip && isRectEmpty(rectsIntersection(dst.rect, src.rect)))
    ) {
      const rect = cloneRect(dst.rect);
      if (!clip) addRect(rect, src.rect);

      const dstTexture = dst.texture;
      const dstTextureX = dst.textureX;
      const dstTextureY = dst.textureY;
      dst.texture = undefined;

      ensureLayerTexture(webgl, drawingBounds, dst, rect);
      bindRenderTarget(webgl, dst.texture!);
      const dirtyRect = createRect(dst.textureX, dst.textureY, dst.texture!.width, dst.texture!.height);
      drawLayer(webgl,
        dstTexture || webgl.emptyTexture, src, false, src.opacity, false, clip,
        dirtyRect,
        dirtyRect,
        dstTextureX, dstTextureY,
        dst.opacity);
      unbindRenderTarget(webgl);
      releaseTexture(webgl, dstTexture);
      this.layerChanged(dst, true);
      dst.opacity = 1;
    }

    releaseLayer(webgl, src);
    this.layerChanged(src, true);
  }
  // used in move, transform tools
  splitLayer(surface: ToolSurface, layer: Layer, selection: Mask) {
    const webgl = this.getGL();

    const rect = cloneRect(layer.rect);
    // TODO: use selection poly intersection here instead
    if (!isMaskEmpty(selection)) intersectRect(rect, selection.bounds);

    if (!layer.texture || isRectEmpty(rect)) return;

    if (isMaskEmpty(selection) || isMaskingWholeRect(layer.rect, selection)) {
      ensureSurface(webgl, surface, layer.rect, 0);
      bindRenderTarget(webgl, surface.texture!);
      drawTexture(webgl, layer.texture, 'basic', layer.rect, layer.textureX, layer.textureY);
      unbindRenderTarget(webgl);
      releaseLayer(webgl, layer);
    } else {
      ensureSurface(webgl, surface, rect, 0);
      // mask is aligned with texture and surface
      const { x, y, w, h } = rect;
      const mask = createMaskTexture(webgl, selection, x, y, w, h);
      bindRenderTarget(webgl, surface.texture!);
      drawMaskedLayerRectTexture(webgl, layer.texture, mask, 0, 'mask', rect, layer.textureX, layer.textureY);
      unbindRenderTarget(webgl);

      maskOutLayerRect(webgl, layer, mask, rect, x, y);
      cutMaskFromRect(layer.rect, selection);
      this.layerChanged(layer);
    }

    surface.texture!.hasMipmaps = false;
  }
  // Assumes no active tool surface
  cutLayer(src: Layer, selection: Mask) {
    const webgl = this.getGL();

    if (!src.texture || isRectEmpty(src.rect)) return;
    if (isMaskEmpty(selection)) return;

    const { x, y, w, h } = selection.bounds;
    const mask = createMaskTexture(webgl, selection, x, y, w, h);
    const maskTextureX = x;
    const maskTextureY = y;
    const maskRect = selection.bounds;
    maskOutLayerRect(webgl, src, mask, maskRect, maskTextureX, maskTextureY);

    cutMaskFromRect(src.rect, selection);
    if (isRectEmpty(src.rect)) releaseLayer(webgl, src);
    this.layerChanged(src);
  }
  // Assumes no active tool surface and empty `dst` layer
  copyLayer(src: Layer, dst: Layer, selection: Mask | undefined, copyMode: CopyMode, drawingRect: Rect) {
    const webgl = this.getGL();

    if (layerHasTool(src) || layerHasTool(dst)) throw new Error('Cannot copy layers with active tool');
    if (dst.texture || !isRectEmpty(dst.rect)) throw new Error('Destination layer is not empty');
    if (!src.texture || isRectEmpty(src.rect)) return;

    if (selection) {
      const rect = rectMaskIntersectionBoundsInt(src.rect, selection);

      if (isRectEmpty(rect)) return;

      if (copyMode === CopyMode.Copy || copyMode === CopyMode.Cut) {
        ensureLayerTexture(webgl, drawingRect, dst, rect);
        const maskTextureX = dst.textureX;
        const maskTextureY = dst.textureY;
        const maskRect = selection.bounds;
        const mask = createMaskTexture(webgl, selection, dst.textureX, dst.textureY, dst.texture!.width, dst.texture!.height);
        bindRenderTarget(webgl, dst.texture!);
        drawMaskedLayerRectTexture(webgl,
          src.texture, mask, 0, 'mask', selection.bounds,
          src.textureX + (selection.bounds.x - dst.textureX), src.textureY + (selection.bounds.y - dst.textureY),
        );
        unbindRenderTarget(webgl);

        if (copyMode === CopyMode.Cut) {
          maskOutLayerRect(webgl, src, mask, maskRect, maskTextureX, maskTextureY);
          cutMaskFromRect(src.rect, selection);
          if (isRectEmpty(src.rect)) releaseLayer(webgl, src);
          this.layerChanged(src, true);
        }
      } else {
        invalidEnum(copyMode);
      }

      if (isRectEmpty(dst.rect)) releaseLayer(webgl, dst);
      this.layerChanged(dst);
    } else {
      if (copyMode === CopyMode.Copy) { // copy entire layer
        ensureLayerTexture(webgl, drawingRect, dst, src.rect);
        copyTextureRect(webgl, src.texture, dst.texture!,
          src.rect.x - src.textureX, src.rect.y - src.textureY, src.rect.w, src.rect.h,
          dst.rect.x - dst.textureX, dst.rect.y - dst.textureY);
        this.layerChanged(dst);
      } else {
        throw new Error('Invalid copyMode');
      }
    }
  }
  private showContentOutside(options: DrawDrawingOptions | undefined) {
    return options?.selectedTool?.id === ToolId.Crop;
  }
  private updateVisibleDrawingRect(drawing: Drawing, options: DrawDrawingOptions) {
    const { visibleRect, tileSize, lod } = drawing;
    const showContentOutside = this.showContentOutside(options);
    setRect(visibleRect, 0, 0, options.view.width, options.view.height); // visible area
    screenToDocumentRect(visibleRect, options.view);
    integerizeRect(visibleRect);
    documentToAbsoluteDocumentRect(visibleRect, drawing);
    if (showContentOutside) {
      getAllLayersRect(tempRect, drawing, drawing.layers);
      intersectRect(visibleRect, tempRect);
      roundRectToGrid(visibleRect, tileSize * lod); // do not redraw on 1px move, round to visible drawing tiles
      intersectRect(visibleRect, tempRect);
    } else {
      intersectRect(visibleRect, drawing);
      roundRectToGrid(visibleRect, tileSize * lod); // do not redraw on 1px move, round to visible drawing tiles
      intersectRect(visibleRect, drawing);
    }
  }
  drawDrawing(drawing: Drawing, dirtyRect: Rect | undefined, options: DrawDrawingOptions) {
    if (!this.webgl) return;

    const { renderedRect, visibleRect } = drawing;
    this.updateVisibleDrawingRect(drawing, options);
    if (!rectsEqual(renderedRect, visibleRect)) {
      ensureDrawingTiles(this.webgl, drawing, visibleRect);
    }
    this.drawVisibleDrawing(this.webgl, drawing, visibleRect, dirtyRect ?? visibleRect, this.showContentOutside(options));
  }
  draw(drawing: Drawing, user: User, view: Viewport, dirtyRect: Rect, options: DrawOptions) {
    if (!this.webgl) return;

    const showContentOutside = this.showContentOutside(options);
    this.updateVisibleDrawingRect(drawing, options);

    const { renderedRect, visibleRect } = drawing;
    if (!rectsEqual(renderedRect, visibleRect)) {
      ensureDrawingTiles(this.webgl, drawing, visibleRect);
      this.drawVisibleDrawing(this.webgl, drawing, visibleRect, visibleRect, showContentOutside);
    }

    this.drawViewport(this.webgl, drawing, user, view, dirtyRect, options);
  }
  drawLayerThumbs(layers: Layer[], drawingRect: Rect) {
    if (this.webgl) {
      for (const layer of layers) {
        if (drawLayerThumb(this.webgl, layer, drawingRect)) {
          break;
        }
      }
    }
  }
  drawThumb(drawing: Drawing, dirtyRect: Rect) {
    if (!this.webgl) return;

    const { gl, webgl2 } = this.webgl;
    const gl2 = gl as WebGL2RenderingContext;

    if (this.webgl.pendingDrawingThumb) {
      deleteBuffer(gl, this.webgl.pendingDrawingThumb.buffer);
      this.webgl.pendingDrawingThumb = undefined;
    }

    const scale = Math.min(SEQUENCE_THUMB_WIDTH / drawing.w, SEQUENCE_THUMB_HEIGHT / drawing.h);
    const thumbWidth = Math.ceil(drawing.w * scale);
    const thumbHeight = Math.ceil(drawing.h * scale);

    // outset and add 1px border to avoid edge issues
    const x1 = Math.max(0, Math.floor((dirtyRect.x - drawing.x) * scale) - 1);
    const x2 = Math.min(SEQUENCE_THUMB_WIDTH, Math.ceil((dirtyRect.x - drawing.x + dirtyRect.w) * scale) + 1);
    const y1 = Math.max(0, Math.floor((dirtyRect.y - drawing.y) * scale) - 1);
    const y2 = Math.min(SEQUENCE_THUMB_HEIGHT, Math.ceil((dirtyRect.y + - drawing.y + dirtyRect.h) * scale) + 1);
    const thumbRect = createRect(x1, y1, x2 - x1, y2 - y1);

    const texture = getTexture(this.webgl, thumbWidth, thumbHeight, 'thumb', false);
    this._drawDrawing(this.webgl, drawing, texture, drawing, drawing, 1 / scale);

    bindRenderTarget(this.webgl, texture);
    if (webgl2) {
      const buffer = allocBuffer(gl);
      gl2.bindBuffer(gl2.PIXEL_PACK_BUFFER, buffer);
      gl2.bufferData(gl2.PIXEL_PACK_BUFFER, thumbRect.w * thumbRect.h * 4, gl2.STATIC_READ);
      gl2.readPixels(thumbRect.x, thumbRect.y, thumbRect.w, thumbRect.h, gl.RGBA, gl.UNSIGNED_BYTE, 0);
      gl2.bindBuffer(gl2.PIXEL_PACK_BUFFER, null);
      const sync = gl2.fenceSync(gl2.SYNC_GPU_COMMANDS_COMPLETE, 0);

      if (sync) {
        this.webgl.pendingDrawingThumb = { id: drawing.id, sync, buffer, rect: thumbRect };
      } else if (DEVELOPMENT) {
        console.error(`Failed to create gpu sync`);
      }
    } else {
      const data = new Uint8Array(thumbRect.w * thumbRect.h * 4);
      gl.readPixels(0, 0, thumbRect.w, thumbRect.h, gl.RGBA, gl.UNSIGNED_BYTE, data);
      unpremultiply(data);
      drawing.thumbUpdate = { data, rect: thumbRect, width: thumbWidth, height: thumbHeight };
    }
    unbindRenderTarget(this.webgl);

    releaseTexture(this.webgl, texture);
  }
  pingThumb(drawing: Drawing) {
    if (this.webgl) pingThumb(this.webgl, drawing);
  }
  discardThumb() {
    if (this.webgl) discardThumb(this.webgl);
  }
  scaleImage(image: HTMLImageElement | ImageBitmap | ImageData, scaledWidth: number, scaledHeight: number) {
    const canvas = createCanvas(scaledWidth, scaledHeight);
    const context = getContext2d(canvas);
    const webgl = this.getGL();
    const { gl } = webgl;

    let textureWidth = findTextureWidth(webgl, image.width);
    let textureHeight = findTextureHeight(webgl, image.height);

    const texture = getTexture(webgl, textureWidth, textureHeight, 'scaled', true);

    bindTexture(gl, 0, texture);
    gl.texSubImage2D(gl.TEXTURE_2D, 0, 0, 0, gl.RGBA, gl.UNSIGNED_BYTE, image);
    unbindTexture(gl, 0);

    drawScaled(webgl, texture, scaledWidth, scaledHeight, image.width, image.height, context);

    releaseTexture(webgl, texture);

    return canvas;
  }
  getScaledDrawingSnapshot(drawing: Drawing, scaledWidth: number, scaledHeight: number, selection?: Rect) {
    const webgl = this.getGL();
    const bounds = selection ? selection : drawing;

    const scale = Math.max(bounds.w / scaledWidth, bounds.h / scaledHeight);

    const texture = getTexture(webgl, scaledWidth, scaledHeight, 'scaled', false);
    this._drawDrawing(webgl, drawing, texture, bounds, bounds, scale);
    releaseTexture(webgl, texture);

    return textureToCanvas(webgl, texture);
  }
  getScaledLayerSnapshot(drawing: Drawing, layer: Layer, scaledWidth: number, scaledHeight: number, selection?: Rect) {
    const webgl = this.getGL();
    const bounds = selection ? selection : drawing;

    const scale = Math.max(bounds.w / scaledWidth, bounds.h / scaledHeight);

    const texture = getTexture(webgl, scaledWidth, scaledHeight, 'scaled', true);
    bindRenderTarget(webgl, texture, transparentColor);
    drawLayer(webgl, webgl.emptyTexture, layer, layer.opacityLocked, layer.opacity, true, false, bounds, bounds, 0, 0, 1, undefined, scale);
    releaseTexture(webgl, texture);

    return textureToCanvas(webgl, texture);
  }
  getScaledLayerMask(drawing: Drawing, layer: Layer, scaledWidth: number, scaledHeight: number, selection?: Rect): HTMLCanvasElement {
    const webgl = this.getGL();
    const bounds = selection ? selection : drawing;

    const scale = Math.max(bounds.w / scaledWidth, bounds.h / scaledHeight);

    const texture = getTexture(webgl, scaledWidth, scaledHeight, 'scaled', false);
    bindRenderTarget(webgl, texture, colorToFloatArray(WHITE));
    drawLayer(webgl, webgl.whiteTexture, layer, layer.opacityLocked, layer.opacity, true, false, bounds, bounds, 0, 0, 1, getShader(webgl, 'aiMask'), scale);
    releaseTexture(webgl, texture);

    return textureToCanvas(webgl, texture);
  }

  pickColor(drawing: Drawing, layer: Layer | undefined, px: number, py: number, activeLayer: boolean) {
    const webgl = this.getGL();
    const { gl, emptyTexture } = webgl;

    if (!rectContainsXY(drawing, px, py)) return undefined;

    const x = px | 0;
    const y = py | 0;

    let textureWidth = findTextureWidth(webgl, 1);
    let textureHeight = findTextureHeight(webgl, 1);
    if (IS_IE11) textureWidth = textureHeight = Math.max(textureWidth, textureHeight);

    const temp = getTexture(webgl, textureWidth, textureHeight, 'temp-pick-color', false);
    bindRenderTarget(webgl, temp);
    gl.enable(gl.SCISSOR_TEST);
    gl.scissor(0, 0, 1, 1);

    if (!activeLayer && drawing.background) {
      const bg = parseDrawingBackground(drawing.background);
      gl.clearColor(bg[0], bg[1], bg[2], bg[3]);
    } else {
      gl.clearColor(0, 0, 0, 0);
    }

    gl.clear(gl.COLOR_BUFFER_BIT);

    const rect = createRect(px, py, 1, 1);
    if (!activeLayer) {
      this._drawDrawing(webgl, drawing, temp, rect);
    } else if (layer) {
      // TODO: this doesn't account for clipping groups
      drawLayer(webgl, emptyTexture, layer, layer.opacityLocked, layer.opacity, true, false, rect, rect);
    }

    bindRenderTarget(webgl, temp);
    gl.disable(gl.SCISSOR_TEST);

    const data = tempPixelBuffer;
    gl.readPixels(px - x, py - y, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, data);
    unbindRenderTarget(webgl);
    releaseTexture(webgl, temp);

    if (data[3] === 0) return undefined;

    const a = 255 / data[3];
    return colorFromRGBA(data[0] * a, data[1] * a, data[2] * a, data[3]);
  }
  getToolRenderingContext(user: User, drawingBounds: Rect) {
    if (DEVELOPMENT && user.surface.context) throw new Error('Rendering context not released');

    const webgl = this.getGL();

    ensureSurface(webgl, user.surface, drawingBounds, user.localId);

    // TODO: perf: re-use ?
    return user.surface.context = createWebGLRenderingContext(webgl, user.surface);
  }
  // used in worker service
  trimLayer(layer: Layer, rect: Rect, allowExpanding = false) {
    if (isRectEmpty(rect)) {
      this.releaseLayer(layer);
    } else {
      if (!rectIncludesRect(layer.rect, rect) && !allowExpanding) {
        throw new Error(`Trim layer is going to expand layer! (${rectToString(layer.rect)} -> ${rectToString(rect)})`);
      }
      ensureLayerTexture(this.getGL(), undefined, layer, rect);
      this.layerChanged(layer);
    }
  }
  // for testing (this seems to be broken in some cases)
  fillSelection(layer: Layer, drawingRect: Rect) {
    const webgl = this.getGL();
    const { gl, batch } = webgl;

    if (!layer.owner) throw new Error('Missing layer owner');

    const textureWidth = findTextureWidth(webgl, drawingRect.w);
    const textureHeight = findTextureHeight(webgl, drawingRect.h);
    const temp = getTexture(webgl, textureWidth, textureHeight, 'temp-fill-selection');

    if (!hasZeroTransform(layer.owner.surface)) {
      drawUsingCanvas(webgl, temp, 0, 0, textureWidth, textureHeight, 0, (context, x0, y0) => {
        context.save();
        context.fillStyle = 'cornflowerblue';
        context.translate(-x0, -y0);
        applyTransform(context, layer.owner!.surface.transform);
        fillMask(context, layer.owner!.selection);
        context.restore();
      });
    }

    ensureLayerTexture(webgl, drawingRect, layer, drawingRect); // just force full size texture

    gl.enable(gl.BLEND);
    gl.blendEquation(gl.FUNC_ADD);
    gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
    const shader = getShader(webgl, 'basic');
    gl.useProgram(shader.program);
    bindRenderTarget(webgl, layer.texture!);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, frameBufferTransform(webgl));
    bindTexture(gl, 0, temp);
    pushQuad(batch,
      0, 0, drawingRect.w, drawingRect.h, 0, 0,
      drawingRect.w / textureWidth, drawingRect.h / textureHeight, 0, 0,
      1, 1, 1, 1);
    flushBatch(batch);
    unbindTexture(gl, 0);
    gl.disable(gl.BLEND);
    unbindRenderTarget(webgl);
    releaseTexture(webgl, temp);

    this.layerChanged(layer);
  }
  drawTextLayer(layer: ReadyTextLayer, drawing: Drawing) {
    const webgl = this.getGL();

    const textarea = layer.textarea;
    textarea.write(layer.textData.text);

    const rect = cloneRect(textarea.textureRect);
    clipToDrawingRect(rect, drawing);

    this.releaseLayer(layer);
    if (!isRectEmpty(rect)) {
      ensureLayerTexture(webgl, drawing, layer, rect);
      drawUsingCanvas(webgl, layer.texture!, layer.rect.x - layer.textureX, layer.rect.y - layer.textureY, layer.rect.w, layer.rect.h, 0, (context, x0, y0) => {
        context.save();
        context.translate(-x0 - layer.textureX, -y0 - layer.textureY);
        textarea.drawOn(context);
        if (DEVELOPMENT && DRAW_TEXTURE_RECT) drawTextareaTextureRect(context, textarea);
        context.restore();
      });
    }

    layer.invalidateCanvas = false;
    this.layerChanged(layer);
  }
  private _drawDrawing(webgl: WebGLResources, drawing: Drawing, renderTexture: Texture, renderRect: Rect, dirtyRect?: Rect, scale = 1) {
    preprocessTextLayersForDrawing(drawing, (layer) => this.drawTextLayer(layer, drawing));

    if (USE_FAST_DRAWING && canDrawDrawingFast(drawing)) {
      drawDrawingFast(webgl, drawing, renderTexture, renderRect, dirtyRect ?? renderRect, scale);
      lastFast = true;
    } else {
      drawDrawingSlow(webgl, drawing, renderTexture, renderRect, dirtyRect ?? renderRect, scale);
      lastFast = false;
    }
  }

  private drawVisibleDrawing(webgl: WebGLResources, drawing: Drawing, renderRect: Rect, dirtyRect?: Rect, showContentOutside = false) {
    preprocessTextLayersForDrawing(drawing, (layer) => this.drawTextLayer(layer, drawing));

    const { tiles, tileSize, lod } = drawing;
    const dirty = cloneRect(dirtyRect ?? renderRect);

    if (showContentOutside) {
      getAllLayersRect(tempRect, drawing, drawing.layers);
      intersectRect(dirty, tempRect);
    } else {
      intersectRect(dirty, drawing);
    }

    const dirtyTileRect = createRect(0, 0, 0, 0);
    iterateTiles(tiles, renderRect, tileSize * lod, (tile) => {
      // this will be called only if tile is dirty but still intersect to get only dirty tile rect 
      copyRect(dirtyTileRect, tile.textureRect);
      intersectRect(dirtyTileRect, dirty);

      if (isRectEmpty(dirtyTileRect)) return;
      roundRectToGrid(dirtyTileRect, lod); // make sure that we will redraw full pixels on lower LOD

      if (USE_FAST_DRAWING && canDrawDrawingFast(drawing)) {
        drawDrawingFast(webgl, drawing, tile.texture, tile.textureRect, dirtyTileRect, lod);
        lastFast = true;
      } else {
        drawDrawingSlow(webgl, drawing, tile.texture, tile.textureRect, dirtyTileRect, lod);
        lastFast = false;
      }
    });
    copyRect(drawing.renderedRect, renderRect);
  }

  // filters
  applyHueSaturationLightnessFilter(_srcData: ImageData | undefined, surface: ToolSurface, values: IFiltersValues) {
    const { hue = 0, saturation = 0, lightness = 0 } = values;
    const webgl = this.getGL();
    const { gl, batch } = webgl;

    if (!surface.texture) return;

    const vTextureTemp = createEmptyTexture(webgl, surface.texture.width, surface.texture.height);
    bindRenderTarget(webgl, vTextureTemp);

    bindTexture(gl, 0, surface.texture);
    const shader = getShader(webgl, 'hueSaturationLightnessShader');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, transformMatrixForSize(surface.texture.width, surface.texture.height));
    gl.uniform1f(shader.uniforms.hue, hue);
    gl.uniform1f(shader.uniforms.saturation, saturation);
    gl.uniform1f(shader.uniforms.lightness, lightness);
    gl.uniform2f(shader.uniforms.size, surface.texture.width, surface.texture.height);
    pushQuad(batch, 0, 0, surface.texture.width, surface.texture.height, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);
    flushBatch(batch);
    unbindTexture(gl, 0);
    unbindRenderTarget(webgl);
    releaseTexture(webgl, surface.texture);
    surface.texture = vTextureTemp;
  }
  applyBrightnessContrastFilter(_srcData: ImageData | undefined, surface: ToolSurface, values: IFiltersValues) {
    const { brightness, contrast } = values;
    const webgl = this.getGL();
    const { gl, batch } = webgl;

    if (!surface.texture) return;

    const vTextureTemp = createEmptyTexture(webgl, surface.texture.width, surface.texture.height);
    bindRenderTarget(webgl, vTextureTemp);
    bindTexture(gl, 0, surface.texture);
    const shader = getShader(webgl, 'brightnessContrastShader');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, transformMatrixForSize(surface.texture.width, surface.texture.height));
    gl.uniform1f(shader.uniforms.brightness, brightness!);
    gl.uniform1f(shader.uniforms.contrast, contrast!);
    gl.uniform2f(shader.uniforms.size, surface.texture.width, surface.texture.height);
    pushQuad(batch,
      0, 0, surface.texture.width, surface.texture.height,
      0, 0, 0, 0,
      0, 0, 1, 1, 1, 1
    );
    flushBatch(batch);
    unbindTexture(gl, 0);
    unbindRenderTarget(webgl);
    releaseTexture(webgl, surface.texture);
    surface.texture = vTextureTemp;
  }
  applyCurvesFilter(_srcData: ImageData | undefined, surface: ToolSurface, values: IFiltersValues) {
    const { curvePoints = [] } = values;
    const webgl = this.getGL();
    const { gl, batch } = webgl;
    const curveValues = getCurveValues(curvePoints);

    if (!surface.texture) return;

    const vTextureTemp = createEmptyTexture(webgl, surface.texture!.width, surface.texture!.height);
    const outputTexture = createEmptyTexture(webgl, 256, 1, new Uint8Array([
      ...Array.from(curveValues[0]).map((_, index) =>
        [
          curveValues[1][index], // red channel
          curveValues[2][index], // green channel
          curveValues[3][index], // blue channel
          curveValues[0][index]  // RGB
        ]).flat()
    ]));
    bindRenderTarget(webgl, vTextureTemp);

    bindTexture(gl, 0, surface.texture);
    bindTexture(gl, 1, outputTexture);
    const shader = getShader(webgl, 'curvesShader');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, transformMatrixForSize(surface.texture.width, surface.texture.height));
    gl.uniform2f(shader.uniforms.size, surface.texture!.width, surface.texture!.height);
    pushQuad(batch, 0, 0, surface.texture!.width, surface.texture!.height, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);
    flushBatch(batch);
    unbindTexture(gl, 0);
    unbindTexture(gl, 1);
    unbindRenderTarget(webgl);
    releaseTexture(webgl, surface.texture);
    releaseTexture(webgl, outputTexture);
    surface.texture = vTextureTemp;
  }
  applyGaussianBlurFilter(srcData: ImageData | undefined, _rect: Rect, surface: ToolSurface, values: IFiltersValues) {
    const webgl = this.getGL();
    const { gl } = webgl;
    const { radius = 0 } = values;

    if (!surface.texture) return;

    const roundedRadius = Math.round(radius);
    const count = roundedRadius + 1;
    const coefficients = calculateGraussianBlurCoefficients(radius / 2, count);

    let shader = webgl.gaussianBlurShaders.get(coefficients.length) as BlurShader;

    if (!shader) {
      const fragmentSource = createFastGaussianBlurShader(coefficients.length);
      try {
        const fragmentShader = createWebGLShader(gl, gl.FRAGMENT_SHADER, fragmentSource, true);
        shader = createShaderProgram(gl, webgl.vertexShader, fragmentShader, vertexSource) as BlurShader;
      } catch (e) {
        logAction(`Shader creation for Gaussian blur failed (error: ${e.message})`);

        if (!srcData) throw new Error(`[applyGaussianBlurFilter] Missing srcData`);

        let dstData = this.createImageData(srcData.width, srcData.height, undefined);
        applyGaussianBlur(srcData.data, dstData.data, srcData.width, srcData.height, values.radius || 0);

        let rectangle: Rect = { x: surface.rect.x + roundedRadius, y: surface.rect.y + roundedRadius, w: surface.rect.w - roundedRadius * 2, h: surface.rect.h - roundedRadius * 2 } as Rect;
        dstData = cropImageData(dstData, GAUSSIAN_BLUR_MAX, GAUSSIAN_BLUR_MAX, dstData.width - GAUSSIAN_BLUR_MAX * 2, dstData.height - GAUSSIAN_BLUR_MAX * 2);
        this.putImageData({ surface } as any, dstData, rectangle);

        return;
      }

      shader.uniforms = initUniforms(gl, shader.program, { vertex: vertexSource, fragment: fragmentSource });
      shader.coeffs = initCoeffs(gl, shader.program, { vertex: vertexSource, fragment: fragmentSource });
      webgl.gaussianBlurShaders.set(coefficients.length, shader);
    }

    gl.useProgram(shader.program);
    gl.uniform1f(shader.uniforms.radius, radius);
    gl.uniform2f(shader.uniforms.size, surface.texture.width, surface.texture.height);

    for (let i = 0; i < coefficients.length; i++) {
      gl.uniform1f(shader.coeffs[i], coefficients[i]);
    }

    const temp = createEmptyTexture(webgl, surface.texture.width, surface.texture.height);
    runBlurPass(webgl, surface.texture, temp, shader as BlurShader, 1.0, 0.0); // horizontal pass
    runBlurPass(webgl, temp, surface.texture, shader as BlurShader, 0.0, 1.0); // vertical pass
    deleteTexture(gl, temp);
  }

  applyMotionBlurFilter(_srcData: ImageData | undefined, _srcDataRect: Rect, surface: ToolSurface, values: IFiltersValues) {
    const webgl = this.getGL();
    const { gl } = webgl;
    const { distance = 0 } = values;

    if (!surface.texture) return;

    const roundedDistance = Math.round(distance);
    const angle = values.angle!;
    const angleInRadians = deg2rad(angle);
    const dirx = (angle == 90 || angle == 270) ? 0 : (angle == 180) ? 1 : Math.cos(angleInRadians);
    const diry = (angle == 90 || angle == 270) ? 1 : (angle == 180) ? 0 : Math.sin(angleInRadians);

    const shader = getShader(webgl, 'motionBlurShader');

    gl.useProgram(shader.program);
    gl.uniform1f(shader.uniforms.radius, roundedDistance / 2);
    gl.uniform2f(shader.uniforms.size, surface.texture.width, surface.texture.height);

    const vTextureTemp = createEmptyTexture(webgl, surface.texture.width, surface.texture.height);
    runBlurPass(webgl, surface.texture, vTextureTemp, shader as BlurShader, dirx, diry);

    releaseTexture(webgl, surface.texture);
    surface.texture = vTextureTemp;
  }

  // with draw icon centered in ix and iy
  private pushIcon(mat: Mat2d, view: Viewport, ix: number, iy: number, icon: SpriteIcon, ox = 0, oy = 0) {
    const { batch, spritesTexture } = this.getGL();
    if (!spritesTexture) return;
    const pixelRatio = getPixelRatio();
    tempVec[0] = ix;
    tempVec[1] = iy;
    transformVec2ByMat2d(tempVec, tempVec, mat);

    const { x, y, w, h } = spriteIcons[icon];

    const sx = (tempVec[0] + ox - w / (2 * SPRITE_SCALE)) * pixelRatio;
    const sy = (tempVec[1] + oy - h / (2 * SPRITE_SCALE)) * pixelRatio;

    const vw = tempVec[0] * pixelRatio;
    const vh = tempVec[1] * pixelRatio;

    identityMat2d(tempMat2);
    translateMat2d(tempMat2, tempMat2, vw, vh);
    rotateMat2d(tempMat2, tempMat2, -view.rotation);
    scaleMat2d(tempMat2, tempMat2, (view.flipped ? -1 : 1), 1);
    translateMat2d(tempMat2, tempMat2, -vw, -vh);

    pushQuadTransformed(batch,
      tempMat2,
      sx, sy,
      w * pixelRatio / SPRITE_SCALE, h * pixelRatio / SPRITE_SCALE,
      x / spritesTexture.width, y / spritesTexture.height,
      w / spritesTexture.width, h / spritesTexture.height,
      0, 0, 1, 1, 1, 1
    );
  }

  private drawCropLabel(webgl: WebGLResources, bounds: Rect, mat: Mat2d, tool: CropTool) {
    const pixelRatio = getPixelRatio();
    const { gl, batch, cropLabelTextureRect } = webgl;
    tempVec[0] = bounds.x + bounds.w / 2;
    tempVec[1] = bounds.y + bounds.h;
    transformVec2ByMat2d(tempVec, tempVec, mat);

    if (!webgl.cropLabelTexture) webgl.cropLabelTexture = createEmptyTexture(webgl, 128 * pixelRatio, 128 * pixelRatio);
    if (cropLabelTextureRect.w !== bounds.w || cropLabelTextureRect.h !== bounds.h) {
      drawUsingCanvas(webgl, webgl.cropLabelTexture, 0, 0, webgl.cropLabelTexture.width, webgl.cropLabelTexture.height, 0, (context) => {
        drawCropLabel(context, bounds, tool, pixelRatio);
      });
      copyRect(cropLabelTextureRect, bounds);
    }

    const width = tool.state() !== CropToolState.Default ? CROP_LABEL_WIDTH + CROP_LABEL_ICON_WIDTH : CROP_LABEL_WIDTH;

    const vm = identityViewMatrix(gl.drawingBufferWidth / getPixelRatio(), gl.drawingBufferHeight / getPixelRatio());
    const shader = getShader(webgl, 'basic');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, vm);
    bindTexture(gl, 0, webgl.cropLabelTexture);
    pushQuad(batch,
      tempVec[0] - width / 2, tempVec[1] + 8, width, CROP_LABEL_HEIGTH,
      0, 0, width / webgl.cropLabelTexture.width * pixelRatio, CROP_LABEL_HEIGTH / webgl.cropLabelTexture.height * pixelRatio,
      0, 0, 1, 1, 1, 1
    );
    flushBatch(batch);
    unbindTexture(gl, 0);
  }

  drawCropBoundingBox(webgl: WebGLResources, view: Viewport, drawing: Drawing, options: DrawOptions) {
    const tool = options.selectedTool as CropTool;
    if (tool.skipDrawingTool) return;
    const { gl, batch, spritesTexture } = webgl;
    const pixelRatio = getPixelRatio();
    const mat = createViewportMatrix2d(tempMat, view);
    const viewMatrix = identityViewMatrix(gl.drawingBufferWidth / pixelRatio, gl.drawingBufferHeight / pixelRatio);
    const bounds = cloneRect(tool.bounds);
    absoluteDocumentToDocumentRect(bounds, drawing);
    if (tool.maskOpacity > 0) {
      const maskBounds = cloneRect(bounds);
      outsetRect(maskBounds, 1 / options.view.scale);
      const { x, y, w, h } = maskBounds;
      const shader = getShader(webgl, 'vertexColor');
      gl.useProgram(shader.program);
      gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

      const opacity = tool.maskOpacity / 100;

      pushQuadTransformed(batch, mat, x, MAX_VIEWPORT_RECT.y, w, y - MAX_VIEWPORT_RECT.y, 0, 0, 1, 1, 0, 0,
        CROP_BACKDROP_COLOR_FLOAT[0] * opacity, CROP_BACKDROP_COLOR_FLOAT[1] * opacity, CROP_BACKDROP_COLOR_FLOAT[2] * opacity, opacity);
      pushQuadTransformed(batch, mat, x, y + h, w, (MAX_VIEWPORT_RECT.y + MAX_VIEWPORT_RECT.h) - (y + h), 0, 0, 1, 1, 0, 0,
        CROP_BACKDROP_COLOR_FLOAT[0] * opacity, CROP_BACKDROP_COLOR_FLOAT[1] * opacity, CROP_BACKDROP_COLOR_FLOAT[2] * opacity, opacity);
      pushQuadTransformed(batch, mat, MAX_VIEWPORT_RECT.x, MAX_VIEWPORT_RECT.y, x - MAX_VIEWPORT_RECT.x, MAX_VIEWPORT_RECT.h, 0, 0, 1, 1, 0, 0,
        CROP_BACKDROP_COLOR_FLOAT[0] * opacity, CROP_BACKDROP_COLOR_FLOAT[1] * opacity, CROP_BACKDROP_COLOR_FLOAT[2] * opacity, opacity);
      pushQuadTransformed(batch, mat, w + x, MAX_VIEWPORT_RECT.y, (MAX_VIEWPORT_RECT.w + MAX_VIEWPORT_RECT.x) - (w + x), MAX_VIEWPORT_RECT.h, 0, 0, 1, 1, 0, 0,
        CROP_BACKDROP_COLOR_FLOAT[0] * opacity, CROP_BACKDROP_COLOR_FLOAT[1] * opacity, CROP_BACKDROP_COLOR_FLOAT[2] * opacity, opacity);

      flushBatch(batch);
    }

    if (tool.overlay !== CropOverlay.Disabled) {
      drawCropOverlay(webgl, view, bounds, tool.overlay, tool.state() === CropToolState.Error ? CROP_SELECTION_COLOR_ERROR_FLOAT : CROP_SELECTION_COLOR_2_FLOAT);
    }

    drawDrawingBounds(webgl, drawing, view);

    drawSolidFrame(webgl, view, bounds, CROP_SELECTION_COLOR_1_FLOAT, tool.state() === CropToolState.Error ? CROP_SELECTION_COLOR_ERROR_FLOAT : CROP_SELECTION_COLOR_2_FLOAT, 0.5, 1);

    if (spritesTexture) {
      const { x, y, w, h } = bounds;
      const shader = getShader(webgl, 'basic');
      gl.useProgram(shader.program);
      gl.uniformMatrix4fv(shader.uniforms.transform, false, identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight));
      bindTexture(gl, 0, spritesTexture);

      this.pushIcon(mat, view, x, y, SpriteIcon.CropLeftTop, 3, 3);
      this.pushIcon(mat, view, x, y + h, SpriteIcon.CropLeftBottom, 3, -3);
      this.pushIcon(mat, view, x + w, y, SpriteIcon.CropRightTop, -3, 3);
      this.pushIcon(mat, view, x + w, y + h, SpriteIcon.CropRightBottom, -3, -3);

      this.pushIcon(mat, view, x, y + h / 2, SpriteIcon.CropVertical, 0, 0);
      this.pushIcon(mat, view, x + w, y + h / 2, SpriteIcon.CropVertical, 0, 0);

      this.pushIcon(mat, view, x + w / 2, y, SpriteIcon.CropHorizontal, 0, 0);
      this.pushIcon(mat, view, x + w / 2, y + h, SpriteIcon.CropHorizontal, 0, 0);
      flushBatch(batch);
      unbindTexture(gl, 0);
    }
    this.drawCropLabel(webgl, bounds, mat, tool);
  }

  drawViewport(webgl: WebGLResources, drawing: Drawing, user: User, view: Viewport, _dirtyRect: Rect, options: DrawOptions) {
    const { gl, batch, cropLabelTexture, cropLabelTextureRect } = webgl;
    const { tileSize, tiles, lod } = drawing;
    const { cursor, settings, lastPoint, showShiftLine, users } = options;
    const bg = parseBackground(settings.background);
    const width = drawing.w;
    const height = drawing.h;

    if (gl.isContextLost()) {
      DEVELOPMENT && console.warn('Context is lost');
      return;
    }

    const ratio = getPixelRatio();
    const pixelSize = 1 / view.scale;
    const exactView = view.rotation === 0 && ratio === pixelSize;
    const viewMatrix = createViewportMatrix4(tempMat4, view, exactView);

    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    gl.bindTexture(gl.TEXTURE_2D, null);
    gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);

    gl.clearColor(bg[0], bg[1], bg[2], 1.0);
    gl.clear(gl.COLOR_BUFFER_BIT);

    if (!drawing.id) return;

    gl.enable(gl.BLEND);
    gl.blendEquation(gl.FUNC_ADD);
    gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);

    // draw drawing on viewport texture
    const smooth = smoothScalingWebgl(settings, view);
    const shader = getShader(webgl, options.view.filter === 'grayscale' ? 'drawingGrayscale' : 'drawing');
    const smoothPixels = !smooth && webgl.highp;
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
    gl.uniform1f(shader.uniforms.scale, view.scale);
    gl.uniform1f(shader.uniforms.pixelated, smoothPixels ? 1 : 0);
    gl.uniform1f(shader.uniforms.unlimited, this.showContentOutside(options) ? 1 : 0);

    const scaledPixelSize = lod;

    const rect = createRect(0, 0, 0, 0);
    iterateTiles(tiles, drawing.visibleRect, tileSize * lod, (tile) => {
      copyRect(rect, tile.rect);
      intersectRect(rect, drawing);
      bindTexture(gl, 0, tile.texture);
      ensureTextureMipmaps(gl, tile.texture); // do it here instead of in bindTexture because there is additional logic for that bellow

      const tw = tile.texture.width * lod;
      const th = tile.texture.height * lod;

      gl.uniform2f(shader.uniforms.size, tw, th);

      // 0.5 is important here to avoid pixel bleeding on drawing edges
      const minCoordX = drawing.x === tile.rect.x ? ((tile.rect.x - tile.textureRect.x + 0.5 * scaledPixelSize) / tw) : 0;
      const minCoordY = drawing.y === tile.rect.y ? ((tile.rect.y - tile.textureRect.y + 0.5 * scaledPixelSize) / th) : 0;
      const marginx2 = ((tile.textureRect.x + tile.textureRect.w) - (tile.rect.x + tile.rect.w) + 0.5 * scaledPixelSize) / tw;
      const marginy2 = ((tile.textureRect.y + tile.textureRect.h) - (tile.rect.y + tile.rect.h) + 0.5 * scaledPixelSize) / th;

      const maxCoordX = (drawing.x + drawing.w) === (tile.rect.x + tile.rect.w) ? (1 - marginx2) : 1;
      const maxCoordY = (drawing.y + drawing.h) === (tile.rect.y + tile.rect.h) ? (1 - marginy2) : 1;

      gl.uniform2f(shader.uniforms.minCoord, minCoordX, minCoordY);
      gl.uniform2f(shader.uniforms.maxCoord, maxCoordX, maxCoordY);

      // drawing border aliasing and checker
      if (isRectEmpty(rect)) {
        gl.uniform2f(shader.uniforms.minDrawingCoord, 0, 0);
        gl.uniform2f(shader.uniforms.maxDrawingCoord, 0, 0);
      } else {
        gl.uniform2f(shader.uniforms.minDrawingCoord,
          drawing.x === rect.x ? ((rect.x - tile.textureRect.x + pixelSize) / tw) : 0,
          drawing.y === rect.y ? ((rect.y - tile.textureRect.y + pixelSize) / th) : 0);

        const marginDrawingx2 = ((tile.textureRect.x + tw) - (rect.x + rect.w) + pixelSize) / tw;
        const marginDrawingy2 = ((tile.textureRect.y + th) - (rect.y + rect.h) + pixelSize) / th;

        const maxDrawingCoordX = (drawing.x + drawing.w) === (rect.x + rect.w) ? (1 - marginDrawingx2) : 1;
        const maxDrawingCoordY = (drawing.y + drawing.h) === (rect.y + rect.h) ? (1 - marginDrawingy2) : 1;
        gl.uniform2f(shader.uniforms.maxDrawingCoord, maxDrawingCoordX, maxDrawingCoordY);
      }
      gl.uniform2f(shader.uniforms.checkerOffset, tile.textureRect.x / tw, tile.textureRect.y / th);


      if (smooth && !exactView) {
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
      }

      pushQuad(batch,
        tile.rect.x - drawing.x,
        tile.rect.y - drawing.y,
        tile.rect.w, tile.rect.h,
        (tile.rect.x - tile.textureRect.x) / tw, (tile.rect.y - tile.textureRect.y) / th,
        tile.rect.w / tw, tile.rect.h / th,
        0, 0, 1, 1, 1, 1);
      flushBatch(batch);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
      unbindTexture(gl, 0);
    });

    // draw grid
    if (settings.pixelGrid && view.scale > 6) {
      const shader = getShader(webgl, 'grid');
      const alpha = Math.min(0.1 + 0.01 * (view.scale - 6), 0.2);
      gl.useProgram(shader.program);
      gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
      gl.uniform1f(shader.uniforms.pixelSize, pixelSize);
      gl.uniform2f(shader.uniforms.size, width, height);
      gl.uniform1f(shader.uniforms.alpha, alpha);
      pushQuad(batch, 0, 0, drawing.w, drawing.h, 0, 0, 1, 1, 0, 0, 1, 1, 1, 1);
      flushBatch(batch);
    }

    drawActiveTool(webgl, drawing, user, view);

    //draw perspective grid
    if (options.selectedTool?.id == ToolId.PerspectiveGrid && !!user) {
      if (!isPerspectiveGridLayer(user.activeLayer)) {
        const tool = options.selectedTool as PerspectiveGridTool;
        drawPerspectiveGridBoundingBox(webgl, view, drawing, options, tool.data);
      }
    }
    if (settings.showPerspectiveGrid) {
      for (const layer of drawing.layers) {
        if (isPerspectiveGridLayer(layer) && isLayerVisible(layer)) {
          const selected = !!user && user.activeLayer === layer && options.selectedTool?.id == ToolId.PerspectiveGrid && !layer.locked;
          const perspectiveGridLayer = layer as PerspectiveGridLayer;
          const tool = options.selectedTool?.id === ToolId.PerspectiveGrid ? options.selectedTool as PerspectiveGridTool : undefined;
          drawPerspectiveGrid(webgl, view, drawing, settings, perspectiveGridLayer, selected, tool);
        }
      }
    }

    if (user?.selection && options.selectedTool?.id !== ToolId.AI) drawSelection(webgl, drawing, user, user.selection, view);
    if (user?.showTransform) drawTransform(webgl, user, drawing, view);
    if (user && shouldDrawTextarea(user.activeLayer, options)) drawTextarea(webgl, drawing, user.activeLayer.textarea, view, options);
    if (options.selectedTool?.id === ToolId.AI && !!user) {
      const tool = options.selectedTool as AiTool;
      if (tool.showSelection) {
        if (tool.results.size === 0) {
          fillSelection(webgl, drawing, tool.getSelection(), view);
        }
      }
      if (tool.pipeline === 'outpaint' && user.activeLayer && tool.results.size === 0) {
        drawAiOutpaintingMask(webgl, drawing, tool, user.activeLayer, view);
      }
      drawAiBoundingBox(webgl, view, drawing, options);
    }
    if (options.selectedTool && !toolIncompatibleWithPerspectiveGridLayer(options.selectedTool.id) && user && isPerspectiveGridLayer(user.activeLayer) && isLayerVisible(user.activeLayer) && !user.activeLayer.locked) {
      drawPerspectiveGridBoundingBox(webgl, view, drawing, options, user.activeLayer.perspectiveGrid);
    }
    if (options.selectedTool?.id === ToolId.Crop) {
      this.drawCropBoundingBox(webgl, view, drawing, options);
    } else if (cropLabelTexture) {
      releaseTexture(webgl, webgl.cropLabelTexture);
      webgl.cropLabelTexture = undefined;
      resetRect(cropLabelTextureRect);
    }

    if (showShiftLine) {
      copyPoint(tempPt, lastPoint);
      absoluteDocumentToDocuemnt(tempPt, drawing);
      documentToScreenPoint(tempPt, view);
      const shader = getShader(webgl, 'line');
      gl.useProgram(shader.program);
      gl.uniformMatrix4fv(shader.uniforms.transform, false, identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight));
      const x1 = tempPt.x * ratio;
      const y1 = tempPt.y * ratio;
      const x2 = cursor.x * ratio;
      const y2 = cursor.y * ratio;
      const c = 0.3;
      pushAntialiasedLine(batch, x1, y1, x2, y2, 1.5, c, c, c, c);
      pushAntialiasedLine(batch, x1, y1, x2, y2, 1, 0.5 * c, 0.5 * c, 0.5 * c, c);
      flushBatch(batch);
    }

    if (SHOW_CURSORS && users.length && settings.cursors !== CursorsMode.None) {
      drawCursors(webgl, view, drawing, users, settings.cursors ?? 0, !!options.settings.includeVideo);
    }

    if (!isTextLayer(user?.activeLayer) || (options.selectedTool && !toolIncompatibleWithTextLayers(options.selectedTool.id))) {
      drawCursor(webgl, cursor, view, settings, drawing, options, bg);
    }

    if (options.settings.includeVideo && options.drawingInProgress !== true) {
      const alpha = quadraticEasing(noSelfVideoTime, performance.now(), 1000);
      drawSelfVideo(webgl, cursor, user, alpha);
    } else {
      noSelfVideoTime = performance.now();
    }

    if (DEBUG_DRAW_LAYER_RECTS) drawDebugLayerBounds(webgl, drawing, view, user);
    if (DEBUG_DRAW_ALLOCATED_TEXTURES) drawDebugAllocatedTextures(webgl, view, drawing, user);
    if (DEVELOPMENT) drawDebugMarkers(webgl, view);
    if (DEVELOPMENT && !TESTS && benchmark.showGraph) drawBenchmark(webgl);

    if (DEVELOPMENT && meshes) {
      gl.clear(gl.DEPTH_BUFFER_BIT);
      gl.enable(gl.DEPTH_TEST);
      // TODO: need antialiasing here ? or postprocess ?
      const shader = getShader(webgl, 'mesh');
      gl.useProgram(shader.program);

      const dist = 3;
      const viewMat = lookAtMat4(createMat4(), dist, 2, -dist, 0, 0, 0, 0, 1, 0);
      const projMat = perspectiveMat4(createMat4(), Math.PI * 0.5, view.width / view.height, 0.1, 1000);
      multiplyMat4(viewMat, projMat, viewMat);
      const modelMat = fromYRotationMat4(createMat4(), meshesRotation);

      // TODO: depth testing
      gl.uniformMatrix4fv(shader.uniforms.model, false, modelMat);
      gl.uniformMatrix4fv(shader.uniforms.viewProj, false, viewMat);

      for (const mesh of meshes) {
        drawMesh(gl, mesh);
      }

      meshesRotation += 0.01;
      gl.disable(gl.DEPTH_TEST);
    }

    gl.disable(gl.BLEND);
  }

  private layerChanged(layer: Layer, forceRedraw?: boolean) {
    if (!this.webgl) return;
    layerChanged(layer, forceRedraw);
  }

  private commit(webgl: WebGLResources, user: User, layer: Layer, lockOpacity: boolean) {
    const { surface } = user;
    const selection = surface.ignoreSelection ? emptySelection : user.selection;

    if (surface.mode === CompositeOp.None) throw new Error('Invalid surface operation');
    if (!surface.texture) throw new Error('Missing surface texture');
    if (DEVELOPMENT && layer.owner !== user) {
      throw new Error(`Commiting tool to layer with different owner (layer: ${layer.name}, user: ${user.name}, owner: ${layer.owner?.name})`);
    }

    const { gl, batch, emptyTexture } = webgl;
    const bounds = getSurfaceBounds(surface);

    if (surface.mode !== CompositeOp.Move && !isMaskEmpty(selection)) {
      copyRect(bounds, rectMaskIntersectionBoundsInt(bounds, selection));
    }

    if (surface.mode === CompositeOp.Draw && lockOpacity) {
      intersectRect(bounds, layer.rect);
    }

    const canSkip = isRectEmpty(bounds) ||
      (surface.mode === CompositeOp.Erase && isRectEmpty(layer.rect)) ||
      (surface.mode === CompositeOp.Erase && !haveNonEmptyIntersection(bounds, layer.rect));

    if (!canSkip) {
      const canDrawFast = USE_FAST_DRAWING &&
        !surface.textureMask && !surface.canvasMask && // TODO: shader
        isMaskEmpty(selection); // TODO: shader

      const rect = cloneRect(layer.rect);

      if (surface.mode !== CompositeOp.Erase && !(surface.mode === CompositeOp.Draw && lockOpacity)) {
        addRect(rect, bounds);
      }

      if (canDrawFast) {
        ensureLayerTexture(webgl, surface.drawingRect, layer, rect);
        bindRenderTarget(webgl, layer.texture!);

        gl.enable(gl.SCISSOR_TEST);
        gl.scissor(bounds.x - layer.textureX, bounds.y - layer.textureY, bounds.w, bounds.h);
        gl.enable(gl.BLEND);
        gl.blendEquation(gl.FUNC_ADD);

        if (surface.mode === CompositeOp.Draw) {
          if (lockOpacity) {
            gl.blendFuncSeparate(gl.DST_ALPHA, gl.ONE_MINUS_SRC_ALPHA, gl.ZERO, gl.ONE);
          } else {
            gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
          }
        } else if (surface.mode === CompositeOp.Erase) {
          gl.blendFunc(gl.ZERO, gl.ONE_MINUS_SRC_ALPHA);
        } else if (surface.mode === CompositeOp.Move) {
          gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
        } else {
          throw new Error('Not implemented');
        }

        const shader = getShader(webgl, surface.mode === CompositeOp.Move ? 'fastMove' : 'fastNormal');
        gl.useProgram(shader.program);
        gl.uniformMatrix4fv(shader.uniforms.transform, false, transformMatrixForSize(layer.texture!.width, layer.texture!.height));
        gl.uniform4fv(shader.uniforms.color, colorToFloats(surfaceColor, surface.color, surface.opacity));
        bindTexture(gl, 0, surface.texture);

        if (surface.mode === CompositeOp.Move) {
          setToolTransform(webgl, surface, shader);
          ensureSurfaceMipmaps(webgl, surface);
        }

        pushQuadSurface(webgl, surface, bounds, layer.textureX, layer.textureY);

        flushBatch(batch);

        unbindTexture(gl, 0);
        gl.disable(gl.SCISSOR_TEST);
        gl.disable(gl.BLEND);
        unbindRenderTarget(webgl);
      } else {
        REPORT_SLOW && console.warn('slow (commit)');

        const limit = cloneRect(layer.rect);
        addRect(limit, bounds);

        if (limit.w > getMaxLayerWidth(surface.drawingRect.w) || limit.h > getMaxLayerHeight(surface.drawingRect.h)) {
          clipSurfaceToLimits(limit, surface.drawingRect, getMaxLayerWidth(surface.drawingRect.w), getMaxLayerHeight(surface.drawingRect.h));
          intersectRect(bounds, limit);
        }

        if (!isRectEmpty(bounds)) { // bounds might be empty if selection is transformed outside of layer limits
          let textureWidth = findTextureWidth(webgl, bounds.w);
          let textureHeight = findTextureHeight(webgl, bounds.h);
          if (IS_IE11) textureWidth = textureHeight = Math.max(textureWidth, textureHeight);

          const temp = getTexture(webgl, textureWidth, textureHeight, 'temp-commit', false); // TODO fix size

          bindRenderTarget(webgl, temp);
          gl.enable(gl.SCISSOR_TEST);
          gl.scissor(0, 0, bounds.w, bounds.h);
          drawLayer(webgl, emptyTexture, layer, lockOpacity, 1, true, false, bounds, bounds);
          gl.disable(gl.SCISSOR_TEST);
          unbindRenderTarget(webgl);

          ensureLayerTexture(webgl, surface.drawingRect, layer, rect);
          // TODO: there is a bug here when erasing
          copyTextureRect(webgl, temp, layer.texture!, 0, 0, bounds.w, bounds.h, bounds.x - layer.textureX, bounds.y - layer.textureY);
          releaseTexture(webgl, temp);
        }
      }
    }

    if (isRectEmpty(layer.rect)) releaseLayer(webgl, layer);
    this.layerChanged(layer);
  }
}

function runBlurPass(webgl: WebGLResources, srcTexture: Texture, dstTexture: Texture, shader: BlurShader, dirX: number, dirY: number): void {
  const { gl, batch } = webgl;
  if (!srcTexture) return;

  gl.uniform2f(shader.uniforms.direction, dirX, dirY);
  bindRenderTarget(webgl, dstTexture, transparentColor);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, frameBufferTransform(webgl));
  bindTexture(gl, 0, srcTexture);
  pushQuad(batch, 0, 0, dstTexture.width, dstTexture.height, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);
  flushBatch(batch);
  unbindTexture(gl, 0);
  unbindRenderTarget(webgl);
}

function createFastGaussianBlurShader(coeffsNumber: number): string {
  let coeffsDeclarations = '';

  for (let i = 0; i < coeffsNumber; i++) {
    coeffsDeclarations += `uniform float coeff` + i + `;\n`;
  }

  let sumCalculations = '';

  for (let i = 1; i < (coeffsNumber - 1); i += 2) {
    sumCalculations += `w0 = coeff${i};\n`;
    sumCalculations += `w1 = coeff${i + 1};\n`;
    sumCalculations += `w = w0 + w1;\n`;
    sumCalculations += `t = w1 / w;\n`;
    sumCalculations += `offset = direction * (${i}.0 + t);\n`;
    sumCalculations += `
      sum += w * texture2D(sampler1, (gl_FragCoord.xy + vec2(offset.x, offset.y)) / size.xy);
      sum += w * texture2D(sampler1, (gl_FragCoord.xy - vec2(offset.x, offset.y)) / size.xy);
      coeffsSum += 2.0 * w;
    `;
  }

  const fastGaussianBlurShader = `
    uniform sampler2D sampler1;
    uniform float radius;
    uniform vec2 direction;
    uniform vec2 size;

    ${coeffsDeclarations}

    void main() {
      if (radius == 0.0) {
        gl_FragColor = texture2D(sampler1, (gl_FragCoord.xy) / size.xy);
        return;
      }
      vec4 sum = coeff0 * texture2D(sampler1, (gl_FragCoord.xy) / size.xy);
      float coeffsSum = coeff0;
      float w0;
      float w1;
      float w;
      float t;
      vec2 offset;

      ${sumCalculations}

      gl_FragColor = sum / coeffsSum;
    }`;

  return fastGaussianBlurShader;
}

function calculateGraussianBlurCoefficients(sigma: number, count: number): Float32Array {
  let sum = 0;
  let result = new Float32Array(count);

  for (let i = 0; i < count; i++) {
    result[i] = integratedGuaussian(i, sigma);
    sum += result[i];
    if (i > 0) {
      sum += result[i];
    }
  }

  const mul = 1 / sum;

  for (let i = 0; i < count; i++) {
    result[i] = result[i] * mul;
  }

  return result;
}

function integratedGuaussian(x: number, sigma: number) {
  const steps = 16;
  const d = 1 / steps;
  let result = 0;
  let tx = x - 0.5 + d * 0.5;
  const rss = -0.5 / (sigma * sigma);
  const mul = 0.3989422804014327 / sigma;

  for (let i = 0; i < steps; i++, tx += d) {
    result += mul * Math.exp(rss * tx * tx);
  }

  return result / steps;
}

function canDrawLayerFast(layer: Layer): ToolSurface | true | false {
  if (layer.mode !== 'normal') return false;
  if (!layer.owner) return true;
  const surface = layer.owner.surface;
  if (surface.layer !== layer) return true;
  if (isSurfaceEmpty(surface) || !surface.texture) return true;
  if (surface.textureMask || surface.canvasMask) return false; // TODO: add shader
  return surface;
}

function canDrawDrawingFast({ layers }: Drawing) {
  for (let i = 0; i < layers.length; i++) {
    if (layerVisibleWithTextureOrTool(layers[i])) {
      if (!canDrawLayerFast(layers[i])) return false;
      if (layers[i].clippingGroup) return false;
    }
  }

  return true;
}

let normalShader: Shader | undefined = undefined;

function setNormalShader(webgl: WebGLResources, name: string) {
  const { gl } = webgl;
  const shader = getShader(webgl, name);

  if (normalShader !== shader) {
    normalShader = shader;
    gl.useProgram(normalShader.program);
    gl.uniformMatrix4fv(normalShader.uniforms.transform, false, frameBufferTransform(webgl));
    gl.enable(gl.BLEND);
  }

  return normalShader;
}

function clearNormal(gl: WebGLRenderingContext, rect?: Rect) {
  if (normalShader) {
    gl.bindTexture(gl.TEXTURE_2D, null);
    gl.disable(gl.BLEND);
    if (rect) gl.scissor(0, 0, rect.w, rect.h);
    normalShader = undefined;
  }
}

const mat2 = new Float32Array([1, 0, 0, 1]);

function setToolTransform({ gl }: WebGLResources, surface: ToolSurface, shader: Shader) {
  const tx = surface.textureX;
  const ty = surface.textureY;
  const tw = surface.texture?.width ?? 1;
  const th = surface.texture?.height ?? 1;
  const maxX = surface.rect.w + surface.rect.x - surface.textureX; // will not work for negative ?
  const maxY = surface.rect.h + surface.rect.y - surface.textureY; // will not work for negative ?

  invertMat2d(tempMat2, surface.transform);
  identityMat2d(tempMat);
  scaleMat2d(tempMat, tempMat, 1 / tw, 1 / th);
  translateMat2d(tempMat, tempMat, -tx, -ty);
  multiplyMat2d(tempMat, tempMat, tempMat2);
  translateMat2d(tempMat, tempMat, tx, ty);
  scaleMat2d(tempMat, tempMat, tw, th);
  mat2[0] = tempMat[0];
  mat2[1] = tempMat[2];
  mat2[2] = tempMat[1];
  mat2[3] = tempMat[3];
  gl.uniformMatrix2fv(shader.uniforms.toolTransform, false, mat2);
  gl.uniform2f(shader.uniforms.toolMove, getMat2dX(tempMat), getMat2dY(tempMat));
  gl.uniform2f(shader.uniforms.maxWidthHeight, maxX / tw, maxY / th);
  gl.uniform1f(shader.uniforms.bicubic, shouldUseLinearForSurface(surface) ? 0 : 1);
  if (surface.texture) gl.uniform2f(shader.uniforms.toolTextureSize, surface.texture.width, surface.texture.height);
}

function pushQuadLayer(webgl: WebGLResources, renderRect: Rect, layer: Layer, dirtyRect: Rect, scale: number) {
  const { batch } = webgl;
  const x = dirtyRect.x - renderRect.x;
  const y = dirtyRect.y - renderRect.y;
  const w = dirtyRect.w;
  const h = dirtyRect.h;

  const tx = dirtyRect.x;
  const ty = dirtyRect.y;
  const tw = layer.texture ? layer.texture.width : 1;
  const th = layer.texture ? layer.texture.height : 1;
  pushQuad(batch,
    x / scale, y / scale,
    w / scale, h / scale,
    (tx - layer.textureX) / tw, (ty - layer.textureY) / th,
    w / tw, h / th,
    0, 0, 1, 1, 1, 1,
  );
  flushBatch(batch);
}

function pushQuadSurface(webgl: WebGLResources, surface: ToolSurface, dirtyRect: Rect, textureX = 0, textureY = 0, scale = 1) {
  const { batch } = webgl;
  const { x, y, w, h } = dirtyRect;
  const sw = surface.texture ? surface.texture.width : 1;
  const sh = surface.texture ? surface.texture.height : 1;

  pushQuad(batch,
    (x - textureX) / scale, (y - textureY) / scale, w / scale, h / scale,
    (x - surface.textureX) / sw, (y - surface.textureY) / sh, w / sw, h / sh, // surface, mask
    0, 0, 1, 1, 1, 1
  );
  flushBatch(batch);
}

function pushQuadLayerSurface(webgl: WebGLResources, renderRect: Rect, layer: Layer, surface: ToolSurface, dirtyRect: Rect, scale: number) {
  const { batch } = webgl;
  const { w, h } = dirtyRect;

  const x = dirtyRect.x - renderRect.x;
  const y = dirtyRect.y - renderRect.y;

  const tw = layer.texture ? layer.texture.width : 1;
  const th = layer.texture ? layer.texture.height : 1;

  const sw = surface.texture ? surface.texture.width : 1;
  const sh = surface.texture ? surface.texture.height : 1;

  pushQuad2(batch,
    x / scale, y / scale, w / scale, h / scale,
    (dirtyRect.x - surface.textureX) / sw, (dirtyRect.y - surface.textureY) / sh, w / sw, h / sh, // surface, mask
    (dirtyRect.x - layer.textureX) / tw, (dirtyRect.y - layer.textureY) / th, w / tw, h / th, // layer
    1, 1, 1, 1);
  flushBatch(batch);
}

function pushQuadLayerSurfaceSelection(webgl: WebGLResources, renderRect: Rect, dirtyRect: Rect, baseTexture: Texture, layer: Layer | undefined, surface: ToolSurface | undefined, selection: Texture | undefined,
  baseX = 0, baseY = 0, scale = 1, baseTextureScale = 1) {
  const { batch } = webgl;
  let surfaceX = 0, surfaceY = 0, surfaceW = 1, surfaceH = 1;
  let layerX = 0, layerY = 0, layerW = 1, layerH = 1;
  let baseW = baseTexture.width, baseH = baseTexture.height;
  let selectionX = 0, selectionY = 0, selectionW = 1, selectionH = 1;

  if (layer && layer.texture) {
    layerX = layer.textureX;
    layerY = layer.textureY;
    layerW = layer.texture.width;
    layerH = layer.texture.height;
  }

  if (surface && surface.texture) {
    surfaceX = surface.textureX;
    surfaceY = surface.textureY;
    surfaceW = surface.texture.width;
    surfaceH = surface.texture.height;
  }

  // TODO uncomment later after merging RE changes
  if (selection) {
    // selectionX = selection.textureX;
    // selectionY = selection.textureY;
    selectionW = selection.width;
    selectionH = selection.height;
  }

  const x = dirtyRect.x - renderRect.x;
  const y = dirtyRect.y - renderRect.y;

  const tx = dirtyRect.x;
  const ty = dirtyRect.y;
  const w = dirtyRect.w;
  const h = dirtyRect.h;

  pushQuad4(
    batch,
    x / scale, y / scale, w / scale, h / scale,
    (tx - surfaceX) / surfaceW, (ty - surfaceY) / surfaceH, w / (surfaceW), h / (surfaceH), // surface (texcoord.xy)
    (tx - layerX) / layerW, (ty - layerY) / layerH, w / (layerW), h / (layerH), // layer (texcoord.zw)
    (tx - baseX) / (baseW), (ty - baseY) / (baseH), w / (baseW * baseTextureScale), h / (baseH * baseTextureScale), // base (color.xy)
    (tx - selectionX) / selectionW, (ty - selectionY) / selectionH, w / (selectionW), h / (selectionH), // selection (color.zw)
  );
  flushBatch(batch);
}

function drawLayerFast(webgl: WebGLResources, renderRect: Rect, layer: Layer, dirtyRect: Rect, scale: number) {
  const { gl, batch } = webgl;
  const surface = canDrawLayerFast(layer);
  if (!surface) return false;
  let mask: Texture | undefined = undefined;

  if (surface !== true) {
    const selection = layer.owner!.selection;
    if (!isMaskEmpty(selection) && !surface.ignoreSelection) {
      // TODO: use small texture ?
      //       surface.rect * selection.bounds for brush ?
      //       texture.rect * selection.bounds for eraser ?
      //       ... locked opacity ?
      mask = createMaskTexture(webgl, selection, surface.textureX, surface.textureY, surface.texture!.width, surface.texture!.height);
    }
  }

  const useLinearFiltering = scale !== 1;

  if (surface !== true && surface.texture) {
    const isEraser = surface.mode === CompositeOp.Erase;
    const isBrushWithOpacity = surface.mode === CompositeOp.Draw && layer.texture && (layer.opacity !== 1 || layer.opacityLocked);

    if (isEraser || isBrushWithOpacity) {
      copyRect(tempRect, layer.rect);
      // can't use intersection for eraser here because we still need to redraw rest of the layer
      // in case we're redrawing region larger than just surface
      if (!isEraser) addRect(tempRect, surface.rect);
      intersectRect(tempRect, dirtyRect);
      roundRectToGrid(tempRect, scale);

      if ((layer.texture || isBrushWithOpacity) && !isRectEmpty(tempRect)) {
        let shaderName: string;

        if (isEraser) {
          shaderName = mask ? 'fastEraserWithMask' : 'fastEraser';
        } else {
          shaderName = layer.opacityLocked ?
            (mask ? 'fastBrushOpacityLockedWithMask' : 'fastBrushOpacityLocked') :
            (mask ? 'fastBrushWithMask' : 'fastBrush');
        }

        const shader = setNormalShader(webgl, shaderName);

        gl.uniformMatrix4fv(shader.uniforms.transform, false, frameBufferTransform(webgl));
        gl.scissor(
          Math.floor((tempRect.x - renderRect.x) / scale),
          Math.floor((tempRect.y - renderRect.y) / scale),
          Math.ceil(tempRect.w / scale),
          Math.ceil(tempRect.h / scale)
        );
        gl.uniform1f(shader.uniforms.opacity, layer.opacity);
        gl.uniform1f(shader.uniforms.baseOpacity, 1);

        if (isEraser) {
          gl.uniform1f(shader.uniforms.toolOpacity, surface.opacity);
        } else {
          gl.uniform4fv(shader.uniforms.toolColor, colorToFloats(surfaceColor, surface.color, surface.opacity));
        }

        bindTexture(gl, 0, layer.texture!, useLinearFiltering);
        bindTexture(gl, 1, surface.texture!, useLinearFiltering);
        if (mask) bindTexture(gl, 2, mask, useLinearFiltering);

        pushQuadLayerSurface(webgl, renderRect, layer, surface, dirtyRect, scale);

        mask && unbindTexture(gl, 2, useLinearFiltering);
        unbindTexture(gl, 1, useLinearFiltering);
        unbindTexture(gl, 0, useLinearFiltering);
      }

      return true;
    }
  }

  const shader = setNormalShader(webgl, 'fastNormal');
  copyRect(tempRect, dirtyRect);
  intersectRect(tempRect, layer.rect);
  roundRectToGrid(tempRect, scale);

  if (layer.texture) {
    gl.scissor(
      Math.floor((tempRect.x - renderRect.x) / scale),
      Math.floor((tempRect.y - renderRect.y) / scale),
      Math.ceil(tempRect.w / scale),
      Math.ceil(tempRect.h / scale)
    );

    gl.uniform4f(shader.uniforms.color, layer.opacity, layer.opacity, layer.opacity, layer.opacity);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, frameBufferTransform(webgl));

    bindTexture(gl, 0, layer.texture, useLinearFiltering);
    pushQuadLayer(webgl, renderRect, layer, tempRect, scale);
    unbindTexture(gl, 0, useLinearFiltering);
  }

  // skip for Eraser
  if (surface !== true && surface.texture && (surface.mode === CompositeOp.Draw || surface.mode === CompositeOp.Move)) {
    const isMoveWithTransform = surface.mode === CompositeOp.Move && !isMat2dIdentity(surface.transform);
    const isDrawWithLockedOpacity = surface.mode === CompositeOp.Draw && layer.opacityLocked; // no layer.texture

    if (!isDrawWithLockedOpacity) { // ignore if locked opacity with empty layer
      copyRect(tempRect, dirtyRect);
      intersectRect(tempRect, isMoveWithTransform ? getSurfaceBounds(surface) : surface.rect);
      roundRectToGrid(tempRect, scale);

      if (!isRectEmpty(tempRect)) {
        const shader2 = isMoveWithTransform ?
          (setNormalShader(webgl, mask ? 'fastMoveWithMask' : 'fastMove')) :
          (mask ? setNormalShader(webgl, 'fastNormalWithMask') : shader);

        gl.uniformMatrix4fv(shader2.uniforms.transform, false, frameBufferTransform(webgl));
        gl.uniform4fv(shader2.uniforms.color, colorToFloats(surfaceColor, surface.color, surface.opacity * layer.opacity));

        gl.scissor((tempRect.x - renderRect.x) / scale, (tempRect.y - renderRect.y) / scale, tempRect.w / scale, tempRect.h / scale);
        bindTexture(gl, 0, surface.texture, useLinearFiltering);
        if (isMoveWithTransform) setToolTransform(webgl, surface, shader2);

        if (mask) bindTexture(gl, 1, mask, useLinearFiltering);

        pushQuadSurface(webgl, surface, tempRect, renderRect.x, renderRect.y, scale);

        flushBatch(batch);

        if (mask) unbindTexture(gl, 1, useLinearFiltering);
        unbindTexture(gl, 0, useLinearFiltering);
      }
    }
  }
  return true;
}

function drawDrawingFast(webgl: WebGLResources, drawing: Drawing, renderTexture: Texture, renderRect: Rect, dirtyRect: Rect, scale: number) {
  const { gl } = webgl;
  const bg = parseDrawingBackground(drawing.background);
  try {
    bindRenderTarget(webgl, renderTexture);
    gl.enable(gl.SCISSOR_TEST);

    // clear dirty rect - can be also outside drawing
    clearBoundTextureRect(gl,
      Math.floor((dirtyRect.x - renderRect.x) / scale),
      Math.floor((dirtyRect.y - renderRect.y) / scale),
      Math.ceil(dirtyRect.w / scale),
      Math.ceil(dirtyRect.h / scale),
      transparentColor
    );

    const r = cloneRect(dirtyRect);
    intersectRect(r, drawing);

    // draw drawing background rect
    if (!isRectEmpty(r)) {
      clearBoundTextureRect(gl,
        Math.floor((r.x - renderRect.x) / scale),
        Math.floor((r.y - renderRect.y) / scale),
        Math.ceil(r.w / scale),
        Math.ceil(r.h / scale),
        bg
      );
    }

    // restore dirty rect scrissor
    gl.scissor(
      Math.floor((dirtyRect.x - renderRect.x) / scale),
      Math.floor((dirtyRect.y - renderRect.y) / scale),
      Math.ceil(dirtyRect.w / scale),
      Math.ceil(dirtyRect.h / scale)
    );
    gl.blendEquation(gl.FUNC_ADD);
    gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);

    normalShader = undefined;

    for (let i = drawing.layers.length - 1; i >= 0; i--) {
      if (layerVisibleWithTextureOrTool(drawing.layers[i])) {
        drawLayerFast(webgl, renderRect, drawing.layers[i], dirtyRect, scale);
      }
    }
  } finally {
    gl.disable(gl.SCISSOR_TEST);
    clearNormal(gl);
    unbindRenderTarget(webgl);
  }
}

function layerVisibleWithTextureOrTool(layer: Layer) {
  return isLayerVisible(layer) && ((!!layer.texture || layerHasToolTexture(layer)) || (!!isTextLayer(layer) && layer.textData.text !== ''));
}

function isClippingLayerWithVisibleClippedLayers(index: number, { layers }: Drawing) {
  if (layers[index].clippingGroup) return false; // this is not clipping base

  // check if there is at least one visible clipped layer
  while (index > 0 && layers[index - 1].clippingGroup) {
    if (layerVisibleWithTextureOrTool(layers[index - 1])) return true;
    index--;
  }

  return false;
}

export function drawDrawingSlow(webgl: WebGLResources, drawing: Drawing, target: Texture, renderRect: Rect, dirtyRect: Rect, scale: number) {
  const { gl, emptyTexture, batch } = webgl;
  REPORT_SLOW && console.warn('slow (drawing)');

  const layers = drawing.layers;
  const { w, h } = dirtyRect;
  let tempWidth = findTextureWidth(webgl, w / scale);
  let tempHeight = findTextureHeight(webgl, h / scale);
  if (IS_IE11) tempWidth = tempHeight = Math.max(tempWidth, tempHeight);

  let tex = getTexture(webgl, tempWidth, tempHeight, 'tex', false);
  let texLast = getTexture(webgl, tempWidth, tempHeight, 'tex-last', false);
  let texClip: Texture | undefined = undefined;

  try {
    // TODO: move clear after scissor ?
    bindRenderTarget(webgl, texLast, transparentColor);
    gl.enable(gl.SCISSOR_TEST);

    const r = cloneRect(drawing);
    intersectRect(r, dirtyRect);

    clearBoundTextureRect(gl, (r.x - dirtyRect.x) / scale, (r.y - dirtyRect.y) / scale, r.w / scale, r.h / scale, parseDrawingBackground(drawing.background));

    gl.scissor(0, 0, w / scale, h / scale);
    gl.blendEquation(gl.FUNC_ADD);
    gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);

    normalShader = undefined;

    for (let i = layers.length - 1; i >= 0; i--) {
      if (isClippingLayerWithVisibleClippedLayers(i, drawing)) {
        const clippingLayer = layers[i];

        if (!layerVisibleWithTextureOrTool(clippingLayer)) {
          while (i > 0 && layers[i - 1].clippingGroup) i--; // skip all clipped layers
          continue;
        }

        clearNormal(gl, dirtyRect);
        texClip = texClip || getTexture(webgl, tempWidth, tempHeight, 'tex-clip', false);
        [texClip, texLast] = [texLast, texClip];

        // TODO: fast track (always?)
        // cannot scissor clippingLayer since it can clip stuff outside it's rect
        bindRenderTarget(webgl, tex, transparentColor);
        drawLayer(webgl, emptyTexture, clippingLayer, clippingLayer.opacityLocked, 1, true, false, dirtyRect, dirtyRect, dirtyRect.x, dirtyRect.y, 1, undefined, scale);
        [tex, texLast] = [texLast, tex];

        for (; i > 0 && layers[i - 1].clippingGroup; i--) {
          if (!layerVisibleWithTextureOrTool(layers[i - 1])) continue;

          const layer = layers[i - 1];

          // TODO: need clipped version for fast layer draw
          // if (!USE_FAST_DRAWING || !drawLayerFast(webgl, layer, rect)) { // TODO: use clipped rect
          REPORT_SLOW && console.warn('slow (clipping)');
          // clearNormal(gl, rect); // TODO: use clipped rect
          bindRenderTarget(webgl, tex, transparentColor);
          drawLayer(webgl, texLast, layer, layer.opacityLocked, layer.opacity, false, true, dirtyRect, dirtyRect, dirtyRect.x, dirtyRect.y, 1, undefined, scale, scale);
          [tex, texLast] = [texLast, tex];
        }

        // TODO: fast track for normal mode
        REPORT_SLOW && console.warn('slow (clipping merge)');
        bindRenderTarget(webgl, tex);
        const shader = getShaderForMode(webgl, clippingLayer.mode, CompositeOp.None, false);
        gl.useProgram(shader.program);
        gl.uniformMatrix4fv(shader.uniforms.transform, false, transformMatrixForSize(w, h));
        gl.uniform1f(shader.uniforms.opacity, clippingLayer.opacity);
        gl.uniform1f(shader.uniforms.baseOpacity, 1);
        gl.uniform1f(shader.uniforms.isClipped, 0);
        bindTexture(gl, 0, texLast);
        bindTexture(gl, 1, texClip);

        // need to use 4 texcoords because in small textures mode shaders expect 4 texcoords
        pushQuad4(
          batch,
          0, 0, w, h,
          0, 0, 1, 1, // ---
          0, 0, 1, 1, // texLast
          0, 0, 1, 1, // texClip
          0, 0, 1, 1 // ---
        );
        flushBatch(batch);

        unbindTexture(gl, 1);
        unbindTexture(gl, 0);

        [texClip, texLast] = [texLast, texClip];
        [tex, texLast] = [texLast, tex];
      } else if (layerVisibleWithTextureOrTool(layers[i])) {
        const layer = layers[i];

        // TODO: if last layer and can draw fast, draw directly onto output buffer ?
        if (!USE_FAST_DRAWING || !drawLayerFast(webgl, dirtyRect, layer, dirtyRect, scale)) {
          REPORT_SLOW && console.warn('slow');
          clearNormal(gl, dirtyRect);
          bindRenderTarget(webgl, tex, transparentColor);
          drawLayer(webgl, texLast, layer, layer.opacityLocked, layer.opacity, false, false, dirtyRect, dirtyRect, dirtyRect.x, dirtyRect.y, 1, undefined, scale, scale);
          [tex, texLast] = [texLast, tex];
        }
      }
    }
  } finally {
    clearNormal(gl);
    gl.disable(gl.SCISSOR_TEST);
    gl.bindTexture(gl.TEXTURE_2D, target.handle);

    gl.copyTexSubImage2D(gl.TEXTURE_2D, 0, (dirtyRect.x - renderRect.x) / scale, (dirtyRect.y - renderRect.y) / scale, 0, 0, dirtyRect.w / scale, dirtyRect.h / scale);
    gl.bindTexture(gl.TEXTURE_2D, null);

    unbindRenderTarget(webgl);

    target.hasMipmaps = false;

    releaseTexture(webgl, tex, true);
    releaseTexture(webgl, texLast, true);
    releaseTexture(webgl, texClip, true);
  }
}

interface TextureCache {
  id: number;
  slot: number;
  w: number;
  h: number;
  tx: number;
  ty: number;
  tw: number;
  th: number;
}

interface NamePlate extends TextureCache {
  name: string;
  color: string;
  avatarImage: HTMLImageElement | undefined;
  avatarVideoDimensions?: {
    width: number;
    height: number;
  }
}

interface VideoPlate extends TextureCache {
  lastDrawnFrame: number;
}

let namePlates: NamePlate[] = [];
let videoPlates: VideoPlate[] = [];
let namePlatesRatio = 1;
let videoPlatesRatio = 1;
let selfVideoRatio = 1;
let namePlatesCanvas: HTMLCanvasElement | undefined = undefined;
let videoPlatesCanvas: HTMLCanvasElement | undefined = undefined;
let selfVideoCanvas: HTMLCanvasElement | undefined = undefined;
let selfVideoPlate: VideoPlate | undefined;

function isSlotTaken(slot: number) {
  for (const n of namePlates) {
    if (n.slot === slot) {
      return true;
    }
  }
  return false;
}

function isVideoSlotTaken(slot: number) {
  for (const n of videoPlates) {
    if (n.slot === slot) {
      return true;
    }
  }
  return false;
}

function createSelfVideoTexture(webgl: WebGLResources, ratio: number, user: User) {
  const { gl } = webgl;
  const textureSize = findPowerOf2(2 * CURSOR_VIDEO_HEIGHT * ratio);
  let updateTexture = false;

  if (selfVideoRatio !== ratio) {
    deleteTexture(gl, webgl.selfVideoTexture);
    webgl.selfVideoTexture = undefined;
    selfVideoRatio = ratio;
  }

  if (!selfVideoCanvas) selfVideoCanvas = createCanvas(textureSize, textureSize);

  if (!webgl.selfVideoTexture) {
    webgl.selfVideoTexture = createEmptyTexture(webgl, textureSize, textureSize);
    updateTexture = true;
  }

  if (!user.avatarVideo) {
    return webgl.selfVideoTexture;
  }

  const { avatarVideo } = user;
  const lastDrawnFrame = getPresentedVideoFrames(avatarVideo);

  if (selfVideoPlate?.lastDrawnFrame === lastDrawnFrame) {
    return webgl.selfVideoTexture;
  }

  updateTexture = true;

  const h = CURSOR_VIDEO_HEIGHT * ratio;
  const srcWidth = avatarVideo.videoWidth;
  const srcHeight = avatarVideo.videoHeight;
  let w = Math.floor(h * (srcWidth / srcHeight));

  const context = getContext2d(selfVideoCanvas);

  context.drawImage(avatarVideo, 0, 0, srcWidth, srcHeight, 0, 0, w, h);

  selfVideoPlate = { id: 0, slot: 0, w, h, tx: (1 / textureSize), ty: (1 / textureSize), tw: w / textureSize, th: h / textureSize, lastDrawnFrame };

  if (updateTexture) {
    texSubImage2DImage(gl, webgl.selfVideoTexture, 0, 0, selfVideoCanvas);
  }

  return webgl.selfVideoTexture;
}

function createVideoPlatesTexture(webgl: WebGLResources, ratio: number, users: User[]) {
  const { gl } = webgl;
  const textureSize = findPowerOf2(1024 * ratio);
  const rows = 12;
  const columns = 12;
  const colSize = Math.floor(textureSize / columns);
  const rowSize = Math.floor(textureSize / rows);
  let context: CanvasRenderingContext2D | undefined = undefined;
  let slot = 0;
  let updateTexture = false;

  if (videoPlatesRatio !== ratio) {
    deleteTexture(gl, webgl.videoPlatesTexture);
    webgl.videoPlatesTexture = undefined;
    videoPlatesCanvas = undefined;
    videoPlates = [];
    videoPlatesRatio = ratio;
  }

  if (!videoPlatesCanvas) {
    videoPlatesCanvas = createCanvas(textureSize, textureSize);
  }

  if (!webgl.videoPlatesTexture) {
    webgl.videoPlatesTexture = createEmptyTexture(webgl, textureSize, textureSize);
    updateTexture = true;
  }

  for (const { avatarVideo, localId } of users) {
    const index = findIndexById(videoPlates, localId);

    if (!avatarVideo) {
      continue;
    }

    const lastDrawnFrame = getPresentedVideoFrames(avatarVideo);
    if (index !== -1 && videoPlates[index].lastDrawnFrame === lastDrawnFrame) {
      continue;
    }
    if (!context) {
      context = getContext2d(videoPlatesCanvas);
      context.font = `bold ${14 * ratio}px ${DEFAULT_FONT}`;
    }

    while (isVideoSlotTaken(slot)) slot++;

    if (slot >= columns * rows) {
      for (let i = videoPlates.length - 1; i >= 0; i--) {
        if (!findByLocalId(users, videoPlates[i].id)) {
          removeAtFast(videoPlates, i);
        }
      }

      slot = 0;
      while (isVideoSlotTaken(slot)) slot++;
    }

    const slotX = Math.floor(slot / rows);
    const slotY = slot % rows;

    const h = CURSOR_VIDEO_HEIGHT * ratio;
    const srcWidth = avatarVideo.videoWidth;
    const srcHeight = avatarVideo.videoHeight;
    let w = Math.floor(h * (srcWidth / srcHeight));

    context.drawImage(avatarVideo, 0, 0, srcWidth, srcHeight, slotX * colSize, slotY * rowSize, w, h);

    const tx = slotX * (1 / columns) + (1 / textureSize);
    const ty = slotY * (1 / rows) + (1 / textureSize);
    const tw = w / textureSize;
    const th = h / textureSize;

    const videoPlate: VideoPlate = { id: localId, slot, w, h, tx, ty, tw, th, lastDrawnFrame };

    if (index === -1) {
      videoPlates.push(videoPlate);
    } else {
      videoPlates[index] = videoPlate;
    }

    updateTexture = true;
  }

  if (updateTexture) {
    texSubImage2DImage(gl, webgl.videoPlatesTexture, 0, 0, videoPlatesCanvas);
  }

  return webgl.videoPlatesTexture;
}

function createNamePlatesTexture(webgl: WebGLResources, ratio: number, users: User[], cursors: CursorsMode, includeVideo: boolean) {
  const drawAvatarOption = cursors === CursorsMode.PointerAvatarName || cursors === CursorsMode.PointerAvatar;
  const drawNameOption = cursors === CursorsMode.PointerAvatarName || cursors === CursorsMode.PointerName;
  const { gl } = webgl;
  const textureSize = findPowerOf2(512 * ratio);
  const columns = drawAvatarOption && !drawNameOption ? 12 : 3;
  const rows = drawAvatarOption && !drawNameOption ? 12 : 16;
  const colSize = Math.floor(textureSize / columns);
  const rowSize = Math.floor(textureSize / rows);
  let updateTexture = false;
  let context: CanvasRenderingContext2D | undefined = undefined;
  let slot = 0;

  if (namePlatesRatio !== ratio || webgl.namePlatesMode !== cursors) {
    deleteTexture(gl, webgl.namePlatesTexture);
    webgl.namePlatesTexture = undefined;
    webgl.namePlatesMode = cursors;
    namePlatesCanvas = undefined;
    namePlates = [];
    namePlatesRatio = ratio;
  }

  if (!namePlatesCanvas) {
    namePlatesCanvas = createCanvas(textureSize, textureSize);
  }

  if (!webgl.namePlatesTexture) {
    webgl.namePlatesTexture = createEmptyTexture(webgl, textureSize, textureSize);
    updateTexture = true;
  }

  for (const { name, color, colorFloat, localId, avatarImage, avatarVideo } of users) {
    const index = findIndexById(namePlates, localId);
    // already up to date
    if (index !== -1 &&
      namePlates[index].name === name &&
      namePlates[index].color === color &&
      namePlates[index].avatarImage === avatarImage &&
      namePlates[index].avatarVideoDimensions?.width === (includeVideo ? avatarVideo?.videoWidth : undefined) &&
      namePlates[index].avatarVideoDimensions?.height === (includeVideo ? avatarVideo?.videoHeight : undefined)
    ) {
      continue;
    }

    let drawAvatar = drawAvatarOption;
    let drawName = drawNameOption;
    let drawNameplate = true;
    if (includeVideo && avatarVideo) {
      drawAvatar = false;
      drawNameplate = false;
    }
    if (!context) {
      context = getContext2d(namePlatesCanvas);
      context.font = `bold ${14 * ratio}px ${DEFAULT_FONT}`;
    }

    while (isSlotTaken(slot)) slot++;

    if (slot >= columns * rows) {
      for (let i = namePlates.length - 1; i >= 0; i--) {
        if (!findByLocalId(users, namePlates[i].id)) {
          removeAtFast(namePlates, i);
        }
      }

      slot = 0;
      while (isSlotTaken(slot)) slot++;
    }

    const slotX = Math.floor(slot / rows);
    const slotY = slot % rows;
    const padX = 10 * ratio;
    const maxLength = (includeVideo && avatarVideo) ? Math.floor(CURSOR_VIDEO_HEIGHT * ratio * avatarVideo.videoWidth / avatarVideo.videoHeight) - 10 * ratio : 100 * ratio;
    const brightness = drawNameplate ? rgbToGray(colorFloat[0], colorFloat[1], colorFloat[2]) : 0;
    const trimmed = truncateName(context, name, maxLength);
    let w = 0;
    const h = Math.round((drawAvatar && !drawName ? CURSOR_AVATAR_LARGE_HEIGHT : USER_NAME_HEIGHT) * ratio);

    if (drawNameplate) {
      context.fillStyle = color;
      context.fillRect(slotX * colSize, slotY * rowSize, colSize, rowSize);
    } else {
      context.clearRect(slotX * colSize, slotY * rowSize, colSize, rowSize);
    }

    if (drawAvatar && avatarImage) {
      const destImgWidth = h + 1;
      context.drawImage(avatarImage, 0, 0, avatarImage.width, avatarImage.width, slotX * colSize, slotY * rowSize, destImgWidth, destImgWidth);
      w += destImgWidth;
    }

    if (drawName) {
      context.fillStyle = brightness > 0.71 ? '#222' : 'white';
      context.fillText(trimmed, slotX * colSize + padX + w, slotY * rowSize + 20 * ratio);
      w += Math.ceil(textWidth(context, trimmed) + padX * 2);
    }

    w -= 2;

    const tx = slotX * (1 / columns) + (1 / textureSize);
    const ty = slotY * (1 / rows) + (1 / textureSize);
    const tw = w / textureSize;
    const th = h / textureSize;

    const namePlate: NamePlate = {
      id: localId, name, color, avatarImage, slot, w, h, tx, ty, tw, th,
      avatarVideoDimensions: (includeVideo && avatarVideo) ? {
        width: avatarVideo.videoWidth,
        height: avatarVideo.videoHeight,
      } : undefined
    };
    const index2 = findIndexById(namePlates, localId); // `index` might have changed since last check

    if (index2 === -1) {
      namePlates.push(namePlate);
    } else {
      namePlates[index2] = namePlate;
    }

    updateTexture = true;
  }

  if (updateTexture) {
    texSubImage2DImage(gl, webgl.namePlatesTexture, 0, 0, namePlatesCanvas);
  }

  return webgl.namePlatesTexture;
}

function drawCursors(webgl: WebGLResources, view: Viewport, drawing: Drawing, users: User[], cursors: CursorsMode, includeVideo: boolean) {
  const { gl, batch } = webgl;
  const role = drawing.permissions.cursors ?? defaultDrawingPermissions.cursors;
  const ratio = getPixelRatio();
  const lastUpdateThreshold = performance.now() - SHOW_CURSOR_UNMOVING_TIMEOUT;

  { // circles
    const radius = USER_CURSOR_RADIUS * ratio;
    const radiusWithBorder = Math.ceil(radius + 2 * ratio);
    const size = radiusWithBorder * 2;
    const shader = getShader(webgl, 'circleOutline');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight));
    gl.uniform1f(shader.uniforms.lineWidth, 1.5 * ratio);

    for (const u of users) {
      if (!hasDrawingRole(u, role) || u.cursorLastUpdate < lastUpdateThreshold) {
        u.cursorX = 1e9;
        u.cursorY = 1e9;
        continue;
      }

      setPoint(tempPt, u.cursorX, u.cursorY);
      absoluteDocumentToDocuemnt(tempPt, drawing);
      documentToScreenPoint(tempPt, view);
      const cx = tempPt.x * ratio;
      const cy = tempPt.y * ratio;
      const x = Math.round(cx - radiusWithBorder);
      const y = Math.round(cy - radiusWithBorder);
      const c = u.colorFloat;
      const a = Math.max(0.5, u.cursorAlpha);
      pushQuad(batch, x, y, size, size, 0, 0, 1, 1, radiusWithBorder, radiusWithBorder / radius, c[0] * a, c[1] * a, c[2] * a, a);
    }

    flushBatch(batch);
  }

  if (cursors === CursorsMode.PointerName || cursors === CursorsMode.PointerAvatarName || cursors === CursorsMode.PointerAvatar) { // name plates
    const shader = getShader(webgl, 'sprite');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight));

    if (includeVideo) {
      bindTexture(gl, 0, createVideoPlatesTexture(webgl, ratio, users));
      for (const u of users) {
        if (!hasDrawingRole(u, role) || u.cursorLastUpdate < lastUpdateThreshold) continue;
        {
          const c = findById(videoPlates, u.localId);

          if (c) {
            setPoint(tempPt, u.cursorX, u.cursorY);
            absoluteDocumentToDocuemnt(tempPt, drawing);
            documentToScreenPoint(tempPt, view);
            const x = Math.round((tempPt.x + USER_NAME_OFFSET) * ratio);
            const y = Math.round((tempPt.y + USER_NAME_OFFSET) * ratio);
            const a = u.cursorAlpha;
            pushQuad(batch, x, y, c.w, c.h, c.tx, c.ty, c.tw, c.th, 1, 0, a, a, a, a);
          }
        }
      }

      flushBatch(batch);
      unbindTexture(gl, 0);
    }
    {
      const namePlateRatio = clamp(ratio, 1, 2);
      const scale = ratio / namePlateRatio;

      bindTexture(gl, 0, createNamePlatesTexture(webgl, namePlateRatio, users, cursors, includeVideo));

      for (const u of users) {
        if (!hasDrawingRole(u, role) || u.cursorLastUpdate < lastUpdateThreshold) continue;

        const c = findById(namePlates, u.localId);
        if (c) {
          setPoint(tempPt, u.cursorX, u.cursorY);
          absoluteDocumentToDocuemnt(tempPt, drawing);
          documentToScreenPoint(tempPt, view);
          const x = Math.round((tempPt.x + USER_NAME_OFFSET) * ratio);

          if (includeVideo) {
            const cv = findById(videoPlates, u.localId);
            if (cv) tempPt.y += cv.h - c.h;
          }

          const y = Math.round((tempPt.y + USER_NAME_OFFSET) * ratio);
          const a = u.cursorAlpha;
          pushQuad(batch, x, y, c.w * scale, c.h * scale, c.tx, c.ty, c.tw, c.th, 1, 0, a, a, a, a);
        }
      }

      flushBatch(batch);
      unbindTexture(gl, 0);
    }
  }

  for (const u of users) {
    const color = u.color;
    if (isTextLayer(u.activeLayer) && u.activeLayer.textarea?.isFocused) {
      if (shouldCacheTextareaInLayer(u.activeLayer)) cacheTextareaInLayer(u.activeLayer);
      drawTextareaBoundaries(webgl, drawing, view, u.activeLayer.textarea, 2, color);
    }
  }
}

function drawLayerThumb(webgl: WebGLResources, layer: Layer, drawingRect: Rect) {
  const { gl, webgl2, batch, emptyTexture, pendingLayerThumb, thumbnailTexture } = webgl;

  if (pendingLayerThumb && webgl2) {
    const gl2 = gl as WebGL2RenderingContext;
    const status = gl2.getSyncParameter(pendingLayerThumb.sync, gl2.SYNC_STATUS);
    if (status !== gl2.SIGNALED) return true;

    const context = pendingLayerThumb.layer!.thumb?.getContext('2d');

    if (context) {
      const data = getImageDataForThumb(context);
      gl.bindBuffer(gl2.PIXEL_PACK_BUFFER, pendingLayerThumb.buffer);
      gl2.getBufferSubData(gl2.PIXEL_PACK_BUFFER, 0, toUint8(data.data));
      gl.bindBuffer(gl2.PIXEL_PACK_BUFFER, null);
      context.putImageData(data, 0, 0);
    }

    deleteBuffer(gl, pendingLayerThumb.buffer);
    webgl.pendingLayerThumb = undefined;
  }
  if (!layer.thumb || !shouldRedrawLayerThumb(layer)) return false;

  const pixelRatio = getPixelRatio();
  const size = Math.floor(LAYER_THUMB_SIZE * pixelRatio);

  if (layer.thumb.width !== size || layer.thumb.height !== size) {
    layer.thumb.width = size;
    layer.thumb.height = size;
  }

  const rect = cloneRect(getLayerRect(layer));
  clipToDrawingRect(rect, drawingRect);

  const aspectRatio = rect.w / rect.h;
  const dw = aspectRatio > 1 ? size : Math.round(size * aspectRatio);
  const dh = aspectRatio > 1 ? Math.round(size / aspectRatio) : size;
  const dx = Math.round((size - dw) / 2);
  const dy = Math.round((size - dh) / 2);

  let temp: Texture | undefined = undefined;

  if (rect.w > 0 && rect.h > 0) {
    temp = getTexture(webgl, dw, dh, 'temp-thumb', false);
    bindRenderTarget(webgl, temp);
    const scale = Math.max(rect.w, rect.h) / size;
    drawLayer(webgl, emptyTexture, layer, layer.opacityLocked, layer.opacity, true, false, rect, rect, 0, 0, 0, undefined, scale);
    unbindRenderTarget(webgl);
  }

  resizeTexture(webgl, thumbnailTexture, size, size);
  bindRenderTarget(webgl, thumbnailTexture, transparentColor);

  if (rect.w > 0 && rect.h > 0) {
    gl.clearColor(0.67, 0.67, 0.67, 1);
    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.enable(gl.BLEND);
    gl.blendEquation(gl.FUNC_ADD);
    gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
    gl.enable(gl.SCISSOR_TEST);
    gl.scissor(dx, dy, dw, dh);

    {
      const shader = getShader(webgl, 'checker');
      gl.useProgram(shader.program);
      gl.uniformMatrix4fv(shader.uniforms.transform, false, transformMatrixForSize(size, size));
      gl.uniform2f(shader.uniforms.size, size, size);
      gl.uniform1f(shader.uniforms.scale, 1);
      gl.uniform1f(shader.uniforms.checkerSize, 6 * pixelRatio);
      pushQuad(batch, 0, 0, size, size, 0, 0, 1, 1, 0, 0, 1, 1, 1, 1);
      flushBatch(batch);
    }
    gl.disable(gl.SCISSOR_TEST);
    if (temp) {
      const shader = getShader(webgl, 'basic');
      gl.useProgram(shader.program);
      gl.uniformMatrix4fv(shader.uniforms.transform, false, transformMatrixForSize(size, size));
      bindTexture(gl, 0, temp);
      pushQuad(batch, dx, dy, dw, dh, 0, 0, 1, 1, 0, 0, 1, 1, 1, 1);
      flushBatch(batch);
      unbindTexture(gl, 0);
    }
    gl.disable(gl.BLEND);
  }

  if (temp) {
    gl.bindTexture(gl.TEXTURE_2D, temp.handle);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.bindTexture(gl.TEXTURE_2D, null);
  }

  unbindRenderTarget(webgl);
  releaseTexture(webgl, temp);

  const context = layer.thumb.getContext('2d');

  if (context) {
    if (webgl2) {
      const gl2 = gl as WebGL2RenderingContext;
      const buffer = allocBuffer(gl);
      gl2.bindBuffer(gl2.PIXEL_PACK_BUFFER, buffer);
      gl2.bufferData(gl2.PIXEL_PACK_BUFFER, size * size * 4, gl2.STATIC_READ);
      bindRenderTarget(webgl, thumbnailTexture);
      gl2.readPixels(0, 0, size, size, gl.RGBA, gl.UNSIGNED_BYTE, 0);
      unbindRenderTarget(webgl);
      gl2.bindBuffer(gl2.PIXEL_PACK_BUFFER, null);
      const sync = gl2.fenceSync(gl2.SYNC_GPU_COMMANDS_COMPLETE, 0);
      if (sync) webgl.pendingLayerThumb = { sync, layer, buffer };
    } else {
      const data = getImageDataForThumb(context);
      bindRenderTarget(webgl, thumbnailTexture);
      gl.readPixels(0, 0, size, size, gl.RGBA, gl.UNSIGNED_BYTE, toUint8(data.data));
      unbindRenderTarget(webgl);
      context.putImageData(data, 0, 0);
    }

    layer.thumbDirty = 0;
    return true;
  } else {
    return false;
  }
}

// needed for IE and Safari
function toUint8(data: Uint8ClampedArray) {
  return new Uint8Array(data.buffer, data.byteOffset, data.byteLength);
}

let imageData: ImageData | undefined = undefined;

function getImageDataForThumb(context: CanvasRenderingContext2D) {
  if (!imageData || imageData.width !== context.canvas.width || imageData.height !== context.canvas.height) {
    imageData = context.createImageData(context.canvas.width, context.canvas.height);
  }

  return imageData;
}

function layerHasTool(layer: Layer) {
  return layer.owner
    && layer.owner.surface.layer === layer
    && !isSurfaceEmpty(layer.owner.surface)
    && !!layer.owner.surface.texture;
}

function shouldUseLinearForSurface(surface: ToolSurface) {
  return !isMat2dIntegerTranslation(surface.transform) && (Math.abs(surface.scaleX) < 0.5 || Math.abs(surface.scaleY) < 0.5);
}

// assumes that texture is bound
export function ensureTextureMipmaps(gl: WebGLRenderingContext, texture: Texture | undefined) {
  if (!texture) return;
  if (texture.hasMipmaps) return;
  gl.generateMipmap(gl.TEXTURE_2D);
  texture.hasMipmaps = true;
}

function ensureSurfaceMipmaps({ gl }: WebGLResources, surface: ToolSurface) {
  if (!surface.texture) return;

  const linear = shouldUseLinearForSurface(surface);

  if (linear !== surface.textureIsLinear) {
    surface.textureIsLinear = linear;
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, linear ? gl.LINEAR : gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, linear ? gl.LINEAR_MIPMAP_LINEAR : gl.NEAREST);

    if (DEVELOPMENT && false) {
      // TODO: move to webgl init
      const ext = gl.getExtension('EXT_texture_filter_anisotropic') ||
        gl.getExtension('MOZ_EXT_texture_filter_anisotropic') ||
        gl.getExtension('WEBKIT_EXT_texture_filter_anisotropic');

      if (ext) {
        const max = gl.getParameter(ext.MAX_TEXTURE_MAX_ANISOTROPY_EXT);
        gl.texParameterf(gl.TEXTURE_2D, ext.TEXTURE_MAX_ANISOTROPY_EXT, max);
      }
    }
  }

  if (linear && !surface.texture.hasMipmaps) {
    gl.generateMipmap(gl.TEXTURE_2D);
    surface.texture.hasMipmaps = true;
  }
}

const surfaceColor = new Float32Array(4);

function drawLayer(
  webgl: WebGLResources, baseTexture: Texture, layer: Layer, lockOpacity: boolean, layerOpacity: number,
  skipMode = false, clip = false,
  dirtyRect: Rect,
  renderRect: Rect,
  baseX = 0, baseY = 0, baseOpacity = 1, forcedShader?: Shader, scale = 1,
  baseTextureScale = 1 // is base texture is already scaled
) {
  const { gl, emptyTexture, whiteTexture } = webgl;
  const surface = layerHasTool(layer) ? layer.owner!.surface : undefined;
  let mask: Texture | undefined = undefined;

  if (surface) {
    const selection = layer.owner!.selection;
    if (!isMaskEmpty(selection) && !surface.ignoreSelection) {
      // TODO: use small texture intersection(surface.rect, selection.bounds) ?
      mask = createMaskTexture(webgl, selection, surface.textureX, surface.textureY, surface.texture!.width, surface.texture!.height);
    }
  }

  const op = surface?.mode ?? CompositeOp.None;
  const layerMode = skipMode ? 'normal' : layer.mode;
  const hasMasks = !!(surface && (mask /*|| mask2 || surface.textureMask*/));
  const shader = forcedShader ?? getShaderForMode(webgl, layerMode, op, hasMasks);

  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, frameBufferTransform(webgl));
  gl.uniform1f(shader.uniforms.opacity, layerOpacity);
  gl.uniform1f(shader.uniforms.baseOpacity, baseOpacity);
  gl.uniform1f(shader.uniforms.isClipped, clip ? 1 : 0);

  if (surface) {
    if (surface.mode === CompositeOp.Move) lockOpacity = false;

    setToolTransform(webgl, surface, shader);

    gl.uniform4fv(shader.uniforms.toolColor, colorToFloats(surfaceColor, surface.color));
    gl.uniform1f(shader.uniforms.toolOpacity, surface.opacity); // TODO: is this needed ? just multiply the color ?
    gl.uniform1f(shader.uniforms.lockOpacity, (lockOpacity && op !== CompositeOp.Erase) ? 1 : 0);
    gl.uniform1f(shader.uniforms.srcMul, op === CompositeOp.Erase ? 0 : 1);
  }

  const layerTexture = layer.texture || emptyTexture;
  const useLinearFiltering = scale !== 1;

  bindTexture(gl, 0, layerTexture, useLinearFiltering);
  bindTexture(gl, 1, baseTexture, useLinearFiltering);

  if (surface) {
    bindTexture(gl, 2, surface.texture || emptyTexture);
    ensureSurfaceMipmaps(webgl, surface);

    if (hasMasks) {
      bindTexture(gl, 3, mask || whiteTexture, useLinearFiltering);
    }
  }

  pushQuadLayerSurfaceSelection(webgl,
    renderRect, dirtyRect, baseTexture,
    layer, surface, mask, baseX, baseY,
    scale, baseTextureScale
  );

  // if (hasMasks) unbindTexture(gl, 4);
  if (hasMasks) unbindTexture(gl, 3, useLinearFiltering);
  if (surface) unbindTexture(gl, 2, useLinearFiltering);
  unbindTexture(gl, 1, useLinearFiltering);
  unbindTexture(gl, 0, useLinearFiltering);
}

function drawCrosshair(webgl: WebGLResources, cx: number, cy: number, viewMatrix: Mat4, pixelRatio: number) {
  const { gl, batch } = webgl;

  const GAP = 5 * pixelRatio;
  const SIZE = 3 * pixelRatio;
  const THICKNESS = 1 * pixelRatio;
  const th = THICKNESS * 0.5;
  const c = cursorColor;
  const r = c[0], g = c[1], b = c[2], a = c[3];
  const x = Math.floor(cx);
  const y = Math.floor(cy);

  const x1 = Math.floor(cx - th);
  const x2 = x1 + 1;
  const ox1 = x2 - (cx - th);
  const ox2 = (cx + th) - x2;

  const y1 = Math.floor(cy - th);
  const y2 = y1 + 1;
  const oy1 = y2 - (cy - th);
  const oy2 = (cy + th) - y2;

  // TODO: use special line shader instead
  const shader = getShader(webgl, 'vertexColor');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
  pushQuad(batch, x1, y - GAP - SIZE, THICKNESS, SIZE, 0, 0, 0, 0, 0, 0, r * ox1, g * ox1, b * ox1, a * ox1); // top (left)
  pushQuad(batch, x2, y - GAP - SIZE, THICKNESS, SIZE, 0, 0, 0, 0, 0, 0, r * ox2, g * ox2, b * ox2, a * ox2); // top (right)
  pushQuad(batch, x1, y + GAP, THICKNESS, SIZE, 0, 0, 0, 0, 0, 0, r * ox1, g * ox1, b * ox1, a * ox1); // bottom (left)
  pushQuad(batch, x2, y + GAP, THICKNESS, SIZE, 0, 0, 0, 0, 0, 0, r * ox2, g * ox2, b * ox2, a * ox2); // bottom (right)
  pushQuad(batch, x - GAP - SIZE, y1, SIZE, THICKNESS, 0, 0, 0, 0, 0, 0, r * oy1, g * oy1, b * oy1, a * oy1); // left (top)
  pushQuad(batch, x - GAP - SIZE, y2, SIZE, THICKNESS, 0, 0, 0, 0, 0, 0, r * oy2, g * oy2, b * oy2, a * oy2); // left (bottom)
  pushQuad(batch, x + GAP, y1, SIZE, THICKNESS, 0, 0, 0, 0, 0, 0, r * oy1, g * oy1, b * oy1, a * oy1); // right (top)
  pushQuad(batch, x + GAP, y2, SIZE, THICKNESS, 0, 0, 0, 0, 0, 0, r * oy2, g * oy2, b * oy2, a * oy2); // right (bottom)
  flushBatch(batch);
}

function drawSelfVideo(webgl: WebGLResources, cursor: Cursor, user: User, opacity: number) {
  const { gl, batch } = webgl;
  const pixelRatio = getPixelRatio();
  const shader = getShader(webgl, 'sprite');
  gl.useProgram(shader.program);
  const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

  unbindTexture(gl, 0);
  const texture = createSelfVideoTexture(webgl, pixelRatio, user);
  bindTexture(gl, 0, texture);
  {
    const cx = cursor.x * pixelRatio;
    const cy = cursor.y * pixelRatio;

    const a = opacity;
    const c = selfVideoPlate;
    if (c) {
      pushQuad(batch, cx + USER_NAME_OFFSET, cy + USER_NAME_OFFSET, c.w, c.h, c.tx + c.tw, c.ty, -c.tw, c.th, 1, 0, a, a, a, a);
    }
  }

  flushBatch(batch);
  unbindTexture(gl, 0);
}

function drawSyntheticCursor(webgl: WebGLResources, cx: number, cy: number, type: CursorType) {
  const index = FALLBACK_CURSORS.indexOf(type);
  if (index === -1) return;

  const { gl, batch } = webgl;

  if (!webgl.fallbackCursorsTexture) {
    const image = getFallbackCursorsImage();
    if (!image) return;

    const texture = createEmptyTexture(webgl, image.width, image.height);
    texSubImage2DImage(gl, texture, 0, 0, image, true);
    webgl.fallbackCursorsTexture = texture;
  }

  const pixelRatio = getPixelRatio();
  const size = FALLBACK_CURSOR_SISE;
  const offset = FALLBACK_CURSORS_OFFSETS[index];
  const texture = webgl.fallbackCursorsTexture;
  const shader = getShader(webgl, 'sprite');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight));
  bindTexture(gl, 0, texture);
  pushQuad(batch,
    (cx - offset.x) * pixelRatio, (cy - offset.y) * pixelRatio, size * pixelRatio, size * pixelRatio,
    index * 2 * size / texture.width, 0, size / texture.width, 1,
    0, 0, 1, 1, 1, 1);
  flushBatch(batch);
  unbindTexture(gl, 0);
}

function setAngleJitter(cursor: Cursor, options?: DrawOptions) {
  const activeToolForCursor = (options as Editor).activeTool;
  if (activeToolForCursor instanceof BaseBrushTool) {
    const randomState: RandomState = { seed: activeToolForCursor.seed };
    randomSeed(randomState, activeToolForCursor.brush.seed);
    if (activeToolForCursor.brush.angleJitter != lastAngleJitter) {
      const randFloat3 = lastRandomNumber1;
      const randFloat4 = lastRandomNumber2;
      if (activeToolForCursor.brush.angleJitter) {
        cursor.additionalAngle = (activeToolForCursor.sizeJitter) ? (activeToolForCursor.brush.angleJitter * 2 * (randFloat4 - 0.5)) : (activeToolForCursor.brush.angleJitter * 2 * (randFloat3 - 0.5));
      } else {
        cursor.additionalAngle =  0;
      } 
      lastAngleJitter = activeToolForCursor.brush.angleJitter;
    }
    if (activeToolForCursor.seed !== lastSeed) {
      randomFloat(randomState);
      randomFloat(randomState);
      const randFloat3 = randomFloat(randomState);
      const randFloat4 = randomFloat(randomState);
      if (activeToolForCursor.brush.angleJitter) {
        cursor.additionalAngle = (activeToolForCursor.sizeJitter) ? (activeToolForCursor.brush.angleJitter * 2 * (randFloat4 - 0.5)) : (activeToolForCursor.brush.angleJitter * 2 * (randFloat3 - 0.5));
      } else {
        cursor.additionalAngle =  0;
      } 
      lastSeed = activeToolForCursor.seed;
      lastRandomNumber1 = randFloat3;
      lastRandomNumber2 = randFloat4;
    }
  }
}

function drawCursor(webgl: WebGLResources, cursor: Cursor, view: Viewport, settings: RendererSettings, drawing?: Drawing, options?: DrawOptions, background?: Float32Array) {
  const { gl, batch } = webgl;

  if (!cursor.show) return;

  const skipCrosshair = settings.showCursor;
  const pixelRatio = getPixelRatio();
  const c = cursorColor;
  const r = c[0], g = c[1], b = c[2], a = c[3];

  switch (cursor.type) {
    case CursorType.Circle: {
      if (options?.selectedTool instanceof BaseBrushTool) {
        const radius = Math.max(1, (cursor.size / 2)) * pixelRatio;
        const radiusWithBorder = Math.ceil(radius + 2);
        const roundnessFactor = (radiusWithBorder - radiusWithBorder * options?.selectedTool.roundness);
        const cx = cursor.x * pixelRatio;
        const cy = cursor.y * pixelRatio;
        const x = cx - radiusWithBorder;
        const y = cy - radiusWithBorder + roundnessFactor;
        const size = radiusWithBorder * 2;

        const radius2 = radius + 0.75;
        const radiusWithBorder2 = Math.ceil(radius + 2);
        const roundnessFactor2 = (radiusWithBorder2 - radiusWithBorder2 * options?.selectedTool.roundness);
        const x2 = cx - radiusWithBorder2;
        const y2 = cy - radiusWithBorder2 + roundnessFactor2;
        const size2 = radiusWithBorder2 * 2;

        const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);
        const MIN_CIRCLE_SIZE = 2.5;
        const MIN_NORMAL_SIZE = 5.0;

        const shader = getShader(webgl, cursor.size >= MIN_CIRCLE_SIZE ? 'ellipseOutline' : 'circle');
        gl.useProgram(shader.program);
        gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

        if (cursor.size >= MIN_CIRCLE_SIZE) {
          let lineWidth = clamp((16 - cursor.size) / 6, 1, 1.75);
          gl.uniform1f(shader.uniforms.uniWidth, size2);
          gl.uniform1f(shader.uniforms.uniHeight, size2 * options?.selectedTool.roundness);
          gl.uniform1f(shader.uniforms.lineWidth, lineWidth);
          gl.uniform1f(shader.uniforms.roundness, options?.selectedTool.roundness);

          if (options?.selectedTool.angle == 0) {
            pushQuad(batch, x2, y2, size2, size2 * options?.selectedTool.roundness, 0, 0, 1, 1, radiusWithBorder2, radiusWithBorder2 / radius2, 0.5, 0.5, 0.5, 0.5);
            pushQuad(batch, x, y, size, size * options?.selectedTool.roundness, 0, 0, 1, 1, radiusWithBorder, radiusWithBorder / radius, r, g, b, a);
          } else {
            setAngleJitter(cursor, options);
            const transformMatrix = tempMat;
            identityMat2d(transformMatrix);
            translateMat2d(transformMatrix, transformMatrix, cx, cy);
            rotateMat2d(transformMatrix, transformMatrix, options?.selectedTool.angle + cursor.additionalAngle);
            pushQuadXXYYTransformed(batch, transformMatrix, -size2/2, -size2/2 * options?.selectedTool.roundness, size2/2, size2/2 * options?.selectedTool.roundness, 0, 0, 1, 1, radiusWithBorder2, radiusWithBorder2 / radius2, 0.5, 0.5, 0.5, 0.5);
            gl.uniform1f(shader.uniforms.uniWidth, size);
            gl.uniform1f(shader.uniforms.uniHeight, size * options?.selectedTool.roundness);
            pushQuadXXYYTransformed(batch, transformMatrix, -size/2, -size/2 * options?.selectedTool.roundness, size/2, size/2 * options?.selectedTool.roundness, 0, 0, 1, 1, radiusWithBorder, radiusWithBorder / radius, r, g, b, a);
          }
        } else {
          const x1 = cx + radiusWithBorder, y1 = cy + radiusWithBorder;
          const x1b = cx + radiusWithBorder2, y1b = cy + radiusWithBorder2;

          pushQuadXXYY(batch, x2, y2, x1b, y1b, x2 - cx, y2 - cy, x1b - cx, y1b - cy, radius + 0.5, 1, 1, 1, 1, 0.5);
          pushQuadXXYY(batch, x, y, x1, y1, x - cx, y - cy, x1 - cx, y1 - cy, radius, 1, r, g, b, a);
        }

        flushBatch(batch);

        if (cursor.size < MIN_NORMAL_SIZE && !skipCrosshair) {
          drawCrosshair(webgl, cx, cy, viewMatrix, pixelRatio);
        }

        if (settings.showCursor && cursor.useSynthetic) {
          drawSyntheticCursor(webgl, cursor.x, cursor.y, CursorType.Default);
        }
      }

      break;
    }
    case CursorType.Square: {
      const MIN_NORMAL_SIZE = 4.0;
      const size = Math.round(cursor.size) * pixelRatio;
      const half = size / 2;
      const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);
      const shader = getShader(webgl, 'rectOutline');
      const pix = 1;
      const pix2 = 2;
      const tex = pix / size;
      gl.useProgram(shader.program);
      gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
      gl.uniform1f(shader.uniforms.pixelSize, tex);

      // TODO: handle cursor smaller than outline width
      if (view.rotation === 0) {
        const x = round5(cursor.x * pixelRatio - half);
        const y = round5(cursor.y * pixelRatio - half);
        pushRectOutline(batch, x - 1, y - 1, size + 2, size + 2, 1, 0.25, 0.25, 0.25, 0.25);
        pushRectOutline(batch, x, y, size, size, 1, r, g, b, a);
        flushBatch(batch);
      } else {
        const x = round5(cursor.x * pixelRatio);
        const y = round5(cursor.y * pixelRatio);
        const min = -size / 2 - pix;
        const siz = size + 2 * pix;
        const min2 = -size / 2 - pix2;
        const siz2 = size + 2 * pix2;

        const cursorMatrix = tempMat;
        identityMat2d(cursorMatrix);
        translateMat2d(cursorMatrix, cursorMatrix, x, y);
        rotateMat2d(cursorMatrix, cursorMatrix, -view.rotation);

        pushTransformedQuad(batch, min2, min2, siz2, siz2, -tex, -tex, 1 + 2 * tex, 1 + 2 * tex, 0.25, 0.25, 0.25, 0.25, cursorMatrix);
        pushTransformedQuad(batch, min, min, siz, siz, -tex, -tex, 1 + 2 * tex, 1 + 2 * tex, r, g, b, a, cursorMatrix);
        flushBatch(batch);
      }

      if (cursor.size < MIN_NORMAL_SIZE && !skipCrosshair) {
        const cx = cursor.x * pixelRatio;
        const cy = cursor.y * pixelRatio;
        drawCrosshair(webgl, cx, cy, viewMatrix, pixelRatio);
      }

      if (settings.showCursor && cursor.useSynthetic) {
        drawSyntheticCursor(webgl, cursor.x, cursor.y, CursorType.Default);
      }

      break;
    }
    case CursorType.Image: {
      const activeToolForCursor = (options as Editor).activeTool;
      if (Math.round(cursor.size) * pixelRatio <= 5 && !skipCrosshair) {
        const cx = cursor.x * pixelRatio;
        const cy = cursor.y * pixelRatio;
        const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);
        drawCrosshair(webgl, cx, cy, viewMatrix, pixelRatio);
      }
      else if (activeToolForCursor instanceof BaseBrushTool) {
        const brushShape = brushShapesMap.get(activeToolForCursor.shape);

        if (!brushShape) break;
        if (!drawing) break;

        setPoint(tempPt, cursor.x, cursor.y);
        screenToDocumentPoint(tempPt, view);
        const xDP = tempPt.x;
        const yDP = tempPt.y;

        documentToAbsoluteDocument(tempPt, drawing);

        const xDPabs = tempPt.x;
        const yDPabs = tempPt.y;

        const corrected_size = 238; // that's the minimum size where the leaf brush (most asymmetric one) looks good after rotating

        let size = Math.round(cursor.size) * pixelRatio;
        const s = size * corrected_size / 170; // to compensate for the modified coords
        const x = cursor.x * pixelRatio;
        const y = cursor.y * pixelRatio;

        const cursorShader = getShader(webgl, 'imageBrushCursor');
        gl.useProgram(cursorShader.program);

        const tempRect = { x: xDPabs, y: yDPabs, w: 1, h: 1 };

        if (rectContainsXY(drawing, xDP, yDP)) {
          const { tileSize: drawingTileSize, tiles: drawingTiles, lod: drawingLoD } = drawing;
          iterateTiles(drawingTiles, tempRect, drawingTileSize * drawingLoD, (tile) => {
            const rect = cloneRect(tile.rect);
            intersectRect(rect, drawing);
            bindTexture(gl, 0, tile.texture);
            ensureTextureMipmaps(gl, tile.texture); // do it here instead of in bindTexture because there is additional logic for that bellow

            const tw = tile.texture.width * drawingLoD;
            const th = tile.texture.height * drawingLoD;

            gl.uniform1f(cursorShader.uniforms.drawingLoD, drawingLoD);
            gl.uniform4f(cursorShader.uniforms.backgroundColor, 1.0, 1.0, 1.0, 1.0);
            gl.uniform2f(cursorShader.uniforms.textureSize, tw, th);
            gl.uniform2f(cursorShader.uniforms.position, (xDPabs - tile.textureRect.x) / drawingLoD, (yDPabs - tile.textureRect.y) / drawingLoD);
          });
        } else {
          bindTexture(gl, 0, webgl.whiteTexture);
          gl.uniform1f(cursorShader.uniforms.drawingLoD, 0);
          gl.uniform2f(cursorShader.uniforms.textureSize, webgl.whiteTexture.width, webgl.whiteTexture.height);
          gl.uniform2f(cursorShader.uniforms.position, webgl.whiteTexture.width / 2, webgl.whiteTexture.height / 2);
          gl.uniform4f(cursorShader.uniforms.backgroundColor, background![0], background![1], background![2], background![3]);
        }

        const texture = createBrushTexture(webgl, brushShape);

        bindTexture(gl, 1, texture);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

        gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);

        const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);
        gl.uniform2f(cursorShader.uniforms.brushTextureSize, texture.width, texture.height);
        gl.uniform1i(cursorShader.uniforms.flipX, activeToolForCursor.flipX ? 1 : 0);
        gl.uniform1i(cursorShader.uniforms.flipY, activeToolForCursor.flipY ? 1 : 0);

        let scale = s / corrected_size;
        gl.uniform2f(cursorShader.uniforms.scale, scale, scale * activeToolForCursor.roundness);
        gl.uniformMatrix4fv(cursorShader.uniforms.transform, false, viewMatrix);

        let cursorMatrix = tempMat;
        identityMat2d(cursorMatrix);
        translateMat2d(cursorMatrix, cursorMatrix, x, y);

        setAngleJitter(cursor, options);
        const angle = activeToolForCursor.angle + cursor.additionalAngle;
        let angleForRoundnessSimulation = activeToolForCursor.flipX ? -angle : angle;
        if (activeToolForCursor.flipY) angleForRoundnessSimulation = -angleForRoundnessSimulation;

        rotateMat2d(cursorMatrix, cursorMatrix, angleForRoundnessSimulation);
        scaleMat2d(cursorMatrix, cursorMatrix, 1, activeToolForCursor.roundness);
        rotateMat2d(cursorMatrix, cursorMatrix, -angleForRoundnessSimulation);

        gl.uniform1f(cursorShader.uniforms.angle, angle);

        const ts = (corrected_size / 512); // size of image in texcoords + a margin so it's not cut after rotations + edges detection
        const to = (1 - ts) / 2; // offset from edge of texture to start of image
        const min = -s / 2;

        pushTransformedQuad(batch, min, min, s, s, to, to, ts, ts, 1, 1, 1, a, cursorMatrix);
        flushBatch(batch);
      }

      break;
    }
    case CursorType.Crosshair: {
      const LENGTH = 11 * pixelRatio; // length of arms
      const WIDTH = 1 * pixelRatio; // half width of each arm
      const cx = cursor.x * pixelRatio;
      const cy = cursor.y * pixelRatio;
      const t0 = cy - WIDTH, t1 = cy - WIDTH - LENGTH;
      const b0 = cy + WIDTH;
      const l0 = cx - WIDTH, l1 = cx - WIDTH - LENGTH;

      const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);
      const shader = getShader(webgl, 'vertexColor');
      gl.useProgram(shader.program);
      gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
      gl.blendEquation(gl.FUNC_SUBTRACT);
      gl.blendFunc(gl.ONE, gl.ONE);
      pushQuad(batch, l1, t0, (WIDTH + LENGTH) * 2, WIDTH * 2, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);
      pushQuad(batch, l0, t1, WIDTH * 2, LENGTH, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);
      pushQuad(batch, l0, b0, WIDTH * 2, LENGTH, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);
      flushBatch(batch);
      gl.blendEquation(gl.FUNC_ADD);
      gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);

      break;
    }
    default:
      drawSyntheticCursor(webgl, cursor.x, cursor.y, cursor.type);
      break;
  }
}

function drawActiveTool(webgl: WebGLResources, drawing: Drawing, user: User, view: Viewport) {
  const { gl, batch } = webgl;
  const tool = user.activeTool;

  if (!tool) return;

  switch (tool.id) {
    case ToolId.AI: {
      const t = tool as AiTool;
      drawAiSelectionPoly(webgl, drawing, t.poly, view, AI_BOUNDING_BOX_COLOR_1_ACTIVE, AI_BOUNDING_BOX_COLOR_2_ACTIVE, 1);
      break;
    }
    case ToolId.Selection:
    case ToolId.CircleSelection:
    case ToolId.LassoSelection: {
      const pixelRatio = getPixelRatio();
      const thickness = 1 * pixelRatio;
      const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);

      const shader = getShader(webgl, 'ants');
      gl.useProgram(shader.program);
      gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
      gl.uniform1f(shader.uniforms.size, 6 * pixelRatio);
      gl.uniform1f(shader.uniforms.time, Math.floor(performance.now() / 100));

      const mat = createViewportMatrix2d(tempMat, view);
      translateAbsoluteMatToDrawingMat2d(mat, drawing);
      multiplyMat2d(mat, mat, user.surface.transform);

      switch (tool.id) {
        case ToolId.Selection: {
          const selectionTool = tool as SelectionTool;
          pushLineRect(batch, selectionTool.rect, mat, thickness);
          break;
        }
        case ToolId.CircleSelection: {
          const circleSelectionTool = tool as CircleSelectionTool;
          pushLineEllipse(batch, circleSelectionTool.rect, mat, thickness);
          break;
        }
        case ToolId.LassoSelection: {
          const lassoSelection = tool as LassoSelectionTool;
          pushPoly(batch, lassoSelection.poly, mat, false, thickness);
          break;
        }
        // case ToolId.LassoBrush: {
        //   const lassoBrush = tool as LassoBrushTool;
        //   pushPolyf(batch, lassoBrush.polyf, mat, false, thickness);
        //   break;
        // }
      }

      flushBatch(batch);
      break;
    }
    case ToolId.RotateView: {
      const size = 5;
      const crosshair = size + 3;
      const pixelRatio = getPixelRatio();
      const radius = size * pixelRatio;
      const radiusWithBorder = radius + 2;
      const cx = Math.round(view.width / 2) * pixelRatio;
      const cy = Math.round(view.height / 2) * pixelRatio;
      const x = cx - radiusWithBorder;
      const y = cy - radiusWithBorder;
      const quadSize = radiusWithBorder * 2;
      const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);
      const c = cursorColor;
      const r = c[0], g = c[1], b = c[2], a = c[3];

      {
        const shader = getShader(webgl, 'circleOutline');
        gl.useProgram(shader.program);
        gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
        gl.uniform1f(shader.uniforms.lineWidth, 1.5);

        pushQuad(batch, x, y + 1, quadSize - 1, quadSize - 1, 0, 0, 1, 1, radiusWithBorder, radiusWithBorder / radius, r, g, b, a);
        flushBatch(batch);
      }

      {
        const shader = getShader(webgl, 'vertexColor');
        gl.useProgram(shader.program);
        gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

        pushQuad(batch, cx - 0.5, cy - crosshair + 1, 1, crosshair * 2 - 1, 0, 0, 0, 0, 0, 0, r, g, b, a);
        pushQuad(batch, cx - crosshair, cy - 0.5, crosshair * 2 - 1, 1, 0, 0, 0, 0, 0, 0, r, g, b, a);
        flushBatch(batch);
      }

      break;
    }
    case ToolId.Text: {
      const textTool = tool as TextTool;
      if (textTool.mode === TextToolMode.Creating) {
        const ratio = getPixelRatio();
        const pixelSize = 1 / view.scale;
        const thickness = TEXTAREA_HOVERED_BOUNDARIES_WIDTH;
        const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);

        const rect = cloneRect(textTool.rect);
        absoluteDocumentToDocumentRect(rect, drawing);
        if (textTool.textarea?.type === TextareaType.AutoWidth) {
          rect.x += (textTool.textarea as AutoWidthTextarea).negativeOffsetForWidth;
        }
        const topLeft = createPoint(rect.x, rect.y);
        const topRight = createPoint(rect.x + rect.w, rect.y);
        const bottomRight = createPoint(rect.x + rect.w, rect.y + rect.h);
        const bottomLeft = createPoint(rect.x, rect.y + rect.h);
        documentToScreenPoints([topLeft, topRight, bottomRight, bottomLeft], view);

        const shader = getShader(webgl, 'line');
        gl.useProgram(shader.program);
        gl.uniform1f(shader.uniforms.pixelSize, pixelSize);
        gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

        const { r, g, b, a } = TEXTAREA_BOUNDARIES_COLOR;
        pushAntialiasedLine(batch, topLeft.x * ratio, topLeft.y * ratio, topRight.x * ratio, topRight.y * ratio, thickness, r, g, b, a);
        pushAntialiasedLine(batch, topRight.x * ratio, topRight.y * ratio, bottomRight.x * ratio, bottomRight.y * ratio, thickness, r, g, b, a);
        pushAntialiasedLine(batch, bottomRight.x * ratio, bottomRight.y * ratio, bottomLeft.x * ratio, bottomLeft.y * ratio, thickness, r, g, b, a);
        pushAntialiasedLine(batch, bottomLeft.x * ratio, bottomLeft.y * ratio, topLeft.x * ratio, topLeft.y * ratio, thickness, r, g, b, a);
        flushBatch(batch);
      }
      break;
    }
  }
}

function fillSelection(webgl: WebGLResources, drawing: Drawing, selection: Mask, view: Viewport) {
  const { gl, batch } = webgl;
  const pixelRatio = getPixelRatio();
  const m = createMat2d();
  const mat = createViewportMatrix2d(m, view);
  translateAbsoluteMatToDrawingMat2d(mat, drawing);
  const mask = cloneMask(selection);
  transformMask(mask, mat);
  const width = gl.drawingBufferWidth / pixelRatio;
  const height = gl.drawingBufferHeight / pixelRatio;

  const maskTexture = createMaskTexture(webgl, mask, 0, 0, width, height);

  const shader = getShader(webgl, 'aiMaskInpaint');
  const vm = identityViewMatrix(width, height);
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, vm);
  gl.uniform1f(shader.uniforms.size, 4);
  gl.uniform1f(shader.uniforms.width, width);
  gl.uniform1f(shader.uniforms.height, height);
  gl.uniform4fv(shader.uniforms.color1, AI_SELECTION_COLOR_1_FLOAT);
  gl.uniform4fv(shader.uniforms.color2, AI_SELECTION_COLOR_2_FLOAT);
  gl.uniform1f(shader.uniforms.time, TESTS ? 0 : performance.now() / (40 * pixelRatio));

  bindTexture(gl, 0, maskTexture);
  pushQuad(batch, 0, 0, width, height, 0, 0, 1, 1, 0, 0, 1, 1, 1, 1);
  flushBatch(batch);

  unbindTexture(gl, 0);
}

function drawAiSelectionPoly(webgl: WebGLResources, drawing: Drawing, poly: Poly, view: Viewport, color1: Float32Array, color2: Float32Array, thickness: number) {
  const { gl, batch } = webgl;

  const pixelRatio = getPixelRatio();
  const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);

  const shader = getShader(webgl, 'antsAi');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

  gl.uniform1f(shader.uniforms.size, 4 * pixelRatio);
  gl.uniform1f(shader.uniforms.time, Math.floor(performance.now() / 20));
  gl.uniform4fv(shader.uniforms.color1, color1);
  gl.uniform4fv(shader.uniforms.color2, color2);

  const mat = createViewportMatrix2d(tempMat, view);
  translateAbsoluteMatToDrawingMat2d(mat, drawing);

  pushPoly(batch, poly, mat, true, thickness * pixelRatio);
  flushBatch(batch);
}

function drawAiOutpaintingMask(webgl: WebGLResources, drawing: Drawing, tool: AiTool, layer: Layer, view: Viewport) {
  const gl = webgl.gl;
  const bounds = tool.bounds;
  const texture = getTexture(webgl, bounds.w, bounds.h, 'outpaint', false);
  bindRenderTarget(webgl, texture, colorToFloatArray(TRANSPARENT));

  drawLayer(webgl, webgl.emptyTexture, layer, layer.opacityLocked, layer.opacity, true, false, bounds, bounds, 0, 1);
  unbindTexture(gl, 0);
  unbindRenderTarget(webgl);

  const pixelRatio = getPixelRatio();
  const width = texture.width / pixelRatio * view.scale;
  const height = texture.height / pixelRatio * view.scale;
  const vm = identityViewMatrix(gl.drawingBufferWidth / pixelRatio, gl.drawingBufferHeight / pixelRatio);
  const m = createMat2d();
  const mat = createViewportMatrix2d(m, view);
  translateAbsoluteMatToDrawingMat2d(mat, drawing);

  const shader = getShader(webgl, 'aiMaskOutpaint');
  gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, vm);
  gl.uniform1f(shader.uniforms.size, 4 / pixelRatio);
  gl.uniform4fv(shader.uniforms.color1, AI_SELECTION_COLOR_1_FLOAT);
  gl.uniform4fv(shader.uniforms.color2, AI_SELECTION_COLOR_2_FLOAT);
  gl.uniform1f(shader.uniforms.time, TESTS ? 0 : performance.now() / (40 * pixelRatio));
  gl.uniform1f(shader.uniforms.width, width);
  gl.uniform1f(shader.uniforms.height, height);

  bindTexture(gl, 0, texture);
  pushQuadTransformed(webgl.batch, mat, bounds.x, bounds.y, texture.width, texture.height, 0, 0, 1, 1, 0, 0, 1, 0, 0, 0.5);
  flushBatch(webgl.batch);

  unbindTexture(gl, 0);

  releaseTexture(webgl, texture);
}

function drawSelection(webgl: WebGLResources, drawing: Drawing, user: User, selection: Mask, view: Viewport) {
  const { gl, batch } = webgl;

  if (selection?.poly && !isMaskEmpty(selection)) {
    const pixelRatio = getPixelRatio();
    const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);

    const shader = getShader(webgl, 'ants');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
    gl.uniform1f(shader.uniforms.size, 4 * pixelRatio);
    gl.uniform1f(shader.uniforms.time, Math.floor(performance.now() / 250));

    const mat = createViewportMatrix2d(tempMat, view);
    translateAbsoluteMatToDrawingMat2d(mat, drawing);
    multiplyMat2d(mat, mat, user.surface.transform);

    // TODO: cache poly batch
    pushPoly(batch, selection.poly, mat, true, 1 * pixelRatio);
    flushBatch(batch);
  }
}

export function pushRect(batch: TriangleBatch, x: number, y: number, w: number, h: number, r: number, g: number, b: number, a: number) {
  pushQuad(batch, x, y, w, 1, 0, 0, 0, 0, 0, 0, r, g, b, a); // top
  pushQuad(batch, x, y + h - 1, w, 1, 0, 0, 0, 0, 0, 0, r, g, b, a); // bottom
  pushQuad(batch, x, y + 1, 1, h - 2, 0, 0, 0, 0, 0, 0, r, g, b, a); // left
  pushQuad(batch, x + w - 1, y + 1, 1, h - 2, 0, 0, 0, 0, 0, 0, r, g, b, a); // right
}

function pushSolidTransformedRect(batch: TriangleBatch, mat: Mat2d, x: number, y: number, w: number, h: number, r: number, g: number, b: number, a: number) {
  pushTransformedQuad(batch, x, y, w, h, 0, 0, 0, 0, r, g, b, a, mat);
}

function drawTransformControl(batch: TriangleBatch, cx: number, cy: number) {
  const size = 7;
  const x = round5(cx) - size / 2;
  const y = round5(cy) - size / 2;
  pushRect(batch, x - 1, y - 1, size + 2, size + 2, 1, 1, 1, 1); // white
  pushRect(batch, x, y, size, size, 0, 0, 0, 1); // black
}

function drawAiControl(batch: TriangleBatch, mat: Mat2d, cx: number, cy: number, color: Float32Array, view: Viewport) {
  const size = 8 / view.scale;
  const x = Math.round(cx) - size / 2;
  const y = Math.round(cy) - size / 2;

  pushSolidTransformedRect(batch, mat, x, y, size, size, color[0], color[1], color[2], color[3]);
  pushSolidTransformedRect(batch, mat, x + 1 / view.scale, y + 1 / view.scale, size - 2 / view.scale, size - 2 / view.scale, 1, 1, 1, 1);
}

function drawTransform(webgl: WebGLResources, user: User, drawing: Drawing, view: Viewport) {
  const { gl, batch } = webgl;
  const pixelRatio = getPixelRatio();
  const mat = createViewportMatrix2d(tempMat, view);
  translateAbsoluteMatToDrawingMat2d(mat, drawing);

  const bounds = getTransformBounds(user, drawing, mat);
  let viewMatrix = identityViewMatrix(gl.drawingBufferWidth / pixelRatio, gl.drawingBufferHeight / pixelRatio);

  { // transform cage
    const shader = getShader(webgl, 'ants');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
    gl.uniform1f(shader.uniforms.size, 3);
    gl.uniform1f(shader.uniforms.time, 0);
    pushPolygon(batch, bounds, true, 1);
    flushBatch(batch);
  }

  { // control points
    const shader = getShader(webgl, 'vertexColor');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

    for (let i = 0; i < 4; i++) {
      const pt = bounds[i];
      drawTransformControl(batch, pt[0], pt[1]);
      const pt2 = bounds[(i + 1) % 4];
      drawTransformControl(batch, (pt[0] + pt2[0]) / 2, (pt[1] + pt2[1]) / 2);
    }
    flushBatch(batch);
  }

  viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);
  getTransformOrigin(tempVec, bounds, user, mat);
  const centerX = round5(tempVec[0] * pixelRatio);
  const centerY = round5(tempVec[1] * pixelRatio);

  { // transform origin circle
    const shader = getShader(webgl, 'circleOutline');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
    gl.uniform1f(shader.uniforms.lineWidth, 1.5 * pixelRatio);

    {
      const circleSize = 8;
      const radius = (circleSize / 2) * pixelRatio;
      const radiusWithBorder = Math.ceil(radius + 2);
      const size = radiusWithBorder * 2;
      const x = centerX - radiusWithBorder;
      const y = centerY - radiusWithBorder;
      pushQuad(batch, x, y, size, size, 0, 0, 1, 1, radiusWithBorder, radiusWithBorder / radius, 1, 1, 1, 1); // white
    }

    {
      const circleSize = 6;
      const radius = (circleSize / 2) * pixelRatio;
      const radiusWithBorder = Math.ceil(radius + 2);
      const size = radiusWithBorder * 2;
      const x = centerX - radiusWithBorder;
      const y = centerY - radiusWithBorder;
      pushQuad(batch, x, y, size, size, 0, 0, 1, 1, radiusWithBorder, radiusWithBorder / radius, 0, 0, 0, 1); // black
    }

    flushBatch(batch);
  }

  { // transform origin lines
    const pr = pixelRatio;
    const shader = getShader(webgl, 'vertexColor');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

    pushQuad(batch, centerX - 0.5 * pr, centerY - 7.5 * pr, 1 * pr, 4 * pr, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1); // top
    pushQuad(batch, centerX - 0.5 * pr, centerY + 3.5 * pr, 1 * pr, 4 * pr, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1); // bottom
    pushQuad(batch, centerX - 7.5 * pr, centerY - 0.5 * pr, 4 * pr, 1 * pr, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1); // left
    pushQuad(batch, centerX + 3.5 * pr, centerY - 0.5 * pr, 4 * pr, 1 * pr, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1); // right

    pushQuad(batch, centerX - 0.5 * pr, centerY - 6.5 * pr, 1 * pr, 3 * pr, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1); // top
    pushQuad(batch, centerX - 0.5 * pr, centerY + 3.5 * pr, 1 * pr, 3 * pr, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1); // bottom
    pushQuad(batch, centerX - 6.5 * pr, centerY - 0.5 * pr, 3 * pr, 1 * pr, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1); // left
    pushQuad(batch, centerX + 3.5 * pr, centerY - 0.5 * pr, 3 * pr, 1 * pr, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1); // right

    flushBatch(batch);
  }
}

function drawCropOverlay(webgl: WebGLResources, view: Viewport, bounds: Rect, mode: CropOverlay, color: Float32Array) {
  const { gl, batch } = webgl;
  const ratio = getPixelRatio();
  const viewMatrix = identityViewMatrix(gl.drawingBufferWidth / ratio, gl.drawingBufferHeight / ratio);
  const mat = createViewportMatrix2d(tempMat, view);

  const { x, y, w, h } = bounds;

  const shader = getShader(webgl, 'line');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
  gl.uniform1f(shader.uniforms.pixelSize, 1);

  switch (mode) {
    case CropOverlay.Diagonal:
      pushTransfomedAntialiasedLine(batch, x, y, x + w, y + h, 1, color, mat);
      pushTransfomedAntialiasedLine(batch, x, y + h, x + w, y, 1, color, mat);
      break;
    case CropOverlay.RuleOfThirds:
      pushTransfomedAntialiasedLine(batch, x + w / 3, y, x + w / 3, y + h, 1, color, mat);
      pushTransfomedAntialiasedLine(batch, x + 2 * w / 3, y, x + 2 * w / 3, y + h, 1, color, mat);
      pushTransfomedAntialiasedLine(batch, x, y + h / 3, x + w, y + h / 3, 1, color, mat);
      pushTransfomedAntialiasedLine(batch, x, y + 2 * h / 3, x + w, y + 2 * h / 3, 1, color, mat);
      break;
    case CropOverlay.Disabled:
      break;
    default: invalidEnum(mode);
  }

  flushBatch(batch);
}

function drawAiBoundingBox(webgl: WebGLResources, view: Viewport, drawing: Drawing, options: DrawOptions) {
  const tool = options.selectedTool as AiTool;
  const { gl, batch } = webgl;
  const pixelRatio = getPixelRatio();
  const mat = createViewportMatrix2d(tempMat, view);
  const viewMatrix = identityViewMatrix(gl.drawingBufferWidth / pixelRatio, gl.drawingBufferHeight / pixelRatio);
  const bounds = cloneRect(tool.bounds);
  absoluteDocumentToDocumentRect(bounds, drawing);
  if (tool.showMask) {
    const maskBounds = cloneRect(bounds);
    clipRect(maskBounds, 0, 0, drawing.w, drawing.h);
    const { x, y, w, h } = maskBounds;
    const shader = getShader(webgl, 'vertexColor');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

    pushQuadTransformed(batch, mat, x, y, w, -y, 0, 0, 1, 1, 0, 0, 0, 0, 0, AI_BOUNDING_BOX_MASK_ALPHA);
    pushQuadTransformed(batch, mat, x, y + h, w, drawing.h - (y + h), 0, 0, 1, 1, 0, 0, 0, 0, 0, AI_BOUNDING_BOX_MASK_ALPHA);
    pushQuadTransformed(batch, mat, 0, 0, x, drawing.h, 0, 0, 1, 1, 0, 0, 0, 0, 0, AI_BOUNDING_BOX_MASK_ALPHA);
    pushQuadTransformed(batch, mat, w + x, 0, drawing.w - w - x, drawing.h, 0, 0, 1, 1, 0, 0, 0, 0, 0, AI_BOUNDING_BOX_MASK_ALPHA);

    flushBatch(batch);
  }

  if (tool.isActive) {
    drawAnimatedFrame(webgl, view, bounds, AI_BOUNDING_BOX_COLOR_1_ACTIVE, AI_BOUNDING_BOX_COLOR_2_ACTIVE, 2);
  } else {
    drawSolidFrame(webgl, view, bounds, AI_BOUNDING_BOX_COLOR_2_ACTIVE, WHITE_FLOAT, 1);
  }

  if (!tool.isActive) { // control points
    const { x, y, w, h } = bounds;
    const shader = getShader(webgl, 'vertexColor');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

    drawAiControl(batch, mat, x, y, AI_BOUNDING_BOX_COLOR_2_ACTIVE, view);
    drawAiControl(batch, mat, x, y + h, AI_BOUNDING_BOX_COLOR_2_ACTIVE, view);
    drawAiControl(batch, mat, x + w, y, AI_BOUNDING_BOX_COLOR_2_ACTIVE, view);
    drawAiControl(batch, mat, x + w, y + h, AI_BOUNDING_BOX_COLOR_2_ACTIVE, view);

    flushBatch(batch);
  }
}

function drawTextarea(webgl: WebGLResources, drawing: Drawing, textarea: Textarea, view: Viewport, options: DrawOptions) {
  if (options.selectedTool?.id === ToolId.Text) {
    const textTool = (options.selectedTool as TextTool);
    if (shouldRenderTextareaBoundaries(textarea, textTool, options)) drawTextareaBoundaries(webgl, drawing, view, textarea);
    if (shouldRenderTextareaCursor(textarea, textTool, options)) {
      const selection = textTool.textSelection;
      if (selection) {
        const { caretRect, selectionRects } = textarea.getCursorPosition(selection);
        if (caretRect) drawTextareaCaret(webgl, drawing, view, caretRect, textarea.transform);
        if (selectionRects.length > 0) drawTextareaSelectionRects(webgl, drawing, view, selectionRects, textarea.transform);
      }
    }
    if (shouldRenderTextareaBaselineIndicator(textarea, textTool, options)) drawTextareaBaselineIndicator(webgl, drawing, view, textarea);
    if (shouldRenderTextareaControlPoints(textarea, textTool, options)) drawTextareaControlPoints(webgl, drawing, view, textarea);
    if (shouldRenderTextareaOverflowIndicator(textarea, textTool, options)) drawTextareaOverflowIndicator(webgl, drawing, view, textarea);
  } else {
    if (options.selectedTool?.id !== ToolId.Transform) drawTextareaBoundaries(webgl, drawing, view, textarea, TEXTAREA_UNHOVERED_BOUNDARIES_WIDTH);
  }
}

function drawSolidFrame(webgl: WebGLResources, view: Viewport, rect: Rect, color1: Float32Array, color2: Float32Array, thickness: number, thickness2?: number) {
  const { gl, batch } = webgl;
  const ratio = getPixelRatio();
  const viewMatrix = identityViewMatrix(gl.drawingBufferWidth / ratio, gl.drawingBufferHeight / ratio);
  const mat = createViewportMatrix2d(tempMat, view);

  const shader = getShader(webgl, 'line');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
  gl.uniform1f(shader.uniforms.pixelSize, 1);

  pushTransfomedAntialiasedRect(batch, rect, thickness, color1, mat, 0);
  pushTransfomedAntialiasedRect(batch, rect, (thickness2 ?? thickness), color2, mat, 0.5);

  flushBatch(batch);
}

function drawTransformedSolidFrame(webgl: WebGLResources, view: Viewport, drawing: Drawing, transform: Float32Array, rect: Rect, color1: Float32Array, color2: Float32Array, thickness: number) {
  const { gl, batch } = webgl;
  const ratio = getPixelRatio();
  const viewMatrix = identityViewMatrix(gl.drawingBufferWidth / ratio, gl.drawingBufferHeight / ratio);
  const mat = createViewportMatrix2d(tempMat, view);
  translateAbsoluteMatToDrawingMat2d(mat, drawing);
  multiplyMat2d(mat, mat, transform);

  const shader = getShader(webgl, 'line');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
  gl.uniform1f(shader.uniforms.pixelSize, 1);

  pushTransfomedAntialiasedRect(batch, rect, thickness, color1, mat, 0);
  pushTransfomedAntialiasedRect(batch, rect, 1, color2, mat, thickness-0.5);

  flushBatch(batch);
}

function drawAnimatedFrame(webgl: WebGLResources, view: Viewport, rect: Rect, color1: Float32Array, color2: Float32Array, thickness: number) {
  const { gl, batch } = webgl;
  const ratio = getPixelRatio();
  const viewMatrix = identityViewMatrix(gl.drawingBufferWidth / ratio, gl.drawingBufferHeight / ratio);
  const mat = createViewportMatrix2d(tempMat, view);

  const bounds = [createVec2(), createVec2(), createVec2(), createVec2()];
  rectToBounds(bounds, rect);
  if (mat) transformBounds(bounds, mat);

  const shader = getShader(webgl, 'antsAi');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);
  gl.uniform1f(shader.uniforms.time, Math.floor(performance.now() / 20));
  gl.uniform1f(shader.uniforms.size, 4);
  gl.uniform4fv(shader.uniforms.color1, color1);
  gl.uniform4fv(shader.uniforms.color2, color2);

  pushPolygon(batch, bounds, true, thickness);
  flushBatch(batch);
}

function drawTextareaBoundaries(webgl: WebGLResources, drawing: Drawing, view: Viewport, textarea: Textarea, forceBoundariesThickness?: number, color?: string) {
  const { gl, batch } = webgl;
  const pixelSize = 1 / view.scale / getPixelRatio();
  const viewMatrix = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);

  const shader = getShader(webgl, 'line');
  gl.useProgram(shader.program);
  gl.uniform1f(shader.uniforms.pixelSize, pixelSize);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

  const thickness = forceBoundariesThickness ?? textarea.boundariesStrokeWidth;
  let r: number, g: number, b: number, a: number;
  if (!color) {
    const textareaColor = textarea.boundariesStrokeColor;
    r = textareaColor.r; g = textareaColor.g;
    b = textareaColor.b; a = textareaColor.a;
  } else {
    const textareaColor = colorToRGBA(parseColor(color));
    r = textareaColor.r / 255; g = textareaColor.g / 255;
    b = textareaColor.b / 255; a = textareaColor.a / 255;
  }

  const blueBounds = cloneBounds(textarea.bounds);
  const whiteBounds = cloneBounds(textarea.bounds);
  outsetBounds(whiteBounds, pixelSize);

  createViewportMatrix2d(tempMat, view);
  translateAbsoluteMatToDrawingMat2d(tempMat, drawing);

  pushTextareaBoundry(batch, whiteBounds, tempMat, thickness, 1, 1, 1, a);
  pushTextareaBoundry(batch, blueBounds, tempMat, thickness, r, g, b, a);

  flushBatch(batch);
}

function pushTextareaBoundry(batch: TriangleBatch, bounds: Vec2[], viewMatrix: Mat2d, thickness: number, r: number, g: number, b: number, a: number) {
  const ratio = getPixelRatio();
  for (const point of bounds) { transformVec2ByMat2d(point, point, viewMatrix); }
  pushAntialiasedLine(batch, bounds[0][0] * ratio, bounds[0][1] * ratio, bounds[1][0] * ratio, bounds[1][1] * ratio, thickness, r, g, b, a);
  pushAntialiasedLine(batch, bounds[1][0] * ratio, bounds[1][1] * ratio, bounds[2][0] * ratio, bounds[2][1] * ratio, thickness, r, g, b, a);
  pushAntialiasedLine(batch, bounds[2][0] * ratio, bounds[2][1] * ratio, bounds[3][0] * ratio, bounds[3][1] * ratio, thickness, r, g, b, a);
  pushAntialiasedLine(batch, bounds[3][0] * ratio, bounds[3][1] * ratio, bounds[0][0] * ratio, bounds[0][1] * ratio, thickness, r, g, b, a);
}

function drawTextareaCaret(webgl: WebGLResources, drawing: Drawing, view: Viewport, caret: Rect, transform: Mat2d) {
  const { gl, batch } = webgl;
  const pixelSize = 1 / view.scale;

  const viewMatrix = createViewportMatrix2d(tempMat2, view);
  const mat4 = identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight);

  const shader = getShader(webgl, 'line');
  gl.useProgram(shader.program);
  gl.uniform1f(shader.uniforms.pixelSize, pixelSize);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, mat4);

  const p1 = createVec2FromValues(caret.x + caret.w / 2 - drawing.x, caret.y - drawing.y);
  transformVec2ByMat2d(p1, p1, transform);
  transformVec2ByMat2d(p1, p1, viewMatrix);

  const p2 = createVec2FromValues(caret.x + caret.w / 2 - drawing.x, caret.y + caret.h - drawing.y);
  transformVec2ByMat2d(p2, p2, transform);
  transformVec2ByMat2d(p2, p2, viewMatrix);

  const ratio = getPixelRatio();
  const thickness = clamp(distance(p1[0], p1[1], p2[0], p2[1]) * 0.05, MIN_TEXTAREA_CURSOR_WIDTH, MAX_TEXTAREA_CURSOR_WIDTH);

  pushAntialiasedLine(batch, p1[0] * ratio, p1[1] * ratio, p2[0] * ratio, p2[1] * ratio, thickness * ratio, 0, 0, 0, 1);

  flushBatch(batch);
}

function drawTextareaSelectionRects(webgl: WebGLResources, drawing: Drawing, view: Viewport, selectionRects: Rect[], transform: Mat2d) {
  const { gl, batch } = webgl;
  const pixelSize = 1 / view.scale;
  const mat4 = createViewportMatrix4(tempMat4, view);
  translateMat4(tempMat4, tempMat4, -drawing.x, -drawing.y, 0);

  const shader = getShader(webgl, 'vertexColor');
  gl.useProgram(shader.program);
  gl.uniform1f(shader.uniforms.pixelSize, pixelSize);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, mat4);

  const { r, g, b, a } = TEXTAREA_SELECTION_RECT_COLOR;
  gl.blendFunc(gl.DST_COLOR, gl.ZERO);
  for (const rect of selectionRects) {
    pushQuadTransformed(batch, transform, rect.x, rect.y, rect.w, rect.h, 0, 0, 1, 1, 0, 0, r * a, g * a, b * a, a);
  }
  flushBatch(batch);
  gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
}

function drawTextareaControlPoints(webgl: WebGLResources, drawing: Drawing, view: Viewport, textarea: Textarea) {
  const { gl, batch } = webgl;
  const pixelSize = 1 / view.scale;
  const ratio = getPixelRatio();
  const viewMatrix = identityViewMatrix(gl.drawingBufferWidth / ratio, gl.drawingBufferHeight / ratio);

  const shader = getShader(webgl, 'vertexColor');
  gl.useProgram(shader.program);
  gl.uniform1f(shader.uniforms.pixelSize, pixelSize);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

  for (const controlPoint of textarea.controlPoints) {
    const { blueRect, whiteRect, thickness } = controlPoint.getDrawingInstructions(view, -drawing.x, -drawing.y);
    pushQuad(batch, whiteRect.x, whiteRect.y, whiteRect.w, whiteRect.h, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);
    pushQuad(batch, blueRect.x, blueRect.y, blueRect.w, blueRect.h, 0, 0, 0, 0, 0, 0, 46 / 255, 109 / 255, 1, 1);
    if (!controlPoint.active) pushQuad(batch, blueRect.x + thickness, blueRect.y + thickness, blueRect.w - 2 * thickness, blueRect.h - 2 * thickness, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);
  }

  flushBatch(batch);
}

function drawTextareaBaselineIndicator(webgl: WebGLResources, drawing: Drawing, view: Viewport, textarea: Textarea) {
  const { gl, batch } = webgl;
  const ratio = getPixelRatio();
  const pixelSize = 1 / view.scale;
  const viewMatrix = identityViewMatrix(gl.drawingBufferWidth / ratio, gl.drawingBufferHeight / ratio);

  const baselineIndicator = textarea.getBaselineIndicator();

  for (const entry of baselineIndicator) {
    {
      const [lineStart, lineEnd] = entry.line;
      // blue line on glyphs baseline
      const shader = getShader(webgl, 'line');
      gl.useProgram(shader.program);
      gl.uniform1f(shader.uniforms.pixelSize, pixelSize);
      gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

      absoluteDocumentToDocuemnt(lineStart, drawing);
      absoluteDocumentToDocuemnt(lineEnd, drawing);

      documentToScreenPoints([lineStart, lineEnd], view);
      const thickness = textarea.isHovering ? TEXTAREA_BASELINE_INDICATOR_HOVERED_LINE_THICKNESS : TEXTAREA_BASELINE_INDICATOR_UNHOVERED_LINE_THICKNESS;
      const { r, g, b, a } = textarea.boundariesStrokeColor;

      pushAntialiasedLine(batch, lineStart.x, lineStart.y, lineEnd.x, lineEnd.y, thickness, r, g, b, a);
      flushBatch(batch);
    }

    {
      const { alignmentSquare } = entry;
      if (alignmentSquare) {
        // blue square indicating how paragraph is aligned
        const shader = getShader(webgl, 'vertexColor');
        gl.useProgram(shader.program);
        gl.uniform1f(shader.uniforms.pixelSize, pixelSize);
        gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

        const squareSize = getBaselineIndicatorAlignmentSquareSize(view);

        const mat2 = createViewportMatrix2d(tempMat2, view);
        pushQuadTransformed(batch, mat2, alignmentSquare.x - squareSize / 2 - drawing.x, alignmentSquare.y - squareSize / 2 - drawing.y, squareSize, squareSize, 0, 0, 0, 0, 0, 0, 46 / 255, 109 / 255, 1, 1);
        flushBatch(batch);
      }
    }
  }
}

function drawTextareaOverflowIndicator(webgl: WebGLResources, drawing: Drawing, view: Viewport, textarea: Textarea) {
  const point = textarea.getOverflowIndicator();
  absoluteDocumentToDocuemnt(point, drawing);
  documentToScreenPoint(point, view);
  const { x, y } = point;

  const ratio = getPixelRatio();
  const size = TEXTAREA_OVERFLOW_INDICATOR_SQUARE_SIZE / ratio;

  const { gl, batch } = webgl;
  const pixelSize = 1 / view.scale;
  const viewMatrix = identityViewMatrix(gl.drawingBufferWidth / ratio, gl.drawingBufferHeight / ratio);

  const shader = getShader(webgl, 'vertexColor');
  gl.useProgram(shader.program);
  gl.uniform1f(shader.uniforms.pixelSize, pixelSize);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMatrix);

  pushQuad(batch, x - size / 2 - 2, y - size / 2 - 2, size + 4, size + 4, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);

  const { r, g, b, a } = TEXTAREA_OVERFLOW_INDICATOR_RED;
  pushQuad(batch, x - size / 2, y - size / 2, size, size, 0, 0, 0, 0, 0, 0, r / 255, g / 255, b / 255, a / 255);

  const plusThickness = size * TEXTAREA_OVERFLOW_INDICATOR_PLUS_THICKNESS;
  const plusSize = size * TEXTAREA_OVERFLOW_INDICATOR_PLUS_SIZE;

  pushQuad(batch, x - plusThickness / 2, y - plusSize / 2, plusThickness, plusSize, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);
  pushQuad(batch, x - plusSize / 2, y - plusThickness / 2, plusSize, plusThickness, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1);

  flushBatch(batch);
}

export function initializeWebGL(canvas: HTMLCanvasElement, gg?: WebGLResources): WebGLResources {
  const { gl, webgl2 } = gg ?? getWebGLContext(canvas);

  const webgl: WebGLResources = {
    name: gg?.name || '',
    gl,
    webgl2,
    shaders: gg?.shaders ?? new Map(),
    gaussianBlurShaders: gg?.gaussianBlurShaders ?? new Map(),
    emptyTexture: undefined as any,
    whiteTexture: undefined as any,
    frameBuffer: undefined as any,
    batch: undefined as any,
    tempCanvas: undefined as any,
    textures: gg?.textures ?? [],
    allocatedTextures: gg?.allocatedTextures ?? [],
    thumbnailTexture: undefined as any,
    namePlatesTexture: undefined,
    videoPlatesTexture: undefined,
    selfVideoTexture: undefined,
    namePlatesMode: CursorsMode.None,
    vertexShader: gg?.vertexShader as any,
    brushCache: gg?.brushCache || [],
    markers: [],
    maskTexture: undefined,
    maskCacheId: -1,
    maskRect: createRect(0, 0, 0, 0),
    highp: false,
    fallbackCursorsTexture: undefined,
    spritesTexture: undefined,
    frameBufferWidth: -1,
    frameBufferHeight: -1,
    texturesToDebug: [],
    cropLabelTexture: undefined,
    cropLabelTextureRect: createRect(0, 0, 0, 0),
    params: {
      maxTextureSize: 0,
      maxTextureUnits: 0
    },
    benchmarkTexture: undefined,
    tempDebugTexture: undefined
  };

  try {
    if (!gg) {
      webgl.vertexShader = createWebGLShader(gl, gl.VERTEX_SHADER, vertexSource, true);

      if (DEVELOPMENT && !SERVER) {
        // compile all shaders to check for errors
        for (const key of Object.keys(shaderSources)) {
          getShader(webgl, key);
        }
      }
    }

    webgl.highp = !!gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_FLOAT)?.precision;
    webgl.params.maxTextureSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);
    webgl.params.maxTextureUnits = gl.getParameter(gl.MAX_TEXTURE_IMAGE_UNITS);
    webgl.emptyTexture = gg?.emptyTexture ?? createEmptyTexture(webgl, 1, 1, new Uint8Array([0, 0, 0, 0]));
    webgl.whiteTexture = gg?.whiteTexture ?? createEmptyTexture(webgl, 1, 1, new Uint8Array([255, 255, 255, 255]));
    webgl.spritesTexture = gg?.spritesTexture ?? createSpriteTexture(webgl, getSpritesBitmap());

    const frameBuffer = gg?.frameBuffer ?? gl.createFramebuffer();
    if (!frameBuffer) throw new Error('Failed to create frame buffer');
    webgl.frameBuffer = frameBuffer;

    // TODO: check if these can be shared
    webgl.batch = /*gg?.batch ??*/ createBatch(gl, 8192, SERVER ? 2 : 8, SERVER ? 16 : 32);
    webgl.tempCanvas = gg?.tempCanvas ?? createCanvas(1, 1);

    if (!gg || TESTS) {
      webgl.thumbnailTexture = createEmptyTexture(webgl, 1, 1);
    }
  } catch (e) {
    logAction(`initializeWebGL:error ${e && e.message}`);
    releaseWebGL(webgl);
    throw e;
  }

  return webgl;
}

export function releaseWebGL(webgl: WebGLResources, shared = false) {
  const { gl, shaders, gaussianBlurShaders, frameBuffer, textures, batch,
    vertexShader, brushCache } = webgl;

  if (!shared) {
    for (const key of Array.from(shaders.keys())) gl.deleteProgram(shaders.get(key)!.program);
    for (const key of Array.from(gaussianBlurShaders.keys())) gl.deleteProgram(gaussianBlurShaders.get(key)!.program);
    for (const texture of textures) deleteTexture(gl, texture);
    for (const brush of brushCache) deleteTexture(gl, brush.texture);

    deleteTexture(gl, webgl.emptyTexture);
    deleteTexture(gl, webgl.whiteTexture);
    deleteTexture(gl, webgl.spritesTexture); webgl.spritesTexture = undefined;
    frameBuffer && gl.deleteFramebuffer(frameBuffer);
    vertexShader && gl.deleteShader(vertexShader);
  }

  deleteTexture(gl, webgl.thumbnailTexture);
  deleteTexture(gl, webgl.namePlatesTexture); webgl.namePlatesTexture = undefined;
  deleteTexture(gl, webgl.maskTexture); webgl.maskTexture = undefined;
  deleteTexture(gl, webgl.fallbackCursorsTexture); webgl.fallbackCursorsTexture = undefined;
  deleteTexture(gl, webgl.selfVideoTexture); webgl.selfVideoTexture = undefined;
  deleteTexture(gl, webgl.videoPlatesTexture); webgl.videoPlatesTexture = undefined;

  deleteTexture(gl, webgl.tempDebugTexture);
  deleteTexture(gl, webgl.benchmarkTexture);

  batch && releaseBatch(batch);

  webgl.shaders = new Map();
  webgl.gaussianBlurShaders = new Map();
  webgl.textures = [];
  webgl.allocatedTextures = [];
  webgl.brushCache = [];
  webgl.frameBuffer = null as any;
  webgl.emptyTexture = null as any;
  webgl.whiteTexture = null as any;
  webgl.vertexShader = null as any;

  if (webgl.pendingLayerThumb) {
    deleteBuffer(gl, webgl.pendingLayerThumb.buffer);
    webgl.pendingLayerThumb = undefined;
  }

  if (webgl.pendingDrawingThumb) {
    deleteBuffer(gl, webgl.pendingDrawingThumb.buffer);
    webgl.pendingDrawingThumb = undefined;
  }
}

const shadersDraw = new Map<string, string>();
const shadersDrawAndMask = new Map<string, string>();

for (const mode of LAYER_MODES) {
  if (mode) {
    shadersDraw.set(mode, `${mode}WithDraw`);
    shadersDrawAndMask.set(mode, `${mode}WithDrawAndMask`);
  }
}

function getShaderNameForMode(mode: string, toolMode: CompositeOp, twoMasks: boolean): string {
  switch (toolMode) {
    case CompositeOp.None:
      return mode;
    case CompositeOp.Erase:
    case CompositeOp.Draw:
    case CompositeOp.Move:
      if (twoMasks) {
        return shadersDrawAndMask.get(mode)!;
      } else {
        return shadersDraw.get(mode)!;
      }
    // TODO: add separate shader for CompositeOperation.Move
    default:
      return invalidEnum(toolMode);
  }
}

function getShaderForMode(webgl: WebGLResources, mode: string, toolMode: CompositeOp, hasMasks: boolean) {
  const name = getShaderNameForMode(mode, toolMode, hasMasks);
  return getShader(webgl, name);
}

function layerHasToolTexture(layer: Layer) {
  const owner = layer.owner;

  return owner !== undefined &&
    owner.surface.layer === layer &&
    !isSurfaceEmpty(owner.surface) &&
    !!owner.surface.texture;
}

const tempPixelBuffer = new Uint8Array(4);

export function copyCanvasToTexture(gl: WebGLRenderingContext, context: CanvasRenderingContext2D, texture: Texture, width: number, height: number, premultiply = false) {
  if (SERVER) {
    // server doesn't support interoperation between canvas and webgl
    const data = context.getImageData(0, 0, width, height);
    texSubImage2D(gl, texture, 0, 0, width, height, toUint8(data.data), false, premultiply);
  } else {
    // NOTE: this is not being tested (test manually)
    texSubImage2DImage(gl, texture, 0, 0, context.canvas, false, true);
  }
}

export function copyCanvasToTextureAt(gl: WebGLRenderingContext, context: CanvasRenderingContext2D, texture: Texture, x: number, y: number, premultiply = false) {
  if (SERVER) {
    // server doesn't support interoperation between canvas and webgl
    const { width, height } = context.canvas;
    const data = context.getImageData(0, 0, width, height);
    texSubImage2D(gl, texture, x, y, width, height, toUint8(data.data), false, premultiply);
  } else {
    // NOTE: this is not being tested (test manually)
    texSubImage2DImage(gl, texture, x, y, context.canvas, false, true);
  }
}

function drawMaskToTexture(webgl: WebGLResources, texture: Texture, selection: Mask, ox: number, oy: number) {
  // We're not using selection bounds here because the texture is assumend not clear.
  // We can't clear the texture because we're often creating masks when something is bounds to render target.
  drawUsingCanvas(webgl, texture, 0, 0, texture.width, texture.height, 0, (context, x0, y0) => {
    context.save();
    context.translate(ox - x0, oy - y0);
    fillMask(context, selection);
    context.restore();
  });
}

function createMaskTexture(webgl: WebGLResources, selection: Mask, x: number, y: number, w: number, h: number) {
  const { maskRect: r, maskTexture, maskCacheId } = webgl;

  if (maskTexture && maskCacheId === selection.cacheId && r.x === x && r.y === y && r.w === w && r.h === h) {
    return maskTexture;
  }

  releaseTexture(webgl, webgl.maskTexture);
  webgl.maskTexture = getTexture(webgl, w, h, 'mask', false);
  drawMaskToTexture(webgl, webgl.maskTexture, selection, -x, -y);
  setRect(webgl.maskRect, x, y, w, h);
  webgl.maskCacheId = selection.cacheId;
  return webgl.maskTexture;
}

function maskOutLayerRect(webgl: WebGLResources, layer: Layer, mask: Texture, maskRect: Rect, maskTextureX = 0, maskTextureY = 0) {
  const { gl, batch } = webgl;
  const { w, h } = layer.rect;

  if (!layer.texture) throw new Error('Missing texture');

  bindRenderTarget(webgl, layer.texture);
  gl.enable(gl.SCISSOR_TEST);
  gl.scissor(maskRect.x - layer.textureX, maskRect.y - layer.textureY, maskRect.w, maskRect.h);

  gl.enable(gl.BLEND);
  gl.blendEquation(gl.FUNC_ADD);
  gl.blendFunc(gl.ZERO, gl.ONE_MINUS_SRC_ALPHA);

  const { frameBufferWidth, frameBufferHeight } = webgl;

  const shader = getShader(webgl, 'basic');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, transformMatrixForSize(w, h));
  bindTexture(gl, 0, mask);

  const tx = layer.textureX - maskTextureX;
  const ty = layer.textureY - maskTextureY;

  pushQuad(batch,
    0, 0, w, h,
    tx / mask.width, ty / mask.height, frameBufferWidth / mask.width, frameBufferHeight / mask.height,
    0, 0, 1, 1, 1, 1
  );

  flushBatch(batch);
  unbindTexture(gl, 0);

  gl.disable(gl.BLEND);
  gl.disable(gl.SCISSOR_TEST);
  unbindRenderTarget(webgl);
}

function drawTexture(webgl: WebGLResources, texture: Texture, shaderName: 'unpremultiply' | 'basic', rect: Rect, textureX = 0, textureY = 0) {
  const { gl, batch, frameBufferWidth, frameBufferHeight } = webgl;
  const { x, y, w, h } = rect;
  const shader = getShader(webgl, shaderName);
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, transformMatrixForSize(w, h));
  bindTexture(gl, 0, texture);
  pushQuad(batch,
    0, 0, w, h,
    (x - textureX) / texture.width, (y - textureY) / texture.height, frameBufferWidth / texture.width, frameBufferHeight / texture.height,
    0, 0, 1, 1, 1, 1);

  flushBatch(batch);
  unbindTexture(gl, 0);
}

// TODO: combine mask* shaders
function drawMasked(
  webgl: WebGLResources, texture: Texture, mask: Texture, reverse: number, shaderName: 'mask' | 'maskUnpremultiply',
  x: number, y: number, w: number, h: number, tx: number, ty: number, mx: number, my: number
) {
  const { gl, batch } = webgl;
  const shader = getShader(webgl, shaderName);
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, frameBufferTransform(webgl));
  gl.uniform1f(shader.uniforms.reverse, reverse);
  bindTexture(gl, 0, texture);
  bindTexture(gl, 1, mask);

  pushQuad2(batch,
    x, y, w, h,
    mx / mask.width, my / mask.height, w / mask.width, h / mask.height, // mask
    tx / texture.width, ty / texture.height, w / texture.width, h / texture.height, // texture
    1, 1, 1, 1);

  flushBatch(batch);
  unbindTexture(gl, 1);
  unbindTexture(gl, 0);
}

function drawMaskedLayerRectTexture(
  webgl: WebGLResources, texture: Texture, mask: Texture, reverse: number, shaderName: 'mask' | 'maskUnpremultiply',
  rect: Rect, textureX = 0, textureY = 0
) {
  const { gl, batch, frameBufferWidth, frameBufferHeight } = webgl;
  const { x, y, w, h } = rect;
  const tw = texture.width;
  const th = texture.height;
  const shader = getShader(webgl, shaderName);
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, transformMatrixForSize(rect.w, rect.h));
  gl.uniform1f(shader.uniforms.reverse, reverse);
  bindTexture(gl, 0, texture);
  bindTexture(gl, 1, mask);

  pushQuad2(batch,
    0, 0, w, h,
    0, 0, frameBufferWidth / mask.width, frameBufferHeight / mask.height, // assumes that mask is aligned with bound texture
    (x - textureX) / tw, (y - textureY) / th, frameBufferWidth / tw, frameBufferHeight / th,
    1, 1, 1, 1);

  flushBatch(batch);
  unbindTexture(gl, 1);
  unbindTexture(gl, 0);
}



export function findTextureSize(size: number, gl2: boolean) {
  if (DEVELOPMENT && size <= 0) throw new Error(`Invalid texture size: ${size}`);

  if (TESTS) {
    return findPowerOf2(size);
  } else if (gl2) {
    return Math.max(256, findMultipleOf256(size));
  } else {
    return findPowerOf2(size);
  }
}

// TODO: remove, use findTextureSize directly
export function findTextureWidth(webgl: WebGLResources, width: number) {
  return findTextureSize(width, webgl.webgl2);
}

// TODO: remove, use findTextureSize directly
export function findTextureHeight(webgl: WebGLResources, height: number) {
  return findTextureSize(height, webgl.webgl2);
}

function ensureLayerTexture(webgl: WebGLResources, drawingBounds: Rect | undefined, layer: Layer, r: Rect) {
  const rect = cloneRect(r);

  if (drawingBounds) {
    if (DEVELOPMENT && !TESTS && (rect.w > getMaxLayerWidth(drawingBounds.w) || rect.h > getMaxLayerWidth(drawingBounds.h))) console.warn(`Reached layer size limit ${rect.w}x${rect.h}, limit ${getMaxLayerWidth(drawingBounds.w)}x${getMaxLayerHeight(drawingBounds.h)}`);
    clipSurfaceToLimits(rect, drawingBounds, getMaxLayerWidth(drawingBounds.w), getMaxLayerWidth(drawingBounds.h));
  }

  switchToFallbackRendererIfNeeded(webgl, rect.w, rect.h);

  let textureWidth = findTextureWidth(webgl, rect.w);
  let textureHeight = findTextureHeight(webgl, rect.h);
  if (IS_IE11) textureWidth = textureHeight = Math.max(textureWidth, textureHeight);

  if (
    !layer.texture ||
    layer.texture.width !== textureWidth ||
    layer.texture.height !== textureHeight ||
    rect.x < layer.textureX ||
    rect.y < layer.textureY ||
    (rect.x + rect.w) > (layer.textureX + layer.texture.width) ||
    (rect.y + rect.h) > (layer.textureY + layer.texture.height)
  ) {
    const newTexture = getTexture(webgl, textureWidth, textureHeight, `layer-${layer.id}`, true);
    const newTextureX = rect.x - Math.floor((newTexture.width - rect.w) / 2);
    const newTextureY = rect.y - Math.floor((newTexture.height - rect.h) / 2);

    if (layer.texture && !isRectEmpty(layer.rect) && !isRectEmpty(rect)) {
      const x = Math.max(rect.x, layer.rect.x);
      const y = Math.max(rect.y, layer.rect.y);
      const w = Math.min(rect.x + rect.w, layer.rect.x + layer.rect.w) - x;
      const h = Math.min(rect.y + rect.h, layer.rect.y + layer.rect.h) - y;

      if (w > 0 && h > 0) {
        copyTextureRect(webgl, layer.texture, newTexture, x - layer.textureX, y - layer.textureY, w, h, x - newTextureX, y - newTextureY);
      }
    }

    releaseTexture(webgl, layer.texture);
    layer.texture = newTexture;
    layer.textureX = newTextureX;
    layer.textureY = newTextureY;
  }

  copyRect(layer.rect, rect);
}

function releaseLayer(webgl: WebGLResources, layer: Layer) {
  layer.texture = releaseTexture(webgl, layer.texture);
  layer.textureX = 0;
  layer.textureY = 0;
  resetRect(layer.rect);
  layerChanged(layer);
}

export function getTexture(
  webgl: WebGLResources, width: number, height: number, id: string, clear = true, format = TextureFormat.RGBA
): Texture {
  const { gl, textures } = webgl;
  let texture: Texture | undefined = undefined;

  if (DEVELOPMENT && gl.getParameter(gl.FRAMEBUFFER_BINDING) && clear) {
    throw new Error('Getting texture while frame buffer is bound');
  }

  if (textures.length) {
    // prefer using last one returned to pool, because it's most likely to still be in memory
    for (let i = textures.length - 1; i >= 0; i--) {
      if (textures[i].width === width && textures[i].height === height && textures[i].format === format) {
        texture = textures[i];
        removeAtFast(textures, i);
        break;
      }
    }

    if (!texture && textures.length >= TEXTURE_POOL_SIZE) {
      // TODO: pick closest in size ?
      texture = textures.shift()!; // pick oldest one
      resizeTexture(webgl, texture, width, height, format);
    }

    if (texture && clear) clearTexture(webgl, texture);
  }

  if (!texture) {
    texture = createEmptyTexture(webgl, width, height, undefined, format);
  }

  texture.id = id;

  if (DEVELOPMENT) webgl.allocatedTextures.push(texture);

  return texture;
}

export function releaseTexture(webgl: WebGLResources | undefined, texture: Texture | undefined, skipCheck?: boolean): undefined {
  if (webgl && texture) {
    const { gl, textures } = webgl;

    if ((gl as any).webglId !== texture.webglId) {
      if (DEVELOPMENT) throw new Error(`Releasing texture from different context`);
      logAction(`releasing texture from different context`);
      return undefined;
    }

    if (DEVELOPMENT && !skipCheck) {
      const oldHandle = gl.getParameter(gl.TEXTURE_BINDING_2D);
      if (oldHandle === texture.handle) throw new Error('Releasing texture that is still bound');
      gl.bindTexture(gl.TEXTURE_2D, texture.handle);
      if (gl.getTexParameter(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER) !== gl.NEAREST) {
        throw new Error('Releasing texture with TEXTURE_MAG_FILTER not reset to NEAREST');
      }
      if (gl.getTexParameter(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER) !== gl.NEAREST) {
        throw new Error('Releasing texture with TEXTURE_MIN_FILTER not reset to NEAREST');
      }
      if (gl.getTexParameter(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S) !== gl.CLAMP_TO_EDGE) {
        throw new Error('Releasing texture with TEXTURE_WRAP_S not reset to CLAMP_TO_EDGE');
      }
      if (gl.getTexParameter(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T) !== gl.CLAMP_TO_EDGE) {
        throw new Error('Releasing texture with TEXTURE_WRAP_T not reset to CLAMP_TO_EDGE');
      }
      gl.bindTexture(gl.TEXTURE_2D, oldHandle);
    }
    if (DEVELOPMENT) removeItem(webgl.allocatedTextures, texture);

    if (textures.length < TEXTURE_POOL_SIZE) {
      textures.push(texture);
    } else {
      deleteTexture(gl, texture);
    }
  }

  return undefined;
}

function releaseSurfaceTexture(webgl: WebGLResources | undefined, surface: ToolSurface) {
  // reset filters in case it was switched to linear for transformed surfaces
  if (webgl && surface.texture && surface.textureIsLinear) {
    const { gl } = webgl;
    gl.bindTexture(gl.TEXTURE_2D, surface.texture.handle);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.bindTexture(gl.TEXTURE_2D, null);
  }

  surface.texture = releaseTexture(webgl, surface.texture);
}

function releaseSurface(webgl: WebGLResources | undefined, surface: ToolSurface) {
  releaseSurfaceTexture(webgl, surface);
  surface.textureMask = releaseTexture(webgl, surface.textureMask);
  surface.textureIsLinear = false;
  surface.canvasMask = undefined; // TODO: release
  resetSurface(surface);
}

function getLayerSnapshot(webgl: WebGLResources, layer: Layer, selectionMask?: Mask, outBounds?: Rect) {
  const { gl, emptyTexture } = webgl;
  const selection = layer.owner?.surface.ignoreSelection ? undefined : selectionMask;

  const layerRect = getLayerRect(layer);
  const bounds = selection ? selection.bounds : layerRect;

  if (isRectEmpty(bounds)) return undefined;
  if (!layerHasNonEmptyToolSurface(layer) && !layer.texture) return undefined;
  if (!layer.texture) return undefined;

  const canvas = createCanvas(bounds.w, bounds.h);
  const context = getContext2d(canvas);
  const data = context.createImageData(canvas.width, canvas.height);

  let textureWidth = findTextureWidth(webgl, bounds.w);
  let textureHeight = findTextureHeight(webgl, bounds.h);
  if (IS_IE11) textureWidth = textureHeight = Math.max(textureWidth, textureHeight);

  const temp = getTexture(webgl, textureWidth, textureHeight, 'temp-layer-snapshot', false);
  bindRenderTarget(webgl, temp);
  if (selection) {
    const { x, y, w, h } = bounds;
    const mask = createMaskTexture(webgl, selection, x, y, w, h);
    drawMaskedLayerRectTexture(webgl, layer.texture, mask, 0, 'maskUnpremultiply', bounds, layer.textureX, layer.textureY);
    gl.readPixels(0, 0, bounds.w, bounds.h, gl.RGBA, gl.UNSIGNED_BYTE, toUint8(data.data));
    unbindRenderTarget(webgl);
  } else {
    drawLayer(webgl, emptyTexture, layer, layer.opacityLocked, 1, true, false, bounds, bounds);
    unbindRenderTarget(webgl);
    readPixels(webgl, temp, toUint8(data.data), createRect(0, 0, bounds.w, bounds.h));
  }
  outBounds && copyRect(outBounds, bounds);

  releaseTexture(webgl, temp);
  context.putImageData(data, 0, 0);

  if (TESTS) (canvas as any).__raw = { width: canvas.width, height: canvas.height, data: toUint8(data.data) };

  return canvas;
}

function createFakeCanvas(webgl: WebGLResources, layer: Layer, rect: Rect): HTMLCanvasElement {
  function getImageDataInner(x: number, y: number, w: number, h: number) {
    let textureWidth = findTextureWidth(webgl, w);
    let textureHeight = findTextureHeight(webgl, h);
    if (IS_IE11) textureWidth = textureHeight = Math.max(textureWidth, textureHeight);

    const temp = getTexture(webgl, textureWidth, textureHeight, 'temp-fake', false);
    bindRenderTarget(webgl, temp, transparentColor);
    drawLayer(webgl, webgl.emptyTexture, layer, false, 1, true, false, rect, rect);
    unbindRenderTarget(webgl);
    const result = getImageData(webgl, temp, createRect(x, y, w, h));
    releaseTexture(webgl, temp);
    return result;
  }

  const canvas: any = { width: rect.w, height: rect.h };
  canvas.getContext = () => ({ getImageData: getImageDataInner, canvas });
  return canvas;
}

function readPixels(webgl: WebGLResources, texture: Texture, data: Uint8Array, rect: Rect, textureX = 0, textureY = 0) {
  const { gl } = webgl;
  const { w, h } = rect;

  let textureWidth = findTextureWidth(webgl, w);
  let textureHeight = findTextureHeight(webgl, h);
  if (IS_IE11) textureWidth = textureHeight = Math.max(textureWidth, textureHeight);

  const temp = getTexture(webgl, textureWidth, textureHeight, 'temp-read-pixels');
  bindRenderTarget(webgl, temp);

  drawTexture(webgl, texture, 'unpremultiply', rect, textureX, textureY);

  gl.readPixels(0, 0, w, h, gl.RGBA, gl.UNSIGNED_BYTE, data);
  unbindRenderTarget(webgl);
  releaseTexture(webgl, temp);
}

function getImageData(webgl: WebGLResources, texture: Texture, rect: Rect): ImageData {
  const data = allocUint8ClampedArray(rect.w, rect.h);
  readPixels(webgl, texture, toUint8(data), rect);
  return { width: rect.w, height: rect.h, data, colorSpace: 'srgb' };
}

function allocUint8ClampedArray(width: number, height: number) {
  try {
    return new Uint8ClampedArray(width * height * 4);
  } catch (e) {
    throw new Error(`Failed to allocate buffer [${width}x${height}] (${e.stack || e.message || e})`);
  }
}

function ensureSurface(webgl: WebGLResources, surface: ToolSurface, rect: Rect, localId: number) {
  if (surface.texture) {
    if (surface.texture.width === rect.w && surface.texture.height === rect.h) {
      clearTexture(webgl, surface.texture);
    } else { // we need different texture size (this can happen when pasting image larger than canvas)
      releaseSurfaceTexture(webgl, surface);
    }
  }

  switchToFallbackRendererIfNeeded(webgl, rect.w, rect.h);

  if (!surface.texture) {
    surface.texture = getTexture(webgl, rect.w, rect.h, `tool-${localId}`);
    surface.textureIsLinear = false;
  }

  copyRect(surface.rect, rect);
  surface.textureMask = releaseTexture(webgl, surface.textureMask);
  surface.texture.hasMipmaps = false;
  surface.canvasMask = undefined; // TODO: release
  surface.textureX = rect.x;
  surface.textureY = rect.y;
}


export function premultiply(data: Uint8Array | Uint8ClampedArray) {
  const t = 1 / 255;

  for (let i = 0; i < data.byteLength; i += 4) {
    const a = data[i + 3] * t;
    data[i + 0] = Math.round(data[i + 0] * a);
    data[i + 1] = Math.round(data[i + 1] * a);
    data[i + 2] = Math.round(data[i + 2] * a);
  }
}

export function premultiplyColor(array: Float32Array) {
  array[0] *= array[3];
  array[1] *= array[3];
  array[2] *= array[3];
  return array;
}

export function unpremultiply(data: Uint8Array | Uint8ClampedArray) {
  for (let i = 0; i < data.byteLength; i += 4) {
    let a = data[i + 3];
    a = a ? (255 / a) : 0;
    data[i + 0] = Math.round(data[i + 0] * a);
    data[i + 1] = Math.round(data[i + 1] * a);
    data[i + 2] = Math.round(data[i + 2] * a);
  }
}

export function getTempCanvas({ tempCanvas }: WebGLResources, width: number, height: number, clear: boolean) {
  if (SERVER) return createCanvas(width, height); // avoid issues with sharing canvas between users

  if (tempCanvas.width !== width || tempCanvas.height !== height || clear) {
    tempCanvas.width = width;
    tempCanvas.height = height;
  }

  return tempCanvas;
}

export function drawUsingCanvas(
  webgl: WebGLResources, target: Texture, x: number, y: number, w: number, h: number, pad: number,
  draw: (context: CanvasRenderingContext2D, x0: number, y0: number) => void
) {
  // limited because of issues on iOS
  const sizeLimit = SERVER ? 8192 : ((isSafari || isiOS) ? 2048 : 4096);

  const x0 = clamp(Math.floor(Math.min(x, x + w) - pad), 0, target.width);
  const y0 = clamp(Math.floor(Math.min(y, y + h) - pad), 0, target.height);
  const x1 = clamp(Math.ceil(Math.max(x, x + w) + pad), 0, target.width);
  const y1 = clamp(Math.ceil(Math.max(y, y + h) + pad), 0, target.height);

  for (let oy = y0; oy < y1; oy += Math.min(y1 - oy, sizeLimit)) {
    for (let ox = x0; ox < x1; ox += Math.min(x1 - ox, sizeLimit)) {
      const canvasWidth = Math.min(x1 - ox, sizeLimit);
      const canvasHeight = Math.min(y1 - oy, sizeLimit);
      const tempCanvas = getTempCanvas(webgl, canvasWidth, canvasHeight, true);
      const context = getContext2d(tempCanvas);
      context.save();
      draw(context, ox, oy);
      context.restore();
      copyCanvasToTextureAt(webgl.gl, context, target, ox, oy, true);
    }
  }
}

// drawing thumb

function pingThumb(webgl: WebGLResources, drawing: Drawing) {
  const { gl, webgl2 } = webgl;
  const gl2 = gl as WebGL2RenderingContext;

  if (webgl.pendingDrawingThumb && webgl2) {
    const { id, sync, rect, buffer } = webgl.pendingDrawingThumb;

    if (drawing.id === id) {
      const status = gl2.getSyncParameter(sync, gl2.SYNC_STATUS);
      if (status !== gl2.SIGNALED) return;

      const scale = Math.min(SEQUENCE_THUMB_WIDTH / drawing.w, SEQUENCE_THUMB_HEIGHT / drawing.h);
      const thumbWidth = Math.ceil(drawing.w * scale);
      const thumbHeight = Math.ceil(drawing.h * scale);
      const thumbRect = rect!;
      const data = new Uint8Array(thumbRect.w * thumbRect.h * 4);
      gl.bindBuffer(gl2.PIXEL_PACK_BUFFER, buffer);
      gl2.getBufferSubData(gl2.PIXEL_PACK_BUFFER, 0, data);
      gl.bindBuffer(gl2.PIXEL_PACK_BUFFER, null);

      // no need to unpremultiply because we always put white background behind thumbnail
      drawing.thumbUpdate = { data, rect: thumbRect, width: thumbWidth, height: thumbHeight };
    }

    deleteBuffer(gl, buffer);
    webgl.pendingDrawingThumb = undefined;
  }
}

function discardThumb(webgl: WebGLResources) {
  const { gl, webgl2 } = webgl;

  if (webgl.pendingDrawingThumb && webgl2) {
    deleteBuffer(gl, webgl.pendingDrawingThumb.buffer);
    webgl.pendingDrawingThumb = undefined;
  }
}

function drawScaled(webgl: WebGLResources, sourceTexture: Texture, scaledWidth: number, scaledHeight: number, sourceWidth: number, sourceHeight: number, output: CanvasRenderingContext2D) {
  const { gl, batch } = webgl;

  let textureWidth = findTextureWidth(webgl, scaledWidth);
  let textureHeight = findTextureHeight(webgl, scaledHeight);
  const tempTexture = getTexture(webgl, textureWidth, textureHeight, 'scaled', false);
  bindRenderTarget(webgl, tempTexture);

  const shader = getShader(webgl, 'basic');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, frameBufferTransform(webgl));

  const w = scaledWidth;
  const h = scaledHeight;
  const tw = sourceWidth / sourceTexture.width;
  const th = sourceHeight / sourceTexture.height;

  bindTexture(gl, 0, sourceTexture, true);
  pushQuad(batch, 0, 0, w, h, 0, 0, tw, th, 0, 0, 1, 1, 1, 1);
  flushBatch(batch);
  unbindTexture(gl, 0, true);

  const d = output.createImageData(scaledWidth, scaledHeight);
  const buffer = new Uint8Array(d.data.buffer);
  gl.readPixels(0, 0, scaledWidth, scaledHeight, gl.RGBA, gl.UNSIGNED_BYTE, buffer);
  output.putImageData(d, 0, 0);

  unbindRenderTarget(webgl);
  releaseTexture(webgl, tempTexture);
}

export function pushAntialiasedQuadInViewRect(batch: TriangleBatch, rect: Rect, ratio: number, view: Viewport, r: number, g: number, b: number) {
  pushAntialiasedQuadInView(batch, rect.x, rect.y, rect.w, rect.h, ratio, view, r, g, b);
}

export function pushAntialiasedQuadInView(batch: TriangleBatch, x: number, y: number, w: number, h: number, ratio: number, view: Viewport, r: number, g: number, b: number) {
  setPoint(tempPt, x, y);
  documentToScreenPoint(tempPt, view);
  const x1 = tempPt.x * ratio;
  const y1 = tempPt.y * ratio;
  setPoint(tempPt, x + w, y);
  documentToScreenPoint(tempPt, view);
  const x2 = tempPt.x * ratio;
  const y2 = tempPt.y * ratio;
  setPoint(tempPt, x + w, y + h);
  documentToScreenPoint(tempPt, view);
  const x3 = tempPt.x * ratio;
  const y3 = tempPt.y * ratio;
  setPoint(tempPt, x, y + h);
  documentToScreenPoint(tempPt, view);
  const x4 = tempPt.x * ratio;
  const y4 = tempPt.y * ratio;
  pushAntialiasedQuad(batch, x1, y1, x2, y2, x3, y3, x4, y4, ratio, r, g, b, 1);
}

export function drawDrawingBounds(webgl: WebGLResources, drawing: Drawing, view: Viewport) {
  const { gl, batch } = webgl;
  const ratio = getPixelRatio();

  const shader = getShader(webgl, 'line');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight));

  pushAntialiasedQuadInView(batch, 0, 0, drawing.w, drawing.h, ratio, view, 0, 0, 0);

  flushBatch(batch);
}

function drawPerspectiveGridBoundingBox(webgl: WebGLResources, view: Viewport, drawing: Drawing, options: DrawOptions, data: PerspectiveGridLayerData) {
  const { batch } = webgl;
  const mat = createViewportMatrix2d(tempMat, view);
  translateAbsoluteMatToDrawingMat2d(mat, drawing);
  const tool = options.selectedTool?.id == ToolId.PerspectiveGrid ? (options.selectedTool as PerspectiveGridTool) : undefined;
  const boundingBoxState = tool?.boundingBoxState ?? PerspectiveGridBoundingBoxState.Default;

  if (!isRectEmpty(data.bounds)) {
    const transform = tempMat2;
    copyMat2d(transform, data.transform);
    if (boundingBoxState === PerspectiveGridBoundingBoxState.Default) {
      drawTransformedSolidFrame(webgl, view, drawing, transform, data.bounds, PERSPECTIVE_GRID_COLOR_ARRAY_SELECTED, WHITE_FLOAT, PERSPECTIVE_GRID_BB_LINE_WIDTH_DEFAULT);
    } else if (boundingBoxState === PerspectiveGridBoundingBoxState.Hover) {
      drawTransformedSolidFrame(webgl, view, drawing, transform, data.bounds, PERSPECTIVE_GRID_COLOR_ARRAY_SELECTED, WHITE_FLOAT, PERSPECTIVE_GRID_BB_LINE_WIDTH_HOVER);
    }
    if (tool !== undefined) {
      const mat = createViewportMatrix2d(tempMat, view);
      translateAbsoluteMatToDrawingMat2d(mat, drawing);
      let bounds = tempBoundsVec;
      rectToBounds(bounds, data.bounds);
      transformBounds(bounds, transform);
      drawAiControl(batch, mat, bounds[0][0], bounds[0][1], PERSPECTIVE_GRID_COLOR_ARRAY_SELECTED, view);
      drawAiControl(batch, mat, bounds[1][0], bounds[1][1], PERSPECTIVE_GRID_COLOR_ARRAY_SELECTED, view);
      drawAiControl(batch, mat, bounds[2][0], bounds[2][1], PERSPECTIVE_GRID_COLOR_ARRAY_SELECTED, view);
      drawAiControl(batch, mat, bounds[3][0], bounds[3][1], PERSPECTIVE_GRID_COLOR_ARRAY_SELECTED, view);
    }
    flushBatch(batch);
  }
}

function drawPerspectiveGridHorizonLine(webgl: WebGLResources, viewMat: Float32Array, layer: PerspectiveGridLayer, selected: boolean) {
  const { gl, batch } = webgl;
  const data = layer.perspectiveGrid;
  const color = selected ? PERSPECTIVE_GRID_COLOR_ARRAY_SELECTED : PERSPECTIVE_GRID_COLOR_ARRAY_NORMAL;
  let shader = getShader(webgl, 'line');
  let horizonOriginX = 0.0;
  let horizonOriginY = 0.0;
  if (data.vpointScreenList.length == 1) {
    horizonOriginX = data.vpointScreenList[0].x;
    horizonOriginY = data.vpointScreenList[0].y;
  } else if (data.vpointScreenList.length >= 2) {
    horizonOriginX = (data.vpointScreenList[0].x + data.vpointScreenList[1].x) / 2;
    horizonOriginY = (data.vpointScreenList[0].y + data.vpointScreenList[1].y) / 2;
  }
  let r = 0;
  r = Math.max(distance(horizonOriginX, horizonOriginY, 0, 0), r);
  r = Math.max(distance(horizonOriginX, horizonOriginY, gl.drawingBufferWidth, 0), r);
  r = Math.max(distance(horizonOriginX, horizonOriginY, gl.drawingBufferWidth, gl.drawingBufferHeight), r);
  r = Math.max(distance(horizonOriginX, horizonOriginY, 0, gl.drawingBufferHeight), r);
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMat);
  pushAntialiasedLine(batch,
    Math.floor(horizonOriginX - data.horizonDir.x * r),
    Math.floor(horizonOriginY - data.horizonDir.y * r),
    Math.floor(horizonOriginX + data.horizonDir.x * r),
    Math.floor(horizonOriginY + data.horizonDir.y * r),
    data.thickness,
    color[0], color[1], color[2], color[3] * (selected ? 1.0 : layer.opacity));
  flushBatch(batch);
  gl.useProgram(null);
}

function drawPerspectiveGridVanishingPointHandles(webgl: WebGLResources, viewMat: Float32Array, layer: PerspectiveGridLayer, selected: boolean, tool?: PerspectiveGridTool) {
  const { gl, batch } = webgl;
  const data = layer.perspectiveGrid;
  const color = selected ? PERSPECTIVE_GRID_COLOR_ARRAY_SELECTED : PERSPECTIVE_GRID_COLOR_ARRAY_NORMAL;
  const shader = getShader(webgl, 'circleOutline');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMat);
  for (let i = 0; i < data.vpointScreenList.length; i++) {
    let lineWidth = PERSPECTIVE_GRID_HANDLE_LINE_WIDTH_LIGHT;
    if (tool !== undefined) {
      const state = tool.getVanishingPointState(data.vpointList[i]);
      if (state === PerspectiveGridVanishingPointState.Hover) {
        lineWidth = PERSPECTIVE_GRID_HANDLE_LINE_WIDTH_MEDIUM;
      } else if (state === PerspectiveGridVanishingPointState.Pressed) {
        lineWidth = PERSPECTIVE_GRID_HANDLE_LINE_WIDTH_BOLD;
      }
    }
    const radius = PERSPECTIVE_GRID_HANDLE_RADIUS - (lineWidth - PERSPECTIVE_GRID_HANDLE_LINE_WIDTH_LIGHT) / 2.0;
    const radiusWithBorder = Math.ceil(radius + 2);
    gl.uniform1f(shader.uniforms.lineWidth, lineWidth);
    pushQuadXXYY(batch,
      Math.floor(data.vpointScreenList[i].x) - radiusWithBorder,
      Math.floor(data.vpointScreenList[i].y) - radiusWithBorder,
      Math.floor(data.vpointScreenList[i].x) + radiusWithBorder,
      Math.floor(data.vpointScreenList[i].y) + radiusWithBorder,
      0.0, 0.0, 1.0, 1.0,
      radius, radiusWithBorder / radius,
      color[0], color[1], color[2], color[3] * (selected ? 1.0 : layer.opacity));
    flushBatch(batch);
  }
  gl.useProgram(null);
}

function drawPerspectiveGridVanishingPointLines(webgl: WebGLResources, viewMat: Float32Array, view: Viewport, drawing: Drawing, vpoint: Point, layer: PerspectiveGridLayer, stretch: boolean) {
  const { gl, batch } = webgl;
  const data = layer.perspectiveGrid;
  const shader = getShader(webgl, 'perspectiveGridLine');
  const color = PERSPECTIVE_GRID_COLOR_ARRAY_NORMAL_SEMI;
  const linesNumber = data.linesNumber;
  const pixelRatio = getPixelRatio();
  let lineDirX = 0;
  let lineDirY = 0;
  let lineDirRotX = 0;
  let lineDirRotY = 0;
  let vpointScreen = tempPt;
  copyPoint(vpointScreen, vpoint);
  perspectiveGridToDocument(vpointScreen, data);
  vpointScreen.x -= drawing.x;
  vpointScreen.y -= drawing.y;
  documentToScreenPoint(vpointScreen, view);
  let r = 0;
  r = Math.max(distance(vpointScreen.x, vpointScreen.y, 0, 0), r);
  r = Math.max(distance(vpointScreen.x, vpointScreen.y, gl.drawingBufferWidth, 0), r);
  r = Math.max(distance(vpointScreen.x, vpointScreen.y, gl.drawingBufferWidth, gl.drawingBufferHeight), r);
  r = Math.max(distance(vpointScreen.x, vpointScreen.y, 0, gl.drawingBufferHeight), r);

  const horizonCos = Math.cos(data.horizonAngle);
  const horizonSin = Math.sin(data.horizonAngle);

  gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
  gl.useProgram(shader.program);

  let bounds = tempBounds;
  rectToPoints(bounds, data.bounds);
  for (let i = 0; i < 4; i++) {
    perspectiveGridToDocument(bounds[i], data);
    bounds[i].x -= drawing.x;
    bounds[i].y -= drawing.y;
  }
  documentToScreenPoints(bounds, view);
  for (let i = 0; i < 4; i++) {
    bounds[i].x *= pixelRatio;
    bounds[i].y *= pixelRatio;
  }
  if (isPerspectiveGridBoundsClockwise(bounds)) {
    gl.uniform4f(shader.uniforms.bounds1, bounds[0].x, bounds[0].y, bounds[1].x, bounds[1].y);
    gl.uniform4f(shader.uniforms.bounds2, bounds[2].x, bounds[2].y, bounds[3].x, bounds[3].y);
  } else {
    gl.uniform4f(shader.uniforms.bounds1, bounds[3].x, bounds[3].y, bounds[2].x, bounds[2].y);
    gl.uniform4f(shader.uniforms.bounds2, bounds[1].x, bounds[1].y, bounds[0].x, bounds[0].y);
  }
  setRect(tempRect, 0, 0, drawing.w, drawing.h);
  rectToPoints(bounds, tempRect);
  documentToScreenPoints(bounds, view);
  for (let i = 0; i < 4; i++) {
    bounds[i].x *= pixelRatio;
    bounds[i].y *= pixelRatio;
  }
  if (isPerspectiveGridBoundsClockwise(bounds)) {
    gl.uniform4f(shader.uniforms.bounds3, bounds[0].x, bounds[0].y, bounds[1].x, bounds[1].y);
    gl.uniform4f(shader.uniforms.bounds4, bounds[2].x, bounds[2].y, bounds[3].x, bounds[3].y);
  } else {
    gl.uniform4f(shader.uniforms.bounds3, bounds[3].x, bounds[3].y, bounds[2].x, bounds[2].y);
    gl.uniform4f(shader.uniforms.bounds4, bounds[1].x, bounds[1].y, bounds[0].x, bounds[0].y);
  }

  gl.uniform4f(shader.uniforms.horizonDir, -data.horizonDir.y, data.horizonDir.x, 0.0, 0.0);
  gl.uniform2f(shader.uniforms.resolution, gl.drawingBufferWidth, gl.drawingBufferHeight);
  gl.uniform1f(shader.uniforms.fog, 0);

  gl.uniformMatrix4fv(shader.uniforms.transform, false, viewMat);
  gl.uniform4f(shader.uniforms.horizonDir, -data.horizonDir.y, data.horizonDir.x, vpointScreen.x * pixelRatio, vpointScreen.y * pixelRatio);
  gl.uniform2f(shader.uniforms.resolution, gl.drawingBufferWidth, gl.drawingBufferHeight);
  gl.uniform1f(shader.uniforms.fog, data.depthFade * 400);

  for (let i = 0; i < linesNumber; i++) {
    lineDirX = Math.sin(i * 2 * Math.PI / linesNumber) * r * (stretch ? PERSPECTIVE_GRID_PLANE_GEOMETRY_STRETCH : 1);
    lineDirY = Math.cos(i * 2 * Math.PI / linesNumber) * r;
    lineDirRotX = lineDirX * horizonCos + lineDirY * horizonSin;
    lineDirRotY = -lineDirX * horizonSin + lineDirY * horizonCos;
    pushAntialiasedLine(batch,
      Math.floor(vpointScreen.x),
      Math.floor(vpointScreen.y),
      Math.floor(vpointScreen.x + lineDirRotX),
      Math.floor(vpointScreen.y + lineDirRotY),
      data.thickness,
      color[0], color[1], color[2], color[3] * layer.opacity);
  }

  flushBatch(batch);
  gl.useProgram(null);
  gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
}

export function drawPerspectiveGrid(webgl: WebGLResources, view: Viewport, drawing: Drawing, settings: RendererSettings, layer: PerspectiveGridLayer, selected: boolean, tool?: PerspectiveGridTool) {
  const data = layer.perspectiveGrid;
  calculatePerspectiveGridData(view, drawing, data);
  const pixelRatio = getPixelRatio();
  const viewMat = identityViewMatrix(
    webgl.gl.drawingBufferWidth / pixelRatio,
    webgl.gl.drawingBufferHeight / pixelRatio);
  if (settings.showPerspectiveGridLines) {
    for (let i = 0; i < data.vpointList.length; i++) {
      drawPerspectiveGridVanishingPointLines(webgl, viewMat, view, drawing, data.vpointList[i], layer, i < 2);
    }
  }
  if (settings.showPerspectiveGridHorizonLines) {
    drawPerspectiveGridHorizonLine(webgl, viewMat, layer, selected);
  }
  if (settings.showPerspectiveGridVanishingPoints) {
    drawPerspectiveGridVanishingPointHandles(webgl, viewMat, layer, selected, tool);
  }
}

function isPerspectiveGridBoundsClockwise(bounds: Point[]): boolean {
  const boundsVecHX = bounds[1].x - bounds[0].x;
  const boundsVecHY = bounds[1].y - bounds[0].y;
  const boundsVecVX = bounds[2].x - bounds[1].x;
  const boundsVecVY = bounds[2].y - bounds[1].y;
  if (boundsVecHX !== 0 && boundsVecVY !== 0) {
    return (Math.sign(boundsVecHX) * Math.sign(boundsVecVY)) > 0;
  } else {
    return (Math.sign(boundsVecHY) * Math.sign(boundsVecVX)) < 0;
  }
}