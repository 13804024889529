import { User, Rect, IRenderingContext, IRenderer, BrushCache, BrushShape, BitFlags, BrushFeature } from './interfaces';
import { clamp, lerp } from './mathUtils';
import { hasFlag, invalidEnum } from './baseUtils';
import { isRectEmpty, resetRect, addRect, createRect, intersectRect } from './rect';
import { BLACK, DEFAULT_BRUSH_TOOL_SETTINGS, HARD_BRUSH_THRESHOLD, MAX_IMAGE_HEIGHT, MAX_IMAGE_WIDTH, PENCIL_LOG, WHITE } from './constants';
import { randomFloat } from './random';
import { colorFromHSVAObject, colorToHSVAInPlace, HSVA, lerpColors, normalizeAngle } from './color';

export enum PaintBrushMode {
  Brush,
  Pencil,
}

const enum DrawMode {
  Square,
  Circle,
  Image,
  SoftBrush,
}

const { abs, sin, cos, sqrt, atan2, PI } = Math;
const MIN = -Math.max(MAX_IMAGE_WIDTH, MAX_IMAGE_HEIGHT);
const MAX = Math.max(MAX_IMAGE_WIDTH, MAX_IMAGE_HEIGHT) * 2;

function clampCoord(value: number) {
  return clamp(value, MIN, MAX);
}

function clampPressure(value: number) {
  return clamp(value, 0, 1);
}

function jitterAngle(value: number, jitter: number, rand: number) {
  return normalizeAngle(value + jitter * (rand - 0.5) * 360);
}

function jitterPercent(value: number, jitter: number, rand: number) {
  const min = Math.max(0, value - jitter);
  const max = Math.min(1, value + jitter);
  return min + (max - min) * rand;
}

export const points: number[] = [];

// TEMP: testing
export const pencilLog: { input: number[]; points: number[]; }[] = [];
const PENCIL_LOG_LIMIT = 50;

// TEMP: for testing issues with incorrect seed
export let paintBrushLastPointCount = 0;
export let paintBrushLastStartSeed = 0;
export let paintBrushLastEndSeed = 0;

export class PaintBrush {
  seed = 0;
  onDirtyRect: ((rect: Rect) => void) | undefined = undefined;
  private mode = PaintBrushMode.Brush;
  viewFlip = false;
  viewRotation = 0;
  // spacing
  private spacing = 0.2;
  minSpacing = 0.5;
  maxSpacing = 10;
  // size
  size = 10;
  private minsize = 0;
  sizeJitter = 0;
  sizePressure = true;
  // flow (density)
  private flow = 1;
  flowPressure = false;
  // hardness
  private hardness = 1;
  // opacity
  opacityPressure = false;
  // rotation
  angle = 0;
  angleJitter = 0;
  rotateToDirection = false;
  flipX = false;
  flipY = false;
  roundness = 1;
  // spread
  normalSpread = 0;
  tangentSpread = 0;
  // color
  color = BLACK;
  colorHue = 0;
  background = BLACK;
  colorPressure = false;
  foregroundBackgroundJitter = 0;
  hueJitter = 0;
  saturationJitter = 0;
  brightnessJitter = 0;
  // TEMP: testing
  startX = 0;
  startY = 0;
  endX = 0;
  endY = 0;
  // state
  bounds = createRect(0, 0, 1e6, 1e6);
  hasFeatures: BitFlags<BrushFeature> = DEFAULT_BRUSH_TOOL_SETTINGS.hasFeatures;
  lockedFeatures: BitFlags<BrushFeature> = DEFAULT_BRUSH_TOOL_SETTINGS.lockedFeatures;
  private shape: BrushShape | undefined = undefined;
  // private image: HTMLCanvasElement | undefined = undefined;
  // private mipmaps: HTMLCanvasElement[] | undefined = undefined;
  // private transformedImage?: HTMLCanvasElement;
  // private transformedImageIsDirty = true;
  private imageRatio = 1;
  private delta = 0;
  private dir = 0;
  private lastX = 0;
  private lastY = 0;
  private prevX = 0;
  private prevY = 0;
  private prevScale = 0;
  private prevScaleOriginal = 0;
  private reserved = false;
  private reservedX = 0;
  private reservedY = 0;
  private reservedScale = 0;
  private reservedScaleOriginal = 0;
  dirtyRect = createRect(0, 0, 0, 0);
  context: IRenderingContext | undefined = undefined;
  private hsva: HSVA = { h: 0, s: 0, v: 0, a: 1 };
  private brush: BrushCache = {
    size: 0,
    hardness: 0,
    roundness: 1,
    color: 0,
    canvas: undefined,
  };
  private drawReserved() {
    if (this.reserved) {
      this.drawOne(this.reservedX, this.reservedY, this.reservedScale, this.reservedScaleOriginal);
      this.reserved = false;
    }
  }
  private tempRect = createRect(0, 0, 0, 0);
  private appendDirtyRect(x: number, y: number, w: number, h: number) {
    // round and pad by 1px
    this.tempRect.x = Math.round(x - 1);
    this.tempRect.w = Math.round(x + w + 1) - this.tempRect.x;
    this.tempRect.y = Math.round(y - 1);
    this.tempRect.h = Math.round(y + h + 1) - this.tempRect.y;

    intersectRect(this.tempRect, this.bounds);
    addRect(this.dirtyRect, this.tempRect);
    this.onDirtyRect?.(this.tempRect);
  }
  private drawOne(x: number, y: number, scale: number, scaleOriginal: number) {
    const context = this.context;
    if (!context) throw new Error('[PaintBrush] context not initialized');

    const hasShapeDynamics = hasFlag(this.hasFeatures, BrushFeature.ShapeDynamics);
    const hasScattering = hasFlag(this.hasFeatures, BrushFeature.Scattering);
    const hasColorDynamics = hasFlag(this.hasFeatures, BrushFeature.ColorDynamics);

    paintBrushLastPointCount++;

    const drawMode = this.drawMode();
    let scaledSize = this.size;
    let flow = this.flow;
    let opacity = 1;

    if (this.sizePressure) scaledSize *= scale;
    if (this.sizeJitter && hasShapeDynamics) scaledSize *= 1 - this.sizeJitter * randomFloat(this);
    if (this.flowPressure) flow *= scaleOriginal;
    if (this.opacityPressure) opacity *= scaleOriginal;

    scaledSize = Math.max(0, scaledSize);

    if (drawMode === DrawMode.Square) scaledSize = Math.round(scaledSize);

    const nr = (hasScattering ? this.normalSpread : DEFAULT_BRUSH_TOOL_SETTINGS.normalSpread) * scaledSize * (randomFloat(this) - 0.5);
    const tr = (hasScattering ? this.tangentSpread : DEFAULT_BRUSH_TOOL_SETTINGS.tangentSpread) * scaledSize * (randomFloat(this) - 0.5);
    let ra = this.angle;
    if (this.rotateToDirection) {
      ra += this.dir - Math.PI * 0.5;
    } else {
      ra += this.viewRotation * (this.viewFlip ? -1 : 1);
    }
    
    if (this.angleJitter) ra += (hasShapeDynamics ? this.angleJitter : DEFAULT_BRUSH_TOOL_SETTINGS.angleJitter) * 2 * (randomFloat(this) - 0.5);

    let width = scaledSize;
    const height = width * this.imageRatio;
    const boundWidth = abs(height * sin(ra)) + abs(width * cos(ra));
    const boundHeight = abs(width * sin(ra)) + abs(height * cos(ra));
    const nrm = this.dir + PI * 0.5;
    x += sin(nrm) * nr + sin(this.dir) * tr;
    y += -cos(nrm) * nr - cos(this.dir) * tr;
    const radius = width * 0.5;

    context.opacity = opacity;
    context.usingOpacity = this.opacityPressure;
    context.globalAlpha = flow;

    let color = WHITE;

    const { colorPressure, foregroundBackgroundJitter, hueJitter, saturationJitter, brightnessJitter, hsva } = this;
    if (colorPressure || foregroundBackgroundJitter || hueJitter || saturationJitter || brightnessJitter) {
      color = this.color;

      let fgbgScale = 0;
      if (hasColorDynamics && colorPressure) fgbgScale = scaleOriginal;
      if (hasColorDynamics && foregroundBackgroundJitter) {
        const minJitter = Math.max(0, fgbgScale - foregroundBackgroundJitter);
        const maxJitter = Math.min(1, fgbgScale + foregroundBackgroundJitter);
        fgbgScale = lerp(minJitter, maxJitter, randomFloat(this));
      }
      if (hasColorDynamics && fgbgScale) color = lerpColors(color, this.background, fgbgScale);

      if (hasColorDynamics && (hueJitter || saturationJitter || brightnessJitter)) {
        colorToHSVAInPlace(hsva, color, this.colorHue);
        if (hueJitter) hsva.h = jitterAngle(hsva.h, hueJitter, randomFloat(this));
        if (saturationJitter) hsva.s = jitterPercent(hsva.s, saturationJitter, randomFloat(this));
        if (brightnessJitter) hsva.v = jitterPercent(hsva.v, brightnessJitter, randomFloat(this));
        color = colorFromHSVAObject(hsva);
      }
    }

    // DEVELOPMENT && context.marker(x, y, 0x00ff00ff); console.log(x, y, '|', this.seed); // Math.round(x - width * 0.5), Math.round(y - height * 0.5), width, height, boundWidth, boundHeight);

    switch (drawMode) {
      case DrawMode.Square:
        // TEMP: testing
        if (PENCIL_LOG && this.mode === PaintBrushMode.Pencil) {
          pencilLog[pencilLog.length - 1]?.points.push(x, y);
        }
        context.fillRect(color, Math.round(x - width * 0.5), Math.round(y - height * 0.5), width, height);
        break;
      case DrawMode.Circle:
        context.fillCircle(color, x, y, radius, this.roundness, ra);
        break;
      case DrawMode.SoftBrush:
        context.drawSoftBrush(this.brush, color, radius, this.size, this.hardness, x, y, this.roundness, ra);
        break;
      case DrawMode.Image:
        if (!this.shape) throw new Error('Missing brush shape');
        context.translate(x, y);
        if (this.flipX) context.scale(-1, 1);
        if (this.flipY) context.scale(1, -1);
        context.rotate(ra);
        context.scale(1, this.roundness);
        if (this.viewFlip && !this.rotateToDirection) context.scale(-1, 1);
        context.drawImageBrush(this.shape, color, 0, 0, width, this.roundness);
        context.setTransform(1, 0, 0, 1, 0, 0);
        break;
      default:
        invalidEnum(drawMode);
    }

    context.opacity = 1;
    context.usingOpacity = false;
    context.globalAlpha = 1;

    this.appendDirtyRect(x - (boundWidth * 0.5), y - (boundHeight * 0.5), boundWidth, boundHeight);
  }
  // private transformImage() {
  //   if (!this.image) throw new Error('Missing paintBrush image');
  //   if (!this.transformedImage) this.transformedImage = createCanvas(Math.ceil(this.size), Math.ceil(this.size));

  //   const image = this.transformedImage;
  //   image.width = Math.ceil(this.size);
  //   image.height = Math.ceil(this.size * this.imageRatio);

  //   const context = getContext2d(image);
  //   clearRect(context, 0, 0, image.width, image.height);
  //   context.drawImage(this.image, 0, 0, image.width, image.height);
  //   // color handling
  //   context.globalCompositeOperation = 'source-in';
  //   fillRect(context, colorToCSS(this.color), 0, 0, image.width, image.height);

  //   this.transformedImageIsDirty = false;
  // }
  private drawMode() {
    if (this.mode === PaintBrushMode.Pencil) {
      return DrawMode.Square;
    } else if (this.shape?.imageData || this.shape?.path) {
      return DrawMode.Image;
    } else if (this.hardness > HARD_BRUSH_THRESHOLD) {
      return DrawMode.Circle;
    } else {
      return DrawMode.SoftBrush;
    }
  }
  private innerMove(x: number, y: number, scaleOriginal: number) {
    const scale = this.computeScale(scaleOriginal);
    const dx = x - this.prevX;
    const dy = y - this.prevY;
    const ds = scale - this.prevScale;
    const dss = scaleOriginal - this.prevScaleOriginal;
    const d = sqrt(dx * dx + dy * dy);
    this.prevX = x;
    this.prevY = y;
    this.delta += d;
    const midScale = this.sizePressure ? (this.prevScale + scale) * 0.5 : 1;
    this.roundness = clamp(this.roundness, 0.03, 1);
    const drawSpacing = clamp(this.size * this.spacing * this.roundness * midScale, this.minSpacing, this.maxSpacing);
    let ldx = x - this.lastX;
    let ldy = y - this.lastY;
    const ld = sqrt(ldx * ldx + ldy * ldy);
    this.dir = atan2(ldx, ldy === 0 ? 0 : -ldy); // prevent (0, -0), which would results in 180 deg rotation

    if (ldx || ldy) {
      this.drawReserved();
    }

    if (this.delta < drawSpacing) {
      this.prevScale = scale;
      this.prevScaleOriginal = scaleOriginal;
      return false;
    }

    if (ld < drawSpacing) {
      this.lastX = x;
      this.lastY = y;
      this.drawOne(this.lastX, this.lastY, scale, scaleOriginal);
      this.delta -= drawSpacing;
    } else {
      const scaleSpacing = ds * (drawSpacing / this.delta);
      const scaleSpacingOriginal = dss * (drawSpacing / this.delta);

      while (this.delta >= drawSpacing) {
        ldx = x - this.lastX;
        ldy = y - this.lastY;
        this.lastX += sin(this.dir) * drawSpacing;
        this.lastY += -cos(this.dir) * drawSpacing;
        this.prevScale = clamp(this.prevScale + scaleSpacing, 0, 1); // near 0 values can go epsilon below 0
        this.prevScaleOriginal = clamp(this.prevScaleOriginal + scaleSpacingOriginal, 0, 1);
        this.drawOne(this.lastX, this.lastY, this.prevScale, this.prevScaleOriginal);
        this.delta -= drawSpacing;
      }
    }

    this.prevScale = scale;
    this.prevScaleOriginal = scaleOriginal;
    return true;
  }
  private computeScale(scale: number) {
    const hasShapeDynamics = hasFlag(this.hasFeatures, BrushFeature.ShapeDynamics);
    if (hasShapeDynamics) {
      return scale * (1 - this.minsize) + this.minsize;
    } else {
      return scale;
    }
  }
  start(x: number, y: number, scaleOriginal: number) {
    // DEVELOPMENT && this.context?.marker(x, y, 0xff00ffff); //console.log(x, y);

    // TEMP: testing
    if (PENCIL_LOG && this.mode === PaintBrushMode.Pencil) {
      while (pencilLog.length >= PENCIL_LOG_LIMIT) pencilLog.shift();
      pencilLog.push({ input: [x, y], points: [] });
    }

    paintBrushLastPointCount = 0;
    paintBrushLastStartSeed = this.seed;

    // clamp to reasonable ranges to prevent extremely long strokes that would hang the server
    x = clampCoord(x);
    y = clampCoord(y);
    scaleOriginal = clampPressure(scaleOriginal);

    this.startX = x;
    this.startY = y;
    this.reserved = false;

    // points.length = 0; points.push(x, y, scale);
    const scale = this.computeScale(scaleOriginal);
    this.dir = 0;
    resetRect(this.dirtyRect);

    if (scale > 0 || !this.sizePressure) {
      if (this.rotateToDirection || this.normalSpread !== 0 || this.tangentSpread !== 0) {
        this.reserved = true;
        this.reservedX = x;
        this.reservedY = y;
        this.reservedScale = scale;
        this.reservedScaleOriginal = scaleOriginal;
      } else {
        this.drawOne(x, y, scale, scaleOriginal);
      }
    }

    this.delta = 0;
    this.lastX = this.prevX = x;
    this.lastY = this.prevY = y;
    this.prevScale = scale;
    this.prevScaleOriginal = scaleOriginal;
    this.context!.flush();
  }
  move(x: number, y: number, scale: number) {
    // DEVELOPMENT && this.context?.marker(x, y, 0xff0000ff); //console.log(x, y);
    // TEMP: testing
    if (PENCIL_LOG && this.mode === PaintBrushMode.Pencil) {
      pencilLog[pencilLog.length - 1]?.input.push(x, y);
    }

    x = clampCoord(x);
    y = clampCoord(y);
    scale = clampPressure(scale);

    // points.push(x, y, scale);
    this.innerMove(x, y, scale);
    this.context!.flush();
  }
  end(x: number, y: number, scale: number) {
    // DEVELOPMENT && this.context?.marker(x, y, 0x0000ffff); //console.log(x, y);
    // TEMP: testing
    if (PENCIL_LOG && this.mode === PaintBrushMode.Pencil) {
      pencilLog[pencilLog.length - 1]?.input.push(x, y);
    }

    x = clampCoord(x);
    y = clampCoord(y);
    scale = clampPressure(scale);

    this.endX = x;
    this.endY = y;

    // points.push(x, y, scale);
    this.innerMove(x, y, scale);
    this.drawReserved();
    this.context!.flush();

    paintBrushLastEndSeed = this.seed;
  }
  commit(toolName: string, opacityLocked: boolean, user: User, renderer: IRenderer, remote: boolean) {
    // sometimes dirtyRect ends up being empty on remote user, we have to force it to write to history anyway
    if (isRectEmpty(user.surface.rect) && !remote) {
      renderer.releaseUserCanvas(user);
      return false;
    } else {
      const layer = user.activeLayer;
      if (!layer) throw new Error('[paintBrush.commit] Missing activeLayer');

      user.history.pushDirtyRect(toolName, layer.id, user.surface.rect);
      renderer.commitTool(user, opacityLocked);
      return true;
    }
  }
  setMode(value: PaintBrushMode) {
    this.mode = value;
  }
  setMinSize(value: number) {
    this.minsize = clamp(value, 0, 1);
  }
  setFlow(value: number) {
    if (this.flow !== value) {
      this.flow = value;
      // this.transformedImageIsDirty = true;
    }
  }
  setSpacing(value: number) {
    this.spacing = Math.max(0.01, value);
  }
  setHardness(value: number) {
    if (this.hardness !== value) {
      this.hardness = value;

      // if (!this.image && this.hardness > 0.95) {
      //   this.transformedImage = undefined;
      // } else {
      //   this.transformedImageIsDirty = true;
      // }
    }
  }
  setShape(shape: BrushShape | undefined) {
    this.shape = shape;
  }
  // setImage(value: HTMLCanvasElement | undefined, mipmaps: HTMLCanvasElement[] | undefined) {
  //   if (!value) {
  //     this.transformedImage = this.image = undefined;
  //     this.mipmaps = undefined;
  //     this.imageRatio = 1;
  //   } else if (value !== this.image) {
  //     this.image = value;
  //     this.imageRatio = this.image.height / this.image.width;
  //     this.mipmaps = mipmaps;
  //     this.transformedImageIsDirty = true;
  //     this.transformedImage = undefined;
  //   }
  // }
}
