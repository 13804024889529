import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'info-modal',
  templateUrl: 'info-modal.pug',
})
export class InfoModal{
  @Output() close = new EventEmitter<boolean>();
  @Input() data = {
    header: undefined,
    message: '',
    confirmButtonStyle: undefined,
  };
  ok() {
    this.close.emit(false);
  }
}
