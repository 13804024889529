import type { DisabledOnMobileModalData } from '../components/shared/modals/disabled-on-mobile-modal/disabled-on-mobile-modal';
import { canDrawOnActiveLayer, canEditLayer, Editor, isFilterActive } from './editor';
import { copyViewport, createViewport } from '../common/viewport';
import { AlignmentType, ITool, Layer, Rect, ToolId, ToolSource } from '../common/interfaces';
import { toolIdToString } from '../common/toolIdUtils';
import { asOpaque, getAlpha } from '../common/color';
import { isMaskEmpty } from '../common/mask';
import { canvasToBlob, createCanvas, getContext2d } from '../common/canvasUtils';
import { getTransformedSelection } from '../common/user';
import { scheduleSaveSettings } from './settingsService';
import { TransformTo } from '../common/tools/transformTool';
import { rotateCanvasToView } from './copyPasteActions';
import { setEditorColor } from './editorUtils';
import { isViewTool } from '../common/update';
import { logAction } from '../common/actionLog';
import { invalidEnum } from '../common/baseUtils';
import { getSurfaceBounds, getTransformedRectBounds } from '../common/toolSurface';
import { copyMat2d } from '../common/mat2d';
import { hasPermission } from '../common/userRole';
import { cloneRect } from '../common/rect';
import { invalidImageSizeError, isImageSizeValid } from '../common/drawingUtils';
import { getAllows } from '../common/userUtils';
import { CropToolData } from '../common/tools/cropTool';
import { finishTransform } from '../common/toolUtils';
import { isWebGL } from '../common/utils';
import { isTextureInLimits } from './webgl';

enum ParticipationFlags {
  None,
  Creator
}

export function pickColorAt(editor: Editor, x: number, y: number, activeLayer: boolean, secondary: boolean, alphaTo: string) {
  const color = editor.renderer.pickColor(editor.drawing, editor.activeLayer, x, y, activeLayer);

  if (color) {
    editor.apply(() => {
      setEditorColor(editor, asOpaque(color), !secondary);

      // TODO: this actually doesn't work at all, since our selected tool is eyedropper at this moment
      const tool: any = editor.selectedTool;

      if (alphaTo && tool[alphaTo] !== undefined) {
        tool[alphaTo] = getAlpha(color) / 0xff;
      }
    });
  }
}

export function moveBy(editor: Editor, dx: number, dy: number) {
  if (canDrawOnActiveLayer(editor) && !editor.drawingInProgress) {
    logAction(`[local] moveBy (${dx}, ${dy})`);
    copyViewport(editor.moveTool.view!, createViewport());
    editor.moveTool.start!(0, 0, 0, undefined);
    editor.moveTool.end!(dx, dy, 0);
  }
}

export function selectTool(editor: Editor, tool: ITool | undefined, source: ToolSource) {
  if (editor.drawingInProgress && !(isFilterActive(editor) && tool?.id && isViewTool(tool.id))) return;
  if (editor.drawing.isRevision && tool && !tool.navigation) return;

  editor.selectedTool?.onDeselect?.();
  editor.selectedTool = tool;
  editor.selectedTool?.onSelect?.();
  editor.toolSource = source;

  if (editor.model.activeSlot && tool) {
    editor.model.activeSlot.activeTool = toolIdToString(tool.id);
  }

  scheduleSaveSettings(editor.model);
}

export async function createAvatarFromSelection(editor: Editor) {
  const selection = getTransformedSelection(editor.model.user);
  if (isMaskEmpty(selection)) return false;

  let canvas = editor.renderer.getDrawingSnapshot(editor.drawing, selection);
  if (!canvas || !canvas.width || !canvas.height) return false;

  canvas = rotateCanvasToView(canvas, editor.view);

  const size = Math.max(canvas.width, canvas.height);

  let result = createCanvas(size, size);
  const context = getContext2d(result);

  if (editor.drawing.background) {
    context.fillStyle = editor.drawing.background;
    context.fillRect(0, 0, size, size);
  }

  context.drawImage(canvas, (size - canvas.width) / 2, (size - canvas.height) / 2);

  while (result.width >= 512) {
    const halfSize = Math.floor(result.width / 2);
    const halfCanvas = createCanvas(halfSize, halfSize);
    getContext2d(halfCanvas).drawImage(result, 0, 0, result.width, result.height, 0, 0, halfSize, halfSize);
    result = halfCanvas;
  }

  const blob = await canvasToBlob(result);
  if (!blob) return false;

  await editor.model.changeAvatar(blob);
  return true;
}

export function transformRotate(editor: Editor, angle: number) {
  if (canEditLayer(editor, editor.activeLayer)) {
    editor.transformTool.rotate(angle);
  }
}

export function transformScale(editor: Editor, x: number, y: number) {
  if (canEditLayer(editor, editor.activeLayer)) {
    editor.transformTool.scale(x, y);
  }
}

export function transformFit(editor: Editor) {
  if (canEditLayer(editor, editor.activeLayer)) {
    editor.transformTool.transformTo(TransformTo.Fit);
  }
}

export function transformCover(editor: Editor) {
  if (canEditLayer(editor, editor.activeLayer)) {
    editor.transformTool.transformTo(TransformTo.Cover);
  }
}

export function transformFull(editor: Editor) {
  if (canEditLayer(editor, editor.activeLayer)) {
    editor.transformTool.transformTo(TransformTo.Full);
  }
}

export function launchDisabledOnMobileModal(editor: Editor, data: DisabledOnMobileModalData) {
  editor.model.modals.disabledOnMobile(data).catch((e) => {
    DEVELOPMENT && console.error(e);
    if (editor.model.modals.isOpen('disabledOnMobile')) {
      editor.model.modals.closeByName('disabledOnMobile');
    }
  });
}

export function alignLayer(editor: Editor, align: AlignmentType) {
  const layer = editor.activeLayer;

  if (!canDrawOnActiveLayer(editor) || editor.drawingInProgress) return;
  if (!layer) return;

  const getLayerProperty = (layer: Layer) => {
    const { selection, surface } = editor.model.user;

    if (layer.textarea) {
      const { textareaFormatting, rect, transform } = layer.textarea;
      copyMat2d(transform, textareaFormatting.transform);
      return getTransformedRectBounds(rect, transform);
    } else if (surface.layer === layer) {
      return getSurfaceBounds(surface);
    } else if (!isMaskEmpty(selection)) {
      return selection.bounds;
    } else {
      return layer.rect;
    }
  };

  const { x, y, w, h } = editor.drawing;
  const layerRect = getLayerProperty(layer);

  let dx = 0;
  let dy = 0;
  let opt = '';

  switch (align) {
    case AlignmentType.Left:
      dx -= layerRect.x - x;
      opt = 'align:left';
      break;
    case AlignmentType.Right:
      dx = w - layerRect.x + x - layerRect.w;
      opt = 'align:right';
      break;
    case AlignmentType.Top:
      dy -= layerRect.y - y;
      opt = 'align:top';
      break;
    case AlignmentType.Bottom:
      dy = h - layerRect.y + y - layerRect.h;
      opt = 'align:bottom';
      break;
    case AlignmentType.CenterHorizontally:
      dx = w / 2 - layerRect.x + x - layerRect.w / 2;
      opt = 'align:center-horizontally';
      break;
    case AlignmentType.CenterVertically:
      dy = h / 2 - layerRect.y + y - layerRect.h / 2;
      opt = 'align:center-vertically';
      break;

    default:
      invalidEnum(align);
  }

  dx = Math.round(dx);
  dy = Math.round(dy);

  logAction(`[local] align (${dx}, ${dy})`);

  copyViewport(editor.moveTool.view!, createViewport());
  editor.moveTool.start!(0, 0, 0, undefined);
  editor.moveTool.end!(dx, dy, 0, undefined, opt);
}

export function cropDrawing(editor: Editor, rect: Rect) {
  if (!hasPermission(editor.drawing, editor.model.user, 'cropDrawing')) {
    throw new Error(`You don't have permission to resize canvas`);
  }

  const teamData = editor.drawing.team ? editor.model.manage.team(editor.drawing.team) : undefined;
  const allows = getAllows(editor.model.user, teamData);

  if (!isImageSizeValid(rect.w, rect.h, allows)) {
    throw new Error(invalidImageSizeError(allows));
  }

  if (isWebGL(editor.renderer) && !isTextureInLimits(editor.renderer.params().maxTextureSize, rect, editor.drawing.layers)) {
    editor.model.modals.textureSizeLimitReached();
    return;
  }

  editor.model.lastCropRect = cloneRect(rect);
  finishTransform(editor, editor.model.user, 'cropDrawing');

  editor.model.user.history.pushResize(true);
  editor.resizeCanvas(rect);
  editor.model.doTool<CropToolData>(0, { id: ToolId.Crop, rect });
  editor.model.resizeDrawing(rect.x, rect.y, rect.w, rect.h);
}

export function isDrawingParticipant(editor: Editor, isEditor = false) {
  const drawing = editor.drawing;
  const userId = editor.model.user.accountId;
  const participants = drawing.participants || [];
  const participant = participants.find(p => p.id.toString() === userId);

  if (!participant) return false;
  return isEditor ? participant?.flags === ParticipationFlags.Creator : true;
}
