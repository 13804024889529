import { Component, Input, Output, EventEmitter, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { clamp } from '../../../common/mathUtils';
import { faArrowUp } from '../../../generated/fa-icons';
import { AgDragEvent } from '../directives/agDrag';
import { deg2rad, rad2deg } from '../../../common/mathUtils';

@Component({
  selector: 'slider-circle',
  templateUrl: 'slider-circle.pug',
  styleUrls: ['slider-circle.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'role': 'slider',
    'aria-valuenow': 'value',
    '[tabindex]': 'disabled ? -1 : 0',
  },
})
export class SliderBarCircle {
  _value = 0;
  positionX1 = 8;
  positionY1 = 42;
  positionX2 = 78;
  positionY2 = 42;
  @Input() min = 0;
  @Input() max = 359;
  @Input() step = 0;
  @Input() largeStep = 10;
  @Input() disabled = false;
  @Input() lowBound: number | undefined;
  @Output() valueChange = new EventEmitter<number>();
  @Output() start = new EventEmitter();
  @Output() changed = new EventEmitter<{ oldValue: number; value: number; }>();
  private currentWidth = 0;
  private currentHeight = 0;
  private oldValue = 0;
  private shift = false;
  private dragging = false;
  readonly upIcon = faArrowUp;
  constructor(private element: ElementRef<HTMLElement>) {
  }
  get value() {
    return this._value;
  }
  @Input() set value(value: number) {
    this._value = value;
    this.positionX2 = 42 + (37 * Math.cos(deg2rad(value)));
    this.positionY2 = 42 + (37 * Math.sin(deg2rad(value)));

    const valueOpposite = (value >= 180) ? (value - 180) : (value + 180);
    this.positionX1 = 42 + (37 * Math.cos(deg2rad(valueOpposite)));
    this.positionY1 = 42 + (37 * Math.sin(deg2rad(valueOpposite)));
  }
  drag({ x, y, type, event }: AgDragEvent) {
    if (this.disabled && !this.dragging) return;

    if (type === 'start') {
      const element = this.element.nativeElement;
      this.currentWidth = element.getBoundingClientRect().width;
      this.currentHeight = element.getBoundingClientRect().height;
      this.oldValue = this.value;
      this.dragging = true;
      this.start.emit();
    }
    const min = +this.min;
    const max = +this.max;
    const step = +this.step;

    let value;
    const centerX = this.currentWidth / 2;
    const centerY = this.currentHeight / 2;
    const deltaX = x - centerX;
    const deltaY = y - centerY;
    let rad = Math.atan2(deltaY, deltaX);

    value = rad2deg(rad);
    if (value < 0) {
      value = 360 + value;
    }

    if (step) value = Math.round(value / step) * step;
    value = clamp(value, this.lowBound ?? min, max);

    let shift_value = value;

    this.shift = event.shiftKey;
    if (this.shift) {
      shift_value = ((value % 15) > 7) ? (value + (value % 15)) : (value - (value % 15));
    }

    if (type === 'cancel') {
      value = this.oldValue;
      this.dragging = false;
    }

    if (this.value !== value && value==shift_value) {
      this.value = value;
      this.valueChange.emit(value);
    }

    if (type === 'end' || type === 'cancel' || this.disabled) {
      this.dragging = false;
      this.changed.emit({ oldValue: this.oldValue, value });
    }
  }
}
