import { ICanvasProvider, Drawing, IErrorReporter, IRenderer } from '../common/interfaces';
import { Renderer } from './renderer';
import { WebGLRenderer } from './webglRenderer';
import { logAction } from '../common/actionLog';
import { createCanvas, createCanvasGL } from '../common/canvasUtils';
import { storageGetItem, storageSetItem } from './storage';
import { isiOS } from '../common/userAgentUtils';
import { isTextureInLimits } from './webgl';

const IS_IE11 = typeof navigator !== 'undefined' && /Trident\/7/.test(navigator.userAgent);

export function isUsingWebGL() {
  // disabled for IE11 because it's still broken on webgl and I don't know why
  return !SERVER && ((storageGetItem('webgl') !== 'no' && !IS_IE11) || isiOS);
}

export let usingWebGL = isUsingWebGL();
export let failedWebGL = '';

// to be used when software renderer is used
export function getWebMaxTextureSize() {
  const canvas = createCanvasGL(1, 1);
  try {
    let gl: WebGLRenderingContext | WebGL2RenderingContext | null = canvas.getContext('webgl2');
    if (!gl) gl = canvas.getContext('webgl');
    if (gl) return gl.getParameter(gl.MAX_TEXTURE_SIZE);
  } catch (e) {
    DEVELOPMENT && console.error(e);
  }
  return -1;
}

export function createRenderer(id: string, canvasProvider: ICanvasProvider, drawing: Drawing, errorReporter: IErrorReporter): { renderer: IRenderer; error?: string; } {
  let error: string | undefined = undefined;

  try {
    if (usingWebGL) {
      const renderer = new WebGLRenderer(id, errorReporter);
      renderer.init(createCanvasGL(100, 100));

      // TODO: move out of here, as we won't be passing `drawing` here anymore
      if (!isTextureInLimits(renderer.params().maxTextureSize, drawing, drawing.layers)) {
        throw new Error('Reached texture size limit');
      }

      return { renderer };
    }
  } catch (e) {
    logAction(`Failed to create WebGLRenderer: ${e.message}`);
    DEVELOPMENT && console.error(e);
    error = e.message;
    usingWebGL = false;
    failedWebGL = e.message || 'unknown error';
    storageSetItem('renderer-change-reason', e.mssage === 'Reached texture size limit' ? 'texture-size-limit-reached' : 'error');
  }

  const renderer = new Renderer(id, !!failedWebGL, canvasProvider, errorReporter);
  renderer.init(createCanvas(400, 400)); // 400x400 is used in editor tests
  return { renderer, error };
}
