import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { SvgIconDefinition } from '../../../common/interfaces';
import { clamp } from '../../../common/mathUtils';

function createSvgPath(svgPath: string | Record<string, string>) {
  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttribute('fill', 'currentColor');
  if (typeof svgPath === 'object') {
    for (const key in svgPath) {
      path.setAttribute(key, svgPath[key]);  
    }
  } else {
    path.setAttribute('d', svgPath);
  }
  return path;
}

@Component({
  selector: 'svg-icon',
  template: '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class="svg-icon fa-fw"></svg>',
  styleUrls: ['svg-icon.scss'],
  host: {
    class: 'ng-fa-icon',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIcon implements AfterViewInit {
  private _icon: SvgIconDefinition | undefined;
  private _spin = false;
  private _size = '';
  private _autoWidth = false;
  private _rotate = 0;
  @Input() get icon() {
    return this._icon;
  }
  set icon(value) {
    if (this._icon !== value) {
      this._icon = value;
      this.updateSVG();
      this.updateClass();
    }
  }
  @Input() get spin() {
    return this._spin;
  }
  set spin(value) {
    if (this._spin !== value) {
      this._spin = value;
      this.updateClass();
    }
  }
  @Input() get rotate() {
    return this._rotate;
  }
  set rotate(value) {
    if (this._rotate !== value) {
      this._rotate = value;
      this.updateClass();
    }
  }
  // lg xs sm 1x 2x 3x 4x 5x 6x 7x 8x 9x 10x
  @Input() get size() {
    return this._size;
  }
  set size(value) {
    if (this._size !== value) {
      this._size = value;
      this.updateClass();
    }
  }
  @Input() get autoWidth() {
    return this._autoWidth;
  }
  set autoWidth(value) {
    if (this._autoWidth !== value) {
      this._autoWidth = value;
      this.updateClass();
    }
  }
  constructor(private element: ElementRef<HTMLElement>, change: ChangeDetectorRef) {
    change.detach();
  }
  ngAfterViewInit() {
    this.updateSVG();
    this.updateClass();
  }
  private updateSVG() {
    const svg = this.element.nativeElement.firstChild as SVGElement;
    if (!svg) return;

    while (svg.firstChild) svg.removeChild(svg.firstChild);

    if (this._icon) {
      const [width, height, _, __, paths] = this._icon.icon;
      svg.setAttribute('viewBox', `0 0 ${width} ${height}`);

      if (Array.isArray(paths)) {
        for (const p of paths) {
          svg.appendChild(createSvgPath(p));
        }
      } else {
        svg.appendChild(createSvgPath(paths));
      }
    }
  }
  private updateClass() {
    const svg = this.element.nativeElement.firstChild as SVGElement;
    if (!svg) return;

    let className = `svg-icon`;

    if (this._autoWidth) {
      if (this._icon) {
        const [width, height] = this._icon.icon;
        className += ` fa-w-${clamp(Math.round(16 * width / height), 1, 20)}`;
      }
    } else {
      className += ' fa-fw';
    }

    if (this._spin) className += ' fa-spin';
    if (this._size) className += ` fa-${this._size}`;

    svg.setAttribute('style', `transform: rotate(${this.rotate}deg)`);
    svg.setAttribute('class', className);
  }
}
