import { Component, Directive, Input, Output, EventEmitter, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { AgDrag, AgDragEvent, agDragGetDownEvents } from '../directives/agDrag';
import { clamp } from '../../../common/mathUtils';
import { removeItem } from '../../../common/baseUtils';
import { getContext2d } from '../../../common/canvasUtils';

let sortableRoot: HTMLElement | undefined = undefined;

@Component({
  selector: 'ag-sortable-root',
  template: `<div></div>`,
})
export class AgSortableRoot {
  constructor(element: ElementRef<HTMLElement>) {
    sortableRoot = element.nativeElement;
    sortableRoot.style.position = 'absolute';
    sortableRoot.style.left = '0';
    sortableRoot.style.top = '0';
  }
}

@Directive({
  selector: '[agSortable]',
  exportAs: 'ag-sortable'
})
export class AgSortable<T> {
  @Input('agSortable') items!: T[];
  @Input('agSortableDisabled') disabled = false;
  @Output('agSortableReorder') reorder = new EventEmitter<void>();
  @Output('agSortableChange') change = new EventEmitter<void>();
  @Output('agSortableStart') start = new EventEmitter<void>();
  @Output('agSortableEnd') end = new EventEmitter<void>();
  private itemElements: AgSortableItem<T>[] = [];
  private startIndex = 0;
  draggedItem?: T;
  // constructor(private element: ElementRef<HTMLElement>) {
  // }
  addItem(item: AgSortableItem<T>) {
    this.itemElements.push(item);
  }
  removeItem(item: AgSortableItem<T>) {
    removeItem(this.itemElements, item);
  }
  startMove(element: HTMLElement, item: T, _eventType: string) {
    this.startIndex = this.items.indexOf(item);
    const root = sortableRoot ?? document.body;
    root.appendChild(element);
    this.draggedItem = item;
    // this.itemElements.forEach(i => i.bindMove(eventType));
    this.start.emit();
  }
  endMove() {
    const changed = this.draggedItem && this.startIndex !== this.items.indexOf(this.draggedItem);
    // this.itemElements.forEach(i => i.unbindMove());
    this.draggedItem = undefined;
    this.end.emit();
    changed && this.change.emit();
  }
  pickItem(x: number, y: number) {
    // TODO: fix this, when scrolling up it jitters down every time item is moved
    // const element = this.element.nativeElement;
    // const bound = element.getBoundingClientRect();
    // const scrollRegion = 50;

    // if (bound.left < x && bound.right > x && bound.top < y && bound.bottom > y) {
    //   if (y < (bound.top + scrollRegion)) {
    //     element.scrollTop -= 10;
    //   } else if (y > (bound.bottom - scrollRegion)) {
    //     element.scrollTop += 10;
    //   }
    // }

    for (const e of this.itemElements) {
      if (e.item === this.draggedItem) continue;

      const bounds = e.element.nativeElement.getBoundingClientRect();

      if (bounds.left < x && bounds.right > x && bounds.top < y && bounds.bottom > y) {
        return e;
      }
    }

    return undefined;
  }
}

@Directive({ selector: '[agSortableItem]' })
export class AgSortableItem<T> implements OnInit, OnDestroy {
  @Input('agSortableItem') item!: T;
  private startX = 0;
  private startY = 0;
  private hasPointer = false;
  // private boundEvent?: string;
  private draggable?: HTMLElement;
  private placeholder?: HTMLElement;
  private width = 0;
  private height = 0;
  constructor(public element: ElementRef<HTMLElement>, private list: AgSortable<T>) {
  }
  ngOnInit() {
    this.list.addItem(this);
  }
  ngOnDestroy() {
    this.list.removeItem(this);
  }
  drag(e: AgDragEvent, onlyMouse: boolean) {
    if (this.list.disabled) return;
    if (e.event.type === 'touchstart' || e.event.type === 'touchmove') return;
    if (onlyMouse && ('pointerType' in e.event && e.event.pointerType === 'touch')) return;

    if (!this.draggable && (Math.abs(e.dx) > 10 || Math.abs(e.dy) > 10)) {
      this.startDrag(e.event.type);
    }

    // TODO: handle cancel properly, undoing all changes
    if (this.draggable && (e.type === 'end' || e.type === 'cancel')) {
      this.endDrag();
    }

    this.updateDraggable(e.dx, e.dy);

    const item = this.list.pickItem(e.x, e.y);

    if (item) {
      this.hasPointer = this.hasPointer || e.event.type === 'pointermove';

      if (this.hasPointer && e.event.type !== 'pointermove') return;

      if (this.list.draggedItem && this.list.draggedItem !== item.item) {
        const items = this.list.items;
        let dragIndex = items.indexOf(this.list.draggedItem);
        let hoverIndex = items.indexOf(item.item);

        if (dragIndex === -1 || hoverIndex === -1) {
          this.endDrag();
          return;
        }

        for (; dragIndex < hoverIndex; dragIndex++) {
          const temp = items[dragIndex + 1];
          items[dragIndex + 1] = this.list.draggedItem;
          items[dragIndex] = temp;
        }

        for (; dragIndex > hoverIndex; dragIndex--) {
          const temp = items[dragIndex - 1];
          items[dragIndex - 1] = this.list.draggedItem;
          items[dragIndex] = temp;
        }

        this.list.reorder.emit();
      }
    }
  }
  private startDrag(eventType: string) {
    const element = this.element.nativeElement;
    const rect = element.getBoundingClientRect();
    this.startX = rect.left;
    this.startY = rect.top;
    this.draggable = element.cloneNode(true) as HTMLElement;
    this.draggable.style.width = rect.width + 'px';
    this.draggable.style.height = rect.height + 'px';
    this.width = rect.width;
    this.height = rect.height;
    this.draggable.classList.add('ag-sortable-dragging');
    element.classList.add('ag-sortable-dragged');

    const src = element.querySelectorAll('canvas');
    const dst = this.draggable.querySelectorAll('canvas');

    for (let i = 0; i < src.length; i++) {
      getContext2d(dst.item(i)).drawImage(src.item(i), 0, 0);
    }

    this.placeholder = document.createElement('div');
    this.placeholder.className = 'ag-sortable-placeholder';
    element.appendChild(this.placeholder);
    this.list.startMove(this.draggable, this.item, eventType);
  }
  private endDrag() {
    this.element.nativeElement.classList.remove('ag-sortable-dragged');
    this.draggable?.parentNode?.removeChild(this.draggable);
    this.placeholder?.parentNode?.removeChild(this.placeholder);
    this.placeholder = undefined;
    this.draggable = undefined;
    this.list.endMove();
  }
  private updateDraggable(dx: number, dy: number) {
    if (this.draggable) {
      const root = sortableRoot ?? document.body;
      const { left, top } = root.getBoundingClientRect();
      const x = clamp(this.startX + dx - left, 0, window.innerWidth - this.width);
      const y = clamp(this.startY + dy - top, 0, window.innerHeight - this.height);
      this.draggable.style.transform = `translate3d(${x}px, ${y}px, 0px)`;
    }
  }
}

@Directive({ selector: '[agSortableHandle]' })
export class AgSortableHandle<T> implements OnInit {
  private agdrag: AgDrag;
  constructor(element: ElementRef<HTMLElement>, private item: AgSortableItem<T>) {
    this.agdrag = new AgDrag(element);
    this.agdrag.onlyLeftButton = true;
    this.agdrag.drag.subscribe((e: AgDragEvent) => this.item.drag(e, this.agdrag.onlyLeftButton));
  }
  ngOnInit() {
    this.agdrag.ngOnInit();
  }
  @Input('agSortableHandleOnlyMouse') get onlyMouse() {
    return this.agdrag.onlyLeftButton;
  }
  set onlyMouse(value) {
    this.agdrag.onlyLeftButton = value;
    this.item.element.nativeElement.style.touchAction = value ? 'unset' : 'none';
  }
}

@Directive({ selector: '[agSortablePrevent]' })
export class AgSortablePrevent implements OnInit, OnDestroy {
  private stop = (e: Event) => e.stopPropagation();
  constructor(private element: ElementRef<HTMLElement>) {
  }
  ngOnInit() {
    for (const eventName of agDragGetDownEvents()) {
      this.element.nativeElement.addEventListener(eventName, this.stop);
    }
  }
  ngOnDestroy() {
    for (const eventName of agDragGetDownEvents()) {
      this.element.nativeElement.removeEventListener(eventName, this.stop);
    }
  }
}

export const SORTABLE_COMPONENTS = [AgSortable, AgSortableItem, AgSortableHandle, AgSortableRoot, AgSortablePrevent];
