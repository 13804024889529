import { clearRect, createCanvas, ellipse, getContext2d } from '../common/canvasUtils';
import { colorFromRGBA, colorToCSS, colorToRGBA, getR } from '../common/color';
import { BLACK } from '../common/constants';
import { BrushCache, IRenderingContext, Polyf, Rect, ShapePath } from '../common/interfaces';
import { fillPath, pushPath } from '../common/path';
import { clamp } from '../common/mathUtils';
import { isFirefox } from '../common/userAgentUtils';
import { getBrushShapeMipmaps } from '../common/shapes';


const TAU = 2 * Math.PI;
const sqrt = Math.sqrt;

function ease(x: number, h: number) {
  if (x > 1)
    return 0;
  if (x < h)
    return 1;
  x = ((x - h) / (1 - h)) * 2;
  return 1 - (x < 1 ? (0.5 * x * x) : (-0.5 * ((x - 1) * (x - 3) - 1)));
}

export function createSoftBrush(canvas: HTMLCanvasElement, size: number, hardness: number, color: number) {
  const canvasSize = Math.max(Math.ceil(size), 1);
  canvas.width = canvas.height = canvasSize;
  const context = getContext2d(canvas);
  const data = context.createImageData(canvasSize, canvasSize);
  const imageData = data.data;
  const radius = size / 2 + 0.5;
  const center = canvasSize / 2;
  let r = 0, g = 0, b = 0;

  if (color) {
    const c = colorToRGBA(color);
    r = c.r;
    g = c.g;
    b = c.b;
  }

  for (let y = 0, i = 0; y < canvasSize; y++) {
    for (let x = 0; x < canvasSize; x++) {
      const dx = (x + 0.5) - center;
      const dy = (y + 0.5) - center;
      const dd = dx * dx + dy * dy;
      const d = sqrt(dd) / radius;

      imageData[i++] = r;
      imageData[i++] = g;
      imageData[i++] = b;
      imageData[i++] = d <= hardness ? 255 : ease(d, hardness) * 255;
    }
  }

  context.putImageData(data, 0, 0);
}

export function createRenderingContext(context: CanvasRenderingContext2D): IRenderingContext {
  if (!context) throw new Error(`Invalid rendering context`);


  let cachedColor = BLACK;
  let transformed = false;
  let cachedString = '#000000';
  let coloredBrush: HTMLCanvasElement | undefined = undefined;

  function colorToString(color: number) {
    if (cachedColor !== color) {
      cachedColor = color;
      cachedString = colorToCSS(cachedColor);
    }

    return cachedString;
  }

  return {
    gl: false,
    flush() { },
    opacity: 1,
    usingOpacity: false,
    get globalAlpha() {
      return context.globalAlpha;
    },
    set globalAlpha(value) {
      context.globalAlpha = value;
    },
    translate(x, y) {
      context.translate(x, y);
      transformed = true;
    },
    rotate(angle) {
      context.rotate(angle);
      transformed = true;
    },
    scale(sx, sy) {
      context.scale(sx, sy);
      transformed = true;
    },
    setTransform(m11, m12, m21, m22, x, y) {
      context.setTransform(m11, m12, m21, m22, x, y);
      transformed = !(m11 === 1 && m12 === 0 && m21 === 0 && m22 === 1 && x === 0 && y === 0);
    },
    clearRect(x, y, w, h) {
      clearRect(context, x, y, w, h);
    },
    fillRect(color, x, y, w, h) {
      context.fillStyle = colorToString(color);
      context.fillRect(x, y, w, h);
    },
    fillCircle(color, cx, cy, radius, roundness, angle) {
      if (DEVELOPMENT && transformed) throw new Error('Transform not supported for fillCircle');

      context.fillStyle = colorToString(color);
      fillCircle(context, cx, cy, radius, roundness, angle);
    },
    fillEllipse(color, cx, cy, rx, ry) {
      context.fillStyle = colorToString(color);
      context.beginPath();
      ellipse(context, cx, cy, rx, ry, 0);
      context.fill();
    },
    strokeRect(color, strokeWidth, x, y, w, h) {
      context.strokeStyle = colorToString(color);
      context.beginPath();
      context.lineWidth = strokeWidth;
      context.rect(x, y, w, h);
      context.stroke();
    },
    strokeEllipse(color, strokeWidth, cx, cy, rx, ry) {
      context.strokeStyle = colorToString(color);
      context.lineWidth = strokeWidth;
      context.beginPath();
      ellipse(context, cx, cy, rx, ry, 0);
      context.stroke();
    },
    fillPath(color, x, y, w, h, path) {
      // TODO: angle
      context.save();
      context.fillStyle = colorToString(color);
      context.translate(x, y);
      context.scale(w / path.width, h / path.height);
      fillPath(context, path);
      context.restore();
    },
    fillPolyfgon(color, shape, angle, patternScale, polyf) {
      fillPolyfgonWithPattern(colorToString, context, color, shape, angle, patternScale, polyf);
    },
    strokePath(color, strokeWidth, x, y, w, h, path) {
      // TODO: angle
      context.save();
      context.translate(x, y);
      context.scale(w / path.width, h / path.height);
      context.beginPath();
      pushPath(context, path);
      context.restore();

      context.save();
      context.strokeStyle = colorToString(color);
      context.lineWidth = strokeWidth;
      context.lineJoin = 'round';
      context.stroke();
      context.restore();
    },
    drawSoftBrush(brush, color, originalRadius, baseSize, hardness, cx, cy, roundness) {
      drawSoftBrush(context, brush, color, originalRadius, baseSize, hardness, cx, cy, roundness, colorToString);
    },
    drawImageBrush(shape, color, x, y, size) {
      // mipmaps
      const ss = size * 4; // value of 4 picked to match webgl rendering as closely as possible
      let mip = 3; // mipmaps below 3 result in blurry brush strokes
      while (ss > (1 << mip)) mip++;
      const mipmaps = getBrushShapeMipmaps(shape);
      if (!mipmaps || !mipmaps.length) throw new Error('Cannot get brush shape mipmaps');
      let image = mipmaps[Math.min(mip, mipmaps.length - 1)];

      let alpha = 1;
      if (size < 1) {
        alpha = size;
        size = 1;
      }

      if (color != 0xffffffff) {
        coloredBrush = coloredBrush || createCanvas(image.width, image.height);
        coloredBrush.width = image.width;
        coloredBrush.height = image.height;
        const ctx = getContext2d(coloredBrush);
        ctx.fillStyle = colorToCSS(color);
        ctx.fillRect(0, 0, image.width, image.height);
        ctx.globalCompositeOperation = 'destination-in';
        ctx.drawImage(image, 0, 0);
        ctx.globalCompositeOperation = 'source-over';
        image = coloredBrush;
      }

      const srcSize = image.width * 170 / 512;
      const s = size + 2;
      const tw = srcSize * s / size;
      const tx = (image.width - tw) / 2;
      const globalAlpha = context.globalAlpha;
      context.globalAlpha *= alpha;
      context.drawImage(image, tx, tx, tw, tw, x - s * 0.5, y - s * 0.5, s, s);
      context.globalAlpha = globalAlpha;
    },
    dispose() { },
    marker() { },
  };
}

export function drawSoftBrush(
  context: CanvasRenderingContext2D, brush: BrushCache, color: number, originalRadius: number,
  baseSize: number, hardness: number, cx: number, cy: number, roundness: number, colorToString: (color: number) => string,
  grayMode = false
) {
  if (originalRadius < 1) {
    if (!grayMode) context.fillStyle = colorToString(color);
    context.imageSmoothingEnabled = false;
    const size = originalRadius * 2;
    const actualSize = Math.max(size, 1.0);
    const alphaMul = Math.min(size, 1.0);
    const radius = (actualSize / 2) + 0.5;
    const radiusWithBorder = radius + 1;
    const x0 = Math.floor(cx - radiusWithBorder);
    const y0 = Math.floor(cy - radiusWithBorder);
    const x1 = Math.ceil(cx + radiusWithBorder);
    const y1 = Math.ceil(cy + radiusWithBorder);
    const a = context.globalAlpha;
    const a2 = a * alphaMul;
    const oneByRadius = 1 / radius;

    for (let y = y0; y <= y1; y++) {
      for (let x = x0; x <= x1; x++) {
        const dx = (x + 0.5) - cx;
        const dy = (y + 0.5) - cy;
        const dist = Math.sqrt(dx * dx + dy * dy) * oneByRadius;
        const alpha = ease(dist, hardness);

        if (alpha > 0) {
          if (grayMode) {
            const a = (alpha * getR(color)) | 0;
            const c = colorFromRGBA(a, a, a, 255);
            context.fillStyle = colorToString(c);
          } else {
            context.globalAlpha = a2 * alpha;
          }

          context.fillRect(x, y, 1, 1);
        }
      }
    }

    context.globalAlpha = a;
    context.imageSmoothingEnabled = true;
  } else {
    if (!brush.canvas || brush.size !== baseSize || brush.hardness !== hardness || brush.roundness !== roundness || (!grayMode && brush.color !== color)) {
      const size = baseSize;
      if (!brush.canvas) brush.canvas = createCanvas(Math.ceil(size), Math.ceil(size));
      brush.size = size;
      brush.hardness = hardness;
      brush.roundness = roundness;
      brush.color = color;
      brush.canvas.width = brush.canvas.height = Math.ceil(size);
      createSoftBrush(brush.canvas, size, hardness, color);
    } else if (grayMode && brush.color !== color) {
      brush.color = color;
      const context = getContext2d(brush.canvas);
      context.fillStyle = colorToString(color);
      context.globalCompositeOperation = 'source-atop';
      context.fillRect(0, 0, brush.canvas.width, brush.canvas.height);
      context.globalCompositeOperation = 'source-over';
    }

    const radius = originalRadius * brush.canvas.width / baseSize; // scale to account for image size different than base brush size
    context.save();
    context.translate(cx, cy);
    context.scale(1, roundness);
    context.translate(-radius, -radius);
    context.drawImage(brush.canvas, 0, 0, radius * 2, radius * 2);

    context.restore();

    // const s = (originalRadius * 2) / baseSize;
    // context.save();
    // context.translate(x, y);
    // context.scale(s, s);
    // context.translate(-image.width / 2, -image.height / 2);
    // context.drawImage(image, 0, 0);
    // context.restore();
  }
}

export function fillCircle(context: CanvasRenderingContext2D, cx: number, cy: number, radius: number, roundness: number, angle: number) {
  if (radius < 1) {
    context.imageSmoothingEnabled = false;
    const radiusWithBorder = radius + 1;
    const x0 = Math.floor(cx - radiusWithBorder);
    const y0 = Math.floor(cy - radiusWithBorder);
    const x1 = Math.ceil(cx + radiusWithBorder);
    const y1 = Math.ceil(cy + radiusWithBorder);
    const a = context.globalAlpha;

    for (let y = y0; y <= y1; y++) {
      for (let x = x0; x <= x1; x++) {
        const dx = (x + 0.5) - cx;
        const dy = (y + 0.5) - cy;
        const dist = Math.sqrt(dx * dx + dy * dy);
        const distFromEdge = dist - radius;
        const alpha = clamp(0.5 - distFromEdge, 0.0, 1.0);

        if (alpha > 0) {
          context.globalAlpha = a * alpha;
          context.fillRect(x, y, 1, 1);
        }
      }
    }

    context.globalAlpha = a;
    context.imageSmoothingEnabled = true;
  } else {
    if (isFirefox) radius += 0.1; // ???????
    context.beginPath();
    context.moveTo(cx + radius, cy);
    if (roundness === 1) context.arc(cx, cy, radius, 0, TAU);
    else ellipse(context, cx, cy, radius, radius * roundness, angle);
    context.closePath();
    context.fill();
  }
}

export function fillPolyfgonWithPattern(
  colorToString: any, context: CanvasRenderingContext2D, color: number, path: ShapePath | undefined, angle: number,
  patternSize: number, polyf: Polyf
) {
  context.save();
  context.beginPath();
  context.translate(polyf.ox, polyf.oy);

  for (const items of polyf.segments) {
    const size2 = items.length << 1;

    if (items.length > 0) {
      context.moveTo(items[0], items[1]);

      for (let j = 2; j < size2; j += 2) {
        context.lineTo(items[j], items[j + 1]);
      }
    }
  }

  context.closePath();

  if (!path) {
    context.fillStyle = colorToString(color);
    context.fill();
  } else {
    fillWithPattern(context, colorToString(color), path, angle, patternSize);
  }

  context.restore();
}


export function fillRectWithPattern(context: CanvasRenderingContext2D, r: Rect, patternCanvas: HTMLCanvasElement, matrix?: DOMMatrix) {
  context.save();
  const pattern = context.createPattern(patternCanvas, 'repeat');
  if (pattern) {
    if (!TESTS) pattern.setTransform(matrix);
    context.fillStyle = pattern;
    context.fillRect(r.x, r.y, r.w, r.h);
  }
  context.restore();
}

export function fillWithCanvasPattern(context: CanvasRenderingContext2D, patternCanvas: HTMLCanvasElement, matrix?: DOMMatrix) {
  context.save();
  const pattern = context.createPattern(patternCanvas, 'repeat');
  if (pattern) {
    if (!TESTS) pattern.setTransform(matrix);
    context.fillStyle = pattern;
    context.fill();
  }
  context.restore();
}

export function fillWithPattern(context: CanvasRenderingContext2D, color: string, path: ShapePath, angle: number, patternSize: number, animate = false) {
  context.save();

  let patternWidth = 1, patternHeight = 1;

  if (path.width > path.height) {
    patternWidth = clamp(Math.round(patternSize), 1, 256);
    patternHeight = clamp(Math.round(patternWidth * path.height / path.width), 1, 256);
  } else {
    patternHeight = clamp(Math.round(patternSize), 1, 256);
    patternWidth = clamp(Math.round(patternHeight * path.width / path.height), 1, 256);
  }
  const patternCanvas = createCanvas(patternWidth, patternHeight);
  const patternContext = getContext2d(patternCanvas);
  patternContext.scale(patternCanvas.width / path.width, patternCanvas.height / path.height);
  patternContext.fillStyle = color;
  fillPath(patternContext, path);
  const pattern = context.createPattern(patternCanvas, 'repeat');
  if (!pattern) throw new Error('Failed to create pattern');
  context.fillStyle = pattern;
  context.rotate(angle);
  if (animate) {
    context.translate(0, -performance.now() / 500 * patternWidth);
  }
  context.fill();

  context.restore();
}