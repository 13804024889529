import type { Model } from '../services/model';
import { includes } from './baseUtils';
import { Command, Settings } from './interfaces';
import { isMac } from './userAgentUtils';
import { shortcutToTitle } from './input';

export function inchesToLocalUnits(settings: Settings, value: number) {
  return settings.units === 'cm' ? value * 2.54 : value;
}

export function getCommandTitle(model: Model, command: Command | undefined) {
  if (!command) return '';

  let title = model.commandTitles.get(command.id);

  if (title === undefined) {
    const shortcuts = getShortcutsAsString(model, command.id);
    title = `${command.name}${shortcuts ? ` [${shortcuts}]` : ``}`;
    model.commandTitles.set(command.id, title);
  }

  return title;
}

function filterShortcuts(shortcuts: string[] | undefined): string[] | undefined {
  if (shortcuts && shortcuts.length) {
    if (isMac) {
      // don't double display shortcuts that differ only by cmd/ctrl
      shortcuts = shortcuts.filter(s => !(/ctrl\+/.test(s) && includes(shortcuts, s.replace('ctrl+', 'cmd+'))));
    } else {
      // don't display shortcuts with cmd key on non-mac systems
      shortcuts = shortcuts.filter(s => !/cmd\+/.test(s));
    }

    if (includes(shortcuts, 'enter')) {
      // remove numenter if enter is already in shortcuts
      shortcuts = shortcuts.filter(s => s !== 'numenter');
    }
  }

  return shortcuts;
}

export function shortcutsToString(shortcuts: string[] | undefined) {
  shortcuts = filterShortcuts(shortcuts);
  return shortcuts?.length ? shortcutToTitle(shortcuts[0]) : '';
  // return shortcuts.map(shortcutToTitle).join('/');
}

export function getShortcutsAsString(model: Model, id: string) {
  return shortcutsToString(getFilteredShortcuts(model, id));
}

export function getShortcuts(model: Model, id: string) {
  return model.settings.shortcuts[id];
}

export function getFilteredShortcuts(model: Model, id: string) {
  const shortcuts = getShortcuts(model, id);
  return filterShortcuts(shortcuts);
}

export function getFilteredShortcut(model: Model, id: string) {
  const shortcuts = getFilteredShortcuts(model, id);
  return shortcuts ? shortcuts[0] : undefined;
}
