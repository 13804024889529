import { Subject } from 'rxjs';
import type { VoiceControls } from '@codecharm/voice-chat-client-mediasoup';
import { User } from '../common/interfaces';
import type { Model } from './model';

export interface VoiceChatSounds {
  started: HTMLAudioElement | undefined;
  joined: HTMLAudioElement | undefined;
  muted: HTMLAudioElement | undefined;
  unmuted: HTMLAudioElement | undefined;
  leftMe: HTMLAudioElement | undefined;
  leftOther: HTMLAudioElement | undefined;
}

export interface VoiceChatSettings {
  mute: boolean;
  deafen: boolean;
  videoEnabled: boolean;
  hideInactiveSpeakers: boolean;
  videoTransparent: boolean;
  videoLayout: 'column' | 'focus' | 'cursors';
  activation: 'voice' | 'push';
  inputVolume: number;
  outputVolume: number;
  voiceActivationLevel: number;
  inputDeviceId: string | undefined;
  outputDeviceId: string | undefined;
  videoDeviceId: string | undefined;
  users: [string, number, number][]; // [name, volume, muted]
  disableEchoCancellation: boolean;
  disableNoiseSupression: boolean;
}

export const defaultVoiceChatSettings: VoiceChatSettings = {
  mute: false,
  deafen: false,
  videoEnabled: false,
  videoLayout: 'column',
  videoTransparent: false,
  hideInactiveSpeakers: false,
  activation: 'voice',
  inputVolume: 1,
  outputVolume: 1,
  voiceActivationLevel: -50,
  inputDeviceId: undefined,
  outputDeviceId: undefined,
  videoDeviceId: undefined,
  users: [],
  disableEchoCancellation: false,
  disableNoiseSupression: false,
};

export interface VoiceChatUser {
  uniqId: string;
  name: string;
}

export interface VideoCallUser {
  uniqId: string;
  name?: string;
  avatarVideo?: HTMLVideoElement;
  screenshareVideo?: HTMLVideoElement;
}

export class VoiceChatService {
  session = false;
  drawingId: string | undefined = undefined;
  muted = new Set<string>();
  joined = new Set<string>();
  lonelyCall = true;
  mutedByAdmin = new Set<string>();
  onError = new Subject<string>();
  onTalking = new Subject<User | undefined>();
  onVoiceActivated = new Subject<boolean>();
  settings = defaultVoiceChatSettings;
  isConnected = false;
  isConnecting = false;
  userElement: HTMLElement | undefined = undefined;
  model: Model | undefined = undefined;

  onNewPresentation = new Subject<HTMLVideoElement>();
  onNewVideo = new Subject<HTMLVideoElement>();

  get videoUsers(): VideoCallUser[] { return []; }

  videoForUser(_user: User): HTMLVideoElement | undefined {
    return undefined;
  }
  hasVideo() {
    return false;
  }
  get controls() {
    return undefined as VoiceControls | undefined;
  }
  async start() {
  }
  async join() {
  }
  stop(_caller: string) {
  }
  stopAsync(_caller: string): Promise<void> | undefined {
    return Promise.resolve();
  }
  reset() {
  }
  setToken(_token: string | undefined) {
  }
  addUserElement(_uniqId: string, _element: HTMLElement) {
  }
  removeUserElement(_uniqId: string, _element: HTMLElement) {
  }
  updateOutputDevice() {
  }
  applyMuted() {
  }
  async applyVideo() {
  }
  setVideoDevice(_deviceId: string | undefined) {
  }
  setInputDevice(_deviceId: string | undefined) {
  }
  applySettings() {
  }
  startScreensharing() {
  }
  stopScreensharing() {
  }
  isScreensharing() {
    return false;
  }
  async createVideoStream(): Promise<MediaStream | undefined> {
    return undefined;
  }
  getUserVolume(_user: VoiceChatUser) {
    return 0;
  }
  setUserVolume(_user: VoiceChatUser, _volume: number) {
  }
  isMuted() {
    return false;
  }
  isUserMuted(_user: VoiceChatUser) {
    return false;
  }
  setUserMuted(_user: VoiceChatUser, _muted: boolean) {
  }
  updateUserMutes() {
  }
  updateUserName(_oldName: string, _newName: string) {
  }
  playSound(_sound: keyof VoiceChatSounds) {
  }
}
