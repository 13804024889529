import { blobToArrayBuffer } from '../common/canvasUtils';
import { verifyFileForImport } from '../common/drawingUtils';
import { Model } from './model';
import { ImageInfo, ImageTypeNames } from '../common/imageUtils';
import { Analytics } from '../common/interfaces';
import { EditorInabilityStateId, enterEditorInabilityState, exitEditorInabilityState } from '../common/analytics';
import { getAllows } from '../common/userUtils';

export async function importFile(model: Model, file: File | Blob, addToSequence: boolean) {
  model.importing = true;
  if (model.editor) enterEditorInabilityState(model.editor, EditorInabilityStateId.Importing, 'importFile');
  model.importingProgress = 0;

  let imageInfo: ImageInfo | undefined = undefined;
  try {
    const verificationResult = await verifyFileForImport(file, getAllows(model.user));
    imageInfo = verificationResult.imageInfo;
    const name = 'name' in file ? file.name.replace(/\.\w+$/, '') : 'image';
    return await model.importDrawing(name, verificationResult.buffer, addToSequence);
  } catch (e) {
    DEVELOPMENT && console.error(e);
    model.showError(e.message, false);
    model.trackEvent(Analytics.CreateDrawing, {
      method: 'import',
      fileSize: file.size,
      fileExtension: imageInfo ? ImageTypeNames[imageInfo.type] : ImageTypeNames[0],
      success: false,
    });
    if (e.message.startsWith('Failed to parse PSD file')) {
      blobToArrayBuffer(file)
        .then((buffer) => buffer && model.errorWithData(e.message, '', new Uint8Array(buffer)))
        .catch(e => DEVELOPMENT && console.error(e));
    }
  } finally {
    model.importing = false;
    if (model.editor) exitEditorInabilityState(model.editor, EditorInabilityStateId.Importing, false);
    model.importingProgress = 0;
  }

  return undefined;
}
