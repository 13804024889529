import { ITabletTool, TabletEvent, IToolModel, ToolId } from './interfaces';
import { clamp, rangeMap } from './mathUtils';
import { MAX_DRAWING_SAMPLES } from './constants';
import { roundCoord } from './compressor';

interface IParams {
  x: number;
  y: number;
  pressure: number;
}

interface Entry {
  x: number;
  y: number;
  pressure: number;
  time: number;
}

export function createOuterStabilizer(model: IToolModel, tool: ITabletTool, finish: () => void): ITabletTool {
  if (TESTS) {
    return createMockOuterStabilizer(tool, finish);
  } else {
    return createStandardOuterStabilizer(model, tool, finish);
  }
}

function createStandardOuterStabilizer(model: IToolModel, tool: ITabletTool, finish: () => void, interval = 5): ITabletTool {
  let started = false;
  let last = 0;
  let entries: Entry[] = [];
  let timeFromDate = false;

  function exec() {
    if (entries.length) {
      const now = timeFromDate ? Date.now() : performance.now();
      let time = last;

      for (let i = 0; time < now; time += interval) {
        while (i < (entries.length - 1) && entries[i + 1].time < time) {
          i++;
        }

        const { x, y, pressure } = entries[i];

        if (model.drawingSamples <= MAX_DRAWING_SAMPLES) {
          tool.move!(roundCoord(x), roundCoord(y), pressure);
        }
      }

      last = time - interval;
      entries.splice(0, entries.length - 1);
    }
  }

  return {
    id: tool.id, // for testing
    stabilizer: true,
    start(x: number, y: number, pressure: number, e: TabletEvent) {
      timeFromDate = e.timeStamp > performance.now();
      started = true;
      last = e.timeStamp;
      entries.push({ x, y, pressure, time: e.timeStamp });
      tool.start!(x, y, pressure, e);
    },
    move(x: number, y: number, pressure: number, e: TabletEvent) {
      if (!started) return;
      entries.push({ x, y, pressure, time: e.timeStamp });
    },
    end(x: number, y: number, pressure: number, e: TabletEvent) {
      if (!started) return;
      entries.push({ x, y, pressure, time: e.timeStamp });
      exec();
      tool.end!(x, y, pressure);
      finish();
      started = false;
    },
    cancel() {
      entries = [];
      started = false;
    },
    frame() {
      if (started) {
        exec();
      }

      tool.flush?.();
    },
  };
}

function createMockOuterStabilizer(tool: ITabletTool, finish: () => void): ITabletTool {
  return {
    id: tool.id,
    stabilizer: true,
    start(x: number, y: number, pressure: number, e: TabletEvent) {
      tool.start!(x, y, pressure, e);
    },
    move(x: number, y: number, pressure: number) {
      tool.move!(x, y, pressure);
    },
    end(x: number, y: number, pressure: number) {
      tool.end!(x, y, pressure);
      finish();
    },
    frame() {
      tool.flush?.();
    }
  };
}

export function createInnerStabilizer(id: ToolId, tool: ITabletTool, strength: number): ITabletTool {
  const weight = rangeMap(0.01, 1, 20, 80, clamp(strength, 0.01, 1));
  const level = weight / 4;
  const follow = 1 - clamp(weight / 100, 0, 0.95);
  let paramTable: IParams[] = [];
  let started = false;

  return {
    id,
    start(x: number, y: number, pressure: number, e?: TabletEvent) {
      paramTable = [];

      for (let i = 0; i < level; i++) {
        paramTable.push({ x, y, pressure });
      }

      started = true;
      tool.start!(x, y, pressure, e);
    },
    move(x: number, y: number, pressure: number) {
      if (!started) throw new Error('Stabilizer not started');
      calcMove(paramTable, follow, x, y, pressure);

      const last = paramTable[paramTable.length - 1];
      if (!last) throw new Error('paramTable empty');

      tool.move!(last.x, last.y, last.pressure);
    },
    end(x: number, y: number, pressure: number) {
      if (!started) throw new Error('Stabilizer not started');
      let delta = calcMove(paramTable, follow, x, y, pressure);

      const last = paramTable[paramTable.length - 1];
      if (!last) throw new Error('paramTable empty');

      while (delta > 1) {
        tool.move!(last.x, last.y, last.pressure);
        delta = calcMove(paramTable, follow, x, y, pressure);
      }

      tool.end!(last.x, last.y, last.pressure);
      started = false;
    },
  };
}

const abs = Math.abs;

// TODO: use float array ?
function calcMove(paramTable: IParams[], follow: number, x: number, y: number, pressure: number) {
  if (!paramTable.length) throw new Error('paramTable empty in calcMove');
  paramTable[0].x = x;
  paramTable[0].y = y;
  paramTable[0].pressure = pressure;

  let delta = 0;

  for (let i = 1; i < paramTable.length; ++i) {
    const curr = paramTable[i];
    const prev = paramTable[i - 1];
    const dx = prev.x - curr.x;
    const dy = prev.y - curr.y;
    const dp = prev.pressure - curr.pressure;
    delta += abs(dx);
    delta += abs(dy);
    curr.x = curr.x + dx * follow;
    curr.y = curr.y + dy * follow;
    curr.pressure = curr.pressure + dp * follow;
  }

  return delta;
}
