import { Component, Input } from '@angular/core';
import { Layer } from '../../../common/interfaces';

@Component({
  selector: 'layer-item-commands',
  templateUrl: 'layer-item-commands.pug',
})
export class LayerItemCommands {

  @Input() direction: 'left' | 'right' = 'left';
  @Input() layer: Layer | undefined = undefined;

  private isMenuVisible = false;
  display: 'none' | 'block' = 'none';

  constructor() { }

  onCommandClick(event: MouseEvent) {
    event.stopPropagation();
  }

  onCommandMouseEnter(event: MouseEvent) {
    event.stopPropagation();
    this.isMenuVisible = true;
    this.changeVisibility();
  }

  onCommandMouseLeave(event: MouseEvent) {
    event.stopPropagation();
    this.isMenuVisible = false;
    setTimeout(() => {
      this.changeVisibility();
    }, 200);
  }

  private changeVisibility() {
    this.display = this.isMenuVisible ? 'block' : 'none';
  }
}
