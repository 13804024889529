import { Analytics, CursorType, Drawing, DrawOptions, Feature, hasCtrlOrMetaKey, ITool, IToolData, IToolEditor, IToolModel, IUndoFunction, Layer, LayerData, MsgPosition, Mutable, Point, Rect, TabletEvent, TabletEventFlags, TextLayer, TextSelection, ToolError, ToolId, Viewport } from '../interfaces';
import { CLOSE_KEYBOARD_SVG, faText, OPEN_KEYBOARD_SVG } from '../icons';
import { FontFamily, FontStyleNames, fontStyleNameToWeight, isBold, isItalic } from '../text/font-family';
import { assertTextarea, countCtrlExtension, createTextarea, FixedWidthTextarea, insertCharactersIntoTextarea, isBoxTextareaType, PARAGRAPH_SPLIT_CHARACTER, removeCharactersFromTextarea, sanitizeEmojis, Textarea, TEXTAREA_BOUNDARIES_COLOR, TEXTAREA_UNHOVERED_BOUNDARIES_WIDTH, TextareaOptions, TextareaType } from '../text/textarea';
import { truncate, uniq } from 'lodash';
import { AnyLevelTextFormattingDescription, CharacterFormatting, CharacterFormattingDescription, DEFAULT_CHARACTER_FORMATTING, DEFAULT_PARAGRAPH_FORMATTING, DEFAULT_TEXTAREA_FORMATTING, getAppliedFormattingName, isValidCharacterFormatting, isValidParagraphFormatting, MAX_SAFE_BASELINE_SHIFT, MAX_SAFE_LETTERSPACING, MAX_SAFE_LINEHEIGHT, MAX_SAFE_SCALE_X, MAX_SAFE_SCALE_Y, MAX_SAFE_SIZE, MIN_SAFE_BASELINE_SHIFT, MIN_SAFE_LETTERSPACING, MIN_SAFE_LINEHEIGHT, MIN_SAFE_SCALE_X, MIN_SAFE_SCALE_Y, MIN_SAFE_SIZE, ParagraphFormatting, ParagraphFormattingDescription, TextAlignment, TextareaFormatting, TextCases, TextRange, VerticalAlignments } from '../text/formatting';
import { colorToCSS, getAlpha, parseColor, withAlpha } from '../color';
import { cloneRect, copyRect, createRect, integerizeRect, isRectEmpty, moveRect, normalizeRect, rectToString, resetRect, setRect } from '../rect';
import { clonePoint, copyPoint, createPoint, setPoint } from '../point';
import { pickLayerFromEditor, removeLayer, selectLayer, withNewLayers } from '../../services/layerActions';
import { Editor, isLoadedConnectedAndNotLocked, lockEditor, unlockEditor } from '../../services/editor';
import { getLayerName, isTextLayer, layerFromState, layerHasImageData, setLayerState, toLayerState } from '../layer';
import { addLayerToDrawing, sendLayerOrder } from '../layerToolHelpers';
import { isClientEditor, redraw, redrawDrawing, redrawLayer } from '../../services/editorUtils';
import { FONTS, FONTS_SOURCES, hasFontsLoaded, isFontLoaded, loadAnotherFont, loadFontsForLayer } from '../text/fonts';
import { assertTextareaControlPoint, TextareaControlPoint, TextareaControlPointDirections } from '../text/control-points';
import { clamp, distance, generateNumbersArray, isOdd } from '../mathUtils';
import { forAllTextLayers, getLayer, getLayerSafe, getLayersOwnedByAccount } from '../drawing';
import { applyTextAndParagraphIndexesToSelection, doubleIndexToCharacter, handleArrowDownVerticalSelection, handleArrowUpVerticalSelection, handleEndVerticalSelection, handleHomeVerticalSelection, pointToDoubledIndex, TextSelectionDetailed, TextSelectionDirection } from '../text/navigation';
import { Key, MagmaKeyboardEvent } from '../input';
import { AUTO_SETTING_STRING, AutoOr, getPixelRatio, isControl, isMobile, tabletEventSourceToString } from '../utils';
import { charactersToWordsWithWhitespaces } from '../text/text-character';
import { cacheTextareaInLayer, canDrawTextLayer, getUsedFonts, MAX_TEXTAREA_TEXT_LENGTH, ReadyTextLayer, textLayerToTextBox } from '../text/text-utils';
import { copyTextFromTextarea, copyTextFromTextareaViaClipboardEvent, cutTextFromTextareaViaClipboardEvent, displayToastAboutNotSupportedClipboard, htmlToPlainText, insertRichText, parseTextboxHtml, readPastedTextFromClipboard, readPlainTextFromClipboard, truncateRichText } from '../text/clipboard';
import { History as GlobalHistory, undoLog } from '../history';
import { clipboardSupported } from '../../services/copyPasteActions';
import { createVec2, setVec2, transformVec2ByMat2d } from '../vec2';
import { createMat2d, decomposeMat2d, invertMat2d } from '../mat2d';
import { documentToScreenPoint, documentToScreenXY } from '../viewport';
import { finishTransform } from '../toolUtils';
import { Mandatory } from '../typescript-utils';
import { keys } from '../baseUtils';
import { getImageDataBounds } from '../canvasUtils';
import { logAction } from '../actionLog';
import { LAYER_NAME_LENGTH_LIMIT } from '../constants';
import { TextToolAnalytics, TextToolAnalyticsEvents, TextToolChangedTextBoxTypeEvent, TextToolSelectedFontStyleEvent, TransformedLayerEvent } from '../analytics';
import { isAndroid, isiOS, isMac, isSafari } from '../userAgentUtils';
import { showHelpAlertForError } from '../../services/help';
import { clearLoneSurrogateCharacters } from '../utf8';

const CLICK_OR_DRAG_THRESHOLD = 10;
const TYPING_IDLE_DEBOUNCE = 800;
const SHORT_TEXTAREA_PLACEHOLDER = 'Lorem ipsum';
export const LONG_TEXTAREA_PLACEHOLDER = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.';
export const TEXT_TOOL_ARTIFICIAL_TEXTAREA_CLASS = 'text-tool-input';
const ARTIFICIAL_TEXTAREA_SIZE = 200;
const MULTI_CLICK_NEXT_TICK_TIMEOUT = 400;

// debug switches:
export const DRAW_TEXTURE_RECT = false;
const SHOW_ARTIFICIAL_TEXTAREA = false;
const FIX_TEXTAREA_IN_TOP_LEFT = false;

export interface TextToolData extends IToolData {
  layerIndex: number;
  layerData: LayerData;
  croppedRect?: Rect; // force rect after rasterization due to browser/node canvases rendering differently
  mode: TextToolMode;
  historyString?: string;
  analytics?: {
    layersOwned: number;
    layersTotal: number;
  }; // present for creating only
}

export enum TextToolMode {
  Creating = 'creating', // only invoked with end()
  Typing = 'typing', // every action changing textarea value so not only input event but also pasting, cutting etc.
  Moving = 'moving',
  Resizing = 'resizing',
  Formatting = 'formatting',
  Rasterizing = 'rasterizing',
  Selecting = 'selecting', // doesn't synchronize anything to remote, shouldn't ever call doTool with it
  Focusing = 'focusing', // does synchronize state at remote but doesn't account as an action (does not modify drawing, doesnt add undos, doesn't clear redos)
}

export interface TextToolPasteOptions {
  forcePlainText?: boolean;
  providedText?: string; // sometimes we need to grab it in safe context
  providedPlainText?: string; // sometimes we need to grab it in safe context
}

const tempVec2 = createVec2();
const tempMat2d = createMat2d();

export class TextTool implements ITool {
  id = ToolId.Text;
  name = 'Text tool';
  icon = faText;
  feature = Feature.Text;
  onlyPortal = true;
  skipMoves = true;
  contextMenu = true;
  continuousRedraw = true;
  description = 'Enter text from keyboard to your artworks';
  video = { url: 'assets/videos/text.mp4', width: 374, height: 210 };
  learnMore = 'https://help.magma.com/en/articles/7194046-text-tool';
  editor: IToolEditor;
  layer: TextLayer | undefined = undefined;
  cursor = CursorType.Default;
  cancellableLocally = true;
  mode = TextToolMode.Typing;
  fields = keys<TextTool>(['fontFamily', 'fontStyle', 'fontSize', 'lineheight', 'letterSpacing', 'baselineShift', 'bold', 'italic', 'underline', 'strikethrough', 'alignment', 'textareaType', 'verticalAlignment', 'scaleX', 'scaleY', 'textCase']);
  private _focused = false;
  get focused() {
    return !!this.input && this._focused;
  }
  set focused(value: boolean) {
    if (!TESTS && SERVER) {
      if (DEVELOPMENT) throw new Error(`TextTool.focused was set on server. That should not be needed.`);
      else return;
    }

    const wasDifferent = value !== this._focused;
    this.confirmDeferredChange();

    redraw(this.editor);
    this._focused = value;
    if (this._focused) {
      if (isMobile) {
        if (this.input) {
          this.input.autocapitalize = this.getAutocapitalizeAttribute();
          this.input.blur();
          this.input.focus();
          this.input.click();
          this.input.classList.remove('ks-allow');
        }
        if (this.textarea) this.textarea.blinkOffset = performance.now();
      } else {
        this.input?.focus();
        this.input?.classList.remove('ks-allow');
        if (this.textarea) this.textarea.blinkOffset = performance.now();
      }
    } else {
      this.input?.blur();
      this.input?.classList.add('ks-allow');
      if (this.textarea && this.textarea.textareaFormatting.displayNonPrintableCharacters) this.applyTextareaFormatting({ displayNonPrintableCharacters: false });
      setTimeout(() => this.hover((this.editor as Editor).lastMoveX, (this.editor as Editor).lastMoveY, undefined));
      if (isMobile) this.hideMobileKeyboard();
    }

    const doToolOptions = this.recomputeTextLocally(TextToolMode.Focusing);
    if (isLayerOk(this.layer, this.editor.drawing) && wasDifferent && doToolOptions && isLoadedConnectedAndNotLocked(this.editor as Editor)) {
      this.model.doTool(this.layer.id, doToolOptions);
    }

    if (this.textarea) this.textarea.isFocused = this.focused;
  }

  input: HTMLTextAreaElement | undefined = undefined;
  initialised = false;
  lastViewportHeight = (!SERVER && isMobile) ? (window.visualViewport?.height ?? 0) : 0;
  keyboardOpened = false;
  lastOrientation = this.getOrientation();
  private getOrientation() {
    if (SERVER || TESTS || window.innerWidth > window.innerHeight) return 'landscape';
    else return 'portrait';
  }

  constructor(editor: IToolEditor, public model: IToolModel) {
    this.editor = editor;
  }

  get textarea() {
    return this.layer?.textarea;
  }

  set textarea(value: Textarea | undefined) {
    if (this.layer) this.layer.textarea = value;
  }

  initTool() {
    this.onLayerChange(this.model.user.activeLayer);
    this.fontFamily = this.selectableFontFamilies[0];
    this.editor.apply(() => { });
    if (!SERVER) this.setupBodyListeners();
    this.initialised = true;
  }

  private showKeyboardFab: HTMLButtonElement | undefined = undefined;
  private createShowKeyboardFab() {
    this.showKeyboardFab = document.createElement('button');
    this.styleShowKeyboardFab(this.showKeyboardFab);
    this.showKeyboardFab.innerHTML = OPEN_KEYBOARD_SVG;
    const editorView = document.getElementsByClassName('editor').item(0);
    (editorView ?? document.body).appendChild(this.showKeyboardFab);
    if (this.textarea) {
      setShowKeyboardFabPositionOnPage(this.showKeyboardFab, this.editor.view, this.textarea);
      setTimeout(() => {
        if (this.textarea) {
          setShowKeyboardFabPositionOnPage(this.showKeyboardFab, this.editor.view, this.textarea);
        }
      });
    }
    this.registerShowKeyboardFabHandlers(this.showKeyboardFab);
    this.editor.apply(() => { });
  }
  private styleShowKeyboardFab(button: HTMLButtonElement) {
    button.style.position = 'absolute';
    button.style.zIndex = '1';
    button.style.top = '0';
    button.style.left = '0';
    button.style.transition = '.2s ease-in-out';
    button.style.background = '#383838';
    button.style.color = '#eeeeee';
    button.style.borderRadius = '16px';
    button.style.cursor = 'pointer';
    button.style.fontSize = '12px';
    button.style.padding = '8px 20px';
    button.style.border = '0';
  }
  private registerShowKeyboardFabHandlers(button: HTMLButtonElement) {
    button.addEventListener('pointerenter', () => { button.style.background = '#454545'; });
    button.addEventListener('pointerleave', () => { setTimeout(() => { button.style.background = '#383838'; }, 100); });
    button.addEventListener('pointerup', () => { setTimeout(() => { button.style.background = '#383838'; }, 100); });
    button.addEventListener('pointerdown', () => { button.style.background = '#535353'; });
    button.addEventListener('click', () => {
      if (this.keyboardOpened) {
        this.hideMobileKeyboard();
      } else {
        this.showMobileKeyboard();
      }
    });
  }

  updateShowKeyboardFabPosition() {
    if (isLayerOk(this.layer, this.editor.drawing) && isTextareaOk(this.layer.textarea)) {
      setShowKeyboardFabPositionOnPage(this.showKeyboardFab, this.editor.view, this.layer.textarea);
    }
  }

  private setupBodyListeners() {
    document.body.addEventListener('keydown', (e) => {
      const target = e.target as HTMLElement;
      if ((this.editor as Editor).selectedTool?.id === this.id && this.focused && !isControl(target)) {
        this.focused = true;
        this.keydownHandler(e);
      }
    });

    document.body.addEventListener('focusout', (e) => {
      if (this.isUsingTextTool && !e.relatedTarget && isTextLayer(this.layer) && !isMobile) {
        // it's important to check if this.layer is not undefined here because when restoring initial state
        // we first set layer to undefined just for this so we don't refocus input about to be deleted
        // without this check keyboard service can break in few scenarios (for example removing layer with undo)
        this.input?.focus();
      }
    });
  }

  cancel() {
    switch (this.mode) {
      case TextToolMode.Selecting: {
        // ending prematurely
        this.end((this.editor as Editor).lastMoveX, (this.editor as Editor).lastMoveY, 0);
        break;
      }
      case TextToolMode.Moving:
      case TextToolMode.Resizing: {
        if (isLayerOk(this.layer, this.editor.drawing) && this.startSnapshot) {
          setLayerState(this.layer, this.startSnapshot);
          this.recomputeTextLocally();
        }
        this.becomeIdle();
        break;
      }
      default: {
        this.mousePreundo = undefined;
        if (isLayerOk(this.model.user.activeLayer, this.editor.drawing) && this.mode === TextToolMode.Creating) {
          this.onLayerChange(this.model.user.activeLayer);
        }
        break;
      }
    }
    this.becomeIdle();
  }

  onSelect() {
    const editor = this.editor as Editor;
    if (isLoadedConnectedAndNotLocked(editor)) {
      const layer = this.model.user.activeLayer;
      this.onLayerEnter(layer);
    }
  }

  onDeselect() {
    this.scheduledLayer = undefined;
    this.confirmDeferredChange();
    if (!SERVER) this.focused = false;
    this.becomeLayerless();
  }

  private getSelection(): TextSelectionDetailed | undefined {
    if (this.input && isTextareaOk(this.textarea)) {
      const textarea = this.textarea;

      const { selectionStart, selectionEnd } = this.input;

      const startCharacter = textarea.characters[selectionStart];
      let endCharacter = textarea.characters[selectionEnd];

      const startParagraphIndex = startCharacter ? textarea.findParagraphIndexFromCharacter(startCharacter) : 0;
      const endParagraphIndex = endCharacter ? textarea.findParagraphIndexFromCharacter(endCharacter) : 0;

      return {
        start: selectionStart,
        end: selectionEnd,
        length: Math.max(selectionEnd - selectionStart, 0),
        direction: this.input.selectionDirection as TextSelectionDirection,
        text: textarea.text.substring(selectionEnd, selectionStart),
        paragraphIndexes: generateNumbersArray(startParagraphIndex, endParagraphIndex),
      };
    } else {
      return undefined;
    }
  }

  async doAsync(data: TextToolData) {
    switch (data.mode) {
      case TextToolMode.Creating: {
        const user = this.model.user;
        const newLayer = layerFromState(data.layerData);

        finishTransform(this.editor, user, `TextTool:doAsync:${TextToolMode.Creating}`);
        this.globalHistory.pushAddLayer(data.layerData, data.layerIndex);

        addLayerToDrawing(this.editor, newLayer, data.layerIndex);
        newLayer.owner = user;
        break;
      }
      case TextToolMode.Rasterizing: {
        logAction(`[remote] text:rasterize (layer: ${data.layerData.id})`);
        const layer = getLayerSafe(this.editor.drawing, data.layerData.id);

        if (!isTextLayer(layer)) throw new Error(`TextTool attempting to rasterize non-text layer (${data.layerData.id})!`);
        const { croppedRect } = data;
        if (!croppedRect) throw new Error('Missing croppedRect for text layer rasterization!');

        setTextLayerDirty(this.editor, layer);
        finishTransform(this.editor, this.model.user, `TextTool:doAsync:${TextToolMode.Rasterizing}`);
        this.editor.renderer.releaseLayer(layer);

        this.globalHistory.execTransaction((history) => {
          history.pushLayerState(layer.id, TextToolMode.Rasterizing);
          history.pushDirtyRect('rasterizing-text', layer.id, croppedRect, false, true);
        });

        if (!canDrawTextLayer(layer)) {
          logAction(`[remote] dispatching async rasterization (layerId: ${layer.id})`);
          await this.dispatchAsyncRasterization(layer, data);
          logAction(`[remote] after async rasterization (layerId: ${layer.id})`);
        } else {
          this.rasterizeRemote(layer, data);
        }
        break;
      }
      case TextToolMode.Focusing: {
        const layer = getLayer(this.editor.drawing, data.layerData.id);
        if (!isLayerOk(layer, this.editor.drawing)) {
          return; // not an action, can be sent when exiting layer that gets removed
        }
        setLayerState(layer, data.layerData);
        redrawLayer(this.editor, layer);
        break;
      }
      default: {
        const layer = getLayerSafe(this.editor.drawing, data.layerData.id);
        if (!isTextLayer(layer)) {
          throw new Error(`TextTool attempting to update non-text layer (${data.layerData.id})!`);
        }

        if (!data.replace) {
          finishTransform(this.editor, this.model.user, 'TextTool:doAsync:default');
          this.globalHistory.pushLayerState(layer.id, data.historyString ?? data.mode); // historyString is used only when applying something with wonky (non-mode-only) history string like formatting
        } else {
          this.globalHistory.clearRedos();
        }

        setLayerState(layer, data.layerData);
        redrawLayer(this.editor, layer);
        break;
      }
    }
  }

  rect = createRect(0, 0, 0, 0);

  private originalRect = createRect(0, 0, 0, 0); // saved on start used for resizing/moving
  private resizingControlPoint: TextareaControlPoint | undefined = undefined;

  private dragged = false;

  private selectDoubleIndexStart: number | undefined = undefined;
  private selectDoubleIndexEnd: number | undefined = undefined;

  private get readyForActions() {
    return isLayerOk(this.layer, this.editor.drawing) && isTextareaOk(this.textarea);
  }

  private get isCreating() {
    return !this.readyForActions || this.mode === TextToolMode.Creating;
  }

  private startPoint = createPoint(0, 0);
  private startPointTransformed = createPoint(0, 0);
  private endPoint = createPoint(0, 0);
  private endPointTransformed = createPoint(0, 0);

  private getTransformedPoint(point: Point) {
    setVec2(tempVec2, point.x, point.y);
    if (this.textarea) {
      invertMat2d(tempMat2d, this.textarea.transform);
      transformVec2ByMat2d(tempVec2, tempVec2, tempMat2d);
    }
    return createPoint(tempVec2[0], tempVec2[1]);
  }

  private copyTextareaRect(textarea: Textarea) {
    this.rect.x = textarea.x;
    this.rect.y = textarea.y;
    this.rect.w = textarea.width;
    this.rect.h = textarea.height;
  }
  get isTextLayerLocked() {
    const realModel = (this.editor as Editor).model;
    return (isLayerOk(this.layer, this.editor.drawing) && this.layer.locked)
      || (realModel && realModel.isPresentationMode && !realModel.isPresentationHost && realModel.presentationModeState.participantsUiHidden);
  }
  startSnapshot: LayerData | undefined = undefined;
  start(x: number, y: number, _: number, e?: TabletEvent) {
    if (this.slidingFormattingLayerId) return;
    if (this.toolCreationLock) return;
    setPoint(this.startPoint, x, y);
    copyPoint(this.startPointTransformed, this.getTransformedPoint(this.startPoint));
    this.dragged = false;
    this.mousePreundo = undefined;
    const shiftPressed = !!(e && (e.flags & TabletEventFlags.ShiftKey));

    this.confirmDeferredChange();
    if (isLayerOk(this.layer, this.editor.drawing) && isTextareaOk(this.textarea) && !this.isTextLayerLocked) {
      this.startSnapshot = toLayerState(this.layer);
      copyRect(this.originalRect, this.textarea.rect);
      const insideTextarea = this.textarea.pointInFrame(this.startPoint);
      if (this.focused) {
        this.resizingControlPoint = this.textarea.pointInControlPoint(this.startPoint, this.editor.view);
        if (this.resizingControlPoint) {
          this.startResizing(this.layer as Mandatory<TextLayer, 'textarea'>, e);
        } else if (insideTextarea) {
          this.startSelecting(this.startPoint, this.startPointTransformed, shiftPressed);
        } else {
          this.startMoving(this.layer as Mandatory<TextLayer, 'textarea'>, e);
        }
      } else {
        this.resizingControlPoint = this.textarea.pointInControlPoint(this.startPoint, this.editor.view);
        if (this.resizingControlPoint) {
          this.startResizing(this.layer as Mandatory<TextLayer, 'textarea'>, e);
        } else if (insideTextarea) {
          this.focused = true;
          this.startSelecting(this.startPoint, this.startPointTransformed, shiftPressed);
        } else {
          this.startCreating();
        }
      }
    } else {
      // not a text layer (or for some weird reason textarea not existing)
      this.startCreating();
    }

    if (isMobile && this.mode !== TextToolMode.Selecting) this.mobileSelectionStarted = false;

    this.move(x, y, 0, e);
  }

  private startCreating() {
    if (isLayerOk(this.layer, this.editor.drawing) && this.input && !this.panelState) {
      this.updatePanelStateFromSelection(this.textSelection);
    }
    this.becomeLayerless();
    this.mode = TextToolMode.Creating;
    resetRect(this.rect);
  }

  private startResizing(layer: Mandatory<TextLayer, 'textarea'>, e?: TabletEvent) {
    if (e) this.inputMethod = tabletEventSourceToString(e);
    if (layer.textarea.type === TextareaType.AutoWidth) {
      // _width property holds user-defined width, the one user for setting during resizing
      // since auto-width uses not-user-defined (automatic) at almost all times,
      // we want to restore that value to auto-computed before beginning so there is no weird snap from previous state
      layer.textarea['_width'] = layer.textarea.width;
    }
    this.mode = TextToolMode.Resizing;
    layer.textarea.isResizing = true;
    layer.textarea.activeControlPoint = this.resizingControlPoint!;
    layer.textarea.activeControlPoint.lastUsed = Date.now();
    layer.textarea.resizedNegativelyX = false;
    layer.textarea.resizedNegativelyY = false;
  }

  private startMoving(layer: Mandatory<TextLayer, 'textarea'>, e?: TabletEvent) {
    this.mode = TextToolMode.Moving;
    this.dragged = false;
    layer.textarea.isMoving = true;
    if (e) this.inputMethod = tabletEventSourceToString(e);
  }

  private inputMethod: TransformedLayerEvent['source'] = 'mouse';
  private mobileSelectionStarted = false;
  private startSelecting(clicked: Point, clickedTransformed: Point, shiftPressed: boolean, skipcheck = false) {
    if (!isTextareaOk(this.textarea) || !this.input) return;
    this.mode = TextToolMode.Selecting;
    this.selectDoubleIndexStart = pointToDoubledIndex(this.textarea, clickedTransformed);
    this.selectDoubleIndexEnd = this.selectDoubleIndexStart;
    const ctxMenu = (this.editor as Editor).toolCtxMenu;
    if (isMobile && !ctxMenu?.dragThresholdReached && ctxMenu?.waiting && !skipcheck) {
      this.mobileSelectionStarted = false;
      return;
    }

    this.inputPositionAdjust?.();
    // TODO: when user hid keyboard and selects sth we dont want to reopen it on android
    if (isAndroid) {
      if (this.keyboardOpened) this.input.focus();
    } else {
      this.input.focus();
    }

    if (!isMobile || (!ctxMenu?.waiting && !ctxMenu?.isOpen)) {
      if (!this.multiClickPosition || distance(this.multiClickPosition.x, this.multiClickPosition.y, clicked.x, clicked.y) < CLICK_OR_DRAG_THRESHOLD) {
        if (!this.registeringMultiClick) this.multiClicks = 0;
        this.incrementMulticlick(clicked, shiftPressed);
      } else if (this.multiClickPosition && distance(this.multiClickPosition.x, this.multiClickPosition.y, clicked.x, clicked.y) >= Math.max(CLICK_OR_DRAG_THRESHOLD / 2, 1)) {
        this.multiClicks = 0;
        this.incrementMulticlick(clicked, shiftPressed);
      }
    }
  }

  private incrementMulticlick(clicked: Point, shiftPressed: boolean) {
    this.registeringMultiClick = true;
    this.multiClickPosition = clonePoint(clicked);

    if (this.registeringMultiClickTimeout) clearTimeout(this.registeringMultiClickTimeout);
    this.registeringMultiClickTimeout = setTimeout(() => {
      this.registeringMultiClick = false;
      this.multiClickPosition = undefined;
    }, MULTI_CLICK_NEXT_TICK_TIMEOUT);

    this.multiClicks++;
    this.updateSelecting(shiftPressed);
  }

  private multiClicks = 0;
  private multiClickPosition: Point | undefined = undefined;
  private registeringMultiClick = false;
  private registeringMultiClickTimeout: NodeJS.Timeout | undefined;

  end(x: number, y: number, _: number, e?: TabletEvent) {
    if (this.slidingFormattingLayerId) return;
    const ctxMenu = (this.editor as Editor).toolCtxMenu;

    if (isMobile && this.mode === TextToolMode.Selecting) {
      if (!this.mobileSelectionStarted) {
        const shiftPressed = !!(e && (e.flags & TabletEventFlags.ShiftKey));
        if (isClientEditor(this.editor) && ctxMenu) {
          ctxMenu.clearLongPressTimeout();
        }
        if (!ctxMenu?.waiting) {
          this.startSelecting(this.startPoint, this.startPointTransformed, shiftPressed, true);
        }
      }
    }
    if (this.toolCreationLock) return;

    if (!(isMobile && this.mode === TextToolMode.Selecting && !this.mobileSelectionStarted)) this.doMove(x, y, e);

    if (this.mousePreundo && this.dragged) {
      if (this.mode !== this.mousePreundo.textAction) throw new Error(`Invalid mouse preundo textAction (${this.mode} != ${this.mousePreundo.textAction})`);
      if (this.layer?.id !== this.mousePreundo.layerId) throw new Error(`Invalid mouse preundo layerId (${this.layer?.id} != ${this.mousePreundo.layerId})`);
      this.globalHistory.pushUndo(this.mousePreundo);
    }

    if (this.isCreating) {
      this.endCreating();
    } else if (isLayerOk(this.layer, this.editor.drawing) && isTextareaOk(this.textarea)) {
      setTextLayerDirty(this.editor, this.layer);
      switch (this.mode) {
        case TextToolMode.Resizing:
          this.endResizing(this.layer as Mandatory<TextLayer, 'textarea'>);
          break;
        case TextToolMode.Moving:
          this.endMoving(this.layer as Mandatory<TextLayer, 'textarea'>);
          break;
        case TextToolMode.Selecting:
          this.endSelecting();
          break;
      }
    }

    this.mousePreundo = undefined;
    this.becomeIdle();
  }

  private endCreating() {
    const editor = this.editor as Editor;

    if (this.rect.w > CLICK_OR_DRAG_THRESHOLD) {
      this.textareaType = TextareaType.FixedDimensions;
    } else {
      this.textareaType = TextareaType.AutoWidth;
    }

    const user = this.model.user;
    logAction('[local] text.endCreating');
    this.toolCreationLock = true;
    editor.drawingInProgress = false;
    const finished = editor.model.startTask('Creating text layer');
    const connId = editor.model.connId;
    setTimeout(() => this.editor.apply(() => { }), 500);
    withNewLayers(editor, 1, () => true, ([id]) => {
      unlockEditor(editor);
      finished();

      if (!id) throw new Error('No layerId for creating text layer!');
      if (connId !== editor.model.connId) return;

      finishTransform(this.editor as Editor, this.model.user, 'TextTool:endCreating');

      const activeLayer = this.model.user.activeLayer;
      const layerIndex = activeLayer === undefined ? this.editor.drawing.layers.length - 1 : this.editor.drawing.layers.indexOf(activeLayer);
      const placeholder = this.textareaType === TextareaType.AutoWidth ? SHORT_TEXTAREA_PLACEHOLDER : LONG_TEXTAREA_PLACEHOLDER;
      const layerData = this.constructTextLayerData(id, placeholder);

      const fullTextCharacterFormatting = this.getCharacterFormattingFromPanel();
      if (fullTextCharacterFormatting && !fullTextCharacterFormatting.fontFamily) {
        fullTextCharacterFormatting.fontFamily = this.selectableFontFamilies[0];
      }
      if (fullTextCharacterFormatting) layerData.textData?.characterFormattings.push(fullTextCharacterFormatting);

      const fullTextParagraphFormatting = this.getParagraphFormattingFromPanel();
      if (fullTextParagraphFormatting) layerData.textData?.paragraphFormattings.push(fullTextParagraphFormatting);

      const textareaFormatting = this.getTextareaFormattingFromPanel();
      if (textareaFormatting && layerData.textData) layerData.textData.textareaFormatting = textareaFormatting;

      if (layerData.textData) {
        layerData.textData.textareaFormatting.transform = [1, 0, 0, 1, 0, 0];
        layerData.textData.dirty = false;
      }

      let numberOfTextLayers = 0;
      for (let i = 0; i < this.editor.drawing.layers.length; i++) {
        if (isTextLayer(this.editor.drawing.layers[i])) numberOfTextLayers++;
      }
      layerData.name = `Text layer #${numberOfTextLayers + 1}`;

      const newLayer = layerFromState(layerData) as TextLayer;
      newLayer.owner = this.model.user;

      const fonts = getUsedFonts(newLayer);
      if (!fonts.length || !fonts.every(isFontLoaded)) {
        newLayer.fontsLoaded = false;
        loadFontsForLayer(newLayer, this.editor as Editor).finally(() => {
          // TODO: perhaps we want to reset that lock onLayerExit? for now this allows
          //  only queueing one text layer creation at the time
          this.toolCreationLock = false;
        });
      } else {
        newLayer.fontsLoaded = true;
        this.toolCreationLock = false;
      }

      user.history.pushAddLayer(layerData, layerIndex);

      const analytics: TextToolData['analytics'] = {
        layersOwned: getLayersOwnedByAccount(this.editor.drawing, this.model.user.accountId).length,
        layersTotal: this.editor.drawing.layers.length,
      };
      addLayerToDrawing(this.editor, newLayer, layerIndex);
      sendLayerOrder(this.editor);

      const doToolOptions: TextToolData = {
        id: ToolId.Text,
        mode: TextToolMode.Creating,
        layerIndex, layerData,
        analytics,
      };

      if (newLayer.fontsLoaded) {
        doToolOptions.br = createRect(0, 0, 0, 0);
        doToolOptions.ar = textLayerToTextBox(newLayer, 'textureRect');
      }

      this.model.doTool(newLayer.id, doToolOptions);

      selectLayer(editor, newLayer);

      this.rememberPanelState();
      this.textarea?.syncControlPoints();
      this.input?.select();
      this.forceCommitOnInputEvent = false;
    }).catch((e) => {
      (this.editor as Editor).errorReporter.reportError(e);
    }).finally(() => {
      logAction('[local] text.endCreating (finally)');
      unlockEditor(editor);
      finished();
    });
    lockEditor(editor, 'text layer creation');
  }

  private endResizing(layer: Mandatory<TextLayer, 'textarea'>) {
    if (!this.resizingControlPoint) throw new Error('TextTool resizing prerequisites not present.');
    layer.textarea.activeControlPoint!.active = false;
    layer.textarea.activeControlPoint = undefined;
    layer.textarea.resizedNegativelyX = false;
    layer.textarea.resizedNegativelyY = false;
    if (this.dragged) {
      switch (layer.textarea.type) {
        case TextareaType.AutoWidth: {
          layer.textarea.x = layer.textarea.leftBorder;
          this.copyTextareaRect(layer.textarea);
          layer.textarea.isResizing = false;
          this.changeTextareaType(TextareaType.FixedWidth, false);
          break;
        }
        case TextareaType.FixedDimensions:
          this.changeTextareaType(TextareaType.FixedDimensions, false);
          break;
        case TextareaType.FixedWidth: {
          if (FixedWidthTextarea.controlPointChangesType(this.resizingControlPoint!)) {
            this.changeTextareaType(TextareaType.FixedDimensions, false);
          }
          break;
        }
      }
      layer.textarea.isResizing = false;
      this.resizingControlPoint = undefined;
      this.editor.track?.event<TransformedLayerEvent>(Analytics.TransformLayer, {
        source: this.inputMethod,
        currentTool: 'text',
        operation: 'resize',
        layerType: 'text',
      });
      this.commitChangesOnRemote(layer, !this.mousePreundo, this.recomputeTextLocally(TextToolMode.Resizing));
    }
    setShowKeyboardFabPositionOnPage(this.showKeyboardFab, this.editor.view, layer.textarea);
  }

  private endMoving(layer: Mandatory<TextLayer, 'textarea'>) {
    const inside = layer.textarea.pointInFrame(this.startPoint!);
    const blurring = !this.dragged && !inside;
    if (blurring) this.focused = false;
    if (this.input && inside) {
      this.input.setSelectionRange(this.input.selectionStart, this.input.selectionStart);
      this.dragged = false;
    }
    layer.textarea.isMoving = false;
    if (!blurring) {
      this.editor.track?.event<TransformedLayerEvent>(Analytics.TransformLayer, {
        source: this.inputMethod,
        currentTool: 'text',
        operation: 'move',
        layerType: 'text',
      });
      this.commitChangesOnRemote(layer, !this.mousePreundo, this.recomputeTextLocally(TextToolMode.Moving));
      setShowKeyboardFabPositionOnPage(this.showKeyboardFab, this.editor.view, layer.textarea);
    }
  }

  private endSelecting() {
    if (this.textSelection && this.textarea) {
      const newSel = { ...this.textSelection };
      applyTextAndParagraphIndexesToSelection(this.textarea, newSel, true);
      // invoke full setter instead of just updating properties like in move to update panel state
      this.textSelection = newSel;
      logAction(`TextTool.endSelecting - new selection: [${newSel.start}, ${newSel.end}, ${newSel.direction}]`);
    }
    this.selectDoubleIndexStart = undefined;
    this.selectDoubleIndexEnd = undefined;
    this.rememberPanelState();
  }

  move(x: number, y: number, _: number, e?: TabletEvent) {
    if (this.slidingFormattingLayerId) return;
    if (this.toolCreationLock) return;
    this.doMove(x, y, e);
  }
  private doMove(x: number, y: number, e?: TabletEvent) {
    if (this.mode === TextToolMode.Selecting && isMobile) {
      if (!this.mobileSelectionStarted) {
        if ((this.editor as Editor).toolCtxMenu?.dragThresholdReached) {
          const shiftPressed = !!(e && (e.flags & TabletEventFlags.ShiftKey));
          this.registeringMultiClick = false;
          if (this.multiClicks === 0) {
            if (isClientEditor(this.editor) && this.editor.toolCtxMenu && this.multiClicks !== 0) this.editor.toolCtxMenu.clearLongPressTimeout();
            this.startSelecting(this.startPoint, this.startPointTransformed, shiftPressed);
          }
          this.mobileSelectionStarted = true;
        }
      }
    }

    setPoint(this.endPoint, x, y);
    copyPoint(this.endPointTransformed, this.getTransformedPoint(this.endPoint));

    const shiftPressed = !!(e && (e.flags & TabletEventFlags.ShiftKey));
    const startPoint = this.startPoint;

    if (!this.dragged) {
      const startInScreen = clonePoint(startPoint);
      documentToScreenPoint(startInScreen, this.editor.view);

      const endInScreen = clonePoint(this.endPoint);
      documentToScreenPoint(endInScreen, this.editor.view);

      this.dragged = distance(startInScreen.x, startInScreen.y, endInScreen.x, endInScreen.y) > CLICK_OR_DRAG_THRESHOLD;

      if (this.dragged) {
        this.registeringMultiClick = false;
        if (this.mode === TextToolMode.Resizing || this.mode === TextToolMode.Moving) {
          if (this.showKeyboardFab) this.showKeyboardFab.style.opacity = '0';
          if (isLayerOk(this.layer, this.editor.drawing)) {
            // we want to commit to history at this point and not at start because focusing and moving
            // uses the same technique and it is known which one is executed at this point, not in start
            // and committing to history on one client and not doing that on other can cause desyncs,
            // state is not modified yet
            finishTransform(this.editor as Editor, this.model.user, `TextTool:doMove:${this.mode}`);
            this.mousePreundo = this.getHistoryEntry(this.layer, this.mode);
          }
        }
      }
    }

    switch (this.mode) {
      case TextToolMode.Creating: {
        this.rect.x = startPoint.x;
        this.rect.y = startPoint.y;
        this.rect.w = x - startPoint.x;
        this.rect.h = y - startPoint.y;
        normalizeRect(this.rect);
        break;
      }
      case TextToolMode.Moving: {
        if (this.dragged) {
          assertTextarea(this.textarea);
          const dx = this.startPointTransformed!.x - this.endPointTransformed!.x;
          const dy = this.startPointTransformed!.y - this.endPointTransformed!.y;
          this.textarea.x = this.originalRect.x - dx - this.textarea.negativeOffset;
          this.textarea.y = this.originalRect.y - dy;
          this.copyTextareaRect(this.textarea);
        }
        break;
      }
      case TextToolMode.Resizing: {
        assertTextareaControlPoint(this.resizingControlPoint);
        assertTextarea(this.textarea);

        this.resizingControlPoint.active = true;

        if (this.dragged) {
          let dx = this.endPointTransformed.x - this.startPointTransformed!.x;
          let dy = this.endPointTransformed.y - this.startPointTransformed!.y;

          if (this.textarea.resizedNegativelyX) dx = this.startPointTransformed!.x - this.endPointTransformed.x;
          if (this.textarea.resizedNegativelyY) dy = this.startPointTransformed!.y - this.endPointTransformed.y;

          const flipped = this.resizingControlPoint.onDrag?.(dx, dy, this.textarea, this.originalRect, this.resizingControlPoint);
          if (flipped) {
            // transformation matrix is changed during flip therefore we need to
            // transform starting point again for dx/dy to be accurate
            this.startPointTransformed = this.getTransformedPoint(this.startPoint!);
          }
          this.copyTextareaRect(this.textarea);
        }
        break;
      }
      case TextToolMode.Selecting: {
        if (this.textarea && (!isMobile || (isMobile && this.mobileSelectionStarted))) {
          const assumedNewEndDoubleIndex = pointToDoubledIndex(this.textarea, this.endPointTransformed);
          if (this.selectDoubleIndexEnd !== assumedNewEndDoubleIndex) {
            this.selectDoubleIndexEnd = assumedNewEndDoubleIndex;
            this.updateSelecting(shiftPressed);
          }
        }
        break;
      }
    }

    this.cursor = this.getCursor(this.mode, this.resizingControlPoint);
    if (this.mode !== TextToolMode.Selecting) this.recomputeTextLocally(this.mode);
  }

  private updateSelecting(shiftPressed: boolean) {
    if (!(this.textarea && this.input && this.selectDoubleIndexStart !== undefined && this.selectDoubleIndexEnd !== undefined && !!this.textarea.characters.length)) return;

    let start = undefined;
    let end = undefined;

    const doubleIndexDiff = this.selectDoubleIndexEnd - this.selectDoubleIndexStart;
    let direction = TextSelectionDirection.None;
    if (doubleIndexDiff > 0) direction = TextSelectionDirection.Forward;
    if (doubleIndexDiff < 0) direction = TextSelectionDirection.Backward;

    start = doubleIndexToCharacter(this.textarea, this.selectDoubleIndexStart).index;
    end = doubleIndexToCharacter(this.textarea, this.selectDoubleIndexEnd).index;

    if (start !== undefined && end !== undefined) {
      if (this.textSelection && shiftPressed) {
        direction = this.textSelection.direction;
        if (direction !== TextSelectionDirection.Backward) {
          start = this.textSelection.start;
          if (end < start) {
            [start, end] = [end, start];
            direction = TextSelectionDirection.Backward;
          }
        } else {
          start = end;
          end = this.textSelection.end;
          if (end < start) {
            [start, end] = [end, start];
            direction = TextSelectionDirection.Forward;
          }
        }
      } else {
        [start, end] = this.sanitizeMouseSelectionRange(start, end);
        [start, end] = this.extendRangeByMulticlickLevel(start, end);
      }

      this.textarea.lastSelectionChangeSource = 'mouse';
      this.textarea.determineIfCaretAtEOL(direction !== TextSelectionDirection.Backward ? end : start, this.selectDoubleIndexEnd);
      if (this.textSelection) {
        this.textSelection.start = start;
        this.textSelection.end = end;
        this.textSelection.length = end - start;
        this.textSelection.direction = direction;
        this.textarea.previousSelection = undefined;
      }
    }
  }
  private sanitizeMouseSelectionRange(start: number, end: number): [number, number] {
    if (this.textarea) {
      if (start > end) [start, end] = [end, start];
      if (
        !this.textarea.viableForSelectionVisually(this.textarea.characters[start]) ||
        !this.textarea.viableForSelectionVisually(this.textarea.characters[end])
      ) {
        let newStart = undefined;
        let newEnd = undefined;
        for (let i = 0; i < Math.ceil((end - start) / 2); i++) {
          const fromStart = this.textarea.characters[start + i];
          const fromEnd = this.textarea.characters[end - i];

          if (newStart === undefined && this.textarea.viableForSelectionVisually(fromStart)) {
            newStart = fromStart.index;
          }

          if (newEnd === undefined && this.textarea.viableForSelectionVisually(fromEnd)) {
            newEnd = fromEnd.index;
          }

          if (newStart !== undefined && newEnd !== undefined) break;
        }

        return [(newStart ?? start), (newEnd ?? end) + 1];
      }
    }
    return [start, end];
  }
  private extendRangeByMulticlickLevel(start: number, end: number): [number, number] {
    if (!this.textarea) return [start, end];

    try {
      if (this.multiClicks === 2) {
        return this.extendRangeByWords(this.textarea, start, end);
      } else if (this.multiClicks === 3) {
        return this.extendRangeByLines(this.textarea, start, end);
      } else if (this.multiClicks === 4) {
        return this.extendRangeByParagraphs(this.textarea, start, end);
      } else if (this.multiClicks > 4) {
        return [0, this.textarea.characters.length - 1]; // select enitre text
      } else {
        return [start, end]; // selection by letters, dont't do anything
      }
    } catch (e) {
      DEVELOPMENT && console.log(e);
      return [start, end]; // fallback to selection by letters
    }
  }
  private extendRangeByWords(textarea: Textarea, start: number, end: number): [number, number] {
    const words = charactersToWordsWithWhitespaces(textarea.characters);
    let newStart = start;
    let newEnd = end;
    let foundStart = false;
    let foundEnd = false;

    wordSearch: for (let i = 0; i < words.length; i++) {
      const word = words[i];
      for (const character of word) {
        if (character.index === start) {
          newStart = word[0].index;
          foundStart = true;
        }
        if (end >= word[0].index && end <= word[word.length - 1].index) {
          newEnd = word[word.length - 1].index + 1;
          foundEnd = true;
        }
        if (foundStart && foundEnd) break wordSearch;
      }
    }

    return [newStart, newEnd];
  }
  private extendRangeByLines(textarea: Textarea, start: number, end: number): [number, number] {
    const newStart = textarea.findLineDataFromCharacter(textarea.characters[start]).startAt;
    const newEnd = textarea.findLineDataFromCharacter(textarea.characters[end]).breaklineAt + 1;
    return [newStart, newEnd];
  }
  private extendRangeByParagraphs(textarea: Textarea, start: number, end: number): [number, number] {
    const newStart = textarea.findParagraphFromCharacter(textarea.characters[start]).characters[0].index;
    const endParagraph = textarea.findParagraphFromCharacter(textarea.characters[end]);
    const newEnd = endParagraph.characters[endParagraph.characters.length - 1].index + 1;
    return [newStart, newEnd];
  }

  private applyGlobalCharacterFormatting(formatting: CharacterFormatting, focusInput = true) {
    if (this.textarea) {
      this.applyCharacterFormatting(formatting, { start: 0, length: this.textarea.characters.length }, focusInput);
    }
  }
  private applyCharacterFormattingToSelection(formatting: CharacterFormatting, focusInput = true) {
    if (this.textSelection) {
      this.applyCharacterFormatting(formatting, this.textSelection, focusInput);
    }
  }
  private syncingRemoteLock = false;
  private slidingFormattingLayerId = 0;
  private pushingHistoryLock = false;
  lockSlidingFormatting(formattedPropertyName: string) {
    if (isLayerOk(this.layer, this.editor.drawing) && !this.slidingFormattingLayerId && isLoadedConnectedAndNotLocked(this.editor as Editor)) {
      this.syncingRemoteLock = true;
      this.pushingHistoryLock = true;
      this.slidingFormattingLayerId = this.layer.id;
      logAction(`lockSlidingFormatting(${formattedPropertyName}) - layer: ${this.slidingFormattingLayerId}, focused: ${this.focused}, hasGlyphsInSelection: ${this.hasGlyphsInSelection}`);
      if (!this.focused || this.hasGlyphsInSelection) {
        // else it would just remember state to apply on next character which is not what we want to add as history entry until they are added
        finishTransform(this.editor as Editor, this.model.user, 'TextTool.lockSlidingFormatting');
        this.confirmDeferredChange();
        this.characterFormattingUndo = this.getHistoryEntry(this.layer, `${TextToolMode.Formatting}-c-${formattedPropertyName}${selectionToString(this.textSelection)}`);
        if (this.characterFormattingUndo) this.globalHistory.pushUndo(this.characterFormattingUndo);
      }
    }
  }
  releaseSlidingFormatting(andDoWhat: () => void) {
    logAction(`releaseSlidingFormatting - layer: ${this.slidingFormattingLayerId}`);
    this.syncingRemoteLock = false;
    if (this.layer?.id === this.slidingFormattingLayerId) andDoWhat();
    this.pushingHistoryLock = false;
    this.slidingFormattingLayerId = 0;
  }
  private characterFormattingUndo: IUndoFunction | undefined = undefined;
  private applyCharacterFormatting(formatting: CharacterFormatting, range: TextRange, focusInput = true) {
    if (isLayerOk(this.layer, this.editor.drawing) && isTextareaOk(this.textarea) && range) {
      const { start, length } = range;

      if (!this.pushingHistoryLock) {
        this.confirmDeferredChange();
        this.characterFormattingUndo = this.getHistoryEntry(this.layer, `${TextToolMode.Formatting}-c-${getAppliedFormattingName(formatting)}${selectionToString(this.textSelection)}`);
        if (this.characterFormattingUndo) this.globalHistory.pushUndo(this.characterFormattingUndo);
      }

      this.textarea.formatCharacters({ ...formatting, start, length }, false);
      const opts = this.recomputeTextLocally(TextToolMode.Formatting);

      if (!this.syncingRemoteLock) {
        this.commitChangesOnRemote(this.layer, !this.characterFormattingUndo, opts, `${TextToolMode.Formatting}-c-${getAppliedFormattingName(formatting)}${selectionToString(this.textSelection)}`);
      }
    }
    if (focusInput && !isMobile) this.input?.focus();
  }
  private applyParagraphFormatting(formatting: ParagraphFormatting) {
    if (isLayerOk(this.layer, this.editor.drawing) && isTextareaOk(this.textarea) && this.textSelection) {
      this.confirmDeferredChange();
      const goToStateBefore = this.getHistoryEntry(this.layer, `${TextToolMode.Formatting}-p-${getAppliedFormattingName(formatting)}${selectionToString(this.textSelection)}`);
      if (goToStateBefore) this.globalHistory.pushUndo(goToStateBefore);
      for (let paragraphIndex of this.textSelection.paragraphIndexes) {
        this.textarea.formatParagraph({ ...formatting, index: paragraphIndex }, false);
      }
      this.commitChangesOnRemote(this.layer, !goToStateBefore, this.recomputeTextLocally(TextToolMode.Formatting), `${TextToolMode.Formatting}-p-${getAppliedFormattingName(formatting)}${selectionToString(this.textSelection)}`);
      if (!isMobile) this.focused = this.focused;
    }
  }
  private applyTextareaFormatting(formatting: TextareaFormatting) {
    if (isLayerOk(this.layer, this.editor.drawing) && isTextareaOk(this.textarea)) {
      this.confirmDeferredChange();
      const goToStateBefore = this.getHistoryEntry(this.layer, `${TextToolMode.Formatting}-t-${getAppliedFormattingName(formatting)}`);
      if (goToStateBefore) this.globalHistory.pushUndo(goToStateBefore);
      this.textarea.formatTextarea(formatting, false);
      this.commitChangesOnRemote(this.layer, !goToStateBefore, this.recomputeTextLocally(TextToolMode.Formatting), `${TextToolMode.Formatting}-t-${getAppliedFormattingName(formatting)}`);
      if (!isMobile) this.focused = this.focused;
    }
  }

  // this function gets curren state of textarea and confirms it in serializable data in layer (layer.textData)
  // recomputes all necesary recomputements and returns doTool options which
  // can be used to request synchronization at remote clients
  private recomputeTextLocally(mode: TextToolMode = this.mode) {
    if (!isLayerOk(this.layer, this.editor.drawing) || !canDrawTextLayer(this.layer)) return undefined;
    const layer = this.layer;
    const textarea = layer.textarea;

    normalizeRect(this.rect);

    textarea.previousSelection = undefined;
    textarea.write(clearLoneSurrogateCharacters(textarea.text));
    textarea.syncControlPoints();

    const layerIndex = this.editor.drawing.layers.indexOf(layer);
    const layerData = this.constructTextLayerData(layer.id);

    (layer as Layer).textData = layerData.textData; // cast is needed because possibly we want to convert this back to raster layer
    layer.invalidateCanvas = true;

    redrawLayer(this.editor, layer);

    const doToolOptions: TextToolData = {
      id: ToolId.Text, mode,
      layerIndex, layerData,
      replace: false,
      preventHistory: mode === TextToolMode.Focusing,
    };

    return doToolOptions;
  }

  hover(x: number, y: number, e?: TabletEvent) {
    if (e && hasCtrlOrMetaKey(e)) {
      const layer = pickLayerFromEditor(this.editor, x, y, true, true);
      if (layer && layer !== this.model.user.activeLayer) {
        this.cursor = CursorType.Pointer;
      } else {
        this.cursor = CursorType.Default;
      }
      return;
    }

    if (this.toolCreationLock) {
      this.cursor = CursorType.Loading;
      return;
    }

    const hovered = createPoint(x + this.editor.drawing.x, y + this.editor.drawing.y);

    const textarea = this.textarea;
    if (isTextareaOk(textarea) && !this.isTextLayerLocked) {

      const insideTextarea = textarea.pointInFrame(hovered);
      const hoveredControlPoint = textarea.pointInControlPoint(hovered, this.editor.view);

      textarea.syncControlPoints();
      if (!textarea.activeControlPoint) {
        textarea.controlPoints.sort((a, b) => {
          const aDistance = distance(x, y, a.x, a.y);
          const bDistance = distance(x, y, b.x, b.y);
          if (bDistance - aDistance !== 0) {
            return bDistance - aDistance;
          } else {
            return b.lastUsed - a.lastUsed;
          }
        });
      }
      textarea.isHovering = !!hoveredControlPoint || textarea.pointInFrame(hovered);

      if (this.focused) {
        if (hoveredControlPoint) {
          this.cursor = this.getCursor(TextToolMode.Resizing, hoveredControlPoint);
        } else if (insideTextarea) {
          this.cursor = CursorType.Text;
        } else {
          this.cursor = CursorType.Move;
        }
      } else {
        if (hoveredControlPoint) {
          this.cursor = this.getCursor(TextToolMode.Resizing, hoveredControlPoint);
        } else if (insideTextarea) {
          this.cursor = CursorType.Text;
        } else {
          this.cursor = CursorType.SelectionAdd;
        }
      }
    } else {
      const realModel = (this.editor as Editor).model;
      if (realModel.isPresentationMode && !realModel.isPresentationHost && realModel.presentationModeState.participantsUiHidden) {
        this.cursor = CursorType.Default;
      } else {
        this.cursor = CursorType.SelectionAdd;
      }
    }

    redrawLayer(this.editor, this.layer);
  }

  private getCursor(action: TextToolMode, extra?: any) {
    if (action === TextToolMode.Selecting) {
      return CursorType.Text;
    } else if (action === TextToolMode.Resizing) {
      return this.getResizeCursor(extra);
    } else if (action === TextToolMode.Moving) {
      return CursorType.Move;
    } else {
      return CursorType.Default;
    }
  }

  private getResizeCursor(controlPoint: TextareaControlPoint) {
    const textarea = this.textarea;

    let flips = 0;
    if (textarea && textarea.transform[0] < 0) flips++;
    if (textarea && textarea.transform[3] < 0) flips++;
    if (this.editor.view.flipped) flips++;

    switch (controlPoint.direction) {
      case TextareaControlPointDirections.North:
      case TextareaControlPointDirections.South:
        return CursorType.ResizeV;
      case TextareaControlPointDirections.East:
      case TextareaControlPointDirections.West:
        return CursorType.ResizeH;
      case TextareaControlPointDirections.NorthWest:
      case TextareaControlPointDirections.SouthEast:
        return isOdd(flips) ? CursorType.ResizeTR : CursorType.ResizeTL;
      case TextareaControlPointDirections.NorthEast:
      case TextareaControlPointDirections.SouthWest:
        return isOdd(flips) ? CursorType.ResizeTL : CursorType.ResizeTR;
      default:
        return CursorType.Move;
    }
  }

  private constructTextLayerData(layerId: number, text?: string): LayerData {
    integerizeRect(this.rect);
    const options: TextareaOptions = {
      type: this.textareaType,
      x: this.rect.x,
      y: this.rect.y,
      text: text ?? this.textarea?.text ?? '',
      textareaFormatting: {
        ...DEFAULT_TEXTAREA_FORMATTING,
        ...this.textarea?.textareaFormatting,
      },
      paragraphFormattings: this.textarea?.paragraphFormattings || [],
      characterFormattings: this.textarea?.characterFormattings || [],
      defaultFontFamily: this.textarea?.defaultFontFamily || this.fontFamily || (this.availableFontFamilies.length ? this.availableFontFamilies[0].name : this.selectableFontFamilies[0]),
      dirty: this.textarea?.dirty || false,
      isFocused: this.focused,
    };

    if (isBoxTextareaType(this.textareaType)) {
      options.w = this.rect.w;
      options.h = this.rect.h;
    }

    return {
      id: layerId,
      name: truncate(this.layer?.name.trim() || text?.trim() || '', { length: LAYER_NAME_LENGTH_LIMIT }),
      textData: options
    };
  }

  private getFormattingPropertyFromPanel(property: (keyof TextTool), defaultsObject: any) {
    const value = this[property];
    if (value === undefined) return null; // mixed
    if (defaultsObject[property] !== undefined && value === defaultsObject[property]) return undefined;
    if (value === AUTO_SETTING_STRING) return undefined;
    return value;
  }
  private addPropertyFromPanelToFormatting<T extends AnyLevelTextFormattingDescription>(formatting: T, property: (keyof T & keyof TextTool), defaultsObject: any) {
    const value = this.getFormattingPropertyFromPanel(property, defaultsObject);
    if (value) formatting[property] = value;
  }
  private getCharacterFormattingFromPanel() {
    const entireTextFormatting: CharacterFormattingDescription = { start: 0, length: LONG_TEXTAREA_PLACEHOLDER.length };

    const activeColor = (this.editor as Editor).activeColor;
    if (activeColor) entireTextFormatting.color = colorToCSS(activeColor);

    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'baselineShift', DEFAULT_CHARACTER_FORMATTING);
    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'lineheight', DEFAULT_CHARACTER_FORMATTING);
    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'letterSpacing', DEFAULT_CHARACTER_FORMATTING);
    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'bold', DEFAULT_CHARACTER_FORMATTING);
    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'italic', DEFAULT_CHARACTER_FORMATTING);
    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'underline', DEFAULT_CHARACTER_FORMATTING);
    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'strikethrough', DEFAULT_CHARACTER_FORMATTING);
    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'scaleX', DEFAULT_CHARACTER_FORMATTING);
    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'scaleY', DEFAULT_CHARACTER_FORMATTING);
    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'textCase', DEFAULT_CHARACTER_FORMATTING);

    if (this.fontFamily) entireTextFormatting.fontFamily = this.fontFamily;

    if (this.fontSize !== undefined && this.fontSize !== AUTO_SETTING_STRING && this.fontSize !== DEFAULT_CHARACTER_FORMATTING.size) {
      // font size is handled manually because it has different keys / types (ITool.size) in TextTool and CharacterFormatting
      entireTextFormatting.size = this.fontSize;
    }

    return isValidCharacterFormatting(entireTextFormatting) ? entireTextFormatting : undefined;
  }
  private getParagraphFormattingFromPanel() {
    const entireTextFormatting: ParagraphFormattingDescription = { index: 0 };
    this.addPropertyFromPanelToFormatting(entireTextFormatting, 'alignment', DEFAULT_PARAGRAPH_FORMATTING);
    return isValidParagraphFormatting(entireTextFormatting) ? entireTextFormatting : undefined;
  }
  private getTextareaFormattingFromPanel() {
    const textareaFormatting: TextareaFormatting = {};
    this.addPropertyFromPanelToFormatting(textareaFormatting, 'verticalAlignment', DEFAULT_TEXTAREA_FORMATTING);
    return Object.keys(textareaFormatting).length > 0 ? textareaFormatting : undefined;
  }
  private toolCreationLock = false;

  private dispatchAsyncRasterization(layer: TextLayer, data: TextToolData) {
    return loadFontsForLayer(layer, this.editor as Editor).then(() => {
      cacheTextareaInLayer(layer);
      this.rasterizeRemote(layer, data);
    });
  }
  private rasterizeRemote(layer: ReadyTextLayer, data: TextToolData) {
    logAction(`[remote] rasterizing (layerId: ${layer.id}, (currentRect: ${rectToString(layer.rect)}) -> (croppedRect: ${rectToString(data.croppedRect)}))`);
    setTextLayerDirty(this.editor, layer);

    this.rasterizeInternal(layer);
    if (!data.croppedRect) throw new Error('Missing cropped rect for remote rasterization.');
    this.editor.renderer.trimLayer(layer, data.croppedRect, true);

    normalizeRect(this.rect);
    redrawDrawing(this.editor);
  }
  rasterizeLocal(layer: TextLayer) {
    if (!canDrawTextLayer(layer)) return;

    this.confirmDeferredChange();
    logAction(`[local] text:rasterize (layer: ${layer.id})`);

    setTextLayerDirty(this.editor, layer);
    finishTransform(this.editor, this.model.user, 'rasterizeLocal');
    this.editor.renderer.releaseLayer(layer);

    this.globalHistory.execTransaction((history) => {
      history.pushLayerState(layer.id, TextToolMode.Rasterizing);

      this.rasterizeInternal(layer);

      let croppedRect = createRect(0, 0, 0, 0);
      if (layer.rect && !isRectEmpty(layer.rect) && layerHasImageData(layer)) {
        const layerBitmapData = this.editor.renderer.getLayerRawData(layer);
        croppedRect = getImageDataBounds(layerBitmapData);
        if (!isRectEmpty(croppedRect)) {
          moveRect(croppedRect, layer.rect.x, layer.rect.y);
        } else {
          croppedRect.x = 0;
          croppedRect.y = 0;
        }
        this.editor.renderer.trimLayer(layer, croppedRect, false);
      } else {
        this.editor.renderer.releaseLayer(layer);
      }

      normalizeRect(this.rect);
      redrawDrawing(this.editor);

      const doToolOptions: TextToolData = {
        id: ToolId.Text,
        mode: TextToolMode.Rasterizing,
        layerIndex: 0,
        layerData: { id: layer.id, },
        br: undefined, // we don't want to compare before rects because worker may skip few states
        ar: cloneRect(croppedRect),
        croppedRect: cloneRect(croppedRect),
      };

      if (layer === this.model.user.activeLayer) this.onLayerChange(layer);

      history.pushDirtyRect('rasterizing-text', layer.id, croppedRect, false, true);
      this.model.doTool(layer.id, doToolOptions);
    });
  }

  private rasterizeInternal(layer: ReadyTextLayer) {
    layer.invalidateCanvas = true;
    this.editor.renderer.drawTextLayer(layer, this.editor.drawing);
    layer.name = getLayerName(layer);
    (layer as Layer).textData = undefined;
    (layer as TextLayer).textarea = undefined;
  }
  private becomeIdle() {
    this.startSnapshot = undefined;
    this.mode = TextToolMode.Typing;
    this.cursor = CursorType.Default;
    this.dragged = false;
    this.selectDoubleIndexStart = undefined;
    this.selectDoubleIndexEnd = undefined;
    this.resizingControlPoint = undefined;
    this.toolCreationLock = false;
    setPoint(this.startPoint, 0, 0);
    setPoint(this.endPoint, 0, 0);
    setPoint(this.startPointTransformed, 0, 0);
    setPoint(this.endPointTransformed, 0, 0);
    setRect(this.originalRect, 0, 0, 0, 0);
    if (this.textarea) {
      this.textarea.isResizing = false;
      if (this.textarea.activeControlPoint) {
        this.textarea.activeControlPoint.active = false;
        this.textarea.activeControlPoint = undefined;
      }
      this.textarea.resizedNegativelyX = false;
      this.textarea.resizedNegativelyY = false;
    }
  }
  becomeLayerless() {
    this.confirmDeferredChange();
    this.scheduledLayer = undefined;
    this.layer = undefined;
    if (this.input) {
      this.input.blur();
      this.input.remove();
    }
    if (this.showKeyboardFab) this.showKeyboardFab.remove();
    this.showKeyboardFab = undefined;
    this.input = undefined;
    this.lastInputEvent = 0;
    this.forceCommitOnInputEvent = true;
    this.panelState = undefined;
    this.mode = TextToolMode.Typing;
    this.activeColorEmpty = false;
    if (!SERVER) this.focused = false;
    this.keyboardOpened = false;
  }

  private get selectedEntireTextOrCaretAtStart() {
    if (!this.textSelection || !this.textarea) return false;
    const { start, end } = this.textSelection;
    const characters = this.textarea.characters;
    return (start <= 0 && end >= characters.length - 1) || start === 0;
  }

  private lastInputEvent = 0;
  private forceCommitOnInputEvent = false;
  private deferredChangeTimeout: NodeJS.Timeout | undefined = undefined;
  private deferredChangeFn: Function | undefined = undefined;
  private getDeferredFn(layer: TextLayer, opts: TextToolData, type: string) {
    return () => {
      logAction(`text tool deferred change`);

      if (!this.typingPreundo) {
        throw new Error('Typing preundo not existent on deferred change');
      }

      if (this.typingPreundo.layerId !== this.layer?.id) {
        const preundoLayerId = this.typingPreundo.layerId;
        this.typingPreundo = undefined;
        throw new Error(`Typing preundo not matching current layer (${preundoLayerId} != ${this.layer?.id}, ${type} async)`);
      }

      this.globalHistory.pushUndo(this.typingPreundo);
      this.model.doTool(layer.id, opts);
      this.lastInputEvent = 0;
      this.typingPreundo = undefined;
    };
  }
  private scheduleDeferredChange(fn: Function) {
    if (this.deferredChangeTimeout) this.clearDeferredChange();
    this.deferredChangeFn = fn;
    this.deferredChangeTimeout = setTimeout(() => {
      this.confirmDeferredChange();
    }, TYPING_IDLE_DEBOUNCE);
  }
  private clearDeferredChange() {
    if (this.deferredChangeTimeout) {
      clearTimeout(this.deferredChangeTimeout);
      this.deferredChangeTimeout = undefined;
      this.lastInputEvent = 0;
      this.deferredChangeFn = undefined;
    }
  }
  confirmDeferredChange() {
    if (isLoadedConnectedAndNotLocked(this.editor as Editor)) {
      this.deferredChangeFn?.();
    } else if (this.deferredChangeTimeout) {
      logAction('TextTool.confirmDeferredChange silent typing preundo drop');
      this.typingPreundo = undefined;
    }
    this.clearDeferredChange();
  }
  private recreateInput(layer: TextLayer) {
    this.input = document.createElement('textarea');
    this.input.className = TEXT_TOOL_ARTIFICIAL_TEXTAREA_CLASS;
    this.input.style.position = 'absolute';
    this.input.style.width = `${ARTIFICIAL_TEXTAREA_SIZE}px`;
    this.input.style.height = `${ARTIFICIAL_TEXTAREA_SIZE}px`;
    this.input.style.whiteSpace = 'pre'; // this is very important, it causes whitespaces to be preserved in original form when ingesting into textarea object instead of collapsing them together and forming "\n" characters
    this.input.style.pointerEvents = 'none';
    setInputPositionOnPage(this.input, this.editor.view, layer.textData.x, layer.textData.y);
    if (this.textarea) setShowKeyboardFabPositionOnPage(this.showKeyboardFab, this.editor.view, this.textarea);
    // (this.input as any).autocorrect = 'off'; // seems to work for now
    (this.input as any).autocapitalize = 'off'; // for iOS
    (this.input as any).autocomplete = 'off'; // for android
    this.input.spellcheck = false;
    this.input.style.opacity = '0';
    this.input.style.fontSize = '18px';
    this.input.value = this.layer?.textData.text ?? '';
    this.lastInputValue = this.input.value;
    if (DEVELOPMENT && SHOW_ARTIFICIAL_TEXTAREA) {
      this.input.style.zIndex = '9999';
      this.input.style.opacity = '100%';
    }

    document.body.appendChild(this.input);

    this.input.addEventListener('compositionstart', (e) => {
      const input = (e.target as HTMLTextAreaElement);
      this.beforeCompositionSelection[0] = input.selectionStart;
      this.beforeCompositionSelection[1] = input.selectionEnd;
      this.beforeCompositionSelection[2] = input.selectionDirection as TextSelectionDirection;
      this.composeInputEvents = true;
    });

    this.input.addEventListener('compositionend', (e) => {
      const input = (e.target as HTMLTextAreaElement);
      input.value = sanitizeEmojis(input.value);
      this.composeInputEvents = false;
      const insertedCharacters = sanitizeEmojis(e.data ?? '');
      let [start, end, direction] = this.beforeCompositionSelection;
      start += insertedCharacters.length;
      end = start;
      input.setSelectionRange(start, end, direction);
      this.inputHandler({ preventDefault() { }, target: input } as any);
    });

    this.input.addEventListener('beforeinput', async (e) => {
      try {
        const notIdle = this.mode !== TextToolMode.Typing;
        const virtualKeyboardPasting = e.inputType === 'insertFromPaste'; // present at least on iPad

        if (virtualKeyboardPasting) {
          if (e.isTrusted && clipboardSupported()) {
            const providedPlainText = await navigator.clipboard.readText();
            this.paste({ providedText: providedPlainText, providedPlainText, forcePlainText: true })
              .catch((e) => { DEVELOPMENT && alert(e); });
          } else {
            this.editor.toast((toastService) => {
              toastService.warning({
                position: MsgPosition.TopLeft,
                message: 'Paste from virtual keyboard was blocked due to security policies. Consider using applications built-in paste under context menu (long-press on mobile devices).'
              });
            });
          }
        }

        const rejectEvent = notIdle || virtualKeyboardPasting || !isLoadedConnectedAndNotLocked(this.editor as Editor);
        if (rejectEvent) {
          e.preventDefault();
          e.stopImmediatePropagation();
          e.stopPropagation();
        }
      } catch (err) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        (this.editor as Editor).errorReporter.reportError(err);
        this.editor.toast((toastService) => {
          toastService.error({ message: 'Error occurred, text input rejected.' });
        });
      }
    });

    this.input.addEventListener('input', (e) => {
      if (!isLoadedConnectedAndNotLocked(this.editor as Editor)) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      if (!this.composeInputEvents) {
        this.inputHandler(e as InputEvent);
      }
    });

    if (!clipboardSupported()) {
      this.input.addEventListener('copy', (e) => {
        if (!isLoadedConnectedAndNotLocked(this.editor as Editor)) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        if (!this.textarea || !this.textSelection) throw new Error('Can\'t copy text from textarea. No textarea or selection.');
        e.preventDefault();
        const successfullyCopied = copyTextFromTextareaViaClipboardEvent(e, this.textarea, this.textSelection);
        if (!successfullyCopied) {
          displayToastAboutNotSupportedClipboard(this.editor, 'Copying');
        }
      });
    }

    if (!clipboardSupported()) {
      this.input.addEventListener('cut', (e) => {
        if (!isLoadedConnectedAndNotLocked(this.editor as Editor)) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        if (!isLayerOk(this.layer, this.editor.drawing) || !this.textarea || !this.textSelection || !this.input) throw new Error('Can\'t cut text from textarea.');
        e.preventDefault();
        this.confirmDeferredChange();
        const successfullyCut = cutTextFromTextareaViaClipboardEvent(e, this.textarea, this.textSelection);
        if (successfullyCut) {
          this.globalHistory.pushLayerState(this.layer.id, TextToolMode.Typing);
          this.commitChangesOnRemote(this.layer, false, this.recomputeTextLocally(TextToolMode.Typing));
        } else {
          displayToastAboutNotSupportedClipboard(this.editor, 'Cutting');
        }
      });
    }

    if (!clipboardSupported()) {
      this.input.addEventListener('paste', (e) => {
        if (!isLoadedConnectedAndNotLocked(this.editor as Editor)) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        if (!isLayerOk(this.layer, this.editor.drawing) || !this.textarea || !this.textSelection || !this.input) throw new Error('Can\'t paste text into textarea.');
        e.preventDefault();
        if (this.selectedEntireTextOrCaretAtStart) this.rememberPanelState();
        this.confirmDeferredChange();
        if (!e.clipboardData) throw new Error('Failed to copy text from textarea via ClipboardEvent, e.clipboardData is null');
        const goToStateBefore = this.globalHistory.createLayerState(this.layer.id, TextToolMode.Typing);
        const html = sanitizeEmojis(e.clipboardData.getData('text/html'));
        let htmlRoot;
        let newSelection: TextSelectionDetailed;
        try {
          htmlRoot = parseTextboxHtml(html);
          if (htmlRoot && !this.pastingWithoutStylesOnFirefox) {
            newSelection = insertRichText(this.textarea, this.textSelection, htmlRoot);
          } else {
            const { pastedTruncatedText } = truncateRichText(this.textarea, this.textSelection, e.clipboardData.getData('text/plain') ?? '');
            newSelection = insertCharactersIntoTextarea(this.textarea, this.textSelection, pastedTruncatedText, this.panelState);
          }
          if (this.textarea.text !== this.layer.textData.text) {
            this.globalHistory.pushUndo(goToStateBefore);
            this.commitChangesOnRemote(this.layer, false, this.recomputeTextLocally(TextToolMode.Typing));
            this.textSelection = newSelection;
          }
        } catch (e) {
          displayToastAboutNotSupportedClipboard(this.editor, 'Pasting');
          DEVELOPMENT && console.error(`An error occurred when pasting ${htmlRoot ? '' : 'non-'}HTML from clipboard: `, e);
        }
        this.pastingWithoutStylesOnFirefox = false;
      });
    }

    if (isMac) {
      this.input.addEventListener('keydown', (e) => {
        if (e.key === 'Meta' && !this.macComposingMeta) {
          this.macComposingMeta = true;
        }

        switch (e.key) {
          case 'a': {
            if (this.macComposingMeta && this.focused) {
              e.stopPropagation();
              this.selectEntireText();
            }
            break;
          }
        }
      });

      this.input.addEventListener('keyup', (e) => {
        if (e.key === 'Meta') {
          this.macComposingMeta = false;
        }
      });
    }

    this.input.addEventListener('keydown', this.keydownHandler);

    this.input.focus();
  }
  private pastingWithoutStylesOnFirefox = false;
  private deletingSelection = 0;
  private macComposingMeta = false;
  keydownHandler = (e: KeyboardEvent) => {
    if (
      this.mode !== TextToolMode.Typing ||
      this.slidingFormattingLayerId ||
      (!TESTS && !isLoadedConnectedAndNotLocked(this.editor as Editor))
    ) {
      e.preventDefault();
      e.stopPropagation();
      return;
    } else if (!this.input || !this.textarea || !isLayerOk(this.layer, this.editor.drawing)) {
      return;
    }

    const { keyCode, ctrlKey, metaKey, shiftKey } = e;
    const ctrlOrMeta = ctrlKey || metaKey;

    if (e.target !== this.input) {
      // we were text layer but on different tool, captured event propagated to body
      // and now replaying to invoke default behavior on proper target (textarea input)
      const duplicatedEvent = new (e.constructor as { new(...args: any[]): Event })(e.type, e);
      Object.defineProperty(duplicatedEvent, 'target', { writable: true });
      (duplicatedEvent as Mutable<Event>).target = this.input;
      e.stopPropagation();
      this.input.dispatchEvent(duplicatedEvent);
    } else if (!this.isUsingTextTool) {
      // we're on text layer but using some different tools, let's not capture events then and
      // forward them to keyboard service under all conditions
      (e as MagmaKeyboardEvent).allowKeyboardService = true;
    } else if (this.isTextLayerLocked) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      switch (keyCode) {
        case Key.Backspace: {
          if (this.focused && this.textSelection && (this.textSelection.start !== 0 || this.hasGlyphsInSelection)) {
            e.stopPropagation();
            this.deletingSelection = ctrlOrMeta && !this.hasGlyphsInSelection ? -countCtrlExtension(this.textarea, this.textSelection.start, true, false) : -1;
            this.prefillInputForTextRemoval(this.input, this.layer, this.textSelection);
          }
          break;
        }
        case Key.Delete: {
          if (this.focused && this.textSelection && (this.textSelection.end !== this.textarea.text.length || this.hasGlyphsInSelection)) {
            e.stopPropagation();
            this.deletingSelection = ctrlOrMeta && !this.hasGlyphsInSelection ? countCtrlExtension(this.textarea, this.textSelection.start, true, true) : 1;
            this.prefillInputForTextRemoval(this.input, this.layer, this.textSelection);
          }
          break;
        }
        case Key.A: {
          if (this.focused) {
            e.stopPropagation();
            if (ctrlKey) {
              this.textSelection = {
                start: 0,
                end: this.textarea.text.length,
                length: this.textarea.text.length,
                direction: TextSelectionDirection.Forward,
                text: this.textarea.text,
                paragraphIndexes: generateNumbersArray(0, Math.max(this.textarea.paragraphs.length - 1, 0))
              };
            }
          }
          break;
        }
        case Key.Tab: {
          if (this.focused) {
            e.stopPropagation();
            this.handleTabKey(e, this.textarea);
          }
          break;
        }
        case Key.Z:
        case Key.Y: {
          if (ctrlOrMeta) {
            (e as MagmaKeyboardEvent).allowKeyboardService = true;
          }
          break;
        }
        case Key.Up: {
          if (this.focused) {
            e.stopPropagation();
            e.preventDefault();
            this.textarea.blinkOffset = performance.now();
            this.textSelection = handleArrowUpVerticalSelection(e, this.textarea, this.textSelection);
          }
          break;
        }
        case Key.Down: {
          if (this.focused) {
            e.stopPropagation();
            e.preventDefault();
            this.textarea.blinkOffset = performance.now();
            this.textSelection = handleArrowDownVerticalSelection(e, this.textarea, this.textSelection);
          }
          break;
        }
        case Key.Home: {
          if (this.focused) {
            this.handleHomeKey(e, this.textarea, this.textSelection);
          }
          break;
        }
        case Key.End: {
          if (this.focused) {
            this.handleEndKey(e, this.textarea, this.textSelection);
          }
          break;
        }
        case Key.Esc: {
          if (this.focused && this.mode === TextToolMode.Typing) {
            e.stopPropagation();
            e.preventDefault();
            this.focused = false;
          }
          break;
        }
        case Key.Right: {
          if (this.focused && this.textSelection) {
            this.handleRightArrow(e, this.textarea, this.textSelection);
          }
          break;
        }
        case Key.Left: {
          if (this.focused && this.textSelection) {
            this.handleLeftArrow(e, this.textarea, this.textSelection);
          }
          break;
        }
        case Key.PageUp:
        case Key.PageDown: {
          if (this.focused) {
            e.stopPropagation();
            e.preventDefault();
          }
          break;
        }
        case Key.Space:
        case Key.Enter: {
          if (this.focused) {
            e.stopPropagation();
            this.forceCommitOnInputEvent = true;
            if (keyCode === Key.Enter) this.enteringEOL = true;
          }
          break;
        }
        case Key.B: {
          if (ctrlOrMeta) {
            e.stopPropagation();
            e.preventDefault();
            this.toggleBold('keydown:bold');
            this.editor.apply(() => { });
          }
          break;
        }
        case Key.I: {
          if (ctrlOrMeta) {
            e.stopPropagation();
            e.preventDefault();
            this.toggleItalic('keydown:italic');
            this.editor.apply(() => { });
          }
          break;
        }
        case Key.U: {
          if (ctrlOrMeta) {
            e.stopPropagation();
            e.preventDefault();
            this.toggleUnderline();
            this.editor.apply(() => { });
          }
          break;
        }
        case Key.C: {
          if (clipboardSupported() && ctrlOrMeta && this.focused) {
            e.stopPropagation();
            e.preventDefault();
            this.copy().catch((e) => {
              DEVELOPMENT && console.warn('Couldn\'t copy textarea text.', e);
            });
          }
          break;
        }
        case Key.X: {
          if (clipboardSupported() && ctrlOrMeta && this.focused && isLayerOk(this.layer, this.editor.drawing)) {
            e.stopPropagation();
            e.preventDefault();
            this.cut()?.catch((e) => {
              DEVELOPMENT && console.warn('Couldn\'t cut textarea text!.', e);
            });
          }
          break;
        }
        case Key.V: {
          if (!clipboardSupported() && ctrlOrMeta && this.focused && shiftKey) {
            this.pastingWithoutStylesOnFirefox = true;
          }
          if (clipboardSupported() && ctrlOrMeta && this.focused) {
            e.stopPropagation();
            e.preventDefault();
            this.paste({ forcePlainText: shiftKey })
              .catch((e) => {
                DEVELOPMENT && console.warn('Couldn\'t paste text in textarea.', e);
              });
          }
          break;
        }
        default: {
          if (this.focused && ctrlOrMeta) {
            // if we're unfocused every event is picked up because of input having 'ks-allow' class
            // if focused we want to make sure we don't handle key in explicit way in one of cases above,
            // if none and ctrl is pressed we want to allow propagation so keyboard service picks it up
            // and attach this custom property to make it pass validation (so it doesn't get ignored because of being event from input)
            (e as MagmaKeyboardEvent).allowKeyboardService = true;
          }
          break;
        }
      }
    }
  };
  private beforeCompositionSelection: TextSelection = [0, 0, TextSelectionDirection.None];
  private composeInputEvents = false;
  private typingPreundo: IUndoFunction | undefined = undefined;
  private mousePreundo: IUndoFunction | undefined = undefined;
  private enteringEOL = false;
  private prefillInputForTextRemoval(input: HTMLTextAreaElement, layer: TextLayer, selection: TextSelectionDetailed) {
    input.value = layer.textData.text;
    input.setSelectionRange(selection.start, selection.end, selection.direction);
  }
  private lastInputValue = '';
  inputHandler = (e: InputEvent) => {
    if (isiOS || isSafari) {
      this.iosInputHandler(e);
      return;
    }
    e.preventDefault();
    const input = (e!.target as HTMLTextAreaElement);
    const layer = this.layer;

    if (e.inputType === 'historyUndo' || e.inputType === 'historyRedo') {
      this.editor.toast(toastService => {
        toastService.warning({
          position: MsgPosition.TopLeft,
          message: 'Undo/redo typing is not supported. Consider using applications built-in undo/redo.'
        });
      });
    } else {
      const rejectEvent = this.mode !== TextToolMode.Typing; // isMobile && input.value.length > 1;
      if (isLayerOk(layer, this.editor.drawing) && this.focused && this.textarea && this.textSelection && !this.isTextLayerLocked && !rejectEvent) {
        let newValue = sanitizeEmojis(input.value);
        if (newValue.length > MAX_TEXTAREA_TEXT_LENGTH) {
          newValue = this.lastInputValue;
          input.value = newValue;
        }
        if (newValue === this.lastInputValue) return;
        let diff = newValue.length - this.lastInputValue.length;

        finishTransform(this.editor as Editor, this.model.user, 'TextTool:inputHandler');
        setTextLayerDirty(this.editor, layer);

        const debounce = (performance.now() > (this.lastInputEvent + TYPING_IDLE_DEBOUNCE)) && this.lastInputEvent !== 0;
        const synchronizeNow = (debounce || this.forceCommitOnInputEvent);

        if (!this.typingPreundo) {
          this.typingPreundo = this.globalHistory.createLayerState(layer.id, TextToolMode.Typing);
        }

        if (synchronizeNow && this.typingPreundo) {
          if (this.typingPreundo.layerId !== this.layer?.id) {
            const preundoLayerId = this.typingPreundo.layerId;
            this.typingPreundo = undefined;
            throw new Error(`Typing preundo not matching current layer (${preundoLayerId} != ${this.layer?.id}, regular sync)`);
          } else {
            this.globalHistory.pushUndo(this.typingPreundo);
            this.typingPreundo = undefined;
          }
        }

        if (this.selectedEntireTextOrCaretAtStart) this.rememberPanelState();
        const deletingForward = e.inputType === 'deleteWordForward' || e.inputType === 'deleteContentForward';
        let newSelection: TextSelectionDetailed = {
          start: this.textSelection.start,
          end: this.textSelection.start,
          length: 0,
          direction: TextSelectionDirection.None,
          text: '',
          paragraphIndexes: [],
        };

        if (this.textSelection.length) {
          newSelection.start += (sanitizeEmojis(e.data ?? '')).length;
          newSelection.start = clamp(newSelection.start, 0, newValue.length + 1);
          newSelection.end = newSelection.start;
        } else if (!deletingForward) {
          newSelection.start += diff;
          newSelection.start = clamp(newSelection.start, 0, newValue.length + 1);
          newSelection.end = newSelection.start;
        }
        applyTextAndParagraphIndexesToSelection(this.textarea, newSelection, true);

        this.textarea.onInputManagedSelection(this.textarea.text, newValue, this.textSelection, newSelection, this.panelState);
        if (this.textarea.formattingToApplyOnNextInput) this.updatePanelState([this.textarea.formattingToApplyOnNextInput]);
        if (this.panelState) this.panelState = undefined;

        const opts = this.recomputeTextLocally(TextToolMode.Typing);
        if (opts && synchronizeNow) {
          this.clearDeferredChange();
          this.model.doTool(layer.id, opts);
        } else if (opts) {
          this.scheduleDeferredChange(this.getDeferredFn(layer, opts, 'regular'));
        }

        this.enteringEOL = false;
        this.lastInputEvent = performance.now();
        this.forceCommitOnInputEvent = false;
        this.deletingSelection = 0;
        this.lastInputValue = newValue;
        this.textSelection = newSelection;
        // if (isMobile) this.showMobileKeyboard();
        this.editor.apply(() => { });
      }
    }
  };

  iosInputHandler = (e: InputEvent) => {
    e.preventDefault();
    const input = (e!.target as HTMLTextAreaElement);
    const layer = this.layer;

    if (e.inputType === 'historyUndo' || e.inputType === 'historyRedo') {
      this.editor.toast(toastService => {
        toastService.warning({
          position: MsgPosition.TopLeft,
          message: 'Undo/redo typing is not supported. Consider using applications built-in undo/redo.'
        });
      });
    } else {
      const rejectEvent = this.mode !== TextToolMode.Typing;
      if (isLayerOk(layer, this.editor.drawing) && this.focused && this.textarea && this.textSelection && !this.isTextLayerLocked && !rejectEvent) {
        let newValue = '';
        // when deleting text with backspace (delete) on iOS, input.value is empty for whatever reason
        const insertedCharacters = sanitizeEmojis(this.enteringEOL ? '\n' : (e.data ?? ''));
        let extraRemove = 0;

        if (this.hasGlyphsInSelection) {
          // deleting selection with backspace or other insertion
          newValue = this.textarea.text.substring(0, this.textSelection.start) + insertedCharacters + this.textarea.text.substring(this.textSelection.end);
        } else if (this.enteringEOL) {
          // entering EOL with eol key from caret
          newValue = this.textarea.text.substring(0, this.textSelection.start) + '\n' + this.textarea.text.substring(this.textSelection.end);
        } else if (this.deletingSelection > 0) {
          // deleting selection with delete from caret
          newValue = this.textarea.text.substring(0, this.textSelection.start) + this.textarea.text.substring(this.textSelection.end + this.deletingSelection);
        } else if (this.deletingSelection < 0) {
          // deleting selection with backspace from caret
          newValue = this.textarea.text.substring(0, this.textSelection.start + this.deletingSelection) + this.textarea.text.substring(this.textSelection.end);
          extraRemove = Math.abs(this.deletingSelection);
        } else {
          newValue = this.textarea.text.substring(0, this.textSelection.start) + insertedCharacters + this.textarea.text.substring(this.textSelection.end);
        }

        if (newValue.length > MAX_TEXTAREA_TEXT_LENGTH) {
          newValue = this.lastInputValue;
          input.value = newValue;
        }
        if (newValue === this.lastInputValue) return;

        finishTransform(this.editor as Editor, this.model.user, 'TextTool:inputHandler');
        setTextLayerDirty(this.editor, layer);

        this.enteringEOL = false;

        const debounce = (performance.now() > (this.lastInputEvent + TYPING_IDLE_DEBOUNCE)) && this.lastInputEvent !== 0;
        const synchronizeNow = (debounce || this.forceCommitOnInputEvent);

        if (!this.typingPreundo) {
          this.typingPreundo = this.globalHistory.createLayerState(layer.id, TextToolMode.Typing);
        }

        if (synchronizeNow && this.typingPreundo) {
          if (this.typingPreundo.layerId !== this.layer?.id) {
            const preundoLayerId = this.typingPreundo.layerId;
            this.typingPreundo = undefined;
            throw new Error(`Typing preundo not matching current layer (${preundoLayerId} != ${this.layer?.id}, ios sync)`);
          } else {
            this.globalHistory.pushUndo(this.typingPreundo);
            this.typingPreundo = undefined;
          }
        }

        if (this.selectedEntireTextOrCaretAtStart) this.rememberPanelState();
        let newSelection: TextSelectionDetailed = {
          start: this.textSelection.start,
          end: this.textSelection.start,
          length: 0,
          direction: TextSelectionDirection.None,
          text: '',
          paragraphIndexes: [this.textarea.findParagraphIndexFromCharacter(this.textarea.characters[0])],
        };
        newSelection.start += insertedCharacters.length;
        newSelection.start -= extraRemove;
        newSelection.end = newSelection.start;
        applyTextAndParagraphIndexesToSelection(this.textarea, newSelection, true);

        this.textarea.onInputManagedSelection(this.textarea.text, newValue, this.textSelection, newSelection, this.panelState);
        if (this.textarea.formattingToApplyOnNextInput) this.updatePanelState([this.textarea.formattingToApplyOnNextInput]);
        if (this.panelState) this.panelState = undefined;

        const opts = this.recomputeTextLocally(TextToolMode.Typing);
        if (opts && synchronizeNow) {
          this.clearDeferredChange();
          this.model.doTool(layer.id, opts);
        } else if (opts) {
          this.scheduleDeferredChange(this.getDeferredFn(layer, opts, 'ios'));
        }

        this.lastInputEvent = performance.now();
        this.forceCommitOnInputEvent = false;
        this.deletingSelection = 0;
        if (isiOS) input.value = this.textarea.text;
        this.lastInputValue = this.textarea.text;
        this.textSelection = newSelection;
        if (isiOS) this.showMobileKeyboard();
        this.editor.apply(() => { });
      }
    }
  };
  private handleTabKey(e: KeyboardEvent, textarea: Textarea) {
    if (e.shiftKey) {
      e.preventDefault();
      this.applyTextareaFormatting({ displayNonPrintableCharacters: !textarea.textareaFormatting.displayNonPrintableCharacters });
      DEVELOPMENT && console.log('TOGGLED VISIBILITY OF NON-PRINTABLE-CHARACTERS now is:', textarea.textareaFormatting.displayNonPrintableCharacters);
    } else {
      const selection = this.textSelection;
      if (selection && isLayerOk(this.layer, this.editor.drawing) && textarea.text.length < MAX_TEXTAREA_TEXT_LENGTH) {
        e.preventDefault();
        this.confirmDeferredChange();
        this.globalHistory.pushLayerState(this.layer.id, TextToolMode.Typing);
        const newSelection = insertCharactersIntoTextarea(textarea, selection, '\t', this.panelState);
        this.input!.value = textarea.text;
        this.lastInputValue = this.input!.value;
        this.textSelection = newSelection;
        this.commitChangesOnRemote(this.layer, false, this.recomputeTextLocally(TextToolMode.Typing));
      }
    }
  }
  private handleHomeKey(e: KeyboardEvent, textarea: Textarea, selection: TextSelectionDetailed | undefined) {
    e.stopPropagation();
    e.preventDefault();
    textarea.blinkOffset = performance.now();
    textarea.verticallyNavigatingDoubleIndex = undefined;
    this.textSelection = handleHomeVerticalSelection(e, textarea, selection);
  }
  private handleEndKey(e: KeyboardEvent, textarea: Textarea, selection: TextSelectionDetailed | undefined) {
    e.stopPropagation();
    e.preventDefault();
    textarea.blinkOffset = performance.now();
    textarea.verticallyNavigatingDoubleIndex = undefined;
    this.textSelection = handleEndVerticalSelection(e, textarea, selection);
  }
  private handleHorizontalArrows(textarea: Textarea) {
    textarea.lastSelectionChangeSource = 'arrows-h';
    textarea.blinkOffset = performance.now();
    textarea.caretAtEOL = false;
  }
  private handleLeftArrow(e: KeyboardEvent, textarea: Textarea, selection: TextSelectionDetailed) {
    const { ctrlKey, metaKey, shiftKey } = e;
    const { start, end, direction, length } = selection;
    e.stopPropagation();
    e.preventDefault();
    if (start > 0 || direction !== TextSelectionDirection.Backward) {
      this.handleHorizontalArrows(textarea);
      const newSelection: TextSelectionDetailed = { ...selection };
      let extension = -1;
      if (ctrlKey || metaKey) {
        extension = -countCtrlExtension(
          textarea,
          direction === TextSelectionDirection.Backward ? start : end,
          direction === TextSelectionDirection.Forward,
          false,
        );
      }
      if (shiftKey) {
        if (newSelection.direction === TextSelectionDirection.Forward && Math.abs(extension) > newSelection.length) {
          newSelection.end = newSelection.start;
          newSelection.length = -(extension + newSelection.length);
          newSelection.start = newSelection.end - newSelection.length;
          newSelection.direction = TextSelectionDirection.Backward;
        } else {
          if (!length && direction === TextSelectionDirection.None) {
            newSelection.direction = TextSelectionDirection.Backward;
          }
          if (direction !== TextSelectionDirection.Forward) {
            newSelection.start += extension;
            newSelection.length -= extension;
          } else {
            newSelection.end += extension;
            newSelection.length += extension;
          }
          if (!newSelection.length) newSelection.direction = TextSelectionDirection.None;
        }
      } else {
        if (this.hasGlyphsInSelection) {
          newSelection.end = newSelection.start;
        } else {
          newSelection.start = start + extension;
          newSelection.end = newSelection.start;
        }
        newSelection.length = 0;
        newSelection.direction = TextSelectionDirection.None;
      }
      applyTextAndParagraphIndexesToSelection(textarea, newSelection, false);
      this.textSelection = newSelection;
    } else if (selection.length && start === 0 && !shiftKey) {
      this.handleHorizontalArrows(textarea);
      const newSelection: TextSelectionDetailed = { ...selection };
      newSelection.start = 0;
      newSelection.end = 0;
      newSelection.direction = TextSelectionDirection.None;
      applyTextAndParagraphIndexesToSelection(textarea, newSelection, true);
      this.textSelection = newSelection;
    }
  }
  private handleRightArrow(e: KeyboardEvent, textarea: Textarea, selection: TextSelectionDetailed) {
    const { ctrlKey, metaKey, shiftKey } = e;
    const { start, end, direction, length } = selection;
    e.preventDefault();
    e.stopPropagation();
    if (end < textarea.characters.length) {
      this.handleHorizontalArrows(textarea);
      const newSelection: TextSelectionDetailed = { ...selection };
      let extension = 1;
      if (ctrlKey || metaKey) {
        extension = countCtrlExtension(
          textarea,
          direction === TextSelectionDirection.Backward ? start : end,
          direction !== TextSelectionDirection.Backward,
          true
        );
      }
      if (shiftKey) {
        if (newSelection.direction === TextSelectionDirection.Backward && extension > newSelection.length) {
          newSelection.start = newSelection.end;
          newSelection.length = extension - newSelection.length;
          newSelection.end = newSelection.start + newSelection.length;
          newSelection.direction = TextSelectionDirection.Forward;
        } else {
          if (!length && direction === TextSelectionDirection.None) {
            newSelection.direction = TextSelectionDirection.Forward;
          }
          if (direction !== TextSelectionDirection.Backward) {
            newSelection.end += extension;
            newSelection.length += extension;
          } else {
            newSelection.start += extension;
            newSelection.length -= extension;
          }
        }
        if (!newSelection.length) newSelection.direction = TextSelectionDirection.None;
      } else {
        if (this.hasGlyphsInSelection) {
          newSelection.start = newSelection.end;
        } else {
          newSelection.start = end + extension;
          newSelection.end = newSelection.start;
        }
        newSelection.length = 0;
        newSelection.direction = TextSelectionDirection.None;
      }

      applyTextAndParagraphIndexesToSelection(textarea, newSelection, false);
      this.textSelection = newSelection;
    }
  }

  // changing active layer:
  private shouldAutoDelete(layer: Layer | undefined) {
    const editor = this.editor as Editor;
    return layer && isLayerOk(layer, editor.drawing) && (layer.textData.text === '' || (layer.textarea && !layer.textarea.dirty)) && !editor.undoingOrRedoing;
  }
  denyLayerAutoDelete(layer: Layer | undefined) {
    if (this.layer === layer && this.shouldAutoDelete(layer)) {
      this.layer = undefined;
    }
  }
  onLayerExit(editor: Editor) {
    this.scheduledLayer = undefined;
    if (isTextLayer(this.layer)) {
      logAction(`TextTool.onLayerExit (layer: ${this.layer?.id}, deferredChangeFn: ${!!this.deferredChangeFn}, typingPreundo: ${!!this.typingPreundo})`);
    }
    this.confirmDeferredChange();
    if (!SERVER && !(this.editor as Editor).undoingOrRedoing) this.focused = false;
    if (this.shouldAutoDelete(this.layer)) {
      DEVELOPMENT && console.log('TextTool removed non-dirty text layer', TESTS ? { id: this.layer?.id } : toLayerState(this.layer!));
      logAction(`text-tool layer auto-delete (${this.layer?.id})`);
      removeLayer(editor, this.layer);
    }
    this.becomeLayerless();
  }
  onLayerEnter(layer: Layer | undefined) {
    this.becomeLayerless();
    if (isLayerOk(layer, this.editor.drawing) && layer.fontsLoaded) {
      logAction('TextTool.onLayerChange (sync)');
      this.layer = layer;
      this.loadTextLayerProperties(layer);
      if (!SERVER) this.focused = true;
      this.recomputeTextLocally();
      if (isMobile && this.isUsingTextTool) this.createShowKeyboardFab();
      if (!layer.textData.dirty) this.selectEntireText();
      this.forceCommitOnInputEvent = true;
      this.trackSelectionChange();
      this.rememberPanelState();
      this.becomeIdle();
    } else if (isLayerOk(layer, this.editor.drawing)) {
      this.scheduleOnLayerChange(layer);
    } else {
      this.mode = TextToolMode.Creating;
    }
  }
  onLayerChange(layer: Layer | undefined) {
    this.onLayerExit(this.editor as Editor);
    this.onLayerEnter(layer);
  }
  private scheduledLayer: TextLayer | undefined = undefined;
  private scheduleOnLayerChange(layer: TextLayer) {
    this.scheduledLayer = layer;
    logAction('TextTool.onLayerChange (async)');
    loadFontsForLayer(layer, this.editor as Editor).then(() => {
      if (isLayerOk(this.scheduledLayer, this.editor.drawing) && this.model.user.activeLayer === this.scheduledLayer) {
        this.onLayerChange(this.scheduledLayer);
      }
    }).catch(e => DEVELOPMENT && console.log(e));
  }

  // editor-sliders component bindable-data:
  fontFamily = '';
  fontStyle: FontStyleNames | '' = '';
  fontSize: AutoOr<number> | undefined = DEFAULT_CHARACTER_FORMATTING.size;
  lineheight: AutoOr<number> | undefined = AUTO_SETTING_STRING;
  letterSpacing: number | undefined = DEFAULT_CHARACTER_FORMATTING.letterSpacing;
  baselineShift: number | undefined = DEFAULT_CHARACTER_FORMATTING.baselineShift;
  bold = false;
  italic = false;
  underline = false;
  strikethrough = false;
  strokeWidth: number | undefined = 0;
  fillOpacity = 255;

  get fillOpacityInPercents() {
    return Math.floor(this.fillOpacity / 255 * 100);
  }

  alignment: TextAlignment | undefined = DEFAULT_PARAGRAPH_FORMATTING.alignment;
  textareaType = TextareaType.AutoWidth;
  verticalAlignment = VerticalAlignments.Top;
  scaleX: number | undefined = 1;
  scaleY: number | undefined = 1;
  textCase: TextCases | undefined = TextCases.NoCaseModification;

  get disablePanel() {
    const editorLocked = (this.editor as Editor).locked;
    const layerScheduledToEnter = isLayerOk(this.scheduledLayer, this.editor.drawing);
    const noTextarea = (isLayerOk(this.layer, this.editor.drawing) && !this.textarea);
    return editorLocked || layerScheduledToEnter || noTextarea || !isLoadedConnectedAndNotLocked(this.editor as Editor);
  }
  get disableBoxTextareaControls() {
    return this.textareaType === TextareaType.AutoWidth || this.textarea?.type === TextareaType.AutoWidth;
  }

  private panelState: CharacterFormatting | undefined = undefined; // textarea formatting does not need to be kept and restored, nor paragraph ones
  get selectedCharacters() {
    if (this.textarea && this.textSelection?.length) {
      return this.textarea.characters.slice(this.textSelection.start, this.textSelection.end);
    }
    return [];
  }
  private assemblePanelProperty(panelState: CharacterFormatting, property: keyof TextTool & keyof CharacterFormatting) {
    const firstSelectedCharacterFormatting = this.selectedCharacters.length ? this.selectedCharacters[0].formatting : undefined;
    let value = this.getFormattingPropertyFromPanel(property, DEFAULT_CHARACTER_FORMATTING);
    if (value === null) value = firstSelectedCharacterFormatting ? firstSelectedCharacterFormatting[property] : DEFAULT_CHARACTER_FORMATTING[property];
    if (value !== null && value !== undefined) (panelState as any)[property] = value;
  }
  private getPanelState() {
    const panelState: CharacterFormatting = {};
    const firstSelectedCharacterFormatting = this.selectedCharacters.length ? this.selectedCharacters[0].formatting : undefined;

    let size = this.getFormattingPropertyFromPanel('fontSize', DEFAULT_CHARACTER_FORMATTING);
    if (size === null) size = firstSelectedCharacterFormatting?.size ?? DEFAULT_CHARACTER_FORMATTING.size;
    if (size !== null && size !== undefined) panelState.size = size;

    this.assemblePanelProperty(panelState, 'baselineShift');
    this.assemblePanelProperty(panelState, 'lineheight');
    this.assemblePanelProperty(panelState, 'letterSpacing');

    let fontFamily = this.getFormattingPropertyFromPanel('fontFamily', DEFAULT_CHARACTER_FORMATTING);
    if (fontFamily === null) fontFamily = firstSelectedCharacterFormatting?.fontFamily ?? this.availableFontFamilies[0].name;
    if (fontFamily !== null && fontFamily !== undefined) panelState.fontFamily = fontFamily;

    if (this.bold || firstSelectedCharacterFormatting?.bold) panelState.bold = true;
    if (this.italic || firstSelectedCharacterFormatting?.italic) panelState.italic = true;
    if (this.underline || firstSelectedCharacterFormatting?.underline) panelState.underline = true;
    if (this.strikethrough || firstSelectedCharacterFormatting?.strikethrough) panelState.strikethrough = true;

    let scaleX = this.getFormattingPropertyFromPanel('scaleX', DEFAULT_CHARACTER_FORMATTING);
    if (scaleX === null) scaleX = firstSelectedCharacterFormatting ? firstSelectedCharacterFormatting.scaleX : DEFAULT_CHARACTER_FORMATTING.scaleX;
    if (scaleX !== null && scaleX !== undefined && !!scaleX) panelState.scaleX = scaleX;

    let scaleY = this.getFormattingPropertyFromPanel('scaleY', DEFAULT_CHARACTER_FORMATTING);
    if (scaleY === null) scaleY = firstSelectedCharacterFormatting ? firstSelectedCharacterFormatting.scaleY : DEFAULT_CHARACTER_FORMATTING.scaleY;
    if (scaleY !== null && scaleY !== undefined && !!scaleY) panelState.scaleY = scaleY;

    if (this.activeColorEmpty) { // or rather mixed values in selection
      panelState.color = firstSelectedCharacterFormatting?.color ?? DEFAULT_CHARACTER_FORMATTING.color;
    } else {
      panelState.color = colorToCSS(this.editor.primaryColor);
    }

    this.assemblePanelProperty(panelState, 'textCase');

    return panelState;
  }
  rememberPanelState(focusInput = true) {
    this.panelState = this.getPanelState();
    if (focusInput && this.focused) {
      this.input?.focus();
    }
    this.presettingFont = false;
  }

  get selectableFontFamilies(): string[] {
    return Object.keys(FONTS_SOURCES).sort() || [];
  }
  get availableFontFamilies(): FontFamily[] {
    return Array.from(FONTS.values() || []);
  }
  get availableFontStyles(): FontStyleNames[] {
    if (FONTS) {
      if (this.fontFamily) {
        const family = FONTS.get(this.fontFamily);
        return Array.from(family?.styles.keys() || []).sort((a, b) => {
          return fontStyleNameToWeight(a) - fontStyleNameToWeight(b);
        });
      } else {
        return [FontStyleNames.Regular];
      }
    } else {
      return [];
    }
  }
  readonly selectableFontSizes: AutoOr<number>[] = [
    // AUTO_SETTING_STRING,
    8, 9, 10, 11, 12, 13, 14, 15, 16,
    20, 24, 28, 32, 36, 40, 44, 48,
    60, 72
  ];
  readonly selectableLineheights: AutoOr<number>[] = [AUTO_SETTING_STRING, ...this.selectableFontSizes];
  readonly selectableLetterspacings: number[] = [-100, -75, -50, -25, -10, -5, 0, 5, 10, 25, 50, 75, 100, 200];
  readonly selectableBaselineShifts: number[] = [-12, -6, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 9, 12];
  readonly selectableStrokeWidths: number[] = [
    0, 0.25, 0.5, 0.75,
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    12, 14, 16, 18,
    20, 40, 60, 80,
  ];
  setCharacterFormatting(property: (keyof TextTool & keyof CharacterFormatting), value?: any, focusInput = true) {
    if (isLayerOk(this.layer, this.editor.drawing) && this.mode !== TextToolMode.Typing) return;
    logAction(`setCharacterFormatting(${property}, ${value})`);
    (this as any)[property] = value;
    const formatting = { [property]: this[property] };
    this.onCharacterFormatting(formatting, focusInput);
  }
  setParagraphFormatting(property: (keyof TextTool & keyof ParagraphFormatting), value?: any) {
    if (isLayerOk(this.layer, this.editor.drawing) && this.mode !== TextToolMode.Typing) return;
    logAction(`setParagraphFormatting(${property}, ${value})`);
    finishTransform(this.editor as Editor, this.model.user, 'setParagraphFormatting');
    (this as any)[property] = value;
    const formatting = { [property]: this[property] };
    this.applyParagraphFormatting(formatting);
  }
  setTextareaFormatting(property: (keyof TextTool & keyof TextareaFormatting), value?: any) {
    if (isLayerOk(this.layer, this.editor.drawing) && this.mode !== TextToolMode.Typing) return;
    logAction(`setTextareaFormatting(${property}, ${value})`);
    finishTransform(this.editor as Editor, this.model.user, 'setTextareaFormatting');
    (this as any)[property] = value;
    const formatting = { [property]: this[property] };
    this.applyTextareaFormatting(formatting);
  }
  private onCharacterFormatting(formatting: CharacterFormatting, focusInput = true) {
    if (!this.isUsingTextTool) return;
    if (formatting.color) this.activeColorEmpty = false;
    if (!this.focused) {
      finishTransform(this.editor as Editor, this.model.user, 'onCharacterFormatting-2');
      this.applyGlobalCharacterFormatting(formatting, !isMobile && focusInput);
    } else if (this.hasGlyphsInSelection) {
      this.panelState = undefined;
      finishTransform(this.editor as Editor, this.model.user, 'onCharacterFormatting-1');
      this.applyCharacterFormattingToSelection(formatting, !isMobile && focusInput);
    } else {
      this.rememberPanelState(!isMobile && focusInput);
    }
  }
  queuedLoadingFontName = '';
  private presettingFont = true;
  presetFont(fontFamilyName: string) {
    if (this.presettingFont) {
      this.setFontFamily(fontFamilyName);
      this.presettingFont = false;
    }
  }

  analyticsEventName: TextToolAnalytics | undefined = undefined;
  analyticsEventData: TextToolAnalyticsEvents | undefined = undefined;
  initAnalyticsEvent<T extends TextToolAnalyticsEvents = any>(name: TextToolAnalytics, data: Partial<T> = {}) {
    this.analyticsEventName = name;
    this.analyticsEventData = { ...data } as unknown as TextToolAnalyticsEvents;
  }
  suplementAnalyticsEventData<T extends TextToolAnalyticsEvents = any>(data: Partial<T>) {
    if (!this.analyticsEventData) return;
    this.analyticsEventData = { ...this.analyticsEventData, ...data } as TextToolAnalyticsEvents;
  }
  confirmAnalyticsEvent(skipDrop = false) {
    if (this.analyticsEventName && this.analyticsEventData && this.editor.track) {
      this.editor.track.event(this.analyticsEventName, this.analyticsEventData);
    }
    if (!skipDrop) {
      this.dropAnalyticsEvent();
    }
  }
  dropAnalyticsEvent() {
    this.analyticsEventName = undefined;
    this.analyticsEventData = undefined;
  }
  getAnalyticsSelectionString() {
    if (!isTextareaOk(this.textarea)) return 'undefined';
    if (!this.focused) return 'global';
    if (!this.hasGlyphsInSelection) return 'caret';
    if (this.selectedCharacters.length === this.textarea.characters.length - 1) return 'full';
    return 'partial';
  }

  setFontFamily(fontFamilyName: string) {
    this.queuedLoadingFontName = fontFamilyName;
    this.fontFamily = fontFamilyName;
    if (this.presettingFont) return;
    this.suplementAnalyticsEventData({ fontFamily: fontFamilyName });
    logAction(`setFontFamily("${fontFamilyName}")`);
    if (!FONTS.has(fontFamilyName)) {
      this.suplementAnalyticsEventData({ needsToLoadFontFirst: true });
      loadAnotherFont(fontFamilyName, this.editor as Editor).then((fontFamily) => {
        if (this.queuedLoadingFontName === fontFamilyName) {
          this._setFontFamily(fontFamily, true);
          this.recomputeTextLocally(); // this is needed additionally because we want to do this manually instead of focus text tool input again and close dropdown (when wheeling or using arrow-keys)
        }
      }).catch((e) => DEVELOPMENT && console.error(e));
    } else {
      this.suplementAnalyticsEventData({ needsToLoadFontFirst: false });
      this._setFontFamily(FONTS.get(fontFamilyName)!);
      this.recomputeTextLocally();// this is needed additionally because we want to do this manually instead of focus text tool input again and close dropdown (when wheeling or using arrow-keys)
    }
  }
  private _setFontFamily(fontFamily: FontFamily, skipSettingName = false) {
    logAction(`_setFontFamily("${fontFamily.name}") - aborted: ${this.mode !== TextToolMode.Typing}`);
    if (isLayerOk(this.layer, this.editor.drawing) && this.mode !== TextToolMode.Typing) return;
    if (!skipSettingName) this.fontFamily = fontFamily.name;
    const formatting: CharacterFormatting = { fontFamily: fontFamily.name };

    if (this.fontStyle === '' || !fontFamily.styles.get(this.fontStyle)) {
      this.fontStyle = fontFamily.styles.get(FontStyleNames.Regular) ? FontStyleNames.Regular : '';
      this.bold = isBold(this.fontStyle);
      this.italic = isItalic(this.fontStyle);
    }

    formatting.bold = this.bold;
    formatting.italic = this.italic;

    if (!this.presettingFont) {
      this.suplementAnalyticsEventData({ fontStyle: this.fontStyle, currentTextSelection: this.getAnalyticsSelectionString() });
      this.confirmAnalyticsEvent(true);
      this.onCharacterFormatting(formatting, false);
    } else {
      this.rememberPanelState();
    }
  }
  setFontStyle(fontStyleName: FontStyleNames) {
    if (isLayerOk(this.layer, this.editor.drawing) && this.mode !== TextToolMode.Typing) return;

    this.fontStyle = fontStyleName;
    this.bold = isBold(fontStyleName);
    this.italic = isItalic(fontStyleName);

    this.initAnalyticsEvent<TextToolSelectedFontStyleEvent>(Analytics.TextToolSelectedFontStyle, {
      fontStyle: fontStyleName,
      source: 'dropdown',
      currentTextSelection: this.getAnalyticsSelectionString()
    });
    this.confirmAnalyticsEvent();
    this.onCharacterFormatting({
      bold: this.bold,
      italic: this.italic
    }, this.focused);
  }
  toggleBold(source: TextToolSelectedFontStyleEvent['source']) {
    if (isLayerOk(this.layer, this.editor.drawing) && this.mode !== TextToolMode.Typing) return;
    if (this.availableFontStyles.includes(FontStyleNames.Bold)) {
      const isBoldNow = isBold(this.fontStyle);
      const isItalicNow = isItalic(this.fontStyle);

      if (!isItalicNow && !isBoldNow) this.fontStyle = FontStyleNames.Bold;
      else if (isItalicNow && !isBoldNow) this.fontStyle = FontStyleNames.BoldItalic;
      else if (!isItalicNow && isBoldNow) this.fontStyle = FontStyleNames.Regular;
      else if (isItalicNow && isBoldNow) this.fontStyle = FontStyleNames.Italic;

      this.bold = isBold(this.fontStyle);

      this.initAnalyticsEvent<TextToolSelectedFontStyleEvent>(Analytics.TextToolSelectedFontStyle, {
        fontStyle: this.fontStyle,
        currentTextSelection: this.getAnalyticsSelectionString(),
        source
      });
      this.confirmAnalyticsEvent();

      this.onCharacterFormatting({ bold: this.bold }, this.focused);
    }
  }
  toggleItalic(source: TextToolSelectedFontStyleEvent['source']) {
    if (isLayerOk(this.layer, this.editor.drawing) && this.mode !== TextToolMode.Typing) return;
    if (this.availableFontStyles.includes(FontStyleNames.Italic)) {
      const isBoldNow = isBold(this.fontStyle);
      const isItalicNow = isItalic(this.fontStyle);

      if (!isItalicNow && !isBoldNow) this.fontStyle = FontStyleNames.Italic;
      else if (isItalicNow && !isBoldNow) this.fontStyle = FontStyleNames.Regular;
      else if (!isItalicNow && isBoldNow) this.fontStyle = FontStyleNames.BoldItalic;
      else if (isItalicNow && isBoldNow) this.fontStyle = FontStyleNames.Bold;

      this.italic = isItalic(this.fontStyle);

      this.initAnalyticsEvent<TextToolSelectedFontStyleEvent>(Analytics.TextToolSelectedFontStyle, {
        fontStyle: this.fontStyle,
        currentTextSelection: this.getAnalyticsSelectionString(),
        source
      });
      this.confirmAnalyticsEvent();

      this.onCharacterFormatting({ italic: this.italic }, this.focused);
    }
  }
  toggleUnderline() {
    if (isLayerOk(this.layer, this.editor.drawing) && this.mode !== TextToolMode.Typing) return;
    this.underline = !this.underline;
    this.onCharacterFormatting({ underline: this.underline }, this.focused);
  }
  toggleStrikethrough() {
    if (isLayerOk(this.layer, this.editor.drawing) && this.mode !== TextToolMode.Typing) return;
    this.strikethrough = !this.strikethrough;
    this.onCharacterFormatting({ strikethrough: this.strikethrough }, this.focused);
  }
  setFontSize(size: AutoOr<number>, focusInput = true) {
    if (isLayerOk(this.layer, this.editor.drawing) && this.mode !== TextToolMode.Typing) return;
    this.fontSize = size;
    const formatting = { size: this.fontSize };
    // cast is needed because CharacterFormatting expects size: number and in tool fontSize is of type AutoOr<number>
    // but during applying (Textarea.formatCharacters()) all possible autos are stripped
    this.onCharacterFormatting(formatting as CharacterFormatting, focusInput);
    if (!focusInput) this.recomputeTextLocally();
  }
  setFillOpacity(newOpacity: number) {
    newOpacity;
    // this.fillOpacity = newOpacity;
    // const color = colorToCSS(withAlpha(this.fillColor || 0, this.fillOpacity));
    // this.onCharacterFormatting({ color });
  }
  setColor(color: number, focusInput = this.focused) {
    if (isLayerOk(this.layer, this.editor.drawing) && this.mode !== TextToolMode.Typing) return;
    if (!this.isTextLayerLocked) {
      const formatting = { color: colorToCSS(withAlpha(color || 0, this.fillOpacity)) };
      this.onCharacterFormatting(formatting, focusInput);
    }
  }

  private loadTextLayerProperties(layer: TextLayer) {
    const textarea = createTextarea(FONTS, layer.textData);
    if (textarea) {
      this.textarea = textarea;
      const { type, textareaFormatting } = layer.textData;
      this.textareaType = type;
      this.verticalAlignment = textareaFormatting.verticalAlignment || VerticalAlignments.Top;
      this.mode = TextToolMode.Typing;
      copyRect(this.rect, textarea.rect);
      if (!SERVER && (this.editor as Editor).selectedTool?.id === this.id) {
        this.recreateInput(layer);
      }
    }
  }
  get isOnReadyTextLayer() {
    return isLayerOk(this.layer, this.editor.drawing) && isTextareaOk(this.textarea);
  }
  changeTextareaType(type: TextareaType, sendToRemote = true) {
    if (!isTextLayer(this.layer)) return;
    if (!isLayerOk(this.layer, this.editor.drawing)) { throw new Error('Unable to change textarea type - invalid layer.'); }
    if (!isTextareaOk(this.textarea)) {
      showHelpAlertForError(ToolError.WaitingForFontsToLoad, this.editor as Editor);
      return;
    }
    const fromResizing = !sendToRemote && this.mode === TextToolMode.Resizing;
    const fromUi = this.mode === TextToolMode.Typing;
    if (!fromResizing && !fromUi) return;

    this.confirmDeferredChange();
    if (this.textarea.type !== type) {
      this.initAnalyticsEvent<TextToolChangedTextBoxTypeEvent>(Analytics.TextToolSelectedBoxType, {
        from: this.textarea.type,
        to: type,
        source: sendToRemote ? 'button' : `resizing:${this.resizingControlPoint!.direction}`
      });
      this.confirmAnalyticsEvent();
    }

    const goToStateBefore = this.getHistoryEntry(this.layer, `${TextToolMode.Formatting}-textareaType`);
    if (sendToRemote && goToStateBefore) {
      this.globalHistory.pushUndo(goToStateBefore);
    }

    normalizeRect(this.rect);
    const options: TextareaOptions = {
      ...this.rect,
      textareaFormatting: this.textarea.textareaFormatting,
      paragraphFormattings: this.textarea.paragraphFormattings,
      characterFormattings: this.textarea.characterFormattings,
      defaultFontFamily: this.textarea?.defaultFontFamily || this.fontFamily || (this.availableFontFamilies.length ? this.availableFontFamilies[0].name : this.selectableFontFamilies[0]),
      text: this.textarea.text,
      dirty: this.textarea.dirty,
      type,
    };
    this.textarea = createTextarea(this.textarea.fontFamilies, options);

    normalizeRect(this.rect);
    integerizeRect(this.rect);

    this.textarea?.syncControlPoints();
    this.textareaType = type;
    redraw(this.editor);
    redrawLayer(this.editor, this.layer);

    if (sendToRemote) {
      this.commitChangesOnRemote(this.layer, sendToRemote ? !goToStateBefore : !this.mousePreundo, this.recomputeTextLocally(TextToolMode.Formatting));
      if (!isMobile) this.focused = this.focused;
    }
  }

  inputPositionAdjust: Function | undefined = undefined;
  private _textSelection: TextSelectionDetailed | undefined = undefined;
  get textSelection(): TextSelectionDetailed | undefined {
    return this._textSelection;
  }
  set textSelection(value: TextSelectionDetailed | undefined) {
    this._textSelection = value;
    if (!this.textarea?.formattingToApplyOnNextInput) {
      if (value && this.textarea?.text !== '') {
        this.input!.setSelectionRange(value.start, value.end, value.direction);
        this.editor.apply(() => { });
        this.updatePanelStateFromSelection(value);
      } else {
        this.updatePanelStateFromSelection();
      }
    }
    this.scheduleAdjustingInputPosition(value);
  }

  private scheduleAdjustingInputPosition(selection: TextSelectionDetailed | undefined) {
    this.inputPositionAdjust = () => {
      // repainting artificial textarea input takes a lot of resources, making this asynchronous helps with performance,
      // ux can be only affected with IME or emoji popups popping in wrong place but window is so short it shouldn't happen
      if (isLayerOk(this.layer, this.editor.drawing) && this.input && this.textarea) {
        const cursor = this.textarea.getCursorPosition(selection);
        let x = this.layer.textData.x;
        let y = this.layer.textData.y;
        if (cursor.caretRect) {
          x = cursor.caretRect.x;
          y = cursor.caretRect.y;
        }
        if (cursor.selectionRects.length) {
          if (selection?.direction !== TextSelectionDirection.Backward) {
            const lastSelectionRect = cursor.selectionRects[cursor.selectionRects.length - 1];
            x = lastSelectionRect.x + lastSelectionRect.w;
            y = lastSelectionRect.y;
          } else {
            const firstSelectionRect = cursor.selectionRects[0];
            x = firstSelectionRect.x;
            y = firstSelectionRect.y;
          }
        }
        setInputPositionOnPage(this.input, this.editor.view, x, y);
      }
      this.inputPositionAdjust = undefined;
    };
    setTimeout(() => { this.inputPositionAdjust?.(); }, 10);
  }
  private hideMobileKeyboard() {
    const fakeButton = document.createElement('button');
    document.body.appendChild(fakeButton);
    fakeButton.focus();
    fakeButton.click();
    fakeButton.remove();
    this.keyboardOpened = false;
    this.editor.apply(() => { });
  }
  private showMobileKeyboard() {
    this.focused = true;
    if (this.input) {
      this.input.autocapitalize = this.getAutocapitalizeAttribute();
      this.input.click(); // delegate trusted click
      this.input.click(); // delegate trusted click
      this.input.setSelectionRange(0, this.input.value.length, TextSelectionDirection.Forward);
      setTimeout(() => {
        this.textSelection && this.input?.setSelectionRange(this.textSelection.start, this.textSelection.end, this.textSelection.direction);
      });
    }
    this.keyboardOpened = true;
    this.editor.apply(() => { });
  }
  private getAutocapitalizeAttribute() {
    if (!isTextareaOk(this.textarea) || !this.textSelection) return 'off';
    const { start, direction } = this.textSelection;
    if (direction !== TextSelectionDirection.None) return 'off';
    let prevChar = this.textarea.characters[start - 1];
    if (start === 0 || (prevChar && (prevChar.endsSentence))) {
      return 'on';
    } else {
      return 'off';
    }
  }
  frame() {
    if (!SERVER && isMobile && this.mode === TextToolMode.Typing) {
      const orientation = this.getOrientation();
      if (this.lastOrientation !== orientation) {
        this.lastViewportHeight = window.visualViewport?.height ?? 0;
        this.lastOrientation = orientation;
      }

      const canMangeFocus = this.isUsingTextTool && (document.activeElement && document.activeElement === this.input || !document.activeElement || !isControl(document.activeElement));

      if (this.lastViewportHeight && this.input) {
        if (window.visualViewport && window.visualViewport.height < this.lastViewportHeight) {
          // showing keyboard
          this.becomeIdle();
          if (canMangeFocus) this.showMobileKeyboard();
          if (this.showKeyboardFab) this.showKeyboardFab.innerHTML = CLOSE_KEYBOARD_SVG;
          this.lastViewportHeight = window.visualViewport.height;
          this.editor.apply(() => { });
        } else if (window.visualViewport && window.visualViewport.height > this.lastViewportHeight) {
          // hiding keyboard
          this.becomeIdle();
          if (canMangeFocus) this.hideMobileKeyboard();
          this.lastViewportHeight = window.visualViewport.height;
          if (this.showKeyboardFab) {
            this.showKeyboardFab.style.visibility = 'visible';
            this.showKeyboardFab.innerHTML = OPEN_KEYBOARD_SVG;
          }
          this.editor.apply(() => { });
        } else { /* do nothing */ }
      }
    }
    if (this.shouldFixMissingTextarea(this.layer)) this.fixMissingTextarea(this.layer);
  }
  private shouldFixMissingTextarea(layer: Layer | undefined): layer is TextLayer & { textarea: undefined } {
    return !!((isLayerOk(layer, this.editor.drawing) && !layer.textarea && layer.fontsLoaded));
  }
  private fixMissingTextarea(layer: TextLayer & { textarea: undefined }) {
    if (!layer.textData.defaultFontFamily) {
      layer.textData.defaultFontFamily = this.fontFamily || (this.availableFontFamilies.length ? this.availableFontFamilies[0].name : this.selectableFontFamilies[0]);
    }
    layer.fontsLoaded = hasFontsLoaded(layer);
  }
  get isUsingTextTool() {
    return (this.editor as Editor).selectedTool?.id === this.id;
  }
  get shouldRefocus() {
    if (SERVER) return false;
    if (!this.input) return false;

    if (!this.isUsingTextTool) return false;

    const activeElement = document.activeElement;
    const consideredFocusedButIsNot = this.focused && this.input !== activeElement;
    const focusSomewhereElseValid = !activeElement || (!isControl(activeElement) && !activeElement.classList.contains('font-select-skip-blur'));
    return consideredFocusedButIsNot && focusSomewhereElseValid;
  }
  private trackSelectionChange() {
    if (this.input) {
      const selection = this.getSelection();
      const existing = this.textSelection;
      const differentStart = selection?.start !== existing?.start;
      const differentEnd = selection?.end !== existing?.end;
      if (differentStart || differentEnd) {
        this.textSelection = selection;
      }
    }
  }

  // updating panel according to selection:
  private updatePanelState(formattings: (CharacterFormattingDescription | CharacterFormatting)[]) {
    this.letterSpacing = this.getUniqueValuesFromFormatting(formattings, 'letterSpacing');
    this.baselineShift = this.getUniqueValuesFromFormatting(formattings, 'baselineShift');

    // TODO: for now we mark indeterminate state (mixed formattings) as 0 on scale sliders
    //  (which is unsettable manually because we clamp at 1%), might want to change sometime
    this.scaleX = this.getUniqueValuesFromFormatting(formattings, 'scaleX') || 0;
    this.scaleY = this.getUniqueValuesFromFormatting(formattings, 'scaleY') || 0;

    this.setPanelStateForFontSize(formattings);
    this.setPanelStateForLineheight(formattings);
    this.setPanelStateForFontStyle(formattings);
    this.setPanelStateForFontFamily(formattings);
    this.setPanelStateForDecorations(formattings);
    this.setPanelStateForColor(formattings);
  }
  private updatePanelStateFromSelection(selection?: TextSelectionDetailed) {
    const sel = this.ensureSelectionHasAtLeastOneCharacter(selection);
    if (this.textSelection?.length === 0 && sel?.text === PARAGRAPH_SPLIT_CHARACTER) {
      sel.paragraphIndexes = sel.paragraphIndexes.map(pI => pI + 1);
    }
    if (this._textSelection && sel) {
      this._textSelection.paragraphIndexes = sel?.paragraphIndexes ?? this._textSelection?.paragraphIndexes ?? [];
    }
    const characters = sel ? (this.textarea?.getCharactersFromTextSelection(sel) || []) : [];
    const formattings = characters.map(c => c.formatting);
    this.updatePanelState(formattings);
    this.setPanelStateForAlignment(selection);
  }
  private ensureSelectionHasAtLeastOneCharacter(selection: TextSelectionDetailed | undefined) {
    // this mutation enables setting new panel state according to letter
    // before cursor when it's in not selection mode (between letters)
    if (selection && this.textarea) {
      const newSelection = { ...selection };
      if (newSelection.length === 0) {
        if (newSelection.start === 0) {
          newSelection.end++;
        } else {
          newSelection.start--;
        }
        newSelection.length = 1;
        applyTextAndParagraphIndexesToSelection(this.textarea, newSelection, false);
        return newSelection;
      }
      return selection;
    }
    return undefined;
  }
  private setPanelStateForFontStyle(formattings: (CharacterFormattingDescription | CharacterFormatting)[]): void {
    let differentValues = false;

    const uniqueBolds = uniq(formattings.map(f => f.bold));
    const uniqueItalics = uniq(formattings.map(f => f.italic));

    // TODO: is there indeterminate state for bold/italic togglers?
    //  For now assuming it's the same as false and handling it in elses
    //  unless global textarea formatting says otherwise

    if (uniqueBolds.filter(v => v !== undefined).length === 1) {
      if (uniqueBolds.length !== 1) differentValues = true;
      this.bold = uniqueBolds.filter(v => v !== undefined)[0]!;
    } else {
      if (!(uniqueBolds.length === 1 && uniqueBolds[0] === undefined)) differentValues = true;
      this.bold = false;
    }

    if (uniqueItalics.filter(v => v !== undefined).length === 1) {
      if (uniqueItalics.length !== 1) differentValues = true;
      this.italic = uniqueItalics.filter(v => v !== undefined)[0]!;
    } else {
      if (!(uniqueItalics.length === 1 && uniqueItalics[0] === undefined)) differentValues = true;
      this.italic = false;
    }

    if (this.textarea?.text === '' && !this.textarea.formattingToApplyOnNextInput) {
      this.fontStyle = FontStyleNames.Regular;
    } else if (differentValues) {
      this.fontStyle = '';
    } else {
      if (!this.italic && !this.bold) this.fontStyle = FontStyleNames.Regular;
      else if (this.italic && !this.bold) this.fontStyle = FontStyleNames.Italic;
      else if (!this.italic && this.bold) this.fontStyle = FontStyleNames.Bold;
      else if (this.italic && this.bold) this.fontStyle = FontStyleNames.BoldItalic;
    }
  }
  private setPanelStateForFontFamily(formattings: (CharacterFormattingDescription | CharacterFormatting)[]): void {
    const uniqueValues = uniq(formattings.map(f => f.fontFamily));
    if (uniqueValues.filter(v => v !== undefined).length === 0) {
      this.fontFamily = this.textarea?.defaultFontFamily || '';
    } else if (uniqueValues.length > 1) {
      this.fontFamily = '';
    } else {
      this.fontFamily = uniqueValues[0] ?? this.textarea?.defaultFontFamily ?? '';
    }
  }
  private setPanelStateForFontSize(formattings: (CharacterFormattingDescription | CharacterFormatting)[]): void {
    if (formattings.length === 0) {
      this.fontSize = DEFAULT_CHARACTER_FORMATTING.size;
    } else {
      const uniqueValues = uniq(formattings.map(f => f.size));
      if (uniqueValues.filter(v => v !== undefined).length === 0) {
        // characters in range are not formatted that way - fallback to globals (if not present to defaults)
        if (uniqueValues.length === 0) this.fontSize = undefined;
        else this.fontSize = DEFAULT_CHARACTER_FORMATTING.size;
      } else if (uniqueValues.length > 1) {
        // characters in range have multiple different values - set values in dropdowns to empty strings
        this.fontSize = undefined;
      } else {
        // one common formatting between all characters in range - set to this one
        this.fontSize = uniqueValues[0]!;
      }
    }
  }
  private setPanelStateForAlignment(selection: TextSelectionDetailed | undefined): void {
    if (!selection || selection.paragraphIndexes.length !== 1) this.alignment = undefined;
    else this.alignment = this.textarea?.paragraphs[selection.paragraphIndexes[0]!]?.formatting.alignment ?? TextAlignment.LeftAligned;
  }
  private setPanelStateForLineheight(formattings: (CharacterFormattingDescription | CharacterFormatting)[]): void {
    if (formattings.length === 0) {
      this.lineheight = AUTO_SETTING_STRING;
    } else {
      const uniqueValues = uniq(formattings.map(f => f.lineheight));
      if (uniqueValues.filter(v => v !== undefined).length === 0) {
        // characters in range are not formatted that way - fallback to globals (if not present to defaults)
        if (uniqueValues.length === 0) this.lineheight = undefined;
        else this.lineheight = AUTO_SETTING_STRING;
      } else if (uniqueValues.length > 1) {
        // characters in range have multiple different values - set values in dropdowns to empty strings
        this.lineheight = undefined;
      } else {
        // one common formatting between all characters in range - set to this one
        this.lineheight = uniqueValues[0]!;
      }
    }
  }
  private setPanelStateForDecorations(formattings: (CharacterFormattingDescription | CharacterFormatting)[]): void {
    const uniqueUnderlines = uniq(formattings.map(f => f.underline));
    const uniqueStrikethroughs = uniq(formattings.map(f => f.strikethrough));

    // TODO: is there indeterminate state for underline/strikethgrough togglers?
    //  For now assuming it's the same as false and handling it in elses
    //  unless global textarea formatting says otherwise

    this.underline = uniqueUnderlines.some(v => !!v);
    this.strikethrough = uniqueStrikethroughs.some(v => !!v);
  }
  private setPanelStateForColor(formattings: (CharacterFormattingDescription | CharacterFormatting)[]): void {
    const uniqueColors = uniq(formattings.map(f => f.color));
    if (uniqueColors.filter(c => c !== undefined).length === 1) {
      const color = parseColor(uniqueColors[0]!);
      const alpha = getAlpha(color);
      (this.editor as Editor).activeColor = color;
      this.fillOpacity = alpha;
      this.activeColorEmpty = false;
    } else if (uniqueColors.length !== 1) {
      this.activeColorEmpty = true;
    }
  }
  private getUniqueValuesFromFormatting<T extends keyof CharacterFormatting>(formattings: (CharacterFormattingDescription | CharacterFormatting)[], key: T): (CharacterFormattingDescription | CharacterFormatting)[T] | undefined {
    const uniqueValues = uniq(formattings.map(f => f[key]));
    if (uniqueValues.length === 0) {
      // characters in range are not formatted that way - fallback to globals (if not present to defaults)
      return DEFAULT_CHARACTER_FORMATTING[key];
    } else if (uniqueValues.length > 1) {
      // characters in range have multiple different values - set values in dropdowns to empty strings
      return undefined;
    } else {
      // one common formatting between all characters in range - set to this one
      return uniqueValues[0];
    }
  }

  primaryColorEmpty = false;
  secondaryColorEmpty = false;
  get activeColorEmpty() {
    const editor = this.editor as Editor;
    return editor && editor.activeColorField && editor.activeColorField === 'primary' ? this.primaryColorEmpty : this.secondaryColorEmpty;
  }
  set activeColorEmpty(value: boolean) {
    const editor = this.editor as Editor;
    if (editor && editor.activeColorField) {
      if (editor.activeColorField === 'primary') {
        this.primaryColorEmpty = value;
      } else {
        this.secondaryColorEmpty = value;
      }
    }
  }

  // tooltips in panel:
  get fontFamilyTooltip() {
    return this.fontFamily === undefined ? 'mixed' : undefined;
  }
  get baselineShiftTooltip() {
    return this.baselineShift !== undefined ? 'Baseline Shift' : 'mixed';
  }
  get fontSizeTooltip() {
    return this.fontSize !== undefined ? 'Font Size' : 'mixed';
  }
  get lineheightTooltip() {
    return this.lineheight !== undefined ? 'Line Height' : 'mixed';
  }
  get letterSpacingTooltip() {
    return this.letterSpacing !== undefined ? 'Letter Spacing' : 'mixed';
  }

  private commitChangesOnRemote(layer: TextLayer, replace: boolean, opts: TextToolData | undefined, historyString?: string) {
    if (opts) {
      opts.replace = replace;
      if (replace) this.model.user.history.clearRedos();
      if (historyString) opts.historyString = historyString;
      this.model.doTool(layer.id, opts);
    }
  }

  // context menu:
  selectEntireText() {
    if (this.input && isTextareaOk(this.textarea)) {
      if (!this.focused) {
        this.focused = true;
      } else {
        this.input?.focus();
        this.input?.classList.remove('ks-allow');
      }
      this.dragged = false;
      this.mode = TextToolMode.Typing;
      this.textSelection = {
        start: 0,
        end: this.textarea.text.length,
        length: this.textarea.text.length,
        direction: TextSelectionDirection.Forward,
        paragraphIndexes: generateNumbersArray(0, this.textarea.paragraphs.length - 1),
        text: this.textarea.text,
      };
    }
  }
  get hasGlyphsInSelection() {
    return this.input && this.focused && this.textSelection && this.textSelection.length > 0;
  }
  copy() {
    const selection = this.textSelection;
    const textarea = this.textarea;
    if (!selection || !textarea) throw new Error('Couldn\'t copy textarea text.');
    return copyTextFromTextarea(textarea, selection).then((successfullyCopied) => {
      if (!successfullyCopied) displayToastAboutNotSupportedClipboard(this.editor, 'Copying');
    }).catch((e) => {
      displayToastAboutNotSupportedClipboard(this.editor, 'Copying');
      DEVELOPMENT && console.warn('Couldn\'t copy textarea text.', e);
      return false;
    });
  }
  cut() {
    if (!this.hasGlyphsInSelection) return undefined;
    const selection = this.textSelection;
    const textarea = this.textarea;
    if (!selection || !textarea || !isLayerOk(this.layer, this.editor.drawing)) throw new Error('Couldn\'t copy textarea text.');
    if (!isLoadedConnectedAndNotLocked(this.editor as Editor)) return undefined;

    this.confirmDeferredChange();
    const goToStateBefore = this.globalHistory.createLayerState(this.layer.id, TextToolMode.Typing);

    return copyTextFromTextarea(textarea, selection)
      .then((successfullyCopied) => {
        if (!successfullyCopied) displayToastAboutNotSupportedClipboard(this.editor, 'Cutting');
        const selection = this.textSelection;
        const textarea = this.textarea;
        if (!successfullyCopied || !selection || !textarea || !isLayerOk(this.layer, this.editor.drawing)) {
          DEVELOPMENT && console.warn('Failed to copy text from selection, aborting cut.');
          return false;
        }

        const newSelection = removeCharactersFromTextarea(textarea, selection);
        this.globalHistory.pushUndo(goToStateBefore);

        this.commitChangesOnRemote(this.layer, false, this.recomputeTextLocally(TextToolMode.Typing));
        this.textSelection = newSelection;
        return true;
      })
      .catch((e) => {
        displayToastAboutNotSupportedClipboard(this.editor, 'Cutting');
        DEVELOPMENT && console.warn('Couldn\'t cut textarea text.', e);
        return false;
      });
  }
  async paste({ forcePlainText = false, providedText, providedPlainText }: TextToolPasteOptions) {
    const selection = this.textSelection;
    const textarea = this.textarea;
    const input = this.input;
    if (!selection) throw new Error('Couldn\'t paste text in textarea.');
    if (!textarea) throw new Error('Couldn\'t paste text in textarea.');
    if (!input) throw new Error('Couldn\'t paste text in textarea.');
    if (!isLayerOk(this.layer, this.editor.drawing)) throw new Error('Couldn\'t paste text in textarea.');
    if (!isLoadedConnectedAndNotLocked(this.editor as Editor)) return;

    this.confirmDeferredChange();
    const goToStateBefore = this.globalHistory.createLayerState(this.layer.id, TextToolMode.Typing);
    if (this.selectedEntireTextOrCaretAtStart) this.rememberPanelState();

    const clipboardText = providedText ?? (await readPastedTextFromClipboard());
    const clipboardTextSanitized = sanitizeEmojis(clipboardText);
    let htmlRoot;
    let newSelection: TextSelectionDetailed;
    try {
      htmlRoot = parseTextboxHtml(clipboardTextSanitized);
      if (htmlRoot) {
        if (forcePlainText) {
          const truncateResults = truncateRichText(textarea, selection, htmlToPlainText(htmlRoot));
          newSelection = insertCharactersIntoTextarea(textarea, selection, truncateResults.pastedTruncatedText, this.panelState);
        } else {
          newSelection = insertRichText(textarea, selection, htmlRoot);
        }
      } else {
        const plainText = providedPlainText ?? await readPlainTextFromClipboard();
        const truncateResults = truncateRichText(textarea, selection, plainText);
        newSelection = insertCharactersIntoTextarea(textarea, selection, truncateResults.pastedTruncatedText, this.panelState);
      }
      if (isLayerOk(this.layer, this.editor.drawing) && textarea.text !== this.layer.textData.text) {
        this.globalHistory.pushUndo(goToStateBefore);
        this.commitChangesOnRemote(this.layer, false, this.recomputeTextLocally(TextToolMode.Typing));
        this.input!.value = this.layer.textData.text;
        this.lastInputValue = this.input!.value;
        this.textSelection = newSelection;
      }
      if (isMobile) this.focused = this.focused;
    } catch (e) {
      displayToastAboutNotSupportedClipboard(this.editor, 'Pasting');
      DEVELOPMENT && console.error(`An error occurred when pasting ${htmlRoot ? '' : 'non-'}HTML from clipboard: `, e);
    }
  }
  clear() {
    if (!this.hasGlyphsInSelection) return;
    if (!isLoadedConnectedAndNotLocked(this.editor as Editor)) return;
    const selection = this.textSelection;
    const textarea = this.textarea;
    if (!selection || !textarea || !isLayerOk(this.layer, this.editor.drawing)) throw new Error('Couldn\'t clear text in textarea.');
    this.confirmDeferredChange();
    this.globalHistory.pushLayerState(this.layer.id, TextToolMode.Typing);
    const newSelection = removeCharactersFromTextarea(textarea, selection);
    this.commitChangesOnRemote(this.layer, false, this.recomputeTextLocally(TextToolMode.Typing));
    this.textSelection = newSelection;
  }

  readonly safeLimits = {
    scaleX: { min: MIN_SAFE_SCALE_X, max: MAX_SAFE_SCALE_X },
    scaleY: { min: MIN_SAFE_SCALE_Y, max: MAX_SAFE_SCALE_Y },
    size: { min: MIN_SAFE_SIZE, max: MAX_SAFE_SIZE },
    letterSpacing: { min: MIN_SAFE_LETTERSPACING, max: MAX_SAFE_LETTERSPACING },
    lineheight: { min: MIN_SAFE_LINEHEIGHT, max: MAX_SAFE_LINEHEIGHT },
    baselineShift: { min: MIN_SAFE_BASELINE_SHIFT, max: MAX_SAFE_BASELINE_SHIFT },
  };

  get transformationScaleY() {
    if (!this.textarea) return 1;
    return decomposeMat2d(this.textarea.transform).scaleY;
  }

  private get globalHistory() {
    return this.model.user.history as GlobalHistory;
  }

  private getHistoryEntry(layer: TextLayer, historyString: string) {
    const lastUndo = this.globalHistory.undos[this.globalHistory.undos.length - 1];
    const { textAction, layerId } = lastUndo?.find(iu => iu.textAction !== undefined) ?? { textAction: undefined, layerId: -1 };
    const stackable = !!(textAction && (textAction === TextToolMode.Moving || textAction === TextToolMode.Resizing || textAction.split('-')[0] === TextToolMode.Formatting));
    logAction(`TextTool.getHistoryEntry(${layer.id}, "${historyString}") - lastUndo: ${lastUndo ? undoLog(lastUndo) : '?'}`);
    if (stackable && textAction === historyString && layer.id === layerId) {
      return undefined;
    } else {
      return this.globalHistory.createLayerState(layer.id, historyString);
    }
  }
  getSelectionForUndo(layer: Layer): TextSelection | undefined {
    const selection = this.textSelection;
    if (this.layer === layer && this.focused && selection) {
      const { start, end, direction } = selection;
      return [start, end, direction];
    } else {
      return undefined;
    }
  }
  retrieveSelectionFromUndo(textSelectionSavedInUndo: TextSelection | undefined) {
    if (!textSelectionSavedInUndo || !this.input || !isTextareaOk(this.textarea)) return;
    const [start, end, direction] = textSelectionSavedInUndo;
    const newSelection: TextSelectionDetailed = { length: 0, text: '', paragraphIndexes: [], ...this.textSelection, start, end, direction };
    applyTextAndParagraphIndexesToSelection(this.textarea, newSelection, true);
    this.textSelection = newSelection;
  }
}

const isLayerOk = (layer: Layer | undefined, drawing: Drawing): layer is TextLayer => !!layer && isTextLayer(layer) && drawing.layers.includes(layer);
const isTextareaOk = (textarea: Textarea | undefined): textarea is Textarea => !!textarea && !!textarea.type;

export function preprocessTextLayersForDrawing(drawing: Drawing, drawingFn: (layer: ReadyTextLayer, drawing: Drawing) => void, force = false) {
  forAllTextLayers(drawing, (layer) => {
    if (canDrawTextLayer(layer) && (layer.invalidateCanvas || force)) {
      drawingFn(layer, drawing);
    }
  });
}

export function shouldRenderTextareaBoundaries(textarea: Textarea, tool: TextTool, options: DrawOptions): boolean {
  return (tool.focused || textarea.type !== TextareaType.AutoWidth) && options.selectedTool?.id !== ToolId.Transform;
}

export function shouldRenderTextareaCursor(_textarea: Textarea, tool: TextTool, options: DrawOptions): boolean {
  return (tool.focused && !tool.isTextLayerLocked) && (!options.holdingTool || options.holdingTool.id === ToolId.Text);
}

export function shouldRenderTextareaBaselineIndicator(textarea: Textarea, _tool: TextTool, options: DrawOptions) {
  return (textarea.type !== TextareaType.AutoWidth || !!textarea.text.length) && (!options.holdingTool || options.holdingTool.id === ToolId.Text);
}

export function shouldRenderTextareaControlPoints(textarea: Textarea, tool: TextTool, options: DrawOptions): boolean {
  return !tool.isTextLayerLocked && (tool.focused || textarea.type !== TextareaType.AutoWidth) && (!options.holdingTool || options.holdingTool.id === ToolId.Text);
}

export function shouldRenderTextareaOverflowIndicator(textarea: Textarea, _tool: TextTool, options: DrawOptions): boolean {
  return textarea.hasOverflowingCharacters && (!options.holdingTool || options.holdingTool.id === ToolId.Text);
}

const TEXTURE_RECT_STROKE_COLOR = `rgba(${TEXTAREA_BOUNDARIES_COLOR.r * 255}, ${TEXTAREA_BOUNDARIES_COLOR.b * 255}, ${TEXTAREA_BOUNDARIES_COLOR.g * 255}, ${TEXTAREA_BOUNDARIES_COLOR.a * 255})`;
export function drawTextareaTextureRect(ctx: CanvasRenderingContext2D, textarea: Textarea) {
  if (DEVELOPMENT && DRAW_TEXTURE_RECT) {
    const ratio = getPixelRatio();
    const greenRect = textarea.textureRect;
    ctx.lineWidth = 2 * TEXTAREA_UNHOVERED_BOUNDARIES_WIDTH / ratio;
    ctx.strokeStyle = TEXTURE_RECT_STROKE_COLOR;
    ctx.strokeRect(greenRect.x, greenRect.y, greenRect.w, greenRect.h);
  }
}

function setInputPositionOnPage(input: HTMLTextAreaElement, view: Viewport, docX: number, docY: number) {
  const windowWidth = (!SERVER && !TESTS && typeof window !== 'undefined') ? window.innerWidth : 1920;
  const windowHeight = (!SERVER && !TESTS && typeof window !== 'undefined') ? window.innerHeight : 1080;
  const topLeft = createPoint(docX, docY);
  documentToScreenXY(topLeft, topLeft.x, topLeft.y, view);
  const left = FIX_TEXTAREA_IN_TOP_LEFT ? 50 : clamp(topLeft.x, 0, windowWidth - ARTIFICIAL_TEXTAREA_SIZE);
  const top = FIX_TEXTAREA_IN_TOP_LEFT ? 50 : clamp(topLeft.y, 0, windowHeight - ARTIFICIAL_TEXTAREA_SIZE);
  input.style.transform = `translate3d(${left}px, ${top}px, 0)`;
  input.style.overflow = 'hidden';
}

function setShowKeyboardFabPositionOnPage(button: HTMLButtonElement | undefined, view: Viewport, textarea: Textarea) {
  if (button && !SERVER) {
    const editorView = document.getElementsByClassName('editor-view').item(0);

    const containerWidth = (!SERVER && !TESTS && typeof window !== 'undefined') ? editorView?.clientWidth ?? window.innerWidth : 1920;
    const containerHeight = (!SERVER && !TESTS && typeof window !== 'undefined') ? editorView?.clientHeight ?? window.innerHeight : 1080;

    const { clientWidth: buttonWidth, clientHeight: buttonHeight } = button;
    const buttonMargin = 20;

    const { x, y, w, h } = textarea.textureRect;

    const textareaPoint = createPoint(x + w / 2, y + h);
    documentToScreenXY(textareaPoint, textareaPoint.x, textareaPoint.y, view);
    const { x: textareaCenter, y: underTextarea } = textareaPoint;

    const minX = buttonMargin;
    const maxX = containerWidth - buttonWidth - buttonMargin;
    let buttonLeft = textareaCenter - buttonWidth / 2;
    const minY = buttonMargin;
    const maxY = containerHeight - buttonHeight - buttonMargin;
    let buttonTop = clamp(underTextarea + buttonMargin, minY, Infinity);

    if (buttonLeft < minX) {
      // textarea probably exceeds viewport (container) to left
      setPoint(textareaPoint, x + w, y + h / 2);
      documentToScreenXY(textareaPoint, textareaPoint.x, textareaPoint.y, view);
      buttonLeft = clamp(textareaPoint.x + buttonMargin, minX, maxX);
      buttonTop = clamp(textareaPoint.y - buttonHeight / 2, minY, maxY);
    } else if (buttonLeft > maxX) {
      // textarea probably exceeds viewport (container) to right
      setPoint(textareaPoint, x, y + h / 2);
      documentToScreenXY(textareaPoint, textareaPoint.x, textareaPoint.y, view);
      buttonLeft = clamp(textareaPoint.x - buttonMargin - buttonWidth, minX, maxX);
      buttonTop = clamp(textareaPoint.y - buttonHeight / 2, minY, maxY);
    } else if (buttonTop > maxY) {
      // textarea probably exceeds viewport (container) to bottom
      setPoint(textareaPoint, x + w / 2, y);
      documentToScreenXY(textareaPoint, textareaPoint.x, textareaPoint.y, view);
      buttonLeft = clamp(textareaPoint.x - buttonWidth / 2, minX, maxX);
      buttonTop = clamp(textareaPoint.y - buttonMargin - buttonHeight, minY, maxY);
    }

    button.style.opacity = '0';
    button.style.transform = `translate3d(${buttonLeft}px, ${buttonTop}px, 0)`;
    setTimeout(() => button.style.opacity = '1', 250);
  }
}

const setTextLayerDirty = (editor: IToolEditor, layer: TextLayer) => {
  if (!layer.textData.dirty) {
    layer.textData.dirty = true;
    if (layer.textarea) layer.textarea.dirty = true;
    layer.name = '';
    editor.apply(() => { });
  }
};

function selectionToString(selection: TextSelectionDetailed | undefined) {
  if (selection) {
    const { start, end, direction } = selection;
    return `(${start},${end},${direction})`;
  } else {
    return '';
  }
}
