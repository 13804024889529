import { ITool, IToolEditor, IToolModel, ToolId, TabletEvent, CursorType, LayerData, PerspectiveGridLayer, Drawing, Viewport, User, Point, IToolData, Feature, hasAltKey, IUndoFunction, ToolSurface, PerspectiveGridLayerData, PerspectiveGridStored, Analytics, RendererSettings, Rect } from '../interfaces';
import { perspectiveGridToolIcon } from '../icons';
import { documentToScreenPoint, documentToScreenPoints, documentToScreenXY } from '../viewport';
import { copyRect, createRect, isRectEmpty, rectContainsXY, setRect } from '../rect';
import { copyPoint, createPoint, rectToPoints, setPoint } from '../point';
import { invalidEnum } from '../baseUtils';
import { cloneDeep, getPixelRatio } from '../utils';
import { Editor, lockEditor, unlockEditor } from '../../services/editor';
import { selectLayer, withNewLayers } from '../../services/layerActions';
import { finishTransform, safeFloatAny } from '../toolUtils';
import { isPerspectiveGridLayer, layerFromState, setLayerState, toLayerState } from '../layer';
import { addLayerToDrawing, sendLayerOrder } from '../layerToolHelpers';
import { pointInsideRegion } from './transformTool';
import { redraw } from '../../services/editorUtils';
import { getLayerSafe } from '../drawing';
import { colorToCSS, colorToFloatArray } from '../color';
import { copyMat2d, createMat2d, decomposeMat2d, identityMat2d, invertMat2d } from '../mat2d';
import { createTransform } from '../toolSurface';
import { createVec2, setVec2, transformVec2ByMat2d } from '../vec2';
import { PerspectiveGridModeEvent } from '../analytics';

enum Action {
  None = 'none',
  Create = 'create',
  BoundingBoxMove = 'boundingBoxMove',
  VanishingPointReplace = 'vanishingPointReplace',
  LinesNumberChange = 'linesNumberChange',
  ThicknessChange = 'thicknessChange',
  DepthFadeChange = 'depthFadeChange'
}

const COLOR_NORMAL = 0x000000FF;
const COLOR_SELECTED = 0x1A6EEDFF;
const COLOR_NORMAL_SEMI = 0x00000080;
const COLOR_SELECTED_SEMI = 0x1A6EED80;
const PERSPECTIVE_TYPE_MAP = ['', '1P', '2P', '3P'];
const DEFAULT_LINES_NUMER = 80;
const DEFAULT_THICKNESS = 1;
const DEFAULT_OPACITY = 0.5;
const DEFAULT_DEPTH_FADE = 0.20;
const EPS = 0.0001;
const LAYOUT_ACTIONS = [
  Action.Create,
  Action.VanishingPointReplace,
  Action.BoundingBoxMove
];
const SLIDER_ACTIONS = [
  Action.LinesNumberChange,
  Action.ThicknessChange,
  Action.DepthFadeChange
];
export const PERSPECTIVE_GRID_COLOR_ARRAY_NORMAL = colorToFloatArray(COLOR_NORMAL);
export const PERSPECTIVE_GRID_COLOR_ARRAY_SELECTED = colorToFloatArray(COLOR_SELECTED);
export const PERSPECTIVE_GRID_COLOR_ARRAY_NORMAL_SEMI = colorToFloatArray(COLOR_NORMAL_SEMI);
export const PERSPECTIVE_GRID_COLOR_ARRAY_SELECTED_SEMI = colorToFloatArray(COLOR_SELECTED_SEMI);
export const PERSPECTIVE_GRID_COLOR_STR_NORMAL = colorToCSS(COLOR_NORMAL);
export const PERSPECTIVE_GRID_COLOR_STR_SELECTED = colorToCSS(COLOR_SELECTED);
export const PERSPECTIVE_GRID_COLOR_STR_NORMAL_SEMI = colorToCSS(COLOR_NORMAL_SEMI);
export const PERSPECTIVE_GRID_COLOR_STR_SELECTED_SEMI = colorToCSS(COLOR_SELECTED_SEMI);
export const PERSPECTIVE_GRID_HANDLE_SIZE = 44.0;
export const PERSPECTIVE_GRID_HANDLE_RADIUS = PERSPECTIVE_GRID_HANDLE_SIZE / (2 * 4);
export const PERSPECTIVE_GRID_HANDLE_LINE_WIDTH_LIGHT = 1.5;
export const PERSPECTIVE_GRID_HANDLE_LINE_WIDTH_MEDIUM = 2.5;
export const PERSPECTIVE_GRID_HANDLE_LINE_WIDTH_BOLD = 4.5;
export const PERSPECTIVE_GRID_BB_LINE_WIDTH_DEFAULT = 1.0;
export const PERSPECTIVE_GRID_BB_LINE_WIDTH_HOVER = 2.0;
export const PERSPECTIVE_GRID_MIN_BB_SIZE = 44.0;
export const PERSPECTIVE_GRID_SNAP_SIZE = 30.0;
export const PERSPECTIVE_GRID_PLANE_GEOMETRY_STRETCH = 3;

const tmpBounds = [createPoint(0, 0), createPoint(0, 0), createPoint(0, 0), createPoint(0, 0)];
const tmpPoint = createPoint(0.0, 0.0);
const tmpRect = createRect(0.0, 0.0, 0.0, 0.0);
const tmpMat = createMat2d();
const tmpMat2 = createMat2d();
const tmpVec = createVec2();
const boundingBoxCursors = new Map([
  [BoundingBoxRegion.TopLeft, CursorType.ResizeTL],
  [BoundingBoxRegion.TopRight, CursorType.ResizeTR],
  [BoundingBoxRegion.BottomRight, CursorType.ResizeTL],
  [BoundingBoxRegion.BottomLeft, CursorType.ResizeTR],
  [BoundingBoxRegion.Edge, CursorType.Move],
]);

const enum BoundingBoxRegion {
  Other,
  TopLeft,
  TopRight,
  BottomRight,
  BottomLeft,
  Edge
}

export enum PerspectiveGridVanishingPointState {
  Default,
  Hover,
  Pressed
}

export enum PerspectiveGridBoundingBoxState {
  Default,
  Hover
}

export enum PerspectiveGridRemoteAction {
  LayerCreated,
  LayerUpdated,
}

export interface IPerspectiveGridData extends IToolData {
  layerIndex?: number;
  layerData?: LayerData;
  action: PerspectiveGridRemoteAction;
}

export class PerspectiveGridTool implements ITool {
  id = ToolId.PerspectiveGrid;
  name = 'Perspective Grid';
  description = 'Overlay your artwork with custom line grids that help with perspective drawing. This feature is currently in Beta and is free for all users to test.';
  learnMore = 'https://magm.ai/perspective-grid-guide';
  video = { url: 'assets/videos/perspective-grid.mp4', width: 640, height: 360 };
  contextMenu = true;
  icon = perspectiveGridToolIcon;
  cursor = CursorType.Crosshair;
  cancellableLocally = true;
  skipMoves = true;
  feature = Feature.PerspectiveGrid;
  boundingBoxState = PerspectiveGridBoundingBoxState.Default;
  private action = Action.None;
  private startPosition = createPoint(0, 0);
  private startOffset = createPoint(0, 0);
  private dataUnassigned: PerspectiveGridLayerData;
  private boundsStart = createRect(0, 0, 0, 0);
  private boundingBoxRegion = BoundingBoxRegion.Other;
  private vpointDrag: Point | undefined = undefined;
  private vpointHover: Point | undefined = undefined;
  private undo: IUndoFunction | undefined = undefined;
  private snapshot: LayerData | undefined = undefined;

  constructor(public editor: IToolEditor, public model: IToolModel) {
    this.dataUnassigned = createPerspectiveGridData();
    applyDefaultData(this.dataUnassigned);
  }

  start(x: number, y: number, _pressure: number, e: TabletEvent) {
    if (this.action !== Action.None) {
      //Skip. Another action is already in progress.
      return;
    }
    if (this.undo !== undefined) {
      throw new Error('[PerspectiveGrid.start] Undo is set, but it shouldn\'t be.');
    }
    this.startPosition.x = Math.round(x);
    this.startPosition.y = Math.round(y);
    if (this.layer === undefined) {
      this.startCreating(x, y);
    } else {
      this.snapshot = toLayerState(this.layer);
      this.undo = this.model.user.history.createLayerState(this.layer.id);
      this.boundingBoxRegion = this.pickBoundingBoxRegion(this.model.user, this.editor.view, x, y);
      if (this.boundingBoxRegion !== BoundingBoxRegion.Other) {
        this.startBoundingBox();
      } else {
        this.startVanishingPoint(x, y, e);
      }
    }
    this.editor.apply(() => { });
  }

  move(x: number, y: number, _pressure: number) {
    if (this.action === Action.Create || this.action === Action.BoundingBoxMove) {
      this.moveBoundingBox(x, y);
      redraw(this.editor);
    } else if (this.action === Action.VanishingPointReplace) {
      this.moveVanishingPoint(x, y);
      redraw(this.editor);
    }
  }

  end() {
    if (this.action !== Action.None && this.action !== Action.Create && this.action !== Action.VanishingPointReplace && this.action !== Action.BoundingBoxMove) {
      //Skip. Incompatible action.
      return;
    }
    const action = this.action;
    const undo = this.undo;
    this.action = Action.None;
    this.snapshot = undefined;
    this.undo = undefined;
    if (action === Action.Create) {
      if (this.layer !== undefined) {
        throw new Error('[PerspectiveGridTool.end] Layer already created.');
      }
      this.createLayerAndPopulate();
    } else if (action === Action.VanishingPointReplace) {
      this.vpointDrag = undefined;
      this.submitVanishingPoint();
    }
    if (undo !== undefined) {
      finishTransform(this.editor, this.model.user, 'PerspectiveGridTool:end');
      this.model.user.history.pushUndo(undo);
      this.doTool();
    } else {
      if (action !== Action.None && action !== Action.Create) {
        throw new Error('[PerspectiveGridTool.end] Undo function is not defined.');
      }
    }
    setPoint(this.startPosition, 0, 0);
    setPoint(this.startOffset, 0, 0);
  }

  cancel() {
    if (this.action !== Action.None && this.action !== Action.Create && this.action !== Action.VanishingPointReplace && this.action !== Action.BoundingBoxMove) {
      //Skip. Incompatible action.
      return;
    }
    const action = this.action;
    const snapshot = this.snapshot;
    this.action = Action.None;
    this.snapshot = undefined;
    this.undo = undefined;
    if (action === Action.Create) {
      applyDefaultData(this.dataUnassigned);
      if (this.layer !== undefined) {
        throw new Error('[PerspectiveGridTool.cancel] Layer already created.');
      }
    } else if (action === Action.VanishingPointReplace || action === Action.BoundingBoxMove) {
      if (this.layer && snapshot) {
        setLayerState(this.layer, snapshot);
      } else {
        throw new Error('[PerspectiveGridTool.cancel] Layer or snapshot missing.');
      }
    }
    this.startPosition.x = 0;
    this.startPosition.y = 0;
  }

  hover(x: number, y: number, e: TabletEvent) {
    this.vpointHover = undefined;
    this.boundingBoxState = PerspectiveGridBoundingBoxState.Default;
    x += this.editor.drawing.x;
    y += this.editor.drawing.y;
    const boundingBoxRegion = this.pickBoundingBoxRegion(this.model.user, this.editor.view, x, y);
    if (boundingBoxRegion !== BoundingBoxRegion.Other) {
      this.cursor = boundingBoxCursors.get(boundingBoxRegion) ?? CursorType.Crosshair;
      if (boundingBoxRegion === BoundingBoxRegion.Edge) {
        this.boundingBoxState = PerspectiveGridBoundingBoxState.Hover;
      }
    } else {
      const vpoint = this.getVanishingPointByPosition(x, y);
      if (vpoint !== undefined) {
        if (hasAltKey(e)) {
          this.cursor = CursorType.Remove;
        } else {
          this.cursor = CursorType.Hand;
        }
        this.vpointHover = vpoint;
      } else {
        this.cursor = CursorType.Crosshair;
      }
    }
  }

  do(data: IPerspectiveGridData): void {
    switch (data.action) {
      case PerspectiveGridRemoteAction.LayerCreated: {
        if (data.layerData === undefined || data.layerIndex === undefined) {
          throw new Error('[PerspectiveGridTool.do] Creating layer with missing layerData or layerIndex.');
        }
        const layer = layerFromState(data.layerData);
        finishTransform(this.editor, this.model.user, `PerspectiveGridTool:do`);
        this.model.user.history.pushAddLayer(data.layerData, data.layerIndex);
        addLayerToDrawing(this.editor, layer, data.layerIndex);
        layer.owner = this.model.user;
        redraw(this.editor);
        break;
      }
      case PerspectiveGridRemoteAction.LayerUpdated: {
        if (data.layerData === undefined) {
          throw new Error('[PerspectiveGridTool.do] Creating layer with missing layerData or layerIndex.');
        }
        const layer = getLayerSafe(this.editor.drawing, data.layerData.id);
        finishTransform(this.editor, this.model.user, 'PerspectiveGridTool:do');
        this.model.user.history.pushLayerState(layer.id);
        setLayerState(layer, data.layerData);
        redraw(this.editor);
        break;
      }
      default: {
        invalidEnum(data.action);
      }
    }
  }

  onLayerChange() {
    if (this.layer === undefined) {
      this.dataUnassigned = createPerspectiveGridData();
      applyDefaultData(this.dataUnassigned);
    }
  }

  get layer(): PerspectiveGridLayer | undefined {
    return isPerspectiveGridLayer(this.model.user.activeLayer) ? this.model.user.activeLayer : undefined;
  }

  get data(): PerspectiveGridLayerData {
    return this.layer ? this.layer.perspectiveGrid : this.dataUnassigned;
  }

  get perspectiveType(): string {
    return PERSPECTIVE_TYPE_MAP[this.data.vpointList.length];
  }

  set perspectiveType(type: string) {
    if (this.action !== Action.None) {
      //Skip. Another action is already in progress.
      return;
    }
    if (this.layer !== undefined) {
      finishTransform(this.editor, this.model.user, 'PerspectiveGridTool:perspectiveType');
      this.model.user.history.pushLayerState(this.layer.id);
    }
    const stored = this.getStored(type);
    if (stored) {
      this.data.vpointList = cloneDeep(stored.vpointList);
      calculatePerspectiveGridData(this.editor.view, this.editor.drawing, this.data);
      redraw(this.editor);
    } else {
      if (isRectEmpty(this.data.bounds)) {
        setBoundingBoxToDrawing(this.data, this.editor.drawing);
      }
      if (type === '') {
        this.data.vpointList = [];
      } else if (type === '1P') {
        this.data.vpointList = [
          createPoint(
            this.data.bounds.x + this.data.bounds.w / 2,
            this.data.bounds.y + this.data.bounds.h / 2)
        ];
      } else if (type === '2P') {
        this.data.vpointList = [
          createPoint(
            this.data.bounds.x + this.data.bounds.w / 4,
            this.data.bounds.y + this.data.bounds.h / 2),
          createPoint(
            this.data.bounds.x + this.data.bounds.w / 4 * 3,
            this.data.bounds.y + this.data.bounds.h / 2)
        ];
      } else if (type === '3P') {
        this.data.vpointList = [
          createPoint(
            this.data.bounds.x + this.data.bounds.w / 4,
            this.data.bounds.y + this.data.bounds.h / 2),
          createPoint(
            this.data.bounds.x + this.data.bounds.w / 4 * 3,
            this.data.bounds.y + this.data.bounds.h / 2),
          createPoint(
            this.data.bounds.x + this.data.bounds.w / 2,
            this.data.bounds.y - this.data.bounds.h)
        ];
      }
      this.submitVanishingPoint();
      if (this.layer === undefined) {
        this.createLayer();
      }
    }
    if (this.layer !== undefined) {
      this.doTool();
    }
    this.editor.track?.event<PerspectiveGridModeEvent>(Analytics.PerspectiveGridMode, {
      mode: this.perspectiveType
    });
  }

  set linesNumber(value: number) {
    this.data.linesNumber = value;
    redraw(this.editor);
  }

  get linesNumber() {
    return this.data.linesNumber;
  }

  set thickness(value: number) {
    this.data.thickness = Math.round(value * 10) / 10;
    redraw(this.editor);
  }

  get thickness() {
    return this.data.thickness;
  }

  set depthFade(value: number) {
    this.data.depthFade = value;
    redraw(this.editor);
  }

  get depthFade() {
    return this.data.depthFade;
  }

  sliderStart(key: string) {
    if (this.action !== Action.None) {
      //Skip. Another action is already in progress.
      return;
    }
    this.action = Action[key as keyof typeof Action] ?? Action.None;
    if (this.action === Action.None) {
      throw new Error('[PerspectiveGrid.sliderStart] Invalid action.');
    }
  }

  sliderStop(key: string, { oldValue, value }: { oldValue: number; value: number; }) {
    const action = Action[key as keyof typeof Action] ?? Action.None;
    if (action !== this.action) {
      //Skip. Incompatible action.
      return;
    }
    this.action = Action.None;
    if (action === Action.LinesNumberChange) {
      this.linesNumber = oldValue;
    } else if (action === Action.ThicknessChange) {
      this.thickness = oldValue;
    } else if (action === Action.DepthFadeChange) {
      this.depthFade = oldValue;
    }
    if (this.layer !== undefined) {
      finishTransform(this.editor, this.model.user, 'PerspectiveGridTool:sliderStop');
      this.model.user.history.pushLayerState(this.layer.id);
      this.doTool();
    }
    if (action === Action.LinesNumberChange) {
      this.linesNumber = value;
    } else if (action === Action.ThicknessChange) {
      this.thickness = value;
    } else if (action === Action.DepthFadeChange) {
      this.depthFade = value;
    }
  }

  isLayoutAction(): boolean {
    return LAYOUT_ACTIONS.includes(this.action);
  }

  isSliderAction(): boolean {
    return SLIDER_ACTIONS.includes(this.action);
  }

  isAction() {
    return this.action !== Action.None;
  }

  removeVanishingPoint(vpoint: Point): boolean {
    if (this.action !== Action.None) {
      //Skip. Another action is already in progress.
      this.editor.toast(t => t.warning({ message: 'You cannot remove this vanishing point' }));
      return false;
    }
    if (this.undo !== undefined) {
      throw new Error('[PerspectiveGrid.start] Undo is set, but it shouldn\'t be.');
    }
    if (this.layer !== undefined) {
      this.undo = this.model.user.history.createLayerState(this.layer.id);
    }
    const result = this.removeVanishingPointInternal(vpoint);
    if (result) {
      if (this.undo !== undefined) {
        finishTransform(this.editor, this.model.user, 'PerspectiveGridTool:end');
        this.model.user.history.pushUndo(this.undo);
        this.doTool();
      }
    } else {
      this.editor.toast(t => t.warning({ message: 'You cannot remove this vanishing point' }));
    }
    this.undo = undefined;
    return result;
  }

  getVanishingPointByPosition(x: number, y: number): Point | undefined {
    documentToScreenXY(tmpPoint, x - this.editor.drawing.x, y - this.editor.drawing.y, this.editor.view);
    let vpointFound: Point | undefined = undefined;
    tmpRect.w = PERSPECTIVE_GRID_HANDLE_SIZE;
    tmpRect.h = PERSPECTIVE_GRID_HANDLE_SIZE;
    for (let i = 0; i < this.data.vpointScreenList.length; i++) {
      tmpRect.x = this.data.vpointScreenList[i].x - PERSPECTIVE_GRID_HANDLE_SIZE / 2;
      tmpRect.y = this.data.vpointScreenList[i].y - PERSPECTIVE_GRID_HANDLE_SIZE / 2;
      if (rectContainsXY(tmpRect, tmpPoint.x, tmpPoint.y)) {
        vpointFound = this.data.vpointList[i];
        break;
      }
    }
    return vpointFound;
  }

  getVanishingPointState(vpoint: Point): PerspectiveGridVanishingPointState {
    if (vpoint === this.vpointDrag) {
      return PerspectiveGridVanishingPointState.Pressed;
    } else if (vpoint === this.vpointHover) {
      return PerspectiveGridVanishingPointState.Hover;
    }
    return PerspectiveGridVanishingPointState.Default;
  }

  private startCreating(x: number, y: number) {
    const drawing = this.editor.drawing;
    this.action = Action.Create;
    if (!this.editor.view.flipped) {
      this.boundingBoxRegion = BoundingBoxRegion.BottomRight;
    } else {
      this.boundingBoxRegion = BoundingBoxRegion.BottomLeft;
    }
    if (Math.abs(x - drawing.x) < PERSPECTIVE_GRID_SNAP_SIZE) {
      this.data.bounds.x = drawing.x;
    } else {
      this.data.bounds.x = Math.round(x);
    }
    if (Math.abs(y - drawing.y) < PERSPECTIVE_GRID_SNAP_SIZE) {
      this.data.bounds.y = drawing.y;
    } else {
      this.data.bounds.y = Math.round(y);
    }
    this.data.bounds.w = 0;
    this.data.bounds.h = 0;
    copyRect(this.boundsStart, this.data.bounds);
  }

  private startBoundingBox() {
    this.action = Action.BoundingBoxMove;
    copyRect(this.boundsStart, this.data.bounds);
  }

  private startVanishingPoint(x: number, y: number, e?: TabletEvent) {
    this.action = Action.VanishingPointReplace;
    calculatePerspectiveGridData(this.editor.view, this.editor.drawing, this.data);
    const vpoint = this.getVanishingPointByPosition(x, y);
    let modeChanged = false;
    if (vpoint === undefined) {
      if (this.data.vpointList.length == 1) {
        documentToPerspectiveGridXY(tmpPoint, x, y, this.data);
        this.data.vpointList.push(clonePointSafe(tmpPoint));
        this.vpointDrag = this.data.vpointList[1];
        modeChanged = true;
      } else if (this.data.vpointList.length == 2) {
        documentToPerspectiveGridXY(tmpPoint, x, y, this.data);
        this.data.vpointList.push(clonePointSafe(tmpPoint));
        this.vpointDrag = this.data.vpointList[2];
        modeChanged = true;
      } else {
        this.undo = undefined;
        this.action = Action.None;
      }
    } else {
      if (!!e && hasAltKey(e)) {
        modeChanged = this.removeVanishingPointInternal(vpoint);
        if (!modeChanged) {
          this.editor.toast(t => t.warning({ message: 'You cannot remove this vanishing point' }));
          this.undo = undefined;
          this.action = Action.None;
        }
      } else {
        setPoint(tmpPoint, vpoint.x, vpoint.y);
        perspectiveGridToDocument(tmpPoint, this.data);
        setPoint(this.startOffset, tmpPoint.x - x, tmpPoint.y - y);
        this.vpointDrag = vpoint;
      }
    }
    calculatePerspectiveGridData(this.editor.view, this.editor.drawing, this.data);
    redraw(this.editor);
    if (modeChanged) {
      this.editor.track?.event<PerspectiveGridModeEvent>(Analytics.PerspectiveGridMode, {
        mode: this.perspectiveType
      });
    }
  }

  private moveVanishingPoint(x: number, y: number) {
    if (this.vpointDrag !== undefined) {
      setPoint(this.vpointDrag, x + this.startOffset.x, y + this.startOffset.y);
      documentToPerspectiveGrid(this.vpointDrag, this.data);
    }
  }

  private moveBoundingBox(x: number, y: number) {
    let dxx = Math.round(x - this.startPosition.x);
    let dyy = Math.round(y - this.startPosition.y);
    copyMat2d(tmpMat, this.data.transform);
    const { rotate, scaleX, scaleY } = decomposeMat2d(tmpMat);
    let dx = dxx * Math.cos(rotate) + dyy * Math.sin(rotate);
    let dy = -dxx * Math.sin(rotate) + dyy * Math.cos(rotate);
    dx /= scaleX;
    dy /= scaleY;
    const minBoxWidth = Math.min(16, this.editor.drawing.w);
    const minBoxHeight = Math.min(16, this.editor.drawing.h);
    if (this.boundingBoxRegion === BoundingBoxRegion.TopLeft) {
      const maxDx = this.boundsStart.w - minBoxWidth;
      dx = Math.min(dx, maxDx);
      this.data.bounds.x = this.boundsStart.x + dx;
      this.data.bounds.w = this.boundsStart.w - dx;
      const maxDy = this.boundsStart.h - minBoxHeight;
      dy = Math.min(dy, maxDy);
      this.data.bounds.y = this.boundsStart.y + dy;
      this.data.bounds.h = this.boundsStart.h - dy;
    } else if (this.boundingBoxRegion === BoundingBoxRegion.TopRight) {
      const minDx = -this.boundsStart.w + minBoxWidth;
      dx = Math.max(dx, minDx);
      this.data.bounds.w = this.boundsStart.w + dx;
      const maxDy = this.boundsStart.h - minBoxHeight;
      dy = Math.min(dy, maxDy);
      this.data.bounds.y = this.boundsStart.y + dy;
      this.data.bounds.h = this.boundsStart.h - dy;
    } else if (this.boundingBoxRegion === BoundingBoxRegion.BottomLeft) {
      const maxDx = this.boundsStart.w - minBoxWidth;
      dx = Math.min(dx, maxDx);
      this.data.bounds.x = this.boundsStart.x + dx;
      this.data.bounds.w = this.boundsStart.w - dx;
      const minDy = -this.boundsStart.h + minBoxHeight;
      dy = Math.max(dy, minDy);
      this.data.bounds.h = this.boundsStart.h + dy;
    } else if (this.boundingBoxRegion === BoundingBoxRegion.BottomRight) {
      const minDx = -this.boundsStart.w + minBoxWidth;
      dx = Math.max(dx, minDx);
      this.data.bounds.w = this.boundsStart.w + dx;
      const minDy = -this.boundsStart.h + minBoxHeight;
      dy = Math.max(dy, minDy);
      this.data.bounds.h = this.boundsStart.h + dy;
    } else if (this.boundingBoxRegion === BoundingBoxRegion.Edge) {
      this.data.bounds.x = this.boundsStart.x + dx;
      this.data.bounds.y = this.boundsStart.y + dy;
    } 
    if (this.action === Action.Create) {
      const drawing = this.editor.drawing;
      x -= drawing.x;
      y -= drawing.y;
      if (Math.abs(x - drawing.w) < PERSPECTIVE_GRID_SNAP_SIZE) {
        dx = (drawing.x + drawing.w) - this.data.bounds.x;
        this.data.bounds.w = Math.max(dx, minBoxWidth);
      }
      if (Math.abs(y - drawing.h) < PERSPECTIVE_GRID_SNAP_SIZE) {
        dy = (drawing.y + drawing.h) - this.data.bounds.y;
        this.data.bounds.h = Math.max(dy, minBoxHeight);
      }
    }
  }

  private getStored(type: string): PerspectiveGridStored | undefined {
    for (let stored of this.data.vpointListStored) {
      if (stored.name == type) return stored;
    }
    return undefined;
  }

  private updateStored(type: string) {
    if (type === '') {
      throw new Error('[PerspectiveGridTool.updateStored] Trying to store invalid perspective mode.');
    }
    let stored = this.getStored(type);
    if (stored !== undefined) {
      stored.vpointList = cloneDeep(this.data.vpointList);
    } else {
      stored = { name: type, vpointList: cloneDeep(this.data.vpointList) };
      this.data.vpointListStored.push(stored);
    }
  }

  private removeVanishingPointInternal(vpoint: Point): boolean {
    if (this.data.vpointList.length <= 1) return false;
    const id = this.data.vpointList.lastIndexOf(vpoint);
    if (id >= 0) {
      if (vpoint == this.vpointDrag) {
        this.vpointDrag = undefined;
      }
      if (vpoint == this.vpointHover) {
        this.vpointHover = undefined;
      }
      this.data.vpointList.splice(id, 1);
      return true;
    }
    return false;
  }

  private submitVanishingPoint() {
    if (this.data.vpointList.length > 0) {
      this.updateStored(this.perspectiveType);
    }
    calculatePerspectiveGridData(this.editor.view, this.editor.drawing, this.data);
    redraw(this.editor);
  }

  private createLayerAndPopulate() {
    if (this.data.bounds.w < PERSPECTIVE_GRID_MIN_BB_SIZE && this.data.bounds.h < PERSPECTIVE_GRID_MIN_BB_SIZE) {
      this.data.bounds.x = this.editor.drawing.x;
      this.data.bounds.y = this.editor.drawing.y;
      this.data.bounds.w = this.editor.drawing.w;
      this.data.bounds.h = this.editor.drawing.h;
      this.data.vpointList = [
        createPoint(
          this.startPosition.x,
          this.startPosition.y)
      ];
    } else {
      this.data.vpointList = [
        createPoint(
          this.data.bounds.x + this.data.bounds.w / 2,
          this.data.bounds.y + this.data.bounds.h / 2)
      ];
    }
    this.submitVanishingPoint();
    this.createLayer();
    this.editor.track?.event<PerspectiveGridModeEvent>(Analytics.PerspectiveGridMode, {
      mode: this.perspectiveType
    });
  }

  private createLayer() {
    const editor = this.editor as Editor;
    const user = this.model.user;
    const finished = editor.model.startTask('Creating perspective layer');
    const connId = editor.model.connId;
    editor.drawingInProgress = false;
    setTimeout(() => this.editor.apply(() => { }), 500);
    withNewLayers(editor, 1, () => true, ([id]) => {
      unlockEditor(editor);
      finished();
      if (!id) {
        throw new Error('[PerspectiveGridTool.createLayer] No layerId for creating perspective grid layer.');
      }
      if (connId != editor.model.connId) {
        throw new Error('[PerspectiveGridTool.createLayer] Invalid connId.');
      }
      finishTransform(editor, this.model.user, 'PerspectiveGridTool:createLayer');
      const activeLayer = this.model.user.activeLayer;
      const layerIndex = activeLayer === undefined ? this.editor.drawing.layers.length - 1 : this.editor.drawing.layers.indexOf(activeLayer);

      let layersNumber = 0;
      for (let i = 0; i < this.editor.drawing.layers.length; i++) {
        if (isPerspectiveGridLayer(this.editor.drawing.layers[i])) layersNumber++;
      }
      const layerData: LayerData = {
        id,
        name: `Perspective Grid #${layersNumber + 1}`,
        opacity: DEFAULT_OPACITY,
        perspectiveGrid: clonePerspectiveGridData(this.dataUnassigned)
      };
      const layer = layerFromState(layerData) as PerspectiveGridLayer;
      layer.owner = this.model.user;
      user.history.pushAddLayer(layerData, layerIndex);
      addLayerToDrawing(this.editor, layer, layerIndex);
      sendLayerOrder(this.editor);

      const perspectiveGridData: IPerspectiveGridData = {
        id: ToolId.PerspectiveGrid,
        layerIndex,
        layerData,
        action: PerspectiveGridRemoteAction.LayerCreated,
      };
      this.model.doTool(layer.id, perspectiveGridData);

      selectLayer(editor, layer);
    }).catch((e) => {
      editor.errorReporter.reportError(e);
    }).finally(() => {
      unlockEditor(editor);
      finished();
    });
    lockEditor(editor, 'perspective layer creation');
    this.editor.track?.event(Analytics.PerspectiveGridLayerCreation);
  }

  private pickBoundingBoxRegion(user: User, view: Viewport, x: number, y: number) {
    const { surface } = user;
    const bounds = this.data.bounds;
    if (isRectEmpty(bounds)) {
      return BoundingBoxRegion.Other;
    }
    documentToPerspectiveGridXY(tmpPoint, x, y, this.data);
    x = tmpPoint.x;
    y = tmpPoint.y;

    const boxSize = 15 / view.scale;
    const boxOffset = 6 / view.scale;
    const boxSizeX = boxSize / Math.abs(surface.scaleX);
    const boxSizeY = boxSize / Math.abs(surface.scaleY);
    const boxOffsetX = boxOffset / Math.abs(surface.scaleX);
    const boxOffsetY = boxOffset / Math.abs(surface.scaleY);

    const l = bounds.x;
    const r = bounds.x + bounds.w;
    const t = bounds.y;
    const b = bounds.y + bounds.h;

    const l2 = l - boxSizeX;
    const r2 = r + boxSizeX;
    const t2 = t - boxSizeY;
    const b2 = b + boxSizeY;

    const l1 = l + boxOffsetX;
    const r1 = r - boxOffsetX;
    const t1 = t + boxOffsetY;
    const b1 = b - boxOffsetY;

    if (pointInsideRegion(x, y, l2, t2, l1, t2, l1, t1, l2, t1, undefined)) return BoundingBoxRegion.TopLeft;
    if (pointInsideRegion(x, y, r1, t2, r2, t2, r2, t1, r1, t1, undefined)) return BoundingBoxRegion.TopRight;
    if (pointInsideRegion(x, y, l2, b1, l1, b1, l1, b2, l2, b2, undefined)) return BoundingBoxRegion.BottomLeft;
    if (pointInsideRegion(x, y, r1, b1, r2, b1, r2, b2, r1, b2, undefined)) return BoundingBoxRegion.BottomRight;
    if (pointInsideRegion(x, y, r1, b1, r2, b1, r2, b2, r1, b2, undefined)) return BoundingBoxRegion.BottomRight;

    if (pointInsideRegion(x, y, l1, t2, r1, t2, r1, t1, l1, t1, undefined)) return BoundingBoxRegion.Edge;
    if (pointInsideRegion(x, y, r1, t1, r2, t1, r2, b1, r1, b1, undefined)) return BoundingBoxRegion.Edge;
    if (pointInsideRegion(x, y, l1, b1, r1, b1, r1, b2, l1, b2, undefined)) return BoundingBoxRegion.Edge;
    if (pointInsideRegion(x, y, l1, t2, l1, t1, l1, b1, l2, b1, undefined)) return BoundingBoxRegion.Edge;
    return BoundingBoxRegion.Other;
  }

  private doTool() {
    if (this.layer === undefined) {
      throw new Error('[PerspectiveGridTool.doTool] Missing layer.');
    }
    const layerData: LayerData = {
      id: this.layer.id,
      perspectiveGrid: clonePerspectiveGridData(this.data)
    };
    const perspectiveGridData: IPerspectiveGridData = {
      id: ToolId.PerspectiveGrid,
      layerData: layerData,
      action: PerspectiveGridRemoteAction.LayerUpdated,
    };
    this.model.doTool(this.layer.id, perspectiveGridData);
  }
}

function applyDefaultData(data: PerspectiveGridLayerData) {
  data.linesNumber = DEFAULT_LINES_NUMER;
  data.thickness = DEFAULT_THICKNESS;
  data.depthFade = DEFAULT_DEPTH_FADE;
  setRect(data.bounds, 0, 0, 0, 0);
  identityMat2d(tmpMat);
  copyMat2d(data.transform, tmpMat);
}

function setBoundingBoxToDrawing(data: PerspectiveGridLayerData, drawing: Drawing) {
  data.bounds.x = drawing.x;
  data.bounds.y = drawing.y;
  data.bounds.w = drawing.w;
  data.bounds.h = drawing.h;
}

function calculateVectorMagnitude(x: number, y: number): number {
  return Math.sqrt(x * x + y * y);
}

function calculateDistance(x1: number, y1: number, x2: number, y2: number): number {
  return Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2));
}

export function calculatePerspectiveGridData(view: Viewport, drawing: Drawing, data: PerspectiveGridLayerData) {
  if (data.vpointList.length != data.vpointScreenList.length) {
    data.vpointScreenList = cloneDeep(data.vpointList);
  } else {
    for (let i = 0; i < data.vpointList.length; i++) {
      data.vpointScreenList[i].x = data.vpointList[i].x;
      data.vpointScreenList[i].y = data.vpointList[i].y;
    }
  }
  const transform = tmpMat;
  copyMat2d(transform, data.transform);
  for (let i = 0; i < data.vpointScreenList.length; i++) {
    tmpVec[0] = data.vpointScreenList[i].x;
    tmpVec[1] = data.vpointScreenList[i].y;
    transformVec2ByMat2d(tmpVec, tmpVec, transform);
    data.vpointScreenList[i].x = tmpVec[0];
    data.vpointScreenList[i].y = tmpVec[1];
    data.vpointScreenList[i].x -= drawing.x;
    data.vpointScreenList[i].y -= drawing.y;
  }
  documentToScreenPoints(data.vpointScreenList, view);
  if (data.vpointList.length == 1) {
    const transformAngle = decomposeMat2d(transform).rotate;
    if (!view.flipped) {
      data.horizonAngle = view.rotation - transformAngle;
    } else {
      data.horizonAngle = view.rotation + transformAngle;
    }
    data.horizonDir.x = Math.cos(data.horizonAngle);
    data.horizonDir.y = -Math.sin(data.horizonAngle);
  } else if (data.vpointList.length >= 2) {
    let horizonDirX = data.vpointScreenList[0].x - data.vpointScreenList[1].x;
    let horizonDirY = data.vpointScreenList[0].y - data.vpointScreenList[1].y;
    const horizonDirMagn = calculateVectorMagnitude(horizonDirX, horizonDirY);
    if (horizonDirMagn < EPS && horizonDirMagn > -EPS) {
      data.horizonAngle = view.rotation;
      data.horizonDir.x = Math.cos(data.horizonAngle);
      data.horizonDir.y = -Math.sin(data.horizonAngle);
    } else {
      horizonDirX /= horizonDirMagn;
      horizonDirY /= horizonDirMagn;
      if (horizonDirY < 0) {
        data.horizonAngle = Math.acos(horizonDirX);
      } else {
        data.horizonAngle = 2 * Math.PI - Math.acos(horizonDirX);
      }
      data.horizonDir.x = horizonDirX;
      data.horizonDir.y = horizonDirY;
    }
  }
}

function drawCrop(context: CanvasRenderingContext2D, view: Viewport, data: PerspectiveGridLayerData, drawing: Drawing) {
  let bounds = tmpBounds;
  rectToPoints(bounds, data.bounds);
  for (let i = 0; i < 4; i++) {
    perspectiveGridToDocument(bounds[i], data);
    bounds[i].x -= drawing.x;
    bounds[i].y -= drawing.y;
  }
  documentToScreenPoints(bounds, view);
  context.beginPath();
  context.moveTo(bounds[0].x, bounds[0].y);
  context.lineTo(bounds[1].x, bounds[1].y);
  context.lineTo(bounds[2].x, bounds[2].y);
  context.lineTo(bounds[3].x, bounds[3].y);
  context.closePath();
  context.clip();
  setRect(tmpRect, 0, 0, drawing.w, drawing.h);
  rectToPoints(bounds, tmpRect);
  documentToScreenPoints(bounds, view);
  context.beginPath();
  context.moveTo(bounds[0].x, bounds[0].y);
  context.lineTo(bounds[1].x, bounds[1].y);
  context.lineTo(bounds[2].x, bounds[2].y);
  context.lineTo(bounds[3].x, bounds[3].y);
  context.closePath();
  context.clip();
}

function drawHorizonLine(context: CanvasRenderingContext2D, layer: PerspectiveGridLayer, selected: boolean) {
  const data = layer.perspectiveGrid;
  let horizonOriginX = 0.0;
  let horizonOriginY = 0.0;
  if (data.vpointScreenList.length == 1) {
    horizonOriginX = data.vpointScreenList[0].x;
    horizonOriginY = data.vpointScreenList[0].y;
  } else if (data.vpointScreenList.length >= 2) {
    horizonOriginX = (data.vpointScreenList[0].x + data.vpointScreenList[1].x) / 2;
    horizonOriginY = (data.vpointScreenList[0].y + data.vpointScreenList[1].y) / 2;
  }
  let r = 0;
  r = Math.max(calculateDistance(horizonOriginX, horizonOriginY, 0, 0), r);
  r = Math.max(calculateDistance(horizonOriginX, horizonOriginY, context.canvas.width, 0), r);
  r = Math.max(calculateDistance(horizonOriginX, horizonOriginY, context.canvas.width, context.canvas.height), r);
  r = Math.max(calculateDistance(horizonOriginX, horizonOriginY, 0, context.canvas.height), r);
  context.strokeStyle = selected ? PERSPECTIVE_GRID_COLOR_STR_SELECTED : PERSPECTIVE_GRID_COLOR_STR_NORMAL;
  context.globalAlpha = selected ? 1.0 : layer.opacity;
  context.lineWidth = data.thickness;
  context.beginPath();
  context.moveTo(
    Math.floor(horizonOriginX - data.horizonDir.x * r),
    Math.floor(horizonOriginY - data.horizonDir.y * r));
  context.lineTo(
    Math.floor(horizonOriginX + data.horizonDir.x * r),
    Math.floor(horizonOriginY + data.horizonDir.y * r));
  context.stroke();
  context.lineWidth = 1;
  context.globalAlpha = 1;
}

function drawVanishingPointHandles(context: CanvasRenderingContext2D, layer: PerspectiveGridLayer, selected: boolean, tool?: PerspectiveGridTool) {
  const data = layer.perspectiveGrid;
  for (let i = 0; i < data.vpointScreenList.length; i++) {
    let lineWidth = PERSPECTIVE_GRID_HANDLE_LINE_WIDTH_LIGHT;
    if (tool !== undefined) {
      const state = tool.getVanishingPointState(data.vpointList[i]);
      if (state === PerspectiveGridVanishingPointState.Hover) {
        lineWidth = PERSPECTIVE_GRID_HANDLE_LINE_WIDTH_MEDIUM;
      } else if (state === PerspectiveGridVanishingPointState.Pressed) {
        lineWidth = PERSPECTIVE_GRID_HANDLE_LINE_WIDTH_BOLD;
      }
    }
    const radius = PERSPECTIVE_GRID_HANDLE_RADIUS - (lineWidth - PERSPECTIVE_GRID_HANDLE_LINE_WIDTH_LIGHT) / 2.0;
    context.strokeStyle = selected ? PERSPECTIVE_GRID_COLOR_STR_SELECTED : PERSPECTIVE_GRID_COLOR_STR_NORMAL;
    context.globalAlpha = selected ? 1.0 : layer.opacity;
    context.lineWidth = lineWidth;
    context.beginPath();
    context.arc(
      Math.floor(data.vpointScreenList[i].x),
      Math.floor(data.vpointScreenList[i].y),
      radius, 0, 2 * Math.PI);
    context.stroke();
  }
  context.lineWidth = 1;
  context.globalAlpha = 1;
}

function drawVanishingPointLines(context: CanvasRenderingContext2D, view: Viewport, drawing: Drawing, vpoint: Point, layer: PerspectiveGridLayer, stretch: boolean) {
  const data = layer.perspectiveGrid;
  const depthFadeCoeff = data.depthFade;
  const linesNumber = data.linesNumber;
  let lineDirX = 0.0;
  let lineDirY = 0.0;
  let lineDirRotX = 0.0;
  let lineDirRotY = 0.0;
  copyPoint(tmpPoint, vpoint);
  perspectiveGridToDocument(tmpPoint, data);
  tmpPoint.x -= drawing.x;
  tmpPoint.y -= drawing.y;
  documentToScreenPoint(tmpPoint, view);
  const vpointScreenX = tmpPoint.x;
  const vpointScreenY = tmpPoint.y;
  const color = PERSPECTIVE_GRID_COLOR_STR_NORMAL_SEMI;
  let r = 0;
  r = Math.max(calculateDistance(vpointScreenX, vpointScreenY, 0, 0), r);
  r = Math.max(calculateDistance(vpointScreenX, vpointScreenY, context.canvas.width, 0), r);
  r = Math.max(calculateDistance(vpointScreenX, vpointScreenY, context.canvas.width, context.canvas.height), r);
  r = Math.max(calculateDistance(vpointScreenX, vpointScreenY, 0, context.canvas.height), r);
  if (depthFadeCoeff > 0) {
    const depthFade = depthFadeCoeff * 400;
    const grad = context.createLinearGradient(
      vpointScreenX + data.horizonDir.y * depthFade,
      vpointScreenY - data.horizonDir.x * depthFade,
      vpointScreenX - data.horizonDir.y * depthFade,
      vpointScreenY + data.horizonDir.x * depthFade);
    grad.addColorStop(0.0, color);
    grad.addColorStop(0.5, 'transparent');
    grad.addColorStop(1.0, color);
    context.strokeStyle = grad;
  } else {
    context.strokeStyle = color;
  }
  context.globalAlpha = layer.opacity;
  context.lineWidth = data.thickness;
  const horizonCos = Math.cos(data.horizonAngle);
  const horizonSin = Math.sin(data.horizonAngle);
  for (let i = 0; i < linesNumber; i++) {
    lineDirX = Math.sin(i * 2 * Math.PI / linesNumber) * r * (stretch ? PERSPECTIVE_GRID_PLANE_GEOMETRY_STRETCH : 1);
    lineDirY = Math.cos(i * 2 * Math.PI / linesNumber) * r;
    lineDirRotX = lineDirX * horizonCos + lineDirY * horizonSin;
    lineDirRotY = -lineDirX * horizonSin + lineDirY * horizonCos;
    context.beginPath();
    context.moveTo(
      Math.floor(vpointScreenX),
      Math.floor(vpointScreenY));
    context.lineTo(
      Math.floor(vpointScreenX + lineDirRotX),
      Math.floor(vpointScreenY + lineDirRotY));
    context.stroke();
  }
  context.lineWidth = 1;
  context.globalAlpha = 1;
}

export function drawPerspectiveGrid(context: CanvasRenderingContext2D, view: Viewport, drawing: Drawing, settings: RendererSettings, layer: PerspectiveGridLayer, selected: boolean, tool?: PerspectiveGridTool) {
  const data = layer.perspectiveGrid;
  calculatePerspectiveGridData(view, drawing, data);
  const pixelRatio = getPixelRatio();
  context.save();
  context.scale(pixelRatio, pixelRatio);
  drawCrop(context, view, data, drawing);
  if (settings.showPerspectiveGridLines) {
    for (let i = 0; i < data.vpointList.length; i++) {
      drawVanishingPointLines(context, view, drawing, data.vpointList[i], layer, i < 2);
    }
  }
  context.restore();
  context.save();
  context.scale(pixelRatio, pixelRatio);
  if (settings.showPerspectiveGridHorizonLines) {
    drawHorizonLine(context, layer, selected);
  }
  if (settings.showPerspectiveGridVanishingPoints) {
    drawVanishingPointHandles(context, layer, selected, tool);
  }
  context.restore();
}

export function createPerspectiveGridData(): PerspectiveGridLayerData {
  return {
    vpointList: [],
    vpointScreenList: [],
    vpointListStored: [],
    horizonDir: createPoint(0, 0),
    horizonAngle: 0,
    linesNumber: 0,
    thickness: 0,
    depthFade: 0,
    bounds: createRect(0, 0, 0, 0),
    transform: [1, 0, 0, 1, 0, 0]
  };
}

function cloneInt(value: number) {
  return value | 0;
}

function cloneFloat(value: number) {
  return Number.isFinite(value) ? value : 0;
}

function clonePointSafe(point: Point) {
  if (point && typeof point === 'object') {
    return createPoint(cloneFloat(point.x), cloneFloat(point.y));
  } else {
    return createPoint(0, 0);
  }
}

function cloneArrayOfPoints(array: unknown): Point[] {
  return Array.isArray(array) ? array.map(clonePointSafe) : [];
}

function cloneRectSafe(rect: Rect) {
  if (rect && typeof rect === 'object') {
    return createRect(cloneFloat(rect.x), cloneFloat(rect.y), cloneFloat(rect.w), cloneFloat(rect.h));
  } else {
    return createRect(0, 0, 0, 0);
  }
}

export function clonePerspectiveGridData(data: PerspectiveGridLayerData): PerspectiveGridLayerData {
  const { vpointList, vpointScreenList, vpointListStored, horizonDir, horizonAngle, linesNumber, thickness, depthFade, bounds, transform } = data;

  const clonedVpointListStored: PerspectiveGridStored[] = [];
  if (Array.isArray(vpointListStored)) {
    for (const stored of vpointListStored) {
      const { name, vpointList } = stored;
      if (typeof name !== 'string') {
        throw new Error('[PerspectiveGridTool.clonePerspectiveGridData] Invalid value.');
      }
      clonedVpointListStored.push({ name, vpointList: cloneArrayOfPoints(vpointList) });
    }
  }

  const clonedTransform = [1, 0, 0, 1, 0, 0];
  if (transform && Array.isArray(transform) && transform.length === 6 && transform.every(v => Number.isFinite(v))) {
    copyMat2d(clonedTransform, transform);
  }

  return {
    vpointList: cloneArrayOfPoints(vpointList),
    vpointScreenList: cloneArrayOfPoints(vpointScreenList),
    vpointListStored: clonedVpointListStored,
    horizonDir: clonePointSafe(horizonDir),
    horizonAngle: cloneFloat(horizonAngle),
    linesNumber: cloneInt(linesNumber),
    thickness: cloneFloat(thickness),
    depthFade: cloneFloat(depthFade),
    bounds: cloneRectSafe(bounds),
    transform: clonedTransform,
  };
}

export function setPerspectiveGridLayerTransform(layer: PerspectiveGridLayer, surface: ToolSurface) {
  const { translateX, translateY, rotate, scaleX, scaleY } = surface;
  const tx = safeFloatAny(translateX);
  const ty = safeFloatAny(translateY);
  const r = safeFloatAny(rotate);
  const sx = safeFloatAny(scaleX);
  const sy = safeFloatAny(scaleY);
  createTransform(tmpMat, tx, ty, r, sx, sy);
  copyMat2d(layer.perspectiveGrid.transform, tmpMat);
}

export function perspectiveGridToDocument(point: Point, data: PerspectiveGridLayerData) {
  setVec2(tmpVec, point.x, point.y);
  copyMat2d(tmpMat, data.transform);
  transformVec2ByMat2d(tmpVec, tmpVec, tmpMat);
  point.x = tmpVec[0];
  point.y = tmpVec[1];
}

export function documentToPerspectiveGrid(point: Point, data: PerspectiveGridLayerData) {
  setVec2(tmpVec, point.x, point.y);
  copyMat2d(tmpMat2, data.transform);
  invertMat2d(tmpMat, tmpMat2);
  transformVec2ByMat2d(tmpVec, tmpVec, tmpMat);
  point.x = tmpVec[0];
  point.y = tmpVec[1];
}

export function documentToPerspectiveGridXY(point: Point, x: number, y: number, data: PerspectiveGridLayerData) {
  point.x = x;
  point.y = y;
  documentToPerspectiveGrid(point, data);
}
