import { Editor } from './editor';
import { Cursor, CursorType, Rect } from '../common/interfaces';
import { isWindows } from '../common/userAgentUtils';
import { hasPressure } from './tablet';

export function createCursor(customCursor?: Partial<Cursor>): Cursor {
  return {
    x: customCursor?.x? customCursor.x : -1000,
    y: customCursor?.y? customCursor.y : -1000,
    size: customCursor?.size? customCursor.size : 0,
    type: customCursor?.type? customCursor.type : CursorType.None,
    show: true,
    lastX: 0,
    lastY: 0,
    lastType: CursorType.None,
    lastSize: 0,
    lastVisible: true,
    lastShow: true,
    useSynthetic: false,
    additionalAngle: 0,
  };
}

export function resetCursor(cursor: Cursor) {
  cursor.type = CursorType.None;
  cursor.size = 0;
}

export function moveCursor(cursor: Cursor, x: number, y: number) {
  cursor.x = x;
  cursor.y = y;
}

export function useSyntheticCursor(editor: Editor) {
  return isWindows && editor.tabletConfig.api === 'pen' && hasPressure && !editor.settings.noSyntheticCursor;
}

export function isCanvasOnlyCursor(cursor: CursorType) {
  return cursor === CursorType.Circle || cursor === CursorType.Square || cursor === CursorType.Image;
}

export function updateCursor(cursor: Cursor, editor: Editor, dirtyRect: Rect, useSynthetic: boolean, loaded: boolean) {
  let visible = false;
  let changed = false;
  let minX = Math.min(cursor.x, cursor.lastX);
  let maxX = Math.max(cursor.x, cursor.lastX);
  let minY = Math.min(cursor.y, cursor.lastY);
  let maxY = Math.max(cursor.y, cursor.lastY);
  let maxSize = cursor.size;
  const tool = loaded ? editor.activeTool : undefined;
  const type = tool?.cursor ?? CursorType.Default;
  const canvasOnlyCursor = isCanvasOnlyCursor(type);

  if (tool && type && !editor.movingView && (canvasOnlyCursor || useSynthetic)) {
    const newCursorSize = canvasOnlyCursor ? (tool.size! * editor.view.scale) : 100; // fallback size for fallback cursors to erase whole cursor

    if (cursor.size !== newCursorSize || cursor.lastType !== type) {
      cursor.size = newCursorSize;
      cursor.type = type;
      cursor.lastType = type;
      changed = true;
    }

    visible = true;

    if (cursor.lastX !== cursor.x || cursor.lastY !== cursor.y || cursor.lastSize !== cursor.size) {
      cursor.lastX = cursor.x;
      cursor.lastY = cursor.y;
      cursor.lastSize = cursor.size;
      changed = true;
    }
  } else {
    cursor.type = CursorType.None;
    cursor.lastType = CursorType.None;
  }

  maxSize = Math.max(maxSize, cursor.size);

  if (cursor.useSynthetic || useSynthetic) {
    maxSize = Math.max(maxSize, 100);
  }

  const halfMaxSize = Math.max(Math.ceil(maxSize / 2), 10) + 2;
  const left = Math.floor(minX - halfMaxSize);
  const right = Math.ceil(maxX + halfMaxSize);
  const top = Math.floor(minY - halfMaxSize);
  const bottom = Math.ceil(maxY + halfMaxSize);

  dirtyRect.x = left;
  dirtyRect.y = top;
  dirtyRect.w = right - left;
  dirtyRect.h = bottom - top;

  if (cursor.lastVisible !== visible || cursor.lastShow !== cursor.show || cursor.useSynthetic !== useSynthetic) {
    cursor.lastVisible = visible;
    cursor.lastShow = cursor.show;
    cursor.useSynthetic = useSynthetic;
    changed = true;
  }

  return changed;
}
