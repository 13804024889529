import { Component, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { truncate } from 'lodash';
import { ToastService } from '../../../../services/toast.service';
import { TextSelectionDirection } from '../../../../common/text/navigation';

interface PromptModalValidator {
  (value: string): boolean;
  errorMessage: string;
}

export interface PromptModalOptions {
  yes?: string;
  no?: string;
  header: string;
  value?: string;
  isLarge?: boolean;
  label?: string;
  maxLength?: number;
  required?: boolean;
  validators?: PromptModalValidator[];
  selectAll?: boolean;
}

@Component({
  selector: 'prompt-modal',
  templateUrl: 'prompt-modal.pug',
})
export class PromptModal {
  @Output() close = new EventEmitter<string>();
  header = '';
  value = '';
  label = '';
  maxLength = 64;
  isLarge = false;
  required = false;
  validators: PromptModalValidator[] = [];
  yes = 'OK';
  no = 'Cancel';
  selectAll = false;

  @ViewChild('inputEl') inputElement!: ElementRef<HTMLInputElement>;
  @ViewChild('textareaEl') textareaElement!: ElementRef<HTMLTextAreaElement>;

  get formControlElement() {
    return this.isLarge ? this.textareaElement : this.inputElement;
  }

  @Input() set data(value: PromptModalOptions) {
    this.header = value.header;
    this.isLarge = value.isLarge ?? false;
    this.value = value.value ?? '';
    this.maxLength = value.maxLength ?? 64;
    this.label = value.label ?? '';
    this.required = value.required ?? false;
    this.validators = value.validators ?? [];
    this.yes = value.yes ?? 'OK';
    this.no = value.no ?? 'Cancel';
    this.selectAll = !!value.selectAll;
  }

  ngAfterViewInit() {
    this.formControlElement.nativeElement.setSelectionRange(0, this.value.length, TextSelectionDirection.Forward);
  }

  constructor(private toasts: ToastService) { }

  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    event.preventDefault();
    event.stopPropagation();
    if (input.value.length > this.maxLength) {
      input.value = truncate(input.value, { length: this.maxLength, omission: '' });
    }
    this.value = input.value;
  }
  ok() {
    for (const validator of this.validators) {
      if (!validator(this.value)) {
        this.toasts.error({ message: validator.errorMessage });
        return;
      }
    }
    this.close.emit(this.value);
  }
  cancel() {
    this.header = '';
    this.isLarge = false;
    this.value = '';
    this.maxLength = 64;
    this.label = '';
    this.close.emit(undefined);
  }
}
