import { Injectable } from '@angular/core';
import { PublisherId } from '../common/interfaces';
import { Editor } from './editor';

@Injectable()
export class PublishersService {
  editor?: Editor;
  linkAccount(_id: PublisherId): Promise<void> { return Promise.resolve(); }
  remixRevision(_shortId: string, _artworkName: string | undefined) { return Promise.resolve(); }
  publishRevision(_editor: Editor, _providerId: PublisherId, _data: any) { return Promise.resolve(); }
  async getMyRegisteredIps(): Promise<any[]> { return Promise.resolve([]); }
  getEntity(_id: string): Promise<any> { return Promise.resolve(undefined); }
  updateUserPublicationStatus(_publisherId: PublisherId, _revisionId: string, _status: number): Promise<any> { return Promise.resolve(undefined); }
  cancelPublication(_publisherId: PublisherId, _revisionId: string): Promise<any> { return Promise.resolve(undefined); }
}
