import { Font, parse } from 'opentype.js';
import { FontStyle } from './font-style';

export class FontFamily {
  readonly name: string;

  static async create(name: string, fonts: Partial<Record<FontStyleNames, string>>, get: (url: string) => Promise<ArrayBuffer>): Promise<FontFamily> {
    const fontFamily = new FontFamily(name);

    await Promise.all(
      Object.entries(fonts).map(([ffName, fsUrl]) => {
        return get(fsUrl).then((buffer) => {
          const font = parse(buffer);
          if (font.supported) {
            fontFamily.setFontStyle(ffName as unknown as FontStyleNames, font);
          } else {
            throw new Error('Provided font is not supported. Is provided ArrayBuffer really .ttf/.otf format?');
          }
        });
      })
    );

    return fontFamily;
  }

  private constructor(name: string) {
    this.name = name;
  }

  readonly styles: Map<Partial<FontStyleNames>, FontStyle> = new Map<Partial<FontStyleNames>, FontStyle>();

  private setFontStyle(styleName: FontStyleNames, fontStyle: Font) {
    const styleInternalName = `${this.name} - ${styleName}`;
    this.styles.set(styleName, new FontStyle(fontStyle, styleInternalName));
  }
}

// Object holding key-value pairs of some font-family identifier like:
// "Times New Roman" and their FontFamily containing all the necessary FontStyles.
export type FontFamilies = Map<string, FontFamily>;

// Defines font variants inside one font family
export enum FontStyleNames {
  Regular = 'regular',
  Bold = 'bold',
  Italic = 'italic',
  BoldItalic = 'bold-italic',

  Light = 'light',
  Medium = 'medium',
  SemiBold = 'semi-bold'
}

export function isBold(fontStyleName: FontStyleNames | '') {
  return fontStyleName === FontStyleNames.Bold || fontStyleName === FontStyleNames.BoldItalic;
}

export function isItalic(fontStyleName: FontStyleNames | '') {
  return fontStyleName === FontStyleNames.Italic || fontStyleName === FontStyleNames.BoldItalic;
}

export async function loadFontFamilies(
  fontFamilies: Record<string, Partial<Record<FontStyleNames, string>>>,
  get: (url: string) => Promise<ArrayBuffer>,
  map: FontFamilies = new Map<string, FontFamily>()
): Promise<FontFamilies> {
  const promises = Object.entries(fontFamilies)
    .map(([name, styles]) => FontFamily.create(name, styles, get));

  const fonts = await Promise.all(promises);
  fonts.forEach(font => map.set(font.name, font));

  return map;
}

export function fontStyleNameToWeight(name: FontStyleNames): number {
  // According to: https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping
  switch (name) {
    case FontStyleNames.Light: return 300;
    case FontStyleNames.Regular: return 400;
    case FontStyleNames.Italic: return 401; // used to sort after regular
    case FontStyleNames.Medium: return 500;
    case FontStyleNames.SemiBold: return 600;
    case FontStyleNames.Bold: return 700;
    case FontStyleNames.BoldItalic: return 701; // used to sort after bold
  }
}
