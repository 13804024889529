import { Glyph } from 'opentype.js';
import { FontStyle } from './font-style';
import { CharacterFormatting, DEFAULT_CHARACTER_FORMATTING, InCharacterFormatting, TextCases, TextDecorationData } from './formatting';
import { Rect } from '../interfaces';
import { PARAGRAPH_SPLIT_CHARACTER } from './textarea';
import { BLACK, colorToCSS } from '../color';
import { createRect, isRectEmpty } from '../rect';

export interface TextCharacterCreationMetadata {
  isFirstLetterOfWord: boolean;
  isFirstLetterOfSentence: boolean;
}

export enum GlyphScenario {
  Regular = 'regular',
  UpperCase = 'upper-case',
  LowerCase = 'lower-case',
  DisplayingNonPrintableCharacters = 'displaying-non-printable-characters',
}

export const isWhitespaceTextCharacter = (c: string) => {
  return !!c.match(/\s/);
};

export type TextCharacterWithMeasuredBbox = TextCharacter & { bbox: Rect };
export function hasMeasuredBbox(character: TextCharacter): character is TextCharacterWithMeasuredBbox {
  return character.bbox !== undefined && !isRectEmpty(character.bbox);
}
export function saveBbox(character: TextCharacter, x: number, y: number, w: number, h: number): asserts character is TextCharacterWithMeasuredBbox {
  if (w < 0) {
    x -= w;
    w = Math.abs(w);
  }
  const wSafe = Math.max(w, 0, character.regularGlyphWidth);
  const hSafe = Math.max(h, 0);
  character.bbox = createRect(x, y, wSafe, hSafe);
}

export const SPACE = ' ';
export const ZERO_WIDTH_SPACE = '​';
export const ZERO_WIDTH_JOINER = '\u200d';
const PILCROW = '¶';
const FLOATING_DOT = '·';
const TAB = '\t';
const ARROW_TO_BAR = '→';

export class TextCharacter {
  glyphKey: string;
  index: number;
  fontStyle: FontStyle;

  glyph: Glyph;
  glyphScenarios = new Map<GlyphScenario, Glyph>();
  currentGlyphScenario: GlyphScenario = GlyphScenario.Regular;

  formatting: InCharacterFormatting;
  bbox?: Rect;
  partiallyInsideVisibleBox = true; // bbox partially intersects with textarea.rect (makes character renderable/highlightable)
  renderable = true; // bbox fully in textarea rect and line was rendered

  metadata: TextCharacterCreationMetadata;

  constructor(
    glyphKey: string,
    index: number,
    fontStyle: FontStyle,
    formatting: CharacterFormatting,
    metadata: TextCharacterCreationMetadata
  ) {
    this.glyphKey = glyphKey;
    this.index = index;
    this.fontStyle = fontStyle;
    this.metadata = metadata;
    this.formatting = {
      ...DEFAULT_CHARACTER_FORMATTING,
      ...formatting,
    };

    this.loadGlyphScenarios();
    this.glyph = this.getGlyphAtScenario(GlyphScenario.Regular)!;
    this.applyTextCaseFormatting();
  }

  get regularGlyph() {
    if (this.currentGlyphScenario === GlyphScenario.DisplayingNonPrintableCharacters) {
      return this.glyphScenarios.get(GlyphScenario.Regular)!;
    }
    return this.glyph;
  }

  private applyTextCaseFormatting() {
    switch (this.formatting.textCase) {
      case TextCases.LowerCase: {
        this.setGlyphScenario(GlyphScenario.LowerCase);
        break;
      }
      case TextCases.UpperCase: {
        this.setGlyphScenario(GlyphScenario.UpperCase);
        break;
      }
      case TextCases.UpperCasePerSentence: {
        if (this.metadata.isFirstLetterOfSentence) {
          this.setGlyphScenario(GlyphScenario.UpperCase);
        }
        break;
      }
      case TextCases.UpperCasePerWord: {
        if (this.metadata.isFirstLetterOfWord) {
          this.setGlyphScenario(GlyphScenario.UpperCase);
        }
        break;
      }
    }
  }

  setGlyphScenario(scenario: GlyphScenario) {
    if (this.glyphAtScenarioAvailable(scenario)) {
      this.glyph = this.getGlyphAtScenario(scenario);
      this.currentGlyphScenario = scenario;
    }
  }

  private loadGlyphScenarios() {
    if (this.glyphKey === PARAGRAPH_SPLIT_CHARACTER) {
      this.loadGlyphScenario(GlyphScenario.Regular, SPACE);
      this.loadGlyphScenario(GlyphScenario.DisplayingNonPrintableCharacters, PILCROW);
    } else if (this.glyphKey === SPACE) {
      this.loadGlyphScenario(GlyphScenario.Regular, SPACE);
      this.loadGlyphScenario(GlyphScenario.DisplayingNonPrintableCharacters, FLOATING_DOT);
    } else if (this.glyphKey === TAB) {
      this.loadGlyphScenario(GlyphScenario.Regular, SPACE);
      this.loadGlyphScenario(GlyphScenario.DisplayingNonPrintableCharacters, ARROW_TO_BAR);
    } else {
      this.loadGlyphScenario(GlyphScenario.Regular, this.glyphKey);
      this.loadGlyphScenario(GlyphScenario.LowerCase, this.glyphKey.toLowerCase());
      this.loadGlyphScenario(GlyphScenario.UpperCase, this.glyphKey.toUpperCase());
    }
  }

  private loadGlyphScenario(scenario: GlyphScenario, char: string) {
    this.glyphScenarios.set(scenario, this.fontStyle.getGlyph(char));
  }

  private glyphAtScenarioAvailable(scenario: GlyphScenario): boolean {
    return this.glyphScenarios.has(scenario);
  }

  private getGlyphAtScenario(scenario: GlyphScenario): Glyph {
    return this.glyphScenarios.get(scenario) ?? this.glyph ?? this.regularGlyph;
  }

  get size() {
    return this.formatting.size;
  }

  get isWhitespace(): boolean {
    return isWhitespaceTextCharacter(this.glyphKey);
  }

  get isEOParagraph(): boolean {
    return this.glyphKey === PARAGRAPH_SPLIT_CHARACTER;
  }

  get isZeroWidthSpace(): boolean {
    return this.glyphKey === ZERO_WIDTH_SPACE;
  }

  private getSomeGlyphWidth(glyph: Glyph) {
    const baseWidth = this.fontStyle.toPixels(glyph.advanceWidth, this.size);
    const multiplier = this.getCharacterWidthMultiplier();
    return baseWidth * multiplier * (this.formatting?.scaleX || 1);
  }

  get glyphWidth(): number {
    return this.getSomeGlyphWidth(this.glyph);
  }

  get glyphWidthNoMultiplier(): number {
    return this.glyphWidth / this.getCharacterWidthMultiplier();
  }

  get regularGlyphWidth(): number {
    return this.getSomeGlyphWidth(this.regularGlyph);
  }

  private getCharacterWidthMultiplier(): number {
    switch (this.glyphKey) {
      case PARAGRAPH_SPLIT_CHARACTER:
        return 2;
      case TAB:
        return 4;
      default:
        return 1;
    }
  }

  get lineHeight(): number {
    return this.formatting.lineheight || this.fontStyle.lineheight(this.size);
  }

  get letterSpacingInPx(): number {
    return this.fontStyle.toPixels(this.formatting.letterSpacing || 0, this.size);
  }

  get endsSentence(): boolean {
    return ['.', '?', '!', ';'].includes(this.glyphKey);
  }

  get indexGlyphKeyString(): string {
    return `(${JSON.stringify(this.glyphKey)} / ${this.index})`;
  }

  get canExceedTextareaBoundries(): boolean {
    return this.isEOParagraph;
  }

  get hasDecorations(): boolean {
    return this.formatting.underline !== undefined || this.formatting.strikethrough !== undefined;
  }

  get underline(): TextDecorationData | undefined {
    if (!this.formatting.underline) return undefined;

    const underline = this.formatting.underline;
    let shiftFromBaseline = this.fontStyle.getUnderlinePosition(this.size);
    let thickness = this.fontStyle.getUnderlineThickness(this.size);
    let color = this.formatting?.color || this.formatting?.outline?.color || colorToCSS(BLACK);

    if (underline !== true) {
      thickness = underline.width || thickness;
      color = underline.color || color;
    }

    const baselineShift = this.formatting.baselineShift ? this.formatting.baselineShift : 0;
    const verticalScaling = this.formatting.scaleY ? this.formatting.scaleY : 1;
    shiftFromBaseline = -(shiftFromBaseline * verticalScaling) - baselineShift;
    thickness = thickness * verticalScaling;

    return { color, thickness, shiftFromBaseline };
  }

  get strikethrough(): TextDecorationData | undefined {
    if (!this.formatting.strikethrough) return undefined;
    const strikethrough = this.formatting.strikethrough;

    let shiftFromBaseline = this.fontStyle.getStrikethroughPosition(this.size);
    let thickness = this.fontStyle.getStrikethroughThickness(this.size);
    let color = this.formatting?.color || this.formatting?.outline?.color || colorToCSS(BLACK);

    if (strikethrough !== true) {
      thickness = strikethrough.width || thickness;
      color = strikethrough.color || color;
    }

    const baselineShift = this.formatting.baselineShift ? this.formatting.baselineShift : 0;
    const verticalScaling = this.formatting.scaleY ? this.formatting.scaleY : 1;
    shiftFromBaseline = -(shiftFromBaseline * verticalScaling) - baselineShift;
    thickness = thickness * verticalScaling;

    return { color, thickness, shiftFromBaseline };
  }

}

export const charactersToWords = (characters: TextCharacter[]): TextCharacter[][] => {
  const allWords = [];
  let currentWord = [];
  for (let character of characters) {
    if (character.isWhitespace) {
      if (currentWord.length > 0) allWords.push(currentWord);
      currentWord = [];
    } else {
      currentWord.push(character);
    }
  }
  if (currentWord.length > 0) allWords.push(currentWord);
  return allWords;
};

export const charactersToWordsWithWhitespaces = (characters: TextCharacter[]): TextCharacter[][] => {
  const allWords = [];
  let currentWord = [];
  let foundWhitespace = false;
  for (const character of characters) {
    if (foundWhitespace) {
      if (!character.isWhitespace) {
        allWords.push(currentWord);
        currentWord = [];
        foundWhitespace = false;
      }
      currentWord.push(character);
    } else {
      if (character.isWhitespace) foundWhitespace = true;
      currentWord.push(character);
    }
  }
  if (currentWord.length > 0) allWords.push(currentWord);
  return allWords;
};

export const intersectZeroWidthSpace = (character: TextCharacter, rect: Rect) => {
  // weird intersectRect variant which allows 0 width
  if (character.bbox) {
    const l1 = character.bbox.x;
    const l2 = rect.x;
    const r1 = character.bbox.x + character.bbox.w;
    const r2 = rect.x + rect.w;
    const t1 = character.bbox.y;
    const t2 = rect.y;
    const b1 = character.bbox.y + character.bbox.h;
    const b2 = rect.y + rect.h;

    const x = Math.max(l1, l2);
    const y = Math.max(t1, t2);
    character.bbox.w = Math.min(r1, r2) - x;
    character.bbox.h = Math.min(b1, b2) - y;
    character.bbox.x = x;
    character.bbox.y = y;
  }
};

export const calculateKerning = (previous: TextCharacter, current: TextCharacter): number => {
  if (current.regularGlyphWidth + current.letterSpacingInPx < Number.EPSILON) return Number.EPSILON;
  let kerning = current.fontStyle.toPixels(
    current.fontStyle.font.getKerningValue(previous.regularGlyph, current.regularGlyph) || 0,
    current.size
  );
  return kerning * (current.formatting?.scaleX || 1);
};

export const calculateAdvancement = (current: TextCharacter, previous?: TextCharacter): number => {
  previous;
  const advancement = current.regularGlyphWidth;
  const tracking = current.letterSpacingInPx;
  return Math.max(advancement + tracking, Number.EPSILON);
};
