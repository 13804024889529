import { blazeIcon, fusionIcon, sparkIcon } from 'magma/common/icons';
import { ProductData, ProductPrice } from './interfaces';
import { SubscriptionPlan, SvgIconDefinition } from 'magma/common/interfaces';
import { GB, MB } from 'magma/common/constants';
import { invalidEnumReturn } from 'magma/common/baseUtils';
import { isObjectId, getValueOfVariable } from 'magma/common/utils';

const loadBillingDataFromEnv = SERVER;

export const {
  CC_PRODUCT_MAGMA_PRO,
  CC_PRODUCT_MAGMA_PRO_PRICE_M,
  CC_PRODUCT_MAGMA_PRO_PRICE_Y,

  CC_PRODUCT_MAGMA_FUSION,
  CC_PRODUCT_MAGMA_FUSION_PRICE_M,
  CC_PRODUCT_MAGMA_FUSION_PRICE_Y,

  CC_PRODUCT_EDITOR,
  CC_PRODUCT_EDITOR_PRICE_M,
  CC_PRODUCT_EDITOR_PRICE_Y,

  CC_PRODUCT_REVIEWER,
  CC_PRODUCT_REVIEWER_PRICE_M,
  CC_PRODUCT_REVIEWER_PRICE_Y,

  CC_PRODUCT_VIEWER,
  CC_PRODUCT_VIEWER_PRICE_M,
  CC_PRODUCT_VIEWER_PRICE_Y,
} = loadBillingDataFromEnv ? process.env : (getValueOfVariable('stripeData') || {});

export const TEAM_PRODUCTS = new Map<string, ProductData>([
  [CC_PRODUCT_MAGMA_PRO, {
    productId: CC_PRODUCT_MAGMA_PRO,
    name: 'Blaze',
    stripePriceId: { month: CC_PRODUCT_MAGMA_PRO_PRICE_M, year: CC_PRODUCT_MAGMA_PRO_PRICE_Y }
  }],
  [CC_PRODUCT_MAGMA_FUSION, {
    productId: CC_PRODUCT_MAGMA_FUSION,
    name: 'Fusion',
    stripePriceId: { month: CC_PRODUCT_MAGMA_FUSION_PRICE_M, year: CC_PRODUCT_MAGMA_FUSION_PRICE_Y }
  }]
]);

export const USER_PLAN_STORAGE_DIVIDER = 10;

export type SubscriptionPlanType = {
  icon: SvgIconDefinition;
  planValue: SubscriptionPlan
  class: string;
  code: string;
  monthlyCode: string;
  yearlyCode: string;
  planLimit: number;
  name: string;
  monthly: number;
  yearly: number;
  description: string;
  planContents: string[];
  // NOTE: keep in sync with LIMITS.md
  storageLimit: number;
}

export const FreePlan: SubscriptionPlanType = {
  icon: sparkIcon,
  planValue: SubscriptionPlan.Free,
  class: 'text-spark',
  code: '',
  monthlyCode: '',
  yearlyCode: '',
  planLimit: 1,
  name: 'Spark',
  monthly: 0,
  yearly: 0,
  description: 'Free plan for individual artists',
  planContents: [
    '30 users per drawing',
    '4k canvas',
    '100MB storage & 25MB import',
    '7 days of version history',
    '3 brush presets',
  ],
  storageLimit: 100 * MB,
};

export const BlazePlan: SubscriptionPlanType = {
  icon: blazeIcon,
  planValue: SubscriptionPlan.Blaze,
  class: 'text-blaze',
  code: CC_PRODUCT_MAGMA_PRO,
  monthlyCode: CC_PRODUCT_MAGMA_PRO_PRICE_M,
  yearlyCode: CC_PRODUCT_MAGMA_PRO_PRICE_Y,
  planLimit: 6,
  name: 'Blaze',
  monthly: 999,
  yearly: 9999,
  description: 'More power for artists who want better team collaboration',
  planContents: [
    '50 users per drawing',
    '8k canvas',
    'Up to 200 layers',
    '5GB & 500MB file imports',
    'Reference window',
    'Unlimited version history',
    'Premium API access',
    'Unlimited brush presets',
  ],
  storageLimit: 5 * GB,
};

export const FusionPlan: SubscriptionPlanType = {
  icon: fusionIcon,
  planValue: SubscriptionPlan.Fusion,
  class: 'text-fusion',
  code: CC_PRODUCT_MAGMA_FUSION,
  monthlyCode: CC_PRODUCT_MAGMA_FUSION_PRICE_M,
  yearlyCode: CC_PRODUCT_MAGMA_FUSION_PRICE_Y,
  planLimit: 50,
  name: 'Fusion',
  monthly: 5000,
  yearly: 50000,
  description: 'Increase studio productivity and keep your organization connected',
  planContents: [
    'Unlimited concurrent devices',
    '100GB & 2GB import',
    'Connect your own storage',
    'Unlimited API access',
    'SSO SAML',
    'Administrator panel',
    'Video calls & screen sharing',
    'Unlimited custom brushes',
  ],
  storageLimit: 100 * GB,
};

export function getSubscriptionPlanByCode(code: string = FreePlan.code): SubscriptionPlanType {
  if (code === BlazePlan.code) return BlazePlan;
  if (code === FusionPlan.code) return FusionPlan;
  return FreePlan;
}

export function getSubscriptionPlanByEnum(code: SubscriptionPlan = SubscriptionPlan.Free): SubscriptionPlanType {
  switch (code) {
    case SubscriptionPlan.Blaze: return BlazePlan;
    case SubscriptionPlan.Fusion: return FusionPlan;
    case SubscriptionPlan.Free: return FreePlan;
    default: return invalidEnumReturn(code, FreePlan);
  }
}
export function updatePlanPrices(prices: ProductPrice[]) {
  const pricesMap = new Map(prices.map(p => [p.id, p]));
  BlazePlan.monthly = pricesMap.get(BlazePlan.monthlyCode)?.amount ?? 0;
  BlazePlan.yearly = pricesMap.get(BlazePlan.yearlyCode)?.amount ?? 0;
  FusionPlan.monthly = pricesMap.get(FusionPlan.monthlyCode)?.amount ?? 0;
  FusionPlan.yearly = pricesMap.get(FusionPlan.yearlyCode)?.amount ?? 0;
}

export function getTeamDataFromProSource(s: string): { teamId: string; planValue: SubscriptionPlan } | undefined {
  const [teamId, proPlan] = s.split(':');
  if (isObjectId(teamId) && proPlan) return { teamId, planValue: Number(proPlan) };
  return undefined;
}

