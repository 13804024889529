import { Component, AfterViewInit, ViewChild, OnDestroy, TemplateRef } from '@angular/core';
import { removeItem } from '../../../../common/baseUtils';
import { Modals } from '../../../../services/modals';

@Component({
  selector: 'editor-modals-box',
  templateUrl: 'editor-modals-box.pug',
})
export class EditorModalsBox implements AfterViewInit, OnDestroy {
  @ViewChild('createDrawing', { static: true }) createDrawing!: TemplateRef<any>;
  @ViewChild('drawingSettings', { static: true }) drawingSettings!: TemplateRef<any>;
  @ViewChild('settings', { static: true }) settings!: TemplateRef<any>;
  @ViewChild('createAccount', { static: true }) createAccount!: TemplateRef<any>;
  @ViewChild('userSettings', { static: true }) userSettings!: TemplateRef<any>;
  @ViewChild('signIn', { static: true }) signIn!: TemplateRef<any>;
  @ViewChild('signUp', { static: true }) signUp!: TemplateRef<any>;
  @ViewChild('help', { static: true }) help!: TemplateRef<any>;
  @ViewChild('userLimit', { static: true }) userLimit!: TemplateRef<any>;
  @ViewChild('edition', { static: true }) edition!: TemplateRef<any>;
  @ViewChild('overloaded', { static: true }) overloaded!: TemplateRef<any>;
  @ViewChild('drawingDeleted', { static: true }) drawingDeleted!: TemplateRef<any>;
  @ViewChild('choice', { static: true }) choice!: TemplateRef<any>;
  @ViewChild('question', { static: true }) question!: TemplateRef<any>;
  @ViewChild('confirm', { static: true }) confirm!: TemplateRef<any>;
  @ViewChild('info', { static: true }) info!: TemplateRef<any>;
  @ViewChild('input', { static: true }) input!: TemplateRef<any>;
  @ViewChild('acceptAiEula', { static: true}) acceptAiEula!: TemplateRef<any>;
  @ViewChild('aiQuotaReached', { static: true }) aiQuotaReached!: TemplateRef<any>;
  @ViewChild('disabledOnMobile', { static: true }) disabledOnMobile!: TemplateRef<any>;
  @ViewChild('versionHistoryCreate', { static: true }) versionHistoryCreate!: TemplateRef<any>;
  @ViewChild('vcFeedbackModal', { static: true }) vcFeedbackModal!: TemplateRef<any>;
  @ViewChild('textureSizeLimitReached', { static: true }) textureSizeLimitReached!: TemplateRef<any>;
  @ViewChild('prompt', { static: true }) prompt!: TemplateRef<any>;

  ngAfterViewInit() {
    Modals.templates.push(this);
  }
  ngOnDestroy() {
    removeItem(Modals.templates, this);
  }
}
