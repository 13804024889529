import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Post, PostType } from '../../../../../shared/posts';
import { faDice, faFolder, faInfoCircle, faTrash } from 'magma/common/icons';
import { BlogService, ReadingPostAnalytics } from '../../../services/blog.service';
import { DomSanitizer } from '@angular/platform-browser';
import { cloneDeep } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { ToastService } from '../../../../../../magma/src/ts/services/toast.service';
import { HTTP_URL_REGEX } from '../../../../../../magma/src/ts/common/utils';
import { MAX_POST_DESCRIPTION_LENGTH, MAX_POST_TITLE_LENGTH } from '../../../../../shared/constants';
import { YoutubeApi } from '../../../../../shared/youtube';
import { NavigationStart, Router, } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { Modals } from 'magma/services/modals';

export enum PostModalMode {
  Reading,
  Creating,
  Editing,
  Removing,
  Preview,
}

type hasPostModalMode = { mode: PostModalMode };
type optsTypes = CreatingOpts | ReadingOpts | EditingOpts | RemovingOpts | PreviewOpts;
export type PostModalData = hasPostModalMode & optsTypes;

interface CreatingOpts {
  mode: PostModalMode.Creating;
  projectId: string;
}

interface ReadingOpts {
  mode: PostModalMode.Reading;
  post: Post;
  analytics: ReadingPostAnalytics;
}

interface EditingOpts {
  mode: PostModalMode.Editing;
  projectId: string;
  post: Post;
}

interface RemovingOpts {
  mode: PostModalMode.Removing;
  projectId: string;
  post: Post;
}
interface PreviewOpts {
  mode: PostModalMode.Preview;
  projectId: string;
  post: Post;
}

const createEmptyPost = (): Post => {
  return {
    _id: '',
    createdAt: '',
    description: '',
    highlightOrder: -1,
    order: 0,
    project: '',
    thumbnail: '',
    title: '',
    type: PostType.Link,
    url: '',
    withModal: false,
    notificationTag: '',
    team: '',
  };
};

@UntilDestroy()
@Component({
  selector: 'post-modal',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './post-modal.component.pug',
  styleUrls: ['./post-modal.component.scss'],
})
export class PostModal {
  readonly PostType = PostType;
  readonly postTypes = Object.values(this.PostType);

  readonly faTrash = faTrash;
  readonly faFolder = faFolder;
  readonly faDice = faDice;
  readonly infoIcon = faInfoCircle;

  constructor(
    private blogService: BlogService,
    private domSanitizer: DomSanitizer,
    private http: HttpClient,
    private toastsService: ToastService,
    private changeDetectionRef: ChangeDetectorRef,
    private router: Router,
    private modals: Modals
  ) { }

  @Output() close = new EventEmitter<boolean>();

  projectId = '';
  mode = PostModalMode.Reading;
  post = createEmptyPost();

  loadedThumb: File | undefined = undefined;
  thumbnail = '';

  linkUrlInvalid = false;
  titleInvalid = false;
  descriptionInvalid = false;
  disableSubmit = false;
  requestOngoing = false;


  get isReading () { return isReading(this.data); }
  get isCreating () { return isCreating(this.data); }
  get isEditing () { return isEditing(this.data); }
  get isRemoving () { return isRemoving(this.data); }
  get isPreview () { return isPreview(this.data); }

  fallbackThumb = require('assets/posts-fallback-thumb.png');

  private _data!: PostModalData;
  get data(): PostModalData { return this._data; }
  @Input() set data(data: PostModalData) {
    this._data = data;
    this.loadedThumb = undefined;
    this.mode = data.mode;

    this.linkUrlInvalid = false;
    this.titleInvalid = false;
    this.descriptionInvalid = false;
    this.disableSubmit = false;
    this.requestOngoing = false;

    if (isReading(this.data) || isPreview(this.data)) {
      this.post = this.data.post;
    } else if (isEditing(this.data) || isRemoving(this.data)) {
      this.projectId = this.data.projectId;
      this.post = cloneDeep(this.data.post);
      this.disableSubmit = true;
    } else if (isCreating(this.data)) {
      this.projectId = this.data.projectId;
      this.post = createEmptyPost();
      this.disableSubmit = true;
    }

    this.thumbnail = this.post.thumbnail;

    this.router.events.pipe(
      filter((e): e is NavigationStart => e instanceof NavigationStart),
      untilDestroyed(this),
    ).subscribe(() => this.onClose(false));
  }

  get isCommunityHub() {
    if (isCreating(this.data)) {
      return this.blogService.isCommunityHubProject(this.data.projectId);
    } else {
      return this.blogService.isCommunityHubProject(this.post.project);
    }
  }

  get canResetThumb(){
    if (this.loadedThumb) return true;
    if (this.post.thumbnail === '') return false;
    return true;
  }

  get title() {
    if (this.isPreview) {
      return { value: 'Preview', padding: this.articlePaddingException };
    } else if (this.isReading) {
      return { value: this.post.type === PostType.Link ? 'You are leaving "Magma.com" website' : this.post.title, padding: this.articlePaddingException };
    } else if (this.isCreating || this.isEditing) {
      return { value: `${this.isCreating ? 'New' : 'Edit'}  ${this.isCreating ? 'post' : this.post.type }` };
    } else if (this.isRemoving) {
      return { value: `Delete "${this.post.title }"` };
    }
    return { value: '' };
  }

  get isArticle() {
    return this.post.type === PostType.Article;
  }

  resetThumb(){
    this.thumbnail = '';
    this.loadedThumb = undefined;
    this.post.thumbnail = this.thumbnail;
    this.validate();
  }

  typeSelect(type: PostType) {
    this.post.type = type;
    this.validate();
  }

  validate(){
    switch (this.post.type) {
      case PostType.Link:
        if (this.post.url) this.validateLinkInput();
        if (this.post.title) this.validateTitle();
        break;
      case PostType.Article:
        if (this.post.title) this.validateTitle();
        if (this.post.description) this.validateDescription();
        break;
      case PostType.Video:
        if (this.post.url) this.validateVideoUrl();
        if (this.post.title) this.validateTitle();
        if (this.post.description) this.validateDescription();
        break;
    }
    this.updateDisableSubmit();
  }

  private updateDisableSubmit(){
    const titleOk = this.isTitleValid();
    switch (this.post.type) {
      case PostType.Video: {
        const descriptionOk = this.isDescriptionValid();
        const urlOk = this.isVideoUrlValid();
        this.disableSubmit = !titleOk || !descriptionOk || !urlOk;
        break;
      }
      case PostType.Article: {
        const descriptionOk = this.isDescriptionValid();
        this.disableSubmit = !titleOk || !descriptionOk;
        break;
      }
      case PostType.Link: {
        const urlOk = this.isLinkUrlValid();
        this.disableSubmit = !titleOk || !urlOk;
        break;
      }
    }
  }

  isLinkUrlValid(){
    return this.post.url.length && this.post.url.match(HTTP_URL_REGEX);
  }
  validateLinkInput(){
    this.linkUrlInvalid = !this.isLinkUrlValid();
    this.updateDisableSubmit();
  }

  isTitleValid(){
    return !!(this.post.title.length && this.post.title.length < MAX_POST_TITLE_LENGTH);
  }
  validateTitle() {
    this.titleInvalid = !this.isTitleValid();
    this.updateDisableSubmit();
  }

  isDescriptionValid(){
    return this.post.description.length < MAX_POST_DESCRIPTION_LENGTH;
  }
  validateDescription() {
    this.descriptionInvalid = !this.isDescriptionValid();
    this.updateDisableSubmit();
  }

  isVideoUrlValid(){
    try {
      const httpUrlMatch = this.post.url.match(HTTP_URL_REGEX);
      const url = new URL(this.post.url);
      const youtubeDotCom = url.origin.endsWith('youtube.com') && url.searchParams.has('v');
      const youtuDotBe = url.origin.endsWith('youtu.be') && url.pathname.split('/').length;
      return youtubeDotCom || youtuDotBe;
    } catch (e) {
      return false;
    }
  }
  validateVideoUrl(){
    this.linkUrlInvalid = !this.isVideoUrlValid();
    this.updateDisableSubmit();
  }

  async fileUploaded(e: Event) {
    const file = (e?.target as HTMLInputElement)?.files?.[0];
    if (!file) return;

    const valid = await this.blogService.validateThumbnail(file);
    if (!valid) {
      this.toastsService.warning({ message: 'This file is not an image. Upload image file.' });
      return;
    }

    const url = URL.createObjectURL(file);
    const safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url) as string;

    this.loadedThumb = file;
    if ((e?.target as HTMLInputElement).files) {
      // empty internal list of files in input, so it triggers changes when re-uploading same file
      // (client-side only, upload to server-side is on form submit)
      // because working thumb could have been emptied and prefetched
      (e?.target as HTMLInputElement).value = '';
    }
    this.thumbnail = safeUrl;
    this.validate();
    this.changeDetectionRef.markForCheck();
  }

  async uploadThumbnail(projectId: string, thumb: File) {
    try {
      this.post.thumbnail = await this.blogService.uploadThumbnail(projectId, thumb);
    } catch (e) {
      this.loadedThumb = undefined;
    }
  }

  async onYtUrl() {
    this.validateVideoUrl();
    if (!this.linkUrlInvalid) {
      await this.prefetchFormData();
    }
  }

  async prefetchFormData(){
    if (this.isLinkUrlValid()) {
      const metadata = await this.blogService.getMetadata(this.projectId, this.post.url);
      if (!this.post.title && metadata.title) this.post.title = metadata.title;
      if (!this.post.description && metadata.description) this.post.description = metadata.description;
      if (!this.post.thumbnail && !this.thumbnail && metadata.thumbnail) {
        this.loadedThumb = undefined;
        this.thumbnail = metadata.thumbnail;
        this.post.thumbnail = metadata.thumbnail; // to enable canReset
      }
      this.validate();
      this.changeDetectionRef.markForCheck();
    }
  }

  getEmbeddedVideoUrl(ytUrl: string) {
    const videoId = YoutubeApi.getVideoId(ytUrl);
    if (videoId) {
      const url = YoutubeApi.getEmbeddedVideoUrl(videoId);
      return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      return undefined;
    }
  }

  onClose(confirmed: boolean) {
    if (confirmed) {
      this.submit()
        .catch((e) => {
          DEVELOPMENT && console.error(e);
          this.toastsService.error({ message: 'Something went wrong!' });
        })
        .finally(() => { this.close.emit(confirmed); });
    } else {
      this.close.emit(confirmed);
    }
  }

  private async submit(){
    if (this.isCreating) {
      if (this.loadedThumb) await this.uploadThumbnail(this.projectId, this.loadedThumb);
      this.requestOngoing = true;
      return this.create().finally(() => {
        this.requestOngoing = false;
      });
    } else if (this.isEditing) {
      if (this.loadedThumb) await this.uploadThumbnail(this.projectId, this.loadedThumb);
      this.requestOngoing = true;
      return this.edit().finally(() => {
        this.requestOngoing = false;
      });
    } else if (this.isRemoving) {
      this.requestOngoing = true;
      return this.remove().finally(() => {
        this.requestOngoing = false;
      });
    } else if (this.isReading) {
      if (this.post.type === PostType.Link) window.open(this.post.url, '_blank');
    }
  }

  private create() {
    return this.blogService.createPost(this.projectId, this.post);
  }

  private edit() {
    return this.blogService.updatePost(this.projectId, this.post);
  }

  private remove() {
    const { projectId, post} = this.data as RemovingOpts;
    return this.blogService.removePost(projectId, post._id);
  }

  get articlePaddingException(){
    return this.data && this.isReading && this.post.type === PostType.Article;
  }

  previewModal() {
    const post = { ...this.post };
    post.description = this.previewPlaceholder(post.description, '[Description]');
    post.title = this.previewPlaceholder(post.title, '[title]');
    post.url = this.previewPlaceholder(post.url, 'https://www.youtube.com/watch?v=ZbZmUlSenwY&t=38s');
    post.thumbnail = this.thumbnail;
    const data = { mode: PostModalMode.Preview, post };
    void this.modals.openByName('postModal', { data, class: this.isArticle ? 'modal-lg' : undefined });
  }

  previewPlaceholder(value: string, fallback: string) {
    return value?.length > 0 ? value : fallback;
  }
}

export const isReading = (data: PostModalData): data is ReadingOpts => data.mode === PostModalMode.Reading;
export const isCreating = (data: PostModalData): data is CreatingOpts => data.mode === PostModalMode.Creating;
export const isEditing = (data: PostModalData): data is EditingOpts => data.mode === PostModalMode.Editing;
export const isRemoving = (data: PostModalData): data is RemovingOpts => data.mode === PostModalMode.Removing;
export const isPreview = (data: PostModalData): data is PreviewOpts => data.mode === PostModalMode.Preview;
