import { ITool, IToolEditor, IToolModel, CompositeOp, ToolId, CursorType } from '../interfaces';
import { PaintBrush, PaintBrushMode } from '../paintBrush';
import { BaseBrushTool } from './baseBrushTool';
import { BRUSH_FIELDS_COMMON, roundPercent } from './brushUtils';
import { faPencil } from '../icons';
import { randomSeed } from '../random';
import { PENCIL_SIZES } from '../constants';
import { keys } from '../baseUtils';

export class PencilTool extends BaseBrushTool implements ITool {
  id = ToolId.Pencil;
  name = 'Pixel Pencil';
  description = 'Paint using a hard-edge square brush';
  learnMore = 'https://help.magma.com/en/articles/6871463-pencil';
  video = { url: '/assets/videos/pencil.mp4', width: 374, height: 210 };
  icon = faPencil;
  sizes = PENCIL_SIZES;
  compositeOp = CompositeOp.Draw;
  sizePressure = false;
  fields = keys<PencilTool>(BRUSH_FIELDS_COMMON);
  stabilize = 0;
  constructor(editor: IToolEditor, model: IToolModel) {
    super(editor, model);
  }
  verify() { // override base one
  }
  protected setupBrush(brush: PaintBrush) {
    randomSeed(brush, this.seed);
    brush.sizePressure = this.sizePressure;
    brush.flowPressure = this.flowPressure;
    brush.sizeJitter = roundPercent(this.sizeJitter);
    brush.setMode(PaintBrushMode.Pencil);
    brush.color = this.color;
    brush.colorHue = this.colorHue;
    brush.background = this.background;
    brush.size = this.size;
    brush.setMinSize(roundPercent(this.minSize));
    brush.setFlow(roundPercent(this.flow));
    brush.colorPressure = this.colorPressure;
    brush.foregroundBackgroundJitter = roundPercent(this.foregroundBackgroundJitter);
    brush.hueJitter = roundPercent(this.hueJitter);
    brush.saturationJitter = roundPercent(this.saturationJitter);
    brush.brightnessJitter = roundPercent(this.brightnessJitter);
    brush.viewFlip = false;
    brush.viewRotation = 0;
    brush.hasFeatures = this.hasFeatures;
    brush.lockedFeatures = this.lockedFeatures;
  }
  get cursor(): CursorType { return CursorType.Square; }
}
