import { Injectable, NgZone } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getActionLog, getTimestamp } from 'magma/common/actionLog';
import { post } from 'magma/common/xhr';
import { ErrorReporter } from 'magma/services/errorReporter';

@Injectable()
@UntilDestroy()
export class ErrorReporterService {
  user: { name: string; id: string } | undefined = undefined;
  private lastError = '';
  constructor(errorReporter: ErrorReporter, zone: NgZone) {
    let pendingErrors = 0;

    errorReporter.handleErrors((errorMessage: string, error: any, info: any) => {
      void zone.runOutsideAngular(async () => {
        try {
          // Bail out if errors can't be delivered to the server
          if (pendingErrors > 5) return;
          pendingErrors++;

          // unify code with RealModel's error reporter
          const name = error?.name || 'Error';
          const message = `${error?.message || error?.stack || error || errorMessage || ''}`;
          const stack = error?.stack || '';

          const usedMem = ((performance as any)?.memory?.usedJSHeapSize || 0) / (1024 * 1024 * 1024);
          const totalMem = ((performance as any)?.memory?.jsHeapSizeLimit || 0) / (1024 * 1024 * 1024);
          const actions = `\n[${getTimestamp(Date.now())}] memory: ${usedMem.toFixed(2)}/${totalMem.toFixed(2)}GB\n`
            + `actions:\n${getActionLog().map(x => `  ${x}`).join('\n')}\n\n`;

          if (message === this.lastError) return;
          this.lastError = message;

          await post('/errors/report', {
            name,
            message,
            stack,
            actions,
            info: { ...info, user: this.user },
            originalMessage: errorMessage,
            version: GIT_HASH,
            url: location.href,
          }, 'json');

          pendingErrors--;
        } catch (e) {
          DEVELOPMENT && console.error(e);
        }
      });
    }).pipe(untilDestroyed(this)).subscribe();
  }
}
