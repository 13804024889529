import { Directive, OnInit, ElementRef, Input } from '@angular/core';

@Directive({ selector: '[autoFocus]' })
export class AutoFocus implements OnInit {
  @Input('autoFocus') enabled: '' | true | false = ''; // this is empty string by default
  constructor(private element: ElementRef<HTMLElement>) {
  }
  ngOnInit() {
    if (this.enabled !== false) {
      setTimeout(() => this.element.nativeElement.focus(), 100);
    }
  }
}
