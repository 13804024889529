import { ROTATION_SNAP, MIN_VIEW_SCALE, MAX_VIEW_SCALE } from './constants';
import { clamp } from './mathUtils';
import { setRectNormalized, setRect, createRect, moveRect } from './rect';
import { Point, Rect, Viewport, ViewportState, Mat2d, Mat4, Vec2, ViewFilter } from './interfaces';
import { createPoint } from './point';
import { identityMat2d, translateMat2d, rotateMat2d, scaleMat2d } from './mat2d';
import { identityMat4, translateMat4, scaleMat4, rotateZMat4 } from './mat4';
import { roundCoord } from './compressor';

const tempPoint = createPoint(0, 0);
const tempRect = createRect(0, 0, 0, 0);
const TAU = 2 * Math.PI;

export function createViewport(x = 0, y = 0, scale = 1, rotation = 0, flipped = false, filter: ViewFilter = undefined): Viewport {
  return {
    x, y, scale, rotation, flipped, filter,
    width: 100,
    height: 100,
    contentWidth: 100,
    contentHeight: 100,
  };
}

export function isViewportValid({ x, y, scale, rotation, width, height, contentWidth, contentHeight }: Viewport) {
  return !Number.isNaN(x) && !Number.isNaN(y) && !Number.isNaN(scale) && scale > 0 && !Number.isNaN(rotation) &&
    !Number.isNaN(width) && !Number.isNaN(height) && !Number.isNaN(contentWidth) && !Number.isNaN(contentHeight) &&
    width > 0 && height > 0 && contentWidth > 0 && contentHeight > 0;
}

export function viewportsEqual(a: Viewport, b: Viewport, ignoreViewportSize = false) {
  return a.x === b.x && a.y === b.y && a.scale === b.scale && a.rotation === b.rotation &&
    a.flipped === b.flipped && a.filter === b.filter && a.contentWidth === b.contentWidth && a.contentHeight === b.contentHeight &&
    (ignoreViewportSize || (a.width === b.width && a.height === b.height));
}

export function copyViewport(a: Viewport, b: Viewport) {
  a.x = b.x;
  a.y = b.y;
  a.scale = b.scale;
  a.rotation = b.rotation;
  a.flipped = b.flipped;
  a.filter = b.filter;
  a.width = b.width;
  a.height = b.height;
  a.contentWidth = b.contentWidth;
  a.contentHeight = b.contentHeight;
}

export function getViewportState({ x, y, scale, rotation, flipped, filter }: Viewport): ViewportState {
  return { x, y, scale, rotation, flipped, filter };
}

export function matchFromOtherViewport(view: Viewport, toMatch: Viewport) {
  let { x, y, width, height, scale, rotation, flipped, filter } = toMatch;
  const pt = createPoint(0, 0);

  screenToDocumentXY(pt, width / 2, height / 2, toMatch);

  const scaleWidth = view.width / width;
  const scaleHeight = view.height / height;
  const minScale = Math.min(scaleWidth, scaleHeight);

  view.x = x;
  view.y = y;
  view.scale = (Math.round((scale + Number.EPSILON) * 100) / 100) * minScale;
  view.rotation = rotation;
  view.flipped = flipped;
  view.filter = filter;

  documentToScreenXY(pt, pt.x, pt.y, view);

  view.x += view.width / 2 - pt.x;
  view.y += view.height / 2 - pt.y;
}

export function setViewportState(view: Viewport, state: ViewportState) {
  // safeguards against broken state
  view.x = +(state.x ?? 0);
  view.y = +(state.y ?? 0);
  view.scale = +(state.scale ?? 1);
  view.rotation = +(state.rotation ?? 0);
  view.flipped = !!state.flipped;
  view.filter = state.filter;
}

export function setViewportSize(view: Viewport, width: number, height: number) {
  view.width = width || 1;
  view.height = height || 1;
  clampViewport(view);
}

export function setViewportContentSize(view: Viewport, contentWidth: number, contentHeight: number) {
  view.contentWidth = (contentWidth | 0) || 1;
  view.contentHeight = (contentHeight | 0) || 1;
  clampViewport(view);
}

export function setViewportSizes(view: Viewport, width: number, height: number, contentWidth: number, contentHeight: number) {
  view.width = width || 1;
  view.height = height || 1;
  view.contentWidth = (contentWidth | 0) || 1;
  view.contentHeight = (contentHeight | 0) || 1;
}

export function getViewportAngle(view: Viewport, x: number, y: number) {
  const dx = x - view.width / 2;
  const dy = y - view.height / 2;
  return Math.atan2(dx, dy);
}

export function centerViewport(view: Viewport) {
  view.x = (view.width - view.contentWidth * view.scale) / 2;
  view.y = (view.height - view.contentHeight * view.scale) / 2;
}

export function moveViewport(view: Viewport, x: number, y: number) {
  moveViewportTo(view, view.x + x, view.y + y);
}

export function moveViewportTo(view: Viewport, x: number, y: number) {
  view.x = x;
  view.y = y;
  clampViewport(view);
}

export function zoomViewport(view: Viewport, delta: number) {
  zoomViewportAt(view, delta, view.width / 2, view.height / 2);
}

export function zoomViewportAt(view: Viewport, delta: number, x: number, y: number) {
  scaleViewportAt(view, view.scale * Math.pow(2, delta * 10), x, y);
}

export function scaleViewportAt(view: Viewport, scale: number, x: number, y: number) {
  const oldScale = view.scale || 1;
  view.scale = clamp(scale, MIN_VIEW_SCALE, MAX_VIEW_SCALE);
  view.x = x - ((x - view.x) * (view.scale / oldScale));
  view.y = y - ((y - view.y) * (view.scale / oldScale));
  clampViewport(view);
}

export function rotateViewport(view: Viewport, angle: number) {
  rotateViewportAt(view, angle, view.width / 2, view.height / 2);
}

export function rotateViewportBy(view: Viewport, angle: number) {
  rotateViewport(view, view.rotation + angle);
}

export function rotateViewportXY(view: Viewport, x: number, y: number, startAngle: number, snap: boolean) {
  let rotation = getViewportAngle(view, x, y) + startAngle;

  if (snap) rotation = Math.round(rotation / ROTATION_SNAP) * ROTATION_SNAP;

  rotateViewport(view, rotation);
}

export function rotateViewportAt(view: Viewport, angle: number, x: number, y: number, snap = 0.02) {
  while (angle < -Math.PI) angle += TAU;
  while (angle >= Math.PI) angle -= TAU;
  if (Math.abs(angle) < snap) angle = 0;

  tempPoint.x = x;
  tempPoint.y = y;
  screenToDocumentPoint(tempPoint, view);
  view.rotation = angle;
  documentToScreenPoint(tempPoint, view);

  view.x += x - tempPoint.x;
  view.y += y - tempPoint.y;
}

export function fitViewport(view: Viewport, expand: boolean) {
  const pad = getPadding(view);
  const w = Math.max(view.width - 2 * pad, 1);
  const h = Math.max(view.height - 2 * pad, 1);

  if ((view.contentWidth / w) > (view.contentHeight / h)) {
    view.scale = w / Math.max(view.contentWidth, 1);
  } else {
    view.scale = h / Math.max(view.contentHeight, 1);
  }

  if (!expand) view.scale = Math.min(view.scale, 1);
  view.scale = clamp(view.scale, MIN_VIEW_SCALE, MAX_VIEW_SCALE);

  centerViewport(view);
}

export function fitViewportOnScreen(view: Viewport) {
  view.flipped = false;
  view.rotation = 0;
  fitViewport(view, true);
}

export function fitViewportToActualPixels(view: Viewport) {
  view.flipped = false;
  view.rotation = 0;
  view.scale = 1;
  centerViewport(view);
}

export function flipViewport(view: Viewport) {
  flipViewportAt(view, view.width / 2, view.height / 2);
}

export function flipViewportAt(view: Viewport, x: number, y: number) {
  tempPoint.x = x;
  tempPoint.y = y;
  screenToDocumentPoint(tempPoint, view);
  view.flipped = !view.flipped;
  view.rotation = -view.rotation;
  documentToScreenPoint(tempPoint, view);
  view.x += x - tempPoint.x;
  view.y += y - tempPoint.y;
}

function getPadding(view: Viewport) {
  return Math.min(view.width, view.height) * 0.05;
}

export function clampViewport(view: Viewport) {
  if (DEVELOPMENT && !TESTS) return;
  const pad = getPadding(view);

  if (view.rotation === 0) {
    view.x = clamp(view.x, pad - view.contentWidth * view.scale, view.width - pad);
    view.y = clamp(view.y, pad - view.contentHeight * view.scale, view.height - pad);
  } else {
    const x = view.x;
    const y = view.y;
    view.x = 0;
    view.y = 0;
    setRect(tempRect, 0, 0, view.contentWidth, view.contentHeight);
    documentToScreenRect(tempRect, view);
    view.x = clamp(x, pad - (tempRect.x + tempRect.w), view.width - tempRect.x - pad);
    view.y = clamp(y, pad - (tempRect.y + tempRect.h), view.height - tempRect.y - pad);
  }
}

export function createViewportMatrix2d(mat: Mat2d, view: Viewport) {
  const ratio = 1;
  const vw = view.contentWidth / 2;
  const vh = view.contentHeight / 2;
  const vs = view.scale * ratio;

  identityMat2d(mat);
  translateMat2d(mat, mat, view.x * ratio + vw * vs, view.y * ratio + vh * vs);
  rotateMat2d(mat, mat, -view.rotation);
  scaleMat2d(mat, mat, vs * (view.flipped ? -1 : 1), vs);
  translateMat2d(mat, mat, -vw, -vh);

  return mat;
}

export function createViewportMatrix4(mat: Mat4, view: Viewport, exactView = false) {
  const vw = view.contentWidth / 2;
  const vh = view.contentHeight / 2;
  const vs = view.scale;

  identityMat4(mat);
  translateMat4(mat, mat, -1, 1, 0);
  scaleMat4(mat, mat, 2 / (view.width || 1), -2 / (view.height || 1), 1);

  let x = view.x + vw * vs;
  let y = view.y + vh * vs;

  if (exactView) {
    x = Math.round(x);
    y = Math.round(y);
  }

  translateMat4(mat, mat, x, y, 0);
  rotateZMat4(mat, mat, -view.rotation);
  scaleMat4(mat, mat, vs * (view.flipped ? -1 : 1), vs, 1);
  translateMat4(mat, mat, -vw, -vh, 0);

  return mat;
}

export function applyViewportTransform(context: CanvasRenderingContext2D, view: Viewport, ratio: number) {
  const vw = view.contentWidth / 2;
  const vh = view.contentHeight / 2;
  const vs = view.scale * ratio;

  context.translate(view.x * ratio + vw * vs, view.y * ratio + vh * vs);
  context.rotate(-view.rotation);
  context.scale(vs * (view.flipped ? -1 : 1), vs);
  context.translate(-vw, -vh);
}

export function screenToDocumentXY(output: Point, x: number, y: number, view: Viewport) {
  output.x = x;
  output.y = y;
  screenToDocumentPoint(output, view);
}

export function screenToDocumentAndRoundXY(output: Point, x: number, y: number, view: Viewport) {
  output.x = x;
  output.y = y;
  screenToDocumentPoint(output, view);
  output.x = roundCoord(output.x);
  output.y = roundCoord(output.y);
}

export function documentToAbsoluteDocument(point: Point, drawing: Rect) {
  point.x += drawing.x;
  point.y += drawing.y;
}

export function documentToAbsoluteDocumentRect(rect: Rect, drawing: Rect) {
  moveRect(rect, drawing.x, drawing.y);
}

export function absoluteDocumentToDocumentRect(rect: Rect, drawing: Rect) {
  moveRect(rect, -drawing.x, -drawing.y);
}

export function absoluteDocumentToDocuemnt(point: Point, drawing: Rect) {
  point.x -= drawing.x;
  point.y -= drawing.y;
}

export function screenToDocumentPoint(point: Point, view: Viewport) {
  const r = view.rotation;
  const s = view.scale || 1;

  let x = point.x;
  let y = point.y;
  let nx = 0, ny = 0;

  if (r === 0) {
    nx = (x - view.x) / s;
    ny = (y - view.y) / s;
  } else {
    const cos = Math.cos(r);
    const sin = Math.sin(r);
    const w = view.contentWidth / 2;
    const h = view.contentHeight / 2;
    x -= (view.x + w * s);
    y -= (view.y + h * s);
    nx = x * cos - y * sin;
    ny = x * sin + y * cos;
    nx = nx / s + w;
    ny = ny / s + h;
  }

  point.x = view.flipped ? view.contentWidth - nx : nx;
  point.y = ny;
}

export function documentToScreenXY(output: Point, x: number, y: number, view: Viewport) {
  output.x = x;
  output.y = y;
  documentToScreenPoint(output, view);
}

export function documentToScreenPoint(point: Point, view: Viewport) {
  const r = view.rotation;
  const s = view.scale;

  let x = view.flipped ? (view.contentWidth - point.x) : point.x;
  let y = point.y;

  if (r === 0) {
    x *= s;
    y *= s;
  } else {
    const w = view.contentWidth / 2;
    const h = view.contentHeight / 2;
    x = (x - w) * s;
    y = (y - h) * s;
    const xx = x * Math.cos(-r) - y * Math.sin(-r);
    const yy = x * Math.sin(-r) + y * Math.cos(-r);
    x = xx + (w * s);
    y = yy + (h * s);
  }

  point.x = x + view.x;
  point.y = y + view.y;
}

export function screenToDocumentRect(rect: Rect, view: Viewport) {
  if (view.rotation === 0) {
    const scale = view.scale || 1;
    let x = (rect.x - view.x) / scale;
    let y = (rect.y - view.y) / scale;
    let w = rect.w / scale;
    let h = rect.h / scale;

    if (view.flipped) {
      x = view.contentWidth - x;
      w = -w;
    }

    setRectNormalized(rect, x, y, w, h);
  } else {
    const p = tempPoint;

    screenToDocumentXY(p, rect.x, rect.y, view);
    let x1 = p.x;
    let x2 = x1;
    let y1 = p.y;
    let y2 = y1;

    screenToDocumentXY(p, rect.x + rect.w, rect.y, view);
    x1 = Math.min(x1, p.x);
    x2 = Math.max(x2, p.x);
    y1 = Math.min(y1, p.y);
    y2 = Math.max(y2, p.y);

    screenToDocumentXY(p, rect.x, rect.y + rect.h, view);
    x1 = Math.min(x1, p.x);
    x2 = Math.max(x2, p.x);
    y1 = Math.min(y1, p.y);
    y2 = Math.max(y2, p.y);

    screenToDocumentXY(p, rect.x + rect.w, rect.y + rect.h, view);
    x1 = Math.min(x1, p.x);
    x2 = Math.max(x2, p.x);
    y1 = Math.min(y1, p.y);
    y2 = Math.max(y2, p.y);

    setRectNormalized(rect, x1, y1, x2 - x1, y2 - y1);
  }
}

export function documentToScreenRect(rect: Rect, view: Viewport) {
  if (view.rotation === 0) {
    const s = view.scale;
    let x = rect.x;
    let w = rect.w;

    if (view.flipped) {
      x = view.contentWidth - x;
      w = -w;
    }

    setRectNormalized(rect, x * s + view.x, rect.y * s + view.y, w * s, rect.h * s);
  } else {
    const p = tempPoint;

    p.x = rect.x;
    p.y = rect.y;
    documentToScreenPoint(p, view);
    let x1 = p.x;
    let x2 = x1;
    let y1 = p.y;
    let y2 = y1;

    p.x = rect.x + rect.w;
    p.y = rect.y;
    documentToScreenPoint(p, view);
    x1 = Math.min(x1, p.x);
    x2 = Math.max(x2, p.x);
    y1 = Math.min(y1, p.y);
    y2 = Math.max(y2, p.y);

    p.x = rect.x;
    p.y = rect.y + rect.h;
    documentToScreenPoint(p, view);
    x1 = Math.min(x1, p.x);
    x2 = Math.max(x2, p.x);
    y1 = Math.min(y1, p.y);
    y2 = Math.max(y2, p.y);

    p.x = rect.x + rect.w;
    p.y = rect.y + rect.h;
    documentToScreenPoint(p, view);
    x1 = Math.min(x1, p.x);
    x2 = Math.max(x2, p.x);
    y1 = Math.min(y1, p.y);
    y2 = Math.max(y2, p.y);

    setRectNormalized(rect, x1, y1, x2 - x1, y2 - y1);
  }
}

export function documentToScreenPoints(points: Point[], view: Viewport): void {
  for (let point of points) {
    documentToScreenPoint(point, view);
  }
}

export function documentVec2ToScreenPoint(vec2: Vec2, view: Viewport): Point {
  const point = createPoint(vec2[0], vec2[1]);
  documentToScreenPoint(point, view);
  return point;
}

export function getRotFromView(view: Viewport) {
  let rot = Math.round(view.rotation / (Math.PI / 2)) | 0;
  while (rot < 0) rot += 4;
  rot = rot % 4;
  return rot;
}
