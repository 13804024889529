import { CompositeOp, Layer, ToolId, Drawing, ToolSurface, ToolSurfaceData, Mat2d, Rect, User, Vec2 } from './interfaces';
import { copyRect, isRectEmpty, cloneRect, createRect, resetRect, addRect, intersectRect } from './rect';
import { clipToDrawingRect, getLayerSafe } from './drawing';
import { WHITE } from './constants';
import { createMat2d, identityMat2d, translateMat2d, scaleMat2d, rotateMat2d, isMat2dIdentity, copyMat2d } from './mat2d';
import { cloneVec2, transformVec2ByMat2d, createVec2, copyVec2 } from './vec2';
import { isMaskEmpty } from './mask';
import { isPerspectiveGridLayer, isTextLayer } from './layer';
import { canDrawTextLayer, textLayerToTextBox } from './text/text-utils';
import { clipSurfaceToLimits } from './rect';
import { getMaxLayerHeight, getMaxLayerWidth } from './drawingUtils';

const EPSILON = 0.00001;

export function createSurface(): ToolSurface {
  return {
    toolId: ToolId.None,
    layer: undefined,
    canvas: undefined,
    canvasMask: undefined,
    textureX: 0,
    textureY: 0,
    texture: undefined,
    textureMask: undefined,
    textureIsLinear: false,
    context: undefined,
    mode: CompositeOp.None,
    rect: createRect(0, 0, 0, 0),
    opacity: 1,
    color: WHITE,
    transforming: false,
    translateX: 0,
    translateY: 0,
    rotate: 0,
    scaleX: 1,
    scaleY: 1,
    transform: createMat2d(),
    transformOrigin: undefined,
    ignoreSelection: false,
    drawingRect: createRect(0, 0, 0, 0)
  };
}

export function hasIdentityTransform(surface: ToolSurface) {
  return surface.translateX === 0 && surface.translateY === 0 && surface.scaleX === 1 && surface.scaleY === 1 && surface.rotate === 0;
}

export function hasOnlyTranslation(surface: ToolSurface) {
  return surface.scaleX === 1 && surface.scaleY === 1 && surface.rotate === 0;
}

export function hasZeroTransform(surface: ToolSurface) {
  return Math.abs(surface.scaleX) < EPSILON || Math.abs(surface.scaleY) < EPSILON;
}

export function isSurfaceEmpty(surface: ToolSurface) {
  return !surface.layer || (!surface.canvas && !surface.texture) || isRectEmpty(surface.rect);
}

export function getSurfaceState(surface: ToolSurface): ToolSurfaceData {
  return {
    toolId: surface.toolId,
    layerId: surface.layer?.id ?? 0,
    mode: surface.mode,
    rect: cloneRect(surface.rect),
    opacity: surface.opacity,
    color: surface.color,
    transforming: surface.transforming,
    translateX: surface.translateX,
    translateY: surface.translateY,
    rotate: surface.rotate,
    scaleX: surface.scaleX,
    scaleY: surface.scaleY,
    transformOrigin: surface.transformOrigin ? Array.from(surface.transformOrigin) : undefined,
    drawingRect: cloneRect(surface.drawingRect)
  };
}

export function setSurfaceState(surface: ToolSurface, state: ToolSurfaceData, drawing: Drawing) {
  const { layerId, transformOrigin } = state;
  surface.toolId = state.toolId;
  surface.layer = layerId ? getLayerSafe(drawing, layerId) : undefined;
  surface.mode = state.mode;
  copyRect(surface.rect, state.rect);
  surface.opacity = state.opacity;
  surface.color = state.color;
  surface.transforming = state.transforming;
  surface.translateX = state.translateX;
  surface.translateY = state.translateY;
  surface.rotate = state.rotate;
  surface.scaleX = state.scaleX;
  surface.scaleY = state.scaleY;
  updateTransform(surface);
  surface.transformOrigin = transformOrigin ? cloneVec2(transformOrigin) : undefined;
  copyRect(surface.drawingRect, state.drawingRect);
}

export function setupSurface(surface: ToolSurface, toolId: ToolId, mode: CompositeOp, layer: Layer, bounds: Rect) {
  surface.toolId = toolId;
  surface.layer = layer;
  surface.mode = mode;
  resetRect(surface.rect);
  surface.opacity = 1;
  surface.color = WHITE;
  surface.transforming = false;
  resetTransform(surface);
  surface.transformOrigin = undefined;

  if (isRectEmpty(bounds)) throw new Error('Invalid surface bounds');
  copyRect(surface.drawingRect, bounds);
}

export function resetSurface(surface: ToolSurface) {
  surface.mode = CompositeOp.None;
  surface.opacity = 1;
  resetRect(surface.rect);
  surface.layer = undefined;
  surface.transforming = false;
  surface.textureX = 0;
  surface.textureY = 0;
  resetTransform(surface);
  surface.ignoreSelection = false;

  resetRect(surface.drawingRect);
}

export function resetTransform(surface: ToolSurface) {
  surface.translateX = 0;
  surface.translateY = 0;
  surface.scaleX = 1;
  surface.scaleY = 1;
  surface.rotate = 0;
  identityMat2d(surface.transform);
}

export function setTransform(surface: ToolSurface, transform: number[]) {
  surface.translateX = transform[0];
  surface.translateY = transform[1];
  surface.scaleX = transform[2];
  surface.scaleY = transform[3];
  surface.rotate = transform[4];
  updateTransform(surface);
}

export function updateTransform(s: ToolSurface) {
  createTransform(s.transform, s.translateX, s.translateY, s.rotate, s.scaleX, s.scaleY);
}

export function createTransform(
  transform: Mat2d, translateX: number, translateY: number, rotate: number, scaleX: number, scaleY: number
) {
  identityMat2d(transform);
  translateMat2d(transform, transform, translateX, translateY);
  rotateMat2d(transform, transform, rotate);
  scaleMat2d(transform, transform, scaleX, scaleY);
}

export function copySurfaceTransform(dst: ToolSurface, src: ToolSurface) {
  dst.translateX = src.translateX;
  dst.translateY = src.translateY;
  dst.scaleX = src.scaleX;
  dst.scaleY = src.scaleY;
  dst.rotate = src.rotate;
  dst.transformOrigin = src.transformOrigin?.slice();
  updateTransform(dst);
}

const tempBounds = [createVec2(), createVec2(), createVec2(), createVec2()];
const tempMat = createMat2d();
// const tempRect = createRect(0, 0, 0, 0);

export function rectToBounds(bounds: Float32Array[], rect: Rect) {
  bounds[0][0] = rect.x;
  bounds[0][1] = rect.y;
  bounds[1][0] = rect.x + rect.w;
  bounds[1][1] = rect.y;
  bounds[2][0] = rect.x + rect.w;
  bounds[2][1] = rect.y + rect.h;
  bounds[3][0] = rect.x;
  bounds[3][1] = rect.y + rect.h;
}
/*
function boundsToRect(bounds: Float32Array[], rect: Rect) {
  let x0 = bounds[0][0];
  let x1 = bounds[0][0];
  let y0 = bounds[0][1];
  let y1 = bounds[0][1];

  for (let i = 1; i < 4; i++) {
    x0 = Math.min(x0, bounds[i][0]);
    x1 = Math.max(x1, bounds[i][0]);
    y0 = Math.min(y0, bounds[i][1]);
    y1 = Math.max(y1, bounds[i][1]);
  }

  rect.x = x0;
  rect.w = x1 - x0;
  rect.y = y0;
  rect.h = y1 - y0;
}
*/
export function transformBounds(bounds: Float32Array[], transform: Mat2d) {
  for (let i = 0; i < 4; i++) {
    transformVec2ByMat2d(bounds[i], bounds[i], transform);
  }
}

export function getSurfaceBounds(surface: ToolSurface) {
  if (hasZeroTransform(surface)) {
    return createRect(0, 0, 0, 0);
  } else {
    return getTransformedRectBounds(surface.rect, surface.transform);
  }
}

export function getTransformedRectBounds(rect: Rect, transform: Mat2d) {
  // have to skip for empty rect, otherwise floor/ceil will make the rect non-empty after transform
  if (isMat2dIdentity(transform) || isRectEmpty(rect)) return cloneRect(rect);

  rectToBounds(tempBounds, rect);
  transformBounds(tempBounds, transform);

  let minX = 1e9, minY = 1e9, maxX = -1e9, maxY = -1e9;

  for (const pt of tempBounds) {
    minX = Math.floor(Math.min(minX, pt[0]));
    minY = Math.floor(Math.min(minY, pt[1]));
    maxX = Math.ceil(Math.max(maxX, pt[0]));
    maxY = Math.ceil(Math.max(maxY, pt[1]));
  }

  return createRect(minX, minY, Math.max(maxX - minX, 0), Math.max(maxY - minY, 0));
}

export function getTransformedSurfaceBounds(rect: Rect, transform: Mat2d) {
  // have to skip for empty rect, otherwise floor/ceil will make the rect non-empty after transform
  if (isMat2dIdentity(transform) || isRectEmpty(rect)) return cloneRect(rect);

  rectToBounds(tempBounds, rect);
  transformBounds(tempBounds, transform);

  let minX = 1e9, minY = 1e9, maxX = -1e9, maxY = -1e9;

  for (const pt of tempBounds) {
    minX = Math.round(Math.min(minX, pt[0]));
    minY = Math.round(Math.min(minY, pt[1]));
    maxX = Math.round(Math.max(maxX, pt[0]));
    maxY = Math.round(Math.max(maxY, pt[1]));
  }

  return createRect(minX, minY, Math.max(maxX - minX, 0), Math.max(maxY - minY, 0));
}

// TODO: this is wrong in many cases
//       scale_up+rotate then switch to other layer
export function getTransformRect({ surface, selection, activeLayer }: User, drawing: Drawing) {
  if (!isMaskEmpty(selection)) {
    if (surface.layer != activeLayer) {
      if (hasZeroTransform(surface)) {
        return createRect(0, 0, 0, 0);
      } else {
        return getTransformedRectBounds(selection.bounds, surface.transform);
      }
    } else {
      return selection.bounds;
    }
  } else if (isTextLayer(activeLayer)) {
    return canDrawTextLayer(activeLayer) ? textLayerToTextBox(activeLayer, 'rect') : drawing;
  } else if (isPerspectiveGridLayer(activeLayer)) {
    return activeLayer.perspectiveGrid.bounds;
  } else if (!isSurfaceEmpty(surface) && surface.layer === activeLayer) {
    return surface.rect;
  } else if (activeLayer && !isRectEmpty(activeLayer.rect)) {
    return activeLayer.rect;
  } else {
    return drawing;
  }
}

export function getTransformRectTransformation(user: User){
  if (isTextLayer(user.activeLayer) && canDrawTextLayer(user.activeLayer)) {
    if (!isMaskEmpty(user.selection)) {
      return undefined;
    } else {
      return user.activeLayer.textarea.transform;
    }
  } else if (isPerspectiveGridLayer(user.activeLayer)) {
    if (!isMaskEmpty(user.selection)) {
      return undefined;
    } else {
      copyMat2d(tempMat, user.activeLayer.perspectiveGrid.transform);
      return tempMat;
    }
  } else if (user.surface.layer === user.activeLayer) {
    return user.surface.transform;
  } else {
    return undefined;
  }
}

export function getTransformedTransformRect(user: User) {
  if (isTextLayer(user.activeLayer) && canDrawTextLayer(user.activeLayer)) {
    if (!isMaskEmpty(user.selection)) {
      return createRect(0, 0, 0, 0);
    } else {
      return getTransformedRectBounds(user.activeLayer.textarea.rect, user.activeLayer.textarea.transform);
    }
  } else if (user.surface.layer === user.activeLayer) {
    if (isMaskEmpty(user.selection)) {
      return getTransformedRectBounds(user.surface.rect, user.surface.transform);
    } else {
      return getTransformedRectBounds(user.selection.bounds, user.surface.transform);
    }
  } else {
    return createRect(0, 0, 0, 0);
  }
}

// NOTE: returns global array, do not call again before using the result
export function getTransformBounds(user: User, drawing: Drawing, mat?: Mat2d) {
  const rect = getTransformRect(user, drawing);
  rectToBounds(tempBounds, rect);
  const transform = getTransformRectTransformation(user);
  if (transform) transformBounds(tempBounds, transform);
  if (mat) transformBounds(tempBounds, mat);
  return tempBounds;
}

export function getTransformOrigin(output: Vec2, bounds: Vec2[], { surface, activeLayer }: User, transform: Mat2d) {
  if (surface.transformOrigin && surface.layer === activeLayer) {
    copyVec2(output, surface.transformOrigin);
    transformVec2ByMat2d(output, output, surface.transform);
    transformVec2ByMat2d(output, output, transform);
  } else {
    output[0] = 0;
    output[1] = 0;

    for (const pt of bounds) {
      output[0] += pt[0] * 0.25;
      output[1] += pt[1] * 0.25;
    }
  }
}

export function commitedLayerRect(surface: ToolSurface, layer: Layer) {
  if (isTextLayer(layer) && !canDrawTextLayer(layer)) return undefined;

  let rect = cloneRect(layer.rect);
  if (isTextLayer(layer)) {
    rect = textLayerToTextBox(layer, 'textureRect');
    clipToDrawingRect(rect, surface.drawingRect);
  }

  if (surface.layer !== layer) return rect;
  if (surface.mode === CompositeOp.Erase) return rect;
  if (surface.mode === CompositeOp.Draw && layer.opacityLocked) return rect;

  if (isRectEmpty(surface.drawingRect)) throw new Error('Invalid drawingRect');

  const bounds = getSurfaceBounds(surface);

  // this will detect if surface bounds will intersect with calculated max layer rect (`limit`)
  // if bounds will be outside of new layer rect, it will be ignored and bounds won't be added to layer rect
  const limit = cloneRect(layer.rect);
  addRect(limit, bounds);
  clipSurfaceToLimits(limit, surface.drawingRect, getMaxLayerWidth(surface.drawingRect.w), getMaxLayerHeight(surface.drawingRect.h));
  intersectRect(bounds, limit); // this will make sure that we are using only "usable" part of bounds (inside max layer )

  if (!isRectEmpty(bounds)) {
    addRect(rect, bounds);
    clipSurfaceToLimits(rect, surface.drawingRect, getMaxLayerWidth(surface.drawingRect.w), getMaxLayerHeight(surface.drawingRect.h));
  }

  return rect;
}
