import { insertCharactersIntoTextarea, PARAGRAPH_SPLIT_CHARACTER, removeCharactersFromTextarea, sanitizeEmojis, Textarea } from './textarea';
import { CharacterFormatting, CharacterFormattingDescription, DEFAULT_CHARACTER_FORMATTING, isNonRangeCharacterFormattingKey, isValidCharacterFormatting, isValidParagraphFormatting, ParagraphFormatting, ParagraphFormattingDescription, TextAlignment, TextCases, TextRange } from './formatting';
import { TextCharacter } from './text-character';
import { Paragraph } from './paragraph';
import { clipboardSupported } from '../../services/copyPasteActions';
import { AUTO_SETTING_STRING } from '../utils';
import { TextSelectionDetailed } from './navigation';
import { Editor } from '../../services/editor';
import { IToolEditor } from '../interfaces';
import { colorToHexRGB, parseColor } from '../color';
import { MAX_TEXTAREA_TEXT_LENGTH } from './text-utils';

const WRAPPER_TAG = 'div';

export async function copyTextFromTextarea (textarea: Textarea, selection: TextSelectionDetailed) {
  try {
    return await saveContentToClipboard(textarea, selection);
  } catch (error) {
    DEVELOPMENT && console.error('Failed to save HTML to clipboard:', error);
    return false;
  }
}

export function copyTextFromTextareaViaClipboardEvent (e: ClipboardEvent, textarea: Textarea, selection: TextSelectionDetailed) {
  try {
    const text = textarea.text.substring(selection.start, selection.end);
    const html = serializeToHTML(textarea, selection);
    if (!e.clipboardData) throw new Error('Failed to copy text from textarea via ClipboardEvent, e.clipboardData is null');
    e.clipboardData.setData('text/plain', text);
    e.clipboardData.setData('text/html', html);
    return true;
  } catch (error) {
    DEVELOPMENT && console.error('Failed to save HTML to clipboard:', error);
    return false;
  }
}

export function cutTextFromTextareaViaClipboardEvent (e: ClipboardEvent, textarea: Textarea, selection: TextSelectionDetailed) {
  const successfullyCopied = copyTextFromTextareaViaClipboardEvent(e, textarea, selection);
  if (!successfullyCopied) {
    DEVELOPMENT && console.warn('Failed to copy text from selection, aborting cut.');
    return false;
  }
  removeCharactersFromTextarea(textarea, selection);
  return true;
}

export function serializeToHTML(textarea: Textarea, selection: TextSelectionDetailed) {
  const { start, end } = selection;
  const text = Array.from(textarea.text);
  let html = `<${WRAPPER_TAG}>`;
  let startedFormatting: CharacterFormattingDescription | undefined = undefined;
  let paragraphOnGoing = false;
  let paragraphNr = 0;

  function startParagraphTag () {
    html += `<p style="${paragraphFormattingToStyleAttribute(textarea.paragraphs[paragraphNr])}">`;
    paragraphNr++;
    paragraphOnGoing = true;
  }

  function stopParagraphTag () {
    if (startedFormatting) stopSpanTag();
    html += '</p>';
    paragraphOnGoing = false;
  }

  function startSpanTag (formatting: CharacterFormattingDescription, character: TextCharacter) {
    html += `<span style="${characterFormattingToSpanStyleAttribute(character)}">`;
    startedFormatting = formatting;
  }

  function stopSpanTag() {
    html += '</span>';
    startedFormatting = undefined;
  }

  for (let formattedIndex = start; formattedIndex < end; formattedIndex++) {
    const character = textarea.characters[formattedIndex];
    const formatting = textarea.characterFormattings.find(f => f.start <= formattedIndex && f.start + f.length > formattedIndex);

    if (formatting !== startedFormatting) {
      if (formatting && !startedFormatting) {
        startSpanTag(formatting, character);
      } else if (!formatting && startedFormatting) {
        stopSpanTag();
      } else if (formatting && startedFormatting) {
        stopSpanTag();
        startSpanTag(formatting, character);
      }
    }
    if (character.isEOParagraph) {
      if (startedFormatting) stopSpanTag();
      if (paragraphOnGoing) stopParagraphTag();
      startParagraphTag();
      if (formatting) startSpanTag(formatting, character);
    }
    else html += text[formattedIndex];
  }

  if (paragraphOnGoing) stopParagraphTag();
  else if (startedFormatting) stopSpanTag();
  html += `</${WRAPPER_TAG}>`;

  return html;
}

type FormattingKeyTranslatesToCssPropertyThatCombinesMultipleFormattingKeys = keyof Pick<CharacterFormatting, 'scaleX' | 'scaleY' | 'underline' | 'strikethrough'>;
type FormattingKeyTranslatesToSimpleCssProperty = keyof Omit<CharacterFormatting, FormattingKeyTranslatesToCssPropertyThatCombinesMultipleFormattingKeys>;
type ParsedTextFormattings = { characterFormattings: CharacterFormattingDescription[]; paragraphFormattings: ParagraphFormattingDescription[] };

const saveContentToClipboard = (textarea: Textarea, selection: TextSelectionDetailed) => {
  const text = textarea.text.substring(selection.start, selection.end);
  const html = serializeToHTML(textarea, selection);

  const shouldUseWrite = navigator.clipboard !== undefined && typeof ClipboardItem === 'function' && typeof navigator.clipboard.write === 'function';
  const shouldUseWriteText = navigator.clipboard !== undefined && !shouldUseWrite && typeof navigator.clipboard.writeText === 'function';

  let promise: Promise<boolean>;

  if (shouldUseWrite) {
    const data = new ClipboardItem({
      'text/html': new Blob([html], { type: 'text/html' }),
      'text/plain': new Blob([text], { type: 'text/plain' })
    });
    promise = navigator.clipboard.write([ data ]).then(() => true);
  } else if (shouldUseWriteText) {
    promise = navigator.clipboard.writeText(text).then(() => true);
  } else {
    promise = Promise.resolve(false);
  }

  return promise
    .then((success) => {
      if (!success && DEVELOPMENT) console.warn('Unable to save data to clipboard.');
      return success;
    })
    .catch((e) => {
      if (DEVELOPMENT) console.warn('Unable to save data to clipboard.', e);
      return false;
    });
};

const appliesScaleKeyword = (key: keyof CharacterFormatting) => key === 'scaleX' || key === 'scaleY';

const appliesTextDecorationKeyword = (key: keyof CharacterFormatting) => key === 'underline' || key === 'strikethrough';

const formattingKeyTranslatesToCssPropertyThatCombinesMultipleFormattingKeys = (key: keyof CharacterFormatting): key is FormattingKeyTranslatesToCssPropertyThatCombinesMultipleFormattingKeys => {
  return appliesScaleKeyword(key) || appliesTextDecorationKeyword(key);
};

const formattingPropertyToCSS = (key: FormattingKeyTranslatesToSimpleCssProperty, value: any, character: TextCharacter): string => {
  switch (key) {
    case 'fontFamily': return `font-family: '${value}'`;
    case 'lineheight': return `line-height: ${value}px`;
    case 'baselineShift': return `position: relative; top: ${value}px`;
    case 'letterSpacing': return `letter-spacing: ${character.fontStyle.toPixels(value, character.size)}px`;
    case 'bold': return value ? 'font-weight: bold' : '';
    case 'italic': return value ? 'font-style: italic' : '';
    case 'textCase': {
      switch (value as TextCases) {
        case TextCases.LowerCase: return 'text-transform: lowercase';
        case TextCases.UpperCase: return 'text-transform: uppercase';
        case TextCases.UpperCasePerSentence: return 'text-transform: capitalize-sentence'; // #ourCustom, no CSS property allowing us to transfer this formatting
        case TextCases.UpperCasePerWord: return 'text-transform: capitalize';
        default: return '';
      }
    }
    case 'color': return `color: ${value}`;
    // case 'outline': return '';
    case 'size': return `font-size: ${value}px`;
    default: return '';
  }
};

export const readPastedTextFromClipboard = async () => {
  if (clipboardSupported()) {
    return readTextBlobsFromClipboard();
  } else {
    return readPlainTextFromClipboard();
  }
};

const readTextBlobsFromClipboard = async () => {
  try {
    const clipboardItems = await navigator.clipboard.read();
    for (const item of clipboardItems) {
      let blob;
      if (item.types.includes('text/html')) {
        blob = await item.getType('text/html');
      } else if (item.types.includes('text/plain')) {
        blob = await item.getType('text/plain');
      }
      if (blob) return blob.text();
    }
    return '';
  } catch (error) {
    DEVELOPMENT && console.error('An error occurred when reading "text/html" from clipboard: ', error);
    return readPlainTextFromClipboard();
  }
};

export const readPlainTextFromClipboard = async () => {
  try {
    if (!navigator.clipboard.readText || typeof navigator.clipboard.readText !== 'function') {
      DEVELOPMENT && console.warn('Can\'t readText from clipboard! Aborting paste.');
      return '';
    }
    return navigator.clipboard.readText();
  } catch (e) {
    DEVELOPMENT && console.error('An error occurred when reading plain text from clipboard: ', e);
    return '';
  }
};

export interface RichTextTruncateResults {
  pastedFullText: string;
  pastedTruncatedText: string;
}

export const truncateRichText = (textarea: Textarea, selection: TextSelectionDetailed, newText: string): RichTextTruncateResults => {
  const { start, end } = selection;
  let newTextNoEmojis = sanitizeEmojis(newText);
  let newTextSafe = newTextNoEmojis;
  const newLength = start + (textarea.text.length - end) + newTextSafe.length;
  const truncateLength = Math.max(0, newLength - MAX_TEXTAREA_TEXT_LENGTH);
  if (truncateLength) {
    newTextSafe = newTextSafe.substring(0, newTextSafe.length - truncateLength);
  }
  return {
    pastedFullText: newTextNoEmojis,
    pastedTruncatedText: newTextSafe,
  };
};

export const insertRichText = (textarea: Textarea, selection: TextSelectionDetailed, parsed: HTMLDivElement) => {
  textarea.formattingToApplyOnNextInput = undefined;
  const plainText = htmlToPlainText(parsed);
  const truncateResults = truncateRichText(textarea, selection, plainText);
  const newSelection = insertCharactersIntoTextarea(textarea, selection, truncateResults.pastedTruncatedText);
  const localFormattings = htmlToLocalFormattings(textarea, parsed, truncateResults);
  const { characterFormattings, paragraphFormattings } = globalizeLocalFormattings(localFormattings, selection);
  characterFormattings.forEach(f => textarea.formatCharacters(f, false));
  paragraphFormattings.forEach(f => textarea.formatParagraph(f, false));
  textarea.write(textarea.text);
  return newSelection;
};

export const parseTextboxHtml = (str: string) => {
  try {
    const parser = new DOMParser();
    const struct = parser.parseFromString(str, 'text/html');
    const root = struct.getElementsByTagName(WRAPPER_TAG)[0];

    struct.querySelectorAll('meta, script, .Apple-converted-space').forEach(e => e.remove());

    if (struct.children.length !== 1) return undefined;
    if (!root || root.tagName !== WRAPPER_TAG.toUpperCase()) return undefined;

    for (let i = 0; i < root.childNodes.length; i++) {
      const child = root.childNodes[i];

      const nodeName = child.nodeName.toLowerCase();
      switch (nodeName) {
        case 'p': {
          const pTagChildren = Array.from((child as HTMLParagraphElement).childNodes);
          if (pTagChildren.some(c => (!isSpanNode(c) && !isTextNode(c)))) {
            DEVELOPMENT && console.warn('Attempted to paste unrecognized HTML structure. Make sure pasted rich text is recognizable format', root.childNodes);
            return undefined;
          }
          break;
        }
        case 'span': {
          const spanTagChildren = Array.from((child as HTMLSpanElement).childNodes);
          if (spanTagChildren.some(c => !isTextNode(c))) {
            DEVELOPMENT && console.warn('Attempted to paste unrecognized HTML structure. Make sure pasted rich text is recognizable format', root.childNodes);
            return undefined;
          }
          break;
        }
        case '#text': {
          // allow plain text nodes, they will inherit current style in that place since they don't bring their own
          // this will most likely not happen on copy from magma itself (there's always span there)
          break;
        }
        case 'parsererror': {
          const errorValue = getErrorFromParserErrorNode(child);
          DEVELOPMENT && console.warn('Unable to parse pasted HTML (encountered "parsererror"):', errorValue);
          return undefined;
        }
        default: {
          DEVELOPMENT && console.warn('Attempted to paste unrecognized HTML structure. Make sure pasted rich text is recognizable format', root.childNodes);
          return undefined;
        }
      }
    }

    return root as HTMLDivElement;
  } catch (e) {
    return undefined;
  }
};

const isParagraphNode = (node: Node): node is HTMLParagraphElement => node.nodeName.toLowerCase() === 'p';
const isSpanNode = (node: Node): node is HTMLSpanElement => node.nodeName.toLowerCase() === 'span';
const isTextNode = (node: Node): node is Text => node.nodeName === '#text' && node.nodeType === 3;
const getErrorFromParserErrorNode = (parserErrorNode: Node) => ((parserErrorNode.childNodes.item(1) as HTMLElement)?.innerText || '');

export const htmlToPlainText = (parsedRoot: HTMLDivElement): string => {
  return Array.from(parsedRoot.childNodes).map((child) => {
    if (isParagraphNode(child)) return paragraphNodeToPlainText(child);
    else if (isSpanNode(child)) return spanNodeToPlainText(child);
    else if (isTextNode(child)) return textNodeToPlainText(child);
    else return '';
  }).join('');
};
const paragraphNodeToPlainText = (p: HTMLParagraphElement): string => {
  return [ PARAGRAPH_SPLIT_CHARACTER, ...Array.from(p.childNodes).map((child) => {
    if (isSpanNode(child)) return spanNodeToPlainText(child);
    else if (isTextNode(child)) return textNodeToPlainText(child);
    else return '';
  }) ].join('');
};
const spanNodeToPlainText = (span: HTMLSpanElement): string => (span.textContent || '');
const textNodeToPlainText = (text: Text): string => (text.textContent || '');

const htmlToLocalFormattings = (textarea: Textarea, parsedRoot: HTMLDivElement, truncateResults: RichTextTruncateResults): ParsedTextFormattings => {
  const characterFormattings: CharacterFormattingDescription[] = [];
  const paragraphFormattings: ParagraphFormattingDescription[] = [];

  let previousGlyphs = 0;
  let previousParagraphs = 0;

  function textNodeToLocalFormattings(text: Text) {
    previousGlyphs += textNodeToPlainText(text).length;
  }

  function spanNodeToLocalFormattings(span: HTMLSpanElement) {
    const textLength = spanNodeToPlainText(span).length;
    const formatting: CharacterFormattingDescription = {
      start: previousGlyphs,
      length: textLength
    };
    const characterFormatting = spanToCharacterFormatting(textarea, formatting, span);
    previousGlyphs += textLength;
    if (characterFormatting) characterFormattings.push(characterFormatting);
  }

  function paragraphNodeToLocalFormattings(p: HTMLParagraphElement) {
    const paragraphFormatting = paragraphToParagraphFormatting(p, previousParagraphs);
    if (paragraphFormatting) paragraphFormattings.push(paragraphFormatting);
    previousParagraphs++;

    previousGlyphs++; // '\n' character

    const paragraphChildren = Array.from(p.childNodes);
    for (const pChild of paragraphChildren) {
      if (isTextNode(pChild)) textNodeToLocalFormattings(pChild);
      else if (isSpanNode(pChild)) spanNodeToLocalFormattings(pChild);
    }
  }

  const children = Array.from(parsedRoot.childNodes);
  for (const child of children) {
    if (isParagraphNode(child)) paragraphNodeToLocalFormattings(child);
    if (isSpanNode(child)) spanNodeToLocalFormattings(child);
    if (isTextNode(child)) textNodeToLocalFormattings(child);
  }

  const { pastedFullText, pastedTruncatedText } = truncateResults;
  let truncateLength = pastedFullText.length - pastedTruncatedText.length;
  let i = pastedFullText.length - 1;
  let truncatedCharacterFormatting = characterFormattings[characterFormattings.length - 1];
  let truncatedParagraphFormatting = paragraphFormattings[paragraphFormattings.length - 1];
  while (truncateLength) {
    if (truncatedCharacterFormatting && i >= truncatedCharacterFormatting.start && i < truncatedCharacterFormatting.start + truncatedCharacterFormatting.length) {
      truncatedCharacterFormatting.length--;
      if (!truncatedCharacterFormatting.length) {
        characterFormattings.pop();
        truncatedCharacterFormatting = characterFormattings[characterFormattings.length - 1];
      }
    }

    if (truncatedParagraphFormatting && pastedFullText[i] === '\n') {
      paragraphFormattings.pop();
      truncatedParagraphFormatting = paragraphFormattings[paragraphFormattings.length - 1];
    }

    i--;
    truncateLength--;
  }

  return { characterFormattings, paragraphFormattings };
};

const globalizeLocalFormattings = (localFormattings: ParsedTextFormattings, selection: TextSelectionDetailed): ParsedTextFormattings => ({
  characterFormattings: localFormattings.characterFormattings.map(cf => {
    return { ...cf, start: cf.start += selection.start };
  }),
  paragraphFormattings: localFormattings.paragraphFormattings.map(pf => {
    return { ...pf, index: selection.paragraphIndexes[0] + pf.index };
  }),
});

const paragraphFormattingToStyleAttribute = (paragraph: Paragraph): string => {
  const cssRules = [];
  for (const [key, value] of Object.entries(paragraph.formatting)) {
    switch (key as keyof ParagraphFormatting) {
      case 'alignment': {
        if (paragraph.isJustified) cssRules.push('text-align: justify');
        switch (value as TextAlignment) {
          case TextAlignment.LeftAligned: cssRules.push('text-align: left'); break;
          case TextAlignment.CenterAligned: cssRules.push('text-align: center'); break;
          case TextAlignment.RightAligned: cssRules.push('text-align: right'); break;
          case TextAlignment.LeftJustified: cssRules.push('text-align-last: left'); break;
          case TextAlignment.CenterJustified: cssRules.push('text-align-last: center'); break;
          case TextAlignment.RightJustified: cssRules.push('text-align-last: right'); break;
        }
        break;
      }
    }
  }
  return cssRules.join('; ');
};

const paragraphToParagraphFormatting = (p: HTMLParagraphElement, index: number): ParagraphFormattingDescription | undefined => {
  const formatting: ParagraphFormattingDescription = { index };
  const style = p.style;

  if (style.textAlign) {
    switch (style.textAlign) {
      case 'left': formatting.alignment = TextAlignment.LeftAligned; break;
      case 'center': formatting.alignment = TextAlignment.CenterAligned; break;
      case 'right': formatting.alignment = TextAlignment.RightAligned; break;
      case 'justify': {
        const textAlignLast = style.textAlignLast;
        if (textAlignLast === 'left') {
          formatting.alignment = TextAlignment.LeftJustified;
        } else if (textAlignLast === 'center') {
          formatting.alignment = TextAlignment.CenterJustified;
        } else if (textAlignLast === 'right') {
          formatting.alignment = TextAlignment.RightJustified;
        } else {
          formatting.alignment = TextAlignment.FullyJustified;
        }
        break;
      }
    }
  }

  return isValidParagraphFormatting(formatting) ? formatting : undefined;
};

const spanToCharacterFormatting = (textarea: Textarea, formattingBase: TextRange, span: HTMLSpanElement): CharacterFormattingDescription | undefined => {
  const formatting: CharacterFormattingDescription = { ...formattingBase };
  const style = span.style;

  switch (style.textTransform) {
    case 'lowercase': formatting.textCase = TextCases.LowerCase; break;
    case 'uppercase': formatting.textCase = TextCases.UpperCase; break;
    case 'capitalize': formatting.textCase = TextCases.UpperCasePerWord; break;
    case 'capitalize-sentence': formatting.textCase = TextCases.UpperCasePerSentence; break;
    default: formatting.textCase = TextCases.NoCaseModification; break; // need to set it to not make it inherit from pasted place
  }

  if (style.textDecoration) {
    formatting.strikethrough = style.textDecoration.includes('line-through');
    formatting.underline = style.textDecoration.includes('underline');
  }

  if (style.fontFamily && textarea.fontFamilies.get(style.fontFamily)) {
    formatting.fontFamily = style.fontFamily;
  }

  formatting.bold = (style.fontWeight === 'bold' || style.fontWeight === '900');
  formatting.italic = (style.fontStyle === 'italic' || style.fontStyle === 'oblique');

  if (style.fontSize.endsWith('px')) {
    const n = parseFloat(style.fontSize);
    if (Number.isFinite(n)) formatting.size = n;
  }

  if (style.lineHeight.endsWith('px')) {
    const n = parseFloat(style.lineHeight);
    if (Number.isFinite(n)) formatting.lineheight = n;
  }

  if (style.top.endsWith('px') && style.position === 'relative') {
    const n = parseFloat(style.top);
    if (Number.isFinite(n)) formatting.baselineShift = n;
  }

  if (style.scale && style.display === 'inline-block') {
    const [scaleX = undefined, scaleY = undefined] = style.scale.split(' ');
    if (scaleX && Number.isFinite(+scaleX)) formatting.scaleX = +scaleX;
    if (scaleY && Number.isFinite(+scaleY)) formatting.scaleY = +scaleY;
  }

  formatting.color = `#${colorToHexRGB(parseColor(style.color))}`;

  if (style.letterSpacing.endsWith('px')) {
    // it's important this one is called last (or at least after properties controlling font-style like: 'size', 'bold', 'italic', 'fontFamily' keys)
    // because it needs to know what font style to use to calculate convertion back from pixels to font units (since we operate on these)
    const n = parseFloat(style.letterSpacing);
    if (Number.isFinite(n)) {
      const fontStyle = textarea.getFontStyle(formatting);
      formatting.letterSpacing = Math.round(fontStyle.fromPixels(n, formatting.size || DEFAULT_CHARACTER_FORMATTING.size));
    }
  }

  return isValidCharacterFormatting(formatting) ? formatting : undefined;
};

const enrichCharacterFormatting = (formatting: CharacterFormatting): Required<CharacterFormatting> => {
  // Careful with types here!
  // This is not valid CharacterFormatting and applying for example
  // "bold: false" in normal operations will result in not accepting formatting
  // but this makes sure all the properties are present when serializing,
  // so pasted text will not inherit non-specified properties from pasted place
  // when parsing all of faulty keywords will be omitted
  return {
    bold: false,
    italic: false,
    strikethrough: false,
    underline: false,
    fontFamily: '',
    lineheight: AUTO_SETTING_STRING,
    ...DEFAULT_CHARACTER_FORMATTING,
    ...formatting,
  } as Required<CharacterFormatting>;
};

const characterFormattingToSpanStyleAttribute = (character: TextCharacter) => {
  const formattingEntries = Object.entries(enrichCharacterFormatting(character.formatting)) as [keyof CharacterFormattingDescription, any][];
  const formattableProperties = formattingEntries.filter(([key, value]) => isNonRangeCharacterFormattingKey(key) && value !== AUTO_SETTING_STRING) as [keyof CharacterFormatting, any][];

  let styleAttribute = '';
  let scaleFormattingAppended = false;
  let textDecorationFormattingAppended = false;

  formattableProperties.forEach(([key, value], index, { length }) => {
    const first = index === 0; const last = index === length - 1;
    const space = (!first ? ' ' : ''); const semi = (!last ? ';' : '');
    const multiKey = formattingKeyTranslatesToCssPropertyThatCombinesMultipleFormattingKeys(key);
    if (!multiKey) {
      const cssRule = formattingPropertyToCSS(key, value, character);
      if (cssRule) styleAttribute += space + cssRule + semi;
    } else {
      if (!scaleFormattingAppended && appliesScaleKeyword(key)) {
        styleAttribute += space + `display: inline-block; scale: ${character.formatting.scaleX} ${character.formatting.scaleY}` + semi;
        scaleFormattingAppended = true;
      }
      if (!textDecorationFormattingAppended && appliesTextDecorationKeyword(key)) {
        styleAttribute += space + `text-decoration:` + (character.formatting.underline ? ' underline' : '') + (character.formatting.strikethrough ? ' line-through' : '') + semi;
        textDecorationFormattingAppended = true;
      }
    }
  });

  return styleAttribute;
};

export function displayToastAboutNotSupportedClipboard(editor: IToolEditor, operation: 'Pasting' | 'Cutting' | 'Copying') {
  if (SERVER) return;
  (editor as Editor).toast((toastService) => {
    toastService.warning({
      message: `${operation} text unsuccessful, check browsers permission to access clipboard.`,
      timeout: 3000,
    });
  });
}
