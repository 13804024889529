import { ITool, IToolEditor, IToolModel, CompositeOp, ToolId, CursorType } from '../interfaces';
import { PaintBrush, PaintBrushMode } from '../paintBrush';
import { BaseBrushTool } from './baseBrushTool';
import { BRUSH_FIELDS, IBrushToolData } from './brushUtils';
import { faEraser } from '../icons';
import { DEFAULT_STABILIZE, WHITE } from '../constants';
import { keys } from '../baseUtils';

export function safeBrushMode(value: unknown): PaintBrushMode {
  return value === PaintBrushMode.Brush ? PaintBrushMode.Brush : PaintBrushMode.Pencil;
}

export class EraserTool extends BaseBrushTool implements ITool {
  id = ToolId.Eraser;
  name = 'Eraser';
  description = 'Erase selected part of your drawing';
  learnMore = 'https://help.magma.com/en/articles/6871489-eraser';
  video = { url: '/assets/videos/eraser.mp4', width: 374, height: 210 };
  icon = faEraser;
  // cursor = 'cursor-eraser'; // needs to be none so it's hidden
  stabilize = DEFAULT_STABILIZE;
  fields = keys<EraserTool>([...BRUSH_FIELDS, 'mode']);
  private _mode = PaintBrushMode.Brush;
  constructor(public editor: IToolEditor, public model: IToolModel) {
    super(editor, model);
    this.compositeOperation = CompositeOp.Erase;
  }
  get mode() {
    return this._mode;
  }
  set mode(value) {
    this._mode = value;
  }
  protected setupBrush(brush: PaintBrush) {
    super.setupBrush(brush);
    brush.setMode(this.mode);
  }
  protected getData() {
    const data = super.getData();
    data[1] = this.mode; // use color field to store eraser mode
    return data;
  }
  protected setData(data?: IBrushToolData) {
    super.setData(data);
    this.mode = data ? safeBrushMode(data.p[1]) : this.mode;
    this.color = WHITE;
  }
  get cursor(): CursorType { return this._mode === PaintBrushMode.Brush ? this.shape ? CursorType.Image : CursorType.Circle : CursorType.Square; }
}
