import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { resetStores } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SECOND } from 'magma/common/constants';
import { CreateAccountData, OauthProfile, SurveyId } from 'magma/common/interfaces';
import { storageGetItem, storageRemoveItem, storageSetItem } from 'magma/services/storage';
import { lastValueFrom, NEVER, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { ApiKeyData, ChangeEmail, ChangePassword, InitEmail, UpdateProfile, UserData } from 'shared/interfaces';
import { handleHttpError, toPromise } from 'shared/utils';
import { setUserContextForIntegrations } from '../helpers/integrations';
import { AuthService } from './auth.service';
import { ErrorReporterService } from './error-reporter.service';
import { TeamsQuery } from './team.query';
import { UserQuery } from './user.query';
import { UserStore } from './user.store';
import { TeamData } from '../../../shared/interfaces';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private userQuery: UserQuery,
    private userStore: UserStore,
    private teamsQuery: TeamsQuery,
    private router: Router,
    private errorReporter: ErrorReporterService,
  ) {
    this.userStore.setLoading(true);

    this.user$.pipe(
      untilDestroyed(this),
    ).subscribe(user => {
      this.errorReporter.user = user ? { name: user.name, id: user._id } : undefined;
      void setUserContextForIntegrations(user);
    });

    this.authService.token$.pipe(
      distinctUntilChanged(),
      switchMap(token => token ? this.authService.refresh() : of({})),
      untilDestroyed(this),
    ).subscribe(() => {
      this.userStore.setLoading(false);
    });

    this.user$.pipe(
      map(user => user?._id),
      distinctUntilChanged(),
      switchMap(userId => userId ? this.teamsQuery.selectActive() : NEVER),
      map(team => team?.slug ?? ''),
      debounceTime(SECOND),
      distinctUntilChanged(),
      // make sure user is still logged-in
      switchMap(lastTeam => this.userQuery.getValue().user ? this.httpClient.post('/api/profile/last-team', { lastTeam }).pipe(handleHttpError()) : NEVER),
      untilDestroyed(this),
    ).subscribe();
  }

  user$ = this.userQuery.selectLoading().pipe(
    filter(isLoading => !isLoading),
    switchMap(() => this.userQuery.select(state => state.user)),
  );

  get user() {
    return this.userQuery.getValue().user || null;
  }

  get permissions() {
    return { save: IS_HOSTED || this.user !== null };
  }

  get userId() {
    return this.user?._id;
  }

  isSuperAdmin() {
    return !!this.user?.isSuperAdmin;
  }

  get() {
    if (this.authService.loggedIn) {
      return this.authService.refresh();
    } else {
      return of(null);
    }
  }

  updateUser(update: Partial<UserData>) {
    this.userStore.update(s => ({ ...s, user: s.user ? { ...s.user, ...update } : undefined }));
  }

  async createOauthCode(client_id: string, state: string, redirect_uri: string) {
    return await toPromise(this.httpClient.post<string>('/api/oauth/code', { client_id, state, redirect_uri }));
  }

  async save(user: UpdateProfile) {
    await toPromise(this.httpClient.post<UserData>('/api/profile', user));
    await this.authService.refresh().toPromise();
  }

  initEmail(data: InitEmail) {
    return toPromise(this.httpClient.post<void>('/api/profile/init-email', data));
  }

  resendEmailVerification() {
    return toPromise(this.httpClient.post<void>('/api/profile/resend-verify-email', {}));
  }

  resetPassword(email: string) {
    return toPromise(this.httpClient.post<void>('/reset-password', { email }));
  }

  async changeEmail(data: ChangeEmail) {
    await toPromise(this.httpClient.post<UserData>('/api/profile/change-email', data));
    await this.authService.refresh().toPromise();
  }

  changePassword(data: ChangePassword) {
    return toPromise(this.httpClient.post<UserData>('/api/profile/change-password', data));
  }

  async pendingProfile(): Promise<OauthProfile | undefined> {
    const result = await toPromise(this.httpClient.get<{ pendingProfile?: OauthProfile }>('/api/profile/pending'));
    return result.pendingProfile;
  }

  clearPendingProfile(): Promise<void> {
    return toPromise(this.httpClient.delete<void>('/api/profile/pending'));
  }

  createProfile(data: CreateAccountData) {
    return toPromise(this.httpClient.post<void>('/api/profile/create', data));
  }

  async tag(tag: string) {
    await toPromise(this.httpClient.post<void>('/api/profile/tag', { tag }));
    this.updateUser({ tags: [...(this.user?.tags ?? []), tag] });
  }

  async startTrial(trial: string) {
    const trials = await toPromise(this.httpClient.post<{ [key: string]: string; }>('/api/profile/start-trial', { trial }));
    this.updateUser({ trials });
  }

  async surveyDone(surveyId: SurveyId) {
    await toPromise(this.httpClient.post<void>('/api/profile/survey-done', { surveyId }));
    this.updateUser({ npsSurvey: undefined });
  }

  async impersonateUser(idOrEmail: string) {
    const superAdminToken = this.authService.getToken();
    const data = await toPromise(this.httpClient.post<{ token: string }>('/api/superadmin/impersonate', { idOrEmail }));
    await this.router.navigate(['_loading'], { skipLocationChange: true });
    resetStores();
    this.authService.setToken(data.token);
    await this.authService.refresh().toPromise();
    storageSetItem('superAdminLastUrl', window.location.href);
    storageSetItem('superAdminToken', superAdminToken!);
    window.location.href = '/';
  }

  async endImpersonation() {
    if (!this.superAdminToken) {
      throw new Error('Not impersonating');
    }
    resetStores();
    void this.router.navigate(['_loading'], { skipLocationChange: true });
    this.authService.setToken(this.superAdminToken);
    const lastUrl = storageGetItem('superAdminLastUrl');
    storageRemoveItem('superAdminToken');
    storageRemoveItem('superAdminLastUrl');
    window.location.href = lastUrl || '/';
  }

  get superAdminToken() {
    return storageGetItem('superAdminToken');
  }

  getApiKeys() {
    return this.httpClient.get<ApiKeyData[]>('/api/api-keys');
  }

  generateApiKey() {
    return this.httpClient.post<ApiKeyData>('/api/api-keys', { name: 'default' });
  }

  regenerateApiKey(id: string) {
    return this.httpClient.put<ApiKeyData>(`/api/api-keys/${id}`, null);
  }

  revokeApiKey(id: string) {
    return this.httpClient.delete<boolean>(`/api/api-keys/${id}`);
  }

  getUserCannyToken() {
    return this.httpClient.get<string>('/api/canny');
  }

  reorderArtspaces(newOrder: TeamData[]) {
    return lastValueFrom(this.httpClient.post('/api/profile/reorder-artspaces', {
      artspacesOrder: newOrder.map(t => t._id)
    }));
  }
}
