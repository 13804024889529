import { voiceChatUrl } from '../common/data';
import { injectionTokenBase } from '../common/di';
import type { ArtworkLicensingData, DrawingPermissions, UserRole } from '../common/interfaces';
import { hasPermission } from '../common/userRole';
import type { ClientActions } from './clientActions';
import type { RealModel } from './real-model';

export abstract class Model extends injectionTokenBase<RealModel>() { }

export function userHasPermission(model: Model, user: { role: UserRole; isSuperAdmin: boolean; }, key: keyof DrawingPermissions) {
  return hasPermission(model.editor!.drawing, user, key);
}

export function havePermission(model: Model, key: keyof DrawingPermissions) {
  return userHasPermission(model, model.user, key);
}

export function isActiveClientActions(model: Model, clientActions: ClientActions) {
  return model.connectionService.sockets[0] === clientActions.socket;
}

export function hasVoiceChat(model: Model) {
  if (!voiceChatUrl) return false;

  return IS_HOSTED || model.voiceChat.session || !!model.user.pro;
}

export function updateAndBroadcastDrawingLicense(model: Model, license: ArtworkLicensingData | undefined) {
  if (model.editor) {
    model.server.licenseArtwork(model.connId, license).catch(e => DEVELOPMENT && console.error(e));
    model.editor.drawing.licensing = license;
  }
}
