import { addPolySegmentPoint, createPolySegment, clearPoly, createPoly, movePoly } from './poly';
import { Poly, PolySegment } from './interfaces';

export const enum ClipType { Intersection, Union, Difference, Xor }
const enum PolyType { Subject, Clip }
const enum EdgeSide { Left, Right }
const enum Direction { RightToLeft, LeftToRight }

// By far the most widely used winding rules for polygon filling are
// EvenOdd & NonZero (GDI, GDI+, XLib, OpenGL, Cairo, AGG, Quartz, SVG, Gr32)
// Others rules include Positive, Negative and ABS_GTR_EQ_TWO (only in OpenGL)
// see http://glprogramming.com/red/chapter11.html
const enum PolyFillType { EvenOdd, NonZero, Positive, Negative }

type double = number;

class TEdge {
  botX = 0;
  botY = 0;
  currX = 0;
  currY = 0;
  topX = 0;
  topY = 0;
  deltaX = 0;
  deltaY = 0;
  dx: double = 0;
  polyTyp = PolyType.Subject;
  side = EdgeSide.Left;
  windDelta = 0; //1 or -1 depending on winding direction
  windCnt = 0;
  windCnt2 = 0; //winding count of the opposite polytype
  outIdx = 0;
  next: TEdge | null = null;
  prev: TEdge | null = null;
  nextInLML: TEdge | null = null;
  nextInAEL: TEdge | null = null;
  prevInAEL: TEdge | null = null;
  nextInSEL: TEdge | null = null;
  prevInSEL: TEdge | null = null;
}

class IntersectNode {
  edge1: TEdge | null = null;
  edge2: TEdge | null = null;
  ptX = 0;
  ptY = 0;
}

class LocalMinima {
  y = 0;
  leftBound: TEdge | null = null;
  rightBound: TEdge | null = null;
  next: LocalMinima | null = null;
}

class Scanbeam {
  y = 0;
  next: Scanbeam | null = null;
}

class OutRec {
  idx = 0;
  isHole = false;
  isOpen = false;
  firstLeft: OutRec | null = null; //see comments in clipper.pas
  pts: OutPt | null = null;
  bottomPt: OutPt | null = null;
}

class OutPt {
  idx = 0;
  ptX = 0;
  ptY = 0;
  next: OutPt | null = null;
  prev: OutPt | null = null;
}

interface Join {
  outPt1: OutPt;
  outPt2: OutPt;
  offPtX: number;
  offPtY: number;
}

function createJoin(outPt1: OutPt, outPt2: OutPt, offPtX: number, offPtY: number): Join {
  return { outPt1, outPt2, offPtX, offPtY };
}

const loRange = 0x7FFF;
const hiRange = 0x7FFF;
const Unassigned = -1;
const Skip = -2;
const horizontal = -3.4E+38;

function xor(a: boolean, b: boolean) {
  return (a && !b) || (!a && b);
}

function compareNodes(a: IntersectNode, b: IntersectNode) {
  const i = (b.ptY - a.ptY) | 0;

  if (i > 0) {
    return 1;
  } else if (i < 0) {
    return -1;
  } else {
    return 0;
  }
}

interface Clipper {
  executeLocked: boolean;
  useFullRange: boolean;
  hasOpenPaths: boolean;
  clipType: ClipType;
  scanbeam: Scanbeam | null;
  activeEdges: TEdge | null;
  sortedEdges: TEdge | null;
  clipFillType: PolyFillType;
  subjFillType: PolyFillType;
  joins: Join[];
  ghostJoins: Join[];
  minimaList: LocalMinima | null;
  currentLM: LocalMinima | null;
  edges: TEdge[][];
  usingPolyTree: boolean;
  polyOuts: OutRec[];
  intersectList: IntersectNode[];
  preserveCollinear: boolean;
  reverseSolution: boolean;
  strictlySimple: boolean;
}

function createClipper(): Clipper {
  return {
    executeLocked: false,
    useFullRange: false,
    hasOpenPaths: false,
    clipType: ClipType.Intersection,
    scanbeam: null,
    activeEdges: null,
    sortedEdges: null,
    clipFillType: PolyFillType.EvenOdd,
    subjFillType: PolyFillType.EvenOdd,
    joins: [],
    ghostJoins: [],
    minimaList: null,
    currentLM: null,
    edges: [],
    usingPolyTree: false,
    polyOuts: [],
    intersectList: [],
    preserveCollinear: false,
    reverseSolution: false,
    strictlySimple: false,
  };
}

function Reset(self: Clipper) {
  self.currentLM = self.minimaList;

  if (self.currentLM === null)
    return; //ie nothing to process

  //reset all edges ...
  var lm = self.minimaList;

  while (lm !== null) {
    var e = lm.leftBound;

    if (e !== null) {
      e.currX = e.botX;
      e.currY = e.botY;
      e.side = EdgeSide.Left;
      e.outIdx = Unassigned;
    }

    e = lm.rightBound;

    if (e !== null) {
      e.currX = e.botX;
      e.currY = e.botY;
      e.side = EdgeSide.Right;
      e.outIdx = Unassigned;
    }

    lm = lm.next;
  }

  self.scanbeam = null;
  self.activeEdges = null;
  self.sortedEdges = null;

  lm = self.minimaList;

  while (lm !== null) {
    InsertScanbeam(self, lm.y);
    lm = lm.next;
  }
}

function InsertScanbeam(self: Clipper, Y: number) {
  if (self.scanbeam === null) {
    self.scanbeam = new Scanbeam();
    self.scanbeam.next = null;
    self.scanbeam.y = Y;
  } else if (Y > self.scanbeam.y) {
    let newSb = new Scanbeam();
    newSb.y = Y;
    newSb.next = self.scanbeam;
    self.scanbeam = newSb;
  } else {
    var sb2 = self.scanbeam;

    while (sb2.next !== null && (Y <= sb2.next.y))
      sb2 = sb2.next;

    if (Y === sb2.y)
      return; //ie ignores duplicates

    let newSb = new Scanbeam();
    newSb.y = Y;
    newSb.next = sb2.next;
    sb2.next = newSb;
  }
}

function ProcessBound(self: Clipper, E: TEdge, LeftBoundIsForward: boolean): TEdge {
  var EStart: TEdge;
  var Horz: TEdge;
  var Result = E;

  if (Result.outIdx === Skip) {
    //check if there are edges beyond the skip edge in the bound and if so
    //create another LocMin and calling ProcessBound once more ...
    E = Result;

    if (LeftBoundIsForward) {
      while (E.topY === E.next!.botY) E = E.next!;
      while (E !== Result && E.dx === horizontal) E = E.prev!;
    } else {
      while (E.topY === E.prev!.botY) E = E.prev!;
      while (E !== Result && E.dx === horizontal) E = E.next!;
    }

    if (E === Result) {
      if (LeftBoundIsForward)
        Result = E.next!;
      else
        Result = E.prev!;
    } else {
      //there are more edges in the bound beyond result starting with E
      if (LeftBoundIsForward)
        E = Result.next!;
      else
        E = Result.prev!;

      var locMin = new LocalMinima();
      locMin.next = null;
      locMin.y = E.botY;
      locMin.leftBound = null;
      locMin.rightBound = E;
      E.windDelta = 0;
      Result = ProcessBound(self, E, LeftBoundIsForward);
      InsertLocalMinima(self, locMin);
    }

    return Result;
  }

  if (E.dx === horizontal) {
    //We need to be careful with open paths because this may not be a
    //true local minima (ie E may be following a skip edge).
    //Also, consecutive horz. edges may start heading left before going right.
    if (LeftBoundIsForward)
      EStart = E.prev!;
    else
      EStart = E.next!;

    if (EStart.outIdx !== Skip) {
      if (EStart.dx === horizontal) { //ie an adjoining horizontal skip edge
        if (EStart.botX !== E.botX && EStart.topX !== E.botX)
          ReverseHorizontal(E);
      } else if (EStart.botX !== E.botX) {
        ReverseHorizontal(E);
      }
    }
  }

  EStart = E;

  if (LeftBoundIsForward) {
    while (Result.topY === Result.next!.botY && Result.next!.outIdx !== Skip)
      Result = Result.next!;

    if (Result.dx === horizontal && Result.next!.outIdx !== Skip) {
      //nb: at the top of a bound, horizontals are added to the bound
      //only when the preceding edge attaches to the horizontal's left vertex
      //unless a Skip edge is encountered when that becomes the top divide
      Horz = Result;

      while (Horz.prev!.dx === horizontal)
        Horz = Horz.prev!;

      if (Horz.prev!.topX === Result.next!.topX) {
        if (!LeftBoundIsForward)
          Result = Horz.prev!;
      } else if (Horz.prev!.topX > Result.next!.topX) {
        Result = Horz.prev!;
      }
    }

    while (E !== Result) {
      E.nextInLML = E.next;

      if (E.dx === horizontal && E !== EStart && E.botX !== E.prev!.topX)
        ReverseHorizontal(E);

      E = E.next!;
    }

    if (E.dx === horizontal && E !== EStart && E.botX !== E.prev!.topX)
      ReverseHorizontal(E);

    Result = Result.next!; //move to the edge just beyond current bound
  } else {
    while (Result.topY === Result.prev!.botY && Result.prev!.outIdx !== Skip)
      Result = Result.prev!;

    if (Result.dx === horizontal && Result.prev!.outIdx !== Skip) {
      Horz = Result;

      while (Horz.next!.dx === horizontal)
        Horz = Horz.next!;

      if (Horz.next!.topX === Result.prev!.topX) {
        if (!LeftBoundIsForward)
          Result = Horz.next!;
      } else if (Horz.next!.topX > Result.prev!.topX) {
        Result = Horz.next!;
      }
    }

    while (E !== Result) {
      E.nextInLML = E.prev;

      if (E.dx === horizontal && E !== EStart && E.botX !== E.next!.topX)
        ReverseHorizontal(E);

      E = E.prev!;
    }

    if (E.dx === horizontal && E !== EStart && E.botX !== E.next!.topX)
      ReverseHorizontal(E);

    Result = Result.prev!; //move to the edge just beyond current bound
  }

  return Result;
}

function InsertLocalMinima(self: Clipper, newLm: LocalMinima) {
  if (self.minimaList === null) {
    self.minimaList = newLm;
  } else if (newLm.y >= self.minimaList.y) {
    newLm.next = self.minimaList;
    self.minimaList = newLm;
  } else {
    var tmpLm = self.minimaList;

    while (tmpLm.next !== null && (newLm.y < tmpLm.next.y))
      tmpLm = tmpLm.next;

    newLm.next = tmpLm.next;
    tmpLm.next = newLm;
  }
}

function DoSimplePolygons(self: Clipper) {
  var i = 0;

  while (i < self.polyOuts.length) {
    var outrec = self.polyOuts[i++];
    var op = outrec.pts!;

    if (op === null || outrec.isOpen)
      continue;

    do { // for each Pt in Polygon until duplicate found do ...
      var op2 = op.next!;

      while (op2 !== outrec.pts) {
        if ((op!.ptX === op2.ptX && op!.ptY === op2.ptY) && op2.next !== op && op2.prev !== op) {
          //split the polygon into two ...
          var op3 = op!.prev;
          var op4 = op2.prev;
          op!.prev = op4;
          op4!.next = op;
          op2.prev = op3;
          op3!.next = op2;

          outrec.pts = op;
          var outrec2 = CreateOutRec(self);
          outrec2.pts = op2;
          UpdateOutPtIdxs(outrec2);

          if (Poly2ContainsPoly1(outrec2.pts!, outrec.pts)) {
            //OutRec2 is contained by OutRec1 ...
            outrec2.isHole = !outrec.isHole;
            outrec2.firstLeft = outrec;

            if (self.usingPolyTree)
              FixupFirstLefts2(self, outrec2, outrec);
          } else {
            if (Poly2ContainsPoly1(outrec.pts!, outrec2.pts)) {
              //OutRec1 is contained by OutRec2 ...
              outrec2.isHole = outrec.isHole;
              outrec.isHole = !outrec2.isHole;
              outrec2.firstLeft = outrec.firstLeft;
              outrec.firstLeft = outrec2;

              if (self.usingPolyTree)
                FixupFirstLefts2(self, outrec, outrec2);
            } else {
              //the 2 polygons are separate ...
              outrec2.isHole = outrec.isHole;
              outrec2.firstLeft = outrec.firstLeft;

              if (self.usingPolyTree)
                FixupFirstLefts1(self, outrec, outrec2);
            }
          }

          op2 = op; //ie get ready for the next iteration
        }
        op2 = op2.next!;
      }
      op = op.next!;
    } while (op !== outrec.pts);
  }
}

function FixupFirstLefts1(self: Clipper, OldOutRec: OutRec, NewOutRec: OutRec) {
  for (var i = 0; i < self.polyOuts.length; i++) {
    var outRec = self.polyOuts[i];

    if (outRec.pts === null || outRec.firstLeft === null)
      continue;

    var firstLeft = ParseFirstLeft(outRec.firstLeft);

    if (firstLeft === OldOutRec) {
      if (Poly2ContainsPoly1(outRec.pts, NewOutRec.pts!))
        outRec.firstLeft = NewOutRec;
    }
  }
}

function FixupFirstLefts2(self: Clipper, OldOutRec: OutRec, NewOutRec: OutRec) {
  for (var outRec of self.polyOuts) {
    if (outRec.firstLeft === OldOutRec)
      outRec.firstLeft = NewOutRec;
  }
}

function CreateOutRec(self: Clipper): OutRec {
  var result = new OutRec();
  result.idx = Unassigned;
  result.isHole = false;
  result.isOpen = false;
  result.firstLeft = null;
  result.pts = null;
  result.bottomPt = null;
  self.polyOuts.push(result);
  result.idx = self.polyOuts.length - 1;
  return result;
}

function FixupOutPolygon(self: Clipper, outRec: OutRec) {
  //FixupOutPolygon() - removes duplicate points and simplifies consecutive
  //parallel edges by removing the middle vertex.
  var lastOK: OutPt | null = null;
  outRec.bottomPt = null;
  var pp = outRec.pts!;

  while (true) {
    if (pp.prev === pp || pp.prev === pp.next) {
      outRec.pts = null;
      return;
    }
    //test for duplicate points and collinear edges ...
    if ((pp.ptX === pp.next!.ptX && pp.ptY === pp.next!.ptY) || (pp.ptX === pp.prev!.ptX && pp.ptY === pp.prev!.ptY) ||
      (SlopesEqual(pp.prev!.ptX, pp.prev!.ptY, pp.ptX, pp.ptY, pp.next!.ptX, pp.next!.ptY, self.useFullRange) &&
        (!self.preserveCollinear ||
          !Pt2IsBetweenPt1AndPt3(pp.prev!.ptX, pp.prev!.ptY, pp.ptX, pp.ptY, pp.next!.ptX, pp.next!.ptY)))) {
      lastOK = null;
      pp.prev!.next = pp.next;
      pp.next!.prev = pp.prev;
      pp = pp.prev!;
    } else if (pp === lastOK) {
      break;
    } else {
      if (lastOK === null)
        lastOK = pp;
      pp = pp.next!;
    }
  }
  outRec.pts = pp;
}

function GetOutRec(self: Clipper, idx: number): OutRec {
  var outrec = self.polyOuts[idx];

  while (outrec !== self.polyOuts[outrec.idx])
    outrec = self.polyOuts[outrec.idx];

  return outrec;
}

function JoinPoints(self: Clipper, j: Join, outRec1: OutRec, outRec2: OutRec): boolean {
  var op1 = j.outPt1;
  var op2 = j.outPt2;
  var op1b: OutPt;
  var op2b: OutPt;

  //There are 3 kinds of joins for output polygons ...
  //1. Horizontal joins where Join.OutPt1 & Join.OutPt2 are a vertices anywhere
  //along (horizontal) collinear edges (& Join.OffPt is on the same horizontal).
  //2. Non-horizontal joins where Join.OutPt1 & Join.OutPt2 are at the same
  //location at the Bottom of the overlapping segment (& Join.OffPt is above).
  //3. StrictlySimple joins where edges touch but are not collinear and where
  //Join.OutPt1, Join.OutPt2 & Join.OffPt all share the same point.
  var isHorizontal = j.outPt1.ptY === j.offPtY;

  if (
    isHorizontal && (j.offPtX === j.outPt1.ptX && j.offPtY === j.outPt1.ptY) && (j.offPtX === j.outPt2.ptX && j.offPtY === j.outPt2.ptY)
  ) {
    //Strictly Simple join ...
    if (outRec1 !== outRec2)
      return false;

    op1b = j.outPt1.next!;

    while (op1b !== op1 && (op1b.ptX === j.offPtX && op1b.ptY === j.offPtY))
      op1b = op1b.next!;

    var reverse1 = (op1b.ptY > j.offPtY);
    op2b = j.outPt2.next!;

    while (op2b !== op2 && (op2b.ptX === j.offPtX && op2b.ptY === j.offPtY))
      op2b = op2b.next!;

    var reverse2 = (op2b.ptY > j.offPtY);

    if (reverse1 === reverse2)
      return false;

    if (reverse1) {
      op1b = DupOutPt(op1, false);
      op2b = DupOutPt(op2, true);
      op1.prev = op2;
      op2.next = op1;
      op1b.next = op2b;
      op2b.prev = op1b;
      j.outPt1 = op1;
      j.outPt2 = op1b;
      return true;
    } else {
      op1b = DupOutPt(op1, true);
      op2b = DupOutPt(op2, false);
      op1.next = op2;
      op2.prev = op1;
      op1b.prev = op2b;
      op2b.next = op1b;
      j.outPt1 = op1;
      j.outPt2 = op1b;
      return true;
    }
  } else if (isHorizontal) {
    //treat horizontal joins differently to non-horizontal joins since with
    //them we're not yet sure where the overlapping is. OutPt1.Pt & OutPt2.Pt
    //may be anywhere along the horizontal edge.
    op1b = op1;

    while (op1.prev!.ptY === op1.ptY && op1.prev !== op1b && op1.prev !== op2)
      op1 = op1.prev!;

    while (op1b.next!.ptY === op1b.ptY && op1b.next !== op1 && op1b.next !== op2)
      op1b = op1b.next!;

    if (op1b.next === op1 || op1b.next === op2)
      return false; //a flat 'polygon'

    op2b = op2;

    while (op2.prev!.ptY === op2.ptY && op2.prev !== op2b && op2.prev !== op1b)
      op2 = op2.prev!;

    while (op2b.next!.ptY === op2b.ptY && op2b.next !== op2 && op2b.next !== op1)
      op2b = op2b.next!;

    if (op2b.next === op2 || op2b.next === op1)
      return false; //a flat 'polygon'

    var out = { Left: 0, Right: 0 };

    //Op1 -. Op1b & Op2 -. Op2b are the extremites of the horizontal edges
    if (!GetOverlap(op1.ptX, op1b.ptX, op2.ptX, op2b.ptX, out))
      return false;

    //DiscardLeftSide: when overlapping edges are joined, a spike will created
    //which needs to be cleaned up. However, we don't want Op1 or Op2 caught up
    //on the discard Side as either may still be needed for other joins ...
    var PtX: number;
    var PtY: number;
    var DiscardLeftSide: boolean;

    if (op1.ptX >= out.Left && op1.ptX <= out.Right) {
      PtX = op1.ptX;
      PtY = op1.ptY;
      DiscardLeftSide = (op1.ptX > op1b.ptX);
    } else if (op2.ptX >= out.Left && op2.ptX <= out.Right) {
      PtX = op2.ptX;
      PtY = op2.ptY;
      DiscardLeftSide = (op2.ptX > op2b.ptX);
    } else if (op1b.ptX >= out.Left && op1b.ptX <= out.Right) {
      PtX = op1b.ptX;
      PtY = op1b.ptY;
      DiscardLeftSide = op1b.ptX > op1.ptX;
    } else {
      PtX = op2b.ptX;
      PtY = op2b.ptY;
      DiscardLeftSide = (op2b.ptX > op2.ptX);
    }

    j.outPt1 = op1;
    j.outPt2 = op2;

    return JoinHorz(op1, op1b, op2, op2b, PtX, PtY, DiscardLeftSide);
  } else {
    //nb: For non-horizontal joins ...
    //    1. Jr.OutPt1.Pt.Y === Jr.OutPt2.Pt.Y
    //    2. Jr.OutPt1.Pt > Jr.OffPt.Y

    //make sure the polygons are correctly oriented ...
    op1b = op1.next!;

    while ((op1b.ptX === op1.ptX && op1b.ptY === op1.ptY) && (op1b !== op1))
      op1b = op1b.next!;

    var Reverse1 = ((op1b.ptY > op1.ptY)
      || !SlopesEqual(op1.ptX, op1.ptY, op1b.ptX, op1b.ptY, j.offPtX, j.offPtY, self.useFullRange));

    if (Reverse1) {
      op1b = op1.prev!;

      while ((op1b.ptX === op1.ptX && op1b.ptY === op1.ptY) && (op1b !== op1))
        op1b = op1b.prev!;

      if ((op1b.ptY > op1.ptY) || !SlopesEqual(op1.ptX, op1.ptY, op1b.ptX, op1b.ptY, j.offPtX, j.offPtY, self.useFullRange))
        return false;
    }

    op2b = op2.next!;

    while ((op2b.ptX === op2.ptX && op2b.ptY === op2.ptY) && (op2b !== op2))
      op2b = op2b.next!;

    var Reverse2 = ((op2b.ptY > op2.ptY)
      || !SlopesEqual(op2.ptX, op2.ptY, op2b.ptX, op2b.ptY, j.offPtX, j.offPtY, self.useFullRange));

    if (Reverse2) {
      op2b = op2.prev!;

      while ((op2b.ptX === op2.ptX && op2b.ptY === op2.ptY) && (op2b !== op2))
        op2b = op2b.prev!;

      if ((op2b.ptY > op2.ptY) || !SlopesEqual(op2.ptX, op2.ptY, op2b.ptX, op2b.ptY, j.offPtX, j.offPtY, self.useFullRange))
        return false;
    }

    if ((op1b === op1) || (op2b === op2) || (op1b === op2b) || ((outRec1 === outRec2) && (Reverse1 === Reverse2)))
      return false;

    if (Reverse1) {
      op1b = DupOutPt(op1, false);
      op2b = DupOutPt(op2, true);
      op1.prev = op2;
      op2.next = op1;
      op1b.next = op2b;
      op2b.prev = op1b;
      j.outPt1 = op1;
      j.outPt2 = op1b;
      return true;
    } else {
      op1b = DupOutPt(op1, true);
      op2b = DupOutPt(op2, false);
      op1.next = op2;
      op2.prev = op1;
      op1b.prev = op2b;
      op2b.next = op1b;
      j.outPt1 = op1;
      j.outPt2 = op1b;
      return true;
    }
  }
}

function JoinCommonEdges(self: Clipper) {
  for (var i = 0; i < self.joins.length; i++) {
    var join = self.joins[i];

    var outRec1 = GetOutRec(self, join.outPt1.idx);
    var outRec2 = GetOutRec(self, join.outPt2.idx);

    if (outRec1.pts === null || outRec2.pts === null)
      continue;

    //get the polygon fragment with the correct hole state (FirstLeft)
    //before calling JoinPoints() ...
    var holeStateRec: OutRec;

    if (outRec1 === outRec2)
      holeStateRec = outRec1;
    else if (Param1RightOfParam2(outRec1, outRec2))
      holeStateRec = outRec2;
    else if (Param1RightOfParam2(outRec2, outRec1))
      holeStateRec = outRec1;
    else
      holeStateRec = GetLowermostRec(outRec1, outRec2);

    if (!JoinPoints(self, join, outRec1, outRec2))
      continue;

    if (outRec1 === outRec2) {
      //instead of joining two polygons, we've just created a new one by
      //splitting one polygon into two.
      outRec1.pts = join.outPt1;
      outRec1.bottomPt = null;
      outRec2 = CreateOutRec(self);
      outRec2.pts = join.outPt2;

      //update all OutRec2.Pts Idx's ...
      UpdateOutPtIdxs(outRec2);

      //We now need to check every OutRec.FirstLeft pointer. If it points
      //to OutRec1 it may need to point to OutRec2 instead ...
      if (self.usingPolyTree) {
        for (var j = 0; j < self.polyOuts.length - 1; j++) {
          var oRec = self.polyOuts[j];

          if (oRec.pts === null || ParseFirstLeft(oRec.firstLeft!) !== outRec1 || oRec.isHole === outRec1.isHole)
            continue;
          if (Poly2ContainsPoly1(oRec.pts, join.outPt2))
            oRec.firstLeft = outRec2;
        }
      }

      if (Poly2ContainsPoly1(outRec2.pts, outRec1.pts)) {
        //outRec2 is contained by outRec1 ...
        outRec2.isHole = !outRec1.isHole;
        outRec2.firstLeft = outRec1;

        //fixup FirstLeft pointers that may need reassigning to OutRec1
        if (self.usingPolyTree)
          FixupFirstLefts2(self, outRec2, outRec1);

        if (xor(outRec2.isHole, self.reverseSolution) === (Area(outRec2) > 0))
          ReversePolyPtLinks(outRec2.pts);

      } else if (Poly2ContainsPoly1(outRec1.pts, outRec2.pts)) {
        //outRec1 is contained by outRec2 ...
        outRec2.isHole = outRec1.isHole;
        outRec1.isHole = !outRec2.isHole;
        outRec2.firstLeft = outRec1.firstLeft;
        outRec1.firstLeft = outRec2;

        //fixup FirstLeft pointers that may need reassigning to OutRec1
        if (self.usingPolyTree)
          FixupFirstLefts2(self, outRec1, outRec2);

        if (xor(outRec1.isHole, self.reverseSolution) === (Area(outRec1) > 0))
          ReversePolyPtLinks(outRec1.pts);
      } else {
        //the 2 polygons are completely separate ...
        outRec2.isHole = outRec1.isHole;
        outRec2.firstLeft = outRec1.firstLeft;

        //fixup FirstLeft pointers that may need reassigning to OutRec2
        if (self.usingPolyTree)
          FixupFirstLefts1(self, outRec1, outRec2);
      }
    } else {
      //joined 2 polygons together ...

      outRec2.pts = null;
      outRec2.bottomPt = null;
      outRec2.idx = outRec1.idx;

      outRec1.isHole = holeStateRec.isHole;
      if (holeStateRec === outRec2)
        outRec1.firstLeft = outRec2.firstLeft;
      outRec2.firstLeft = outRec1;

      //fixup FirstLeft pointers that may need reassigning to OutRec1
      if (self.usingPolyTree)
        FixupFirstLefts2(self, outRec2, outRec1);
    }
  }
}

function SetHoleState(self: Clipper, e: TEdge, outRec: OutRec) {
  var isHole = false;
  var e2 = e.prevInAEL;

  while (e2 !== null) {
    if (e2.outIdx >= 0 && e2.windDelta !== 0) {
      isHole = !isHole;

      if (outRec.firstLeft === null)
        outRec.firstLeft = self.polyOuts[e2.outIdx];
    }
    e2 = e2.prevInAEL;
  }

  if (isHole)
    outRec.isHole = true;
}

function AddOutPt(self: Clipper, e: TEdge, ptX: number, ptY: number): OutPt {
  var ToFront = (e.side === EdgeSide.Left);

  if (e.outIdx < 0) {
    let outRec = CreateOutRec(self);
    outRec.isOpen = e.windDelta === 0;
    let newOp = new OutPt();
    outRec.pts = newOp;
    newOp.idx = outRec.idx;
    newOp.ptX = ptX;
    newOp.ptY = ptY;
    newOp.next = newOp;
    newOp.prev = newOp;

    if (!outRec.isOpen)
      SetHoleState(self, e, outRec);

    e.outIdx = outRec.idx; //nb: do this after SetZ !
    return newOp;
  } else {
    let outRec = self.polyOuts[e.outIdx];
    //OutRec.Pts is the 'Left-most' point & OutRec.Pts.Prev is the 'Right-most'
    var op = outRec.pts!;

    if (ToFront && (ptX === op.ptX && ptY === op.ptY))
      return op;
    else if (!ToFront && (ptX === op.prev!.ptX && ptY === op.prev!.ptY))
      return op.prev!;

    let newOp = new OutPt();
    newOp.idx = outRec.idx;
    newOp.ptX = ptX;
    newOp.ptY = ptY;
    newOp.next = op;
    newOp.prev = op.prev;
    newOp.prev!.next = newOp;
    op.prev = newOp;

    if (ToFront)
      outRec.pts = newOp;

    return newOp;
  }
}

function DeleteFromAEL(self: Clipper, e: TEdge) {
  var AelPrev = e.prevInAEL;
  var AelNext = e.nextInAEL;

  if (AelPrev === null && AelNext === null && (e !== self.activeEdges))
    return; //already deleted

  if (AelPrev !== null)
    AelPrev.nextInAEL = AelNext;
  else
    self.activeEdges = AelNext;

  if (AelNext !== null)
    AelNext.prevInAEL = AelPrev;

  e.nextInAEL = null;
  e.prevInAEL = null;
}

function IsEvenOddFillType(self: Clipper, edge: TEdge): boolean {
  if (edge.polyTyp === PolyType.Subject)
    return self.subjFillType === PolyFillType.EvenOdd;
  else
    return self.clipFillType === PolyFillType.EvenOdd;
}

function AppendPolygon(self: Clipper, e1: TEdge, e2: TEdge) {
  //get the start and ends of both output polygons ...
  var outRec1 = self.polyOuts[e1.outIdx];
  var outRec2 = self.polyOuts[e2.outIdx];

  var holeStateRec: OutRec;

  if (Param1RightOfParam2(outRec1, outRec2))
    holeStateRec = outRec2;
  else if (Param1RightOfParam2(outRec2, outRec1))
    holeStateRec = outRec1;
  else
    holeStateRec = GetLowermostRec(outRec1, outRec2);

  var p1_lft = outRec1.pts!;
  var p1_rt = p1_lft!.prev!;
  var p2_lft = outRec2.pts!;
  var p2_rt = p2_lft!.prev!;

  var side: EdgeSide;

  //join e2 poly onto e1 poly and delete pointers to e2 ...
  if (e1.side === EdgeSide.Left) {
    if (e2.side === EdgeSide.Left) {
      //z y x a b c
      ReversePolyPtLinks(p2_lft);
      p2_lft.next = p1_lft;
      p1_lft.prev = p2_lft;
      p1_rt.next = p2_rt;
      p2_rt.prev = p1_rt;
      outRec1.pts = p2_rt;
    } else {
      //x y z a b c
      p2_rt.next = p1_lft;
      p1_lft.prev = p2_rt;
      p2_lft.prev = p1_rt;
      p1_rt.next = p2_lft;
      outRec1.pts = p2_lft;
    }
    side = EdgeSide.Left;
  } else {
    if (e2.side === EdgeSide.Right) {
      //a b c z y x
      ReversePolyPtLinks(p2_lft);
      p1_rt.next = p2_rt;
      p2_rt.prev = p1_rt;
      p2_lft.next = p1_lft;
      p1_lft.prev = p2_lft;
    } else {
      //a b c x y z
      p1_rt.next = p2_lft;
      p2_lft.prev = p1_rt;
      p1_lft.prev = p2_rt;
      p2_rt.next = p1_lft;
    }
    side = EdgeSide.Right;
  }

  outRec1.bottomPt = null;

  if (holeStateRec === outRec2) {
    if (outRec2.firstLeft !== outRec1)
      outRec1.firstLeft = outRec2.firstLeft;
    outRec1.isHole = outRec2.isHole;
  }

  outRec2.pts = null;
  outRec2.bottomPt = null;
  outRec2.firstLeft = outRec1;

  var OKIdx = e1.outIdx;
  var ObsoleteIdx = e2.outIdx;

  e1.outIdx = Unassigned; //nb: safe because we only get here via AddLocalMaxPoly
  e2.outIdx = Unassigned;

  var e = self.activeEdges;

  while (e !== null) {
    if (e.outIdx === ObsoleteIdx) {
      e.outIdx = OKIdx;
      e.side = side;
      break;
    }
    e = e.nextInAEL;
  }
  outRec2.idx = outRec1.idx;
}

function AddLocalMaxPoly(self: Clipper, e1: TEdge, e2: TEdge, ptX: number, ptY: number) {
  AddOutPt(self, e1, ptX, ptY);

  if (e2.windDelta === 0)
    AddOutPt(self, e2, ptX, ptY);

  if (e1.outIdx === e2.outIdx) {
    e1.outIdx = Unassigned;
    e2.outIdx = Unassigned;
  } else if (e1.outIdx < e2.outIdx) {
    AppendPolygon(self, e1, e2);
  } else {
    AppendPolygon(self, e2, e1);
  }
}

function SwapPositionsInAEL(self: Clipper, edge1: TEdge, edge2: TEdge) {
  //check that one or other edge hasn't already been removed from AEL ...
  if (edge1.nextInAEL === edge1.prevInAEL || edge2.nextInAEL === edge2.prevInAEL)
    return;

  if (edge1.nextInAEL === edge2) {
    let next = edge2.nextInAEL;

    if (next !== null)
      next.prevInAEL = edge1;

    let prev = edge1.prevInAEL;

    if (prev !== null)
      prev.nextInAEL = edge2;

    edge2.prevInAEL = prev;
    edge2.nextInAEL = edge1;
    edge1.prevInAEL = edge2;
    edge1.nextInAEL = next;
  } else if (edge2.nextInAEL === edge1) {
    let next = edge1.nextInAEL;

    if (next !== null)
      next.prevInAEL = edge2;

    let prev = edge2.prevInAEL;

    if (prev !== null)
      prev.nextInAEL = edge1;

    edge1.prevInAEL = prev;
    edge1.nextInAEL = edge2;
    edge2.prevInAEL = edge1;
    edge2.nextInAEL = next;
  } else {
    let next = edge1.nextInAEL;
    let prev = edge1.prevInAEL;
    edge1.nextInAEL = edge2.nextInAEL;

    if (edge1.nextInAEL !== null)
      edge1.nextInAEL.prevInAEL = edge1;

    edge1.prevInAEL = edge2.prevInAEL;

    if (edge1.prevInAEL !== null)
      edge1.prevInAEL.nextInAEL = edge1;

    edge2.nextInAEL = next;

    if (edge2.nextInAEL !== null)
      edge2.nextInAEL.prevInAEL = edge2;

    edge2.prevInAEL = prev;

    if (edge2.prevInAEL !== null)
      edge2.prevInAEL.nextInAEL = edge2;
  }

  if (edge1.prevInAEL === null)
    self.activeEdges = edge1;
  else if (edge2.prevInAEL === null)
    self.activeEdges = edge2;
}

function SwapPositionsInSEL(self: Clipper, edge1: TEdge, edge2: TEdge) {
  if (edge1.nextInSEL === null && edge1.prevInSEL === null)
    return;
  if (edge2.nextInSEL === null && edge2.prevInSEL === null)
    return;

  if (edge1.nextInSEL === edge2) {
    let next = edge2.nextInSEL;

    if (next !== null)
      next.prevInSEL = edge1;

    let prev = edge1.prevInSEL;

    if (prev !== null)
      prev.nextInSEL = edge2;

    edge2.prevInSEL = prev;
    edge2.nextInSEL = edge1;
    edge1.prevInSEL = edge2;
    edge1.nextInSEL = next;
  } else if (edge2.nextInSEL === edge1) {
    let next = edge1.nextInSEL;

    if (next !== null)
      next.prevInSEL = edge2;

    let prev = edge2.prevInSEL;

    if (prev !== null)
      prev.nextInSEL = edge1;

    edge1.prevInSEL = prev;
    edge1.nextInSEL = edge2;
    edge2.prevInSEL = edge1;
    edge2.nextInSEL = next;
  } else {
    let next = edge1.nextInSEL;
    let prev = edge1.prevInSEL;

    edge1.nextInSEL = edge2.nextInSEL;
    if (edge1.nextInSEL !== null)
      edge1.nextInSEL.prevInSEL = edge1;
    edge1.prevInSEL = edge2.prevInSEL;
    if (edge1.prevInSEL !== null)
      edge1.prevInSEL.nextInSEL = edge1;
    edge2.nextInSEL = next;
    if (edge2.nextInSEL !== null)
      edge2.nextInSEL.prevInSEL = edge2;
    edge2.prevInSEL = prev;
    if (edge2.prevInSEL !== null)
      edge2.prevInSEL.nextInSEL = edge2;
  }

  if (edge1.prevInSEL === null)
    self.sortedEdges = edge1;
  else if (edge2.prevInSEL === null)
    self.sortedEdges = edge2;
}
function IntersectEdges(self: Clipper, e1: TEdge, e2: TEdge, ptX: number, ptY: number) {
  //e1 will be to the left of e2 BELOW the intersection. Therefore e1 is before
  //e2 in AEL except when e1 is being inserted at the intersection point ...

  var e1Contributing = (e1.outIdx >= 0);
  var e2Contributing = (e2.outIdx >= 0);

  //update winding counts...
  //assumes that e1 will be to the Right of e2 ABOVE the intersection
  if (e1.polyTyp === e2.polyTyp) {
    if (IsEvenOddFillType(self, e1)) {
      var oldE1WindCnt = e1.windCnt;
      e1.windCnt = e2.windCnt;
      e2.windCnt = oldE1WindCnt;
    } else {
      if (e1.windCnt + e2.windDelta === 0)
        e1.windCnt = -e1.windCnt;
      else
        e1.windCnt += e2.windDelta;

      if (e2.windCnt - e1.windDelta === 0)
        e2.windCnt = -e2.windCnt;
      else
        e2.windCnt -= e1.windDelta;
    }
  } else {
    if (!IsEvenOddFillType(self, e2))
      e1.windCnt2 += e2.windDelta;
    else
      e1.windCnt2 = (e1.windCnt2 === 0) ? 1 : 0;

    if (!IsEvenOddFillType(self, e1))
      e2.windCnt2 -= e1.windDelta;
    else
      e2.windCnt2 = (e2.windCnt2 === 0) ? 1 : 0;
  }

  var e1FillType: PolyFillType;
  var e2FillType: PolyFillType;
  var e1FillType2: PolyFillType;
  var e2FillType2: PolyFillType;

  if (e1.polyTyp === PolyType.Subject) {
    e1FillType = self.subjFillType;
    e1FillType2 = self.clipFillType;
  } else {
    e1FillType = self.clipFillType;
    e1FillType2 = self.subjFillType;
  }

  if (e2.polyTyp === PolyType.Subject) {
    e2FillType = self.subjFillType;
    e2FillType2 = self.clipFillType;
  } else {
    e2FillType = self.clipFillType;
    e2FillType2 = self.subjFillType;
  }

  var e1Wc: number;
  var e2Wc: number;

  switch (e1FillType) {
    case PolyFillType.Positive: e1Wc = e1.windCnt; break;
    case PolyFillType.Negative: e1Wc = -e1.windCnt; break;
    default: e1Wc = Math.abs(e1.windCnt); break;
  }

  switch (e2FillType) {
    case PolyFillType.Positive: e2Wc = e2.windCnt; break;
    case PolyFillType.Negative: e2Wc = -e2.windCnt; break;
    default: e2Wc = Math.abs(e2.windCnt); break;
  }

  if (e1Contributing && e2Contributing) {
    if ((e1Wc !== 0 && e1Wc !== 1) || (e2Wc !== 0 && e2Wc !== 1) ||
      (e1.polyTyp !== e2.polyTyp && self.clipType !== ClipType.Xor)) {
      AddLocalMaxPoly(self, e1, e2, ptX, ptY);
    } else {
      AddOutPt(self, e1, ptX, ptY);
      AddOutPt(self, e2, ptX, ptY);
      SwapSides(e1, e2);
      SwapPolyIndexes(e1, e2);
    }
  } else if (e1Contributing) {
    if (e2Wc === 0 || e2Wc === 1) {
      AddOutPt(self, e1, ptX, ptY);
      SwapSides(e1, e2);
      SwapPolyIndexes(e1, e2);
    }

  } else if (e2Contributing) {
    if (e1Wc === 0 || e1Wc === 1) {
      AddOutPt(self, e2, ptX, ptY);
      SwapSides(e1, e2);
      SwapPolyIndexes(e1, e2);
    }
  } else if ((e1Wc === 0 || e1Wc === 1) && (e2Wc === 0 || e2Wc === 1)) {
    //neither edge is currently contributing ...
    var e1Wc2: number;
    var e2Wc2: number;

    switch (e1FillType2) {
      case PolyFillType.Positive: e1Wc2 = e1.windCnt2; break;
      case PolyFillType.Negative: e1Wc2 = -e1.windCnt2; break;
      default: e1Wc2 = Math.abs(e1.windCnt2); break;
    }

    switch (e2FillType2) {
      case PolyFillType.Positive: e2Wc2 = e2.windCnt2; break;
      case PolyFillType.Negative: e2Wc2 = -e2.windCnt2; break;
      default: e2Wc2 = Math.abs(e2.windCnt2); break;
    }

    if (e1.polyTyp !== e2.polyTyp) {
      AddLocalMinPoly(self, e1, e2, ptX, ptY);
    } else if (e1Wc === 1 && e2Wc === 1) {
      switch (self.clipType) {
        case ClipType.Intersection:
          if (e1Wc2 > 0 && e2Wc2 > 0)
            AddLocalMinPoly(self, e1, e2, ptX, ptY);
          break;
        case ClipType.Union:
          if (e1Wc2 <= 0 && e2Wc2 <= 0)
            AddLocalMinPoly(self, e1, e2, ptX, ptY);
          break;
        case ClipType.Difference:
          if (((e1.polyTyp === PolyType.Clip) && (e1Wc2 > 0) && (e2Wc2 > 0)) ||
            ((e1.polyTyp === PolyType.Subject) && (e1Wc2 <= 0) && (e2Wc2 <= 0)))
            AddLocalMinPoly(self, e1, e2, ptX, ptY);
          break;
        case ClipType.Xor:
          AddLocalMinPoly(self, e1, e2, ptX, ptY);
          break;
      }
    } else {
      SwapSides(e1, e2);
    }
  }
}

function AddJoin(self: Clipper, Op1: OutPt, Op2: OutPt, OffPtX: number, OffPtY: number) {
  self.joins.push(createJoin(Op1, Op2, OffPtX, OffPtY));
}

function AddLocalMinPoly(self: Clipper, e1: TEdge, e2: TEdge, ptX: number, ptY: number): OutPt {
  var result: OutPt;
  var e: TEdge;
  var prevE: TEdge;

  if (IsHorizontal(e2) || (e1.dx > e2.dx)) {
    result = AddOutPt(self, e1, ptX, ptY);
    e2.outIdx = e1.outIdx;
    e1.side = EdgeSide.Left;
    e2.side = EdgeSide.Right;
    e = e1;

    if (e.prevInAEL === e2)
      prevE = e2.prevInAEL!;
    else
      prevE = e.prevInAEL!;
  } else {
    result = AddOutPt(self, e2, ptX, ptY);
    e1.outIdx = e2.outIdx;
    e1.side = EdgeSide.Right;
    e2.side = EdgeSide.Left;
    e = e2;

    if (e.prevInAEL === e1)
      prevE = e1.prevInAEL!;
    else
      prevE = e.prevInAEL!;
  }

  if (prevE !== null && prevE.outIdx >= 0 &&
    (TopX(prevE, ptY) === TopX(e, ptY)) &&
    SlopesEqualEdge(e, prevE, self.useFullRange) &&
    (e.windDelta !== 0) && (prevE.windDelta !== 0)) {
    var outPt = AddOutPt(self, prevE, ptX, ptY);
    AddJoin(self, result, outPt, e.topX, e.topY);
  }
  return result;
}

function DoMaxima(self: Clipper, e: TEdge) {
  var eMaxPair = GetMaximaPair(e);

  if (eMaxPair === null) {
    if (e.outIdx >= 0)
      AddOutPt(self, e, e.topX, e.topY);

    DeleteFromAEL(self, e);
    return;
  }

  var eNext = e.nextInAEL;

  while (eNext !== null && eNext !== eMaxPair) {
    IntersectEdges(self, e, eNext, e.topX, e.topY);
    SwapPositionsInAEL(self, e, eNext);
    eNext = e.nextInAEL;
  }

  if (e.outIdx === Unassigned && eMaxPair.outIdx === Unassigned) {
    DeleteFromAEL(self, e);
    DeleteFromAEL(self, eMaxPair);
  } else if (e.outIdx >= 0 && eMaxPair.outIdx >= 0) {
    if (e.outIdx >= 0)
      AddLocalMaxPoly(self, e, eMaxPair, e.topX, e.topY);

    DeleteFromAEL(self, e);
    DeleteFromAEL(self, eMaxPair);
  } else {
    throw new Error('DoMaxima error');
  }
}

function UpdateEdgeIntoAEL(self: Clipper, e: TEdge): TEdge {
  if (e.nextInLML === null)
    throw new Error('UpdateEdgeIntoAEL: invalid call');

  var AelPrev = e.prevInAEL;
  var AelNext = e.nextInAEL;
  e.nextInLML.outIdx = e.outIdx;

  if (AelPrev !== null)
    AelPrev.nextInAEL = e.nextInLML;
  else
    self.activeEdges = e.nextInLML;

  if (AelNext !== null)
    AelNext.prevInAEL = e.nextInLML;

  e.nextInLML.side = e.side;
  e.nextInLML.windDelta = e.windDelta;
  e.nextInLML.windCnt = e.windCnt;
  e.nextInLML.windCnt2 = e.windCnt2;
  e = e.nextInLML;
  e.currX = e.botX;
  e.currY = e.botY;
  e.prevInAEL = AelPrev;
  e.nextInAEL = AelNext;

  if (!IsHorizontal(e))
    InsertScanbeam(self, e.topY);

  return e;
}

function AddEdgeToSEL(self: Clipper, edge: TEdge) {
  //SEL pointers in PEdge are reused to build a list of horizontal edges.
  //However, we don't need to worry about order with horizontal edge processing.
  if (self.sortedEdges === null) {
    self.sortedEdges = edge;
    edge.prevInSEL = null;
    edge.nextInSEL = null;
  } else {
    edge.nextInSEL = self.sortedEdges;
    edge.prevInSEL = null;
    self.sortedEdges.prevInSEL = edge;
    self.sortedEdges = edge;
  }
}

function ProcessEdgesAtTopOfScanbeam(self: Clipper, topY: number) {
  var e = self.activeEdges;

  while (e !== null) {
    //1. process maxima, treating them as if they're 'bent' horizontal edges,
    //   but exclude maxima with horizontal edges. nb: e can't be a horizontal.
    var IsMaximaEdge = IsMaxima(e, topY);

    if (IsMaximaEdge) {
      var eMaxPair = GetMaximaPair(e);
      IsMaximaEdge = (eMaxPair === null || !IsHorizontal(eMaxPair));
    }

    if (IsMaximaEdge) {
      let ePrev = e.prevInAEL;
      DoMaxima(self, e);

      if (ePrev === null)
        e = self.activeEdges;
      else
        e = ePrev.nextInAEL;
    } else {
      //2. promote horizontal edges, otherwise update Curr.X and Curr.Y ...
      if (IsIntermediate(e, topY) && IsHorizontal(e.nextInLML!)) {
        e = UpdateEdgeIntoAEL(self, e);

        if (e.outIdx >= 0)
          AddOutPt(self, e, e.botX, e.botY);

        AddEdgeToSEL(self, e);
      } else {
        e.currX = TopX(e, topY);
        e.currY = topY;
      }

      if (self.strictlySimple) {
        let ePrev = e.prevInAEL;
        if ((e.outIdx >= 0) && (e.windDelta !== 0) && ePrev !== null &&
          (ePrev.outIdx >= 0) && (ePrev.currX === e.currX) &&
          (ePrev.windDelta !== 0)) {
          var ipX = e.currX;
          var ipY = e.currY;
          let op = AddOutPt(self, ePrev, ipX, ipY);
          let op2 = AddOutPt(self, e, ipX, ipY);
          AddJoin(self, op, op2, ipX, ipY); //StrictlySimple (type-3) join
        }
      }

      e = e.nextInAEL;
    }
  }

  //3. Process horizontals at the Top of the scanbeam ...
  ProcessHorizontals(self, true);

  //4. Promote intermediate vertices ...
  e = self.activeEdges;

  while (e !== null) {
    if (IsIntermediate(e, topY)) {
      let op: OutPt | null = null;

      if (e.outIdx >= 0)
        op = AddOutPt(self, e, e.topX, e.topY);

      e = UpdateEdgeIntoAEL(self, e);

      //if output polygons share an edge, they'll need joining later ...
      let ePrev = e.prevInAEL;
      let eNext = e.nextInAEL;

      if (ePrev !== null && ePrev.currX === e.botX &&
        ePrev.currY === e.botY && op !== null &&
        ePrev.outIdx >= 0 && ePrev.currY > ePrev.topY &&
        SlopesEqualEdge(e, ePrev, self.useFullRange) &&
        (e.windDelta !== 0) && (ePrev.windDelta !== 0)) {
        let op2 = AddOutPt(self, ePrev, e.botX, e.botY);
        AddJoin(self, op, op2, e.topX, e.topY);
      } else if (eNext !== null && eNext.currX === e.botX &&
        eNext.currY === e.botY && op !== null &&
        eNext.outIdx >= 0 && eNext.currY > eNext.topY &&
        SlopesEqualEdge(e, eNext, self.useFullRange) &&
        (e.windDelta !== 0) && (eNext.windDelta !== 0)) {
        let op2 = AddOutPt(self, eNext, e.botX, e.botY);
        AddJoin(self, op, op2, e.topX, e.topY);
      }
    }
    e = e.nextInAEL;
  }
}

function BuildIntersectList(self: Clipper, topY: number) {
  if (self.activeEdges === null)
    return;

  //prepare for sorting ...
  var e = self.activeEdges;
  self.sortedEdges = e;

  while (e !== null) {
    e.prevInSEL = e.prevInAEL;
    e.nextInSEL = e.nextInAEL;
    e.currX = TopX(e, topY);
    e = e.nextInAEL!;
  }

  //bubblesort ...
  var isModified = true;

  while (isModified && self.sortedEdges !== null) {
    isModified = false;
    e = self.sortedEdges;

    while (e.nextInSEL !== null) {
      var eNext = e.nextInSEL;

      if (e.currX > eNext.currX) {
        var pt = IntersectPoint(e, eNext);
        var newNode = new IntersectNode();
        newNode.edge1 = e;
        newNode.edge2 = eNext;
        newNode.ptX = pt.x;
        newNode.ptY = pt.y;
        self.intersectList.push(newNode);

        SwapPositionsInSEL(self, e, eNext);
        isModified = true;
      } else {
        e = eNext;
      }
    }

    if (e.prevInSEL !== null)
      e.prevInSEL.nextInSEL = null;
    else
      break;
  }

  self.sortedEdges = null;
}

function CopyAELToSEL(self: Clipper) {
  var e = self.activeEdges;
  self.sortedEdges = e;

  while (e !== null) {
    e.prevInSEL = e.prevInAEL;
    e.nextInSEL = e.nextInAEL;
    e = e.nextInAEL;
  }
}

function FixupIntersectionOrder(self: Clipper): boolean {
  //pre-condition: intersections are sorted bottom-most first.
  //Now it's crucial that intersections are made only between adjacent edges,
  //so to ensure this the order of intersections may need adjusting ...
  self.intersectList.sort(compareNodes);

  CopyAELToSEL(self);
  var cnt = self.intersectList.length;

  for (var i = 0; i < cnt; i++) {
    if (!EdgesAdjacent(self.intersectList[i])) {
      var j = i + 1;

      while (j < cnt && !EdgesAdjacent(self.intersectList[j]))
        j++;

      if (j === cnt)
        return false;

      var tmp = self.intersectList[i];
      self.intersectList[i] = self.intersectList[j];
      self.intersectList[j] = tmp;
    }

    SwapPositionsInSEL(self, self.intersectList[i].edge1!, self.intersectList[i].edge2!);
  }
  return true;
}

function ProcessIntersectList(self: Clipper) {
  for (var i = 0; i < self.intersectList.length; i++) {
    var iNode = self.intersectList[i];
    IntersectEdges(self, iNode.edge1!, iNode.edge2!, iNode.ptX, iNode.ptY);
    SwapPositionsInAEL(self, iNode.edge1!, iNode.edge2!);
  }
  self.intersectList.length = 0;
}

function ProcessIntersections(self: Clipper, topY: number): boolean {
  if (self.activeEdges === null)
    return true;

  try {
    BuildIntersectList(self, topY);

    if (self.intersectList.length === 0)
      return true;

    if (self.intersectList.length === 1 || FixupIntersectionOrder(self))
      ProcessIntersectList(self);
    else
      return false;
  } catch (e) {
    self.sortedEdges = null;
    self.intersectList.length = 0;
    throw new Error('ProcessIntersections error');
  }

  self.sortedEdges = null;
  return true;
}

function ProcessHorizontals(self: Clipper, isTopOfScanbeam: boolean) {
  var horzEdge = self.sortedEdges;

  while (horzEdge !== null) {
    // DeleteFromSEL {
    var SelPrev = horzEdge.prevInSEL;
    var SelNext = horzEdge.nextInSEL;

    if (SelPrev === null && SelNext === null && (horzEdge !== self.sortedEdges))
      return; //already deleted

    if (SelPrev !== null)
      SelPrev.nextInSEL = SelNext;
    else
      self.sortedEdges = SelNext;

    if (SelNext !== null)
      SelNext.prevInSEL = SelPrev;

    horzEdge.nextInSEL = null;
    horzEdge.prevInSEL = null;
    // }

    ProcessHorizontal(self, horzEdge, isTopOfScanbeam);
    horzEdge = self.sortedEdges;
  }
}

function AddGhostJoin(self: Clipper, Op: OutPt, OffPtX: number, OffPtY: number) {
  self.ghostJoins.push(createJoin(Op, null as any, OffPtX, OffPtY));
}

function ProcessHorizontal(self: Clipper, horzEdge: TEdge, isTopOfScanbeam: boolean) {
  var out = { Dir: Direction.LeftToRight, Left: 0, Right: 0 };

  GetHorzDirection(horzEdge, out);

  var eLastHorz = horzEdge;
  var eMaxPair: TEdge | null = null;

  while (eLastHorz.nextInLML !== null && IsHorizontal(eLastHorz.nextInLML))
    eLastHorz = eLastHorz.nextInLML;

  if (eLastHorz.nextInLML === null)
    eMaxPair = GetMaximaPair(eLastHorz)!;

  while (true) {
    var IsLastHorz = (horzEdge === eLastHorz);
    var e = GetNextInAEL(horzEdge, out.Dir);

    while (e !== null) {
      //Break if we've got to the end of an intermediate horizontal edge ...
      //nb: Smaller Dx's are to the right of larger Dx's ABOVE the horizontal.
      if (e.currX === horzEdge.topX && horzEdge.nextInLML !== null && e.dx < horzEdge.nextInLML.dx)
        break;

      let eNext = GetNextInAEL(e, out.Dir); //saves eNext for later

      if ((out.Dir === Direction.LeftToRight && e.currX <= out.Right) ||
        (out.Dir === Direction.RightToLeft && e.currX >= out.Left)) {
        //so far we're still in range of the horizontal Edge  but make sure
        //we're at the last of consec. horizontals when matching with eMaxPair
        if (e === eMaxPair && IsLastHorz) {
          if (horzEdge.outIdx >= 0) {
            let op1 = AddOutPt(self, horzEdge, horzEdge.topX, horzEdge.topY);
            var eNextHorz = self.sortedEdges;

            while (eNextHorz !== null) {
              if (eNextHorz.outIdx >= 0 &&
                HorzSegmentsOverlap(horzEdge.botX, horzEdge.topX, eNextHorz.botX, eNextHorz.topX)) {
                let op2 = AddOutPt(self, eNextHorz, eNextHorz.botX, eNextHorz.botY);
                AddJoin(self, op2, op1, eNextHorz.topX, eNextHorz.topY);
              }

              eNextHorz = eNextHorz.nextInSEL;
            }
            AddGhostJoin(self, op1, horzEdge.botX, horzEdge.botY);
            AddLocalMaxPoly(self, horzEdge, eMaxPair, horzEdge.topX, horzEdge.topY);
          }
          DeleteFromAEL(self, horzEdge);
          DeleteFromAEL(self, eMaxPair);
          return;
        } else if (out.Dir === Direction.LeftToRight) {
          IntersectEdges(self, horzEdge, e, e.currX, horzEdge.currY);
        } else {
          IntersectEdges(self, e, horzEdge, e.currX, horzEdge.currY);
        }
        SwapPositionsInAEL(self, horzEdge, e);
      } else if ((out.Dir === Direction.LeftToRight && e.currX >= out.Right) ||
        (out.Dir === Direction.RightToLeft && e.currX <= out.Left)) {
        break;
      }

      e = eNext;
    }

    if (horzEdge.nextInLML !== null && IsHorizontal(horzEdge.nextInLML)) {
      horzEdge = UpdateEdgeIntoAEL(self, horzEdge);

      if (horzEdge.outIdx >= 0)
        AddOutPt(self, horzEdge, horzEdge.botX, horzEdge.botY);

      GetHorzDirection(horzEdge, out);
    } else {
      break;
    }
  }

  if (horzEdge.nextInLML !== null) {
    if (horzEdge.outIdx >= 0) {
      let op1 = AddOutPt(self, horzEdge, horzEdge.topX, horzEdge.topY);

      if (isTopOfScanbeam)
        AddGhostJoin(self, op1, horzEdge.botX, horzEdge.botY);

      horzEdge = UpdateEdgeIntoAEL(self, horzEdge);

      if (horzEdge.windDelta === 0)
        return;

      //nb: HorzEdge is no longer horizontal here
      let ePrev = horzEdge.prevInAEL;
      let eNext = horzEdge.nextInAEL;

      if (ePrev !== null && ePrev.currX === horzEdge.botX &&
        ePrev.currY === horzEdge.botY && ePrev.windDelta !== 0 &&
        (ePrev.outIdx >= 0 && ePrev.currY > ePrev.topY &&
          SlopesEqualEdge(horzEdge, ePrev, self.useFullRange))) {
        let op2 = AddOutPt(self, ePrev, horzEdge.botX, horzEdge.botY);
        AddJoin(self, op1, op2, horzEdge.topX, horzEdge.topY);
      } else if (eNext !== null && eNext.currX === horzEdge.botX &&
        eNext.currY === horzEdge.botY && eNext.windDelta !== 0 &&
        eNext.outIdx >= 0 && eNext.currY > eNext.topY &&
        SlopesEqualEdge(horzEdge, eNext, self.useFullRange)) {
        let op2 = AddOutPt(self, eNext, horzEdge.botX, horzEdge.botY);
        AddJoin(self, op1, op2, horzEdge.topX, horzEdge.topY);
      }
    } else {
      horzEdge = UpdateEdgeIntoAEL(self, horzEdge);
    }
  } else {
    if (horzEdge.outIdx >= 0)
      AddOutPt(self, horzEdge, horzEdge.topX, horzEdge.topY);
    DeleteFromAEL(self, horzEdge);
  }
}

function PopScanbeam(self: Clipper): number {
  var Y = self.scanbeam!.y;
  self.scanbeam = self.scanbeam!.next;
  return Y;
}

function PopLocalMinima(self: Clipper) {
  if (self.currentLM === null)
    return;

  self.currentLM = self.currentLM.next;
}

function E2InsertsBeforeE1(e1: TEdge, e2: TEdge): boolean {
  if (e2.currX === e1.currX) {
    if (e2.topY > e1.topY)
      return e2.topX < TopX(e1, e2.topY);
    else
      return e1.topX > TopX(e2, e1.topY);
  } else {
    return e2.currX < e1.currX;
  }
}

function InsertEdgeIntoAEL(self: Clipper, edge: TEdge, startEdge: TEdge) {
  if (self.activeEdges === null) {
    edge.prevInAEL = null;
    edge.nextInAEL = null;
    self.activeEdges = edge;
  } else if (startEdge === null && E2InsertsBeforeE1(self.activeEdges, edge)) {
    edge.prevInAEL = null;
    edge.nextInAEL = self.activeEdges;
    self.activeEdges.prevInAEL = edge;
    self.activeEdges = edge;
  } else {
    if (startEdge === null)
      startEdge = self.activeEdges;

    while (startEdge.nextInAEL !== null && !E2InsertsBeforeE1(startEdge.nextInAEL, edge))
      startEdge = startEdge.nextInAEL;

    edge.nextInAEL = startEdge.nextInAEL;

    if (startEdge.nextInAEL !== null)
      startEdge.nextInAEL.prevInAEL = edge;

    edge.prevInAEL = startEdge;
    startEdge.nextInAEL = edge;
  }
}

function SetWindingCount(self: Clipper, edge: TEdge) {
  var e = edge.prevInAEL;
  //find the edge of the same polytype that immediately preceeds 'edge' in AEL
  while (e !== null && ((e.polyTyp !== edge.polyTyp) || (e.windDelta === 0)))
    e = e.prevInAEL;

  if (e === null) {
    edge.windCnt = (edge.windDelta === 0 ? 1 : edge.windDelta);
    edge.windCnt2 = 0;
    e = self.activeEdges; //ie get ready to calc WindCnt2
  } else if (edge.windDelta === 0 && self.clipType !== ClipType.Union) {
    edge.windCnt = 1;
    edge.windCnt2 = e.windCnt2;
    e = e.nextInAEL; //ie get ready to calc WindCnt2
  } else if (IsEvenOddFillType(self, edge)) {
    //EvenOdd filling ...
    if (edge.windDelta === 0) {
      //are we inside a subj polygon ...
      var Inside = true;
      var e2 = e.prevInAEL;

      while (e2 !== null) {
        if (e2.polyTyp === e.polyTyp && e2.windDelta !== 0)
          Inside = !Inside;
        e2 = e2.prevInAEL;
      }
      edge.windCnt = (Inside ? 0 : 1);
    } else {
      edge.windCnt = edge.windDelta;
    }
    edge.windCnt2 = e.windCnt2;
    e = e.nextInAEL; //ie get ready to calc WindCnt2
  } else {
    //nonZero, Positive or Negative filling ...
    if ((e.windCnt * e.windDelta) < 0) {
      //prev edge is 'decreasing' WindCount (WC) toward zero
      //so we're outside the previous polygon ...
      if (Math.abs(e.windCnt) > 1) {
        //outside prev poly but still inside another.
        //when reversing direction of prev poly use the same WC
        if ((e.windDelta * edge.windDelta) < 0) {
          edge.windCnt = e.windCnt;
          //otherwise continue to 'decrease' WC ...
        } else {
          edge.windCnt = e.windCnt + edge.windDelta;
        }
      } else {
        //now outside all polys of same polytype so set own WC ...
        edge.windCnt = (edge.windDelta === 0 ? 1 : edge.windDelta);
      }
    } else {
      //prev edge is 'increasing' WindCount (WC) away from zero
      //so we're inside the previous polygon ...
      if (edge.windDelta === 0) {
        edge.windCnt = (e.windCnt < 0 ? e.windCnt - 1 : e.windCnt + 1);
        //if wind direction is reversing prev then use same WC
      } else if ((e.windDelta * edge.windDelta) < 0) {
        edge.windCnt = e.windCnt;
        //otherwise add to WC ...
      } else {
        edge.windCnt = e.windCnt + edge.windDelta;
      }
    }
    edge.windCnt2 = e.windCnt2;
    e = e.nextInAEL; //ie get ready to calc WindCnt2
  }

  //update WindCnt2 ...
  if (IsEvenOddAltFillType(self, edge)) {
    //EvenOdd filling ...
    while (e !== edge) {
      if (e!.windDelta !== 0)
        edge.windCnt2 = (edge.windCnt2 === 0 ? 1 : 0);
      e = e!.nextInAEL;
    }
  } else {
    //nonZero, Positive or Negative filling ...
    while (e !== edge) {
      edge.windCnt2 += e!.windDelta;
      e = e!.nextInAEL;
    }
  }
}

function IsEvenOddAltFillType(self: Clipper, edge: TEdge): boolean {
  if (edge.polyTyp === PolyType.Subject)
    return self.clipFillType === PolyFillType.EvenOdd;
  else
    return self.subjFillType === PolyFillType.EvenOdd;
}

function IsContributing(self: Clipper, edge: TEdge): boolean {
  var pft: PolyFillType;
  var pft2: PolyFillType;

  if (edge.polyTyp === PolyType.Subject) {
    pft = self.subjFillType;
    pft2 = self.clipFillType;
  } else {
    pft = self.clipFillType;
    pft2 = self.subjFillType;
  }

  switch (pft) {
    case PolyFillType.EvenOdd:
      //return false if a subj line has been flagged as inside a subj polygon
      if (edge.windDelta === 0 && edge.windCnt !== 1)
        return false;
      break;
    case PolyFillType.NonZero:
      if (Math.abs(edge.windCnt) !== 1)
        return false;
      break;
    case PolyFillType.Positive:
      if (edge.windCnt !== 1)
        return false;
      break;
    default: //PolyFillType.pftNegative
      if (edge.windCnt !== -1)
        return false;
      break;
  }

  switch (self.clipType) {
    case ClipType.Intersection:
      switch (pft2) {
        case PolyFillType.EvenOdd:
        case PolyFillType.NonZero:
          return (edge.windCnt2 !== 0);
        case PolyFillType.Positive:
          return (edge.windCnt2 > 0);
        default:
          return (edge.windCnt2 < 0);
      }
    case ClipType.Union:
      switch (pft2) {
        case PolyFillType.EvenOdd:
        case PolyFillType.NonZero:
          return (edge.windCnt2 === 0);
        case PolyFillType.Positive:
          return (edge.windCnt2 <= 0);
        default:
          return (edge.windCnt2 >= 0);
      }
    case ClipType.Difference:
      if (edge.polyTyp === PolyType.Subject) {
        switch (pft2) {
          case PolyFillType.EvenOdd:
          case PolyFillType.NonZero:
            return (edge.windCnt2 === 0);
          case PolyFillType.Positive:
            return (edge.windCnt2 <= 0);
          default:
            return (edge.windCnt2 >= 0);
        }
      } else {
        switch (pft2) {
          case PolyFillType.EvenOdd:
          case PolyFillType.NonZero:
            return (edge.windCnt2 !== 0);
          case PolyFillType.Positive:
            return (edge.windCnt2 > 0);
          default:
            return (edge.windCnt2 < 0);
        }
      }
    case ClipType.Xor:
      if (edge.windDelta === 0) { //XOr always contributing unless open
        switch (pft2) {
          case PolyFillType.EvenOdd:
          case PolyFillType.NonZero:
            return (edge.windCnt2 === 0);
          case PolyFillType.Positive:
            return (edge.windCnt2 <= 0);
          default:
            return (edge.windCnt2 >= 0);
        }
      } else {
        return true;
      }
  }
  return true;
}
function InsertLocalMinimaIntoAEL(self: Clipper, botY: number) {
  while (self.currentLM !== null && self.currentLM.y === botY) {
    var lb = self.currentLM.leftBound;
    var rb = self.currentLM.rightBound;
    PopLocalMinima(self);

    var Op1: OutPt | null = null;

    if (lb === null) {
      InsertEdgeIntoAEL(self, rb!, null as any);
      SetWindingCount(self, rb!);

      if (IsContributing(self, rb!))
        Op1 = AddOutPt(self, rb!, rb!.botX, rb!.botY);
    } else if (rb === null) {
      InsertEdgeIntoAEL(self, lb, null as any);
      SetWindingCount(self, lb);

      if (IsContributing(self, lb))
        Op1 = AddOutPt(self, lb, lb.botX, lb.botY);

      InsertScanbeam(self, lb.topY);
    } else {
      InsertEdgeIntoAEL(self, lb, null as any);
      InsertEdgeIntoAEL(self, rb, lb);
      SetWindingCount(self, lb);
      rb.windCnt = lb.windCnt;
      rb.windCnt2 = lb.windCnt2;

      if (IsContributing(self, lb))
        Op1 = AddLocalMinPoly(self, lb, rb, lb.botX, lb.botY);

      InsertScanbeam(self, lb.topY);
    }

    if (rb !== null) {
      if (IsHorizontal(rb))
        AddEdgeToSEL(self, rb);
      else
        InsertScanbeam(self, rb.topY);
    }

    if (lb === null || rb === null)
      continue;

    //if output polygons share an Edge with a horizontal rb, they'll need joining later ...
    if (Op1 !== null && IsHorizontal(rb) && self.ghostJoins.length > 0 && rb.windDelta !== 0) {
      for (var i = 0; i < self.ghostJoins.length; i++) {
        //if the horizontal Rb and a 'ghost' horizontal overlap, then convert
        //the 'ghost' join to a real join ready for later ...
        var j = self.ghostJoins[i];

        if (HorzSegmentsOverlap(j.outPt1.ptX, j.offPtX, rb.botX, rb.topX))
          AddJoin(self, j.outPt1, Op1, j.offPtX, j.offPtY);
      }
    }

    if (lb.outIdx >= 0 && lb.prevInAEL !== null &&
      lb.prevInAEL.currX === lb.botX &&
      lb.prevInAEL.outIdx >= 0 &&
      SlopesEqualEdge(lb.prevInAEL, lb, self.useFullRange) &&
      lb.windDelta !== 0 && lb.prevInAEL.windDelta !== 0) {
      let Op2 = AddOutPt(self, lb.prevInAEL, lb.botX, lb.botY);
      AddJoin(self, Op1!, Op2, lb.topX, lb.topY);
    }

    if (lb.nextInAEL !== rb) {
      if (rb.outIdx >= 0 && rb.prevInAEL!.outIdx >= 0 &&
        SlopesEqualEdge(rb.prevInAEL!, rb, self.useFullRange) &&
        rb.windDelta !== 0 && rb.prevInAEL!.windDelta !== 0) {
        let Op2 = AddOutPt(self, rb.prevInAEL!, rb.botX, rb.botY);
        AddJoin(self, Op1!, Op2, rb.topX, rb.topY);
      }

      var e = lb.nextInAEL!;

      if (e !== null) {
        while (e !== rb) {
          //nb: For calculating winding counts etc, IntersectEdges() assumes
          //that param1 will be to the right of param2 ABOVE the intersection ...
          IntersectEdges(self, rb, e, lb.currX, lb.currY); //order important here
          e = e.nextInAEL!;
        }
      }
    }
  }
}
function ExecuteInternal(self: Clipper) {
  Reset(self);

  if (self.currentLM === null)
    throw new Error('m_CurrentLM is null');

  var botY = PopScanbeam(self);

  do {
    InsertLocalMinimaIntoAEL(self, botY);
    self.ghostJoins.length = 0;
    ProcessHorizontals(self, false);

    if (self.scanbeam === null)
      break;

    var topY = PopScanbeam(self);

    if (!ProcessIntersections(self, topY))
      throw new Error('failed to process intersections');

    ProcessEdgesAtTopOfScanbeam(self, topY);
    botY = topY;
  } while (self.scanbeam !== null || self.currentLM !== null);

  //fix orientations ...
  for (let i = 0; i < self.polyOuts.length; i++) {
    let outRec = self.polyOuts[i];

    if (outRec.pts === null || outRec.isOpen)
      continue;

    if (xor(outRec.isHole, self.reverseSolution) === (Area(outRec) > 0))
      ReversePolyPtLinks(outRec.pts);
  }

  JoinCommonEdges(self);

  for (let i = 0; i < self.polyOuts.length; i++) {
    let outRec = self.polyOuts[i];

    if (outRec.pts !== null && !outRec.isOpen)
      FixupOutPolygon(self, outRec);
  }

  if (self.strictlySimple)
    DoSimplePolygons(self);
}
function PointCount(pts: OutPt): number {
  if (pts === null)
    return 0;

  var result = 0;
  var p = pts;

  do {
    result++;
    p = p.next!;
  } while (p !== pts);

  return result;
}
function BuildResult(self: Clipper, polyg: Poly) {
  clearPoly(polyg);

  for (var i = 0; i < self.polyOuts.length; i++) {
    var outRec = self.polyOuts[i];

    if (outRec.pts === null)
      continue;

    var p = outRec.pts.prev!;
    var cnt = PointCount(p);

    if (cnt < 2)
      continue;

    var pg = createPolySegment();

    for (var j = 0; j < cnt; j++) {
      addPolySegmentPoint(pg, p.ptX, p.ptY);
      p = p.prev!;
    }

    polyg.segments.push(pg);
  }
}
function DisposeAllPolyPts(self: Clipper) {
  for (var i = 0; i < self.polyOuts.length; ++i)
    DisposeOutRec(self, i);

  self.polyOuts.length = 0;
}

function DisposeOutRec(self: Clipper, index: number) {
  var outRec = self.polyOuts[index];
  outRec.pts = null;
  outRec = null as any;
  self.polyOuts[index] = null as any;
}

function getX(arr: Int32Array, index: number) {
  return arr[index << 1];
}

function getY(arr: Int32Array, index: number) {
  return arr[(index << 1) + 1];
}

function AddPath(self: Clipper, pg: PolySegment, polyType: PolyType, Closed: boolean): boolean {
  if (!Closed)
    throw new Error('AddPath: Open paths have been disabled.');

  var highI = pg.size - 1;
  var items = pg.items;

  if (Closed) {
    while (highI > 0 && getX(items, highI) === getX(items, 0) && getY(items, highI) === getY(items, 0))
      --highI;
  }

  while (highI > 0 && getX(items, highI) === getX(items, highI - 1) && getY(items, highI) === getY(items, highI - 1))
    --highI;

  if ((Closed && highI < 2) || (!Closed && highI < 1))
    return false;

  //create a new edge array ...
  var edges: TEdge[] = [];

  for (let i = 0; i <= highI; i++)
    edges.push(new TEdge());

  var IsFlat = true;

  //1. Basic (first) edge initialization ...
  edges[1].currX = getX(items, 1);
  edges[1].currY = getY(items, 1);
  self.useFullRange = rangeTest(getX(items, 0), getY(items, 0), self.useFullRange);
  self.useFullRange = rangeTest(getX(items, highI), getY(items, highI), self.useFullRange);
  InitEdge(edges[0], edges[1], edges[highI], getX(items, 0), getY(items, 0));
  InitEdge(edges[highI], edges[0], edges[highI - 1], getX(items, highI), getY(items, highI));

  for (let i = highI - 1; i >= 1; --i) {
    self.useFullRange = rangeTest(getX(items, i), getY(items, i), self.useFullRange);
    InitEdge(edges[i], edges[i + 1], edges[i - 1], getX(items, i), getY(items, i));
  }

  var eStart = edges[0];

  //2. Remove duplicate vertices, and (when closed) collinear edges ...
  var E = eStart, eLoopStop = eStart;

  while (true) {
    //nb: allows matching start and end points when not Closed ...
    if ((E.currX === E.next!.currX && E.currY === E.next!.currY) && (Closed || E.next !== eStart)) {
      if (E === E.next)
        break;
      if (E === eStart)
        eStart = E.next!;

      E = RemoveEdge(E);
      eLoopStop = E;
      continue;
    }

    if (E.prev === E.next) {
      break; //only two vertices
    } else if (
      Closed && SlopesEqual(E.prev!.currX, E.prev!.currY, E.currX, E.currY, E.next!.currX, E.next!.currY, self.useFullRange) &&
      (!self.preserveCollinear ||
        !Pt2IsBetweenPt1AndPt3(E.prev!.currX, E.prev!.currY, E.currX, E.currY, E.next!.currX, E.next!.currY))
    ) {
      //Collinear edges are allowed for open paths but in closed paths
      //the default is to merge adjacent collinear edges into a single edge.
      //However, if the PreserveCollinear property is enabled, only overlapping
      //collinear edges (ie spikes) will be removed from closed paths.
      if (E === eStart)
        eStart = E.next!;

      E = RemoveEdge(E);
      E = E.prev!;
      eLoopStop = E;
      continue;
    }

    E = E.next!;

    if ((E === eLoopStop) || (!Closed && E.next === eStart))
      break;
  }

  if ((!Closed && (E === E.next)) || (Closed && (E.prev === E.next)))
    return false;

  if (!Closed) {
    self.hasOpenPaths = true;
    eStart.prev!.outIdx = Skip;
  }

  //3. Do second stage of edge initialization ...
  E = eStart;

  do {
    InitEdge2(E, polyType);
    E = E.next!;
    if (IsFlat && E.currY !== eStart.currY)
      IsFlat = false;
  } while (E !== eStart);

  //4. Finally, add edge bounds to LocalMinima list ...

  //Totally flat paths must be handled differently when adding them
  //to LocalMinima list to avoid endless loops etc ...
  if (IsFlat) {
    if (Closed)
      return false;

    E.prev!.outIdx = Skip;

    if (E.prev!.botX < E.prev!.topX)
      ReverseHorizontal(E.prev!);

    let locMin = new LocalMinima();
    locMin.next = null;
    locMin.y = E.botY;
    locMin.leftBound = null;
    locMin.rightBound = E;
    locMin.rightBound.side = EdgeSide.Right;
    locMin.rightBound.windDelta = 0;

    while (E.next!.outIdx !== Skip) {
      E.nextInLML = E.next;

      if (E.botX !== E.prev!.topX)
        ReverseHorizontal(E);

      E = E.next!;
    }

    InsertLocalMinima(self, locMin);
    self.edges.push(edges);
    return true;
  }

  self.edges.push(edges);
  var leftBoundIsForward: boolean;
  var EMin: TEdge | null = null;

  //workaround to avoid an endless loop in the while loop below when
  //open paths have matching start and end points ...
  if (E.prev!.botX === E.prev!.topX && E.prev!.botY === E.prev!.topY)
    E = E.next!;

  while (true) {
    E = FindNextLocMin(E);

    if (E === EMin)
      break;
    else if (EMin === null)
      EMin = E;

    //E and E.Prev now share a local minima (left aligned if horizontal).
    //Compare their slopes to find which starts which bound ...
    let locMin = new LocalMinima();
    locMin.next = null;
    locMin.y = E.botY;

    if (E.dx < E.prev!.dx) {
      locMin.leftBound = E.prev;
      locMin.rightBound = E;
      leftBoundIsForward = false; //Q.nextInLML = Q.prev
    } else {
      locMin.leftBound = E;
      locMin.rightBound = E.prev;
      leftBoundIsForward = true; //Q.nextInLML = Q.next
    }

    locMin.leftBound!.side = EdgeSide.Left;
    locMin.rightBound!.side = EdgeSide.Right;

    if (!Closed)
      locMin.leftBound!.windDelta = 0;
    else if (locMin.leftBound!.next === locMin.rightBound)
      locMin.leftBound!.windDelta = -1;
    else
      locMin.leftBound!.windDelta = 1;

    locMin.rightBound!.windDelta = -locMin.leftBound!.windDelta;

    E = ProcessBound(self, locMin.leftBound!, leftBoundIsForward);

    if (E.outIdx === Skip)
      E = ProcessBound(self, E, leftBoundIsForward);

    var E2 = ProcessBound(self, locMin.rightBound!, !leftBoundIsForward);

    if (E2.outIdx === Skip)
      E2 = ProcessBound(self, E2, !leftBoundIsForward);

    if (locMin.leftBound!.outIdx === Skip)
      locMin.leftBound = null;
    else if (locMin.rightBound!.outIdx === Skip)
      locMin.rightBound = null;

    InsertLocalMinima(self, locMin);

    if (!leftBoundIsForward)
      E = E2;
  }

  return true;
}

function AddPaths(self: Clipper, ppg: Poly, polyType: PolyType, closed: boolean): boolean {
  var result = false;
  var length = ppg.segments.length;

  for (var i = 0; i < length; ++i) {
    if (AddPath(self, ppg.segments[i], polyType, closed)) {
      result = true;
    }
  }

  return result;
}

function Execute(self: Clipper, clipType: ClipType, solution: Poly, subjFillType: PolyFillType, clipFillType: PolyFillType) {
  if (self.executeLocked)
    throw new Error('execute locked');
  if (self.hasOpenPaths)
    throw new Error('PolyTree struct is need for open path clipping.');

  self.executeLocked = true;
  clearPoly(solution);
  self.subjFillType = subjFillType;
  self.clipFillType = clipFillType;
  self.clipType = clipType;
  self.usingPolyTree = false;

  try {
    ExecuteInternal(self);
    BuildResult(self, solution);
  } finally {
    self.joins.length = 0;
    self.ghostJoins.length = 0;
    DisposeAllPolyPts(self);
    self.executeLocked = false;
  }
}

function rangeTest(x: number, y: number, useFullRange: boolean): boolean {
  if (useFullRange) {
    if (x > hiRange || y > hiRange || -x > hiRange || -y > hiRange) {
      throw new Error(`Coordinate outside allowed range (${x}, ${y})`);
    }
  } else if (x > loRange || y > loRange || -x > loRange || -y > loRange) {
    useFullRange = true;
    useFullRange = rangeTest(x, y, useFullRange);
  }

  return useFullRange;
}

function InitEdge(e: TEdge, eNext: TEdge, ePrev: TEdge, ptX: number, PtY: number) {
  e.next = eNext;
  e.prev = ePrev;
  e.currX = ptX;
  e.currY = PtY;
  e.outIdx = Unassigned;
}

function InitEdge2(e: TEdge, polyType: PolyType) {
  if (e.currY >= e.next!.currY) {
    e.botX = e.currX;
    e.botY = e.currY;
    e.topX = e.next!.currX;
    e.topY = e.next!.currY;
  } else {
    e.topX = e.currX;
    e.topY = e.currY;
    e.botX = e.next!.currX;
    e.botY = e.next!.currY;
  }
  SetDx(e);
  e.polyTyp = polyType;
}

function SetDx(e: TEdge) {
  e.deltaX = (e.topX - e.botX) | 0;
  e.deltaY = (e.topY - e.botY) | 0;

  if (e.deltaY === 0) {
    e.dx = horizontal;
  } else {
    e.dx = e.deltaX / e.deltaY;
  }
}

function FindNextLocMin(E: TEdge): TEdge {
  var E2: TEdge;

  while (true) {
    while (!(E.botX === E.prev!.botX && E.botY === E.prev!.botY) || (E.currX === E.topX && E.currY === E.topY))
      E = E.next!;

    if (E.dx !== horizontal && E.prev!.dx !== horizontal)
      break;

    while (E.prev!.dx === horizontal)
      E = E.prev!;

    E2 = E;

    while (E.dx === horizontal)
      E = E.next!;

    if (E.topY === E.prev!.botY)
      continue; //ie just an intermediate horz.

    if (E2.prev!.botX < E.botX)
      E = E2;

    break;
  }

  return E;
}

function EdgesAdjacent(inode: IntersectNode): boolean {
  return (inode.edge1!.nextInSEL === inode.edge2) || (inode.edge1!.prevInSEL === inode.edge2);
}

function GetHorzDirection(HorzEdge: TEdge, out: { Dir: Direction, Left: number, Right: number }) {
  if (HorzEdge.botX < HorzEdge.topX) {
    out.Left = HorzEdge.botX;
    out.Right = HorzEdge.topX;
    out.Dir = Direction.LeftToRight;
  } else {
    out.Left = HorzEdge.topX;
    out.Right = HorzEdge.botX;
    out.Dir = Direction.RightToLeft;
  }
}

function GetNextInAEL(e: TEdge, dir: Direction): TEdge {
  return dir === Direction.LeftToRight ? e.nextInAEL! : e.prevInAEL!;
}

function HorzSegmentsOverlap(seg1a: number, seg1b: number, seg2a: number, seg2b: number): boolean {
  if (seg1a > seg1b) {
    let temp = seg1a;
    seg1a = seg1b;
    seg1b = temp;
  }

  if (seg2a > seg2b) {
    let temp = seg2a;
    seg2a = seg2b;
    seg2b = temp;
  }

  return (seg1a < seg2b) && (seg2a < seg1b);
}

function IntersectPoint(edge1: TEdge, edge2: TEdge): { x: number, y: number } {
  var ipX = 0;
  var ipY = 0;
  var b1: double;
  var b2: double;

  //nb: with very large coordinate values, it's possible for SlopesEqual() to
  //return false but for the edge.Dx value be equal due to double precision rounding.
  if (edge1.dx === edge2.dx) {
    ipY = edge1.currY;
    ipX = TopX(edge1, ipY);
    return { x: ipX, y: ipY };
  }

  if (edge1.deltaX === 0) {
    ipX = edge1.botX;

    if (IsHorizontal(edge2)) {
      ipY = edge2.botY;
    } else {
      b2 = edge2.botY - (edge2.botX / edge2.dx);
      ipY = Round(ipX / edge2.dx + b2);
    }
  } else if (edge2.deltaX === 0) {
    ipX = edge2.botX;

    if (IsHorizontal(edge1)) {
      ipY = edge1.botY;
    } else {
      b1 = edge1.botY - (edge1.botX / edge1.dx);
      ipY = Round(ipX / edge1.dx + b1);
    }
  }
  else {
    b1 = edge1.botX - edge1.botY * edge1.dx;
    b2 = edge2.botX - edge2.botY * edge2.dx;
    var q = (b2 - b1) / (edge1.dx - edge2.dx);
    ipY = Round(q);

    if (Math.abs(edge1.dx) < Math.abs(edge2.dx))
      ipX = Round(edge1.dx * q + b1);
    else
      ipX = Round(edge2.dx * q + b2);
  }

  if (ipY < edge1.topY || ipY < edge2.topY) {
    if (edge1.topY > edge2.topY)
      ipY = edge1.topY;
    else
      ipY = edge2.topY;

    if (Math.abs(edge1.dx) < Math.abs(edge2.dx))
      ipX = TopX(edge1, ipY);
    else
      ipX = TopX(edge2, ipY);
  }

  //finally, don't allow 'ip' to be BELOW curr.Y (ie bottom of scanbeam) ...
  if (ipY > edge1.currY) {
    ipY = edge1.currY;
    //better to use the more vertical edge to derive X ...
    if (Math.abs(edge1.dx) > Math.abs(edge2.dx))
      ipX = TopX(edge2, ipY);
    else
      ipX = TopX(edge1, ipY);
  }

  return { x: ipX, y: ipY };
}

function IsIntermediate(e: TEdge, Y: double): boolean {
  return (e.topY === Y && e.nextInLML !== null);
}

function GetOverlap(a1: number, a2: number, b1: number, b2: number, out: { Left: number; Right: number }): boolean {
  if (a1 < a2) {
    if (b1 < b2) {
      out.Left = Math.max(a1, b1);
      out.Right = Math.min(a2, b2);
    } else {
      out.Left = Math.max(a1, b2);
      out.Right = Math.min(a2, b1);
    }
  } else {
    if (b1 < b2) {
      out.Left = Math.max(a2, b1);
      out.Right = Math.min(a1, b2);
    } else {
      out.Left = Math.max(a2, b2);
      out.Right = Math.min(a1, b1);
    }
  }

  return out.Left < out.Right;
}

function JoinHorz(op1: OutPt, op1b: OutPt, op2: OutPt, op2b: OutPt, PtX: number, PtY: number, DiscardLeft: boolean): boolean {
  var Dir1 = op1.ptX > op1b.ptX ? Direction.RightToLeft : Direction.LeftToRight;
  var Dir2 = op2.ptX > op2b.ptX ? Direction.RightToLeft : Direction.LeftToRight;

  if (Dir1 === Dir2)
    return false;

  //When DiscardLeft, we want Op1b to be on the Left of Op1, otherwise we
  //want Op1b to be on the Right. (And likewise with Op2 and Op2b.)
  //So, to facilitate this while inserting Op1b and Op2b ...
  //when DiscardLeft, make sure we're AT or RIGHT of Pt before adding Op1b,
  //otherwise make sure we're AT or LEFT of Pt. (Likewise with Op2b.)
  if (Dir1 === Direction.LeftToRight) {
    while (op1.next!.ptX <= PtX && op1.next!.ptX >= op1.ptX && op1.next!.ptY === PtY)
      op1 = op1.next!;

    if (DiscardLeft && (op1.ptX !== PtX))
      op1 = op1.next!;

    op1b = DupOutPt(op1, !DiscardLeft);

    if (!(op1b.ptX === PtX && op1b.ptY === PtY)) {
      op1 = op1b;
      op1.ptX = PtX;
      op1.ptY = PtY;
      op1b = DupOutPt(op1, !DiscardLeft);
    }
  } else {
    while (op1.next!.ptX >= PtX && op1.next!.ptX <= op1.ptX && op1.next!.ptY === PtY)
      op1 = op1.next!;

    if (!DiscardLeft && (op1.ptX !== PtX))
      op1 = op1.next!;

    op1b = DupOutPt(op1, DiscardLeft);

    if (!(op1b.ptX === PtX && op1b.ptY === PtY)) {
      op1 = op1b;
      op1.ptX = PtX;
      op1.ptY = PtY;
      op1b = DupOutPt(op1, DiscardLeft);
    }
  }

  if (Dir2 === Direction.LeftToRight) {
    while (op2.next!.ptX <= PtX && op2.next!.ptX >= op2.ptX && op2.next!.ptY === PtY)
      op2 = op2.next!;

    if (DiscardLeft && (op2.ptX !== PtX))
      op2 = op2.next!;

    op2b = DupOutPt(op2, !DiscardLeft);

    if (!(op2b.ptX === PtX && op2b.ptY === PtY)) {
      op2 = op2b;
      op2.ptX = PtX;
      op2.ptY = PtY;
      op2b = DupOutPt(op2, !DiscardLeft);
    }
  } else {
    while (op2.next!.ptX >= PtX && op2.next!.ptX <= op2.ptX && op2.next!.ptY === PtY)
      op2 = op2.next!;

    if (!DiscardLeft && (op2.ptX !== PtX))
      op2 = op2.next!;

    op2b = DupOutPt(op2, DiscardLeft);

    if (!(op2b.ptX === PtX && op2b.ptY === PtY)) {
      op2 = op2b;
      op2.ptX = PtX;
      op2.ptY = PtY;
      op2b = DupOutPt(op2, DiscardLeft);
    }
  }

  if ((Dir1 === Direction.LeftToRight) === DiscardLeft) {
    op1.prev = op2;
    op2.next = op1;
    op1b.next = op2b;
    op2b.prev = op1b;
  } else {
    op1.next = op2;
    op2.prev = op1;
    op1b.prev = op2b;
    op2b.next = op1b;
  }

  return true;
}

function DupOutPt(outPt: OutPt, InsertAfter: boolean): OutPt {
  var result = new OutPt();
  result.ptX = outPt.ptX;
  result.ptY = outPt.ptY;
  result.idx = outPt.idx;

  if (InsertAfter) {
    result.next = outPt.next;
    result.prev = outPt;
    outPt.next!.prev = result;
    outPt.next = result;
  } else {
    result.prev = outPt.prev;
    result.next = outPt;
    outPt.prev!.next = result;
    outPt.prev = result;
  }

  return result;
}

function GetLowermostRec(outRec1: OutRec, outRec2: OutRec): OutRec {
  //work out which polygon fragment has the correct hole state ...
  if (outRec1.bottomPt === null)
    outRec1.bottomPt = GetBottomPt(outRec1.pts!);
  if (outRec2.bottomPt === null)
    outRec2.bottomPt = GetBottomPt(outRec2.pts!);

  var bPt1 = outRec1.bottomPt!;
  var bPt2 = outRec2.bottomPt!;

  if (bPt1.ptY > bPt2.ptY)
    return outRec1;
  else if (bPt1.ptY < bPt2.ptY)
    return outRec2;
  else if (bPt1.ptX < bPt2.ptX)
    return outRec1;
  else if (bPt1.ptX > bPt2.ptX)
    return outRec2;
  else if (bPt1.next === bPt1)
    return outRec2;
  else if (bPt2.next === bPt2)
    return outRec1;
  else if (FirstIsBottomPt(bPt1, bPt2))
    return outRec1;
  else
    return outRec2;
}

function GetBottomPt(pp: OutPt): OutPt {
  var dups: OutPt | null = null;
  var p = pp.next!;

  while (p !== pp) {
    if (p.ptY > pp.ptY) {
      pp = p;
      dups = null;
    } else if (p.ptY === pp.ptY && p.ptX <= pp.ptX) {
      if (p.ptX < pp.ptX) {
        dups = null;
        pp = p;
      } else {
        if (p.next !== pp && p.prev !== pp)
          dups = p;
      }
    }
    p = p.next!;
  }

  if (dups !== null) {
    //there appears to be at least 2 vertices at bottomPt so ...
    while (dups !== p) {
      if (!FirstIsBottomPt(p, dups!))
        pp = dups!;

      dups = dups!.next;

      while (!(dups!.ptX === pp.ptX && dups!.ptY === pp.ptY))
        dups = dups!.next;
    }
  }
  return pp;
}

function FirstIsBottomPt(btmPt1: OutPt, btmPt2: OutPt): boolean {
  var p = btmPt1.prev!;

  while ((p.ptX === btmPt1.ptX && p.ptY === btmPt2.ptY) && (p !== btmPt1))
    p = p.prev!;

  var dx1p = Math.abs(GetDx(btmPt1.ptX, btmPt1.ptY, p.ptX, p.ptY));
  p = btmPt1.next!;

  while ((p.ptX === btmPt1.ptX && p.ptY === btmPt1.ptY) && (p !== btmPt1))
    p = p.next!;

  var dx1n = Math.abs(GetDx(btmPt1.ptX, btmPt1.ptY, p.ptX, p.ptY));

  p = btmPt2.prev!;

  while ((p.ptX === btmPt2.ptX && p.ptY === btmPt2.ptY) && (p !== btmPt2))
    p = p.prev!;

  var dx2p = Math.abs(GetDx(btmPt2.ptX, btmPt2.ptY, p.ptX, p.ptY));
  p = btmPt2.next!;

  while ((p.ptX === btmPt2.ptX && p.ptY === btmPt2.ptY) && (p !== btmPt2))
    p = p.next!;

  var dx2n = Math.abs(GetDx(btmPt2.ptX, btmPt2.ptY, p.ptX, p.ptY));

  return (dx1p >= dx2p && dx1p >= dx2n) || (dx1n >= dx2p && dx1n >= dx2n);
}

function Param1RightOfParam2(outRec1: OutRec, outRec2: OutRec): boolean {
  do {
    outRec1 = outRec1.firstLeft!;

    if (outRec1 === outRec2)
      return true;
  } while (outRec1 !== null);

  return false;
}

function GetDx(pt1X: number, pt1Y: number, pt2X: number, pt2Y: number): double {
  if (pt1Y === pt2Y)
    return horizontal;
  else
    return (pt2X - pt1X) / (pt2Y - pt1Y);
}

function Pt2IsBetweenPt1AndPt3(pt1X: number, pt1Y: number, pt2X: number, pt2Y: number, pt3X: number, pt3Y: number): boolean {
  if ((pt1X === pt3X && pt1Y === pt3Y) || (pt1X === pt2X && pt1Y === pt2Y) || (pt3X === pt2X && pt3Y === pt2Y))
    return false;
  else if (pt1X !== pt3X)
    return (pt2X > pt1X) === (pt2X < pt3X);
  else
    return (pt2Y > pt1Y) === (pt2Y < pt3Y);
}

function RemoveEdge(e: TEdge): TEdge {
  //removes e from double_linked_list (but without removing from memory)
  e.prev!.next = e.next!;
  e.next!.prev = e.prev!;
  var result = e.next;
  e.prev = null; //flag as removed (see ClipperBase.Clear)
  return result!;
}

function ReverseHorizontal(e: TEdge) {
  //swap horizontal edges' top and bottom x's so they follow the natural
  //progression of the bounds - ie so their xbots will align with the
  //adjoining lower edge. [Helpful in the ProcessHorizontal() method.]
  var temp = e.topX;
  e.topX = e.botX;
  e.botX = temp;
}

function ReversePolyPtLinks(pp: OutPt) {
  if (pp === null)
    return;

  var pp1 = pp;
  var pp2: OutPt;

  do {
    pp2 = pp1.next!;
    pp1.next = pp1.prev;
    pp1.prev = pp2;
    pp1 = pp2;
  } while (pp1 !== pp);
}

function Area(outRec: OutRec): double {
  var op = outRec.pts!;

  if (op === null)
    return 0;

  var a: double = 0;

  do {
    a = a + (op.prev!.ptX + op.ptX) * (op.prev!.ptY - op.ptY);
    op = op.next!;
  } while (op !== outRec.pts);

  return a * 0.5;
}

function GetMaximaPair(e: TEdge): TEdge | null {
  var result: TEdge | null = null;

  if ((e.next!.topX === e.topX && e.next!.topY === e.topY) && e.next!.nextInLML === null)
    result = e.next;
  else if ((e.prev!.topX === e.topX && e.prev!.topY === e.topY) && e.prev!.nextInLML === null)
    result = e.prev;

  if (result !== null && (result.outIdx === Skip || (result.nextInAEL === result.prevInAEL && !IsHorizontal(result))))
    return null;

  return result;
}

function SwapSides(edge1: TEdge, edge2: TEdge) {
  var side = edge1.side;
  edge1.side = edge2.side;
  edge2.side = side;
}

function SwapPolyIndexes(edge1: TEdge, edge2: TEdge) {
  var outIdx = edge1.outIdx;
  edge1.outIdx = edge2.outIdx;
  edge2.outIdx = outIdx;
}

function Round(value: double): number {
  return value < 0 ? (value - 0.5) | 0 : (value + 0.5) | 0;
}

function TopX(edge: TEdge, currentY: number): number {
  if (currentY === edge.topY)
    return edge.topX;
  return (edge.botX + Round(edge.dx * (currentY - edge.botY))) | 0;
}

function IsMaxima(e: TEdge, Y: double): boolean {
  return e !== null && e.topY === Y && e.nextInLML === null;
}

function IsHorizontal(e: TEdge): boolean {
  return e.deltaY === 0;
}

function SlopesEqual(
  pt1X: number, pt1Y: number, pt2X: number, pt2Y: number, pt3X: number, pt3Y: number, UseFullRange: boolean
): boolean {
  if (UseFullRange) // return Int128.Int128Mul(pt1.Y - pt2.Y, pt2.X - pt3.X) === Int128.Int128Mul(pt1.X - pt2.X, pt2.Y - pt3.Y);
    throw new Error('Not implemented');
  else
    return (((pt1Y - pt2Y) * (pt2X - pt3X)) | 0) - (((pt1X - pt2X) * (pt2Y - pt3Y)) | 0) === 0;
}

function SlopesEqualEdge(e1: TEdge, e2: TEdge, UseFullRange: boolean): boolean {
  if (UseFullRange)
    throw new Error('Not implemented'); // return Int128.Int128Mul(e1.Delta.Y, e2.Delta.X) === Int128.Int128Mul(e1.Delta.X, e2.Delta.Y);
  else
    return ((e1.deltaY * e2.deltaX) | 0) === ((e1.deltaX * e2.deltaY) | 0);
}

function UpdateOutPtIdxs(outrec: OutRec) {
  var op = outrec.pts;

  do {
    op!.idx = outrec.idx!;
    op = op!.prev!;
  } while (op !== outrec.pts);
}

function Poly2ContainsPoly1(outPt1: OutPt, outPt2: OutPt): boolean {
  var op = outPt1;

  do {
    //nb: PointInPolygon returns 0 if false, +1 if true, -1 if pt on polygon
    var res = PointInPolygon(op.ptX, op.ptY, outPt2);

    if (res >= 0)
      return res > 0;

    op = op.next!;
  } while (op !== outPt1);

  return true;
}

function ParseFirstLeft(FirstLeft: OutRec): OutRec {
  while (FirstLeft !== null && FirstLeft.pts === null)
    FirstLeft = FirstLeft.firstLeft!;
  return FirstLeft;
}

/*
function PointInPaths(ptX: number, ptY: number, paths: Poly): boolean {
  var intersections = 0;
  var length = paths.length;

  for (var i = 0; i < length; i++) {
    if (PointInPath(ptX, ptY, paths[i]) !== 0) {
      intersections++;
    }
  }

  return (intersections % 2) === 1;
}

function PointInPath(ptX: number, ptY: number, path: PolySegment): number {
  //returns 0 if false, +1 if true, -1 if pt ON polygon boundary
  //See "The Point in Polygon Problem for Arbitrary Polygons" by Hormann & Agathos
  //http://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.88.5498&rep=rep1&type=pdf
  var result = 0;
  var cnt = path.size;

  if (cnt < 3)
    return 0;

  var ipX = path.x[0];
  var ipY = path.y[0];

  for (var i = 1; i <= cnt; ++i) {
    var ipNextX = (i === cnt ? path.x[0] : path.x[i]);
    var ipNextY = (i === cnt ? path.y[0] : path.y[i]);

    if (ipNextY === ptY) {
      if ((ipNextX === ptX) || (ipY === ptY && ((ipNextX > ptX) === (ipX < ptX))))
        return -1;
    }

    if ((ipY < ptY) !== (ipNextY < ptY)) {
      if (ipX >= ptX) {
        if (ipNextX > ptX) {
          result = 1 - result;
        } else {
          let d: double = (ipX - ptX) * (ipNextY - ptY) - (ipNextX - ptX) * (ipY - ptY);

          if (d === 0)
            return -1;
          else if ((d > 0) === (ipNextY > ipY))
            result = 1 - result;
        }
      } else {
        if (ipNextX > ptX) {
          let d: double = (ipX - ptX) * (ipNextY - ptY) - (ipNextX - ptX) * (ipY - ptY);

          if (d === 0)
            return -1;
          else if ((d > 0) === (ipNextY > ipY))
            result = 1 - result;
        }
      }
    }

    ipX = ipNextX;
    ipY = ipNextY;
  }
  return result;
}
*/

function PointInPolygon(ptX: number, ptY: number, op: OutPt): number {
  //returns 0 if false, +1 if true, -1 if pt ON polygon boundary
  //See "The Point in Polygon Problem for Arbitrary Polygons" by Hormann & Agathos
  //http://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.88.5498&rep=rep1&type=pdf
  var result = 0;
  var startOp = op;
  var ptx = ptX;
  var pty = ptY;
  var poly0x = op.ptX;
  var poly0y = op.ptY;

  do {
    op = op.next!;
    var poly1x = op.ptX;
    var poly1y = op.ptY;

    if (poly1y === pty) {
      if ((poly1x === ptx) || (poly0y === pty && ((poly1x > ptx) === (poly0x < ptx))))
        return -1;
    }

    if ((poly0y < pty) !== (poly1y < pty)) {
      if (poly0x >= ptx) {
        if (poly1x > ptx) {
          result = 1 - result;
        } else {
          let d = (poly0x - ptx) * (poly1y - pty) - (poly1x - ptx) * (poly0y - pty);

          if (d === 0)
            return -1;
          if ((d > 0) === (poly1y > poly0y))
            result = 1 - result;
        }
      } else {
        if (poly1x > ptx) {
          let d = (poly0x - ptx) * (poly1y - pty) - (poly1x - ptx) * (poly0y - pty);

          if (d === 0)
            return -1;
          if ((d > 0) === (poly1y > poly0y))
            result = 1 - result;
        }
      }
    }

    poly0x = poly1x;
    poly0y = poly1y;
  } while (startOp !== op);

  return result;
}

export function simplifyPolygons(polys: Poly, fillType = PolyFillType.EvenOdd): Poly {
  const result = createPoly(polys.ox, polys.ox);
  const clipper = createClipper();
  clipper.strictlySimple = true;
  AddPaths(clipper, polys, PolyType.Subject, true);
  Execute(clipper, ClipType.Union, result, fillType, fillType);
  return result;
}

export function executeClip(a: Poly, b: Poly, clipType: ClipType, simplify: boolean): Poly {
  const clipper = createClipper();

  movePoly(b, b.ox - a.ox, b.oy - a.oy);
  b.ox = a.ox;
  b.oy = a.oy;

  clipper.strictlySimple = simplify;
  AddPaths(clipper, a, PolyType.Subject, true);
  AddPaths(clipper, b, PolyType.Clip, true);
  const solution = createPoly(0, 0);
  Execute(clipper, clipType, solution, PolyFillType.EvenOdd, PolyFillType.EvenOdd);
  solution.ox = a.ox;
  solution.oy = a.oy;
  return solution;
}
