import { Layer, Drawing, ClientDrawingData, User, SequenceDrawingData, LayerOwner, SequenceDrawing, WorkerDrawingData, Rect, TextLayer } from './interfaces';
import { findById, includes, removeItem } from './baseUtils';
import { toLayerState, layerFromState, isTextLayer } from './layer';
import { clipRect, createRect } from './rect';
import { SEQUENCE_THUMB_HEIGHT, SEQUENCE_THUMB_WIDTH, DEFAULT_DRAWING_DATA, DEFAULT_TEXTURE_TILE_MARING_SIZE, DEFAULT_TEXTURE_TILE_SIZE } from './constants';
import { fullName } from './userUtils';
import { cloneDeep } from 'lodash';

export function createDrawing(data: ClientDrawingData): Drawing {
  const {
    _id, id, name, x, y, w, h, background, dpi, password, hasAdmins = false, layerOwners,
    layers = [], pro = false, hideEdition = false, sequenceMainDrawingId, sequence,
    respectOfflineOwners = false, layersPerUser = 0, justImported = false,
    team, project, folder,
    shareType, sequenceId, isRevision, licensing, publishedIn, inheritedLicense, participants = []
  } = data;

  const drawing: Drawing = {
    _id, id, name, w, h, x, y, background, dpi, password,
    hasAdmins, hideEdition,
    respectOfflineOwners, layersPerUser,
    pro, justImported,
    permissions: data.permissions ? cloneDeep(data.permissions) : {},
    featureFlags: [...data.featureFlags ?? []],
    layers: layers.map(l => layerFromState(l)),
    sequence: sequence?.map(createSequenceDrawing) ?? [{ _id, id, name, users: [] }],
    sequenceMainDrawingId,
    team, project, folder,
    canvas: undefined,
    thumbUpdate: undefined,
    permissionFlags: [...data.permissionFlags ?? []],
    shareType,
    promptHistory: cloneDeep(data.promptHistory),
    sequenceId,
    isRevision: !!isRevision,
    licensing,
    publishedIn,
    inheritedLicense,
    participants,
    // webgl renderer fields
    lod: 1,
    tileMarginSize: TESTS ? 4 : DEFAULT_TEXTURE_TILE_MARING_SIZE,
    tileSize: TESTS ? 120 : DEFAULT_TEXTURE_TILE_SIZE,
    tiles: { ox: 0, oy: 0, tiles: [[]] },
    visibleRect: createRect(0, 0, 0, 0),
    renderedRect: createRect(0, 0, 0, 0),
  };

  if (layerOwners) {
    const layerToOwner = new Map<number, LayerOwner>();

    for (const o of layerOwners) {
      for (const layerId of o.left) {
        layerToOwner.set(layerId, { name: o.name, color: o.color, left: true });
      }
    }

    for (const o of layerOwners) {
      for (const layerId of o.layers) {
        layerToOwner.set(layerId, { name: o.name, color: o.color, left: false });
      }
    }

    for (const layer of drawing.layers) {
      layer.layerOwner = layerToOwner.get(layer.id);
    }
  }

  return drawing;
}

export function createSequenceDrawing({ _id, id, name, cacheId }: SequenceDrawingData): SequenceDrawing {
  return { _id, id, name, users: [], cacheId };
}

export function reassignSequenceDrawings(current: Drawing, old: Drawing) {
  if (!current.sequence || !old.sequence) return;

  for (const drawing of current.sequence) {
    const found = findById(old.sequence, drawing.id);
    if (found) {
      // do not reassign name, it can contain old value
      const { name, ...rest } = found;
      Object.assign(drawing, rest);
    }
  }

  // copy placeholders if drawing was changed in the same sequence
  if (current.sequenceId === old.sequenceId) {
    for (const drawing of old.sequence) {
      if (drawing.id.startsWith('placeholder-')) {
        const drawingId = drawing.id.split('-')[1];
        const index = old.sequence.map(d => d.id).indexOf(drawingId);
        if (index >= 0) {
          current.sequence.splice(index + 1, 0, drawing);
        }
      }
    }
  }
}

export function createTestDrawing(data: Partial<ClientDrawingData>) {
  return createDrawing({ ...DEFAULT_DRAWING_DATA, ...data });
}

// used in worker
export function createWorkerDrawingState(drawing: Drawing): WorkerDrawingData {
  const { _id, id, name, w, h, background, layers, dpi, x, y, defaultStorageBackendMountId } = drawing;
  return { _id, id, name, w, h, x, y, background, dpi, layers: layers.map(toLayerState), defaultStorageBackendMountId };
}

export function hasAllFontsLoaded(drawing: Drawing) {
  return !drawing.layers.some(l => isTextLayer(l) && !l.fontsLoaded);
}

export function forAllTextLayers(drawing: Drawing, fn: (layer: TextLayer) => void) {
  for (const l of drawing.layers) {
    if (isTextLayer(l)) fn(l);
  }
}

export function hasFreeLayers(drawing: Drawing) {
  return drawing.layers.some(l => !l.owner);
}

export function getLayer(drawing: { layers: Layer[] }, id: number): Layer | undefined {
  return findById(drawing.layers, id);
}

export function getAboveLayer(drawing: Drawing, layer: Layer): Layer | undefined {
  const index = drawing.layers.indexOf(layer) - 1;
  return index >= 0 ? drawing.layers[index] : undefined;
}

export function getBelowLayer(drawing: Drawing, layer: Layer): Layer | undefined {
  const index = drawing.layers.indexOf(layer) + 1;
  return index < drawing.layers.length ? drawing.layers[index] : undefined;
}

export function getLayerSafe(drawing: Drawing, id: number) {
  const layer = getLayer(drawing, id);

  if (!layer) throw new Error(`Missing layer (${id})`);

  return layer;
}

export function getNewLayerName(drawing: Drawing | ClientDrawingData) {
  let id = drawing.layers.length;
  let name: string;

  do {
    id++;
    name = `Layer #${id}`;
  } while (drawing.layers.some(l => l.name === name));

  return name;
}

export function hasOwner(layer: Layer) {
  return !!layer.owner || !!(layer.layerOwner && !layer.layerOwner.left);
}

export function setLayerOwner(layer: Layer, user: User | undefined, removeLayerOwner = false) {
  if (layer.owner && layer.owner !== user) {
    removeItem(layer.owner.ownedLayers, layer.id);
  }

  layer.owner = user;

  if (user) {
    layer.layerOwner = { name: fullName(user), color: user.color, left: false };
  } else if (removeLayerOwner && layer.layerOwner) {
    layer.layerOwner = { ...layer.layerOwner, left: true };
  }

  if (user && !includes(user.ownedLayers, layer.id)) {
    user.ownedLayers.push(layer.id);
  }
}

export function setOwnedLayer(drawing: Drawing, layerId: number, user: User | undefined) {
  const layer = getLayer(drawing, layerId);

  if (layer) {
    setLayerOwner(layer, user);
  } else if (user && !includes(user.ownedLayers, layerId)) {
    user.ownedLayers.push(layerId);
  }
}

export function getSequenceThumbSize(drawing: Drawing) {
  const scale = Math.min(SEQUENCE_THUMB_WIDTH / drawing.w, SEQUENCE_THUMB_HEIGHT / drawing.h);
  const width = Math.ceil(drawing.w * scale);
  const height = Math.ceil(drawing.h * scale);
  return { width, height };
}

export function clipToDrawingRect(rect: Rect, drawing: Rect) {
  clipRect(rect, drawing.x, drawing.y, drawing.w, drawing.h);
}

export function getMainDrawingId(drawing: { id: string; sequenceMainDrawingId?: string; } | undefined) {
  return drawing?.sequenceMainDrawingId ?? drawing?.id;
}

export function getLayersOwnedByAccount(drawing: Drawing, accountId: string | undefined) {
  if (!accountId) return [];
  return drawing.layers.filter(l => l.owner && l.owner.accountId === accountId);
}
