import { redrawDrawing } from '../../services/editorUtils';
import { logAction } from '../actionLog';
import { IToolEditor, IToolModel, Surface, ToolId, Layer, CompositeOp, HistoryBufferEntry, IToolData, IFilterTool, IFiltersValues, Rect } from '../interfaces';
import { redrawLayerThumb } from '../layer';
import { createRectMask, isMaskEmpty } from '../mask';
import { addRect, cloneRect, copyRect, createRect, isRectEmpty, outsetRectForBlur, rectsEqual } from '../rect';
import { setupSurface } from '../toolSurface';
import { finishTransform } from '../toolUtils';
import { throwIfTextLayer } from '../text/text-utils';
import { isWebGL } from '../utils';
import { MOTION_BLUR_DISTANCE_MAX } from '../constants';

export interface IFilterData extends IFiltersValues, IToolData {
}

export abstract class BaseFilterTool implements IFilterTool {
  id = ToolId.None;
  name = '';
  nonDrawing = true;
  preview = true;
  protected values: IFiltersValues = {};
  protected layer: Layer | undefined = undefined;
  protected srcData: ImageData | undefined = undefined;
  snapshot: Surface | undefined = undefined;
  protected layerRect = createRect(0, 0, 0, 0);
  protected initialSurfaceRect = createRect(0, 0, 0, 0);
  protected surfaceRect = createRect(0, 0, 0, 0);
  protected dataRect = createRect(0, 0, 0, 0);
  protected layerRectPadding = 0;
  protected alwaysFetchSrcData = false;
  protected motionBlurOutset = false;
  protected gaussianBlurOutset = false;
  drawingBounds = createRect(0, 0, 0, 0);

  constructor(public editor: IToolEditor, public model: IToolModel, protected toolName: string) {
  }
  setup(data?: IFilterData | undefined): void {
    if (data) {
      if (!data.bounds) throw new Error('Missing drawing bounds');
      copyRect(this.drawingBounds, data.bounds);
    }
  }
  move() { }
  end() { }
  start() { }
  onLayerChange() {
    logAction(`[${this.model.type}] layer change ${this.toolName} (${this.preview})`);

    if (this.preview) {
      if (this.layer) this.cancel();
      this.init(this.editor.drawing);
      this.applyInternal();
    }
  }
  do(data?: IFilterData) {
    finishTransform(this.editor, this.model.user, 'BaseFilterTool:do');
    this.updateValues(data);
    if (!data?.bounds) throw new Error('Missing drawing bounds');
    this.init(data?.bounds);
    this.commit();
  }
  // TODO why this is not `setup` ? refactor it ?
  init(drawingBounds: Rect) {
    copyRect(this.drawingBounds, drawingBounds);

    const layer = this.model.user.activeLayer;
    if (!layer) throw new Error(`[${this.toolName}] Missing activeLayer`);
    if (isRectEmpty(layer.rect)) {
      throw new Error(`Active layer is empty`);
    }
    throwIfTextLayer(layer);

    logAction(`[${this.model.type}] init ${this.toolName} (layerId: ${layer.id})`);

    this.layer = layer;

    copyRect(this.layerRect, layer.rect);

    if (isMaskEmpty(this.model.user.selection)) {
      copyRect(this.surfaceRect, layer.rect);
    } else {
      copyRect(this.surfaceRect, this.model.user.selection.bounds);
    }

    copyRect(this.initialSurfaceRect, this.surfaceRect);

    if (this.motionBlurOutset) {
      this.setPadding();
    }

    if (!isRectEmpty(this.surfaceRect) && (this.motionBlurOutset || this.gaussianBlurOutset)) {
      const bounds = cloneRect(layer.rect);
      addRect(bounds, this.drawingBounds);
      outsetRectForBlur(this.surfaceRect, bounds, this.layerRectPadding);
    }

    copyRect(this.dataRect, this.surfaceRect);

    if (this.alwaysFetchSrcData || !isWebGL(this.editor.renderer)) {
      this.srcData = this.editor.renderer.getLayerImageData(layer, this.surfaceRect);
    }

    const src = layer.canvas || layer.texture;

    if (src && !isRectEmpty(layer.rect)) {
      this.snapshot = this.editor.renderer.createSurface('filter', layer.rect.w, layer.rect.h);
      this.editor.renderer.copyToSnapshot(src, this.snapshot, layer.rect.x - layer.textureX, layer.rect.y - layer.textureY, layer.rect.w, layer.rect.h, 0, 0);
    } else {
      this.snapshot = undefined;
    }
  }
  apply(values: IFiltersValues) {
    logAction(`[${this.model.type}] apply ${this.toolName}`, true);

    this.updateValues(values);
    this.reset();

    if (this.motionBlurOutset) {
      const needsInit = this.setPadding();
      if (needsInit) this.init(this.drawingBounds);
    }

    this.applyInternal();
  }
  save(values: IFiltersValues) {
    logAction(`[${this.model.type}] save ${this.toolName}`);

    this.updateValues(values);

    if (this.motionBlurOutset) {
      const needsInit = this.setPadding();
      if (needsInit) this.init(this.drawingBounds);
    }

    if (!this.preview) this.init(this.drawingBounds);

    if (isRectEmpty(this.layerRect)) {
      this.cancel();
    } else {
      this.reset();
      this.commit();
    }
  }
  cancel() {
    logAction(`[${this.model.type}] cancel ${this.toolName}`);

    if (!this.layer) return;

    this.reset();
    this.model.user.history.unpre();
    this.cleanup();
  }
  setPadding() {
    let needsInit = false;
    if (this.values.distance! > 1500 && this.layerRectPadding != 2000) {
      this.layerRectPadding = MOTION_BLUR_DISTANCE_MAX;
      needsInit = true;
    } else if (this.values.distance! > 1000 && this.values.distance! <= 1500 && this.layerRectPadding != 1500) {
      this.layerRectPadding = 1500;
      needsInit = true;
    } else if (this.values.distance! > 500 && this.values.distance! <= 1000 && this.layerRectPadding != 1000) {
      this.layerRectPadding = 1000;
      needsInit = true;
    } else if (this.values.distance! <= 500 && this.layerRectPadding != 500) {
      this.layerRectPadding = 500;
      needsInit = true;
    }

    return needsInit;
  }
  private reset() {
    if (!this.layer) throw new Error(`[${this.toolName}] Missing layer`);

    const entry: HistoryBufferEntry | undefined = this.snapshot ? {
      buffer: { sheets: [] }, rect: this.layerRect, x: 0, y: 0,
      sheet: { left: 0, bottom: 0, top: 0, entries: [], surface: this.snapshot }
    } : undefined;

    this.editor.renderer.restoreSnapshotToLayer(entry, this.layer, this.layerRect);
    this.editor.renderer.releaseUserCanvas(this.model.user);

    redrawDrawing(this.editor);
    redrawLayerThumb(this.layer);
  }
  private applyInternal() {
    const user = this.model.user;
    const layer = this.layer;

    if (isRectEmpty(this.layerRect)) return;
    if (!layer) throw new Error(`[${this.toolName}] Missing layer`);

    setupSurface(user.surface, this.id, CompositeOp.Draw, layer, this.drawingBounds);
    copyRect(this.surfaceRect, this.initialSurfaceRect);
    if (this.motionBlurOutset || this.gaussianBlurOutset) {
      let valueForOutset = (this.gaussianBlurOutset) ? this.values.radius : this.values.distance;
      const bounds = cloneRect(layer.rect);
      addRect(bounds, this.drawingBounds);
      outsetRectForBlur(this.surfaceRect, bounds, Math.round(valueForOutset || 0));
    }

    this.editor.renderer.copyLayerToSurface(layer, user.surface, this.surfaceRect);

    if (isMaskEmpty(user.selection)) {
      if (rectsEqual(this.surfaceRect, layer.rect)) {
        this.editor.renderer.releaseLayer(layer);
      } else {
        this.editor.renderer.cutLayer(layer, createRectMask(this.surfaceRect));
      }
    } else {
      this.editor.renderer.cutLayer(layer, user.selection);
    }

    this.applyFilter();

    this.editor.renderer.commitTool(user, false);

    redrawDrawing(this.editor);
    redrawLayerThumb(layer);
  }
  private commit() {
    if (!this.layer) throw new Error(`[${this.toolName}] Missing layer`);
    this.model.user.history.pushDirtyRect(this.toolName, this.layer.id, this.surfaceRect);
    this.applyInternal();
    this.model.doTool<IFilterData>(this.layer.id, { id: this.id, ...this.values, br: cloneRect(this.layerRect), ar: cloneRect(this.layer.rect), bounds: cloneRect(this.drawingBounds) });
    this.cleanup();
  }
  private cleanup() {
    this.srcData = undefined;
    this.layer = undefined;
    if (this.snapshot) this.snapshot = this.editor.renderer.releaseSurface(this.snapshot);
  }
  private updateValues(values?: IFiltersValues) {
    Object.assign(this.values, this.parseData(values));
  }
  protected abstract applyFilter(): void;
  protected abstract parseData(data?: IFiltersValues): IFiltersValues;
}
