import { createCanvas, ellipse, getContext2d } from '../common/canvasUtils';
import { colorFromRGBA, colorToCSS, colorToHexRGB, getAlpha, getB, getG, getR } from '../common/color';
import { BLACK, TEST_PRESSURE_OPACITY, WHITE } from '../common/constants';
import { BrushShape, IRenderingContext, Shader, Texture, ToolSurface, WebGLResources } from '../common/interfaces';
import { createMat2d, getMat2dX, getMat2dY, isMat2dTranslation, rotateMat2d, scaleMat2d, setMat2d, translateMat2d, identityMat2d } from '../common/mat2d';
import { fillPath, pushPath } from '../common/path';
import { shaders as shaderSources, vertexMeshShader, vertexShader as vertexSource } from '../common/shaders';
import { clamp } from '../common/mathUtils';
import { includes } from '../common/baseUtils';
import { isiPad } from '../common/userAgentUtils';
import { createVec2, setVec2, transformVec2ByMat2d } from '../common/vec2';
import { drawSoftBrush, fillCircle, fillPolyfgonWithPattern } from './renderingContext';
import {
  bindRenderTarget, bindTexture, clearTextureRect, createEmptyTexture, createShaderProgram,
  createWebGLShader, deleteTexture, initUniforms, unbindRenderTarget, unbindTexture
} from './webgl';
import { canPush, drawBatch, pushQuadTransformed, pushQuadXXYY, pushQuadXXYYTransformed, resetBatch } from './webglBatch';
import { pushRectOutline } from './webglBatchUtils';
import { drawUsingCanvas, findTextureHeight, findTextureWidth, frameBufferTransform, getTexture } from './webglRenderer';
import { getBrushShapeImage } from '../common/shapes';
import { decompressImageAlphaRLE } from '../common/rle';
import { getPolyfBounds } from '../common/poly';

const transformMatrix = createMat2d();
const preciseShaders = ['grid', 'checker'];

function createNamedShader({ gl, vertexShader }: WebGLResources, key: string) {
  if (!shaderSources[key]) throw new Error(`Invalid shader name: "${key}"`);
  const fragmentSouce = shaderSources[key];
  const highp = isiPad || includes(preciseShaders, key); // force high-precission on ipad because of rendering issues that show up otherwise
  const fragmentShader = createWebGLShader(gl, gl.FRAGMENT_SHADER, fragmentSouce, highp);
  const mesh = key === 'mesh'; // HACK
  const vertexShader2 = mesh ? createWebGLShader(gl, gl.VERTEX_SHADER, vertexMeshShader, true) : vertexShader;
  const vertexSource2 = mesh ? vertexMeshShader : vertexSource;
  const shader = createShaderProgram(gl, vertexShader2, fragmentShader, vertexSource2);
  gl.deleteShader(fragmentShader);
  shader.uniforms = initUniforms(gl, shader.program, { vertex: vertexSource2, fragment: fragmentSouce });
  return shader;
}

export function getShader(webgl: WebGLResources, name: string) {
  let shader = webgl.shaders.get(name);

  if (!shader) {
    shader = createNamedShader(webgl, name);
    webgl.shaders.set(name, shader);
  }

  return shader;
}

export function createWebGLRenderingContext(webgl: WebGLResources, surface: ToolSurface): IRenderingContext {
  const { gl, batch } = webgl;
  const target = surface.texture!;
  const transform = createMat2d();
  const v2 = createVec2();
  const opacityFallback = !('MAX' in gl);

  let currentShader: Shader | undefined = undefined;
  let currentOpacityShader: Shader | undefined = undefined;
  let texture: Texture | undefined = undefined;
  let flushed = true;
  let globalAlpha = 1;
  let lastColor = -1;
  let lastAlpha = 1;
  let r = 0, g = 0, b = 0, a = 0;
  let cachedColor = BLACK;
  let cachedString = '#000000';
  let usingMask = false;
  let biasForBrush = -1;

  function colorPremul(color: number) {
    if (lastColor !== color || lastAlpha !== globalAlpha) {
      a = getAlpha(color) * (1 / 255) * globalAlpha;
      const mul = a * (1 / 255);
      r = getR(color) * mul;
      g = getG(color) * mul;
      b = getB(color) * mul;
      lastColor = color;
      lastAlpha = globalAlpha;
    }
  }

  function colorToString(color: number) {
    if (cachedColor !== color) {
      cachedColor = color;
      cachedString = colorToCSS(cachedColor);
    }

    return cachedString;
  }

  function setupForDrawing() {
    bindRenderTarget(webgl, target);
    gl.enable(gl.BLEND);
    gl.blendEquation(gl.FUNC_ADD);
    gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
    if (texture) bindTexture(gl, 0, texture);
  }

  // function startDrawing() {
  //   flush();
  //   setupForDrawing();
  // }

  function endDrawing() {
    gl.disable(gl.BLEND);
    unbindRenderTarget(webgl);
    surface.texture!.hasMipmaps = false; // invalidate mipmaps
    if (texture) unbindTexture(gl, 0);
  }

  function continueBatch(shaderName: string, opacityShaderName: string | undefined = undefined) {
    const shader = getShader(webgl, shaderName);

    // make sure there's space for another sprite, we don't want to make batch flush itself
    if (currentShader !== shader || !canPush(batch, 2)) {
      flush();
      currentShader = shader;
      currentOpacityShader = (TEST_PRESSURE_OPACITY && opacityShaderName) ? getShader(webgl, opacityShaderName) : undefined;
    }

    flushed = false;
  }

  function flush() {
    if (currentShader && !flushed) {
      const drawMask = TEST_PRESSURE_OPACITY && usingMask && currentOpacityShader && !opacityFallback;

      if (drawMask && !surface.textureMask) {
        const textureWidth = findTextureWidth(webgl, surface.drawingRect.w);
        const textureHeight = findTextureHeight(webgl, surface.drawingRect.h);
        surface.textureMask = getTexture(webgl, textureWidth, textureHeight, 'texture-mask');
      }

      setupForDrawing();
      gl.useProgram(currentShader.program);
      gl.uniformMatrix4fv(currentShader.uniforms.transform, false, frameBufferTransform(webgl));
      gl.uniform1f(currentShader!.uniforms.biasForBrush, biasForBrush);
      drawBatch(batch);

      if (drawMask && currentOpacityShader) {
        bindRenderTarget(webgl, surface.textureMask!);
        gl.useProgram(currentOpacityShader.program);
        gl.uniformMatrix4fv(currentOpacityShader.uniforms.transform, false, frameBufferTransform(webgl));
        gl.uniform1f(currentOpacityShader!.uniforms.biasForBrush, biasForBrush);
        gl.blendEquation((gl as WebGL2RenderingContext).MAX);
        gl.blendFunc(gl.ONE, gl.ONE);
        drawBatch(batch);
        usingMask = false;
      }

      endDrawing();
      resetBatch(batch);
      flushed = true;
    }
  }

  function getMaskContext() {
    if (!surface.canvasMask) {
      const textureWidth = findTextureWidth(webgl, surface.drawingRect.w);
      const textureHeight = findTextureHeight(webgl, surface.drawingRect.h);
      surface.canvasMask = createCanvas(textureWidth, textureHeight); // TODO: use some cache
      const context = getContext2d(surface.canvasMask); // TODO: cache
      context.fillStyle = 'black';
      context.fillRect(0, 0, textureWidth, textureHeight);
    }

    return getContext2d(surface.canvasMask); // TODO: cache
  }

  return {
    gl: true,
    flush,
    opacity: 1,
    usingOpacity: false,
    get globalAlpha() {
      return globalAlpha;
    },
    set globalAlpha(value) {
      if (globalAlpha !== value) {
        if (DEVELOPMENT && ((value < 0) || (value > 1))) throw new Error(`Invalid globalAlpha: ${value}`);
        globalAlpha = clamp(value, 0, 1);
      }
    },
    translate(x, y) {
      translateMat2d(transform, transform, x, y);
    },
    rotate(angle) {
      rotateMat2d(transform, transform, angle);
    },
    scale(sx, sy) {
      scaleMat2d(transform, transform, sx, sy);
    },
    setTransform(m11, m12, m21, m22, x, y) {
      setMat2d(transform, m11, m12, m21, m22, x, y);
    },
    clearRect(x, y, w, h) {
      flush();
      clearTextureRect(webgl, target, x, y, w, h);
    },
    fillRect(color, x, y, w, h) {
      continueBatch('vertexColor');
      colorPremul(color);
      pushQuadTransformed(batch, transform, x, y, w, h, 0, 0, 0, 0, 0, 0, r, g, b, a);
    },
    strokeRect(color, strokeWidth, x, y, w, h) {
      if (DEVELOPMENT && !isMat2dTranslation(transform)) throw new Error('Only translation is supported for fillCircle');

      continueBatch('vertexColor');
      setVec2(v2, x, y);
      transformVec2ByMat2d(v2, v2, transform);
      colorPremul(color);
      pushRectOutline(batch, v2[0], v2[1], w, h, strokeWidth, r, g, b, a);
    },
    fillCircle(color, cx, cy, radius, roundness, angle) {
      if (radius <= 0) return;
      if (DEVELOPMENT && !isMat2dTranslation(transform)) throw new Error('Only translation is supported for fillCircle');

      continueBatch('circle', 'circleOpacity');

      cx += getMat2dX(transform);
      cy += getMat2dY(transform);

      const radiusWithBorder = radius + 2;
      const roundnessFactor = Math.round(radiusWithBorder - radiusWithBorder * roundness);
      const x0 = Math.floor(cx - radiusWithBorder);
      const y0Tex = Math.floor(cy - radiusWithBorder);
      const y0 = Math.floor(cy - radiusWithBorder) + roundnessFactor;
      const x1 = Math.ceil(cx + radiusWithBorder);
      const y1Tex = Math.ceil(cy + radiusWithBorder);
      const y1 = Math.ceil(cy + radiusWithBorder) - roundnessFactor;

      if (this.usingOpacity) usingMask = true;

      colorPremul(color);

      if (angle == 0) {
        pushQuadXXYY(batch, x0, y0, x1, y1, x0 - cx, y0Tex - cy, x1 - cx, y1Tex - cy, radius, this.opacity, r, g, b, a);
        
      } else {
        identityMat2d(transformMatrix);
        translateMat2d(transformMatrix, transformMatrix, cx, cy);
        rotateMat2d(transformMatrix, transformMatrix, angle);
        pushQuadXXYYTransformed(batch, transformMatrix, -radiusWithBorder, -radiusWithBorder + roundnessFactor, radiusWithBorder, radiusWithBorder - roundnessFactor, x0 - cx, y0Tex - cy, x1 - cx, y1Tex - cy, radius, this.opacity, r, g, b, a);
      }

      if (usingMask && opacityFallback) {
        const context = getMaskContext();
        context.fillStyle = `rgb(${this.opacity * 255}, ${this.opacity * 255}, ${this.opacity * 255})`;
        context.globalCompositeOperation = 'lighten';
        fillCircle(context, cx, cy, radius, roundness, angle);
      }
    },
    // NOTE: this only works for single ellipse on the canvas
    fillEllipse(color, cx, cy, rx, ry) {
      if (rx <= 0 || ry <= 0) return;
      if (DEVELOPMENT && !isMat2dTranslation(transform)) throw new Error('Only translation is supported for fillEllipse');

      // TODO: do transform on 2d context side

      drawUsingCanvas(webgl, target, cx - rx, cy - ry, rx * 2, ry * 2, 0, (context, x0, y0) => {
        context.fillStyle = colorToString(color);
        context.beginPath();
        context.globalAlpha = globalAlpha;
        ellipse(context, cx - x0, cy - y0, rx, ry, 0);
        context.fill();
        context.globalAlpha = 1;
      });

      /*startDrawing();
      cx += getMat2dX(transform);
      cy += getMat2dY(transform);
      const x0 = Math.floor(cx - (rx + 2));
      const y0 = Math.floor(cy - (ry + 2));
      const x1 = Math.ceil(cx + (rx + 2));
      const y1 = Math.ceil(cy + (ry + 2));
      const shader = getShader(webgl, 'ellipse');
      gl.useProgram(shader.program);
      gl.uniformMatrix4fv(shader.uniforms.transform, false, drawingTransform);
      gl.uniform2f(shader.uniforms.radius, rx, ry);
      gl.uniform2f(shader.uniforms.oneByRadiusSq, 1 / (rx * rx), 1 / (ry * ry));
      startBatch(batch);
      colorPremul(color);
      pushQuadXXYY(batch, x0, y0, x1, y1, x0 - cx, y0 - cy, x1 - cx, y1 - cy, 0, 0, r, g, b, a);
      endBatch(batch);
      endDrawing();*/
    },
    // NOTE: this only works for single ellipse on the canvas
    strokeEllipse(color, strokeWidth, cx, cy, rx, ry) {
      if (rx <= 0 || ry <= 0) return;
      if (DEVELOPMENT && !isMat2dTranslation(transform)) throw new Error('Only translation is supported for strokeEllipse');

      // TODO: do transform on 2d context side

      const pad = Math.ceil(strokeWidth / 2) + 1;

      drawUsingCanvas(webgl, target, cx - rx, cy - ry, rx * 2, ry * 2, pad, (context, x0, y0) => {
        context.strokeStyle = colorToString(color);
        context.lineWidth = strokeWidth;
        context.beginPath();
        context.globalAlpha = globalAlpha;
        ellipse(context, cx - x0, cy - y0, rx, ry, 0);
        context.stroke();
        context.globalAlpha = 1;
      });

      /*startDrawing();
      cx += getMat2dX(transform);
      cy += getMat2dY(transform);
      const pad = 1 + strokeWidth / 2;
      const x0 = Math.floor(cx - (rx + pad));
      const y0 = Math.floor(cy - (ry + pad));
      const x1 = Math.ceil(cx + (rx + pad));
      const y1 = Math.ceil(cy + (ry + pad));
      const shader = getShader(webgl, 'ellipseOutlineOld');
      gl.useProgram(shader.program);
      gl.uniformMatrix4fv(shader.uniforms.transform, false, drawingTransform);
      gl.uniform2f(shader.uniforms.radius, rx, ry);
      gl.uniform1f(shader.uniforms.lineWidth, strokeWidth);
      startBatch(batch);
      colorPremul(color);
      pushQuadXXYY(batch, x0, y0, x1, y1, x0 - cx, y0 - cy, x1 - cx, y1 - cy, 0, 0, r, g, b, a);
      endBatch(batch);
      endDrawing();*/
    },
    fillPath(color, x, y, w, h, path) {
      if (w === 0 || h === 0) return;
      if (DEVELOPMENT && !isMat2dTranslation(transform)) throw new Error('Only translation is supported for fillPath');

      // TODO: angle
      // TODO: do transform on 2d context side

      drawUsingCanvas(webgl, target, x, y, w, h, 0, (context, x0, y0) => {
        context.save();
        context.fillStyle = colorToString(color);
        context.translate(x - x0, y - y0);
        context.scale(w / path.width, h / path.height);
        context.globalAlpha = globalAlpha;
        fillPath(context, path);
        context.restore();
      });
    },
    fillPolyfgon(color, shape, angle, patternScale, polyf) {
      if (DEVELOPMENT && !isMat2dTranslation(transform)) throw new Error('Only translation is supported for fillPolyfgon');

      const { x, y, w, h } = getPolyfBounds(polyf);

      drawUsingCanvas(webgl, target, x - surface.textureX, y - surface.textureY, w, h, 0, (context, x0, y0) => {
        context.save();
        context.globalAlpha = globalAlpha;
        context.translate(-x0 - surface.textureX, -y0 - surface.textureY);
        fillPolyfgonWithPattern(colorToString, context, color, shape, angle, patternScale, polyf);
        context.restore();
      });
    },
    strokePath(color, strokeWidth, x, y, w, h, path) {
      if (w === 0 || h === 0) return;
      if (DEVELOPMENT && !isMat2dTranslation(transform)) throw new Error('Only translation is supported for strokePath');

      // TODO: angle
      // TODO: do transform on 2d context side

      const pad = Math.ceil(strokeWidth / 2) + 1;

      drawUsingCanvas(webgl, target, x, y, w, h, pad, (context, x0, y0) => {
        context.save();
        context.translate(x - x0, y - y0);
        context.scale(w / path.width, h / path.height);
        context.beginPath();
        pushPath(context, path);
        context.restore();

        context.save();
        context.strokeStyle = colorToString(color);
        context.lineWidth = strokeWidth;
        context.lineJoin = 'round';
        context.stroke();
        context.restore();
      });
    },
    drawSoftBrush(brush, color, originalRadius, baseSize, hardness, x, y, roundness, angle) {
      if (DEVELOPMENT && !isMat2dTranslation(transform)) throw new Error('Only translation is supported for drawSoftBrush');

      continueBatch('softBrush2', 'softBrush2Opacity');

      x += getMat2dX(transform);
      y += getMat2dY(transform);

      const size = originalRadius * 2;
      const actualSize = Math.max(size, 1.0);
      const alphaMul = Math.min(size, 1.0);
      const radius = (actualSize / 2) + 0.5;
      const radiusWithBorder = radius + 2;
      const roundnessFactor = (radiusWithBorder - radiusWithBorder * roundness);

      const x0 = Math.floor(x - radiusWithBorder);
      const y0Tex = Math.floor(y - radiusWithBorder);
      const y0 = Math.floor(y - radiusWithBorder) + roundnessFactor;
      const x1 = Math.ceil(x + radiusWithBorder);
      const y1Tex = Math.ceil(y + radiusWithBorder);
      const y1 = Math.ceil(y + radiusWithBorder) - roundnessFactor;

      if (this.usingOpacity) usingMask = true;

      const alpha = globalAlpha;
      globalAlpha *= alphaMul;
      colorPremul(color);
      globalAlpha = alpha;

      if (angle == 0) {
        pushQuadXXYY(batch, x0, y0, x1, y1, (x0 - x) / radius, (y0Tex - y) / radius, (x1 - x) / radius, (y1Tex - y) / radius,
        hardness, this.opacity, r, g, b, a);
      } else {    
        identityMat2d(transformMatrix);
        translateMat2d(transformMatrix, transformMatrix, x, y);
        rotateMat2d(transformMatrix, transformMatrix, angle);
        pushQuadXXYYTransformed(batch, transformMatrix, -radiusWithBorder, -radiusWithBorder + roundnessFactor, radiusWithBorder, radiusWithBorder - roundnessFactor, (x0 - x) / radius, (y0Tex - y) / radius, (x1 - x) / radius, (y1Tex - y) / radius, hardness, this.opacity, r, g, b, a);
      }

      if (usingMask && opacityFallback) {
        const context = getMaskContext();
        context.globalCompositeOperation = 'lighten';
        const c = Math.round(this.opacity * 255) | 0;
        const color = colorFromRGBA(c, c, c, 255);
        drawSoftBrush(context, brush, color, originalRadius, baseSize, hardness, x, y, roundness, grayToString, true);
        context.globalCompositeOperation = 'source-over';
      }
    },
    drawImageBrush(shape, color, x, y, size, roundness) {
      continueBatch('imageBrush', 'imageBrushOpacity');

      biasForBrush = (roundness == 1) ? -1 : Math.floor(Math.log2(roundness));

      // TODO: clear/set texture in continue batch, so you can combine different brushes in one context ?
      texture = texture ?? createBrushTexture(webgl, shape);

      if (this.usingOpacity) usingMask = true;

      colorPremul(color);

      let m = 1;

      if (size < 1) {
        m = size;
        size = 1;
      }

      const s = size + 2;
      const ts = (170 / 512) * (s / size); // size of image in texcoords
      const to = (1 - ts) / 2; // offset from edge of texture to start of image
      pushQuadTransformed(batch, transform, x - s * 0.5, y - s * 0.5, s, s, to, to, ts, ts, 0,
        this.opacity, r * m, g * m, b * m, a * m);

      // TODO: opacityFallback
    },
    dispose() {
    },
    marker(x, y, color) {
      if (DEVELOPMENT) webgl.markers.push({ x, y, color });
    },
  };
}

export function createBrushTexture(webgl: WebGLResources, shape: BrushShape) {
  const { gl, brushCache } = webgl;
  // TODO: replace with `createCachedGenerator`
  for (let i = 0; i < brushCache.length; i++) {
    if (brushCache[i].id === shape.id) {
      const t = brushCache[i];
      brushCache[i] = brushCache[brushCache.length - 1];
      brushCache[brushCache.length - 1] = t; // put at the end of list (as last used)
      return t.texture;
    }
  }

  let texture: Texture;

  // vector paths and images of different size than 170
  if (shape.path || (shape.imageData && (shape.imageData.width !== 170 || shape.imageData.height !== 170))) {
    const image = getBrushShapeImage(shape);
    if (!image) throw new Error('Cannot get brush shape image');
    if (DEVELOPMENT && (image.width !== 170 || image.height !== 170)) {
      throw new Error('Invalid brush image size');
    }

    texture = createEmptyTexture(webgl, 512, 512);
    gl.bindTexture(gl.TEXTURE_2D, texture.handle);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
    gl.texSubImage2D(gl.TEXTURE_2D, 0, 171, 171, gl.RGBA, gl.UNSIGNED_BYTE, image);
    gl.generateMipmap(gl.TEXTURE_2D);
    texture.hasMipmaps = true;
    gl.bindTexture(gl.TEXTURE_2D, null);
  } else if (shape.imageData) {
    // TODO: move handling different sized images here, manually scale them up ? or render to texture ?

    const image = decompressImageAlphaRLE(
      shape.imageData.compressed, shape.imageData.width, shape.imageData.height, WHITE,
      (width, height, buffer) => ({ width, height, data: buffer ?? new Uint8ClampedArray(width * height * 4), colorSpace: 'srgb' }));

    // TODO: alpha-only texture ? for some reason initializing didn't work
    texture = createEmptyTexture(webgl, 512, 512);
    gl.bindTexture(gl.TEXTURE_2D, texture.handle);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
    gl.texSubImage2D(gl.TEXTURE_2D, 0, 171, 171, image.width, image.height, gl.RGBA, gl.UNSIGNED_BYTE, image.data);
    gl.generateMipmap(gl.TEXTURE_2D);
    texture.hasMipmaps = true;
    gl.bindTexture(gl.TEXTURE_2D, null);
  } else {
    throw new Error('Cannot get brush shape image');
  }

  const limit = SERVER ? 10 : 5;

  while (brushCache.length >= limit) {
    deleteTexture(gl, brushCache.shift()!.texture);
  }

  brushCache.push({ id: shape.id, texture });
  return texture;
}

const grays = new Map<number, string>();

function grayToString(color: number) {
  let s = grays.get(color);

  if (!s) {
    s = colorToHexRGB(color);
    grays.set(color, s);
  }

  return s;
}
