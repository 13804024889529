import { saveAs } from 'file-saver';
import type { Model } from '../services/model';
import { TimingEntry, AsyncTimingEntry, ClientDrawingData, LoadingStats } from './interfaces';

export let loadingFailures = 0;
export const loadingEntries: TimingEntry[] = [];
export const asyncLoadingEntries: AsyncTimingEntry[][] = [];
const mockEntry: AsyncTimingEntry = { n: '', f: 0, t: 0 };

export function loadingReset() {
  loadingFailures = 0;
  loadingEntries.length = 0;
  asyncLoadingEntries.length = 0;
}

export function loadingStart(n: string, c?: string) {
  if (!SERVER) loadingEntries.push({ n, c, t: performance.now() });
}

export function loadingEnd() {
  if (!SERVER) loadingEntries.push({ t: performance.now() });
}

export function asyncLoadingTrack() {
  asyncLoadingEntries.push([]);
  return asyncLoadingEntries[asyncLoadingEntries.length - 1];
}

export function asyncLoadingStart(track: AsyncTimingEntry[], n: string, c?: string) {
  if (SERVER) return mockEntry;
  let entry: AsyncTimingEntry;
  track.push(entry = { n, c, f: performance.now(), t: 0 });
  return entry;
}

export function asyncLoadingEnd(entry: AsyncTimingEntry) {
  if (!SERVER) entry.t = performance.now();
}

export function asyncLoadingAt(track: AsyncTimingEntry[], n: string, start: number, end: number, c?: string) {
  track.push({ n, c, f: start, t: end });
}

export function loadingFailure() {
  loadingFailures++;
}

let hiddenTrack: AsyncTimingEntry[] = [];
let hiddenEntry: AsyncTimingEntry | undefined = undefined;
let hiddenStart = 0;

function loadingVisibilityChange() {
  if (document.hidden) {
    hiddenEntry = asyncLoadingStart(hiddenTrack, 'hidden', 'e64f80');
  } else {
    if (hiddenEntry) asyncLoadingEnd(hiddenEntry);
    hiddenEntry = undefined;
  }
}

export function startLoadingStats() {
  if (SERVER) return;

  performance.clearResourceTimings?.();
  loadingReset();
  hiddenEntry = undefined;

  hiddenStart = performance.now();
  hiddenTrack = asyncLoadingTrack();
  if (document.hidden) hiddenEntry = asyncLoadingStart(hiddenTrack, 'hidden', 'e64f80');

  document.addEventListener('visibilitychange', loadingVisibilityChange);
}

export function reportLoadingStats(model: Model, drawing: ClientDrawingData, renderer: string, error: string | undefined) {
  if (SERVER) return;

  const resources = (performance.getEntriesByType?.('resource') ?? []) as PerformanceResourceTiming[];

  function toMB(value: number) {
    return value == null ? '?' : `${(value / (1024 * 1024)).toFixed(1)}`;
  }

  for (const r of resources) {
    const name = r.name.substring(r.name.lastIndexOf('/') + 1);
    const track = asyncLoadingTrack();
    asyncLoadingAt(track, `redirect (${name})`, r.redirectStart, r.redirectEnd);
    asyncLoadingAt(track, `appcache (${name})`, r.fetchStart, r.domainLookupStart);
    asyncLoadingAt(track, `dns (${name})`, r.domainLookupStart, r.domainLookupEnd);
    asyncLoadingAt(track, `tcp (${name})`, r.connectStart, r.connectEnd);
    asyncLoadingAt(track, `request (${name})`, r.requestStart, r.responseStart);
    asyncLoadingAt(track, `response (${name}) [${toMB(r.encodedBodySize)} / ${toMB(r.decodedBodySize)} MB]`,
      r.responseStart, r.responseEnd);
  }

  let hidden = 0;

  if (hiddenEntry) {
    asyncLoadingEnd(hiddenEntry);

    const totalTime = performance.now() - hiddenStart;
    let hiddenTime = 0;

    for (const entry of hiddenTrack) {
      hiddenTime += entry.t - entry.f;
    }

    hidden = hiddenTime / totalTime;
  }

  document.removeEventListener('visibilitychange', loadingVisibilityChange);

  const nav = navigator as any;
  const con = nav.connection || nav.mozConnection || nav.webkitConnection;
  const mem = (performance as any).memory;
  const stats: LoadingStats = {
    sync: loadingEntries,
    async: asyncLoadingEntries,
    error,
    drawingId: drawing.id,
    width: drawing.w,
    height: drawing.h,
    layers: drawing.layers.length,
    renderer,
    userAgent: navigator.userAgent,
    time: performance.now() - (loadingEntries[0]?.t ?? performance.now()),
    hidden,
    fails: loadingFailures,
    info: {
      connection: con && {
        downlink: con.downlink,
        downlinkMax: con.downlinkMax,
        effectiveType: con.effectiveType,
        rtt: con.rtt,
        saveData: con.saveData,
        type: con.type,
      },
      memory: mem && {
        jsHeapSizeLimit: toMB(mem.jsHeapSizeLimit),
        totalJSHeapSize: toMB(mem.totalJSHeapSize),
        usedJSHeapSize: toMB(mem.usedJSHeapSize),
      },
      cpus: nav.hardwareConcurrency,
      ram: nav.deviceMemory ? `${nav.deviceMemory} GB` : '-',
    },
  };

  const json = JSON.stringify(stats);
  (window as any).saveLoadingStats = () => {
    saveAs(new Blob([json], { type: 'text/plain' }), 'loading-stats.json');
  };
  model.server.debug(model.connId, 'load', json);
}
