import { ProjectType } from 'magma/common/interfaces';
import type { ID, UserDocument } from 'server/dao/types';
import { MaxLength, MinLength } from '@deepkit/type';
import { MAX_POST_DESCRIPTION_LENGTH, MAX_POST_TITLE_LENGTH } from './constants';

export enum PostType {
  // Drawing = 'drawing',
  // Directory = 'directory',
  Link = 'link',
  Video = 'video',
  Article = 'article',
}

export interface Post {
  _id: string;
  type: PostType;
  project: string; // id of project in which post exist within
  url: string; // url to post itself
  thumbnail: string; // url to thumbnail
  // title below entity thumbnail in grid view
  title: string & MinLength<1> & MaxLength<typeof MAX_POST_TITLE_LENGTH>;
  description: string & MaxLength<typeof MAX_POST_DESCRIPTION_LENGTH>; // markdown description visible in modal
  order: number;
  highlightOrder: number;
  createdAt: string;
  withModal: boolean;
  notificationTag: string;
  team: string;
}

export interface CreatePostData {
  type: PostType;
  project: ID;
  url?: string;
  thumbnail?: string;
  title: Post['title'];
  description?: Post['description'];
  withModal: boolean;
  creator: UserDocument['_id'];
  notificationTag: string;
}

export type UpdatePostData = Omit<Partial<Post>, 'project' | 'type'>;

export const COMMUNITY_HUB_TEAM_ID = 'e07f1d11c5e0f34e04cef85c';
export const COMMUNITY_HUB_TEAM_NAME = 'Community Hub';
export const BASE_COMMUNITY_HUB_PROJECT = {
  team: COMMUNITY_HUB_TEAM_ID,
  type: ProjectType.ContentPage,
};
