import { MOTION_BLUR_ANGLE_MAX, MOTION_BLUR_ANGLE_MIN, MOTION_BLUR_DISTANCE_MAX, MOTION_BLUR_DISTANCE_MIN } from '../constants';
import { IFiltersValues, IToolEditor, IToolModel, ToolId } from '../interfaces';
import { safeFloat } from '../toolUtils';
import { BaseFilterTool, IFilterData } from './baseFilterTool';

export class MotionBlurTool extends BaseFilterTool {
  id = ToolId.MotionBlur;
  name = 'Motion Blur';
  constructor(public editor: IToolEditor, public model: IToolModel) {
    super(editor, model, 'motionBlur');
    this.layerRectPadding = 500; // value for a start
    this.motionBlurOutset = true;
  }
  protected applyFilter() {
    if (!this.layer) throw new Error(`[${this.toolName}] Missing layer`);
    this.editor.renderer.applyMotionBlurFilter(this.srcData, this.dataRect, this.model.user.surface, this.values);
  }
  protected parseData(data?: IFilterData): IFiltersValues {
    const angle = safeFloat(data?.angle, MOTION_BLUR_ANGLE_MIN, MOTION_BLUR_ANGLE_MAX);
    const distance = safeFloat(data?.distance, MOTION_BLUR_DISTANCE_MIN, MOTION_BLUR_DISTANCE_MAX);
    return { angle, distance };
  }
}
