import { createCanvas } from 'canvas';
import { createEmptyTexture, bindTexture, unbindTexture } from '../services/webgl';
import { pushQuad, flushBatch } from '../services/webglBatch';
import { copyCanvasToTexture, identityViewMatrix } from '../services/webglRenderer';
import { getShader } from '../services/webglRenderingContext';
import { getContext2d } from './canvasUtils';
import { WebGLResources } from './interfaces';

const samples: number[] = [];


export enum BenchmarkType {
  DrawDrawing,
  DrawViewport,
  RAF,
  RAF_FPS,
  None
}

export const benchmark = {
  showGraph: false,
  type: BenchmarkType.None,
  flags: {
    drawDrawing: false,
    drawDrawingPartial: false,
    drawViewport: false,
    drawViewportPartial: false
  }
};

function sampleCount() {
  if (benchmark.type === BenchmarkType.RAF_FPS) return 1;
  return 15;
}

function frameSkip() {
  return 15;
}

let lastAverage = 0;

export function getBenchmarkLastAverage() {
  return lastAverage;
}

export function benchmarkCollect(n: number) {
  samples.push(n);

  if (samples.length > sampleCount()) {
    lastAverage = samples.reduce((sum, val) => sum + val, 0) / samples.length;
    samples.length = 0;
  }
}


let benchmarkCanvas: HTMLCanvasElement | undefined = undefined;
let benchmarkContext: CanvasRenderingContext2D | undefined = undefined;

let counter = 0;
export function drawBenchmark(webgl: WebGLResources) {
  const { gl, batch } = webgl;

  if (counter > frameSkip()) counter = 0;
  if (counter === 0) {
    if (!benchmarkCanvas || !benchmarkContext) {
      benchmarkCanvas = createCanvas(400, 170);
      benchmarkContext = getContext2d(benchmarkCanvas);
      benchmarkContext.fillStyle = '#22222250';
      benchmarkContext.fillRect(0, 0, benchmarkCanvas.width, benchmarkCanvas.height);
    }
    benchmarkContext.globalCompositeOperation = 'copy';
    benchmarkContext.drawImage(benchmarkContext.canvas, 2, 0);
    benchmarkContext.globalCompositeOperation = 'source-over';

    const value = getBenchmarkLastAverage() * (benchmark.type === BenchmarkType.RAF_FPS ? 1 : 10);

    benchmarkContext.fillStyle = '#22222250';
    benchmarkContext.fillRect(0, 0, 2, benchmarkCanvas.height - value);
    benchmarkContext.fillStyle = 'lime';
    benchmarkContext.fillRect(0, benchmarkCanvas.height - value, 2, value);

    benchmarkContext.fillStyle = 'blue';
    benchmarkContext.fillRect(250, 0, 150, 24);

    benchmarkContext.fillStyle = 'white';
    benchmarkContext.font = `20px Monospace`;

    benchmarkContext.fillText(`${getBenchmarkLastAverage().toFixed(2)} ${benchmark.type === BenchmarkType.RAF_FPS ? 'fps' : 'ms'}`, 260, 20);

    if (!webgl.benchmarkTexture) {
      webgl.benchmarkTexture = createEmptyTexture(webgl, benchmarkCanvas.width, benchmarkCanvas.height);
    }

    copyCanvasToTexture(webgl.gl, benchmarkContext, webgl.benchmarkTexture, benchmarkCanvas.width, benchmarkCanvas.height);
  }
  counter++;

  if (webgl.benchmarkTexture) {
    const shader = getShader(webgl, 'basic');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight));

    bindTexture(gl, 0, webgl.benchmarkTexture);
    pushQuad(batch, gl.drawingBufferWidth - 420, 20, webgl.benchmarkTexture.width, webgl.benchmarkTexture.height, 0, 0, 1, 1, 0, 0, 1, 1, 1, 1);
    flushBatch(batch);
    unbindTexture(gl, 0);
  }
}
