import { Component, EventEmitter, Output } from '@angular/core';
import { Model } from '../../../../services/model';
import { ToastService } from '../../../../services/toast.service';
import { MAX_REVISION_LABEL_LENGTH } from '../../../../common/constants';
import { faSpinner } from '../../../../generated/fa-icons';


@Component({
  selector: 'version-history-create-modal',
  templateUrl: 'version-history-create-modal.pug',
  styleUrls: ['./version-history-create-modal.scss'],
})
export class VersionHistoryCreateModalComponent {
  spinnerIcon = faSpinner;
  MAX_REVISION_LABEL_LENGTH = MAX_REVISION_LABEL_LENGTH;
  @Output() close = new EventEmitter<boolean | undefined>();

  label = '';
  saving = false;

  constructor(private model: Model, private toastService: ToastService) { }

  async save() {
    try {
      this.saving = true;
      await this.model.createDrawingRevision(this.label);
      this.onClose();
    } catch (e) {
      this.toastService.error({ message: 'Failed to create revision', subtitle: e.message });
    }
    this.saving = false;
  }

  onClose() {
    this.close.emit(undefined);
  }
}
