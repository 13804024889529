import { Analytics, hasAnalyticsLayerType, BrushFeature, BrushToolSettings, } from './interfaces';
import { TextareaType } from './text/textarea';
import { TextareaControlPointDirections } from './text/control-points';
import type { Editor } from '../services/editor';
import { invalidEnum, hasFlag } from './baseUtils';

export type TextToolAnalytics = Analytics.TextToolSelectedFontFamily | Analytics.TextToolSelectedFontStyle | Analytics.SelectedColor | Analytics.TextToolSelectedBoxType;
export type TextToolAnalyticsEvents = TextToolSelectedFontFamilyEvent | TextToolSelectedFontStyleEvent | SelectedColorEvent | TextToolChangedTextBoxTypeEvent;

type TextToolSelectionAnalyticsString = 'undefined' | 'global' | 'caret' | 'full' | 'partial';
interface HasTextToolSelectionAnalyticsString {
  currentTextSelection: TextToolSelectionAnalyticsString;
}

interface TextToolSelectedFontSomething extends HasTextToolSelectionAnalyticsString {
  fontFamily: string;
  fontStyle: string;
}

export interface TextToolSelectedFontFamilyEvent extends TextToolSelectedFontSomething {
  needsToLoadFontFirst: boolean;
  source: 'click' | 'wheel' | 'arrows' | 'esc';
  searchedFor?: string;
}

export interface TextToolSelectedFontStyleEvent extends TextToolSelectedFontSomething {
  source: 'keydown:bold' | 'keydown:italic' | 'button:bold' | 'button:italic' | 'dropdown';
}

export interface SelectedColorEvent extends hasAnalyticsLayerType, Partial<HasTextToolSelectionAnalyticsString> {
  source: 'input' | 'color-wheel' | 'switch-colors' | 'eyedropper' | 'tool-preset-change' | 'fg-swatch' | 'bg-swatch';
  currentTool: string;
  currentTextFocus?: boolean;
  immediateEffect?: 'text-color-change'
}

export interface TextToolChangedTextBoxTypeEvent {
  from: TextareaType;
  to: TextareaType;
  source: 'button' | `resizing:${TextareaControlPointDirections}`;
}

export interface RasterizedTextLayerEvent {
  source: 'layer-context-menu' | 'confirmation-prompt';
}

export interface TransformedLayerEvent extends hasAnalyticsLayerType {
  currentTool: string;
  operation: 'move' | 'rotate' | 'scale' | 'resize' | 'move-origin' | 'cover' | 'fit' | 'reset';
  source: 'mouse' | 'touch' | 'pen' | 'keyboard' | 'button:rotateCW' | 'button:rotateCCW' | 'button:flipX' | 'button:flipY' | 'button:fit' | 'button:cover' | 'button:reset';
}

export interface StopScreensharingEvent {
  source: 'video-box' | 'voice-menu';
}

export interface EditorInabilityStateEvent {
  state: EditorInabilityStateId;
  reason: string;
  timeInSuspension: number;
  waitingPeriods: number;
  leftPage: boolean;
}

export interface PendingEditorInabilityState {
  startedAt: number;
  reason: string;
}

export enum EditorInabilityStateId {
  Locked = 'Locked', // lockEditor(), unlockEditor()
  Loading = 'Loading',  // model.loaded() setter
  Connecting = 'Connecting', // ClientActions: .connected(), .disconnectedInternal()
  WaitingForServer = 'Waiting for server', // model.connectionIssues
  LoadingFonts = 'Loading fonts', // fonts.ts
  Importing = 'Importing', // importUtils.ts
}

export function clearEditorInabilityStates(editor: Editor, leaving: boolean) {
  for (const state of editor.pendingInabilityStates.keys()) {
    exitEditorInabilityState(editor, state, leaving);
  }
}

export function exitEditorInabilityState(editor: Editor, id: EditorInabilityStateId, leaving: boolean) {
  const state = editor.pendingInabilityStates.get(id);
  if (state) {
    editor.track?.event<EditorInabilityStateEvent>(Analytics.EditorLocked, {
      state: id,
      reason: state.reason,
      timeInSuspension: performance.now() - state.startedAt,
      waitingPeriods: editor.inabilityStatesCount,
      leftPage: leaving,
    });
    editor.pendingInabilityStates.delete(id);
  }
}

export function enterEditorInabilityState(editor: Editor, state: EditorInabilityStateId, reason: string){
  if (!editor.pendingInabilityStates.has(state)) {
    editor.pendingInabilityStates.set(state, { reason, startedAt: performance.now() });
    editor.inabilityStatesCount++;
  }
}

export interface PerspectiveGridModeEvent {
  mode: string
}

export function brushFeatureToString(tab: BrushFeature) {
  switch (tab) {
    case BrushFeature.BrushTipShape: return 'Brush Tip Shape';
    case BrushFeature.ShapeDynamics: return 'Shape Dynamics';
    case BrushFeature.Scattering: return 'Scattering';
    case BrushFeature.Texture: return 'Texture';
    case BrushFeature.DualBrush: return 'Dual Brush';
    case BrushFeature.ColorDynamics: return 'Color Dynamics';
    case BrushFeature.Transfer: return 'Transfer';
    case BrushFeature.BrushPose: return 'Brush Pose';
    default: invalidEnum(tab);
  }
}

export function brushFeaturesToString(features: BrushToolSettings['hasFeatures']) {
  let str = 'Brush Tip Shape';
  if (hasFlag(features, BrushFeature.ShapeDynamics)) str += `, ${brushFeatureToString(BrushFeature.ShapeDynamics)}`;
  if (hasFlag(features, BrushFeature.Scattering)) str += `, ${brushFeatureToString(BrushFeature.Scattering)}`;
  if (hasFlag(features, BrushFeature.Texture)) str += `, ${brushFeatureToString(BrushFeature.Texture)}`;
  if (hasFlag(features, BrushFeature.DualBrush)) str += `, ${brushFeatureToString(BrushFeature.DualBrush)}`;
  if (hasFlag(features, BrushFeature.ColorDynamics)) str += `, ${brushFeatureToString(BrushFeature.ColorDynamics)}`;
  if (hasFlag(features, BrushFeature.Transfer)) str += `, ${brushFeatureToString(BrushFeature.Transfer)}`;
  if (hasFlag(features, BrushFeature.BrushPose)) str += `, ${brushFeatureToString(BrushFeature.BrushPose)}`;
  return str;
}
