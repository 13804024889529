import { DocumentStatuses } from 'shared/constants';
import { UserData } from 'shared/interfaces';

export interface EntityStatus {
  state: DocumentStatuses;
  notify: UserData[]; //  | ObjectId[] | UserDocument[] | UserData[];
  lastChange: {
    at: Date;
    by: UserData;
  };
}

export enum EntityType {
  Folder = 'Folder',
  Drawing = 'Drawing',
  Flowchart = 'Flowchart',
}
