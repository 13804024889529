import { UserData } from 'shared/interfaces';
import { getPageTitle } from 'shared/title-generator';
import { AI_ONBOARDING_FLOWS_IDS, ONBOARDING_FLOWS_IDS, UserFlowId } from 'magma/common/constants';

export interface ViewPageEvent {
  page: string;
  url: string;
  screenResolution: string | undefined;
  viewport: string;
  isAnonymous: boolean;
  missingFetch?: boolean;
}

export function getViewPageEvent(user: UserData | undefined, url = window.location.pathname): ViewPageEvent {
  let page = getPageTitle(url);
  if (!page && url.startsWith('/d/')) page = 'Drawing';

  const w = window;
  const screenResolution = (w.screen?.width && w.screen.height) ? `${w.screen?.height}x${w.screen?.width}` : undefined;
  const viewport = `${w.innerHeight}x${w.innerWidth}`;
  const isAnonymous = user?.userType != 'user';
  const extra = typeof fetch === 'undefined' ? { missingFetch: true } : {}; // TODO: remove later
  return {
    page: page ?? '',
    url,
    screenResolution,
    viewport,
    isAnonymous,
    ...extra
  };
}

export function onboardingFlowToReadableString(id: UserFlowId) {
  return ONBOARDING_FLOWS_IDS.find(([_,v]) => v === id)![0];
}
