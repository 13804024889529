import { BehaviorSubject } from 'rxjs';
import { cancelTool, canDrawOnActiveLayer, canEdit, Editor, hasLoadedDrawing, isFilterActive, updateBounds } from '../services/editor';
import { CursorsMode, CursorType, EventType, getTabletEventButton, hasCtrlOrMetaKey, hasShiftKey, hasShiftKeyOnly, ITool, PresentationModeMessage, PresentViewport, QuickAction, QuickDrawingAction, SequenceDrawing, Settings, TabletEvent, TabletEventButton, TabletEventFlags, TabletEventSource, ToolError, ToolId, ToolSource } from './interfaces';
import { CURSOR_FADE_DISTANCE, CURSOR_FADE_PAD, FALLBACK_CURSORS, FLUSH_TIMEOUT, MAX_DRAWING_SAMPLES, MAX_LASSO_SAMPLES, SECOND, SHOW_CURSORS, USER_NAME_HEIGHT, USER_NAME_OFFSET, USER_NAME_WIDTH } from './constants';
import { redraw, redrawDrawing } from '../services/editorUtils';
import { isCanvasOnlyCursor, moveCursor, updateCursor, useSyntheticCursor } from '../services/cursor';
import { addRect, cloneRect, copyRect, createRect, isIntegerRect, isRectEmpty, outsetRect, rectToString, resetRect } from './rect';
import { clampViewport, copyViewport, documentToAbsoluteDocument, documentToScreenPoint, documentToScreenRect, fitViewportOnScreen, isViewportValid, matchFromOtherViewport, screenToDocumentAndRoundXY, screenToDocumentPoint, screenToDocumentXY, setViewportSizes, viewportsEqual } from './viewport';
import { isMaskEmpty } from './mask';
import { getPixelRatio, hasKeyboard, isMobile } from './utils';
import { clamp, distance, distancePointToRect } from './mathUtils';
import { findById, hasFlag, invalidEnum, invalidEnumReturn } from './baseUtils';
import { isAndroid, isiOS, isMac } from './userAgentUtils';
import { Model } from '../services/model';
import { copyModifiers, createTabletEvent, hasExtension, hasPressure, isCursorVisible, TabletEventsControl } from '../services/tablet';
import { setActiveLayer } from './user';
import { logAction } from './actionLog';
import { setLastToolSource } from './toolUtils';
import { toolIdFromString, toolIdToString } from './toolIdUtils';
import { copyPoint, createPoint } from './point';
import { pickColorAt } from '../services/otherActions';
import { getContext2d, getPixelContext, loadImage, replaceImageDataRect } from './canvasUtils';
import { createOuterStabilizer } from './stabilizer';
import { isPerspectiveGridLayer, isTextLayer, layerHasImageData } from './layer';
import { moveView, zoomViewAt } from '../services/viewActions';
import { compressImageDataRLE } from './rle';
import { isLayerEmpty } from './layerUtils';
import { getThumbPath } from './clientUtils';
import { showHelpAlertForError } from '../services/help';
import { scheduleSaveSettings } from '../services/settingsService';
import { toolStarted } from './update-state';
import { settingsHideCursor } from './settings';
import { createClientBrowserInfo } from '../services/real-model';
import { TextTool, TextToolMode } from './tools/textTool';
import { canDrawTextLayer, invokeRasterizeFlow } from './text/text-utils';
import { checkedLoadingFonts, hasFontsLoaded, loadFontsForLayer, shouldCheckLoadingFonts } from './text/fonts';
import { clipToDrawingRect, forAllTextLayers } from './drawing';
import { pickLayerFromEditor } from '../services/layerActions';
import { EditorInabilityStateId, enterEditorInabilityState, exitEditorInabilityState } from './analytics';
import { failedWebGL } from '../services/rendererFactory';
import { checkForLeakedTextures } from '../services/webgl';
import { benchmark, benchmarkCollect } from './benchmark';
import { BenchmarkType } from './benchmark';

const FLASH_DIRTY_RECT = DEVELOPMENT && false;
const CURSOR_SEND_TIMEOUT = 30; // ms

const tempPt = createPoint(0, 0);
const tempRect = createRect(0, 0, 0, 0);
let previousZoom = 0;

let p = 0; // used in benchmark

export const toolPosition$ = new BehaviorSubject<TabletEvent>(createTabletEvent());

let lastGotPressure: boolean | undefined = undefined;
export let gotPressure: boolean | undefined = undefined;

let wheelTimeout: any;

function saveLast(editor: Editor, e: TabletEvent) {
  editor.lastMoveFlag = true;
  editor.lastMoveXView = editor.x;
  editor.lastMoveYView = editor.y;
  editor.lastMoveX = editor.p.x;
  editor.lastMoveY = editor.p.y;
  editor.lastEventX = e.x;
  editor.lastEventY = e.y;
  editor.lastPressure = e.pressure;
}

export function isViewTool(id: ToolId) {
  return id === ToolId.Hand || id === ToolId.RotateView || id === ToolId.Zoom;
}

function isDrawingTool(id: ToolId) {
  return id === ToolId.Brush || id === ToolId.Pencil || id === ToolId.Eraser || id === ToolId.Rect ||
    id === ToolId.Ellipse || id === ToolId.Paintbucket || id === ToolId.LassoBrush;
}

const TOOLS_RASTERIZING_TEXT_LAYERS = [
  ToolId.Pencil, ToolId.Brush, ToolId.Eraser,
  ToolId.Paintbucket, ToolId.LassoBrush,
  ToolId.Shape, ToolId.Rect, ToolId.Ellipse
];
const TEXT_LAYERS_TOOLS_WHITELIST = [
  ToolId.Text, ToolId.Hand, ToolId.Zoom, ToolId.RotateView, ToolId.Comment, ToolId.Move, ToolId.Eyedropper,
  ToolId.Selection, ToolId.LassoSelection, ToolId.SelectionHelper, ToolId.CircleSelection, ToolId.Transform, ToolId.Crop,
  ToolId.PerspectiveGrid,
  ...TOOLS_RASTERIZING_TEXT_LAYERS
];
const PERSPECTIVE_GRID_LAYERS_TOOLS_WHITELIST = [
  ToolId.Move, ToolId.Selection, ToolId.SelectionHelper, ToolId.CircleSelection, ToolId.LassoSelection, ToolId.Eyedropper, ToolId.Hand, ToolId.RotateView,
  ToolId.Zoom, ToolId.Transform, ToolId.Text, ToolId.Comment, ToolId.Crop, ToolId.PerspectiveGrid
];
export function toolIncompatibleWithTextLayers(id: ToolId) {
  return !TEXT_LAYERS_TOOLS_WHITELIST.includes(id);
}
function toolHasToRasterizeTextLayer(id: ToolId) {
  return TOOLS_RASTERIZING_TEXT_LAYERS.includes(id);
}
export function toolIncompatibleWithPerspectiveGridLayer(id: ToolId) {
  return !PERSPECTIVE_GRID_LAYERS_TOOLS_WHITELIST.includes(id);
}

function initTool(editor: Editor, tool: ITool | undefined, e: TabletEvent): ToolError {
  if (editor.locked) return ToolError.EditorLocked;
  if (!tool) return ToolError.NoActiveTool;
  if (editor.model.connectionIssues) return ToolError.WaitingForServerResponse;

  if (editor.model.isPresentationMode && !editor.model.isPresentationHost) {
    const presentationState = editor.model.presentationModeState;
    if (presentationState.participantsUiHidden) return ToolError.BlockedByPresentationMode;
    if (isViewTool(tool.id) && presentationState.followingHostViewportEnforced) return ToolError.BlockedByPresentationMode;
  }

  if ((tool.id == ToolId.Move || tool.id == ToolId.Text) && hasCtrlOrMetaKey(e)) {
    const temp = createPoint(0, 0);
    screenToDocumentXY(temp, e.x, e.y, editor.view);
    const layer = pickLayerFromEditor(editor, temp.x, temp.y, true, tool.id == ToolId.Text);
    if (layer && layer !== editor.activeLayer) {
      logAction(`[local] ctrl+${tool.id == ToolId.Text ? 'text' : 'move'} layer switch (old: ${editor.activeLayer?.id}, new: ${layer.id})`);
      editor.apply(() => editor.selectLayer(layer));
    }
  }

  const isTextToolInCreatingMode = tool.id === ToolId.Text && (tool as TextTool).mode === TextToolMode.Creating;
  const requiresLayer = tool.id !== ToolId.Comment && !isTextToolInCreatingMode && tool.id !== ToolId.Crop;

  if (requiresLayer) {
    if (!(canDrawOnActiveLayer(editor) || (tool.selection && canEdit(editor)) || tool.navigation)) {
      return ToolError.UnableToDrawOnActiveLayer;
    }

    if (isDrawingTool(tool.id) && editor.activeLayer?.opacityLocked && isLayerEmpty(editor.activeLayer)) {
      return ToolError.LayerIsEmpty;
    }
  }

  if (isPerspectiveGridLayer(editor.activeLayer) && toolIncompatibleWithPerspectiveGridLayer(tool.id)) {
    return ToolError.ImpossibleOnPerspectiveGridLayer;
  }

  if (isPerspectiveGridLayer(editor.activeLayer) && !isMaskEmpty(editor.model.user.selection) && (tool.id === ToolId.Move || tool.id === ToolId.Transform)) {
    return ToolError.ImpossibleOnPerspectiveGridLayer;
  }

  if (!isViewportValid(editor.view)) return ToolError.InvalidViewport;

  if (tool.canStart) {
    const error = tool.canStart();
    if (error !== ToolError.NoError) {
      return error;
    }
  }

  if (isTextLayer(editor.activeLayer)) {
    if (toolIncompatibleWithTextLayers(tool.id)) return ToolError.ImpossibleOnTextLayer;
    if (toolHasToRasterizeTextLayer(tool.id)) {
      if (canDrawTextLayer(editor.activeLayer)) {
        invokeRasterizeFlow(editor, editor.activeLayer).catch((e) => DEVELOPMENT && console.error(e));
        return ToolError.ImpossibleOnTextLayerInvokedRasterizing;
      } else {
        return ToolError.WaitingForFontsToLoad;
      }
    }
  }

  editor.x = e.x;
  editor.y = e.y;
  editor.p.x = editor.x;
  editor.p.y = editor.y;

  return ToolError.NoError;
}

function getMouseButtonSetting(settings: Settings, button: TabletEventButton) {
  switch (button) {
    case TabletEventButton.Left: return '';
    case TabletEventButton.Middle: return settings.mouseMiddle;
    case TabletEventButton.Right: return settings.mouseRight;
    case TabletEventButton.Button4: return settings.mouseButton4;
    case TabletEventButton.Button5: return settings.mouseButton5;
    case TabletEventButton.Eraser: return 'eraser';
    default: return invalidEnumReturn(button, '');
  }
}

export function start(editor: Editor, e: TabletEvent) {
  const user = editor.model.user;

  try {
    editor.cursor.show = !settingsHideCursor;

    toolStarted.next();

    const button = getTabletEventButton(e);
    const settings = editor.model.settings;
    const touchAction = settings.touchDrag[0];
    let tool = editor.activeTool;

    if (tool) {
      if (button !== TabletEventButton.Left && button !== TabletEventButton.Eraser) {
        if (tool.contextMenu) {
          // allow editor-box component to handle context menu
          return;
        }
        const action = getMouseButtonSetting(settings, button);
        const toolId = toolIdFromString(action, true);

        if (toolId) {
          const foundTool = editor.tools.find(t => t.id === toolId);
          if (foundTool) {
            setLastToolSource(ToolSource.MouseGesture);
            tool = foundTool;
          } else {
            DEVELOPMENT && console.error('Tool not found');
          }
        } else {
          if (action) {
            editor.apply(() => editor.model.executeCommandById(action, {}, ToolSource.MouseGesture).catch(e => DEVELOPMENT && console.error(e)));
          }
          return;
        }
      } else if (tool.altTool && button === TabletEventButton.Eraser) {
        setLastToolSource(ToolSource.StylusEraser);
        tool = editor.eraserTool;
      } else if (e.source === TabletEventSource.Touch && touchAction === 'pan') {
        setLastToolSource(ToolSource.TouchGesture);
        tool = editor.handTool;
      } else if (tool.altTool && e.source === TabletEventSource.Touch && touchAction === 'eraser') {
        setLastToolSource(ToolSource.TouchGesture);
        tool = editor.eraserTool;
      } else {
        setLastToolSource(editor.toolSource);
      }
    }

    const error = initTool(editor, tool, e);
    const isViewTool = tool?.id === ToolId.Hand || tool?.id === ToolId.RotateView || tool?.id === ToolId.Zoom;

    if (error === ToolError.NoError && (!editor.drawingInProgress || (isViewTool && isFilterActive(editor)))) {
      editor.drawTooFar = false;
      editor.startX = e.x;
      editor.startY = e.y;

      toolPosition$.next(e);

      user.activeTool = tool;

      if (editor.stylus !== editor.tabletConfig.api || lastGotPressure !== gotPressure) {
        lastGotPressure = gotPressure;
        editor.stylus = editor.tabletConfig.api;
      }

      if (gotPressure && editor.tabletConfig.api && !editor.model.pressureApiSent) {
        editor.model.server.quickAction(QuickAction.PressureApi, editor.tabletConfig.api);
        editor.model.pressureApiSent = true;
      }

      if (tool) {
        tool.verify?.();

        editor.drawingEnded = false;

        if (tool.view) copyViewport(tool.view, editor.view);
        // drawing bounds can be passed in setup but it causes multiple problems so for now do it here
        if (tool.drawingBounds) {
          copyRect(tool.drawingBounds, editor.drawing);
        } else {
          tool.drawingBounds = cloneRect(editor.drawing);
        }
        tool.setup?.();

        if (tool.stabilize) {
          editor.tabletTool = createOuterStabilizer(editor.model, tool, () => finish(editor, 'stabilizer'));
        } else {
          editor.tabletTool = tool;
        }

        try {
          const layer = editor.activeLayer;
          const surface = editor.model.user.surface;

          if (hasShiftKey(e) && tool.lineOnShift && editor.lastMoveFlag) {
            logAction(`[local] shift-line: ${toolIdToString(tool.id)}`);
            drawShiftLine(editor, tool, e);
          } else if (tool.view) {
            logAction(`[local] start: ${toolIdToString(tool.id)} (view) (${rectToString(layer?.rect)}; ${rectToString(surface.rect)}${layer?.textData ? '; text' : ''})`);
            screenToDocumentAndRoundXY(tempPt, editor.x, editor.y, editor.view);
            documentToAbsoluteDocument(tempPt, editor.drawing);
            editor.tabletTool.start!(tempPt.x, tempPt.y, e.pressure, e);
            editor.drawingInProgress = true;
          } else {
            logAction(`[local] start: ${toolIdToString(tool.id)} (${rectToString(layer?.rect)}; ${rectToString(surface.rect)}${layer?.textData ? '; text' : ''})`);
            screenToDocumentAndRoundXY(tempPt, editor.p.x, editor.p.y, editor.view);
            documentToAbsoluteDocument(tempPt, editor.drawing);
            editor.tabletTool.start!(tempPt.x, tempPt.y, e.pressure, e);
            editor.drawingInProgress = true;
          }

          if (DEVELOPMENT && editor.throwAfterStart) throw new Error('test error');
        } catch (error) {
          cancelTool(editor, 'start:error');
          throw error;
        }
      }
    } else {
      showHelpAlertForError(error, editor);
    }

    saveLast(editor, e);
  } catch (error) {
    user.activeTool = undefined;
    editor.errorReporter.reportError('start', error, { tabletTool: editor.tabletTool?.id });
    editor.apply(() => editor.model.error = error.message || 'Error occurred');
  }
}

export function move(editor: Editor, e: TabletEvent) {
  try {
    const error = initTool(editor, editor.activeTool, e);
    if (error === ToolError.NoError && !editor.drawingEnded && editor.tabletTool) {
      if (!editor.drawTooFar && distance(editor.startX, editor.startY, e.x, e.y) > 100) {
        editor.drawTooFar = true;
      }

      if (editor.model.user.activeTool?.view) {
        screenToDocumentAndRoundXY(tempPt, editor.x, editor.y, editor.view);
      } else {
        screenToDocumentAndRoundXY(tempPt, editor.p.x, editor.p.y, editor.view);
      }
      documentToAbsoluteDocument(tempPt, editor.activeTool?.drawingBounds ?? editor.drawing);

      editor.tabletTool.move!(tempPt.x, tempPt.y, e.pressure, e);

      toolPosition$.next(e);
    }
  } catch (error) {
    editor.errorReporter.reportError('drag', error);
  }

  saveLast(editor, e);
}

export function endPrematurely(editor: Editor) {
  editor.ev.x = editor.lastEventX;
  editor.ev.y = editor.lastEventY;
  editor.ev.pressure = editor.lastPressure;
  end(editor, editor.ev);
}

export function end(editor: Editor, e: TabletEvent) {
  try {
    const error = initTool(editor, editor.activeTool, e);
    if (error === ToolError.NoError && !editor.drawingEnded && editor.tabletTool) {
      const tool = editor.model.user.activeTool;

      if (tool?.view) {
        screenToDocumentAndRoundXY(tempPt, editor.x, editor.y, editor.view);
      } else {
        screenToDocumentAndRoundXY(tempPt, editor.p.x, editor.p.y, editor.view);
      }
      documentToAbsoluteDocument(tempPt, tool?.drawingBounds ?? editor.drawing);

      editor.tabletTool.end!(tempPt.x, tempPt.y, e.pressure, e);

      copyPoint(editor.attachLastPt, editor.lastPoint);

      if (tool && !tool.navigation) {
        copyPoint(editor.lastPoint, tempPt);
      }

      if (!editor.tabletTool.stabilizer) {
        finish(editor, 'end');
      }

      editor.drawingEnded = true;
      editor.attachLastSave = true;
      editor.drawTooFar = false;
      toolPosition$.next(e);
    }
  } catch (error) {
    editor.errorReporter.reportError('end', error, { tabletTool: editor.tabletTool?.id });
  }

  if (e.flags & TabletEventFlags.Touch) {
    editor.cursor.show = false;
  }

  saveLast(editor, e);
}

function finish(editor: Editor, source: string) {
  editor.drawingInProgress = isFilterActive(editor) ? true : false;
  editor.drawingNonCancellable = false;

  const user = editor.model.user;

  logAction(`[local] finish: ${source} (${rectToString(user.activeLayer?.rect)}; ${rectToString(user.surface.rect)})`);

  if (!user.activeTool || !user.activeTool.navigation) {
    user.lastTool = user.activeTool;
  }

  if (user.activeTool?.continuousRedraw) redraw(editor);

  user.activeTool = undefined;

  if (editor.attachLastSave) {
    user.history.attachLastPoint(editor.attachLastPt.x, editor.attachLastPt.y);
  }

  // update UI on next frame so we don't put more work on this one
  editor.applyNextFrame();
}

export function wheel(editor: Editor, e: TabletEvent) {
  let dx = e.deltaX;
  let dy = e.deltaY;

  if (editor.model.isPresentationViewer && editor.model.presentationModeState.followingHostViewportEnforced) {
    clearTimeout(wheelTimeout);
    wheelTimeout = setTimeout(() => {
      editor.model.manage.warning(PresentationModeMessage.DisabledZoomAndPan, true);
    }, 250);
    return;
  }

  if (editor.activeTool?.wheel) {
    editor.activeTool.wheel(e.x, e.y, dx * 0.0002, dy * -0.0002, e);
  } else {
    const shift = hasFlag(e.flags, TabletEventFlags.ShiftKey);
    const ctrl = hasFlag(e.flags, TabletEventFlags.CtrlKey) || hasFlag(e.flags, TabletEventFlags.MetaKey);
    const alt = hasFlag(e.flags, TabletEventFlags.AltKey);
    const settings = editor.model.settings;
    const mouseWheel = ctrl ? settings.mouseWheelCtrl : (alt ? settings.mouseWheelAlt : settings.mouseWheel);

    switch (mouseWheel) {
      case '': break;
      case 'zoom':
        zoomViewAt(editor, e.x, e.y, dy * -0.0002);
        break;
      case 'pan': {
        if (shift && !isMac) {
          const t = dx;
          dx = dy;
          dy = t;
        }
        const s = -0.5 * editor.view.scale;
        moveView(editor, dx * s, dy * s);
        break;
      }
      default: invalidEnum(mouseWheel);
    }
  }
}

export function hover(editor: Editor, e: TabletEvent) {
  editor.cursor.show = !settingsHideCursor;
  editor.lastMove.x = e.x;
  editor.lastMove.y = e.y;
  editor.lastEventX = e.x;
  editor.lastEventY = e.y;

  if (editor.activeTool?.hover) {
    copyModifiers(editor.ev, e);
    screenToDocumentPoint(editor.lastMove, editor.view);
    editor.activeTool.hover(editor.lastMove.x, editor.lastMove.y, e);
  }
}

export function cancel(editor: Editor, e: TabletEvent) {
  try {
    if (editor.drawingInProgress) {
      if (editor.drawingNonCancellable) {
        logAction('[local] cancel (end)');
        DEVELOPMENT && console.warn('Cannot cancel non-cancellable tool');
        end(editor, e);
      } else if (!editor.movingView) {
        logAction('[local] cancel');
        cancelTool(editor, 'cancel event');
        editor.drawingInProgress = false;
      }
    }
  } catch (error) {
    editor.errorReporter.reportError('cancel', error);
  }
}

function drawShiftLine(editor: Editor, tool: ITool, e: TabletEvent) {
  logAction(`[local] shift line: ${toolIdToString(tool.id)}`);
  copyModifiers(editor.ev, e);

  // TODO: if (is not the same point)

  tool.drawingShiftLine = true;

  screenToDocumentAndRoundXY(tempPt, editor.x, editor.y, editor.view);
  documentToAbsoluteDocument(tempPt, editor.drawing);

  if (tool.view) {
    tool.start!(editor.lastPoint.x, editor.lastPoint.y, 1, editor.ev);
    tool.move!(tempPt.x, tempPt.y, 1, e);
    tool.end!(tempPt.x, tempPt.y, 1, e);
  } else {
    tool.start!(editor.lastMoveX, editor.lastMoveY, 1, editor.ev);
    tool.move!(editor.p.x, editor.p.y, 1, e);
    tool.end!(editor.p.x, editor.p.y, 1, e);
  }

  tool.drawingShiftLine = false;

  copyPoint(editor.attachLastPt, editor.lastPoint);
  copyPoint(editor.lastPoint, tempPt);
  finish(editor, 'shift line');

  editor.drawingEnded = true;
  editor.attachLastSave = true;
}

let lastScroll = 0;
let lastCursorSend = 0;
let lastEvent = 0;
let lastHideUI = false;
let lastShowSequence = false;
let warningsShown = 0;
let lastIsMobile = isMobile;
let lastHasKeyboard = hasKeyboard;
let lastHasPressure = hasPressure;
let lastHasExtension = hasExtension();
let isPenPressed = false;
let lastCheckedForLeakedTextures = 0;

if (typeof window !== 'undefined') {
  window.addEventListener('pointerdown', e => isPenPressed = e.pointerType === 'pen');
  window.addEventListener('pointerup', () => isPenPressed = false);
  window.addEventListener('pointercancel', () => isPenPressed = false);
}

function shouldShowShiftLine(event: TabletEvent, editor: Editor) {
  return hasShiftKeyOnly(event) && !!editor.activeTool?.lineOnShift && editor.lastMoveFlag &&
    event.x > 0 && event.y > 0 && event.x < editor.view.width && event.y < editor.view.height;
}

function checkFlagsForAnalytics(model: Model) {
  if (isMobile !== lastIsMobile || hasKeyboard !== lastHasKeyboard || hasPressure !== lastHasPressure || hasExtension() !== lastHasExtension) {
    lastIsMobile = isMobile;
    lastHasKeyboard = hasKeyboard;
    lastHasPressure = hasPressure;
    lastHasExtension = hasExtension();
    if (model.isConnected) {
      model.server.quickAction(QuickAction.BrowserInfo, createClientBrowserInfo());
    }
  }
}

export function update(editor: Editor, model: Model, tablet: TabletEventsControl, delta: number) {
  if (model.fatalError || !editor.renderer) return;

  // show connection issue indicator
  const confirmed = model.sentTools - model.confirmedTools;
  const connectionIssues = confirmed > 5;
  if (model.connectionIssues !== connectionIssues && !model.sending) {
    if (!IS_HOSTED && hasLoadedDrawing(editor)) {
      if (connectionIssues) {
        enterEditorInabilityState(editor, EditorInabilityStateId.WaitingForServer, 'connection issues');
      } else {
        exitEditorInabilityState(editor, EditorInabilityStateId.WaitingForServer, false);
      }
    }
    editor.apply(() => {
      model.connectionIssues = connectionIssues;
    });
  }

  checkFlagsForAnalytics(model);

  editor.settings = model.settings;

  const drawingInProgress = editor.drawingInProgress && !editor.filter.activeFilter;

  // fix for page scrolling up when up open bottom panel or rotate the screen on iPhone
  if (!drawingInProgress && document.documentElement.scrollTop && document.activeElement?.tagName !== 'INPUT') {
    document.documentElement.scrollTop = 0;
  }

  if (!drawingInProgress) {
    const view = editor.view;

    // TEMP: investigating view change
    if (
      view.width !== Math.max(1, editor.width) || view.height !== Math.max(1, editor.height) ||
      view.contentWidth !== editor.drawing.w || view.contentHeight !== editor.drawing.h
    ) {
      logAction(`fixing viewport size (${view.width}, ${view.height}, ${view.contentWidth}, ${view.contentHeight})` +
        ` -> (${editor.width}, ${editor.height}, ${editor.drawing.w}, ${editor.drawing.h})`);
    }

    // TODO: need to handle non-integer with and height ? (or make sure editor.width/height is integer and >= 1)
    setViewportSizes(editor.view, editor.width, editor.height, editor.drawing.w, editor.drawing.h);

    if (model.isPresentationMode && !model.isPresentationHost) {
      if (editor.lastPresentationViewToMatch) {
        if (model.presentationModeState.followingHostViewportEnforced) {
          matchFromOtherViewport(editor.view, editor.lastPresentationViewToMatch);
        } else {
          fitViewportOnScreen(editor.view);
        }
      }
    }
  }

  // reset viewport in case anything went wrong
  if (!drawingInProgress && !isViewportValid(editor.view)) {
    const before = JSON.stringify(editor.view, null, 2);
    setViewportSizes(editor.view, editor.width, editor.height, editor.drawing.w, editor.drawing.h);
    fitViewportOnScreen(editor.view);
    const after = JSON.stringify(editor.view, null, 2);
    logAction('invalid viewport');
    model.reportError(`Invalid viewport`, undefined, { before, after });
  }

  // TEMP: this shouldn't happen, remove later
  if (model.user.activeLayer && !editor.drawing.layers.includes(model.user.activeLayer)) {
    model.reportError(`Deselecting missing layer (layerId: ${model.user.activeLayer.id})`, undefined);
    editor.apply(() => setActiveLayer(model.user, editor.drawing.layers.find(l => l.owner === model.user)));
  }

  // TEMP: this shouldn't happen, remove later
  if (editor.movingView && !editor.drawingInProgress) {
    model.reportError(`movingView is true but drawingInProgress is false`, undefined);
    editor.movingView = false;
  }

  if (DEVELOPMENT) { // check for texture leaks
    const webgl = (editor.renderer as any).webgl;
    if (webgl && (performance.now() - lastCheckedForLeakedTextures) > (5 * SECOND)) {
      lastCheckedForLeakedTextures = performance.now();
      const leaked = checkForLeakedTextures([webgl], [editor.drawing], [[model.user], model.users], editor.allTools);
      if (leaked.length) console.warn('Leaked textures:', leaked.length);
    }
  }

  // TEMP: debugging
  if (DEVELOPMENT && model.loaded === 1 && !model.failed && warningsShown < 100) {
    const layer = model.user.surface.layer;
    if (layer && layer.owner !== model.user) throw new Error(`Surface layer is not owned by user`);

    if (model.user.selection.bounds.w < 0 || model.user.selection.bounds.h < 0) {
      console.warn(`Invalid selection bounds:`, model.user.selection.bounds);
      warningsShown++;
    }

    if (!isIntegerRect(model.user.surface.rect)) {
      console.warn(`Surface rect has non-integer values:`, model.user.surface.rect);
      warningsShown++;
    }

    for (const layer of editor.drawing.layers) {
      if (isRectEmpty(layer.rect) && layerHasImageData(layer)) {
        console.warn(`Empty layer with image data: ${layer.name}`);
        warningsShown++;
      }

      if (!isRectEmpty(layer.rect) && !layerHasImageData(layer)) {
        console.warn(`Non-empty layer without image data: ${layer.name}`);
        warningsShown++;
      }

      if (!isIntegerRect(layer.rect)) {
        console.warn(`Layer rect has non-integer values:`, layer.rect);
        warningsShown++;
      }
    }
  }

  if (!model.rendererApiSent && model.isConnected) {
    const { maxTextureSize, maxTextureUnits } = editor.renderer.params();
    model.server.quickAction(QuickAction.RendererApi, [editor.renderer.name, failedWebGL, maxTextureSize, maxTextureUnits]);
    model.rendererApiSent = true;
  }

  const maxSamples = editor.activeTool?.id === ToolId.LassoSelection ? MAX_LASSO_SAMPLES : MAX_DRAWING_SAMPLES;
  let showShiftLine = editor.showShiftLine;
  let next: TabletEvent | undefined;

  for (let e = tablet.readEvent(); e; tablet.reuseEvent(e), e = next) {
    next = tablet.readEvent();
    editor.events++;
    lastEvent = performance.now();
    gotPressure = gotPressure || (e.pressure !== 0 && e.pressure !== 1);

    // stylus params debugging
    if (DEVELOPMENT && 0) {
      const debug = document.getElementById('editor-debug');
      if (debug) {
        debug.textContent = `x: ${e.x.toString().padStart(4)} y: ${e.y.toString().padStart(4)} ` +
          `p: ${e.pressure.toFixed(2)} tx: ${e.tiltX.toFixed(1).padStart(5)} ty: ${e.tiltY.toFixed(1).padStart(5)}`;
      }
    }

    // skip multiple move events in a row (for tools that only depend on final position of the cursor)
    if (editor.activeTool?.skipMoves && next && e.type === EventType.Move) {
      if (next.type === EventType.Move || next.type === EventType.End) {
        continue;
      }
    }

    if (model.drawingSamples <= maxSamples) {
      showShiftLine = shouldShowShiftLine(e, editor);

      if (!Number.isFinite(e.x) || !Number.isFinite(e.y)) {
        editor.errorReporter.reportError(`NaN event (${e.x}, ${e.y})`, undefined, { event: e });
      }

      switch (e.type) {
        case EventType.Start: start(editor, e); break;
        case EventType.Move: move(editor, e); break;
        case EventType.End: end(editor, e); break;
        case EventType.Wheel: wheel(editor, e); break;
        case EventType.Hover: hover(editor, e); break;
        case EventType.Cancel: cancel(editor, e); break;
        default: invalidEnum(e.type);
      }
    }
  }

  const now = performance.now();
  const pointer = tablet.getPointer();
  const pointerX = pointer.x - editor.left;
  const pointerY = pointer.y - editor.top;

  if (model.hideUI != lastHideUI && !drawingInProgress) {
    lastHideUI = model.hideUI;
    // shift viewport by UI size so the canvas stays in the same place on screen
    const boundsX = 39; // TODO: better way to get these
    const boundsY = 43;
    const mul = model.hideUI ? 1 : -1;
    editor.view.x += boundsX * mul;
    editor.view.y += boundsY * mul;
    editor.boundsChanged = true;
  }

  if (model.settings.showSequence != lastShowSequence) {
    lastShowSequence = model.settings.showSequence;
    editor.boundsChanged = true;
  }

  // view scrolling
  if (model.user.activeTool?.scrollView && (now - lastScroll) > 15) {
    let dx = 0, dy = 0;

    if (pointerX < 0) {
      dx += 5;
    } else if (pointerX > editor.view.width) {
      dx -= 5;
    }

    if (pointerY < 0) {
      dy += 5;
    } else if (pointerY > editor.view.height) {
      dy -= 5;
    }

    if (dx || dy) {
      logAction(`[local] view scroll (${dx}, ${dy})`);
      editor.view.x += dx;
      editor.view.y += dy;
      clampViewport(editor.view);
      editor.ev.x = editor.lastEventX;
      editor.ev.y = editor.lastEventY;
      move(editor, editor.ev);
    }

    lastScroll = now;
  }

  if (settingsHideCursor) editor.cursor.show = false;
  editor.lastCursorX = pointer.x;
  editor.lastCursorY = pointer.y;
  moveCursor(editor.cursor, pointerX, pointerY);

  editor.tabletTool?.frame?.();
  editor.activeTool?.frame?.();

  if (model.drawingSamples > maxSamples) endPrematurely(editor);

  while (!editor.drawingInProgress && editor.afterFinish.length) {
    editor.afterFinish.shift()!();
  }

  if (SHOW_CURSORS && !(editor.drawingInProgress && editor.activeTool?.updatesCursor) && model.isConnected) {
    const ignore = model.isAnyModalOpen;
    tempPt.x = (ignore || pointerX < 0 || pointerX > editor.view.width || !isCursorVisible) ? 1e9 : pointerX;
    tempPt.y = (ignore || pointerY < 0 || pointerY > editor.view.height || !isCursorVisible) ? 1e9 : pointerY;

    screenToDocumentPoint(tempPt, editor.view);
    documentToAbsoluteDocument(tempPt, editor.drawing);

    if ((model.user.cursorX !== tempPt.x || model.user.cursorY !== tempPt.y) && (now - lastCursorSend) > CURSOR_SEND_TIMEOUT) {
      model.user.cursorX = tempPt.x;
      model.user.cursorY = tempPt.y;
      model.server.cursor(model.connId, tempPt.x, tempPt.y);
      lastCursorSend = now;
    }
  }

  model.user.surface.context?.flush();

  for (const user of model.users) {
    user.surface.context?.flush();
  }

  if (showShiftLine || editor.showShiftLine) redraw(editor); // TODO: could redraw only relevant rect here
  editor.showShiftLine = showShiftLine;

  if (!viewportsEqual(editor.viewLast, editor.view)) {
    editor.viewUpdated.next(editor.view);
    redraw(editor);
    scheduleSaveSettings(editor.model);
    copyViewport(editor.viewLast, editor.view);

    if (model.isPresentationHost && model.presentationModeState.followingHostViewportEnforced) {
      model.server.quickDrawingAction<PresentViewport>(model.connId, QuickDrawingAction.PresentViewport, { view: editor.view });
    }

    const zoom = Math.pow(2, Math.ceil(Math.log2(editor.view.scale)));
    const scale = Math.min(1 / Math.min(1, zoom * Math.ceil(getPixelRatio())), 8);
    if (previousZoom !== scale) {
      previousZoom = scale;
      editor.renderer.setLevelOfDetail(editor.drawing, scale);
      redrawDrawing(editor);
      redraw(editor);
    }
  }

  const hasSelection = !isMaskEmpty(model.user.selection);
  if (editor.selectionLast !== hasSelection) {
    editor.selectionLast = hasSelection;
    editor.selectionUpdated.next(hasSelection);
  }

  if (DEVELOPMENT) {
    if (benchmark.flags.drawDrawing) redrawDrawing(editor, editor.drawing);
    if (benchmark.flags.drawDrawingPartial) redrawDrawing(editor, createRect(100, 100, 200, 200));
    if (benchmark.flags.drawViewport) redraw(editor);
    if (benchmark.flags.drawViewportPartial) redraw(editor, createRect(100, 100, 200, 200));
  }

  if (!isRectEmpty(editor.drawingDirty)) {
    trackLoadingFonts(editor);

    if (DEVELOPMENT) p = performance.now();
    editor.renderer.drawDrawing(editor.drawing, editor.drawingDirty, editor);
    if (DEVELOPMENT && benchmark.type === BenchmarkType.DrawDrawing) benchmarkCollect(performance.now() - p);

    addRect(editor.thumbDirty, editor.drawingDirty);

    documentToScreenRect(editor.drawingDirty, editor.view);
    outsetRect(editor.drawingDirty, 1);
    addRect(editor.dirty, editor.drawingDirty);
    resetRect(editor.drawingDirty);
  }

  // bounds are wrong after resize on new iPad
  // bounds are wrong on startup on Android (TODO: maybe only resize it at the start?)
  if (editor.boundsChanged || isiOS || isAndroid) updateBounds(editor);
  if (editor.activeTool?.continuousRedraw) redraw(editor);

  const selection = model.user.selection;

  if (selection && !isMaskEmpty(selection) && (Date.now() - editor.lastRedraw) > 250) redraw(editor);
  if (isTextLayer(model.user.activeLayer)) redraw(editor);

  const cursor = (model.loaded === 1 ? editor.activeTool?.cursor : undefined) ?? CursorType.Default;
  const useSynthetic = isPenPressed && useSyntheticCursor(editor) && (FALLBACK_CURSORS.includes(cursor) || editor.settings.showCursor);

  resetRect(tempRect);
  if (updateCursor(editor.cursor, editor, tempRect, useSynthetic, model.loaded === 1)) {
    redraw(editor, tempRect);
  }

  fadeOutUserCursors(editor, model, delta);

  let cursorClass = useSynthetic ? CursorType.None : cursor;

  if (isTextLayer(model.user.activeLayer) && editor.selectedTool && toolIncompatibleWithTextLayers(editor.selectedTool.id)) {
    cursorClass = CursorType.NotAllowed;
  }

  if ((cursorClass === CursorType.None || isCanvasOnlyCursor(cursorClass)) && editor.settings.showCursor) {
    cursorClass = CursorType.Default;
  }

  if (editor.cursorClass !== cursorClass && editor.element) {
    if (editor.cursorClass) editor.element.classList.remove(editor.cursorClass);
    if (cursorClass) editor.element.classList.add(cursorClass);
    editor.cursorClass = cursorClass;
  }

  // redraw viewport

  try {
    // webgl renderer will redraw webgl canvas every frame if `preserveDrawingBuffer` is false
    editor.renderer.addRedrawRect(model.user, editor.dirty, editor);

    if (!isRectEmpty(editor.dirty) && editor.canvas) {
      const ratio = getPixelRatio();
      const rw = Math.round(editor.width * ratio) | 0;
      const rh = Math.round(editor.height * ratio) | 0;

      if (editor.canvas.width !== rw || editor.canvas.height !== rh) {
        redraw(editor);
        editor.canvas.style.width = `${rw / ratio}px`;
        editor.canvas.style.height = `${rh / ratio}px`;
        editor.canvas.width = rw;
        editor.canvas.height = rh;
      }

      model.user.showTransform = editor.activeTool === editor.transformTool;
      if (DEVELOPMENT) p = performance.now();
      editor.renderer.draw(editor.drawing, model.user, editor.view, editor.dirty, editor);
      if (DEVELOPMENT && benchmark.type === BenchmarkType.DrawViewport) benchmarkCollect(performance.now() - p);

      if (FLASH_DIRTY_RECT) {
        const context = getContext2d(editor.canvas);
        context.fillStyle = `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.1)`;
        context.fillRect(editor.dirty.x, editor.dirty.y, editor.dirty.w, editor.dirty.h);
      }

      resetRect(editor.dirty);
      editor.lastRedraw = Date.now();
    }
  } catch (e) {
    DEVELOPMENT && console.error(e);
    if (!model.error) {
      model.reportError('Redraw error', e, undefined);
      model.showError(e.message);
    }
  }

  commitPickColor(editor);
  updateSequenceAndLayerThumbnails(editor, model);

  if ((now - model.nextToolFlushTime) > FLUSH_TIMEOUT) {
    model.flushTool();
  }
}

function trackLoadingFonts(editor: Editor) {
  if (shouldCheckLoadingFonts()) {
    forAllTextLayers(editor.drawing, layer => {
      layer.fontsLoaded = hasFontsLoaded(layer);
      if (!layer.fontsLoaded) {
        editor.apply(() => {
          loadFontsForLayer(layer, editor).then(() => {
            redrawDrawing(editor);
          }).catch((e) => DEVELOPMENT && console.error(e));
        });
      }
    });
    checkedLoadingFonts(editor);
  }
}

function fadeOutUserCursors(editor: Editor, model: Model, delta: number) {
  if (SHOW_CURSORS && editor.settings.cursors !== CursorsMode.None) {
    if (editor.settings.fadeCursors) {
      const ratio = getPixelRatio();
      const pad = CURSOR_FADE_PAD;
      let cursorX = editor.cursor.x * ratio;
      let cursorY = editor.cursor.y * ratio;
      let changed = false;

      // disable cursor fading if there's no interaction for extended period of time
      if ((performance.now() - lastEvent) > (10 * SECOND)) {
        cursorY = cursorX = 1e9;
      }

      for (const user of model.users) {
        tempPt.x = user.cursorX;
        tempPt.y = user.cursorY;
        documentToScreenPoint(tempPt, editor.view);
        const x = (tempPt.x + USER_NAME_OFFSET - pad) * ratio;
        const y = (tempPt.y + USER_NAME_OFFSET - pad) * ratio;
        const w = (USER_NAME_WIDTH + 2 * pad) * ratio;
        const h = (USER_NAME_HEIGHT + 2 * pad) * ratio;
        const dist = distancePointToRect(cursorX, cursorY, x, y, w, h);
        const alpha = clamp(dist / CURSOR_FADE_DISTANCE, 0, 1);

        if (alpha <= user.cursorAlpha) {
          user.cursorDelay = 0.4;
          user.cursorAlpha = alpha;
        } else {
          user.cursorDelay = Math.max(0, user.cursorDelay - delta);
          user.cursorAlpha = Math.min(alpha, user.cursorAlpha + Math.max(0, 2 * (delta - user.cursorDelay)));
        }

        changed = true; // force redraw, otherwise it's flickering
      }

      if (changed) redraw(editor);
    } else {
      for (const user of model.users) {
        user.cursorAlpha = 1;
        user.cursorDelay = 0;
      }
    }
  }
}

function updateSequenceAndLayerThumbnails(editor: Editor, model: Model) {
  // less frequent updates for 2d and webgl1
  const name = editor.renderer.name;
  const isSlow = name === '2d-off' || name === '2d-fail' || name === 'webgl';
  const thumbTimeout = Date.now() - (isSlow ? 10 : 5) * SECOND; // wait since last update
  let redrewDrawingThumb = false;

  if (!editor.locked && !editor.drawingInProgress && model.loaded === 1 && !model.failed && model.isConnected) {
    try {
      const sequenceDrawing = findById(editor.drawing.sequence, editor.drawing.id);

      // skip for webgl1 context because of issues with performance
      if (sequenceDrawing && sequenceDrawing.thumbCanvas && editor.renderer.name !== 'webgl') {
        editor.renderer.pingThumb(editor.drawing);

        if (!isRectEmpty(editor.thumbDirty) && (!sequenceDrawing.thumbUpdated || sequenceDrawing.thumbUpdated < thumbTimeout)) {
          if (!sequenceDrawing.thumbData) {
            // redraw whole thumbnail if we didn't initialize thumbData yet
            addRect(editor.thumbDirty, editor.drawing);
          }
          clipToDrawingRect(editor.thumbDirty, editor.drawing);
          editor.renderer.drawThumb(editor.drawing, editor.thumbDirty, sequenceDrawing.thumbCanvas);
          resetRect(editor.thumbDirty);
          redrewDrawingThumb = true;
          sequenceDrawing.thumbUpdated = Date.now();
        }

        if (editor.drawing.thumbUpdate) {
          const { rect, data, width, height } = editor.drawing.thumbUpdate;

          if (!sequenceDrawing.thumbData || sequenceDrawing.thumbData.width !== width || sequenceDrawing.thumbData.height !== height) {
            getContext2d(sequenceDrawing.thumbCanvas).clearRect(0, 0, sequenceDrawing.thumbCanvas.width, sequenceDrawing.thumbCanvas.height);
            sequenceDrawing.thumbData = getPixelContext().createImageData(width, height);
            sequenceDrawing.thumbImage = undefined;
            sequenceDrawing.thumbTimestamp = 0; // prevent reloading using image
          }

          replaceImageDataRect(sequenceDrawing.thumbData, data, rect);
          redrawSequenceThumbCanvas(sequenceDrawing);
        }

        if (editor.drawing.thumbUpdate) {
          const { rect, data } = editor.drawing.thumbUpdate;
          // TODO: don't send if we're not thumbnail provider
          //       send even when visibleLocally !== undefined if we're thumbnail provider
          if (!editor.drawing.layers.some(l => l.visibleLocally !== undefined)) {
            logAction(`[local] updateThumb (id: ${editor.drawing.id})`);
            const compressed = compressImageDataRLE({ data: data as any, width: rect.w, height: rect.h, colorSpace: 'srgb' }, true);
            model.server.updateThumb(model.connId, rect.x, rect.y, compressed);
          }
          editor.drawing.thumbUpdate = undefined;
        }
      }
    } catch (e) {
      DEVELOPMENT && console.error(e);
    }

    // update layer thumbnails

    try {
      if (!redrewDrawingThumb) {
        editor.renderer.drawLayerThumbs(editor.drawing.layers, editor.drawing);
      }
    } catch (e) {
      DEVELOPMENT && console.error(e);
    }
  }

  try {
    for (const d of editor.drawing.sequence) {
      if (d.id === editor.drawing.id) continue;
      if (!d.thumbCanvas) continue;
      if (d.thumbLoading) continue;

      if (!d.thumbUpdated || ((d.thumbTimestamp || 0) > d.thumbUpdated && d.thumbUpdated < thumbTimeout)) {
        d.thumbUpdated = Date.now();
        d.thumbLoading = true;

        void loadImage(getThumbPath(d.id, d.cacheId ?? d.thumbUpdated, true))
          .then(img => {
            // notify server that we no longer have thumb data
            if (d.thumbData) model.server.quickAction(QuickAction.DiscardThumb, d.id);

            d.thumbImage = img;
            d.thumbData = undefined;
            redrawSequenceThumbCanvas(d);
          })
          .catch(e => {
            DEVELOPMENT && console.error(e);
          })
          .finally(() => {
            d.thumbLoading = false;
          });

        break; // update only one per frame
      }
    }
  } catch (e) {
    DEVELOPMENT && console.error(e);
  }
}

export function redrawSequenceThumbCanvas(drawing: SequenceDrawing) {
  const canvas = drawing.thumbCanvas;
  if (!canvas) return;

  const context = canvas.getContext('2d', { desynchronized: true, preserveDrawingBuffer: true }) as CanvasRenderingContext2D | undefined;
  if (!context) return;

  if (drawing.thumbImage) {
    const img = drawing.thumbImage;
    context.clearRect(0, 0, canvas.width, canvas.height);
    const s = Math.min(canvas.width / img.width, canvas.height / img.height);
    const dw = Math.ceil(img.width * s);
    const dh = Math.ceil(img.height * s);
    const dx = Math.floor((canvas.width - dw) / 2);
    const dy = Math.floor((canvas.height - dh) / 2);
    if (s > 1) context.imageSmoothingEnabled = false;
    context.drawImage(img, 0, 0, img.width, img.height, dx, dy, dw, dh);
    context.imageSmoothingEnabled = true;
    drawing.thumbImage = undefined;
  } else if (drawing.thumbData) {
    // TODO: only update changed bit ?
    const data = drawing.thumbData;
    const dx = Math.floor((canvas.width - data.width) / 2);
    const dy = Math.floor((canvas.height - data.height) / 2);
    context.putImageData(data, dx, dy);
  }
  drawing.thumbUpdated = Date.now();
}

export function commitPickColor(editor: Editor) {
  try {
    const pick = editor.pickColor;

    if (pick.do) {
      pick.do = false;
      pickColorAt(editor, pick.x, pick.y, pick.activeLayer, pick.secondary, pick.alphaTo);
    }
  } catch (e) {
    DEVELOPMENT && console.error(e);
  }
}
