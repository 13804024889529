// Promise

export function delay(timeout: number) {
  return new Promise<void>(resolve => setTimeout(resolve, timeout));
}

export function delayWithValue<T>(timeout: number, value: any) {
  return new Promise<T>(resolve => setTimeout(() => resolve(value), timeout));
}

export function concurrentMap<T, V>(array: T[], map: (value: T, index: number) => Promise<V>, concurrency = Infinity) {
  let index = 0;
  const results = array.map(() => undefined as any as V);
  const pending: Promise<V>[] = [];

  function wrappedMapper(): Promise<any> | undefined {
    if (index >= array.length) return undefined;
    const i = index++;
    return map(array[i], i).then(result => {
      results[i] = result;
      return wrappedMapper();
    });
  }

  for (let i = 0; i < concurrency; i++) {
    const thread = wrappedMapper();
    if (!thread) break;
    pending.push(thread);
  }

  return Promise.all(pending).then(() => results);
}

export interface Deferred<T> {
  promise: Promise<T>;
  resolve(result: T): void;
  reject(error?: Error): void;
}

export function deferred<T = void>(): Deferred<T> {
  const obj: Deferred<T> = <any>{};

  obj.promise = new Promise<T>(function (resolve, reject) {
    obj.resolve = resolve;
    obj.reject = reject;
  });

  return obj;
}

export function parallel<T, V>(items: T[], map: (item: T) => Promise<V>, limit: number): Promise<V[]> {
  return new Promise<V[]>((resolve, reject) => {
    const results: V[] = [];
    let rejected = false;
    let count = 0;
    let current = 0;
    let done = 0;

    function next() {
      if (rejected) return;

      if (done === items.length) {
        resolve(results);
        return;
      }

      while (current < items.length && count < limit) {
        const index = current++;
        count++;
        map(items[index])
          .then(result => {
            results[index] = result;
            count--;
            done++;
            next();
          }, error => {
            rejected = true;
            reject(error);
          });
      }
    }

    next();
  });
}
