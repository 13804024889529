import { Component, EventEmitter, Output } from '@angular/core';
import { switchToFallbackRenderer } from '../../../../services/webgl';


@Component({
  selector: 'texture-size-warning-modal',
  templateUrl: 'texture-size-warning-modal.pug',
  styleUrls: ['./texture-size-warning-modal.scss'],
})
export class TextureSizeWarningModalComponent {
  @Output() close = new EventEmitter<boolean | undefined>();

  accept() {
    this.close.emit(true);
    switchToFallbackRenderer();
  }

  cancel() {
    this.close.emit(false);
  }
}
