import { Component, EventEmitter, forwardRef, HostBinding, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SvgIconDefinition } from '../../../common/interfaces';

@Component({
  selector: 'custom-input',
  templateUrl: 'custom-input.pug',
  styleUrls: ['custom-input.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomInputComponent),
      multi: true,
    },
  ],
})
export class CustomInputComponent implements ControlValueAccessor {
  @HostBinding('class.form-control') hostFormControlClass = true;

  @Input() value = '';
  @Input() placeholder = '';
  @Input() icon?: SvgIconDefinition;
  @Input() id = '';
  @Input() autoFocus = false;

  @Output() valueChange = new EventEmitter<string>();
  @Output() blur = new EventEmitter<FocusEvent>();
  @Output() focus = new EventEmitter<FocusEvent>();

  updateChanges() {
    this.onChange(this.value);
  }
  onTouched = () => {
  };
  onChange = (_value: any) => {
    this.valueChange.emit(this.value);
  };
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
  writeValue(value: string) {
    this.value = value;
    this.updateChanges();
  }
}
