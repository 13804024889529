import {
  ITool, IToolEditor, IToolModel, TabletEvent, IToolData, SelectionMode, ToolId, Mask, hasAltKey,
  hasShiftKey, IUndoFunction, SelectionToolMode, CursorType,
} from '../interfaces';
import { getPixelRatio } from '../utils';
import { distance, round5 } from '../mathUtils';
import { invalidEnum } from '../baseUtils';
import { maskContainsXY, subtractRectFromMask, addRectToMask, clearMask, moveMask, isMaskEmpty, clampXLimits, clampYLimits, intersectRectWithMask } from '../mask';
import { selectRectIcon } from '../icons';
import { userSelectionContainsPoint } from '../selectionUtils';
import { copyRect, isRectEmpty, resetRect, clipRect, intersectRect, setRectDynamic, createRect, safeRect } from '../rect';
import { createPoint } from '../point';
import { documentToScreenXY } from '../viewport';
import { logAction } from '../actionLog';
import { redraw } from '../../services/editorUtils';
import { finishTransform } from '../toolUtils';

const SELECTION_RESTRICT_BOUNDS_MARGIN = 1000;
export enum SelectionToolAction {
  Clear,
  Select,
  Move,
  Add,
  Subtract,
  Intersect,
}

export type SelectionToolActionModes = SelectionToolAction.Select | SelectionToolAction.Subtract | SelectionToolAction.Add | SelectionToolAction.Intersect;

export interface ISelectionToolData extends IToolData {
  action: SelectionToolAction;
  x: number;
  y: number;
  w: number;
  h: number;
}

const temp = createPoint(0, 0);

export class SelectionTool implements ITool {
  id = ToolId.Selection;
  name = 'Selection';
  description = 'Make a rectangle-shaped selection';
  learnMore = 'https://help.magma.com/en/articles/6790127-selection-tool';
  video = { url: '/assets/videos/selection.mp4', width: 374, height: 210 };
  icon = selectRectIcon;
  cursor = CursorType.Crosshair;
  navigation = false;
  selection = true;
  continuousRedraw = true;
  usesModifiers = true;
  scrollView = true;
  action = SelectionToolAction.Select;
  fixedRatio = false;
  fromCenter = false;
  restrictToBounds = true;
  rect = createRect(0, 0, 0, 0);
  cancellableLocally = true;
  mode: SelectionToolMode = 'select';
  fields = ['mode'];
  private alt = false;
  private shift = false;
  private startX = 0;
  private startY = 0;
  private endX = 0;
  private endY = 0;
  private originalStartX = 0;
  private originalStartY = 0;
  private moveX = 0;
  private moveY = 0;
  private selectionBounds = createRect(0, 0, 0, 0);
  private startedInside = false;
  private moved = false;
  private selectionStarted = false;
  private undo: IUndoFunction | undefined = undefined;
  constructor(public editor: IToolEditor, public model: IToolModel) {
  }
  isPathEmpty() {
    return isRectEmpty(this.rect);
  }
  selectPath(selection: Mask, action: SelectionToolActionModes) {
    if (!isRectEmpty(this.rect)) {
      switch (action) {
        case SelectionToolAction.Select:
        case SelectionToolAction.Add:
          addRectToMask(selection, this.rect);
          break;
        case SelectionToolAction.Subtract:
          subtractRectFromMask(selection, this.rect);
          break;
        case SelectionToolAction.Intersect:
          intersectRectWithMask(selection, this.rect);
          break;
        default: invalidEnum(action);
      }
    }
  }
  drawPath(context: CanvasRenderingContext2D, xoffset = 0, yoffset = 0) {
    const view = this.editor.view;
    const ratio = getPixelRatio();

    context.beginPath();
    documentToScreenXY(temp, this.rect.x + xoffset, this.rect.y + yoffset, view);
    context.moveTo(round5(temp.x * ratio), round5(temp.y * ratio));
    documentToScreenXY(temp, this.rect.x + xoffset + this.rect.w, this.rect.y + yoffset, view);
    context.lineTo(round5(temp.x * ratio), round5(temp.y * ratio));
    documentToScreenXY(temp, this.rect.x + xoffset + this.rect.w, this.rect.y + yoffset + this.rect.h, view);
    context.lineTo(round5(temp.x * ratio), round5(temp.y * ratio));
    documentToScreenXY(temp, this.rect.x + xoffset, this.rect.y + yoffset + this.rect.h, view);
    context.lineTo(round5(temp.x * ratio), round5(temp.y * ratio));
    context.closePath();
  }
  do(data: ISelectionToolData) {
    const selection = this.model.user.selection;

    copyRect(this.rect, safeRect(data));

    finishTransform(this.editor, this.model.user, 'SelectionTool:do');
    this.model.user.history.pushSelection('selection');

    if (data.action === SelectionToolAction.Clear) {
      clearMask(selection);
    } else if (data.action === SelectionToolAction.Move) {
      moveMask(selection, this.rect.x, this.rect.y);
    } else {
      if (data.action === SelectionToolAction.Select) {
        clearMask(selection);
      }

      this.selectPath(selection, data.action);
    }
  }
  hover(x: number, y: number, e: TabletEvent) {
    const user = this.model.user;

    if (this.mode === 'add') {
      this.cursor = CursorType.SelectionAdd;
    } else if (this.mode === 'subtract') {
      this.cursor = CursorType.SelectionSubtract;
    } else if (this.mode === 'intersect') {
      this.cursor = CursorType.SelectionIntersect;
    } else if (!isMaskEmpty(user.selection) && (hasAltKey(e) || hasShiftKey(e))) {
      this.cursor = hasAltKey(e) ? CursorType.SelectionSubtract : CursorType.SelectionAdd;
      // TODO: add canvas cursors for add/subtract and move
      // this.canvasCursor = CursorType.None;
    } else if (userSelectionContainsPoint(user, x, y)) {
      this.cursor = CursorType.SelectionMove;
      // this.canvasCursor = CursorType.None;
    } else {
      this.cursor = CursorType.Crosshair;
      // this.canvasCursor = CursorType.Crosshair;
    }
  }
  start(x: number, y: number, pressure: number, e?: TabletEvent) {
    this.alt = !!e && hasAltKey(e);
    this.shift = !!e && hasShiftKey(e);
    this.originalStartX = x;
    this.originalStartY = y;
    this.moveX = 0;
    this.moveY = 0;
    this.startX = x | 0;
    this.startY = y | 0;
    this.moved = false;
    this.startedInside = false;
    this.selectionStarted = false;
    this.fixedRatio = false;
    this.fromCenter = false;

    finishTransform(this.editor, this.model.user, 'SelectionTool:start');
    this.undo = this.model.user.history.createSelection('selection');
    copyRect(this.selectionBounds, this.model.user.selection.bounds);
    resetRect(this.rect);

    const empty = isMaskEmpty(this.model.user.selection);

    if (this.mode === 'add') {
      this.action = SelectionToolAction.Add;
    } else if (this.mode === 'subtract' && !empty) {
      this.action = SelectionToolAction.Subtract;
    } else if (this.mode === 'intersect' && !empty) {
      this.action = SelectionToolAction.Intersect;
    } else if (empty) {
      this.action = SelectionToolAction.Select;
      this.fixedRatio = this.shift;
      this.fromCenter = this.alt;
    } else if (this.alt) {
      this.action = SelectionToolAction.Subtract;
    } else if (this.shift) {
      this.action = SelectionToolAction.Add;
    } else if (maskContainsXY(this.model.user.selection, x, y)) {
      this.action = SelectionToolAction.Clear;
      this.startedInside = true;
    } else {
      this.action = SelectionToolAction.Clear;
      this.selectionStarted = true;
      clearMask(this.model.user.selection);
    }

    this.doMove(x, y, pressure, e);
  }
  move(x: number, y: number, pressure: number, e?: TabletEvent) {
    this.doMove(x, y, pressure, e);
  }
  end(x: number, y: number, pressure: number, e?: TabletEvent) {
    this.doMove(x, y, pressure, e);
    const action = this.action;
    let omitted = false;

    switch (action) {
      case SelectionToolAction.Clear: { // guaranteed not empty selection
        this.selectionStarted = true;
        clearMask(this.model.user.selection);
        break;
      }
      case SelectionToolAction.Move: { // guaranteed that selection was moved
        break;
      }
      case SelectionToolAction.Select:
      case SelectionToolAction.Intersect:
      case SelectionToolAction.Add:
      case SelectionToolAction.Subtract: {
        if (this.selectionStarted) {
          if (isRectEmpty(this.rect)) {
            // TODO: started empty -> empty should be omitted
            // TODO: this can be selection outside canvas
            //   logAction('[local] empty selection omitted');
            //   // this.model.user.history.unpre(); // how to do this now ???
            //   this.model.user.history.cancelLastUndo(); // omitted, but still clears redos
            // omitted = true;
            if (action === SelectionToolAction.Select) clearMask(this.model.user.selection);
          } else {
            this.selectPath(this.model.user.selection, action);
          }
        } else {
          logAction('[local] selection omitted');
          omitted = true;
        }
        break;
      }
      default: invalidEnum(action);
    }

    if (omitted) {
      this.model.user.history.unpre();
    } else {
      if (!this.undo) throw new Error('Missing undo');
      this.model.user.history.pushUndo(this.undo);
      this.model.doTool<ISelectionToolData>(0, {
        id: this.id,
        action: action,
        selection: (action === SelectionToolAction.Clear || action === SelectionToolAction.Select) ? SelectionMode.Break : SelectionMode.Update,
        x: action === SelectionToolAction.Move ? clampXLimits(this.endX - this.startX, this.selectionBounds, this.editor.drawing) : this.rect.x,
        y: action === SelectionToolAction.Move ? clampYLimits(this.endY - this.startY, this.selectionBounds, this.editor.drawing) : this.rect.y,
        w: this.rect.w,
        h: this.rect.h,
        as: { ...this.model.user.selection.bounds }, // TEMP: testing
      });
    }

    this.undo = undefined;
    resetRect(this.rect);
    redraw(this.editor);
  }
  cancel() {
    this.undo?.();
  }
  private doMove(x: number, y: number, _pressure: number, e?: TabletEvent) {
    if (!e) throw new Error('Missing event');

    this.moved = this.moved || distance(x, y, this.originalStartX, this.originalStartY) > 0;
    this.endX = x | 0;
    this.endY = y | 0;

    if (this.moved) this.selectionStarted = true;
    if (this.moved && this.action === SelectionToolAction.Clear) {
      this.action = this.startedInside ? SelectionToolAction.Move : SelectionToolAction.Select;
    }

    if (this.alt !== hasAltKey(e)) this.fromCenter = this.alt = hasAltKey(e);
    if (this.shift !== hasShiftKey(e)) this.fixedRatio = this.shift = hasShiftKey(e);

    if (this.action === SelectionToolAction.Move) {
      const dx = this.endX - this.startX;
      const dy = this.endY - this.startY;
      const moveX = clampXLimits(dx, this.selectionBounds, this.editor.drawing);
      const moveY = clampYLimits(dy, this.selectionBounds, this.editor.drawing);
      moveMask(this.model.user.selection, moveX - this.moveX, moveY - this.moveY);
      this.moveX = moveX;
      this.moveY = moveY;
    } else if (this.moved) {
      setRectDynamic(this.rect, this.startX, this.startY,
        this.endX - this.startX, this.endY - this.startY, this.fixedRatio, this.fromCenter);

      this.rect.w += 1;
      this.rect.h += 1;

      if (this.restrictToBounds) {
        intersectRect(this.rect, this.editor.drawing);
      } else {
        clipRect(this.rect,
          this.editor.drawing.x - SELECTION_RESTRICT_BOUNDS_MARGIN,
          this.editor.drawing.y - SELECTION_RESTRICT_BOUNDS_MARGIN,
          this.editor.drawing.w + 2 * SELECTION_RESTRICT_BOUNDS_MARGIN,
          this.editor.drawing.h + 2 * SELECTION_RESTRICT_BOUNDS_MARGIN
        );
      }
    } else {
      resetRect(this.rect);
    }

    redraw(this.editor);
  }
}
