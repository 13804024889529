import { Allows, Feature, SubscriptionPlan, User } from './interfaces';

export function fullName(user: User) {
  return user.anonymous ? `${user.name}#${user.anonymousNumber}` : user.name;
}

export function isAnonymousName(name: string) {
  return /^anonymous$/i.test(name);
}

export function getAllows(user: { pro?: SubscriptionPlan; isSuperAdmin?: boolean; featureFlags?: Feature[] | Set<Feature>; }, team?: { featureFlags?: Feature[] }): Allows {
  const pro = Math.max(user.pro ?? SubscriptionPlan.Free, (user.isSuperAdmin || IS_HOSTED) ? SubscriptionPlan.Blaze : SubscriptionPlan.Free);// TODO remove isSuperAdmin ?
  return { pro, features: new Set([...user.featureFlags ?? [], ...team?.featureFlags ?? []]), isSuperAdmin: !!user.isSuperAdmin };
}
