import { hasFlag } from '../baseUtils';
import { createCanvas, getContext2d } from '../canvasUtils';
import { DEFAULT_BRUSH_TOOL_SETTINGS, MAX_BRUSH_SIZE, MAX_BRUSH_SPACING, MAX_SPREAD, MIN_BRUSH_SIZE, MIN_BRUSH_SPACING, setBrushFeaturesFromProps } from '../constants';
import { BrushBlendMode, BrushFeature, BrushToolSettings, CompressedBrush, IToolData, ShapePath } from '../interfaces';
import { PaintBrush, PaintBrushMode } from '../paintBrush';
import { svgPath } from '../path';
import { brushShapesMap } from '../shapes';
import { safeAngle, safeFloat, safeFloatAny, safeInt, safeIntAny, safeOpacity, safeUintAny } from '../toolUtils';
import type { BaseBrushTool } from './baseBrushTool';
import { clamp } from '../mathUtils';

export interface IBrushToolData extends IToolData {
  p: (string | number)[];
  shiftLine?: boolean;
}

export const enum BrushFlags {
  SizePressure = 0x0001,
  FlowPressure = 0x0002,
  OpacityLocked = 0x0004,
  Stabilize = 0x0008,
  HasMinSize = 0x0010,
  HasShape = 0x0020,
  AngleToDirection = 0x0040,
  HasSizeJitter = 0x0080,
  HasAngleJitter = 0x0100,
  HasSpread = 0x0200,
  SeparateSpread = 0x0400,
  ColorPressure = 0x0800,
  HasForegroundBackgroundJitter = 0x1000,
  HasColorJitter = 0x2000,
  ViewFlipped = 0x4000,
  ViewRotation = 0x8000,
  FlipX = 0x10000,
  FlipY = 0x20000,
  Roundness = 0x40000,
}

export const BRUSH_FIELDS_COMMON: (keyof BaseBrushTool)[] = [
  'size', 'minSize', 'flow', 'opacity', 'sizePressure', 'sizeJitter', 'flowPressure', 'stabilize',
  'colorPressure', 'foregroundBackgroundJitter', 'hueJitter', 'saturationJitter', 'brightnessJitter',
  'flipX', 'flipY', 'roundness',
];

export const BRUSH_FIELDS: (keyof BaseBrushTool)[] = [
  ...BRUSH_FIELDS_COMMON, 'sizeRatio', 'hardness', 'shape', 'separateSpread', 'normalSpread', 'tangentSpread',
  'spacing', 'angle', 'angleJitter', 'angleToDirection', 'name', 'hasFeatures', 'lockedFeatures'
];

export const brushSettingsFields: (keyof BrushToolSettings)[] = [
  'size', 'sizePressure', 'sizeJitter', 'minSize', 'flow', 'flowPressure', 'opacity', 'opacityPressure', 'spacing',
  'hardness', 'separateSpread', 'normalSpread', 'tangentSpread', 'shape', 'angle', 'angleJitter', 'angleToDirection',
  'colorPressure', 'foregroundBackgroundJitter', 'hueJitter', 'saturationJitter', 'brightnessJitter', 'flipX', 'flipY', 'roundness', 'name', '_id', 'hasFeatures', 'lockedFeatures'
];

export function encodePercent(value: number) {
  return Math.round(value * 100);
}

export function decodePercent(value: unknown) {
  return ((value as any) | 0) / 100;
}

export function roundPercent(value: number) {
  return decodePercent(encodePercent(value));
}

function safePercent(value: unknown) {
  return safeFloat(decodePercent(value), 0, 1);
}

export function createBrushShapeImageFromPath(path: ShapePath) {
  const size = 170;
  const canvas = createCanvas(size, size);
  const context = getContext2d(canvas);
  context.fillStyle = 'white';
  context.scale(size / path.width, size / path.height);
  context.beginPath();
  svgPath(context, path.path);
  context.fill();
  return canvas; // TODO: we should free this after reaching some limit
}

export function setImageFromShape(brush: PaintBrush, shapeId: string) {
  const shape = brushShapesMap.get(shapeId);
  if (!shape) throw new Error(`Missing brush shape (${shapeId})`);

  brush.setShape(shape);

  if (!shape.imageData && !shape.path) {
    brush.rotateToDirection = false;
  }
}

export function setupBrush(brush: PaintBrush, tool: ExtendedBrushToolSettings, color: number, colorHue: number, background: number) {
  brush.sizePressure = tool.sizePressure;
  brush.flowPressure = tool.flowPressure;
  brush.opacityPressure = tool.opacityPressure;
  brush.normalSpread = tool.normalSpread;
  brush.tangentSpread = tool.separateSpread ? tool.tangentSpread : tool.normalSpread;
  brush.sizeJitter = roundPercent(tool.sizeJitter);
  brush.angle = (tool.shape || tool.roundness !== 1) ? tool.angle : 0;
  if (hasFlag(tool.hasFeatures, BrushFeature.ShapeDynamics)) {
    brush.angleJitter = (tool.shape || tool.roundness !== 1) ? tool.angleJitter : 0;
    brush.rotateToDirection = (tool.shape || tool.roundness !== 1) ? tool.angleToDirection : false;
  } else {
    brush.angleJitter = 0;
    brush.rotateToDirection = false;
  }
  brush.setMode(PaintBrushMode.Brush);
  brush.color = color;
  brush.colorHue = colorHue;
  brush.background = background;
  brush.size = tool.size ?? 20;
  brush.setMinSize(roundPercent(tool.minSize));
  brush.setFlow(roundPercent(tool.flow));
  brush.setHardness(roundPercent(tool.hardness));
  brush.setSpacing(roundPercent(tool.spacing));
  brush.colorPressure = tool.colorPressure;
  brush.foregroundBackgroundJitter = roundPercent(tool.foregroundBackgroundJitter);
  brush.hueJitter = roundPercent(tool.hueJitter);
  brush.saturationJitter = roundPercent(tool.saturationJitter);
  brush.brightnessJitter = roundPercent(tool.brightnessJitter);
  brush.viewFlip = !!tool.viewFlip;
  brush.viewRotation = tool.viewRotation ?? 0;
  brush.flipX = tool.flipX;
  brush.flipY = tool.flipY;
  brush.roundness = clamp(tool.roundness, 0.03, 1);
  brush.hasFeatures = tool.hasFeatures;
  brush.lockedFeatures = tool.lockedFeatures;

  // TODO: need to solve this
  if (tool.spacing !== 0.2 || tool.shape) {
    brush.maxSpacing = 10000;
  } else {
    brush.maxSpacing = 10;
  }

  setImageFromShape(brush, tool.shape);
}

// TODO: better compression (use flags instead of booleans, skip unecessary fields, skip default values)
//       also use local DEFAULT_BRUSH_TOOL_SETTINGS instead of one from constants
export function compressBrush(brush: BrushToolSettings): CompressedBrush {
  const compressed: any = { name: brush.name };
  const base: any = DEFAULT_BRUSH_TOOL_SETTINGS;
  const target: any = { ...base, ...brush };

  for (const key of Object.keys(brush)) {
    if (target[key] !== base[key]) {
      compressed[key] = target[key];
    }
  }

  return compressed;
}

export function decompressBrushes(brushes: CompressedBrush[]): BrushToolSettings[] {
  return brushes.map(decompressBrush);
}

export function decompressBrush(brush: any): BrushToolSettings {
  const b ={ ...DEFAULT_BRUSH_TOOL_SETTINGS, ...brush };
  setBrushFeaturesFromProps(b);
  return b;
}

export interface ExtendedBrushToolSettings extends BrushToolSettings {
  opacityLocked?: boolean;
  color?: number;
  colorHue?: number;
  background?: number;
  seed?: number;
  stabilize?: number;
  viewFlip?: boolean;
  viewRotation?: number;
}

export const defaultBrushToolSettings: ExtendedBrushToolSettings = {
  _id: 'magma_rou_ink',
  name: '',
  // size
  sizePressure: true,
  sizeJitter: 0,
  minSize: 0,
  // other
  flow: 1,
  flowPressure: false,
  opacity: 1,
  opacityPressure: false,
  spacing: 0.2,
  hardness: 1,
  // spread
  separateSpread: false,
  normalSpread: 0,
  tangentSpread: 0,
  // shape
  shape: '',
  angle: 0,
  angleJitter: 3.14,
  angleToDirection: false,
  // color dynamics
  colorPressure: false,
  foregroundBackgroundJitter: 0,
  hueJitter: 0,
  saturationJitter: 0,
  brightnessJitter: 0,
  blendMode: BrushBlendMode.Normal,
  // advanced
  flipX: false,
  flipY: false,
  roundness: 1,
  // features
  hasFeatures: BrushFeature.BrushTipShape,
  lockedFeatures: 0,
};

export function compressBrushData(brush: ExtendedBrushToolSettings) {
  const hasSpread = brush.separateSpread ? (brush.normalSpread || brush.tangentSpread) : brush.normalSpread;
  const hasColorJitter = brush.hueJitter || brush.saturationJitter || brush.brightnessJitter;
  const hasAngleJitter = (brush.shape || brush.roundness !== 1) && brush.angleJitter;
  const flags = (
    (brush.sizePressure ? BrushFlags.SizePressure : 0) |
    (brush.flowPressure ? BrushFlags.FlowPressure : 0) |
    (brush.opacityLocked ? BrushFlags.OpacityLocked : 0) |
    (brush.stabilize ? BrushFlags.Stabilize : 0) |
    (brush.minSize ? BrushFlags.HasMinSize : 0) |
    (brush.shape ? BrushFlags.HasShape : 0) |
    (brush.angleToDirection ? BrushFlags.AngleToDirection : 0) |
    (brush.sizeJitter ? BrushFlags.HasSizeJitter : 0) |
    (hasAngleJitter ? BrushFlags.HasAngleJitter : 0) |
    (hasSpread ? BrushFlags.HasSpread : 0) |
    ((hasSpread && brush.separateSpread) ? BrushFlags.SeparateSpread : 0) |
    (brush.colorPressure ? BrushFlags.ColorPressure : 0) |
    (brush.foregroundBackgroundJitter ? BrushFlags.HasForegroundBackgroundJitter : 0) |
    (hasColorJitter ? BrushFlags.HasColorJitter : 0) |
    (brush.viewFlip ? BrushFlags.ViewFlipped : 0) |
    (brush.viewRotation ? BrushFlags.ViewRotation : 0) |
    (brush.flipX ? BrushFlags.FlipX : 0) |
    (brush.flipY ? BrushFlags.FlipY : 0) |
    (brush.roundness !== 1 ? BrushFlags.Roundness : 0)
  ) >>> 0;

  const data: (string | number)[] = [
    flags,
    brush.color ?? 0,
    brush.size ?? 0,
    encodePercent(brush.flow),
    encodePercent(brush.opacity),
    brush.spacing,
    brush.seed ?? 0,
    brush.hasFeatures,
  ];

  if (brush.minSize) data.push(encodePercent(brush.minSize));
  if (brush.sizeJitter) data.push(encodePercent(brush.sizeJitter));
  if (brush.stabilize) data.push(encodePercent(brush.stabilize));
  if (hasSpread) data.push(brush.normalSpread);
  if (hasSpread && brush.separateSpread) data.push(brush.tangentSpread);

  data.push(brush.angle);
  if (hasAngleJitter) data.push(brush.angleJitter);

  if (brush.shape) {
    data.push(brush.shape); 
  } else {
    data.push(encodePercent(brush.hardness));
  }

  if (brush.foregroundBackgroundJitter) {
    data.push(encodePercent(brush.foregroundBackgroundJitter));
  }

  if (brush.colorPressure || brush.foregroundBackgroundJitter) {
    data.push(brush.background ?? 0);
  }

  if (hasColorJitter) {
    data.push(brush.colorHue ?? 0);
    data.push(encodePercent(brush.hueJitter), encodePercent(brush.saturationJitter), encodePercent(brush.brightnessJitter));
  }

  if (brush.viewRotation) {
    data.push(brush.viewRotation);
  }

  if (brush.roundness) {
    data.push(brush.roundness);
  }

  return data;
}

export function decompressBrushData(p: (string | number)[], allowZeroSize = false) {
  const result: Partial<ExtendedBrushToolSettings> = {};

  // if the property is skipped make sure to set it to default value

  let i = 0;
  const flags = safeIntAny(p[i++]);
  result.sizePressure = hasFlag(flags, BrushFlags.SizePressure);
  result.flowPressure = hasFlag(flags, BrushFlags.FlowPressure);
  result.opacityLocked = hasFlag(flags, BrushFlags.OpacityLocked);
  result.angleToDirection = hasFlag(flags, BrushFlags.AngleToDirection);
  result.separateSpread = hasFlag(flags, BrushFlags.SeparateSpread);
  result.viewFlip = hasFlag(flags, BrushFlags.ViewFlipped);

  result.flipX = hasFlag(flags, BrushFlags.FlipX);
  result.flipY = hasFlag(flags, BrushFlags.FlipY);

  result.color = safeUintAny(p[i++]);
  result.size = safeFloat(p[i++], allowZeroSize ? 0 : MIN_BRUSH_SIZE, MAX_BRUSH_SIZE);
  result.flow = safePercent(p[i++]);
  result.opacity = safeOpacity(decodePercent(p[i++]));
  result.spacing = safeFloat(p[i++], MIN_BRUSH_SPACING, MAX_BRUSH_SPACING);
  result.seed = safeIntAny(p[i++]);
  result.hasFeatures = safeIntAny(p[i++]);

  result.minSize = hasFlag(flags, BrushFlags.HasMinSize) ? safePercent(p[i++]) : 0;
  result.sizeJitter = hasFlag(flags, BrushFlags.HasSizeJitter) ? safePercent(p[i++]) : 0;
  result.stabilize = hasFlag(flags, BrushFlags.Stabilize) ? safePercent(p[i++]) : 0;

  if (hasFlag(flags, BrushFlags.HasSpread)) {
    result.normalSpread = result.tangentSpread = safeFloat(p[i++], 0, MAX_SPREAD);
    if (result.separateSpread) result.tangentSpread = safeFloat(p[i++], 0, MAX_SPREAD);
  } else {
    result.normalSpread = result.tangentSpread = 0;
  }

  result.angle = safeAngle(p[i++]);
  result.angleJitter = hasFlag(flags, BrushFlags.HasAngleJitter) ? safeAngle(p[i++]) : 0;

  if (hasFlag(flags, BrushFlags.HasShape)) {
    result.shape = `${p[i++] || ''}`;
    // TODO: verify that shape exists
    result.hardness = 1;
  } else {
    result.shape = '';
    result.hardness = safeFloat(decodePercent(p[i++]), 0, 1);
  }

  result.colorPressure = hasFlag(flags, BrushFlags.ColorPressure);
  result.foregroundBackgroundJitter = hasFlag(flags, BrushFlags.HasForegroundBackgroundJitter) ? safePercent(p[i++]) : 0;

  if (result.colorPressure || result.foregroundBackgroundJitter) {
    result.background = safeUintAny(p[i++]);
  } else {
    result.background = 0;
  }

  if (hasFlag(flags, BrushFlags.HasColorJitter)) {
    result.colorHue = safeInt(p[i++], 0, 360);
    result.hueJitter = safePercent(p[i++]);
    result.saturationJitter = safePercent(p[i++]);
    result.brightnessJitter = safePercent(p[i++]);
  } else {
    result.colorHue = 0;
    result.hueJitter = 0;
    result.saturationJitter = 0;
    result.brightnessJitter = 0;
  }

  result.viewRotation = hasFlag(flags, BrushFlags.ViewRotation) ? safeFloatAny(p[i++]) : 0;

  result.roundness = hasFlag(flags, BrushFlags.Roundness) ? safeFloatAny(p[i++]) : 1;

  return result;
}
