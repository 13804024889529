import { removeItem, includes } from './baseUtils';

export function toggleClass(element: Element | any, className: string, set: boolean) {
  if ('classList' in element) {
    if (set) {
      element.classList.add(className);
    } else {
      element.classList.remove(className);
    }
  } else { // fallback for IE11 (in svg elements)
    const classes = element.getAttribute('class')?.split(' ') ?? [];

    if (includes(classes, className) !== set) {
      if (set) {
        classes.push(className);
      } else {
        removeItem(classes, className);
      }

      element.setAttribute('class', classes.join(' '));
    }
  }
}

export function removeAllNodes(element: Element) {
  let child: Node | null;

  while ((child = element.lastChild) != null) {
    element.removeChild(child);
  }
}

export function removeElement(element: Element) {
  try {
    element.parentElement?.removeChild(element);
  } catch { }
}

export function isDescendantOf(element: Element, parents: Element[]) {
  for (let e: Element | null = element; e; e = e.parentElement) {
    if (parents.indexOf(e) !== -1) return true;
  }

  return false;
}

export function appendText(element: HTMLElement, text: string) {
  element.appendChild(document.createTextNode(text));
}

export function findFocusableElements(root: HTMLElement) {
  const elements = root.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
  return (Array.from(elements) as HTMLElement[]).filter(e => !e.classList?.contains('hidden'));
}

export function isParentOfOrSelf(parent: HTMLElement, child: HTMLElement) {
  for (let current: HTMLElement | null = child; current; current = current.parentElement) {
    if (current === parent) {
      return true;
    }
  }

  return false;
}

export function focusFirstElement(root: HTMLElement) {
  const elements = findFocusableElements(root);

  if (elements.length) {
    elements[0].focus();
    return elements[0];
  }

  return undefined;
}

const actions: (() => void)[] = [];
let frame: any = undefined;

function execFrame() {
  frame = undefined;
  for (const a of actions) a();
  actions.length = 0;
}

// actions passed to this function are deduplicated and have to be unique
export function requestFrame(action: () => void) {
  if (!includes(actions, action)) actions.push(action);
  frame = frame || requestAnimationFrame(execFrame);
}

export function appendStyle(style: string) {
  const styleElement = document.createElement('style');
  styleElement.appendChild(document.createTextNode(style));
  document.head?.appendChild(styleElement);
  return styleElement;
}

export function scrollIntoViewHorizontally(scroller: Element, child: Element, pad: number) {
  const childRect = child.getBoundingClientRect();
  const scrollerRect = scroller.getBoundingClientRect();
  const left = scrollerRect.left + pad;
  const right = scrollerRect.right - pad;

  if (childRect.left < left) {
    scroller.scrollLeft -= left - childRect.left;
  } else if (childRect.right > right) {
    scroller.scrollLeft += childRect.right - right;
  }
}

export function downloadViaLink(url: string, name: string) {
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  a.rel = 'noopener';
  setTimeout(() => a.click());
}
