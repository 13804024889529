import { redrawDrawing, redrawLayer } from '../../services/editorUtils';
import { BLACK, DEFAULT_STABILIZE, MAX_PATTERN_SCALE, MIN_PATTERN_SCALE } from '../constants';
import { lassoBrushIcon } from '../icons';
import { CompositeOp, CursorType, ITabletTool, ITool, IToolData, IToolEditor, IToolModel, Layer, Polyf, ToolId } from '../interfaces';
import { addPolyfPoint, getPolyfBounds, isPolyfEmpty } from '../poly';
import { cloneRect, copyRect, createRect, integerizeRect, intersectRect, isRectEmpty, resetRect } from '../rect';
import { createInnerStabilizer } from '../stabilizer';
import { setupSurface } from '../toolSurface';
import { releaseToolRenderingContext } from '../user';
import { clamp } from '../mathUtils';
import { copyViewport, createViewport } from '../viewport';
import { roundPercent } from './brushUtils';
import { patternShapesMap } from '../shapes';
import { keys } from '../baseUtils';
import { finishTransform, safeAngle, safeFloat, safeInt, safeOpacity, safeUintAny } from '../toolUtils';
import { throwIfTextLayer } from '../text/text-utils';

export const enum LassoBrushMode {
  Draw = 0,
  Erase = 1,
}

export interface LassoBrushData extends IToolData {
  color: number;
  opacity: number;
  stabilize: number;
  pattern: string;
  patternAngle: number;
  patternScale: number;
  mode: LassoBrushMode;
}

export class LassoBrushTool implements ITool {
  id = ToolId.LassoBrush;
  name = 'Lasso Brush';
  description = 'Draw out your selection and fill it immediately with color or pattern';
  learnMore = 'https://help.magma.com/en/articles/6879708-lasso-brush';
  video = { url: '/assets/videos/lasso-brush.mp4', width: 374, height: 210 };
  icon = lassoBrushIcon;
  cursor = CursorType.Crosshair;
  updatesCursor = true;
  altTool = true;
  navigation = false;
  fields = keys<LassoBrushTool>(['opacity', 'stabilize', 'color', 'pattern', 'patternAngle', 'patternScale', 'mode', 'autoEraseMode']);
  opacity = 1;
  stabilize = DEFAULT_STABILIZE;
  color = BLACK;
  pattern = '';
  patternAngle = 0;
  patternScale = 16;
  mode = LassoBrushMode.Draw;
  autoEraseMode = false;
  view = createViewport();
  private viewCopy = createViewport();
  private polyf: Polyf = {
    ox: 0, oy: 0, segments: []
  };
  private needsFlush = false;
  private layer: Layer | undefined = undefined;
  private lastX = -1e5;
  private lastY = -1e5;
  private stable: ITabletTool;
  private proxy: ITabletTool;
  private endX = 0;
  private endY = 0;
  private lastRect = createRect(0, 0, 0, 0);
  drawingBounds = createRect(0, 0, 0, 0);
  constructor(public editor: IToolEditor, public model: IToolModel) {
    this.stable = this.proxy = {
      id: this.id,
      start: (x: number, y: number) => {
        if (!this.layer) throw new Error('[LassoBrushTool.proxy.start] Missing layer');

        finishTransform(this.editor, this.model.user, 'LassoBrushTool');

        this.lastX = -1e5;
        this.lastY = -1e5;
        this.polyf.ox = Math.round(x);
        this.polyf.oy = Math.round(x);
        this.polyf.segments.length = 0;
        this.needsFlush = false;
        resetRect(this.lastRect);

        const erase = this.mode === LassoBrushMode.Erase;
        const surface = this.model.user.surface;
        setupSurface(surface, this.id, erase ? CompositeOp.Erase : CompositeOp.Draw, this.layer, this.drawingBounds);
        surface.opacity = roundPercent(this.opacity);

        this.editor.renderer.getToolRenderingContext(this.model.user, this.drawingBounds);

        this.doMove(x, y);
      },
      move: (x: number, y: number) => {
        this.doMove(x, y);
      },
      end: (x: number, y: number) => {
        this.doMove(x, y);
        this.flush();

        if (!this.layer) throw new Error('[LassoBrushTool.proxy.end] Missing layer');

        const user = this.model.user;
        releaseToolRenderingContext(user);

        if (isRectEmpty(user.surface.rect)) {
          this.editor.renderer.releaseUserCanvas(user);
          this.model.cancelTool('empty');
          user.history.unpre();
        } else {
          user.history.pushDirtyRect(this.name, this.layer.id, user.surface.rect);
          const beforeRect = cloneRect(this.layer.rect);
          this.editor.renderer.commitTool(user, this.layer.opacityLocked);
          const afterRect = cloneRect(this.layer.rect);
          this.model.endTool(this.id, this.endX, this.endY, 0, beforeRect, afterRect);
          redrawLayer(this.editor, this.layer, this.layer.rect);
        }

        this.polyf.ox = 0;
        this.polyf.oy = 0;
        this.polyf.segments.length = 0;
        this.layer = undefined;
      }
    };
  }
  isPathEmpty() {
    return isPolyfEmpty(this.polyf);
  }
  setup(data?: LassoBrushData) {
    this.layer = this.model.user.activeLayer;
    if (!this.layer) throw new Error('[LassoBrushTool] Missing activeLayer');

    if (data) {
      this.mode = safeInt(data.mode, 0, 1);
      this.opacity = safeOpacity(data.opacity);
      this.stabilize = safeFloat(data.stabilize, 0, 1);
      this.pattern = `${data.pattern}`;
      this.patternAngle = safeAngle(data.patternAngle);
      this.patternScale = safeInt(data.patternScale, MIN_PATTERN_SCALE, MAX_PATTERN_SCALE);
      this.color = safeUintAny(data.color);
    } else {
      this.color = this.editor.primaryColor;
    }

    if (this.stabilize === 0) {
      this.stable = this.proxy;
    } else {
      this.stable = createInnerStabilizer(this.id, this.proxy, roundPercent(this.stabilize));
    }

    if (data) {
      if (!data.bounds) throw new Error('Missing drawing bounds');
      copyRect(this.drawingBounds, data.bounds);
    }
  }
  start(x: number, y: number) {
    if (!this.layer) throw new Error('[LassoBrushTool] Missing activeLayer');
    throwIfTextLayer(this.layer);

    copyViewport(this.viewCopy, this.view);
    this.stable.start!(x, y, 0, undefined);

    const { id, opacity, color, stabilize, pattern, patternAngle, patternScale, mode } = this;
    this.model.startTool<LassoBrushData>(this.layer.id, { id, opacity, color, stabilize, pattern, patternAngle, patternScale, mode, bounds: this.drawingBounds }, x, y, 0);
  }
  move(x: number, y: number) {
    this.stable.move!(x, y, 0);
    this.model.nextTool(x, y, 0);
  }
  end(x: number, y: number) {
    this.endX = x;
    this.endY = y;
    this.stable.end!(x, y, 0);
  }
  flush() {
    if (!this.needsFlush) return;

    const user = this.model.user;
    const context = user.surface.context;
    if (!context) throw new Error('Missing context');

    const rect = getPolyfBounds(this.polyf);
    integerizeRect(rect);
    if (this.mode == LassoBrushMode.Erase) intersectRect(rect, this.layer!.rect);
    copyRect(user.surface.rect, rect);

    const pattern = patternShapesMap.get(this.pattern);
    // TODO: support non-vector patterns?
    context.translate(-this.drawingBounds.x, -this.drawingBounds.y);

    if (!isRectEmpty(this.lastRect)) context.clearRect(this.lastRect.x - this.drawingBounds.x, this.lastRect.y - this.drawingBounds.y, this.lastRect.w, this.lastRect.h);
    context.fillPolyfgon(this.color, pattern?.path, this.patternAngle, this.patternScale, this.polyf);
    context.translate(this.drawingBounds.x, this.drawingBounds.y);
    context.flush();
    copyRect(this.lastRect, rect);

    redrawDrawing(this.editor, rect);

    this.needsFlush = false;
  }
  private doMove(x: number, y: number) {
    x = clamp(x, this.drawingBounds.x, this.drawingBounds.x + this.drawingBounds.w);
    y = clamp(y, this.drawingBounds.y, this.drawingBounds.y + this.drawingBounds.h);

    if ((Math.abs(x - this.lastX) > 1 || Math.abs(y - this.lastY) > 1)) {
      addPolyfPoint(this.polyf, x, y);
      this.lastX = x;
      this.lastY = y;
      this.needsFlush = true;
    }
  }
}
