import { logAction } from '../actionLog';
import { invalidEnum, keys } from '../baseUtils';
import { colorToCSS, colorToFloatArray } from '../color';
import { MAX_IMAGE_HEIGHT, MAX_IMAGE_WIDTH, WHITE_FLOAT, WHITE_STR } from '../constants';
import { blazeIcon, faCropAlt, farExclamationTriangle } from '../icons';
import { CursorType, Feature, ITool, IToolData, IToolEditor, IToolModel, Layer, Mat2d, Rect, TabletEvent, ToolId, User, Viewport, hasAltKey, hasShiftKey } from '../interfaces';
import { createMat2d, identityMat2d } from '../mat2d';
import { createRect, copyRect, setRect, resetRect, rectsEqual, rectToString } from '../rect';
import { createTransform, getTransformedRectBounds } from '../toolSurface';
import { finishTransform } from '../toolUtils';
import { cloneVec2, createVec2, setVec2, transformVec2ByMat2d } from '../vec2';
import { createViewport } from '../viewport';
import { pointInsideRegion } from './transformTool';
import { createShapePath } from '../shapes';
import { clamp } from '../mathUtils';
import { redraw } from '../../services/editorUtils';
import { hasPro } from '../userRole';

export enum CropToolState {
  Default,
  Upsell,
  Error
}

const enum BoxControl {
  Create,
  Other,
  TopLeft,
  TopRight,
  BottomRight,
  BottomLeft,
  Center,

  BottomCenter,
  TopCenter,
  Centerleft,
  CenterRight,
}

const cursors = new Map([
  [BoxControl.Other, CursorType.Move],
  [BoxControl.TopLeft, CursorType.ResizeTL],
  [BoxControl.TopRight, CursorType.ResizeTR],
  [BoxControl.BottomRight, CursorType.ResizeTL],
  [BoxControl.BottomLeft, CursorType.ResizeTR],
  [BoxControl.Center, CursorType.Crosshair],

  [BoxControl.BottomCenter, CursorType.ResizeV],
  [BoxControl.TopCenter, CursorType.ResizeV],
  [BoxControl.Centerleft, CursorType.ResizeH],
  [BoxControl.CenterRight, CursorType.ResizeH],
]);

export const CROP_LABEL_WIDTH = 80;
export const CROP_LABEL_HEIGTH = 40;
export const CROP_LABEL_ICON_WIDTH = 20;

export const CROP_SELECTION_COLOR_1_STR = WHITE_STR;
export const CROP_SELECTION_COLOR_1_FLOAT = WHITE_FLOAT;

export const CROP_SELECTION_COLOR_2_STR = colorToCSS(0x2E6DE1FF);
export const CROP_SELECTION_COLOR_2_FLOAT = colorToFloatArray(0x2E6DE1FF);

export const CROP_SELECTION_COLOR_ERROR_STR = colorToCSS(0xE34545FF);
export const CROP_SELECTION_COLOR_ERROR_FLOAT = colorToFloatArray(0xE34545FF);

export const CROP_LABEL_BLAZE_COLOR_STR = colorToCSS(0xE84244FF);
export const CROP_LABEL_ERROR_COLOR_STR = colorToCSS(0xE34545FF);

const CROP_BACKDROP_COLOR = 0x222222FF;
export const CROP_BACKDROP_COLOR_FLOAT = colorToFloatArray(CROP_BACKDROP_COLOR);

export enum CropOverlay {
  Disabled = 'disabled',
  RuleOfThirds = 'rule-of-thirds',
  Diagonal = 'diagonal',
}

export enum CropPreset {
  NoPreset = 'no-preset',
  CustomRatio = 'custom',
  OriginalRatio = 'original',
  Ratio_1_1 = '1:1',
  Ratio_4_3 = '4:3',
  Ratio_16_9 = '16:9',
  Ratio_3_2 = '3:2',
  Ratio_4_5 = '4:5',
}

export interface CropPresetOptions {
  ratio?: number
  w?: number;
  h?: number;
}

const cropPresetOptions: { [key in CropPreset]: CropPresetOptions } = {
  [CropPreset.NoPreset]: {},
  [CropPreset.CustomRatio]: {},
  [CropPreset.OriginalRatio]: { w: 1, h: 1, ratio: 1 },
  [CropPreset.Ratio_16_9]: { w: 16, h: 9, ratio: 16 / 9 },
  [CropPreset.Ratio_3_2]: { w: 3, h: 2, ratio: 3 / 2 },
  [CropPreset.Ratio_1_1]: { w: 1, h: 1, ratio: 1 },
  [CropPreset.Ratio_4_5]: { w: 4, h: 5, ratio: 4 / 5 },
  [CropPreset.Ratio_4_3]: { w: 4, h: 3, ratio: 4 / 3 },
};

export interface CropToolData extends IToolData {
  rect: Rect;
}

export class CropTool implements ITool {
  id = ToolId.Crop;
  name = 'Crop Tool';
  description = 'Expand or contract edges of the canvas';
  learnMore = 'https://help.magma.com/en/articles/8773095-canvas-crop-expand-and-trim';
  video = { url: '/assets/videos/crop.mp4', width: 374, height: 210 };
  feature = Feature.Crop;
  icon = faCropAlt;
  cursor = CursorType.Move;
  bounds = createRect(0, 0, 0, 0);
  startBounds = createRect(0, 0, 0, 0);
  boxMode = BoxControl.Other;
  startX = 0;
  startY = 0;
  view = createViewport();
  skipDrawingTool = false;

  fields = keys<CropTool>(['preset', 'overlay', 'maskOpacity', 'customRatioW', 'customRatioH']);

  maskOpacity = 70;

  overlayOptions = [CropOverlay.Disabled, CropOverlay.RuleOfThirds, CropOverlay.Diagonal];
  overlay = CropOverlay.Disabled;

  presetOptions = [
    CropPreset.NoPreset,
    CropPreset.CustomRatio,
    CropPreset.OriginalRatio,
    CropPreset.Ratio_16_9,
    CropPreset.Ratio_3_2,
    CropPreset.Ratio_1_1,
    CropPreset.Ratio_4_3,
    CropPreset.Ratio_4_5,
  ];
  preset = CropPreset.NoPreset;
  customRatioW = 1;
  customRatioH = 1;

  refPt = createVec2();
  ctrlPt = createVec2();
  transform: Mat2d = createMat2d();

  toolBlazeIconPaths = createShapePath(blazeIcon.icon[0], blazeIcon.icon[1], blazeIcon.icon[4] as string);
  toolWarningIconPaths = createShapePath(farExclamationTriangle.icon[0], farExclamationTriangle.icon[1], farExclamationTriangle.icon[4]);

  constructor(public editor: IToolEditor, public model: IToolModel) { }

  do(data: CropToolData) {
    logAction(`[remote] crop (rect: ${rectToString(data.rect)})`);
    finishTransform(this.editor, this.model.user, 'crop:do');
    this.model.user.history.pushResize(false);
  }

  private pickRegion(user: User, view: Viewport, x: number, y: number): BoxControl {
    const { surface } = user;
    const rect = this.bounds;

    const boxSize = 15 / view.scale;
    const boxOffset = 6 / view.scale;
    const boxSizeX = boxSize / Math.abs(surface.scaleX);
    const boxSizeY = boxSize / Math.abs(surface.scaleY);
    const boxOffsetX = boxOffset / Math.abs(surface.scaleX);
    const boxOffsetY = boxOffset / Math.abs(surface.scaleY);

    const l = rect.x;
    const r = rect.x + rect.w;
    const t = rect.y;
    const b = rect.y + rect.h;

    const l2 = l - boxSizeX;
    const r2 = r + boxSizeX;
    const t2 = t - boxSizeY;
    const b2 = b + boxSizeY;

    const l1 = l + boxOffsetX;
    const r1 = r - boxOffsetX;
    const t1 = t + boxOffsetY;
    const b1 = b - boxOffsetY;

    if (pointInsideRegion(x, y, l2, t2, l1, t2, l1, t1, l2, t1, undefined)) return BoxControl.TopLeft;
    if (pointInsideRegion(x, y, r1, t2, r2, t2, r2, t1, r1, t1, undefined)) return BoxControl.TopRight;
    if (pointInsideRegion(x, y, l2, b1, l1, b1, l1, b2, l2, b2, undefined)) return BoxControl.BottomLeft;
    if (pointInsideRegion(x, y, r1, b1, r2, b1, r2, b2, r1, b2, undefined)) return BoxControl.BottomRight;

    if (pointInsideRegion(x, y, l1, t2, r1, t2, r1, t1, l1, t1, undefined)) return BoxControl.TopCenter; // top
    if (pointInsideRegion(x, y, l1, b1, r1, b1, r1, b2, l1, b2, undefined)) return BoxControl.BottomCenter; // bottom
    if (pointInsideRegion(x, y, l2, t1, l1, t1, l1, b1, l2, b1, undefined)) return BoxControl.Centerleft; // left
    if (pointInsideRegion(x, y, r1, t1, r2, t1, r2, b1, r1, b1, undefined)) return BoxControl.CenterRight; // right

    return BoxControl.Other;
  }

  start(ax: number, ay: number) {
    this.skipDrawingTool = false;
    if (this.boxMode !== BoxControl.Create) {
      this.boxMode = this.pickRegion(this.model.user, this.editor.view, ax, ay);
      copyRect(this.startBounds, this.bounds);
    }
    this.startX = Math.round(ax);
    this.startY = Math.round(ay);
    if (this.boxMode === BoxControl.Create) {
      setRect(this.bounds, this.startX, this.startY, 0, 0);
    }
  }

  // oposite of control point
  private getRefPoint(point: Float32Array, alt: boolean) {
    const { x, y, w, h } = this.startBounds;
    if (alt) {
      return setVec2(point, x + w / 2, y + h / 2);
    }

    switch (this.boxMode) {
      case BoxControl.BottomRight:
        return setVec2(point, x, y);
      case BoxControl.BottomLeft:
        return setVec2(point, x + w, y);
      case BoxControl.TopLeft:
        return setVec2(point, x + w, y + h);
      case BoxControl.TopRight:
        return setVec2(point, x, y + h);

      case BoxControl.TopCenter:
        return setVec2(point, x + w / 2, y + h);
      case BoxControl.BottomCenter:
        return setVec2(point, x + w / 2, y);
      case BoxControl.CenterRight:
        return setVec2(point, x, y + h / 2);
      case BoxControl.Centerleft:
        return setVec2(point, x + w, y + h / 2);
      // can't use invalid enum here becuase box mode contains also custom modes
    }
    return point;
  }

  private getControlPoint(point: Float32Array) {
    const { x, y, w, h } = this.startBounds;
    switch (this.boxMode) {
      case BoxControl.BottomRight:
        return setVec2(point, x + w, y + h);
      case BoxControl.BottomLeft:
        return setVec2(point, x, y + h);
      case BoxControl.TopLeft:
        return setVec2(point, x, y);
      case BoxControl.TopRight:
        return setVec2(point, x + w, y);

      case BoxControl.TopCenter:
        return setVec2(point, x + w / 2, y);
      case BoxControl.BottomCenter:
        return setVec2(point, x + w / 2, y + h);
      case BoxControl.CenterRight:
        return setVec2(point, x + w, y + h / 2);
      case BoxControl.Centerleft:
        return setVec2(point, x, y + h / 2);
      // can't use invalid enum here becuase box mode contains also custom modes
    }

    return point;
  }

  move(ax: number, ay: number, _: number, e?: TabletEvent, clip = false) {
    let dx = Math.round(ax - this.startX);
    let dy = Math.round(ay - this.startY);

    const shift = !!e && hasShiftKey(e);
    const alt = !!e && hasAltKey(e);
    const options = this.getPresetOptions();

    // do not use values from options.ratio here becuase use could switch w and h
    const ratio = options.ratio ? this.customRatioW / this.customRatioH : undefined;

    if (this.boxMode === BoxControl.Create) {
      const sign = Math.sign(dy);
      if (dx < 0) {
        if (ratio) dy = -dx * sign / ratio;
        this.bounds.x = this.startX + dx;
        this.bounds.w = -dx;
      } else {
        if (ratio) dy = dx * sign / ratio;
        this.bounds.x = this.startX;
        this.bounds.w = dx;
      }
      if (dy < 0) {
        this.bounds.y = this.startY + dy;
        this.bounds.h = -dy;
      } else {
        this.bounds.y = this.startY;
        this.bounds.h = dy;
      }

      return;
    }

    let tx = 0;
    let ty = 0;
    let sx = 1;
    let sy = 1;

    if (this.boxMode === BoxControl.Other) {
      tx = dx;
      ty = dy;
    } else {
      this.getRefPoint(this.refPt, alt);
      this.getControlPoint(this.ctrlPt);

      const w = this.ctrlPt[0] - this.refPt[0];
      const h = this.ctrlPt[1] - this.refPt[1];
      sx = w ? (this.ctrlPt[0] + dx - this.refPt[0]) / w : 1;
      sy = h ? (this.ctrlPt[1] + dy - this.refPt[1]) / h : 1;

      if (ratio) {
        if (w === 0) {
          const sh = this.startBounds.h * sy;
          const sw = sh * ratio;
          sx = sw / this.startBounds.w;
        } else {
          const sw = this.startBounds.w * sx;
          const sh = sw / ratio;
          sy = sh / this.startBounds.h;
        }
      } else if (shift) {
        if (w === 0) {
          sx = sy;
        } else if (h !== 0) {
          sx = (sx + sy) / 2;
        }
        sy = sx;
      }

      if (clip) {
        // do not allow to resize crop bounding box to size that is bigger than max image size
        const maxSize = MAX_IMAGE_WIDTH;
        if (ratio && ((- maxSize / this.startBounds.w) > sx || sx > (maxSize / this.startBounds.w))) {
          sx = clamp(sx, - maxSize / this.startBounds.w, maxSize / this.startBounds.w);
          const sw = this.startBounds.w * sx;
          const sh = sw / ratio;
          sy = sh / this.startBounds.h;
        }
        if (ratio && ((- maxSize / this.startBounds.h) > sy || sy > (maxSize / this.startBounds.h))) {
          sy = clamp(sy, - maxSize / this.startBounds.h, maxSize / this.startBounds.h);
          const sh = this.startBounds.h * sy;
          const sw = sh * ratio;
          sx = sw / this.startBounds.w;
        }

        if (ratio === undefined) {
          sx = clamp(sx, - maxSize / this.startBounds.w, maxSize / this.startBounds.w);
          sy = clamp(sy, - maxSize / this.startBounds.h, maxSize / this.startBounds.h);
        }
      }

      const newRefPt = cloneVec2(this.refPt);
      createTransform(this.transform, 0, 0, 0, sx, sy);
      transformVec2ByMat2d(newRefPt, newRefPt, this.transform);

      tx = this.refPt[0] - newRefPt[0];
      ty = this.refPt[1] - newRefPt[1];
    }

    createTransform(this.transform, tx, ty, 0, sx, sy);
    this.bounds = getTransformedRectBounds(this.startBounds, this.transform);
    this.bounds.w = clamp(this.bounds.w, 1, MAX_IMAGE_WIDTH);
    this.bounds.h = clamp(this.bounds.h, 1, MAX_IMAGE_HEIGHT);
  }

  end(ax: number, ay: number, pressure: number, e?: TabletEvent) {
    this.move(ax, ay, pressure, e, true);

    if (this.bounds.w <= 1 || this.bounds.h <= 1) {
      copyRect(this.bounds, this.startBounds);
    }

    this.boxMode = BoxControl.Other;

    // reset transform
    identityMat2d(this.transform);

    redraw(this.editor);
  }

  cancel(): void {
    copyRect(this.bounds, this.startBounds);
  }

  hover(ax: number, ay: number) {
    if (this.boxMode === BoxControl.Create) {
      this.cursor = CursorType.Crosshair;
    } else {
      const region = this.pickRegion(this.model.user, this.editor.view, ax + this.editor.drawing.x, ay + this.editor.drawing.y);
      this.cursor = cursors.get(region) ?? CursorType.Move;
    }
  }

  layerSize(layer: Layer | undefined) {
    if (layer) copyRect(this.bounds, layer.rect);
  }

  select() {
    this.boxMode = BoxControl.Create;
    this.skipDrawingTool = true;
    copyRect(this.startBounds, this.bounds);
    resetRect(this.bounds);
  }

  reset() {
    copyRect(this.bounds, this.editor.drawing);
    this.setPreset(CropPreset.OriginalRatio);
  }

  resetSettings() {
    copyRect(this.bounds, this.editor.drawing);
    cropPresetOptions[CropPreset.OriginalRatio].ratio = this.editor.drawing.w / this.editor.drawing.h;
    cropPresetOptions[CropPreset.OriginalRatio].w = this.editor.drawing.w;
    cropPresetOptions[CropPreset.OriginalRatio].h = this.editor.drawing.h;

    cropPresetOptions[CropPreset.CustomRatio].ratio = this.customRatioW / this.customRatioH;
    cropPresetOptions[CropPreset.CustomRatio].w = this.customRatioW;
    cropPresetOptions[CropPreset.CustomRatio].h = this.customRatioH;
    this.setPreset(this.preset);
  }

  onDeselect() {
    this.reset();
  }

  getPresetOptions() {
    return cropPresetOptions[this.preset];
  }

  setPreset(preset: CropPreset) {
    this.preset = preset;
    const options = this.getPresetOptions();

    if (options.w && options.h) {
      this.customRatioW = options.w;
      this.customRatioH = options.h;
    }

    if (options.ratio) this.bounds.h = this.bounds.w / options.ratio | 0;
  }

  changedCustomRatio() {
    if (this.customRatioW && this.customRatioH && !Number.isNaN(Math.abs(this.customRatioW)) && !Number.isNaN(Math.abs(this.customRatioH))) {
      this.customRatioH = Math.abs(this.customRatioH);
      this.customRatioW = Math.abs(this.customRatioW);
      cropPresetOptions[CropPreset.CustomRatio].ratio = this.customRatioW / this.customRatioH;
      cropPresetOptions[CropPreset.CustomRatio].w = this.customRatioW;
      cropPresetOptions[CropPreset.CustomRatio].h = this.customRatioH;
      this.setPreset(CropPreset.CustomRatio);
    }
  }

  swtichRatio() {
    [this.customRatioW, this.customRatioH] = [this.customRatioH, this.customRatioW];
    [this.bounds.h, this.bounds.w] = [this.bounds.w, this.bounds.h];
  }

  canCrop() {
    return !rectsEqual(this.bounds, this.editor.drawing) && this.state() === CropToolState.Default;
  }

  getCropOverlayName(overlay: CropOverlay) {
    switch (overlay) {
      case CropOverlay.Disabled:
        return 'Disabled';
      case CropOverlay.RuleOfThirds:
        return 'Rule of Thirds';
      case CropOverlay.Diagonal:
        return 'Diagonal';
      default: invalidEnum(overlay);
    }
  }

  getCropPresetName(preset: CropPreset) {
    switch (preset) {
      case CropPreset.NoPreset:
        return 'No preset';
      case CropPreset.CustomRatio:
        return 'Custom ratio';
      case CropPreset.OriginalRatio:
        return 'Original ratio';
      case CropPreset.Ratio_1_1:
        return '1:1 (Square)';
      case CropPreset.Ratio_4_3:
        return '4:3';
      case CropPreset.Ratio_16_9:
        return '16:9';
      case CropPreset.Ratio_3_2:
        return '3:2';
      case CropPreset.Ratio_4_5:
        return '4:5';
      default: invalidEnum(preset);
    }
  }

  state(): CropToolState {
    if (this.bounds.w > MAX_IMAGE_WIDTH || this.bounds.h > MAX_IMAGE_HEIGHT) return CropToolState.Error;
    if (this.bounds.w > this.model.getMaxImageSize() || this.bounds.h > this.model.getMaxImageSize()) return CropToolState.Upsell;
    return CropToolState.Default;
  }

  showUpsell() {
    return !hasPro(this.model.user) && (this.bounds.w > this.model.getMaxImageSize() || this.bounds.h > this.model.getMaxImageSize());
  }
}
