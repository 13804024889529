import { createCanvas, getContext2d } from '../common/canvasUtils';
import { colorToFloatArray } from '../common/color';
import { TRANSPARENT } from '../common/constants';
import { BitmapData, Drawing, IFilterTool, ITool, Layer, LayerData, Rect, Shader, Texture, TextureFormat, Tile, Tiles, User, WebGLResources } from '../common/interfaces';
import { invalidEnum, removeItemFast } from '../common/baseUtils';
import { isChromeOS, isiPad } from '../common/userAgentUtils';
import { addRect, cloneRect, copyRect, createRect, intersectRect, outsetRect, rectsEqual, roundRectToGrid } from '../common/rect';
import { ensureTextureMipmaps, getTexture, releaseTexture } from './webglRenderer';
import { clamp } from '../common/mathUtils';
import { getTransformedSurfaceBounds, isSurfaceEmpty } from '../common/toolSurface';
import { storageSetItem } from './storage';

export interface Mesh {
  vertexBuffer: WebGLBuffer;
  indexBuffer: WebGLBuffer;
  elements: number;
}

type Context = WebGLRenderingContext;

export const FLOATS_PER_VERTEX = 10; // x, y, tx, ty, custom1, custom2, r, g, b, a
export const STRIDE = FLOATS_PER_VERTEX * 4;

export const MESH_FLOATS_PER_VERTEX = 8; // x, y, z, nx, ny, nz, tx, ty
export const MESH_STRIDE = MESH_FLOATS_PER_VERTEX * 4;

const GL_ERRORS: (keyof Context)[] = [
  'NO_ERROR',
  'INVALID_ENUM',
  'INVALID_VALUE',
  'INVALID_OPERATION',
  'INVALID_FRAMEBUFFER_OPERATION',
  'OUT_OF_MEMORY',
  'CONTEXT_LOST_WEBGL',
];

function getError(gl: Context) {
  const error = gl.getError();

  for (const e of GL_ERRORS) {
    if (error === gl[e]) return e;
  }

  return `${error}`;
}

export function setTextureTileSize(webgl: WebGLResources, drawing: Drawing) {
  const tileSize = Math.min(
    findTileSize(drawing.w, webgl.params.maxTextureSize),
    findTileSize(drawing.h, webgl.params.maxTextureSize)
  );

  drawing.tileMarginSize = TESTS ? 4 : tileSize / 32;
  drawing.tileSize = TESTS ? 120 : (tileSize - 2 * drawing.tileMarginSize);
}

export function findTileSize(originalSize: number, limit: number) {
  const max = Math.pow(2, Math.floor(Math.log2(limit)));
  const min = 256;
  const size = Math.pow(2, Math.ceil(Math.log2(originalSize / 4))); // use 'optimal' tile size picked based on drawing size (will be clamped to limits later)
  //const size = 512; // force specific tile size (will be clamped to limits later)
  //const size = Math.pow(2, Math.ceil(Math.log2(originalSize))); // use tile size that will cover whole drawing, to make it work change also setTextureTileSize to use Math.max instead of min (will be clamped to limits later)
  return clamp(size, min, max);
}

export function findPowerOf2(originalSize: number) {
  return Math.max(1, Math.pow(2, Math.ceil(Math.log2(originalSize))));
}

export function findMultipleOf256(originalSize: number) {
  return (((originalSize + 256 - 1) / 256) | 0) * 256;
}

export interface ShaderSource {
  vertex: string;
  fragment: string;
}

export function initUniforms(gl: Context, program: WebGLProgram, source: ShaderSource) {
  gl.useProgram(program);

  const uniforms: { [key: string]: WebGLUniformLocation; } = {};
  const samplerNames: string[] = [];
  const combinedSource = `${source.vertex}\n${source.fragment}`;
  const matches = combinedSource.match(/uniform [a-z0-9_]+ [a-z_][a-z0-9_]*/ig) || [];

  for (const match of matches) {
    const [, type, name] = match.split(' ');
    const location = gl.getUniformLocation(program, name);

    if (location) {
      uniforms[name] = location;
      if (type === 'sampler2D') {
        samplerNames.push(name);
      }
    }
  }

  // TODO: better
  samplerNames.forEach(name => gl.uniform1i(uniforms[name], parseInt(name.substring('sampler'.length), 10) - 1));
  return uniforms;
}

export function initCoeffs(gl: Context, program: WebGLProgram, source: ShaderSource) {
  gl.useProgram(program);

  const coeffs: WebGLUniformLocation[] = [];
  const combinedSource = `${source.vertex}\n${source.fragment}`;
  const matches = combinedSource.match(/uniform float coeff[0-9_]*/ig) || [];

  for (let i = 0; i < matches.length; i++) {
    const name = `coeff${i}`;
    const location = gl.getUniformLocation(program, name);

    if (location) {
      coeffs.push(location);
    }
  }

  return coeffs;
}

export function createShaderProgram(gl: Context, vertexShader: WebGLShader, fragmentShader: WebGLShader, vertexSource: string): Shader {
  const program = gl.createProgram();
  if (!program) throw new Error(`Failed to create program (${getError(gl)})`);

  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);

  const matches = vertexSource.match(/attribute [a-z0-9_]+ [a-z_][a-z0-9_]*/ig) || [];

  for (let i = 0; i < matches.length; i++) {
    const parts = matches[i].split(' ');
    gl.bindAttribLocation(program, i, parts[2]);
  }

  gl.linkProgram(program);

  if (DEVELOPMENT && !gl.getProgramParameter(program, gl.LINK_STATUS)) {
    throw new Error(`Could not create program: ${gl.getProgramInfoLog(program)}`);
  }

  return { program, uniforms: {} };
}

export function createShader(gl: Context, source: ShaderSource): Shader {
  const vertexShader = createWebGLShader(gl, gl.VERTEX_SHADER, source.vertex, true);
  const fragmentShader = createWebGLShader(gl, gl.FRAGMENT_SHADER, source.fragment, isiPad);
  const shader = createShaderProgram(gl, vertexShader, fragmentShader, source.vertex);
  gl.deleteShader(vertexShader);
  gl.deleteShader(fragmentShader);
  return shader;
}

export let allocatedShaders: WebGLShader[] = [];

export function createWebGLShader(gl: Context, type: number, source: string, highp: boolean) {
  const supports = highp && !!gl.getShaderPrecisionFormat(type, gl.HIGH_FLOAT)?.precision;
  const precision = supports ? 'highp' : 'mediump';
  source = `precision ${precision} float;\n${source}`;

  const shader = gl.createShader(type);
  if (!shader) throw new Error(`Failed to create shader (${getError(gl)})`);
  allocatedShaders.push(shader);

  gl.shaderSource(shader, source);
  gl.compileShader(shader);

  if (DEVELOPMENT) {
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
      console.log(source.split(/\n/g).map((l, i) => `${i + 1}: ${l}`).join('\n'));
      console.error(gl.getShaderInfoLog(shader) || 'Shader error');
      throw new Error(gl.getShaderInfoLog(shader) || 'Shader error');
    }
  }

  return shader;
}

// when disabled - screen blinking on Chrome OS
// when enabled - performance issues on iOS14 and some other mobile devices
export const preserveDrawingBuffer = isChromeOS;

// import { makeDebugContext, glEnumToString } from 'webgl-debug';

let webglId = 1;

export function getWebGLContext(canvas: HTMLCanvasElement): { gl: Context; webgl2: boolean; } {
  const alpha = !isChromeOS; // some old devices have issues with alpha: false, override for old contexts
  const options = {
    alpha: false, // TODO: maybe force always true instead ? why do we even set it to false ?
    antialias: false,
    // disabled for Chrome OS to check if this helps with blinking screen
    desynchronized: !isChromeOS, // this causes flicker when resizing but also provides considerable perf improvement
    premultipliedAlpha: true,
    preserveDrawingBuffer,
    powerPreference: 'high-performance',
    // failIfMajorPerformanceCaveat: true,
  };

  let webgl2 = true;
  let gl: any = canvas.getContext('webgl2', options);

  if (!gl) {
    gl = canvas.getContext('webgl', { ...options, alpha }) ||
      canvas.getContext('experimental-webgl', { ...options, alpha });
    webgl2 = false;
  }

  // if (DEVELOPMENT) {
  //   gl = makeDebugContext(gl, (err: any, funcName: any, _args: any) => {
  //     throw new Error(`${glEnumToString(err)} in ${funcName}`);
  //   });
  // }

  if (!gl) throw new Error('WebGL not supported');

  (gl as any).webglId = webglId++;

  if (!webgl2) {
    const blendMinMax = gl.getExtension('EXT_blend_minmax');

    if (blendMinMax) {
      gl.MIN = blendMinMax.MIN_EXT;
      gl.MAX = blendMinMax.MAX_EXT;
    }
  }

  return { gl, webgl2 };
}

export const allocatedTextures: WebGLTexture[] = [];
export let allocatedBuffers = 0;

export function allocBuffer(gl: Context): WebGLBuffer {
  const buffer = gl.createBuffer();
  if (buffer == null) throw new Error(`Failed to create buffer (${getError(gl)})`);
  allocatedBuffers++;
  return buffer;
}

export function deleteBuffer(gl: Context, buffer: WebGLBuffer | null) {
  if (buffer) {
    gl.deleteBuffer(buffer);
    allocatedBuffers--;
  }
}

function allocTextureHandle(gl: Context): WebGLTexture {
  const handle = gl.createTexture();
  if (handle == null) throw new Error(`Failed to create texture (${getError(gl)})`);
  allocatedTextures.push(handle);
  return handle;
}

export function deleteTexture(gl: Context, texture: Texture | undefined) {
  if (texture) {
    removeItemFast(allocatedTextures, texture.handle);
    gl.deleteTexture(texture.handle);
  }
}

export function checkForLeakedTextures(webgls: WebGLResources[], drawings: Drawing[], usersList: User[][], tools: ITool[]) {
  const leaked: WebGLTexture[] = [];

  outer: for (const handle of allocatedTextures) {
    for (const webgl of webgls) {
      if (handle === webgl.whiteTexture?.handle) continue outer;
      if (handle === webgl.emptyTexture?.handle) continue outer;
      if (handle === webgl.thumbnailTexture?.handle) continue outer;
      if (handle === webgl.namePlatesTexture?.handle) continue outer;
      if (handle === webgl.videoPlatesTexture?.handle) continue outer;
      if (handle === webgl.selfVideoTexture?.handle) continue outer;
      if (handle === webgl.maskTexture?.handle) continue outer;
      if (handle === webgl.fallbackCursorsTexture?.handle) continue outer;
      if (handle === webgl.spritesTexture?.handle) continue outer;
      if (handle === webgl.cropLabelTexture?.handle) continue outer;
      if (webgl.textures.some(t => t.handle === handle)) continue outer;
      if (webgl.texturesToDebug.some(t => t.handle === handle)) continue outer;
      if (webgl.brushCache.some(b => b.texture.handle === handle)) continue outer;
      if (handle === webgl.tempDebugTexture?.handle) continue outer;
      if (handle === webgl.benchmarkTexture?.handle) continue outer;
    }

    for (const drawing of drawings) {
      if (drawing.tiles.tiles.some(list => list.some(b => b && b.texture.handle === handle))) continue outer;

      for (const layer of drawing.layers) {
        if (handle === layer.texture?.handle) continue outer;
      }
    }

    for (const users of usersList) {
      for (const user of users) {
        if (handle === user.surface.texture?.handle) continue outer;
        if (user.history.textureHandleInUse(handle)) continue outer;
      }
    }

    for (const tool of tools) {
      if ('snapshot' in tool) {
        const { snapshot } = tool as IFilterTool;
        if (snapshot && 'handle' in snapshot && snapshot.handle === handle) continue outer;
      }
    }

    leaked.push(handle);
  }

  return leaked;
}

export function toGLFormat(gl: Context, format: TextureFormat) {
  switch (format) {
    case TextureFormat.RGBA: return gl.RGBA;
    case TextureFormat.Alpha: return gl.ALPHA;
    default: invalidEnum(format);
  }
}

// assumes `data` to be premultiplied
export function createEmptyTexture(
  webgl: WebGLResources, width: number, height: number, data: Uint8Array | null = null, format = TextureFormat.RGBA, unpackPremultiply = false
): Texture {
  const { gl } = webgl;
  if (width <= 0 || height <= 0 || width > webgl.params.maxTextureSize || height > webgl.params.maxTextureSize) throw new Error(`Invalid texture size ${width}x${height}, max size: ${webgl.params.maxTextureSize}`);

  const glFormat = toGLFormat(gl, format);
  const handle = allocTextureHandle(gl);
  gl.bindTexture(gl.TEXTURE_2D, handle);
  gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, unpackPremultiply ? 1 : 0);
  gl.texImage2D(gl.TEXTURE_2D, 0, glFormat, width, height, 0, glFormat, gl.UNSIGNED_BYTE, data);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.bindTexture(gl.TEXTURE_2D, null);
  return { id: '', width, height, format, handle, webglId: (gl as any).webglId, hasMipmaps: false };
}

export function createSpriteTexture(webgl: WebGLResources, image: BitmapData, format = TextureFormat.RGBA, unpackPremultiply = true): Texture {
  const { gl } = webgl;
  const { width, height, data } = image;
  if (width <= 0 || height <= 0 || width > webgl.params.maxTextureSize || height > webgl.params.maxTextureSize) throw new Error(`Invalid texture size ${width}x${height}`);

  const glFormat = toGLFormat(gl, TextureFormat.RGBA);
  const handle = allocTextureHandle(gl);
  gl.bindTexture(gl.TEXTURE_2D, handle);
  gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, unpackPremultiply ? 1 : 0);
  gl.texImage2D(gl.TEXTURE_2D, 0, glFormat, width, height, 0, glFormat, gl.UNSIGNED_BYTE, data);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
  gl.generateMipmap(gl.TEXTURE_2D);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.bindTexture(gl.TEXTURE_2D, null);
  return { id: '', width, height, format, handle, webglId: (gl as any).webglId, hasMipmaps: true };
}

export function bindRenderTarget(webgl: WebGLResources, texture: Texture, color?: number[] | Float32Array) {
  const { gl, frameBuffer } = webgl;
  if (DEVELOPMENT && (!texture || !frameBuffer)) throw new Error('Invalid arguments');

  gl.bindFramebuffer(gl.FRAMEBUFFER, frameBuffer);
  gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture.handle, 0);
  gl.viewport(0, 0, texture.width, texture.height);

  webgl.frameBufferWidth = texture.width;
  webgl.frameBufferHeight = texture.height;

  if (color) {
    gl.clearColor(color[0], color[1], color[2], color[3]);
    gl.clear(gl.COLOR_BUFFER_BIT);
  }

  texture.hasMipmaps = false; // assume that texture was bound to change it so invalidate mimpmaps
}

export function unbindRenderTarget(webgl: WebGLResources) {
  const gl = webgl.gl;
  gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, null, 0);
  gl.bindFramebuffer(gl.FRAMEBUFFER, null);

  webgl.frameBufferWidth = -1;
  webgl.frameBufferHeight = -1;
}

// assumes that texture is bound and SCISSOR_TEST is enabled
export function clearBoundTextureRect(gl: WebGLRenderingContext, x: number, y: number, w: number, h: number, color: Float32Array) {
  gl.scissor(x, y, w, h);
  gl.clearColor(color[0], color[1], color[2], color[3]);
  gl.clear(gl.COLOR_BUFFER_BIT);
}

export function clearTextureRect(webgl: WebGLResources, texture: Texture, x: number, y: number, w: number, h: number) {
  const { gl } = webgl;
  bindRenderTarget(webgl, texture);
  gl.enable(gl.SCISSOR_TEST);
  gl.scissor(x, y, w, h);
  gl.clearColor(0, 0, 0, 0);
  gl.clear(gl.COLOR_BUFFER_BIT);
  gl.disable(gl.SCISSOR_TEST);
  unbindRenderTarget(webgl);
}

export function unbindTexture(gl: WebGLRenderingContext, unit: number, resetFilterToNearest = false) {
  gl.activeTexture(gl.TEXTURE0 + unit);
  if (resetFilterToNearest) {
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
  }
  gl.bindTexture(gl.TEXTURE_2D, null);
}

export function bindTexture(gl: WebGLRenderingContext, unit: number, texture: Texture, setFilterToLinear = false) {
  gl.activeTexture(gl.TEXTURE0 + unit);
  gl.bindTexture(gl.TEXTURE_2D, texture.handle);

  if (setFilterToLinear) {
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
    ensureTextureMipmaps(gl, texture);
  }
}

const transparentColor = colorToFloatArray(TRANSPARENT);

export function clearTexture(webgl: WebGLResources, texture: Texture, color = transparentColor) {
  bindRenderTarget(webgl, texture, color);
  unbindRenderTarget(webgl);
}

export function resizeTexture(webgl: WebGLResources, texture: Texture, width: number, height: number, format = TextureFormat.RGBA) {
  const { gl } = webgl;
  if (width <= 0 || height <= 0) {
    throw new Error('Invalid texture size ${width}x${height}');
  }
  switchToFallbackRendererIfNeeded(webgl, width, height);

  if (texture.width !== width || texture.height !== height || texture.format !== format) {
    texture.width = width;
    texture.height = height;
    texture.format = format;
    const glFormat = toGLFormat(gl, format);
    gl.bindTexture(gl.TEXTURE_2D, texture.handle);
    gl.texImage2D(gl.TEXTURE_2D, 0, glFormat, width, height, 0, glFormat, gl.UNSIGNED_BYTE, null);
    gl.bindTexture(gl.TEXTURE_2D, null);
  }
}

export function setupAttribPointers(gl: WebGLRenderingContext) {
  gl.enableVertexAttribArray(0); // these are here to work around headless-gl issue
  gl.enableVertexAttribArray(1);
  gl.enableVertexAttribArray(2);
  gl.vertexAttribPointer(0, 2, gl.FLOAT, false, STRIDE, 0); // position
  gl.vertexAttribPointer(1, 4, gl.FLOAT, false, STRIDE, 2 * 4); // texcoord
  gl.vertexAttribPointer(2, 4, gl.FLOAT, false, STRIDE, 6 * 4); // color
}

export function setupMeshAttribPointers(gl: WebGLRenderingContext) {
  gl.enableVertexAttribArray(0); // these are here to work around headless-gl issue
  gl.enableVertexAttribArray(1);
  gl.enableVertexAttribArray(2);
  gl.vertexAttribPointer(0, 3, gl.FLOAT, false, MESH_STRIDE, 0); // position
  gl.vertexAttribPointer(1, 3, gl.FLOAT, true, MESH_STRIDE, 3 * 4); // normal
  gl.vertexAttribPointer(2, 2, gl.FLOAT, true, MESH_STRIDE, 6 * 4); // texcoord
}

export function drawMesh(gl: WebGLRenderingContext, mesh: Mesh) {
  gl.bindBuffer(gl.ARRAY_BUFFER, mesh.vertexBuffer);
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, mesh.indexBuffer);
  setupMeshAttribPointers(gl);
  gl.drawElements(gl.TRIANGLES, mesh.elements, gl.UNSIGNED_SHORT, 0);
  gl.bindBuffer(gl.ARRAY_BUFFER, null);
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
}

export function copyTextureRect(
  webgl: WebGLResources, src: Texture, dst: Texture, sx: number, sy: number, w: number, h: number, dx: number, dy: number
) {
  if (DEVELOPMENT && (
    sx < 0 || (sx + w) > src.width || sy < 0 || (sy + h) > src.height ||
    dx < 0 || (dx + w) > dst.width || dy < 0 || (dy + h) > dst.height)
  ) {
    throw new Error(`Invalid copy rect (${sx}, ${sy}, ${w}, ${h}) [${src.width}x${src.height}] -> (${dx}, ${dy}, ${w}, ${h}) [${dst.width}x${dst.height}]`);
  }

  const { gl } = webgl;
  bindRenderTarget(webgl, src);
  gl.bindTexture(gl.TEXTURE_2D, dst.handle);
  gl.copyTexSubImage2D(gl.TEXTURE_2D, 0, dx, dy, sx, sy, w, h);
  gl.bindTexture(gl.TEXTURE_2D, null);
  unbindRenderTarget(webgl);
}

export function textureToCanvas(webgl: WebGLResources, texture: Texture) {
  const { gl } = webgl;
  const canvas = createCanvas(texture.width, texture.height);
  const context = getContext2d(canvas);
  const data = context.createImageData(canvas.width, canvas.height);
  const buffer = new Uint8Array(data.data.buffer);
  bindRenderTarget(webgl, texture);
  gl.readPixels(0, 0, texture.width, texture.height, gl.RGBA, gl.UNSIGNED_BYTE, buffer);
  unbindRenderTarget(webgl);
  context.putImageData(data, 0, 0);
  return canvas;
}

export function debugTexture(webgl: WebGLResources, texture: Texture) {
  if (DEVELOPMENT && typeof document !== 'undefined') {
    const canvas = textureToCanvas(webgl, texture);
    canvas.style.position = 'fixed';
    canvas.style.left = '0';
    canvas.style.top = '0';
    canvas.style.zIndex = '10000';
    canvas.style.width = '50%';
    canvas.style.border = 'solid 1px red';
    document.body.appendChild(canvas);
  }
}

export function ensureDrawingTiles(webgl: WebGLResources, drawing: Drawing, rect?: Rect) {
  ensureTiles(webgl, drawing.tiles, rect ?? drawing, drawing.tileSize, drawing.tileMarginSize, drawing.lod);
}

export function ensureTiles(webgl: WebGLResources, tiles: Tiles, tilesRect: Rect, scaledTileSize: number, scaledTileMargin = 0, scale = 1) {
  const { x, y, w, h } = tilesRect;

  const tileSize = scaledTileSize * scale;
  const tileMargin = scaledTileMargin * scale;

  const tileStartX = Math.floor(x / tileSize);
  const tileEndX = Math.ceil((x + w) / tileSize);

  const tileStartY = Math.floor(y / tileSize);
  const tileEndY = Math.ceil((h + y) / tileSize);

  const newTiles = [];
  const { ox, oy } = tiles;
  for (let x = tileStartX; x < tileEndX; x++) {
    const row = [];
    for (let y = tileStartY; y < tileEndY; y++) {
      const oldTile = tiles.tiles[x - ox]?.[y - oy];
      const rect = createRect(x * tileSize, y * tileSize, tileSize, tileSize);
      intersectRect(rect, tilesRect);

      const textureRect = cloneRect(rect);
      outsetRect(textureRect, tileMargin);
      roundRectToGrid(textureRect, 16);

      // this should safe because it should not exceed calculated tile size and it is already in the device limtis
      const tw = findPowerOf2(textureRect.w / scale);
      const th = findPowerOf2(textureRect.h / scale);

      if (oldTile && rectsEqual(oldTile.rect, rect) && tw === oldTile.texture.width && th === oldTile.texture.height) {
        row.push(oldTile);
        tiles.tiles[x - ox][y - oy] = null;
      } else {
        const texture = getTexture(webgl, tw, th, `t${x}-${y}`, true);
        row.push({ rect, texture, textureRect });
      }
    }
    newTiles.push(row);
  }

  // release not reused tiles
  for (let x = 0; x < tiles.tiles.length; x++) {
    const row = tiles.tiles[x];
    for (let y = 0; y < row.length; y++) {
      const tile = row[y];
      if (tile) releaseTexture(webgl, tile.texture);
    }
  }

  tiles.tiles = newTiles;
  tiles.ox = tileStartX;
  tiles.oy = tileStartY;
}

export function iterateTiles(tiles: Tiles, rect: Rect, tileSize: number, iterator: (tile: Tile, x: number, y: number) => void) {
  const { x, y, w, h } = rect;

  const tileStartX = Math.floor(x / tileSize);
  const tileEndX = Math.ceil((x + w) / tileSize);

  const tileStartY = Math.floor(y / tileSize);
  const tileEndY = Math.ceil((h + y) / tileSize);

  for (let x = tileStartX; x < tileEndX; x++) {
    for (let y = tileStartY; y < tileEndY; y++) {
      const tile = tiles.tiles.at(x - tiles.ox)?.at(y - tiles.oy);
      if (tile) iterator(tile, x, y);
    }
  }
}

export function isTextureInLimits(maxSize: number, drawing: Rect, layers: LayerData[]) {
  if (maxSize) {
    return !(drawing.w > maxSize || drawing.h > maxSize || layers.find(l => l.rect && (l.rect.w > maxSize || l.rect.h > maxSize)));
  } else {
    return true;
  }
}

export function getAllLayersRect(rect: Rect, drawing: Rect, layers: Layer[]) {
  copyRect(rect, drawing);

  for (let i = 0; i < layers.length; i++) {
    addRect(rect, layers[i].rect);
    const owner = layers[i].owner;

    if (owner && !isSurfaceEmpty(owner.surface)) {
      addRect(rect, getTransformedSurfaceBounds(owner.surface.rect, owner.surface.transform));
    }
  }
}

export function releaseTiles(webgl: WebGLResources, tiles: Tiles) {
  for (let x = 0; x < tiles.tiles.length; x++) {
    const row = tiles.tiles[x];
    for (let y = 0; y < row.length; y++) {
      const tile = row[y];
      if (tile) releaseTexture(webgl, tile.texture);
    }
  }

  tiles.ox = 0;
  tiles.oy = 0;
  tiles.tiles = [];
}

export function switchToFallbackRenderer() {
  if (!SERVER) {
    storageSetItem('webgl', 'no');
    storageSetItem('renderer-change-reason', 'texture-size-limit-reached');
    window.location.reload();
  }
}

export function switchToFallbackRendererIfNeeded(webgl: WebGLResources, width: number, height: number) {
  if (width > webgl.params.maxTextureSize || height > webgl.params.maxTextureSize) {
    switchToFallbackRenderer();
    throw new Error(`Reached texture size limit ${width}x${height}, max size: ${webgl.params.maxTextureSize}`);
  }
}

export function texSubImage2DImage(gl: WebGLRenderingContext, texture: Texture | undefined, xoffset: GLint, yoffset: GLint, pixels: TexImageSource, enableLinearFiltering = false, premultiply = true) {
  if (!texture) return;
  gl.bindTexture(gl.TEXTURE_2D, texture.handle);
  gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, premultiply ? 1 : 0);
  gl.texSubImage2D(gl.TEXTURE_2D, 0, xoffset, yoffset, gl.RGBA, gl.UNSIGNED_BYTE, pixels);
  if (enableLinearFiltering) {
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
    gl.generateMipmap(gl.TEXTURE_2D);
    texture.hasMipmaps = true;
  } else {
    texture.hasMipmaps = false;
  }
  gl.bindTexture(gl.TEXTURE_2D, null);
}

export function texSubImage2D(gl: WebGLRenderingContext, texture: Texture | undefined, xoffset: GLint, yoffset: GLint, width: number, height: number, pixels: ArrayBufferView, enableLinearFiltering = false, premultiply = true) {
  if (!texture) return;
  gl.bindTexture(gl.TEXTURE_2D, texture.handle);
  gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, premultiply ? 1 : 0);
  gl.texSubImage2D(gl.TEXTURE_2D, 0, xoffset, yoffset, width, height, gl.RGBA, gl.UNSIGNED_BYTE, pixels);
  if (enableLinearFiltering) {
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
    gl.generateMipmap(gl.TEXTURE_2D);
    texture.hasMipmaps = true;
  } else {
    texture.hasMipmaps = false;
  }
  gl.bindTexture(gl.TEXTURE_2D, null);
}