import { Psd, Layer as PsdLayer, ImageResources, readPsd } from 'ag-psd';
import { colorFromRGBA, colorToCSS, getB, getG, getR, parseColor } from './color';
import { Drawing, Layer, IRenderer, DrawingDataFlags } from './interfaces';
import { createRect } from './rect';
import { getLayerName } from './layer';

function drawingLayerToPsdLayer(drawing: Drawing, l: Layer, renderer: IRenderer) {
  const layer: PsdLayer = {
    id: l.id,
    name: getLayerName(l),
    opacity: l.opacity,
    hidden: !l.visible,
    transparencyProtected: l.opacityLocked || l.locked,
    blendMode: l.mode,
    clipping: l.clippingGroup,
    protected: {
      transparency: l.opacityLocked,
      composite: false,
      position: false,
    },
  };

  const { rect, canvas } = renderer.getLayerCanvasForImageData(l);

  if (canvas) {
    layer.left = rect.x - drawing.x;
    layer.right = rect.x + rect.w - drawing.y;
    layer.top = rect.y - drawing.y;
    layer.bottom = rect.y + rect.h - drawing.y;
    layer.canvas = canvas;
  }

  return layer;
}

function reverseEndianU32(x: number) {
  x = x >>> 0;
  return (((x >> 24) & 0xff) | ((x >> 8) & 0xff00) | ((x << 8) & 0xff0000) | ((x << 24) & 0xff000000)) >>> 0;
}

export function drawingToPsd(drawing: Drawing, renderer: IRenderer): Psd {
  const { w, h } = drawing;
  const children = drawing.layers.map(l => drawingLayerToPsdLayer(drawing, l, renderer)).reverse();
  const imageResources: ImageResources = {};
  const imageData = renderer.getDrawingImageData(drawing, DrawingDataFlags.None);

  if (drawing.background) {
    const bg = parseColor(drawing.background);
    const imageData: ImageData = { colorSpace: 'srgb', width: w, height: h, data: new Uint8ClampedArray(w * h * 4) };
    (new Uint32Array(imageData.data.buffer)).fill(reverseEndianU32(bg));

    children.unshift({
      name: 'Background',
      left: 0,
      top: 0,
      right: w,
      bottom: h,
      transparencyProtected: true,
      imageData,
    });

    imageResources.backgroundColor = { r: getR(bg), g: getG(bg), b: getB(bg) };
  }

  imageResources.resolutionInfo = {
    widthUnit: 'Inches',
    horizontalResolution: drawing.dpi,
    horizontalResolutionUnit: 'PPI',
    heightUnit: 'Inches',
    verticalResolution: drawing.dpi,
    verticalResolutionUnit: 'PPI',
  };

  imageResources.thumbnail = renderer.getDrawingThumbnail(drawing, 160);

  return { width: w, height: h, children, imageResources, imageData };
}

export function processPsdFileForPasting(data: Uint8Array): PsdLayer[] {
  const psd = readPsd(data, { skipLinkedFilesData: true, skipThumbnail: true, useImageData: true });
  const psdLayers = flattenPsdLayers(psd).layers;
  return psdLayers;
}

export function flattenPsdLayers(psd: Psd): { layers: PsdLayer[], background?: string; } {
  const layers: PsdLayer[] = [];
  let background: string | undefined = undefined;

  if (!psd.children) {
    // use composite image as layer
    psd.children = [
      {
        name: 'Background',
        left: 0,
        top: 0,
        imageData: psd.imageData,
        right: psd.imageData?.width ?? 0,
        bottom: psd.imageData?.height ?? 0,
        transparencyProtected: true,
        protected: {
          transparency: true,
        }
      }
    ];
  }

  if (psd.children) {
    const bgLayer = psd.children[0];

    // check if background layer is single solid color and use background color instead
    if (
      bgLayer && !bgLayer.children && bgLayer.imageData && bgLayer.transparencyProtected &&
      bgLayer.imageData.width === psd.width && bgLayer.imageData.height === psd.height
    ) {
      if (bgLayer.imageData) {
        const color = getSingleColor(bgLayer.imageData);

        if (color !== undefined) {
          background = colorToCSS(color);
          psd.children.shift();
        }
      }
    }

    const processLayer = (psdLayer: PsdLayer) => {
      if (psdLayer.children) { // group
        for (let i = psdLayer.children.length - 1; i >= 0; i--) {
          processLayer(psdLayer.children[i]);
        }
      } else { // layer
        layers.push(psdLayer);
      }
    };

    processLayer(psd);
  }

  return { layers, background };
}

export function getSingleColor({ data }: ImageData) {
  const r = data[0], g = data[1], b = data[2], a = data[3];

  for (let i = 4; i < data.length; i += 4) {
    if (r !== data[i] || g !== data[i + 1] || b !== data[i + 2] || a !== data[i + 3] || a !== 0xff) {
      return undefined;
    }
  }

  return colorFromRGBA(r, g, b, 0xff);
}

export function parsePsdLayerImageData(psdLayer: PsdLayer) {
  if (psdLayer.imageData?.width && psdLayer.imageData?.height) {
    const x = psdLayer.left ?? 0;
    const y = psdLayer.top ?? 0;
    const w = psdLayer.imageData.width;
    const h = psdLayer.imageData.height;
    const rect = createRect(x, y, w, h);
    return { imageData: psdLayer.imageData, rect };
  } else {
    return undefined;
  }
}
