import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { capitalize } from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { includes, removeItem } from '../../../../common/baseUtils';
import { DARK_BACKGROUND, LIGHT_BACKGROUND, MB, USER_CURSOR_RADIUS } from '../../../../common/constants';
import { faAngleDown, faExternalLink, faPlus, faTimes, farExclamationTriangle, threeDotsIcon } from '../../../../common/icons';
import { keyboardLayout, KEYBOARD_LAYOUTS, setKeyboardLayout, shortcutFromEvent } from '../../../../common/input';
import { Analytics, Command, CursorsMode, ITool, Settings, SettingsModalTabs, ToolId, isBetaFeature } from '../../../../common/interfaces';
import { DEFAULT_HIDDEN_TOOLS_ORDER, DEFAULT_MAIN_TOOLS_ORDER, createDefaultKeyboardShortcuts, setHideCursor, setSettingsMinimumPressure, settingsHideCursor, settingsMinimumPressure } from '../../../../common/settings';
import { getShortcuts } from '../../../../common/settingsUtils';
import { toolIdToString } from '../../../../common/toolIdUtils';
import { createAllTools } from '../../../../common/tools';
import { isiOS, isMac, isWindows } from '../../../../common/userAgentUtils';
import { cloneDeep, getPixelRatio, isMobile, isWebGL } from '../../../../common/utils';
import { CommandService } from '../../../../services/commandService';
import type { Editor } from '../../../../services/editor';
import { canEnableHwAcceleration, getMemoryInUse } from '../../../../services/editorUtils';
import { FeatureFlagService } from '../../../../services/feature-flag.service.interface';
import { hasVoiceChat, Model } from '../../../../services/model';
import { createNamePlate, drawPointer } from '../../../../services/renderer';
import { failedWebGL } from '../../../../services/rendererFactory';
import { invalidateSettingsCache, resetSettings, saveSettings, setActiveSlot } from '../../../../services/settingsService';
import { storageGetBoolean, storageSetBoolean, storageSetItem } from '../../../../services/storage';
import { SettingsSelectGroup } from '../../settings-select/settings-select';
import { ITrackService } from '../../../../services/track.service.interface';

interface CommandInfo {
  id: string;
  name: string;
  shortcuts: string[];
}

interface CommandGroup {
  name: string;
  commands: CommandInfo[];
}

const nonbindableCommands = ['full-screen', 'own-layer', 'settings', 'kick-from-layer', 'remove-layer-owner', 'delete-drawing'];

const touchDragActionsTwoFingers: SettingsSelectGroup[] = [
  {
    name: '',
    items: [
      { value: '', label: 'None', description: 'ignore this gesture' },
      { value: 'normal', label: 'Normal', description: 'act in the same way as stylus or mouse' },
      { value: 'eraser', label: 'Eraser tool', description: 'use eraser tool when brush, pencil, paintbucket or shape tool is selected' },
      { value: 'pan', label: 'Pan view', description: 'act as hand tool and pan the view' },
      { value: 'pan-zoom', label: 'Pan / zoom view', description: 'act as hand tool and pan/zoom the view' },
      { value: 'params', label: 'Change tool parameters', description: 'change active tool parameters when dragging' },
    ],
  }
];

const touchDragActions: SettingsSelectGroup[] = [
  {
    name: '',
    items: touchDragActionsTwoFingers[0].items.filter(a => a.value != 'pan-zoom'),
  },
];

const touchDragActionsManyFingers: SettingsSelectGroup[] = [
  {
    name: '',
    items: touchDragActions[0].items.filter(a => a.value != 'normal' && a.value != 'eraser'),
  },
];

const touchTapActions: SettingsSelectGroup[] = [
  {
    name: '',
    items: [
      { value: '', label: 'None', description: 'ignore this gesture' },
      { value: 'undo', label: 'Undo', description: 'undo last action' },
      { value: 'redo', label: 'Redo', description: 'redo last undone action' },
      { value: 'flip-view', label: 'Flip view', description: 'flip view horizontally' },
      { value: 'eyedropper', label: 'Eyedropper', description: 'pick color from drawing' },
      { value: 'toggle-ui', label: 'Toggle UI', description: 'hide or show interface' },
    ],
  },
];

const touchTapActionsManyFingers = [
  {
    name: '',
    items: touchTapActions[0].items.filter(a => a.value !== 'eyedropper'),
  },
];

const touchDragParams: SettingsSelectGroup[] = [
  {
    name: '',
    items: [
      { value: '', label: 'None', description: 'ignore this axis' },
      { value: 'size', label: 'Size', description: 'change size of brush, pencil or eraser' },
      { value: 'opacity', label: 'Opacity', description: 'change tool opacity' },
      { value: 'flow', label: 'Density', description: 'change density of brush, pencil or eraser' },
      { value: 'hardness', label: 'Hardness', description: 'change brush or eraser hardness' },
    ],
  },
];

const mouseScrollActions: SettingsSelectGroup[] = [
  {
    name: '',
    items: [
      { value: '', label: 'None', description: '' },
      { value: 'zoom', label: 'Zoom view', description: '' },
      { value: 'pan', label: 'Pan view', description: '' },
    ],
  },
];

@Component({
  selector: 'settings-modal',
  templateUrl: 'settings-modal.pug',
  styleUrls: ['settings-modal.scss'],
})
export class SettingsModal implements OnInit, OnDestroy {
  readonly addIcon = faPlus;
  readonly removeIcon = faTimes;
  readonly cancelIcon = faTimes;
  readonly keyboardLayouts = KEYBOARD_LAYOUTS;
  readonly caretIcon = faAngleDown;
  readonly arrangeIcon = threeDotsIcon;
  readonly externalLinkIcon = faExternalLink;
  readonly warningIcon = farExclamationTriangle;
  @ViewChild('input', { static: true }) inputDiv!: ElementRef<HTMLDivElement>;
  @ViewChild('mainToolsElement', { static: false }) mainToolsElement!: ElementRef;
  @ViewChild('hiddenToolsElement', { static: false }) hiddenToolsElement!: ElementRef;
  @ViewChild('mainToolsList', { static: false }) mainToolsList!: ElementRef;
  @ViewChild('hiddenToolsList', { static: false }) hiddenToolsList!: ElementRef;
  @Output() close = new EventEmitter<void>();
  @Input() data = { startTab: 0 };
  @Input() onKeydown!: Subject<KeyboardEvent>;
  commands: CommandGroup[];
  enableWebGL = true;
  lightBackground = false;
  hideCursor = false;
  settings!: Settings;
  settingsCopy: Settings | undefined = undefined;
  error: string | undefined = undefined;
  resetMessage: string | undefined = undefined;
  tab = 0;
  ctrlKey = isMac ? 'cmd' : 'ctrl';
  touchDragActions = touchDragActions;
  touchDragActionsTwoFingers = touchDragActionsTwoFingers;
  touchDragActionsManyFingers = touchDragActionsManyFingers;
  touchTapActions = touchTapActions;
  touchTapActionsManyFingers = touchTapActionsManyFingers;
  touchLongPressActions = touchTapActions;
  touchLongPressActionsManyFingers = touchTapActionsManyFingers;
  touchDoubleTapActions = touchTapActions;
  touchDoubleTapActionsManyFingers = touchTapActionsManyFingers;
  touchDragParams = touchDragParams;
  mouseWheelActions = mouseScrollActions;
  isWindows = isWindows;
  mouseButtonActions: SettingsSelectGroup[] = [];
  tools: ITool[] = [];
  hiddenTools: ITool[] = [];
  dragOverTool: ToolId | undefined = undefined;
  dragOverHiddenTool: ToolId | undefined = undefined;
  isDragging = false;
  isDraggingHidden = false;
  currentToolList: number | undefined = undefined;
  private subscription?: Subscription;
  private oldMinimumPressure = 0;
  RENDERING_MORE_INFO_URL = 'https://magm.ai/cpu-rendering';
  constructor(
    @Inject('Editor') private editor: Editor,
    private model: Model,
    commandService: CommandService,
    private featureFlags: FeatureFlagService,
    private track: ITrackService,
  ) {
    const tools = createAllTools({} as any, {} as any);
    const groups: { name: string; commands: Command[]; }[] = [];

    for (const command of commandService.getAll()) {
      const group = groups.find(g => g.name === command.group) ?? { name: command.group, commands: [] };
      if (!group.commands.length) groups.push(group);
      group.commands.push(command);
    }

    this.commands = [
      ...groups.map(({ name, commands }) => ({
        name: capitalize(name),
        commands: commands
          .filter(c => {
            if (c.feature) {
              return this.featureFlags.isFeatureSupported(c.feature);
            }
            return !includes(nonbindableCommands, c.id);
          })
          .map(({ id, name }) => ({
            id,
            name,
            shortcuts: [...getShortcuts(model, id) || []],
          })),
      })),
      {
        name: 'Tools',
        commands: tools
          .filter(t => {
            if (t.feature) {
              return this.featureFlags.isFeatureSupported(t.feature);
            }
            return true;
          })
          .map(({ id, name }) => ({
            id: toolIdToString(id),
            name,
            shortcuts: [...getShortcuts(model, toolIdToString(id)) || []],
          })),
      },
    ];

    this.mouseButtonActions = [
      {
        name: '',
        items: [
          { value: '', label: 'None', description: '' },
        ],
      },
      {
        name: 'tools',
        items: tools.map(t => ({ value: toolIdToString(t.id), label: t.name, description: '' })),
      },
      {
        name: 'commands',
        items: commandService.getAll().map(c => ({ value: c.id, label: c.name, description: '' })),
      },
    ];

    this.init();

    this.tools = this.settings.mainToolsOrder.map(t => this.getTool(t)).filter((t): t is ITool => !!t);
    this.hiddenTools = this.settings.hiddenToolsOrder.map(t => this.getTool(t)).filter((t): t is ITool => !!t);
  }
  private init() {
    this.settings = cloneDeep(this.model.settings);
    this.enableWebGL = !!(this.editor.renderer && isWebGL(this.editor.renderer)); // renderer was undefined here
    this.lightBackground = this.settings.background !== '#222';
    this.hideCursor = settingsHideCursor;
    this.minimumPressure = this.minimumPressure;
  }
  async ngOnInit() {
    this.tab = this.data?.startTab | 0;
    this.subscription = this.onKeydown.subscribe(e => this.inputKey(e));
  }
  ngAfterViewInit() {
    if (IS_PORTAL) {
      this.renderPreview();
    }
  }
  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
  get hasVoiceChat() {
    return hasVoiceChat(this.model);
  }
  get voiceChatSettings() {
    return this.model.voiceChat.settings;
  }
  // TEMP: remove, any user needs to be able to unassign shortcuts from sequence actions
  get visibleCommands() {
    if (!IS_PORTAL) return this.commands.filter(g => g.name !== 'Sequence');
    return this.commands;
  }
  get canDisableWebGL() {
    return !isiOS;
  }
  get keyboardLayout() {
    const layout = this.keyboardLayouts.find(({ id }) => id === keyboardLayout) || this.keyboardLayouts[0];
    return layout.name;
  }
  get willReload() {
    return !!this.editor.renderer && (this.enableWebGL !== isWebGL(this.editor.renderer));
  }
  get errorMessage() {
    if (failedWebGL) {
      return `Graphics acceleration is not available, try updating your ${isMobile ? 'device' : 'graphics card drivers'}. (error: ${failedWebGL})`;
    } else {
      return undefined;
    }
  }
  get isAnonymous() {
    return this.model.user.anonymous;
  }
  get memoryInUse() {
    return getMemoryInUse(this.editor);
  }
  get disableTouchGestures() {
    return storageGetBoolean('disable-touch-gestures');
  }
  set disableTouchGestures(value) {
    storageSetBoolean('disable-touch-gestures', value);
  }
  get touchTap1Disabled() {
    if (this.settings.touchDrag[0] == 'normal' || this.settings.touchDrag[0] == 'eraser') {
      return `can't use with this drag gesture`;
    }
    return '';
  }
  get touchLongPress1Disabled() {
    const res = this.touchTap1Disabled;
    if (res) return res;
    return '';
  }
  get touchDoubleTap1Disabled() {
    const res = this.touchTap1Disabled;
    if (res) return res;
    if (this.settings.touchTap[0]) {
      return `can't use with this tap gesture`;
    }
    return '';
  }
  get minimumPressure() {
    return settingsMinimumPressure;
  }
  set minimumPressure(value) {
    setSettingsMinimumPressure(value);
  }
  toMB(value: number) {
    return (value / MB).toFixed(0);
  }
  cancel() {
    setKeyboardLayout(this.model.settings.keyboardLayout);
    this.minimumPressure = this.oldMinimumPressure;
    this.close.emit();
  }
  submit() {
    for (const g of this.commands) {
      if (this.hasDuplicates(g)) {
        this.error = 'Cannot save with duplicate shortcuts';
        return;
      }
    }

    this.updateToolsOrder(this.tools, this.hiddenTools);

    this.settings.background = this.lightBackground ? LIGHT_BACKGROUND : DARK_BACKGROUND;

    setHideCursor(this.hideCursor);

    for (const g of this.commands) {
      for (const c of g.commands) {
        this.settings.shortcuts[c.id] = c.shortcuts;
      }
    }

    const activeSlot = this.model.settings.slots.indexOf(this.model.activeSlot!);
    Object.assign(this.model.settings, this.settings);
    setActiveSlot(this.model, this.model.settings.slots[activeSlot] || this.model.settings.slots[0]);
    saveSettings(this.model);
    invalidateSettingsCache(this.model);
    this.close.emit();

    if (this.enableWebGL !== isWebGL(this.editor.renderer)) {
      storageSetItem('webgl', this.enableWebGL ? 'yes' : 'no');
      storageSetItem('renderer-change-reason', 'manual');
      setTimeout(() => location.reload());
    }

    if (this.hasVoiceChat) {
      this.model.voiceChat.applySettings();
    }
  }
  resetAllSettings() {
    this.settingsCopy = this.settingsCopy ?? cloneDeep(this.settings);
    resetSettings(this.model);
    this.resetToolsOrder();
    invalidateSettingsCache(this.model);
    this.init();
    this.resetMessage = 'All settings have been reset to defaults';
  }
  undoReset() {
    if (this.settingsCopy) {
      const settings = this.model.settings;
      const activeSlot = settings.slots.indexOf(this.model.activeSlot!);
      Object.assign(settings, this.settingsCopy);
      setActiveSlot(this.model, settings.slots[activeSlot] || settings.slots[0]);
      saveSettings(this.model);
      this.settingsCopy = undefined;
      invalidateSettingsCache(this.model);
      setKeyboardLayout(this.settings.keyboardLayout);
      this.init();
      this.resetMessage = 'All settings have been restored to state before the reset';
    }
  }
  resetShortcuts() {
    const defaultShortcuts = createDefaultKeyboardShortcuts();

    for (const g of this.commands) {
      for (const c of g.commands) {
        c.shortcuts = defaultShortcuts[c.id] || [];
      }
    }
  }
  resetToolsOrder() {
    this.settings.mainToolsOrder = DEFAULT_MAIN_TOOLS_ORDER;
    this.settings.hiddenToolsOrder = DEFAULT_HIDDEN_TOOLS_ORDER;
    this.tools = DEFAULT_MAIN_TOOLS_ORDER.map(t => this.getTool(t)).filter((t): t is ITool => !!t);
    this.hiddenTools = DEFAULT_HIDDEN_TOOLS_ORDER.map(t => this.getTool(t)).filter((t): t is ITool => !!t);
  }
  editingShortcuts: string[] | undefined = undefined;
  startKeyInput(shortcuts: string[]) {
    this.editingShortcuts = this.editingShortcuts === shortcuts ? undefined : shortcuts;
  }
  endKeyInput(shortcuts: string[]) {
    if (this.editingShortcuts === shortcuts) {
      this.editingShortcuts = undefined;
    }
  }
  inputKey(e: KeyboardEvent) {
    if (this.editingShortcuts) {
      e.preventDefault();

      this.error = undefined;

      const shortcut = shortcutFromEvent(e);

      if (shortcut) {
        if (!includes(this.editingShortcuts, shortcut)) {
          this.editingShortcuts.push(shortcut);
        }
        this.editingShortcuts = undefined;
      }
    }
  }
  removeShortcut(shortcut: string, shortcuts: string[]) {
    this.editingShortcuts = undefined;
    removeItem(shortcuts, shortcut);
  }
  isDuplicate(shortcut: string, shortcuts: string[]) {
    for (const g of this.commands) {
      for (const c of g.commands) {
        if (c.shortcuts === shortcuts) continue;

        if (includes(c.shortcuts, shortcut)) return true;
      }
    }

    return false;
  }
  isAnyDuplicate(shortcuts: string[]) {
    for (const g of this.commands) {
      for (const c of g.commands) {
        if (c.shortcuts === shortcuts) continue;

        for (const s of c.shortcuts) {
          if (includes(shortcuts, s)) return true;
        }
      }
    }

    return false;
  }
  hasDuplicates(group: CommandGroup) {
    for (const c of group.commands) {
      if (this.isAnyDuplicate(c.shortcuts)) return true;
    }
    return false;
  }
  hideShortcut(shortcut: string) {
    return !isMac && /cmd\+/.test(shortcut);
  }
  tabChanged() {
    this.error = undefined;
    this.resetMessage = undefined;
    setTimeout(() => this.renderPreview());
  }
  isShortcutsTab() {
    return this.tab === SettingsModalTabs.Shortcuts;
  }
  isToolsTab() {
    return this.tab === SettingsModalTabs.Toolbar;
  }
  setKeyboardLayout(id?: string) {
    this.settings.keyboardLayout = id;
    setKeyboardLayout(id);
  }
  renderPreview(mode = this.settings.cursors ?? CursorsMode.None) {
    const canvas = document.getElementById('preview') as HTMLCanvasElement | null;
    if (!canvas) return;
    const context = canvas.getContext('2d');
    if (!context) return;

    const pixelRatio = getPixelRatio();

    canvas.width = Math.ceil(300 * pixelRatio);
    canvas.height = Math.ceil(80 * pixelRatio);
    canvas.style.width = canvas.width / pixelRatio + 'px';
    canvas.style.height = canvas.height / pixelRatio + 'px';

    // cursor center on canvas
    const x = 30 * pixelRatio;
    const y = 25 * pixelRatio;

    if (mode !== CursorsMode.None) {
      context.lineWidth = 1.5 * pixelRatio;
      drawPointer(context, x, y, this.model.user.color, this.model.user.cursorAlpha);
    }

    if (mode !== CursorsMode.None && mode !== CursorsMode.Pointer) {
      const offset = USER_CURSOR_RADIUS - 4;
      const xBadge = Math.round(x + offset * pixelRatio);
      const yBadge = Math.round(y + offset * pixelRatio);
      const namePlate = createNamePlate(this.model.user, pixelRatio, mode, false);
      context.drawImage(namePlate.canvas, xBadge, yBadge);
    }
  }

  getTool(toolId: ToolId) {
    const tool = this.editor.toolsMap.get(toolId);
    const isToolAllowed = tool
      && (!tool.feature || this.featureFlags.isFeatureSupported(tool.feature))
      && (!tool.onlyPortal || IS_PORTAL || DEVELOPMENT)
      && !(this.isDragging && tool.id === this.dragOverTool);

    return isToolAllowed && tool;
  }

  private moveTool(toolId: ToolId, targetArray: ITool[], sourceArray: ITool[], indexPosition: number | undefined): void {
    const index = sourceArray.findIndex((t) => t.id === toolId);
    if (index !== -1) {
      const tool = sourceArray[index];
      sourceArray.splice(index, 1);
      indexPosition = indexPosition === undefined ? targetArray.length : indexPosition;
      targetArray.splice(indexPosition, 0, tool);
    }
  }

  droppedOn(hiddenTools = false, index: number | undefined = undefined): void {
    // Fix for mobile not detecting the right tool and list
    if ((this.currentToolList === 2) !== hiddenTools) {
      hiddenTools = !hiddenTools;
      const toolsArray = hiddenTools ? this.hiddenTools : this.tools;
      index = toolsArray.findIndex(t => t.id === (hiddenTools ? this.dragOverHiddenTool : this.dragOverTool));
      index = index === -1 ? undefined : index;
    }
    if (hiddenTools && this.dragOverTool) {
      this.moveTool(this.dragOverTool, this.hiddenTools, this.tools, index);
    }
    if (!hiddenTools && this.dragOverHiddenTool) {
      this.moveTool(this.dragOverHiddenTool, this.tools, this.hiddenTools, index);
    }
    this.dragOverTool = undefined;
    this.dragOverHiddenTool = undefined;
  }

  changeDragOverTool(toolId?: ToolId): void {
    this.dragOverTool = toolId;
  }

  changeDragOverHiddenTool(toolId?: ToolId): void {
    this.dragOverHiddenTool = toolId;
  }

  enterToolList(hiddenToolList = false): void {
    this.currentToolList = hiddenToolList ? 2 : 1;
  }

  leaveToolList(): void {
    this.currentToolList = undefined;
  }

  touchMove(event: TouchEvent, mainToolsTouch = true) {
    // workaround: for pointer/mouse enter/leave not triggering for mobile
    this.currentToolList = this.isInsideElement(event, this.mainToolsElement) ? 1 :
      (this.isInsideElement(event, this.hiddenToolsElement) && mainToolsTouch) ? 2 :
        undefined;

    const elementArray = mainToolsTouch ? this.hiddenToolsList.nativeElement : this.mainToolsList.nativeElement;

    if (this.currentToolList === 1 && !mainToolsTouch) {
      this.dragOverTool = this.findDragOverItemId(event, elementArray, this.tools);
    }

    if (this.currentToolList === 2 && mainToolsTouch) {
      this.dragOverHiddenTool = this.findDragOverItemId(event, elementArray, this.hiddenTools);
    }
  }

  private findDragOverItemId(event: TouchEvent, elementArray: HTMLElement, toolArray: ITool[]) {
    const children = elementArray.querySelectorAll('.tool-item');
    for (let index = 0; index < children.length; index++) {
      const element = children[index] as HTMLElement;
      if (this.isInsideElement(event, element)) {
        return toolArray[index]?.id;
      }
    }
    return undefined;
  }

  private isInsideElement(event: TouchEvent, element: any) {
    const nativeElement = element.nativeElement || element;

    if (nativeElement && 'getBoundingClientRect' in nativeElement) {
      const touchX = event.touches[0].clientX;
      const touchY = event.touches[0].clientY;

      const rect = nativeElement.getBoundingClientRect();
      if (
        touchX >= rect.left &&
        touchX <= rect.right &&
        touchY >= rect.top &&
        touchY <= rect.bottom
      ) {
        return true;
      }
    }
    return false;
  }

  updateToolsOrder(mainTools: ITool[], hiddenTools: ITool[]): void {
    const newToolsOrder = mainTools.map(t => t.id);
    const newHiddenToolsOrder = hiddenTools.map(t => t.id);
    const isOrderChanged = JSON.stringify(newToolsOrder) !== JSON.stringify(this.settings.mainToolsOrder)
      || JSON.stringify(newHiddenToolsOrder) !== JSON.stringify(this.settings.hiddenToolsOrder);

    if (isOrderChanged) {
      this.settings.mainToolsOrder = newToolsOrder;
      this.settings.hiddenToolsOrder = newHiddenToolsOrder;

      this.track.event(Analytics.customizeToolbar, {
        userId: this.model.user.accountId,
        mainTools: mainTools.map(t => t.name).join(', '),
        hiddenTools: hiddenTools.map(t => t.name).join(', '),
      });
    }
  }
  isBeta(tool: ITool) {
    return tool.feature && isBetaFeature(tool.feature);
  }
  canEnableHwAcceleration() {
    return canEnableHwAcceleration(this.editor);
  }
  isUsingWebGL() {
    return isWebGL(this.editor.renderer);
  }
}
