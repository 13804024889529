import { AsyncTimingEntry, BitmapData, CancellablePromise, Drawing, LAYER_MODES_ALL, Layer, LayerData, LayerFlag, LayerLoadingResult, LayerMode, LayerUpdateData, PerspectiveGridLayer, TextLayer } from './interfaces';
import { LAYER_NAME_LENGTH_LIMIT } from './constants';
import { deferred } from './promiseUtils';
import { includes } from './baseUtils';
import { cloneRect, copyRect, createRect, isRectEmpty } from './rect';
import { colorToHexRGB, randomHueColor } from './color';
import { createCanvas, getContext2d, ImageLoader, LoadOptions, totalDecodingTime } from './canvasUtils';
import { apiPath } from './data';
import * as validate from './validate';
import { asyncLoadingEnd, asyncLoadingStart, asyncLoadingTrack, loadingEnd, loadingFailure, loadingStart } from './loading';
import { truncate } from 'lodash';
import { logAction } from './actionLog';
import { hasFontsLoaded, requireFontsCheck } from './text/fonts';
import { cloneTextData, validateTextData } from './text/text-utils';
import { safeBoolean, safeFloat, safeString } from './toolUtils';
import { clonePerspectiveGridData } from './tools/perspectiveGridTool';

const DEFAULT_NAME = '';
const DEFAULT_MODE = 'normal';
const DEFAULT_OPACITY = 1;
const DEFAULT_VISIBLE = true;
const DEFAULT_OPACITY_LOCKED = false;
const DEFAULT_LOCKED = false;

export function createLayer(id: number, name = DEFAULT_NAME): Layer {
  return {
    id,
    name,
    mode: DEFAULT_MODE,
    opacity: DEFAULT_OPACITY,
    opacityLocked: DEFAULT_OPACITY_LOCKED,
    visible: DEFAULT_VISIBLE,
    visibleLocally: undefined,
    locked: DEFAULT_LOCKED,
    clippingGroup: false,
    changed: false,
    rect: createRect(0, 0, 0, 0),
    thumb: undefined,
    thumbDirty: 1,
    image: undefined,
    owner: undefined,
    layerOwner: undefined,
    canvas: undefined,
    textureX: 0,
    textureY: 0,
    texture: undefined,
    // worker
    loaded: false,
    lastUsed: undefined,
    unloadedImage: undefined,
    unloadingNumber: 0,
    flags: LayerFlag.None
  };
}

export function isTextLayer(layer: Layer | LayerData | undefined): layer is TextLayer {
  return !!layer && !!layer.textData;
}

export function isPerspectiveGridLayer(layer: Layer | LayerData | undefined): layer is PerspectiveGridLayer {
  return !!layer && !!layer.perspectiveGrid;
}

export function layerFromState(data: LayerData, fromUser = false) {
  const layer = createLayer(data.id, data.name);
  setLayerState(layer, data, fromUser);
  return layer;
}

export function toLayerState(layer: Layer): LayerData {
  const state: LayerData = {
    id: layer.id,
    name: layer.name,
    mode: layer.mode,
    visible: layer.visible,
    locked: layer.locked,
    opacity: layer.opacity,
    opacityLocked: layer.opacityLocked,
    clippingGroup: layer.clippingGroup,
    image: layer.image,
    rect: cloneRect(layer.rect),
    flags: layer.flags,
  };
  if (layer.textData) state.textData = cloneTextData(layer.textData);
  if (layer.perspectiveGrid) state.perspectiveGrid = clonePerspectiveGridData(layer.perspectiveGrid);
  return state;
}

// prepares layer state for saving in database, strips all unnecessary fields
export function packLayerState(layer: LayerData): LayerData {
  const { id, name, mode, visible, locked, opacity, opacityLocked, clippingGroup, image, rect } = layer;
  const data: LayerData = { id };
  if (name !== DEFAULT_NAME) data.name = name;
  if (mode !== DEFAULT_MODE) data.mode = mode;
  if (visible !== DEFAULT_VISIBLE) data.visible = visible;
  if (locked !== DEFAULT_LOCKED) data.locked = locked;
  if (opacity !== DEFAULT_OPACITY) data.opacity = opacity;
  if (opacityLocked !== DEFAULT_OPACITY_LOCKED) data.opacityLocked = opacityLocked;
  if (clippingGroup !== false) data.clippingGroup = clippingGroup;
  if (image) data.image = image;
  if (rect && !isRectEmpty(rect)) data.rect = cloneRect(rect);
  if (layer.flags) data.flags = layer.flags;
  if (layer.textData) data.textData = layer.textData;
  if (layer.perspectiveGrid) data.perspectiveGrid = layer.perspectiveGrid;
  return data;
}

// TODO: deprecated, remove this function
export function setLayerState(layer: Layer, data: LayerData, fromUser = false) {
  layer.name = data.name ?? layer.name;
  layer.mode = (data.mode && includes(LAYER_MODES_ALL, data.mode)) ? data.mode as LayerMode : layer.mode;
  layer.visible = !!(data.visible ?? layer.visible);
  layer.locked = !!(data.locked ?? layer.locked);
  layer.opacity = data.opacity ?? layer.opacity;
  layer.opacityLocked = !!(data.opacityLocked ?? layer.opacityLocked);
  layer.clippingGroup = !!(data.clippingGroup ?? layer.clippingGroup);

  if (data.textData) {
    layer.textData = cloneTextData(data.textData);
    layer.invalidateCanvas = true;
    layer.textarea = undefined;
    layer.fontsLoaded = hasFontsLoaded(layer as TextLayer);
    if (!layer.fontsLoaded) requireFontsCheck();
  } else {
    layer.textarea = undefined;
    layer.fontsLoaded = undefined;
    layer.invalidateCanvas = undefined;
    layer.textData = undefined;
  }
  if (data.perspectiveGrid) {
    layer.perspectiveGrid = clonePerspectiveGridData(data.perspectiveGrid);
  } else {
    layer.perspectiveGrid = undefined;
  }

  if (!fromUser) { // don't allow users to change these fields via layer add/update actions
    layer.image = data.image ?? layer.image; // TODO: check how clearing image works here
    if ('flags' in data) layer.flags = data.flags ?? LayerFlag.None;
    if (data.rect) copyRect(layer.rect, data.rect); // TODO: this might break small textures, maybe remove it ?
  }
}

// update layer using unsafe data, make sure we ignore any extra fields and fix invalid values
export function updateLayerState(layer: Layer, data: LayerUpdateData) {
  if ('visible' in data) layer.visible = safeBoolean(data.visible);
  if ('locked' in data) layer.locked = safeBoolean(data.locked);
  if ('clippingGroup' in data) layer.clippingGroup = safeBoolean(data.clippingGroup);
  if ('mode' in data && LAYER_MODES_ALL.includes(data.mode!)) layer.mode = data.mode as LayerMode;
  if ('name' in data) layer.name = safeString(data.name, LAYER_NAME_LENGTH_LIMIT);
  if ('opacity' in data) layer.opacity = safeFloat(data.opacity, 0, 1);
  if ('opacityLocked' in data) layer.opacityLocked = safeBoolean(data.opacityLocked);
}

export function validateLayerData(data: LayerData) {
  validate.object(data);
  validate.integerRange(data.id, 0, 0xffff);
  validate.stringOrUndefined(data.name, LAYER_NAME_LENGTH_LIMIT);
  validate.stringEnumOrUndefined(data.mode, LAYER_MODES_ALL);
  validate.booleanOrUndefined(data.visible);
  validate.booleanOrUndefined(data.locked);
  validate.numberRangeOrUndefined(data.opacity, 0, 1, true);
  validate.booleanOrUndefined(data.opacityLocked);
  validate.booleanOrUndefined(data.clippingGroup);
  validate.rectOrUndefined(data.rect);
  if (data.textData) validateTextData(data.textData);
}

export function layerHasImageData(layer: Layer) {
  return layer.canvas !== undefined || layer.texture !== undefined;
}

export function redrawLayerThumb(layer: Layer, force = false) {
  layer.thumbDirty = (layer.thumbDirty === 1 || force) ? 1 : Date.now();
}

export function shouldRedrawLayerThumb(layer: Layer) {
  return !!layer.thumb && !!layer.thumbDirty && (Date.now() - layer.thumbDirty) > 1000;
}

export function layerChanged(layer: Layer, forceRedraw?: boolean) {
  layer.changed = true;
  redrawLayerThumb(layer, forceRedraw);
}

export function isLayerVisible(layer: Layer): boolean {
  return layer.visibleLocally != null ? layer.visibleLocally : layer.visible;
}

export function isLayerActive(layer: Layer): boolean {
  return layer.owner !== undefined && layer === layer.owner.activeLayer;
}

export function getLayerDebugInfo(layer: Layer) {
  const { id, rect, texture, canvas, textureX, textureY } = layer;
  const data = canvas ?? texture;
  const area = data ? data.width * data.height : 0;
  const layerArea = rect.w * rect.h;
  const usage = area ? (layerArea * 100 / area).toFixed() : '-';
  return `#${id} ${usage}% ${(data ? `[${data.width} x ${data.height}]` : '[none]')}\n` +
    (isRectEmpty(rect) ? '(none)' : `(${rect.x} ${rect.y} ${rect.w} ${rect.h}) ${textureX} ${textureY}`);
}

export function layerImagePath(drawingId: string, image: string, query = '') {
  return `${apiPath}layer/${drawingId}/${image}${query}`;
}

let reportInvalidImage: ((img: string) => void) | undefined = undefined;

export function setupReportInvalidImage(func: (img: string) => void) {
  reportInvalidImage = func;
}

function loadLayerImage<T>(layer: Layer, drawing: Drawing, query: string, loaders: ImageLoader<T>[]) {
  const url = layerImagePath(drawing.id, layer.image!, query);
  const { w, h } = layer.rect;

  let cancelled = false;
  let currentPromise: CancellablePromise<T>;
  let loaderIndex = 0;

  let entry: AsyncTimingEntry;
  const color = colorToHexRGB(randomHueColor());
  const track = asyncLoadingTrack();
  const options: LoadOptions = { onBlock(block) { asyncLoadingEnd(entry); entry = asyncLoadingStart(track, block, color); } };
  const { promise, resolve, reject } = deferred<T>();
  const cancellable = promise as CancellablePromise<T>;
  cancellable.cancel = () => {
    cancelled = true;
    currentPromise.cancel();
    reject(new Error('Cancelled'));
  };

  const result = { promise: cancellable, layer, color, loadedWith: '?' };

  function tryToLoad(lastError?: Error) {
    if (loaderIndex >= loaders.length || (TESTS && loaderIndex)) {
      reject(lastError ?? new Error('test error'));
    } else {
      entry = asyncLoadingStart(track, `load (${layer.image}) [${loaders[loaderIndex].name}]`, color);
      currentPromise = loaders[loaderIndex].load(url, options);
      currentPromise
        .then(image => {
          const img = image as any as ImageBitmap | HTMLImageElement | BitmapData;
          if (loaderIndex !== loaders.length - 1 && (img.width !== w || img.height !== h)) {
            const message = `Invalid image size (expected: ${w}x${h}, actual: ${img.width}x${img.height}, url: ${url})`;
            logAction(message);
            try {
              if (reportInvalidImage && !('data' in img)) {
                const canvas = createCanvas(img.width, img.height);
                const context = getContext2d(canvas)!;
                context.drawImage(img, 0, 0);
                const encoded = canvas.toDataURL('image/png');
                reportInvalidImage(encoded);
              }
            } catch { }
            throw new Error(message);
          }
          return image;
        })
        .then(image => {
          if (!cancelled) {
            asyncLoadingEnd(entry);
            result.loadedWith = loaders[loaderIndex].name;
            resolve(image);
          }
        }, error => {
          if (!cancelled) {
            logAction(`Failed to load: ${layer.image} (${loaders[loaderIndex].name})`);
            entry.c = 'ff0000';
            entry.n += ' [failed]';
            asyncLoadingEnd(entry);
            loadingFailure();
            loaderIndex++;
            tryToLoad(error);
          }
        });
    }
  }

  tryToLoad();

  return result;
}

export function loadLayerImages<T>(
  drawing: Drawing,
  loaders: ImageLoader<T>[],
  onLoaded: (layer: Layer, image: T) => void,
  onProgress?: (progress: number) => void,
  ignoreErrors = false,
): CancellablePromise<LayerLoadingResult> {
  const layersToLoad = drawing.layers.filter(l => !!l.image);
  const query = ignoreErrors ? '?ignoreErrors=true' : '';
  const { promise, resolve, reject } = deferred<LayerLoadingResult>();
  const cancellable = promise as CancellablePromise<LayerLoadingResult>;

  // TODO: limit number of concurrent loads ?

  const promises = layersToLoad.map(layer => loadLayerImage(layer, drawing, query, loaders));
  const loadersUsed: { [key: string]: number; } = {};
  let loaded = 0;
  let cancelled = false;

  cancellable.cancel = () => {
    cancelled = true;
    promises.forEach(p => p.promise.cancel());
    reject(new Error('Cancelled loading layers'));
  };

  Promise.all(promises.map(state => state.promise.then(img => {
    if (!cancelled) {
      loadingStart(`initLayer`, state.color);
      onLoaded(state.layer, img);
      loadingEnd();
      loaded++;
      loadersUsed[state.loadedWith] = (loadersUsed[state.loadedWith] ?? 0) + 1;
      onProgress?.(loaded / layersToLoad.length);
    }
  }))).then(() => {
    if (!cancelled) resolve({ loadersUsed });

    if (DEVELOPMENT && !TESTS && false) {
      console.log(`decoded PNGs in ${totalDecodingTime.toFixed(2)} ms`);
    }
  }).catch(e => {
    if (!cancelled) reject(e);
  });

  return cancellable;
}

export function getLayerName(layer: Layer) {
  if (isTextLayer(layer)) {
    return layer.name ? layer.name : truncate(layer.textData.text, { length: LAYER_NAME_LENGTH_LIMIT });
  }
  return layer.name;
}
