import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { removeItem } from '../../../common/baseUtils';
import { calculateCurvesWeights } from '../../../common/curves';
import { Curve, CurveChannels, Point } from '../../../common/interfaces';
import { clamp } from '../../../common/mathUtils';
import { times } from '../../../common/utils';
import { AgDragEvent } from '../directives/agDrag';

interface Path {
  color: string;
  d: string;
}

const WIDTH = 290;
const HEIGHT = 128;

@Component({
  selector: 'curves-graph',
  templateUrl: 'curves-graph.pug',
  styleUrls: ['curves-graph.scss'],
})
export class CurvesGraph implements OnChanges {
  readonly colors = ['#E8E8E8', '#DD3333', '#00AA44', '#2E6DE1'];
  @Input() curves: Curve[] = [];
  @Output() curveValues = new EventEmitter<Uint8Array[]>();
  @Output() selectedCurve = new EventEmitter<CurveChannels>();
  activeCurve = 0;
  paths: Path[] = [];
  private draggedPoint: Point = { x: 0, y: 0 };
  private prevPoint: Point | undefined = undefined;
  private nextPoint: Point | undefined = undefined;
  private hiddenPoint: Point | undefined = undefined;
  ngOnChanges() {
    this.regeneratePaths();
  }
  drag(point: Point | undefined, event: AgDragEvent) {
    let x = clamp(event.x / WIDTH, 0, 1);
    let y = clamp(event.y / HEIGHT, 0, 1);
    const points = this.curves[this.activeCurve].points;

    if (event.type === 'start') {
      if (!point) {
        points.push(point = { x, y });
        points.sort((a, b) => a.x - b.x);
      }

      this.draggedPoint = point;
      this.prevPoint = points[points.indexOf(point) - 1];
      this.nextPoint = points[points.indexOf(point) + 1];
    }

    if (this.prevPoint && this.nextPoint) {
      this.hiddenPoint = (x <= this.prevPoint.x || x >= this.nextPoint.x) ? this.draggedPoint : undefined;
    } else {
      x = clamp(x, this.prevPoint ? this.prevPoint.x + 0.01 : 0, this.nextPoint ? this.nextPoint.x - 0.01 : 1);
    }

    this.draggedPoint.x = x;
    this.draggedPoint.y = y;

    if (event.type === 'end') {
      if (this.draggedPoint === this.hiddenPoint) {
        removeItem(points, this.draggedPoint);
      }
    }

    this.regeneratePaths();
  }
  remove(point: Point) {
    const points = this.curves[this.activeCurve].points;

    if (points.indexOf(point) !== 0 && points.indexOf(point) !== (points.length - 1)) {
      removeItem(points, point);
      this.regeneratePaths();
    }
  }
  isHidden(point: Point) {
    return point === this.hiddenPoint;
  }
  reset() {
    for (const curve of this.curves) {
      curve.points = [{ x: 0, y: 1 }, { x: 1, y: 0 }];
    }
    this.regeneratePaths();
  }
  setActiveCurve(value: number) {
    this.activeCurve = value;
    this.selectedCurve.emit(value);
    this.regeneratePaths();
  }
  private regeneratePaths() {
    this.paths = [];

    for (let i = 0; i < this.curves.length; i++) {
      const { points: pt } = this.curves[i];
      const color = this.colors[i];

      // hide if line has default values 
      if (i !== this.activeCurve && pt.length === 2 && pt[0].x === 0 && pt[0].y === 1 && pt[1].x === 1 && pt[1].y === 0) {
        continue;
      }

      let d = `M ${toX(0)} ${toY(pt[0].y)}`;
      d += ` L ${toX(pt[0].x)} ${toY(pt[0].y)}`;
      d += drawCurve(pt.filter(p => p !== this.hiddenPoint));
      d += ` L ${toX(pt[pt.length - 1].x)} ${toY(pt[pt.length - 1].y)}`;
      d += ` L ${toX(1)} ${toY(pt[pt.length - 1].y)}`;

      this.paths.push({ color, d });
    }
    this.curveValues.emit([
      calculateCurvesWeights(this.curves[CurveChannels.RGB].points),
      calculateCurvesWeights(this.curves[CurveChannels.RED].points),
      calculateCurvesWeights(this.curves[CurveChannels.GREEN].points),
      calculateCurvesWeights(this.curves[CurveChannels.BLUE].points),
    ]);
  }
}

function toX(value: number) {
  return value * WIDTH;
}

function toY(value: number) {
  return value * HEIGHT;
}

function secondDerivative(P: Point[]) {
  const n = P.length;

  // build the tridiagonal system (assume 0 boundary conditions: y2[0]=y2[-1]=0) 
  const matrix = times(n, () => new Float32Array(3));
  const result = new Float32Array(n);
  matrix[0][1] = 1;

  for (let i = 1; i < n - 1; i++) {
    matrix[i][0] = (P[i].x - P[i - 1].x) / 6;
    matrix[i][1] = (P[i + 1].x - P[i - 1].x) / 3;
    matrix[i][2] = (P[i + 1].x - P[i].x) / 6;
    result[i] = (P[i + 1].y - P[i].y) / (P[i + 1].x - P[i].x) - (P[i].y - P[i - 1].y) / (P[i].x - P[i - 1].x);
  }

  matrix[n - 1][1] = 1;

  // solving pass1 (up->down)
  for (let i = 1; i < n; i++) {
    const k = matrix[i][0] / matrix[i - 1][1];
    matrix[i][1] -= k * matrix[i - 1][2];
    matrix[i][0] = 0;
    result[i] -= k * result[i - 1];
  }

  // solving pass2 (down->up)
  for (let i = n - 2; i >= 0; i--) {
    const k = matrix[i][2] / matrix[i + 1][1];
    matrix[i][1] -= k * matrix[i + 1][0];
    matrix[i][2] = 0;
    result[i] -= k * result[i + 1];
  }

  // return second derivative value for each point P
  const y2 = new Float32Array(n);
  for (let i = 0; i < n; i++) y2[i] = result[i] / matrix[i][1];
  return y2;
}

function calcY(points: Point[], i: number, x: number, sd: Float32Array) {
  const cur = points[i];
  const next = points[i + 1];
  const t = (x - cur.x) / (next.x - cur.x);
  const a = 1 - t;
  const b = t;
  const h = next.x - cur.x;
  const yy = a * cur.y + b * next.y + (h * h / 6) * ((a * a * a - a) * sd[i] + (b * b * b - b) * sd[i + 1]);
  const y = clamp(yy, 0, 1);
  return y;
}

function drawCurve(points: Point[]) {
  let result = '';
  const sd = secondDerivative(points);
  const step = 1 / 100;

  for (let i = 0; i < points.length - 1; i++) {
    const cur = points[i];
    const next = points[i + 1];

    for (let x = cur.x; x < next.x; x += step) {
      const y = calcY(points, i, x, sd);
      result += ` L ${toX(x)} ${toY(y)}`;
    }
  }

  return result;
}
