import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { animate, style, transition, trigger, AnimationBuilder, AnimationPlayer, AnimationMetadata } from '@angular/animations';
import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { faTimes } from 'magma/common/icons';
import { IMsg } from 'magma/common/interfaces';
import { ToastService } from 'magma/services/toast.service';
import { TrackService } from '../../../services/track.service';

@UntilDestroy()
@Component({
  selector: 'toast',
  templateUrl: './toast.component.pug',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-200%)' }),
        animate('300ms ease-in', style({
          transform: 'translateX(0%)',
        })),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(-200%)' })),
        // eslint-disable-next-line quote-props
        animate('150ms ease-in', style({ 'height': 0, 'margin-top': 0 })),
      ]),
    ]),
  ],
})
export class ToastComponent {
  faTimes = faTimes;

  @Input() toast!: IMsg;

  @ViewChild('timeoutBar') timeoutBar?: ElementRef;
  private player?: AnimationPlayer;

  constructor(
    private toastService: ToastService,
    private animationBuilder: AnimationBuilder,
    private trackService: TrackService,
  ) { }

  close() {
    this.toastService.dismiss(this.toast);
  }

  progressAnimation(timeout: number): AnimationMetadata[] {
    return [
      style({ width: '0%' }),
      animate(`${timeout}ms linear`, style({
        width: '100%',
      })),
    ];
  }

  ngAfterViewInit() {
    this.toast.onUpdate?.pipe(untilDestroyed(this)).subscribe(() => {
      setTimeout(() => this.animateProgress());
    });
    this.animateProgress();
    this.trackService.handleToast(this.toast);
  }

  animateProgress() {
    if (this.timeoutBar) {
      const factory = this.animationBuilder.build(this.progressAnimation(this.toast.timeout));
      this.player = factory.create(this.timeoutBar.nativeElement);
      this.player.play();
      this.player.onDone(() => {
        this.player?.destroy();
        this.close();
      });
    }
  }

  @HostListener('mouseenter')
  pause() {
    this.player?.pause();
  }

  @HostListener('mouseleave')
  resume() {
    this.player?.play();
  }

  onAction() {
    if (this.toast.action) {
      this.toast.action();
      this.close();
    }
  }
}
