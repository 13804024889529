import { Analytics, Drawing, DrawOptions, Layer, Rect, TextLayer, ToolSurface } from '../interfaces';
import { getLayerName, isTextLayer } from '../layer';
import { createTextarea, PARAGRAPH_SPLIT_CHARACTER, TEXTAREA_TYPES, TextareaOptions } from './textarea';
import { FONTS, FONTS_SOURCES, hasFontsLoaded, PRESET_FONT, requireFontsCheck } from './fonts';
import { Mandatory } from '../typescript-utils';
import { toolIncompatibleWithTextLayers } from '../update';
import { cloneRect } from '../rect';
import { Editor } from '../../services/editor';
import { rasterizeLayer } from '../../services/layerActions';
import { fixedScale, safeFloatAny } from '../toolUtils';
import { createTransform } from '../toolSurface';
import { copyMat2d, createMat2d } from '../mat2d';
import { RasterizedTextLayerEvent } from '../analytics';
import { CharacterFormattingDescription, isValidCharacterFormatting, isValidParagraphFormatting, isValidTextareaFormatting, ParagraphFormattingDescription, TEXT_ALIGNMENTS, VERTICAL_ALIGNMENTS, TEXT_CASES } from './formatting';
import * as validate from '../validate';
import { assertEmptyObject } from '../utils';
import { BREAKLINE_STRATEGIES } from './lines';

export type ReadyTextLayer = Mandatory<TextLayer, 'textarea'>;

export function shouldCacheTextareaInLayer(layer: Layer): layer is TextLayer {
  return !!(isTextLayer(layer) && !layer.textarea && layer.fontsLoaded && !!getUsedFonts(layer).length);
}

export function cacheTextareaInLayer(layer: TextLayer): asserts layer is ReadyTextLayer {
  if (!layer.fontsLoaded) {
    throw new Error('Unable to cache textarea. Fonts are still loading');
  }

  if (layer.textData) {
    const textarea = createTextarea(FONTS, layer.textData);
    if (!textarea) {
      DEVELOPMENT && console.error('Tried to cache textarea but failed', layer);
      reportError(`Tried to cache textarea but failed (${layer.id} / "${getLayerName(layer)}")`);
    }
    layer.textarea = textarea;
  } else {
    layer.textarea = undefined;
  }
}

export function isNonDirtyTextLayer(layer: Layer | undefined) {
  return isTextLayer(layer) && !layer.textData.dirty;
}

export function canDrawTextLayer(layer: TextLayer): layer is ReadyTextLayer {
  if (!(layer.visible || layer.visibleLocally)) return false;
  if (!layer.fontsLoaded) {
    requireFontsCheck();
    return false;
  }
  if (layer.textarea) return true;
  if (!hasFontsLoaded(layer)) {
    requireFontsCheck();
    return false;
  }
  cacheTextareaInLayer(layer);
  return !!layer.textarea;
}

export function shouldDrawTextarea(layer: Layer | undefined, options: DrawOptions): layer is ReadyTextLayer {
  if (!isTextLayer(layer)) return false;
  if (!options.selectedTool) return false;
  if (toolIncompatibleWithTextLayers(options.selectedTool.id)) return false;
  return canDrawTextLayer(layer);
}

export function textLayerToTextBox(layer: TextLayer, rect: 'rect' | 'textureRect' | 'untransformedTextureRect'): Rect {
  if (!canDrawTextLayer(layer)) throw new Error('Unable to get text box from text layer. Fonts are still loading');
  if (!layer.textarea) cacheTextareaInLayer(layer);
  if (layer.invalidateCanvas) layer.textarea.write(layer.textarea.text);
  switch (rect) {
    case 'rect': return cloneRect(layer.textarea!.rect);
    case 'textureRect': return cloneRect(layer.textarea!.textureRect);
    case 'untransformedTextureRect': return cloneRect(layer.textarea!.getUntransformedTextureRect());
  }
}

export async function invokeRasterizeFlow(editor: Editor, layer: TextLayer, rasterizeManually = false) {
  if (editor.model.modals.isOpen('rasterizeFlow')) return false;

  try {
    const untilModalResolved = editor.model.modals.invokeRasterizeFlow(layer);
    editor.apply(() => { });

    const confirmedRasterization = await untilModalResolved;
    if (confirmedRasterization) {
      const eventProps: RasterizedTextLayerEvent = { source: 'confirmation-prompt' };
      editor.track?.event(Analytics.RasterizedLayer, eventProps);
      if (!rasterizeManually) rasterizeLayer(editor, layer);
    }

    return !!confirmedRasterization;
  } catch (e) {
    DEVELOPMENT && console.error(e);
    editor.errorReporter.reportError('Failed to complete rasterize flow', e, { layerId: layer.id, rasterizeManually });
    return false;
  }
}

export const getUsedFonts = ({ textData }: TextLayer, list: string[] = []): string[] => {
  if (textData.defaultFontFamily && !list.includes(textData.defaultFontFamily)) list.push(textData.defaultFontFamily);
  for (const cF of textData.characterFormattings) {
    if (cF.fontFamily && !list.includes(cF.fontFamily)) {
      list.push(cF.fontFamily);
    }
  }
  return list;
};

export const getFontFamilyNamesInDrawing = (drawing: Drawing): string[] => {
  const list: string[] = [];
  for (const layer of drawing.layers) {
    if (isTextLayer(layer)) {
      getUsedFonts(layer, list);
    }
  }
  return list;
};

export function setTextLayerTransform(layer: TextLayer, surface: ToolSurface) {
  const { translateX, translateY, scaleX, scaleY, rotate } = surface;
  const tx = safeFloatAny(translateX);
  const ty = safeFloatAny(translateY);
  const sx = fixedScale(safeFloatAny(scaleX));
  const sy = fixedScale(safeFloatAny(scaleY));
  const r = safeFloatAny(rotate);

  if (layer.textarea) {
    const transform = layer.textarea.transform;
    createTransform(transform, tx, ty, r, sx, sy);
    copyMat2d(layer.textarea.textareaFormatting.transform, transform);
    layer.textarea.bounds = layer.textarea!.getBounds();
  } else {
    const transform = createMat2d();
    createTransform(transform, tx, ty, r, sx, sy);
    layer.textData.textareaFormatting.transform = Array.from(transform);
  }

  layer.invalidateCanvas = true;
}

export function ensureTextLayerDirty(layer: Layer | undefined) {
  if (isTextLayer(layer) && !layer.textData.dirty) {
    if (layer.textarea) layer.textarea.dirty = true;
    layer.textData.dirty = true;
  }
}

export function throwIfTextLayer(layer: Layer | undefined) {
  if (isTextLayer(layer)) {
    throw new Error(`This can't be done on text layer!`);
  }
}

export const MAX_TEXTAREA_TEXT_LENGTH = 10000;
export function validateTextData(data: TextareaOptions) {
  validate.object(data);

  const { text, x, y, w, h, characterFormattings, paragraphFormattings, textareaFormatting, defaultFontFamily, type, dirty, isFocused, ...rest } = data;
  assertEmptyObject(rest);

  validate.string(text, MAX_TEXTAREA_TEXT_LENGTH + 10);
  validate.number(x);
  validate.number(y);
  validate.numberRangeOrUndefined(w, 0, 1000000, true);
  validate.numberRangeOrUndefined(h, 0, 1000000, true);
  validate.array(characterFormattings);
  validate.array(paragraphFormattings);
  validate.booleanOrUndefined(dirty);
  validate.booleanOrUndefined(isFocused);

  if (!TEXTAREA_TYPES.includes(type)) throw new Error('Invalid textarea type');
  if (!Object.keys(FONTS_SOURCES).includes(defaultFontFamily)) throw new Error('Invalid default font family');

  if (characterFormattings.length > text.length + 10) throw new Error('Too many character formattings');
  for (const cF of characterFormattings) {
    if (!isValidCharacterFormatting(cF)) throw new Error(`Invalid character formatting (${JSON.stringify(cF)})`);
  }

  const tooManyParagraphFormattings = paragraphFormattings.length > Array.from(data.text).filter((c) => c === PARAGRAPH_SPLIT_CHARACTER).length + 10;
  if (tooManyParagraphFormattings) throw new Error('Too many paragraph formattings!');
  for (const pF of paragraphFormattings) {
    if (!isValidParagraphFormatting(pF)) throw new Error(`Invalid character formatting (${JSON.stringify(pF)})`);
  }

  if (!isValidTextareaFormatting(textareaFormatting)) {
    throw new Error('Invalid textarea formatting');
  }
}

const defined = <T>(x: T | undefined): x is T => x !== undefined;

export function cloneTextData(data: TextareaOptions): TextareaOptions {
  const { x, y, w, h, text, type, dirty, isFocused, textareaFormatting, defaultFontFamily, characterFormattings, paragraphFormattings, ...rest } = data;
  assertEmptyObject(rest);

  const { displayNonPrintableCharacters, verticalAlignment, transform, ...tfRest } = textareaFormatting;
  if (!(Object.keys(tfRest).length === 1 && 'globalStyles' in tfRest)) {
    // assert only if legacy globalStyles is present
    assertEmptyObject(tfRest);
  }
  const clonedTextareaFormatting: TextareaOptions['textareaFormatting'] = { transform: [] };

  if (defined(verticalAlignment) && VERTICAL_ALIGNMENTS.includes(verticalAlignment)) clonedTextareaFormatting.verticalAlignment = verticalAlignment;
  if (defined(displayNonPrintableCharacters)) clonedTextareaFormatting.displayNonPrintableCharacters = !!displayNonPrintableCharacters;

  // TODO: this is not safe, transform fields can be non-numeric
  copyMat2d(clonedTextareaFormatting.transform!, transform ?? createMat2d());

  const clonedCharacterFormattings: CharacterFormattingDescription[] = [];
  for (const cF of characterFormattings) {
    const { textCase, baselineShift, lineheight, letterSpacing, outline, fontFamily, size, color, underline, italic, strikethrough, bold, scaleY, scaleX, length, start, ...cfRest } = cF;
    assertEmptyObject(cfRest);
    if (typeof start !== 'number') throw new Error(`Invalid value!`);
    if (typeof length !== 'number') throw new Error(`Invalid value!`);
    const clonedFormatting: CharacterFormattingDescription = { start, length };
    if (defined(textCase) && TEXT_CASES.includes(textCase)) clonedFormatting.textCase = textCase;
    if (defined(baselineShift) && typeof baselineShift === 'number') clonedFormatting.baselineShift = baselineShift;
    if (defined(lineheight) && typeof lineheight === 'number') clonedFormatting.lineheight = lineheight;
    if (defined(letterSpacing) && typeof letterSpacing === 'number') clonedFormatting.letterSpacing = letterSpacing;
    if (defined(outline)) clonedFormatting.outline = outline;
    if (defined(fontFamily) && Object.keys(FONTS_SOURCES).includes(fontFamily)) clonedFormatting.fontFamily = fontFamily;
    if (defined(size) && typeof size === 'number') clonedFormatting.size = size;
    if (defined(color) && typeof color === 'string') clonedFormatting.color = color;
    if (defined(underline) && (typeof underline === 'boolean' || underline === null)) clonedFormatting.underline = !!underline;
    if (defined(italic) && (typeof italic === 'boolean' || italic === null)) clonedFormatting.italic = !!italic;
    if (defined(strikethrough) && (typeof strikethrough === 'boolean' || strikethrough === null)) clonedFormatting.strikethrough = !!strikethrough;
    if (defined(bold) && (typeof bold === 'boolean' || bold === null)) clonedFormatting.bold = !!bold;
    if (defined(scaleX) && typeof scaleX === 'number') clonedFormatting.scaleX = scaleX;
    if (defined(scaleY) && typeof scaleY === 'number') clonedFormatting.scaleY = scaleY;
    clonedCharacterFormattings.push(clonedFormatting);
  }

  const clonedParagraphFormattings: ParagraphFormattingDescription[] = [];
  for (const pF of paragraphFormattings) {
    const { index, breaklineStrategy, alignment, ...pfRest } = pF;
    assertEmptyObject(pfRest);
    if (typeof index !== 'number') throw new Error(`Invalid value!`);
    if (breaklineStrategy && !BREAKLINE_STRATEGIES.includes(breaklineStrategy)) throw new Error(`Invalid value!`);
    if (alignment && !TEXT_ALIGNMENTS.includes(alignment)) throw new Error(`Invalid value!`);
    clonedParagraphFormattings.push({ index, breaklineStrategy, alignment });
  }

  const defaultFontFamilySafe = Object.keys(FONTS_SOURCES).includes(defaultFontFamily) ? defaultFontFamily : PRESET_FONT;

  return {
    x, y, w, h,
    text, type, dirty, isFocused,
    defaultFontFamily: defaultFontFamilySafe,
    characterFormattings: clonedCharacterFormattings,
    paragraphFormattings: clonedParagraphFormattings,
    textareaFormatting: clonedTextareaFormatting,
  };
}
