enum moveType {
  horizontal, vertical, both, none
}

export function applyMotionBlur(source: Uint8ClampedArray, destination: Uint8ClampedArray, width: number, height: number, distance: number, dirx: number, diry: number, reversed: boolean) {
  const redArray = new Uint8ClampedArray(source.length / 4);
  const greenArray = new Uint8ClampedArray(source.length / 4);
  const blueArray = new Uint8ClampedArray(source.length / 4);
  const alphaArray = new Uint8ClampedArray(source.length / 4);
  const dstredArray = new Uint8ClampedArray(source.length / 4);
  const dstgreenArray = new Uint8ClampedArray(source.length / 4);
  const dstblueArray = new Uint8ClampedArray(source.length / 4);
  const dstalphaArray = new Uint8ClampedArray(source.length / 4);

  // separating red, blue, green and alpha to different arrays
  for (let index = 0, count = 0; index < source.length; index += 4, count++) {
    const alpha = source[index + 3];
    redArray[count] = source[index] * alpha / 255;
    greenArray[count] = source[index + 1] * alpha / 255;
    blueArray[count] = source[index + 2] * alpha / 255;
    alphaArray[count] = alpha;
  }

  motionBlur(redArray, dstredArray, width, height, distance/2, dirx, diry, reversed);
  motionBlur(greenArray, dstgreenArray, width, height, distance/2, dirx, diry, reversed);
  motionBlur(blueArray, dstblueArray, width, height, distance/2, dirx, diry, reversed);
  motionBlur(alphaArray, dstalphaArray, width, height, distance/2, dirx, diry, reversed);

  // re-applying the colors to the destination array
  for (let index = 0, count = 0; index < source.length; index += 4, count++) {
    const alpha = dstalphaArray[count];
    destination[index] = dstredArray[count] / alpha * 255;
    destination[index + 1] = dstgreenArray[count] / alpha * 255;
    destination[index + 2] = dstblueArray[count] / alpha * 255;
    destination[index + 3] = alpha;
  }
}

function motionBlur(source: Uint8ClampedArray, target: Uint8ClampedArray, width: number, height: number, radius: number, dirX: number, dirY: number, reversed: boolean) {
  const roundedRadius = Math.round(radius);
  let horizontalR = 0;
  let verticalR = 0;
  const maxHorizontalMoves = (dirX > 0) ? (Math.round(width/dirX)) : 0;
  const maxVerticalMoves = (dirY > 0) ? (Math.round(height/dirY)) : 0;
  const horizontalFirst = (maxVerticalMoves * width) > maxHorizontalMoves;
  const horizontalTi = horizontalFirst ? 1 : width;
  const firstDimension = horizontalFirst ? width : height;
  let secondDimension = horizontalFirst ? height : width;
  const secondDimensionUnscaled = secondDimension;

  let moves : moveType[];
  const numMovements = ((dirX == 0) || (dirY == 0)) ? 1 :  maxHorizontalMoves + maxVerticalMoves;
  moves = new Array<moveType>(numMovements);

  if (dirX == 1.0) {
    moves[0] = moveType.horizontal;
    horizontalR = roundedRadius;
  }
  else if (dirY == 1.0) {
    moves[0] = moveType.vertical;
    verticalR = roundedRadius;
  } else {
    for (let index = 0; index < numMovements; index++) {
      let XYchanged = calculateIfXYchanged(index, dirX, dirY);
      if (XYchanged[0] == true && XYchanged[1] == true) {
        moves[index] = moveType.both;
        if (index < roundedRadius) {
          horizontalR++;
          verticalR++;
        }
      } else if (XYchanged[0] == true) {
          moves[index] = moveType.horizontal;
          if (index < roundedRadius) horizontalR++;
      } else if (XYchanged[1] == true) {
          moves[index] = moveType.vertical;
          if (index < roundedRadius) verticalR++;
      } else {
        moves[index] = moveType.none;
      }
    }
  }

  if (dirX != 0 && dirY != 0) {
    secondDimension = horizontalFirst ? (secondDimension/dirY) : (secondDimension/dirX);
  }

  // if dirX != 1 and dirY!= 1, we traverse lines in two triangles - from fD = 0 to fD < firstDimension - the top one
  // and from fD = firstDimension - the bottom one
  const secondTurn = (dirX == 1 || dirY == 1) ? 0 : secondDimensionUnscaled;

  for (let fD = 0; fD < firstDimension + secondTurn; fD++) {
    // ti - target index, li - left index, ri - right index
    const tiReversed = (fD < firstDimension) ? ((firstDimension - 1) - fD * horizontalTi) : ((fD - firstDimension + 1) * width + width - 1); // we go from the back
    let ti = reversed ? (tiReversed) : (fD < firstDimension) ? fD * horizontalTi : (fD - firstDimension + 1) * width;
    let li = ti;
    let ri = ti;
    const possibleRiHorMoves = (fD < firstDimension && dirX != 1) ? (width - 1 - fD) : (width - 1);
    const possibleRiVerMoves = (fD < firstDimension || dirY == 1) ? (height - 1) : (height - 1 - (fD - firstDimension + 1));
    const horizontalAdditionWithinR = (dirX == 1) ? 0 : fD;
    let riOverboard: boolean;
    if (fD < firstDimension) {
      riOverboard = !(((horizontalAdditionWithinR + horizontalR) < width) && (verticalR < height));
    } else {
      riOverboard = !((((fD - firstDimension + 1) + verticalR) < height - 1) && ((horizontalR) < (width - 1)));
    }
    let val = 0;
    let movesIndex = 0;
    let liMovesIndex = 0;
    let offset = 0;
    let numberOfValues = 0;
    let omitRi = false;
    
    let horMovesWithinRadius = 0;
    let verMovesWithinRadius = 0;
  
    for (let j = 0; j < roundedRadius; j++) {
      const value = source[ti + offset];
      val += value;
      numberOfValues++;
      if (((dirY != 1) && (horMovesWithinRadius >= possibleRiHorMoves)) || ((dirX != 1) && (verMovesWithinRadius >= possibleRiVerMoves))) {
        omitRi = true;
        break;
      }
      let move = moves[movesIndex];
      if (move == moveType.horizontal) {
        if (reversed) {
          offset--;
          ri--;
        } else {
          offset++;
          ri++;
        }
        horMovesWithinRadius++;
      } else if (move == moveType.vertical) {
        offset += width;
        ri += width;
        verMovesWithinRadius++;
      } else if (move == moveType.both) {
        if (reversed) {
          offset += width - 1;
          ri += width - 1;
        } else {
          offset += width + 1;
          ri += width + 1;
        }
        horMovesWithinRadius++;
        verMovesWithinRadius++;
      }

      movesIndex++;
      if (moves.length == 1) {
        movesIndex = 0;
      }
    }      

    let riFirst = ri; // position of riFirst needs to be one position next to ri
    let riFirstMovesIndex = movesIndex;
    let riFirstMove = moves[riFirstMovesIndex];
    for (let i = 0; i < 2; i++) { // go twice just in case moveType is none
      if (riFirstMove == moveType.horizontal) {
        if (reversed) {
          riFirst--;
        } else {
          riFirst++;
        }
        break;
      } else if (riFirstMove == moveType.vertical) {
        riFirst += width;
        break;
      } else if (riFirstMove == moveType.both) {
        if (reversed) {
          riFirst += width - 1;
        } else {
          riFirst += width + 1;
        }
        break;
      } else {
        riFirstMovesIndex++;
      }
    }

    if (fD >= firstDimension) {
      secondDimension = (secondDimensionUnscaled - (fD - firstDimension + 1)) / dirY;
    }

    movesIndex = 0;
    let limitPassed = false; // to avoid unnecessary calculations 
    let horMoves = 0;
    let rHorMoves = 0;
    let rVerMoves = 0;
    let canSubstractLi = false;

    for (let sD = 0; sD < Math.round(secondDimension); sD++) {
      if (!riOverboard && ti == riFirst) {
        canSubstractLi = true;
      }
      
      if (canSubstractLi) {
        const leftValue = source[li];
        val -= leftValue;
        canSubstractLi = true;
        numberOfValues--;
      }

      if (!omitRi) {
        const rightValue = source[ri];
        val += rightValue;
        numberOfValues++;
      }
      
      let value = 0; 
      if (!canSubstractLi) {
        value = Math.round(val/numberOfValues);
      } else {
        value = Math.round(val/numberOfValues);
      }

      target[ti] = value;
      
      const move = moves[movesIndex];
      if (move == moveType.horizontal) {
        if (reversed) ti--;
        else ti++;
        horMoves++;
      } else if (move == moveType.vertical) {
        ti += width;
      } else if (move == moveType.both) {
        if (reversed) {
          ti += width - 1;
        } else {
          ti += width + 1;
        }
        horMoves++;
      }

      const horizontalAddition = (fD < firstDimension) ? fD : 0;
      const verticalAddition = (fD >= firstDimension) ? (fD-firstDimension+1) : 0;

      if (((dirY == 1) && ((rVerMoves + verticalR) < (height-1))) ||
          ((dirX == 1) && ((rHorMoves + horizontalR) < (width-1))) ||
          ((dirY > 0) && (dirX > 0) && ((rHorMoves + horizontalAddition + horizontalR) < (width-1)) && ((rVerMoves + verticalAddition + verticalR) < (height-1)))) {
        const riMove = (dirY == 1) ? moveType.vertical : (dirX == 1) ? moveType.horizontal : moves[movesIndex + roundedRadius];
        if (riMove == moveType.horizontal) {
          if (reversed) {
            ri--;
          } else {
            ri++;
          }
          rHorMoves++;
        } else if (riMove == moveType.vertical) {
          ri += width;
          rVerMoves++;
        } else if (riMove == moveType.both) {
          if (reversed) {
            ri += width - 1;
          } else {
            ri += width + 1;
          }
          rHorMoves++;
          rVerMoves++;
        }
      } else {
        omitRi = true;
      }

      if (canSubstractLi) {
        if (dirY == 1) {
          li += width;
        } else if (dirX == 1) {
          li++;
        } else {
          const liMove = moves[liMovesIndex];
          liMovesIndex++;
          if (moves.length == 1) {
            liMovesIndex = 0;
          }
          if (liMove == moveType.horizontal) {
            if (reversed) {
              li--;
            } else {
              li++;
            }
          } else if (liMove == moveType.vertical) {  
            li+=width;
          } else if (liMove == moveType.both) {
            if (reversed) {
              li += width - 1;
            } else {
              li += width + 1;
            }
          }
          if (moves.length == 1) {
            liMovesIndex = 0;
          }
        }
      }

      movesIndex++;
      if (moves.length == 1) {
        movesIndex = 0;
      }

      if (dirX != 1 && dirY != 1 && (fD < firstDimension) && (width - (fD + horMoves) <= 0)) {
        limitPassed = true; // no point in going further as we're at the end of the canvas, just go to the next line
        break;
      }
    }

    if (limitPassed) continue;
  }
}

function calculateIfXYchanged(j: number, dirX: number, dirY: number): Array<boolean> {
  let changeIndex: Array<boolean> = [];
  let samplerPositionX = 0.5 + j * dirX;
  let samplerPositionY = 0.5 + j * dirY;
  let samplerPositionXNext = 0.5 + (j + 1) * dirX;
  let samplerPositionYNext = 0.5 + (j + 1) * dirY;
 
  if (Math.ceil(samplerPositionX) != Math.ceil(samplerPositionXNext)) {
    changeIndex[0] = true;
  } else {
    changeIndex[0] = false;
  }
  
  if (Math.ceil(samplerPositionY) != Math.ceil(samplerPositionYNext)) {
    changeIndex[1] = true;
  } else {
    changeIndex[1] = false;
  }
  return changeIndex;
}