import { Bin } from 'ag-sockets/dist/browser';

export const BinLocalId = Bin.U8; // NOTE: if changing also look into startToolWithMoves method & drawingHistory.ts
export const BinLayerId = Bin.U16; // max 0xffff
export const BinCoord = Bin.I32;
export const BinPressure = Bin.U16;
export const BinConnId = Bin.U16;

// @ref interfaces.ts
//                             [uniqId, name, color, avatar, role, anonymous, anonymousNumber]
export const BinSequenceUser = [Bin.Str, Bin.Str, Bin.Str, Bin.Str, Bin.Str, Bin.Bool, Bin.U32];
