import { IToolEditor, IToolModel, IToolData, ITool, ToolId, LayerUpdateData } from '../interfaces';
import { getLayerSafe } from '../drawing';
import { redrawLayerThumb, updateLayerState, validateLayerData } from '../layer';
import { logAction } from '../actionLog';
import { getLayerRect } from '../layerUtils';
import { redrawDrawing } from '../../services/editorUtils';
import { finishTransform } from '../toolUtils';

export interface ILayerUpdateToolData extends IToolData {
  layer: LayerUpdateData;
}

export class LayerUpdateTool implements ITool {
  id = ToolId.LayerUpdate;
  name = '';
  constructor(public editor: IToolEditor, public model: IToolModel) {
  }
  do(data: ILayerUpdateToolData) {
    validateLayerData(data.layer);
    if ('rect' in data.layer) throw new Error('Invalid layer data');
    this.updateLayer(data.layer, true);
  }
  updateLayer(data: LayerUpdateData, remote = false) {
    logAction(`[${remote ? 'remote' : 'local'}] update layer (clientId: ${this.model.user.localId}, layerId: ${data.id})`);

    const user = this.model.user;

    // Keep transform if we're changing the same layer, otherwise finish.
    if (user.surface.layer?.id !== data.id) {
      finishTransform(this.editor, user, 'updateLayer');
    }

    const layer = getLayerSafe(this.editor.drawing, data.id);
    user.history.pushLayerState(data.id);

    updateLayerState(layer, data);

    if ('opacity' in data) redrawLayerThumb(layer);

    this.model.doTool<ILayerUpdateToolData>(data.id, { id: this.id, layer: data });

    this.editor.apply(() => { }); // update UI

    // clipping group and visible can impact all layers above
    if ('clippingGroup' in data || 'visible' in data) {
      redrawDrawing(this.editor);
    } else {
      redrawDrawing(this.editor, getLayerRect(layer)); // TODO: use redrawLayer ?
    }
  }
}
