import { Directive, ElementRef } from '@angular/core';

@Directive({ selector: '[autoSelect]' })
export class AutoSelect {
  constructor(element: ElementRef<HTMLInputElement>) {
    const input = element.nativeElement;
    input.addEventListener('focus', () => {
      if (input.type === 'number') {
        input.select();
      } else {
        input.setSelectionRange(0, 0);
        input.setSelectionRange(0, input.value.length);
      }
    });
  }
}
