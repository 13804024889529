import { Component, Output, Input, EventEmitter } from '@angular/core';
import { Drawing, User } from '../../../common/interfaces';
import { hasPermission } from '../../../common/userRole';
import { faCheck } from '../../../generated/fa-icons';

@Component({
  selector: 'custom-list',
  templateUrl: 'custom-list.pug',
  styleUrls: ['custom-list.scss'],
})
export class CustomList {
  readonly checkIcon = faCheck;
  @Output() selectedOptionsChange = new EventEmitter<User[]>();
  @Input() options: User[] = [];
  @Input() optionCallback: (option: User) => void = () => {};
  @Input() optionCallbackName: string | undefined = undefined;
  @Input() selectedOptions: User[] = [];
  @Input() title = '';
  @Input() emptyListText = '';
  @Input() showTitleBadge = false;
  @Input() drawing: Drawing | undefined = undefined;

  checkedOptions = new Set<User>();
  triggeredCallbacks = new Set<User>();
  allSelected = false;

  get isSomeOptionsChecked() {
    return this.checkedOptions.size > 0 && this.checkedOptions.size < this.options.length;
  }

  get currentOptions() {
    this.triggeredCallbacks.forEach(o => {
      if (!this.options.includes(o)) this.triggeredCallbacks.delete(o);
    });
    return this.options;
  }

  ngOnInit() {
    this.selectedOptions.forEach(option => this.checkedChanged(option));
  }

  checkedChanged(option: User) {
    if (this.checkedOptions.has(option)) this.checkedOptions.delete(option);
    else this.checkedOptions.add(option);
    if (this.checkedOptions.size >= this.options.length) this.allSelected = true;
    if (this.checkedOptions.size === 0) this.allSelected = false;

    this.emitChanges();
  }

  toggleCheckAll(event: boolean) {
    if (event) this.options.forEach(option => this.checkedOptions.add(option));
    else this.checkedOptions.clear();

    this.emitChanges();
  }

  emitChanges() {
    this.selectedOptionsChange.emit(Array.from(this.checkedOptions));
  }

  isApproved(user: User) {
    return this.drawing && hasPermission(this.drawing, user, 'takeOverPresentation');
  }

  callback(user: User) {
    this.optionCallback(user);
    this.triggeredCallbacks.add(user);
  }

  bulkCallback() {
    this.checkedOptions.forEach(option => this.callback(option));
  }
}
