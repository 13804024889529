/* tslint:disable */

export const aiInpaintMaskShader = `uniform vec4 color1;
uniform vec4 color2;
uniform sampler2D sampler1;
uniform float time;
uniform float size;
uniform float width;
uniform float height;
varying vec4 textureCoord;
void main() {
  vec4 src = texture2D(sampler1, textureCoord.xy);
  float fmodY = mod(textureCoord.y*height, size*4.0) + time;
  float fmodResult = mod(textureCoord.x*width + fmodY/2.0, size*2.0);
  gl_FragColor = fmodResult < size ? color1 : color2;
  gl_FragColor *= src.a;
}`;

export const aiOutpaintMaskShader = `uniform vec4 color1;
uniform vec4 color2;
uniform sampler2D sampler1;
uniform float time;
uniform float size;
uniform float width;
uniform float height;
varying vec4 textureCoord;
void main() {
  vec4 src = texture2D(sampler1, textureCoord.xy);
  float fmodY = mod(textureCoord.y*height, size*4.0) + time;
  float fmodResult = mod(textureCoord.x*width + fmodY/2.0, size*2.0);
  gl_FragColor = fmodResult < size ? color1 : color2;
  float a = (1.0 - src[3]) * gl_FragColor[3];
  gl_FragColor = vec4(gl_FragColor.rgb * a, a);
}`;

export const antAiShader = `uniform float time;
uniform float size;
uniform vec4 color1;
uniform vec4 color2;
varying vec4 textureCoord;
void main() {
  float fmodResult = mod(textureCoord.x + time, size * 2.0);
  gl_FragColor = fmodResult < size ? color1 : color2;
}`;

export const antsShader = `uniform float time;
uniform float size;
varying vec4 textureCoord;
void main() {
  float fmodResult = mod(textureCoord.x + time, size * 2.0);
  gl_FragColor = fmodResult < size ? vec4(1, 1, 1, 1) : vec4(0, 0, 0, 1);
}`;

export const basicShader = `uniform sampler2D sampler1;
varying vec4 textureCoord;
void main() {
  vec4 color = texture2D(sampler1, textureCoord.xy);
  gl_FragColor = color;
}`;

export const basicColorShader = `uniform sampler2D sampler1;
uniform vec4 color;
varying vec4 textureCoord;
void main() {
  vec4 src = texture2D(sampler1, textureCoord.xy);
  gl_FragColor = src * color;
}`;

export const basicPremultiplyShader = `uniform sampler2D sampler1;
varying vec4 textureCoord;
void main() {
  vec4 color = texture2D(sampler1, textureCoord.xy);
  color.rgb *= color.a;
  gl_FragColor = color;
}`;

export const brightnessContrastShader = `precision highp float;
uniform sampler2D sampler1;
uniform vec2 size;
uniform float brightness;
uniform float contrast;
vec3 ContrastSaturationBrightness(vec3 color, float brt, float sat, float con)
{
  const float AvgLumR = 0.5;
  const float AvgLumG = 0.5;
  const float AvgLumB = 0.5;
  const vec3 LumCoeff = vec3(0.2125, 0.7154, 0.0721);
  vec3 AvgLumin = vec3(AvgLumR, AvgLumG, AvgLumB);
  vec3 brtColor = color * brt;
  vec3 intensity = vec3(dot(brtColor, LumCoeff));
  vec3 satColor = mix(intensity, brtColor, sat);
  vec3 conColor = mix(AvgLumin, satColor, con);
  return conColor;
}
vec3 convertRgbToLinearRgb(vec3 rgb)
{
    return pow(rgb, vec3(2.2));
}
vec3 convertLinearRgbToRgb(vec3 linear_rgb)
{
    return pow(linear_rgb, vec3(1.0 / 2.2));
}
vec3 RGBtoHSP( vec3 rgb) {
  vec3 hsp = vec3(0.0);
  vec3 PerceivedRGB = vec3(.299, .587, 0.114);
  hsp.z = sqrt(rgb.r * rgb.r *PerceivedRGB.r+ rgb.g * rgb.g * PerceivedRGB.g+ rgb.b * rgb.b * PerceivedRGB.b);
  if (rgb.r == rgb.g && rgb.r == rgb.b) { hsp.x = 0.0; hsp.y = 0.0; return hsp; }
  if (rgb.r >= rgb.g && rgb.r >= rgb.b) {   
    if (rgb.b >= rgb.g ) {
      hsp.x = 6.0 / 6.0 - 1.0 / 6.0 * (rgb.b - rgb.g) / (rgb.r - rgb.g); hsp.y = 1.0 - rgb.g / rgb.r;
    }
    else {
      hsp.x = 0.0 / 6.0 + 1.0 / 6.0 * (rgb.g - rgb.b) / (rgb.r - rgb.b); hsp.y =1.0 - rgb.b / rgb.r;
    }
  } else if (rgb.g >= rgb.r && rgb.g >= rgb.b) {   
    if (rgb.r >= rgb.b) {
      hsp.x= 2.0 / 6.0 - 1.0 / 6.0 * (rgb.r- rgb.b) / (rgb.g - rgb.b); hsp.y = 1.0 - rgb.b / rgb.g;
    }
    else {
      hsp.x= 2.0 / 6.0 + 1.0 / 6.0 * (rgb.b - rgb.r) / (rgb.g - rgb.r); hsp.y = 1.0 - rgb.r / rgb.g;
    }
  }
  else {   
    if (rgb.g >= rgb.r) {
      hsp.x= 4.0 / 6.0 - 1.0 / 6.0 * (rgb.g - rgb.r) / (rgb.b - rgb.r); hsp.y = 1.0 - rgb.r / rgb.b; }
    else {
      hsp.x= 4.0 / 6.0 + 1.0 / 6.0 * (rgb.r - rgb.g) / (rgb.b - rgb.g); hsp.y = 1.0 - rgb.g / rgb.b; }
  }
  return hsp;
}
vec3 HSPtoRGB(vec3 hsp ) {
  float  part, minOverMax= 1.0 - hsp.y ;
  vec3 rgb = vec3(0.0);
  vec3 PerceivedRGB = vec3(.299, .587, 0.114);
  if (minOverMax > 0.0 ) {
    if ( hsp.x  < 1.0 / 6.0) {   
      hsp.x = 6.*( hsp.x -0./6.); part=1.+hsp.x *(1./minOverMax-1.);
      rgb.b = hsp.z /sqrt(PerceivedRGB.r / minOverMax / minOverMax + PerceivedRGB.g  * part * part + PerceivedRGB.b );
      rgb.r =(rgb.b )/minOverMax; rgb.g =(rgb.b )+hsp.x *((rgb.r )-(rgb.b ));
    }
    else if ( hsp.x <2./6.) {   
      hsp.x = 6.*(-hsp.x +2./6.); part=1.+hsp.x *(1./minOverMax-1.);
      rgb.b =hsp.z/sqrt(PerceivedRGB.g /minOverMax/minOverMax+PerceivedRGB.r *part*part+PerceivedRGB.b );
      rgb.g =(rgb.b )/minOverMax; rgb.r =(rgb.b )+hsp.x *((rgb.g )-(rgb.b ));
    }
    else if ( hsp.x <3./6.) {   
      hsp.x = 6.*( hsp.x -2./6.); part=1.+hsp.x *(1./minOverMax-1.);
      rgb.r =hsp.z/sqrt(PerceivedRGB.g /minOverMax/minOverMax+PerceivedRGB.b *part*part+PerceivedRGB.r );
      rgb.g =(rgb.r )/minOverMax; rgb.b =(rgb.r )+hsp.x *((rgb.g )-(rgb.r ));
    }
    else if ( hsp.x <4./6.) {   
      hsp.x = 6.*(-hsp.x +4./6.); part=1.+hsp.x *(1./minOverMax-1.);
      rgb.r =hsp.z/sqrt(PerceivedRGB.b /minOverMax/minOverMax+PerceivedRGB.g *part*part+PerceivedRGB.r );
      rgb.b =(rgb.r )/minOverMax; rgb.g =(rgb.r )+hsp.x *((rgb.b )-(rgb.r ));
    }
    else if ( hsp.x <5./6.) {   
      hsp.x = 6.*( hsp.x -4./6.); part=1.+hsp.x *(1./minOverMax-1.);
      rgb.g =hsp.z/sqrt(PerceivedRGB.b /minOverMax/minOverMax+PerceivedRGB.r *part*part+PerceivedRGB.g );
      rgb.b =(rgb.g )/minOverMax; rgb.r =(rgb.g )+hsp.x *((rgb.b )-(rgb.g ));
    }
    else {   
      hsp.x = 6.*(-hsp.x +6./6.); part=1.+hsp.x *(1./minOverMax-1.);
      rgb.g =hsp.z/sqrt(PerceivedRGB.r /minOverMax/minOverMax+PerceivedRGB.b *part*part+PerceivedRGB.g );
      rgb.r =(rgb.g )/minOverMax; rgb.b =(rgb.g )+hsp.x *((rgb.r )-(rgb.g ));
    }
  }
  else {
    if ( hsp.x <1./6.) {   
      hsp.x = 6.*( hsp.x -0./6.); rgb.r =sqrt(hsp.z * hsp.z/(PerceivedRGB.r +PerceivedRGB.g *hsp.x *hsp.x )); rgb.g =(rgb.r )*hsp.x ; rgb.b =0.;
    }
    else if ( hsp.x <2./6.) {   
      hsp.x = 6.*(-hsp.x +2./6.); rgb.g =sqrt(hsp.z * hsp.z/(PerceivedRGB.g +PerceivedRGB.r *hsp.x *hsp.x )); rgb.r =(rgb.g )*hsp.x ; rgb.b =0.;
    }
    else if ( hsp.x <3./6.) {   
      hsp.x = 6.*( hsp.x -2./6.); rgb.g =sqrt(hsp.z * hsp.z/(PerceivedRGB.g +PerceivedRGB.b *hsp.x *hsp.x )); rgb.b =(rgb.g )*hsp.x ; rgb.r =0.;
    }
    else if ( hsp.x <4./6.) {   
      hsp.x = 6.*(-hsp.x +4./6.); rgb.b =sqrt(hsp.z * hsp.z/(PerceivedRGB.b +PerceivedRGB.g *hsp.x *hsp.x )); rgb.g =(rgb.b )*hsp.x ; rgb.r =0.;
    }
    else if ( hsp.x <5./6.) {   
      hsp.x = 6.*( hsp.x -4./6.); rgb.b =sqrt(hsp.z * hsp.z/(PerceivedRGB.b +PerceivedRGB.r *hsp.x *hsp.x )); rgb.r =(rgb.b )*hsp.x ; rgb.g =0.;
    }
    else {   
      hsp.x = 6.*(-hsp.x +6./6.); rgb.r =sqrt(hsp.z * hsp.z/(PerceivedRGB.r +PerceivedRGB.b *hsp.x *hsp.x )); rgb.b =(rgb.r )*hsp.x ; rgb.g =0.;
    }
  }
  return (rgb);
}
mat4 contrastMatrix( float contrast )
{
  float t = ( 1.0 - contrast ) / 2.0;
    return mat4( contrast, 0, 0, 0,
                 0, contrast, 0, 0,
                 0, 0, contrast, 0,
                 t, t, t, 1 );
}
void main() {
  vec4 color = texture2D(sampler1, (gl_FragCoord.xy ) /size.xy);
  if (color.a == 0.0) {
    gl_FragColor = color;
    return;
  }
  vec3 finalColor = color.rgb / color.a;
  float average = 127.0 / 255.0;
  float percentage = contrast / 100.0;
  if (percentage > 0.0) {
    finalColor.r = average + (finalColor.r  - average) * (1.0 / (1.0 - percentage));
    finalColor.g = average + (finalColor.g  - average) * (1.0 / (1.0 - percentage));
    finalColor.b = average + (finalColor.b  - average) * (1.0 / (1.0 - percentage));
  } else {
    finalColor.r = average + (finalColor.r  - average) * (1.0 + percentage);
    finalColor.g = average + (finalColor.g  - average) * (1.0 + percentage);
    finalColor.b = average + (finalColor.b  - average) * (1.0 + percentage);
  }
  finalColor.r += brightness / 255.0;
  finalColor.g += brightness / 255.0;
  finalColor.b += brightness / 255.0;
  finalColor = clamp(finalColor * color.a, 0.0, color.a);
  gl_FragColor = vec4(finalColor.rgb, color.a);
}`;

export const checkerShader = `uniform float checkerSize;
uniform float scale;
uniform vec2 size;
varying vec4 textureCoord;
vec4 checkerAt(vec2 coord) {
  float checkerScale = scale / checkerSize;
  vec2 scaledCoord = coord * size * checkerScale;
  vec4 light = vec4(1, 1, 1, 1);
  vec4 dark = vec4(0.81, 0.81, 0.81, 1);
  vec2 blend = 1.0 - clamp((1.0 - fract(scaledCoord)) * checkerSize, 0.0, 1.0);
  vec4 a;
  vec4 b;
  highp float fmodResult = mod(floor(scaledCoord.x) + floor(scaledCoord.y), 2.0);
  if (fmodResult < 1.0) {
    a = light;
    b = dark;
  } else {
    a = dark;
    b = light;
  }
  vec4 color = mix(mix(a, b, blend.x), mix(b, a, blend.x), blend.y);
  return color;
}
void main() {
  vec2 texCoord = textureCoord.xy;
  vec2 clamped = clamp(texCoord, 0.0, 1.0);
  vec4 color = checkerAt(clamped);
  if (texCoord != clamped) {
    vec2 a = vec2(1.0) - abs(texCoord - clamped) / ((1.0 / scale) / size);
    color.a *= min(a.x, a.y);
  }
  gl_FragColor = color;
}`;

export const circleShader = `varying vec4 vColor;
varying vec4 textureCoord;
void main() {
  float radius = textureCoord.z;
  float dist = length(textureCoord.xy);
  float distFromEdge = dist - radius;
  float alpha = clamp(0.5 - distFromEdge, 0.0, 1.0);
  #ifdef OPACITY
  float result = textureCoord.w * alpha;
  gl_FragColor = vec4(result);
  #else
  vec4 result = vColor * alpha; 
  gl_FragColor = result;
  #endif
}`;

export const circleOutlineShader = `uniform float lineWidth;
varying vec4 vColor;
varying vec4 textureCoord;
void main() {
  float oneByPixelSize = textureCoord.z;
  float texCoordMultiplier = textureCoord.w;
  vec2 t = (textureCoord.xy - 0.5) * 2.0 * texCoordMultiplier;
  float halfWidth = lineWidth * 0.5 + 0.5;
  float distFromCenter = sqrt(dot(t, t));
  float distFromEdge = abs(distFromCenter - 1.0);
  float alpha = clamp(halfWidth - (distFromEdge * oneByPixelSize), 0.0, 1.0);
  gl_FragColor = vColor * alpha; 
}`;

export const clippingShader = `uniform sampler2D sampler1; 
uniform sampler2D sampler2; 
uniform sampler2D sampler3; 
varying vec4 textureCoord;
uniform float maskOpacity;
void main() {
  vec4 src = texture2D(sampler1, textureCoord.xy);
  vec4 dst = texture2D(sampler2, textureCoord.xy);
  vec4 mask = texture2D(sampler3, textureCoord.xy);
  float a = mask.a * maskOpacity;
  if (src.a > 0.0) src.rgb *= a / src.a;
  src.a = a;
  gl_FragColor = src + (1.0 - src.a) * dst;
}`;

export const curvesShader = `precision highp float;
uniform sampler2D sampler1;
uniform sampler2D sampler2;
uniform vec2 size;
uniform float activeChannel;
vec4 unpremultiplyAlpha(vec4 premultColor) {
    if (premultColor.a == 0.0) {
        return vec4(0.0, 0.0, 0.0, 0.0);
    } else {
        return vec4(premultColor.rgb / premultColor.a, premultColor.a);
    }
}
vec4 premultiplyAlpha(vec4 color) {
    return vec4(color.rgb * color.a, color.a);
}
float getMappedValue(float inputValue, float channel) {
    vec4 color = texture2D(sampler2, vec2(inputValue, 0.0));
    float outputValue = color.a;
    if (channel == 1.0) outputValue = color.r;
    else if (channel == 2.0) outputValue = color.g;
    else if (channel == 3.0) outputValue = color.b;
    return outputValue;
}
void main() {
    vec4 color = texture2D(sampler1, (gl_FragCoord.xy ) /size.xy);
    vec4 unpremultiplyColor = unpremultiplyAlpha(color);
    unpremultiplyColor.r = getMappedValue(unpremultiplyColor.r, 0.0);
    unpremultiplyColor.g = getMappedValue(unpremultiplyColor.g, 0.0);
    unpremultiplyColor.b = getMappedValue(unpremultiplyColor.b, 0.0);
    unpremultiplyColor.r = getMappedValue(unpremultiplyColor.r, 1.0);
    unpremultiplyColor.g = getMappedValue(unpremultiplyColor.g, 2.0);
    unpremultiplyColor.b = getMappedValue(unpremultiplyColor.b, 3.0);
    gl_FragColor = premultiplyAlpha(unpremultiplyColor);
}`;

export const drawingShader = `uniform sampler2D sampler1;
uniform vec2 size;
uniform vec2 minCoord;
uniform vec2 maxCoord;
uniform vec2 minDrawingCoord;
uniform vec2 maxDrawingCoord;
uniform float scale;
uniform float pixelated;
uniform vec2 checkerOffset;
uniform float unlimited;
varying vec4 textureCoord;
vec4 checkerAt(vec2 coord) {
  float checkerSize = 8.0;
  float checkerScale = scale / checkerSize;
  vec2 scaledCoord = (coord + checkerOffset) * size * checkerScale;
  vec4 light = vec4(1, 1, 1, 1);
  vec4 dark = vec4(0.81, 0.81, 0.81, 1);
  vec2 blend = 1.0 - clamp((1.0 - fract(scaledCoord)) * checkerSize, 0.0, 1.0);
  vec4 a, b;
  highp float fmodResult = mod(floor(scaledCoord.x) + floor(scaledCoord.y), 2.0);
  if (fmodResult < 1.0) {
    a = light;
    b = dark;
  } else {
    a = dark;
    b = light;
  }
  return mix(mix(a, b, blend.x), mix(b, a, blend.x), blend.y);
}
void main() {
  vec2 texCoord = textureCoord.xy;
  vec2 clamped = clamp(texCoord, minCoord, maxCoord);
  vec2 clampedDrawing = clamp(texCoord, minDrawingCoord, maxDrawingCoord);
  vec2 sampleCoords = clamped;
  if (pixelated == 1.0) {
    vec2 pixel = clamped * size;
    vec2 uv = floor(pixel) + 0.5;
    uv += 1.0 - clamp((1.0 - fract(pixel)) * scale, 0.0, 1.0);
    sampleCoords = uv / size;
  }
  vec4 color = texture2D(sampler1, sampleCoords);
  if (unlimited == 0.0) {
    if (color.a < 1.0) {
      vec4 checker = checkerAt(texCoord);
      color = vec4(color.rgb + (1.0 - color.a) * checker.rgb, 1.0);
    }
    if (texCoord != clampedDrawing) {
      vec2 a = vec2(1.0)- abs(texCoord - clampedDrawing) / ((1.0 / scale) / size);
      color *= min(a.x, a.y);
    }
  } else {
    if (color.a < 1.0 && texCoord == clampedDrawing) {
      vec4 checker = checkerAt(texCoord);
      color = vec4(color.rgb + (1.0 - color.a) * checker.rgb, 1.0);
    }
  }
#ifdef GRAYSCALE
  color.rgb = vec3(dot(color.rgb, vec3(0.299, 0.587, 0.114)));
#endif
  gl_FragColor = color;
}`;

export const ellipseShader = `uniform vec2 radius;
uniform vec2 oneByRadiusSq;
varying vec4 vColor;
varying vec4 textureCoord;
#define VAR(INDEX, OFFSET) \
  xs[INDEX] = x + OFFSET;\
  xs[INDEX] = xs[INDEX] * xs[INDEX] * aa;\
  ys[INDEX] = y + OFFSET;\
  ys[INDEX] = ys[INDEX] * ys[INDEX] * bb;
void main() {
  vec2 z = textureCoord.xy;
  float x = z.x;
  float y = z.y;
  float aa = oneByRadiusSq.x;
  float bb = oneByRadiusSq.y;
  float xs[16], ys[16];
  VAR(0, -0.46875);
  VAR(15, 0.46875);
  float p0_0 = xs[0] + ys[0];
  float p0_15 = xs[0] + ys[15];
  float p15_0 = xs[15] + ys[0];
  float p15_15 = xs[15] + ys[15];
  if (p0_0 < 1.0 && p0_15 < 1.0 && p15_0 < 1.0 && p15_15 < 1.0) {
    gl_FragColor = vColor * 1.0;
    return;
  }
  if (radius.x > 10.0 && radius.y > 10.0 && p0_0 > 1.0 && p0_15 > 1.0 && p15_0 > 1.0 && p15_15 > 1.0) {
    gl_FragColor = vec4(0);
    return;
  }
  VAR(1, -0.40625);
  VAR(2, -0.34375);
  VAR(3, -0.28125);
  VAR(4, -0.21875);
  VAR(5, -0.15625);
  VAR(6, -0.09375);
  VAR(7, -0.03125);
  VAR(8, 0.03125);
  VAR(9, 0.09375);
  VAR(10, 0.15625);
  VAR(11, 0.21875);
  VAR(12, 0.28125);
  VAR(13, 0.34375);
  VAR(14, 0.40625);
  float opacity = 0.0;
  for (int iy = 0; iy < 16; iy++) {
    for (int ix = 0; ix < 16; ix++) {
      if ((xs[ix] + ys[iy]) < 1.0) opacity += 0.00390625; 
    }
  }
  gl_FragColor = vColor * opacity;
}`;

export const ellipseOutlineShader = `uniform float lineWidth;
uniform float uniWidth;
uniform float uniHeight;
varying vec4 textureCoord;
varying vec4 vColor;
float GetRoot(float r0, float z0, float z1, float g) {
  float n0 = r0 * z0;
  float s0 = z1 - 1.0;
  float s1 = (g < 0.0 ? 0.0 : sqrt(n0 * n0 + z1 * z1) - 1.0);
  float s = 0.0;
  for (int i = 0; i < 15; ++i) {
   s = (s0 + s1) / 2.0;
    float ratio0 = n0 / (s + r0);
    float ratio1 = z1 / (s + 1.0);
    g = ratio0 * ratio0 + ratio1 * ratio1 - 1.0;
    if (g > 0.0) {
     s0 = s;
    } else {
     s1 = s;
    }
  }
  return (s0 + s1) / 2.0;
}
float distance0(float e0, float e1, float y0, float y1) {
 if (y1 > 0.1) {
    if (y0 > 0.1) {
      float z0 = y0 / e0, z1 = y1 / e1;
      float g = z0 * z0 + z1 * z1 - 1.0;
      float r0 = (e0 * e0) / (e1 * e1);
      float sbar = GetRoot(r0, z0, z1, g);
      float x0 = r0 * y0 / (sbar + r0) - y0;
      float x1 = y1 / (sbar + 1.0) - y1;
      return sqrt(x0 * x0 + x1 * x1);
    } else {
      return abs(y1 - e1);
    }
  } else {
    float numer0 = e0 * y0;
    float denom0 = e0 * e0 - e1 * e1;
    if (numer0 < denom0) {
      float xde0 = numer0 / denom0;
      float x0 = e0 * xde0 - y0;
      float x1 = e1 * sqrt(1.0 - xde0 * xde0);
      return sqrt(x0 * x0 + x1 * x1);
    } else {
      return abs(y0 - e0);
    }
  }
}
float distance(float e0, float e1, float y0, float y1) {
  if (e0 > e1) {
   return distance0(e0, e1, abs(y0), abs(y1));
  } else {
   return distance0(e1, e0, abs(y1), abs(y0));
  }
}
void main() {
  float texCoordMultiplier = textureCoord.w;
  vec2 t = (textureCoord.xy - 0.5) * 2.0 * texCoordMultiplier;
  float x = t.x;
  float y = t.y;
  float e0 = uniWidth / 2.0;
  float e1 = uniHeight / 2.0;
  float y0 = x * e0;
  float y1 = y * e1;
  float halfWidth = lineWidth * 0.5 + 0.5;
  float d = distance(e0 - halfWidth / 2.0, e1 - halfWidth / 2.0, y0, y1);
  d -= halfWidth / 2.0;
  if (d > 0.5) {
   discard;
  }
  float alpha = min(1.0, 0.5 - d);
  gl_FragColor = vColor * alpha;
}`;

export const fastBrushShader = `uniform sampler2D sampler1; 
uniform sampler2D sampler2; 
#ifdef MASK
uniform sampler2D sampler3; 
#endif
uniform float opacity;
uniform vec4 toolColor;
varying vec4 textureCoord;
void main() {
  vec4 src = texture2D(sampler1, textureCoord.zw);
  if (textureCoord.z < 0.0 || textureCoord.z > 1.0 || textureCoord.w < 0.0 || textureCoord.w > 1.0) {
    src = vec4(0, 0, 0, 0);
  }
  vec4 tool = texture2D(sampler2, textureCoord.xy);
  tool *= toolColor;
  #ifdef MASK
  tool *= texture2D(sampler3, textureCoord.xy).a;
  #endif
  vec4 res = tool + (1.0 - tool.a) * src;
  #ifdef OPACITY_LOCKED
  float a = res.a == 0.0 ? 0.0 : (src.a / res.a);
  res.rgb *= a;
  res.a = src.a;
  #endif
  gl_FragColor = res * opacity;
}`;

export const fastEraserShader = `uniform sampler2D sampler1; 
uniform sampler2D sampler2; 
#ifdef MASK
uniform sampler2D sampler3; 
#endif
uniform float opacity;
uniform float toolOpacity;
varying vec4 textureCoord;
void main() {
  vec4 src = texture2D(sampler1, textureCoord.zw);
  if (textureCoord.z < 0.0 || textureCoord.z > 1.0 || textureCoord.w < 0.0 || textureCoord.w > 1.0) {
    src = vec4(0, 0, 0, 0);
  }
  vec4 tool = texture2D(sampler2, textureCoord.xy);
  #ifdef MASK
  tool.a *= texture2D(sampler3, textureCoord.xy).a;
  #endif
  gl_FragColor = src * ((1.0 - tool.a * toolOpacity) * opacity);
}`;

export const fastMoveShader = `uniform sampler2D sampler1;
#ifdef MASK
uniform sampler2D sampler2; 
#endif
uniform vec4 color;
uniform mat2 toolTransform;
uniform vec2 toolMove;
uniform vec2 toolTextureSize;
uniform vec2 maxWidthHeight;
uniform float bicubic;
varying vec4 textureCoord;
float bicubicChannel(vec4 tv, vec4 uv, mat4 S, mat4 T, mat4 Q) {
  mat4 A = T * Q * S;
  return dot(tv * A, uv);
}
vec4 textureBicubic(sampler2D textureSampler, vec2 texCoord, vec2 textureSize) {
  vec2 texelSize = 1.0 / textureSize;
  vec2 pixelCoord = texCoord * textureSize - 0.5;
  vec2 coord = (floor(pixelCoord) + 0.5) / textureSize;
  vec4 f00 = texture2D(textureSampler, coord + texelSize * vec2(-1, -1), -1.0);
  vec4 f01 = texture2D(textureSampler, coord + texelSize * vec2(-1,  0), -1.0);
  vec4 f02 = texture2D(textureSampler, coord + texelSize * vec2(-1,  1), -1.0);
  vec4 f03 = texture2D(textureSampler, coord + texelSize * vec2(-1,  2), -1.0);
  vec4 f10 = texture2D(textureSampler, coord + texelSize * vec2( 0, -1), -1.0);
  vec4 f11 = texture2D(textureSampler, coord + texelSize * vec2( 0,  0), -1.0);
  vec4 f12 = texture2D(textureSampler, coord + texelSize * vec2( 0,  1), -1.0);
  vec4 f13 = texture2D(textureSampler, coord + texelSize * vec2( 0,  2), -1.0);
  vec4 f20 = texture2D(textureSampler, coord + texelSize * vec2( 1, -1), -1.0);
  vec4 f21 = texture2D(textureSampler, coord + texelSize * vec2( 1,  0), -1.0);
  vec4 f22 = texture2D(textureSampler, coord + texelSize * vec2( 1,  1), -1.0);
  vec4 f23 = texture2D(textureSampler, coord + texelSize * vec2( 1,  2), -1.0);
  vec4 f30 = texture2D(textureSampler, coord + texelSize * vec2( 2, -1), -1.0);
  vec4 f31 = texture2D(textureSampler, coord + texelSize * vec2( 2,  0), -1.0);
  vec4 f32 = texture2D(textureSampler, coord + texelSize * vec2( 2,  1), -1.0);
  vec4 f33 = texture2D(textureSampler, coord + texelSize * vec2( 2,  2), -1.0);
  vec4 m0 = f11;
  vec4 m1 = f21;
  vec4 m2 = (f21 - f01) / 2.0;
  vec4 m3 = (f31 - f11) / 2.0;
  vec4 m4 = f12;
  vec4 m5 = f22;
  vec4 m6 = (f22 - f02) / 2.0;
  vec4 m7 = (f32 - f12) / 2.0;
  vec4 m8 = (f12 - f10) / 2.0;
  vec4 m9 = (f22 - f20) / 2.0;
  vec4 m10 = (f22 - f20 - f02 + f00) / 4.0;
  vec4 m11 = (f32 - f30 - f12 + f10) / 4.0;
  vec4 m12 = (f13 - f11) / 2.0;
  vec4 m13 = (f23 - f21) / 2.0;
  vec4 m14 = (f23 - f21 - f03 + f01) / 4.0;
  vec4 m15 = (f33 - f31 - f13 + f11) / 4.0;
  float t = fract(pixelCoord.x);
  float u = fract(pixelCoord.y);
  vec4 tv = vec4(1.0, t, t * t, t * t * t);
  vec4 uv = vec4(1.0, u, u * u, u * u * u);
  mat4 S = mat4(1.0, 0.0,  0.0, 0.0, 0.0, 0.0, 1.0,  0.0, -3.0, 3.0, -2.0, -1.0, 2.0, -2.0,  1.0, 1.0);
  mat4 T = mat4(1.0, 0.0, -3.0, 2.0, 0.0, 0.0, 3.0, -2.0,  0.0, 1.0, -2.0,  1.0, 0.0,  0.0, -1.0, 1.0);
  float r = bicubicChannel(tv, uv, S, T, mat4(m0.r, m1.r, m2.r, m3.r, m4.r, m5.r, m6.r, m7.r, m8.r, m9.r, m10.r, m11.r, m12.r, m13.r, m14.r, m15.r));
  float g = bicubicChannel(tv, uv, S, T, mat4(m0.g, m1.g, m2.g, m3.g, m4.g, m5.g, m6.g, m7.g, m8.g, m9.g, m10.g, m11.g, m12.g, m13.g, m14.g, m15.g));
  float b = bicubicChannel(tv, uv, S, T, mat4(m0.b, m1.b, m2.b, m3.b, m4.b, m5.b, m6.b, m7.b, m8.b, m9.b, m10.b, m11.b, m12.b, m13.b, m14.b, m15.b));
  float a = bicubicChannel(tv, uv, S, T, mat4(m0.a, m1.a, m2.a, m3.a, m4.a, m5.a, m6.a, m7.a, m8.a, m9.a, m10.a, m11.a, m12.a, m13.a, m14.a, m15.a));
  return vec4(r, g, b, a);
}
void main() {
  vec2 coord = textureCoord.xy * toolTransform + toolMove;
  vec4 src;
  if (bicubic == 1.0) {
    src = textureBicubic(sampler1, coord, toolTextureSize);
  } else {
    src = texture2D(sampler1, coord, -1.0); 
  }
  #ifdef MASK
  src *= texture2D(sampler2, coord).a;
  #endif
  if (coord.x < 0.0 || coord.y < 0.0 || coord.x > maxWidthHeight.x || coord.y > maxWidthHeight.y) {
    src = vec4(0, 0, 0, 0);
  }
  gl_FragColor = src * color;
}`;

export const fastNormalShader = `uniform sampler2D sampler1;
#ifdef MASK
uniform sampler2D sampler2; 
#endif
uniform vec4 color;
varying vec4 textureCoord;
void main() {
  vec4 src = texture2D(sampler1, textureCoord.xy);
  #ifdef MASK
  src *= texture2D(sampler2, textureCoord.xy).a;
  #endif
  gl_FragColor = src * color;
}`;

export const gridShader = `uniform float pixelSize;
uniform vec2 size;
uniform float alpha;
varying vec4 textureCoord;
void main() {
  vec2 coord = textureCoord.xy * size;
  vec2 dist = coord - floor(coord);
  vec2 dd = abs(0.5 - dist);
  float a = 1.0 - ((1.0 - max(dd.x, dd.y) * 2.0) * 0.5 / pixelSize);
  gl_FragColor = vec4(alpha * a);
}`;

export const hslShader = `float getLum(vec3 c) {
  return dot(c, vec3(0.3, 0.59, 0.11));
}
float channelMax(vec3 c) {
  return max(c.r, max(c.g, c.b));
}
float channelMin(vec3 c) {
  return min(c.r, min(c.g, c.b));
}
float getSat(vec3 c) {
  return channelMax(c) - channelMin(c);
}
vec3 clipColor(vec3 color, float a) {
  float l = getLum(color);
  float n = channelMin(color);
  float x = channelMax(color);
  if (n < 0.0) {
    float t = l - n;
    if (t == 0.0) {
      color = vec3(0);
    } else {
      color = vec3(l) + (((color - vec3(l)) * l) / t);
    }
  }
  if (x > a) {
    float t = x - l;
    if (t == 0.0) {
      color = vec3(a);
    } else {
      color = vec3(l) + (((color - vec3(l)) * (a - l) / t));
    }
  }
  return color;
}
vec3 setLum(vec3 color, float sa, float lum) {
  float d = lum - getLum(color);
  color += vec3(d);
  return clipColor(color, sa);
}
vec3 processSat(vec3 res, float sat) {
  float t = res.z - res.x;
  if (t == 0.0) {
    res.y = 0.0;
    res.z = 0.0;
  } else {
    res.y = ((res.y - res.x) * sat) / t;
    res.z = sat;
  }
  res.x = 0.0;
  return res;
}
vec3 setSat(vec3 src, float sat) {
  if (src.r > src.g) {
    if (src.r > src.b) {
      if (src.g > src.b) {
        src.bgr = processSat(src.bgr, sat);
      } else {
        src.gbr = processSat(src.gbr, sat);
      }
    } else {
      src.grb = processSat(src.grb, sat);
    }
  }  else {
    if (src.r > src.b) {
      src.brg = processSat(src.brg, sat);
    } else {
      if (src.g > src.b) {
        src.rbg = processSat(src.rbg, sat);
      } else {
        src.rgb = processSat(src.rgb, sat);
      }
    }
  }
  return src;
}`;

export const hueSaturationLightnessShader = `precision highp float;
uniform sampler2D sampler1;
uniform vec2 size;
uniform float hue;
uniform float saturation;
uniform float lightness;
const float EPSILON = 1e-10;
vec3 HUEtoRGB(float hue) {
  vec3 rgb = abs(hue * 6. - vec3(3, 2, 4)) * vec3(1, -1, -1) + vec3(-1, 2, 2);
  return clamp(rgb, 0., 1.);
}
vec3 RGBtoHCV(vec3 rgb) {
  vec4 p = (rgb.g < rgb.b) ? vec4(rgb.bg, -1., 2. / 3.) : vec4(rgb.gb, 0., -1. / 3.);
  vec4 q = (rgb.r < p.x) ? vec4(p.xyw, rgb.r) : vec4(rgb.r, p.yzx);
  float c = q.x - min(q.w, q.y);
  float h = abs((q.w - q.y) / (6. * c + EPSILON) + q.z);
  return vec3(h, c, q.x);
}
float HueToRGB(float f1, float f2, float hue) {
  if (hue < 0.0)
    hue += 1.0;
  else if (hue > 1.0)
    hue -= 1.0;
  float res;
  if ((6.0 * hue) < 1.0)
    res = f1 + (f2 - f1) * 6.0 * hue;
  else if ((2.0 * hue) < 1.0)
    res = f2;
  else if ((3.0 * hue) < 2.0)
    res = f1 + (f2 - f1) * ((2.0 / 3.0) - hue) * 6.0;
  else
    res = f1;
  return res;
}
vec3 HSLtoRGB(vec3 hsl) {
  vec3 rgb;
  if (hsl.y == 0.0) {
    rgb = vec3(hsl.z); 
  } else {
    float f2;
    if (hsl.z < 0.5)
      f2 = hsl.z * (1.0 + hsl.y);
    else
      f2 = (hsl.z + hsl.y) - (hsl.y * hsl.z);
    float f1 = 2.0 * hsl.z - f2;
    rgb.r = HueToRGB(f1, f2, hsl.x + (1.0/3.0));
    rgb.g = HueToRGB(f1, f2, hsl.x);
    rgb.b= HueToRGB(f1, f2, hsl.x - (1.0/3.0));
  }
  return rgb;
}
vec3 RGBtoHSL(vec3 color) {
  vec3 hsl; 
  float fmin = min(min(color.r, color.g), color.b);    
  float fmax = max(max(color.r, color.g), color.b);    
  float delta = fmax - fmin;             
  hsl.z = (fmax + fmin) / 2.0; 
  if (delta == 0.0) {   
    hsl.x = 0.0;  
    hsl.y = 0.0;  
  } else {                                   
    if (hsl.z < 0.5)
      hsl.y = delta / (fmax + fmin); 
    else
      hsl.y = delta / (2.0 - fmax - fmin); 
    float deltaR = (((fmax - color.r) / 6.0) + (delta / 2.0)) / delta;
    float deltaG = (((fmax - color.g) / 6.0) + (delta / 2.0)) / delta;
    float deltaB = (((fmax - color.b) / 6.0) + (delta / 2.0)) / delta;
    if (color.r == fmax )
      hsl.x = deltaB - deltaG; 
    else if (color.g == fmax)
      hsl.x = (1.0 / 3.0) + deltaR - deltaB; 
    else if (color.b == fmax)
      hsl.x = (2.0 / 3.0) + deltaG - deltaR; 
    if (hsl.x < 0.0)
      hsl.x += 1.0; 
    else if (hsl.x > 1.0)
      hsl.x -= 1.0; 
  }
  return hsl;
}
vec3 BlendLuminosity(vec3 base, vec3 blend) {
  vec3 baseHSL = RGBtoHSL(base);
  return HSLtoRGB(vec3(baseHSL.r, baseHSL.g, RGBtoHSL(blend).b));
}
void main() {
  vec4 Color = texture2D(sampler1, (gl_FragCoord.xy) /size.xy);
  vec3 fragRGB = Color.rgb;
  vec3 fragHSL = RGBtoHSL(fragRGB);
  float h = hue / 360.0;
  float s = saturation / 100.0;
  if (saturation < 0.0) {
    s = fragHSL.y * s;
  } else {
    s = (1.0 - fragHSL.y) * s;
  }
  fragHSL.x += h;
  fragHSL.x = mod(fragHSL.x, 1.0);
  if (fragHSL.y != 0.0) { 
    fragHSL.y += s;
  }
  vec4 finalColor = vec4(HSLtoRGB(fragHSL), Color.a);
  float l = lightness / 100.0;
  vec3 lightnessVec = vec3(0.0);
  if (lightness < 0.0) {
    lightnessVec = finalColor.rgb * l;
  } else {
    lightnessVec = (vec3(Color.a) - finalColor.rgb) * l;
  }
  gl_FragColor = vec4((finalColor.rgb + lightnessVec), finalColor.a);
}`;

export const imageBrushShader = `uniform sampler2D sampler1;
varying vec4 vColor;
varying vec4 textureCoord;
uniform float biasForBrush;
void main() {
  float alpha = texture2D(sampler1, textureCoord.xy, biasForBrush).a; 
  #ifdef OPACITY
  float result = textureCoord.w * alpha;
  gl_FragColor = vec4(result);
  #else
  vec4 result = vColor * alpha;
  gl_FragColor = result;
  #endif
}`;

export const imageBrushCursorShader = `uniform sampler2D sampler1;
uniform sampler2D sampler2;
varying vec4 textureCoord;
uniform vec2 textureSize;
uniform vec2 position;
uniform float drawingLoD;
uniform vec2 brushTextureSize;
uniform vec2 scale;
uniform vec4 backgroundColor;
uniform bool flipX;
uniform bool flipY;
uniform float angle;
float getEdge(float tLeft, float tRight, float tTop, float tBottom) {
  float x = tRight - tLeft;
  float y = tBottom - tTop;
  return sqrt(x*x + y*y);
}
float getpX(vec2 coord, vec2 offset) {
  float treshhold = 0.5;
  vec2 onePixelBrush = vec2(1.0 / (brushTextureSize.x * scale.x), 1.0 / (brushTextureSize.y * scale.y));
  return step(texture2D(sampler2, coord + offset * onePixelBrush).a, treshhold);
}
void main() {
  vec4 cursorColor = vec4(1.0, 1.0, 1.0, 1.0);
  vec4 invertedColor = vec4(0.0, 0.0,0.0, 1.0);
  vec2 onePixelBackground = vec2(1.0) / textureSize;
  vec2 backgroundCoord = vec2(position.x, position.y) * onePixelBackground;
  vec4 fragColor = texture2D(sampler1, backgroundCoord, 6.0 - drawingLoD) * backgroundColor;
  if (fragColor.r + fragColor.g + fragColor.b > 0.4) {
    cursorColor = vec4(0.0, 0.0, 0.0, 1.0);
    invertedColor = vec4(1.0, 1.0, 1.0, 1.0);
  }
  vec2 center = vec2(0.5, 0.5);
  vec2 pos = textureCoord.xy - center;
  pos.x = flipX ? -pos.x : pos.x;
  pos.y = flipY ? -pos.y : pos.y;
  float rotatedX = (pos.x * cos(-angle) - pos.y * sin(-angle));
  float rotatedY = (pos.x * sin(-angle) + pos.y * cos(-angle));
  vec2 rotatedPos;
  rotatedPos.x = rotatedX;
  rotatedPos.y = rotatedY;
  vec2 rotatedCoord = rotatedPos + center;
  float edge = 0.0;
  float p0 = getpX(rotatedCoord, vec2(-1.0, -1.0));
  float p1 = getpX(rotatedCoord, vec2(0.0, -1.0));
  float p2 = getpX(rotatedCoord, vec2(1.0, -1.0));
  float p3 = getpX(rotatedCoord, vec2(-1.0, 0.0));
  float p4 = getpX(rotatedCoord, vec2(0.0, 0.0));
  float p5 = getpX(rotatedCoord, vec2(1.0, 0.0));
  float p6 = getpX(rotatedCoord, vec2(-1.0, 1.0));
  float p7 = getpX(rotatedCoord, vec2(0.0, 1.0));
  float p8 = getpX(rotatedCoord, vec2(1.0, 1.0));
  edge += getEdge(p0, p1, p0, p3);
  edge += getEdge(p0, p2, p1, p4);
  edge += getEdge(p1, p2, p2, p5);
  edge += getEdge(p3, p4, p0, p6);
  edge += getEdge(p3, p5, p1, p7);
  edge += getEdge(p4, p5, p2, p8);
  edge += getEdge(p6, p7, p3, p6);
  edge += getEdge(p6, p8, p4, p7);
  edge += getEdge(p7, p8, p5, p8);
  float edgeInverse = 0.0;
  float p0Inv = getpX(rotatedCoord, vec2(-2.0, -2.0));
  float p1Inv = getpX(rotatedCoord, vec2(0.0, -2.0));
  float p2Inv = getpX(rotatedCoord, vec2(2.0, -2.0));
  float p3Inv = getpX(rotatedCoord, vec2(-2.0, 0.0));
  float p4Inv = p4;
  float p5Inv = getpX(rotatedCoord, vec2(2.0, 0.0));
  float p6Inv = getpX(rotatedCoord, vec2(-2.0, 2.0));
  float p7Inv = getpX(rotatedCoord, vec2(0.0, 2.0));
  float p8Inv = getpX(rotatedCoord, vec2(2.0, 2.0));
  edgeInverse += getEdge(p0Inv, p1Inv, p0Inv, p3Inv);
  edgeInverse += getEdge(p0Inv, p2Inv, p1Inv, p4Inv);
  edgeInverse += getEdge(p1Inv, p2Inv, p2Inv, p5Inv);
  edgeInverse += getEdge(p3Inv, p4Inv, p0Inv, p6Inv);
  edgeInverse += getEdge(p3Inv, p5Inv, p1Inv, p7Inv);
  edgeInverse += getEdge(p4Inv, p5Inv, p2Inv, p8Inv);
  edgeInverse += getEdge(p6Inv, p7Inv, p3Inv, p6Inv);
  edgeInverse += getEdge(p6Inv, p8Inv, p4Inv, p7Inv);
  edgeInverse += getEdge(p7Inv, p8Inv, p5Inv, p8Inv);
  vec4 firstColor = vec4(cursorColor * edge);
  vec4 secondColor = vec4(invertedColor * edgeInverse);
  if (firstColor.a < 0.5) {
      gl_FragColor = vec4(secondColor.r, secondColor.g, secondColor.b, secondColor.a) * 0.7;
  } else {
      gl_FragColor = firstColor;
  }
}`;

export const lineShader = `varying vec4 vColor;
varying vec4 textureCoord;
void main() {
  float alpha = clamp(textureCoord.y + 0.5 - abs(textureCoord.x), 0.0, 1.0);
  gl_FragColor = vColor * alpha;
}`;

export const maskShader = `uniform sampler2D sampler1; 
uniform sampler2D sampler2; 
uniform float reverse;
varying vec4 textureCoord;
void main() {
  vec4 src = texture2D(sampler1, textureCoord.zw);
  if (textureCoord.z < 0.0 || textureCoord.z > 1.0 || textureCoord.w < 0.0 || textureCoord.w > 1.0) {
    src = vec4(0, 0, 0, 0);
  }
  vec4 mask = texture2D(sampler2, textureCoord.xy);
  float maskA = reverse == 0.0 ? mask.a : (1.0 - mask.a);
  float a = min(src.a, maskA);
  if (src.a > 0.0) src.rgb *= a / src.a;
  src.a = a;
  gl_FragColor = src;
}`;

export const maskPremultiplyShader = `uniform sampler2D sampler1; 
uniform sampler2D sampler2; 
uniform float reverse;
varying vec4 textureCoord;
void main() {
  vec4 src = texture2D(sampler1, textureCoord.zw);
  if (textureCoord.z < 0.0 || textureCoord.z > 1.0 || textureCoord.w < 0.0 || textureCoord.w > 1.0) {
    src = vec4(0, 0, 0, 0);
  }
  vec4 mask = texture2D(sampler2, textureCoord.xy);
  float maskA = reverse == 0.0 ? mask.a : (1.0 - mask.a);
  src.a = min(src.a, maskA);
  src.rgb *= src.a;
  gl_FragColor = src;
}`;

export const maskUnpremultiplyShader = `uniform sampler2D sampler1; 
uniform sampler2D sampler2; 
uniform float reverse;
varying vec4 textureCoord;
void main() {
  vec4 src = texture2D(sampler1, textureCoord.zw);
  if (textureCoord.z < 0.0 || textureCoord.z > 1.0 || textureCoord.w < 0.0 || textureCoord.w > 1.0) {
    src = vec4(0, 0, 0, 0);
  }
  vec4 mask = texture2D(sampler2, textureCoord.xy);
  float maskA = reverse == 0.0 ? mask.a : (1.0 - mask.a);
  float a = min(src.a, maskA);
  if (src.a != 0.0) src.rgb /= src.a;
  src.a = a;
  gl_FragColor = src;
}`;

export const meshShader = `uniform sampler2D sampler1;
varying vec2 vTexCoord;
varying vec3 vNormal;
void main() {
  vec4 color = vec4(1, 1, 1, 1);
  vec3 ambient = vec3(0.2, 0.2, 0.2); 
  vec3 diffuse = vec3(1, 1, 1) - ambient; 
  vec3 N = normalize(vNormal);
  vec3 L = normalize(-vec3(-1, -1, 1));
  vec3 light = ambient + diffuse * max(dot(N, L), 0.0);
  color.rgb *= light;
  gl_FragColor = color;
}`;

export const motionBlurShader = `uniform sampler2D sampler1;
uniform float radius;
uniform vec2 direction;
uniform vec2 size;
void main() {
  if (radius == 0.0) {
    gl_FragColor = texture2D(sampler1, (gl_FragCoord.xy) /size.xy);
    return;
   }
  vec4 sum = texture2D(sampler1, (gl_FragCoord.xy) /size.xy);
  float coeffsSum = 1.0;
  float count = radius + 1.0;
  bool markPositiveXAsDone = false;
  bool markPositiveYAsDone = false;
  bool markNegativeXAsDone = false;
  bool markNegativeYAsDone = false;
  for (int i = 0; i < 2001; i++) {
    if (float(i) > count) {
     break;
    }
    vec2 offset = direction * float(i);
    if ((offset.x >= 0.0) && ((gl_FragCoord.x + offset.x) > size.x)) {
      markPositiveXAsDone = true;
    } else if ((offset.x < 0.0) && ((gl_FragCoord.x - offset.x) > size.x)) {
      markNegativeXAsDone = true;
    }
    if ((offset.y >= 0.0) && ((gl_FragCoord.y + offset.y) > size.y)) {
      markPositiveYAsDone = true;
    } else if ((offset.y < 0.0) && ((gl_FragCoord.y - offset.y) > size.y)) {
      markNegativeYAsDone = true;
    }
    if ((offset.x >= 0.0) && ((gl_FragCoord.x - offset.x) < 0.0)) {
      markNegativeXAsDone = true;
    } else if ((offset.x < 0.0) && ((gl_FragCoord.x + offset.x) < 0.0)) {
      markPositiveXAsDone = true;
    }
    if ((offset.y >= 0.0) && ((gl_FragCoord.y - offset.y) < 0.0)) {
      markNegativeYAsDone = true;
    } else if ((offset.y < 0.0) && ((gl_FragCoord.y + offset.y) < 0.0)) {
      markPositiveYAsDone = true;
    }
    if (!markPositiveXAsDone && !markPositiveYAsDone) {
      sum += texture2D(sampler1, (gl_FragCoord.xy + vec2(offset.x, offset.y)) /size.xy);
      coeffsSum += 1.0;
    }
    if (!markNegativeXAsDone && !markNegativeYAsDone) {
      sum += texture2D(sampler1, (gl_FragCoord.xy - vec2(offset.x, offset.y)) /size.xy);
      coeffsSum += 1.0;
    }
  }
  gl_FragColor = sum/coeffsSum;
}`;

export const perspectiveGridLineShader = `varying vec4 vColor;
varying vec4 textureCoord;
uniform vec4 bounds1;
uniform vec4 bounds2;
uniform vec4 bounds3;
uniform vec4 bounds4;
uniform vec4 horizonDir;
uniform vec2 resolution;
uniform float fog;
void main() {
  vec2 p = vec2(gl_FragCoord.x, resolution.y-gl_FragCoord.y);
  vec3 d1 = cross(vec3(bounds1.zw - bounds1.xy, 0.0), vec3(p - bounds1.zw, 0.0));
  vec3 d2 = cross(vec3(bounds2.xy - bounds1.zw, 0.0), vec3(p - bounds1.zw, 0.0));
  vec3 d3 = cross(vec3(bounds2.zw - bounds2.xy, 0.0), vec3(p - bounds2.xy, 0.0));
  vec3 d4 = cross(vec3(bounds1.xy - bounds2.zw, 0.0), vec3(p - bounds2.zw, 0.0));
  vec3 d5 = cross(vec3(bounds3.zw - bounds3.xy, 0.0), vec3(p - bounds3.zw, 0.0));
  vec3 d6 = cross(vec3(bounds4.xy - bounds3.zw, 0.0), vec3(p - bounds3.zw, 0.0));
  vec3 d7 = cross(vec3(bounds4.zw - bounds4.xy, 0.0), vec3(p - bounds4.xy, 0.0));
  vec3 d8 = cross(vec3(bounds3.xy - bounds4.zw, 0.0), vec3(p - bounds4.zw, 0.0));
  vec4 s = vec4(
    max(sign(d1.z), 0.0), max(sign(d2.z), 0.0),
    max(sign(d3.z), 0.0), max(sign(d4.z), 0.0));
  vec4 s2 = vec4(
    max(sign(d5.z), 0.0), max(sign(d6.z), 0.0),
    max(sign(d7.z), 0.0), max(sign(d8.z), 0.0));
  float fogCoeff= clamp(abs(dot(p-horizonDir.zw, horizonDir.xy))/fog, 0.0, 1.0);
  float alpha = clamp(textureCoord.y + 0.5 - abs(textureCoord.x), 0.0, 1.0);
  gl_FragColor = vec4(vColor.rgb, vColor.a*fogCoeff)*alpha*s.x*s.y*s.z*s.w*s2.x*s2.y*s2.z*s2.w;
}`;

export const rectOutlineShader = `uniform float pixelSize;
varying vec4 vColor;
varying vec4 textureCoord;
void main() {
  vec2 texCoord = textureCoord.xy;
  vec2 delta = min(abs(texCoord), abs(vec2(1.0) - texCoord));
  float d = min(delta.x, delta.y);
  float a = 1.0 - d / pixelSize;
  gl_FragColor = vColor * a; 
}`;

export const softBrush1Shader = `uniform vec4 color;
varying vec4 textureCoord;
void main() {
  vec2 t = (textureCoord.xy - 0.5) * 2.0;
  float d = sqrt(dot(t, t));
  float a = clamp(1.0 - d, 0.0, 1.0);
  float alpha = pow(a, 3.0);
  gl_FragColor = color * alpha; 
}`;

export const softBrush2Shader = `varying vec4 vColor;
varying vec4 textureCoord;
float ease(float x, float h) {
  if (x > 1.0) return 0.0;
  if (x < h) return 1.0;
  x = ((x - h) / (1.0 - h)) * 2.0;
  return 1.0 - (x < 1.0 ? (0.5 * x * x) : (-0.5 * ((x - 1.0) * (x - 3.0) - 1.0)));
}
void main() {
  float dist = sqrt(dot(textureCoord.xy, textureCoord.xy));
  float hardness = textureCoord.z;
  float alpha = ease(dist, hardness);
  #ifdef OPACITY
  float result = textureCoord.w * alpha;
  gl_FragColor = vec4(result);
  #else
  vec4 result = vColor * alpha; 
  gl_FragColor = result;
  #endif
}`;

export const solidColorShader = `uniform vec4 color;
void main() {
  gl_FragColor = color;
}`;

export const spriteShader = `uniform sampler2D sampler1;
varying vec4 vColor;
varying vec4 textureCoord;
void main() {
  vec4 color = texture2D(sampler1, textureCoord.xy);
  gl_FragColor = color * vColor;
}`;

export const testShader = `varying vec4 textureCoord;
void main() {
  gl_FragColor = vec4(textureCoord.xy, 0.0, 1.0);
}`;

export const toolShader = `#define ZERO vec3(0, 0, 0)
#define ONE vec3(1, 1, 1)
#define HALF vec3(0.5, 0.5, 0.5)
uniform sampler2D sampler1; 
uniform sampler2D sampler2; 
#ifdef TOOL
  uniform sampler2D sampler3; 
  #ifdef MASKS
    uniform sampler2D sampler4; 
  #endif
  uniform vec4 toolColor;
  uniform mat2 toolTransform;
  uniform vec2 toolMove;
  uniform vec2 maxWidthHeight;
  uniform vec2 toolTextureSize;
  uniform float srcMul;
  uniform float toolOpacity;
  uniform float lockOpacity;
  uniform float bicubic;
#endif
uniform float isClipped;
uniform float opacity;
uniform float baseOpacity;
varying vec4 textureCoord;
varying vec4 vColor;
float bicubicChannel(vec4 tv, vec4 uv, mat4 S, mat4 T, mat4 Q) {
  mat4 A = T * Q * S;
  return dot(tv * A, uv);
}
vec4 textureBicubic(sampler2D textureSampler, vec2 texCoord, vec2 textureSize) {
  vec2 texelSize = 1.0 / textureSize;
  vec2 pixelCoord = texCoord * textureSize - 0.5;
  vec2 coord = (floor(pixelCoord) + 0.5) / textureSize;
  vec4 f00 = texture2D(textureSampler, coord + texelSize * vec2(-1, -1), -1.0);
  vec4 f01 = texture2D(textureSampler, coord + texelSize * vec2(-1,  0), -1.0);
  vec4 f02 = texture2D(textureSampler, coord + texelSize * vec2(-1,  1), -1.0);
  vec4 f03 = texture2D(textureSampler, coord + texelSize * vec2(-1,  2), -1.0);
  vec4 f10 = texture2D(textureSampler, coord + texelSize * vec2( 0, -1), -1.0);
  vec4 f11 = texture2D(textureSampler, coord + texelSize * vec2( 0,  0), -1.0);
  vec4 f12 = texture2D(textureSampler, coord + texelSize * vec2( 0,  1), -1.0);
  vec4 f13 = texture2D(textureSampler, coord + texelSize * vec2( 0,  2), -1.0);
  vec4 f20 = texture2D(textureSampler, coord + texelSize * vec2( 1, -1), -1.0);
  vec4 f21 = texture2D(textureSampler, coord + texelSize * vec2( 1,  0), -1.0);
  vec4 f22 = texture2D(textureSampler, coord + texelSize * vec2( 1,  1), -1.0);
  vec4 f23 = texture2D(textureSampler, coord + texelSize * vec2( 1,  2), -1.0);
  vec4 f30 = texture2D(textureSampler, coord + texelSize * vec2( 2, -1), -1.0);
  vec4 f31 = texture2D(textureSampler, coord + texelSize * vec2( 2,  0), -1.0);
  vec4 f32 = texture2D(textureSampler, coord + texelSize * vec2( 2,  1), -1.0);
  vec4 f33 = texture2D(textureSampler, coord + texelSize * vec2( 2,  2), -1.0);
  vec4 m0 = f11;
  vec4 m1 = f21;
  vec4 m2 = (f21 - f01) / 2.0;
  vec4 m3 = (f31 - f11) / 2.0;
  vec4 m4 = f12;
  vec4 m5 = f22;
  vec4 m6 = (f22 - f02) / 2.0;
  vec4 m7 = (f32 - f12) / 2.0;
  vec4 m8 = (f12 - f10) / 2.0;
  vec4 m9 = (f22 - f20) / 2.0;
  vec4 m10 = (f22 - f20 - f02 + f00) / 4.0;
  vec4 m11 = (f32 - f30 - f12 + f10) / 4.0;
  vec4 m12 = (f13 - f11) / 2.0;
  vec4 m13 = (f23 - f21) / 2.0;
  vec4 m14 = (f23 - f21 - f03 + f01) / 4.0;
  vec4 m15 = (f33 - f31 - f13 + f11) / 4.0;
  float t = fract(pixelCoord.x);
  float u = fract(pixelCoord.y);
  vec4 tv = vec4(1.0, t, t * t, t * t * t);
  vec4 uv = vec4(1.0, u, u * u, u * u * u);
  mat4 S = mat4(1.0, 0.0,  0.0, 0.0, 0.0, 0.0, 1.0,  0.0, -3.0, 3.0, -2.0, -1.0, 2.0, -2.0,  1.0, 1.0);
  mat4 T = mat4(1.0, 0.0, -3.0, 2.0, 0.0, 0.0, 3.0, -2.0,  0.0, 1.0, -2.0,  1.0, 0.0,  0.0, -1.0, 1.0);
  float r = bicubicChannel(tv, uv, S, T, mat4(m0.r, m1.r, m2.r, m3.r, m4.r, m5.r, m6.r, m7.r, m8.r, m9.r, m10.r, m11.r, m12.r, m13.r, m14.r, m15.r));
  float g = bicubicChannel(tv, uv, S, T, mat4(m0.g, m1.g, m2.g, m3.g, m4.g, m5.g, m6.g, m7.g, m8.g, m9.g, m10.g, m11.g, m12.g, m13.g, m14.g, m15.g));
  float b = bicubicChannel(tv, uv, S, T, mat4(m0.b, m1.b, m2.b, m3.b, m4.b, m5.b, m6.b, m7.b, m8.b, m9.b, m10.b, m11.b, m12.b, m13.b, m14.b, m15.b));
  float a = bicubicChannel(tv, uv, S, T, mat4(m0.a, m1.a, m2.a, m3.a, m4.a, m5.a, m6.a, m7.a, m8.a, m9.a, m10.a, m11.a, m12.a, m13.a, m14.a, m15.a));
  return vec4(r, g, b, a);
}
vec4 alphaBlendOperator(vec4 src, vec4 dst, vec3 blnd) {
  vec4 result = vec4(0, 0, 0, src.a + (1.0 - src.a) * dst.a);
  result.rgb = ((1.0 - dst.a) * src.rgb
             + (1.0 - src.a) * dst.rgb
             + src.a * dst.a * blnd);
  return result;
}
vec3 getRGB(vec4 color) {
  float a = color.a == 0.0 ? 0.0 : (1.0 / color.a);
  return color.rgb * a;
}
DECL_HERE
#ifdef TOOL
vec4 blendTool(vec4 src, vec4 dst) {
  return src * srcMul + (1.0 - src.a) * dst;
}
#endif
void main() {
  vec4 tex = texture2D(sampler1, textureCoord.zw);
  if (textureCoord.z < 0.0 || textureCoord.z > 1.0 || textureCoord.w < 0.0 || textureCoord.w > 1.0) {
    tex = vec4(0, 0, 0, 0);
  }
#ifdef TOOL
  vec2 coord = textureCoord.xy * toolTransform + toolMove;
  vec4 tool;
  if (bicubic == 1.0) {
    tool = textureBicubic(sampler3, coord, toolTextureSize);
  } else {
    tool = texture2D(sampler3, coord, -1.0); 
  }
  float maskAlpha = 1.0;
  #ifdef MASKS
  maskAlpha *= texture2D(sampler4, coord).a;
  #endif
  if (coord.x < 0.0 || coord.y < 0.0 || coord.x > maxWidthHeight.x || coord.y > maxWidthHeight.y) {
    tool = vec4(0, 0, 0, 0);
    maskAlpha = 0.0;
  }
  tool *= maskAlpha * toolOpacity;
  vec4 src = blendTool(tool * toolColor, tex);
  if (lockOpacity == 1.0) {
    float a = src.a == 0.0 ? 0.0 : (tex.a / src.a);
    src.rgb *= a;
    src.a = tex.a;
  }
#else
  vec4 src = tex;
#endif
  vec4 dst = texture2D(sampler2, vColor.xy) * baseOpacity;
  if (vColor.x < 0.0 || vColor.x > 1.0 || vColor.y < 0.0 || vColor.y > 1.0) {
    dst = vec4(0, 0, 0, 0);
  }
  CODE_HERE
  if (isClipped == 1.0) {
    float a = blended.a == 0.0 ? 0.0 : (dst.a / blended.a);
    blended.rgb *= a;
    blended.a = dst.a;
  }
  gl_FragColor = blended;
}`;

export const unpremultiplyShader = `uniform sampler2D sampler1;
varying vec4 textureCoord;
void main() {
  vec4 color = texture2D(sampler1, textureCoord.xy);
  if (color.a != 0.0) {
    color.rgb /= color.a;
  }
  gl_FragColor = color;
}`;

export const vertexShader = `attribute vec2 position;
attribute vec4 texcoords;
attribute vec4 vertexColor;
uniform mat4 transform;
varying vec4 textureCoord;
varying vec4 vColor;
void main() {
  textureCoord = texcoords;
  vColor = vertexColor;
  gl_Position = transform * vec4(position, 0, 1);
}`;

export const vertexColorShader = `varying vec4 vColor;
void main() {
  gl_FragColor = vColor;
}`;

export const vertexMeshShader = `attribute vec3 position;
attribute vec3 vertexNormal;
attribute vec2 texcoord;
uniform mat4 model;
uniform mat4 viewProj;
varying vec2 vTexCoord;
varying vec3 vNormal;
void main() {
  vTexCoord = texcoord;
  vNormal = mat3(model) * vertexNormal;
  gl_Position = viewProj * model * vec4(position, 1);
}`;
