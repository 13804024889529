import {
  ApplicationRef, ChangeDetectionStrategy, Component, ComponentFactory, ComponentFactoryResolver, ComponentRef,
  Directive, ElementRef, Injectable, Injector, Input, NgZone, OnDestroy, TemplateRef, Type, ViewContainerRef, ViewRef,
  EventEmitter, Output
} from '@angular/core';
import { PositioningService } from '../../../services/positioning';

interface ContentRef {
  nodes: any[];
  viewRef?: ViewRef;
  componentRef?: ComponentRef<any>;
}

export interface TooltipContainer {
  className: string;
  data: any;
}

@Injectable({ providedIn: 'any' })
export class TooltipService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private zone: NgZone,
    private injector: Injector,
    private applicationRef: ApplicationRef,
    private positionService: PositioningService,
  ) {
  }
  create(elementRef: ElementRef<HTMLElement>, viewContainerRef: ViewContainerRef, container: any = AgTooltipContainer): TooltipInstance {
    return new TooltipInstance(elementRef, viewContainerRef, this.componentFactoryResolver, this.zone, this.injector,
      this.applicationRef, this.positionService, container);
  }
}

@Directive({
  selector: '[tooltip]',
  exportAs: 'ag-tooltip'
})
export class AgTooltip implements OnDestroy {
  static DEFAULT_CONTAINER = 'body';
  @Input('tooltip') get tooltip() { return this.tip.tooltip; } set tooltip(value) { this.tip.tooltip = value; }
  @Input() get adaptivePosition() { return this.tip.adaptivePosition; } set adaptivePosition(value) { this.tip.adaptivePosition = value; }
  @Input() get placement() { return this.tip.placement; } set placement(value) { this.tip.placement = value; }
  @Input() get container() { return this.tip.container; } set container(value) { this.tip.container = value; }
  @Input() get containerClass() { return this.tip.containerClass; } set containerClass(value) { this.tip.containerClass = value; }
  @Input() get boundariesElement() { return this.tip.boundariesElement; } set boundariesElement(value) { this.tip.boundariesElement = value; }
  @Input() get isDisabled() { return this.tip.isDisabled; } set isDisabled(value) { this.tip.isDisabled = value; }
  @Input() get delay() { return this.tip.delay; } set delay(value) { this.tip.delay = value; }
  @Input() get triggers() { return this.tip.triggers; } set triggers(value) { this.tip.triggers = value; }
  @Input() get isOpen() { return this.tip.isOpen; } set isOpen(value) { this.tip.isOpen = value; }
  @Input() get keepOpenWhenHovered() { return this.tip.keepOpenWhenHovered; } set keepOpenWhenHovered(value) { this.tip.keepOpenWhenHovered = value; }
  @Output() isOpenChange = new EventEmitter<boolean>();
  private tip: TooltipInstance;
  constructor(elementRef: ElementRef<HTMLElement>, viewContainerRef: ViewContainerRef, tooltipService: TooltipService) {
    this.tip = tooltipService.create(elementRef, viewContainerRef);
    this.tip.isOpenChange = () => {
      this.isOpenChange.emit(this.tip.isOpen);
    };
  }
  ngOnDestroy() {
    this.tip.destroy();
  }
  toggle() {
    this.tip.toggle();
  }
  show() {
    this.tip.show();
  }
  hide() {
    this.tip.hide();
  }
}

@Component({
  selector: 'ag-tooltip-container',
  template: `<div class="tooltip-arrow arrow"></div><div class="tooltip-inner"><ng-content></ng-content></div>`,
  styles: [`:host.tooltip { display: block; }`],
  host: {
    '[class]': 'className',
    'role': 'tooltip',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgTooltipContainer implements TooltipContainer {
  className = '';
  data: any = undefined;
}

export const DEFAULT_TOOLTIP_PLACEMENT = 'top';
export class TooltipInstance {
  adaptivePosition = true;
  placement = DEFAULT_TOOLTIP_PLACEMENT; // "top" | "bottom" | "left" | "right"
  container: string | ElementRef | undefined = AgTooltip.DEFAULT_CONTAINER;
  containerClass = 'tooltip-default';
  boundariesElement: 'viewport' | 'scrollParent' | 'window' = 'scrollParent';
  delay = 0;
  closeDelay = 0;
  keepOpenWhenHovered = false;
  data: any = undefined;
  isOpenChange = () => { };
  beforeShow = () => { };
  private _tooltip: string | TemplateRef<unknown> | undefined = undefined;
  get tooltip() {
    return this._tooltip;
  }
  set tooltip(value) {
    if (this._tooltip !== value) {
      this._tooltip = value;

      if (!value) {
        this.clearDelayTimeout();
        this.hideTooltip();
      } else if (this.isOpen) {
        // re-open with new content
        this.clearDelayTimeout();
        this.hideTooltip();
        this.showTooltip();
      }
    }
  }
  private _isDisabled = false;
  get isDisabled() {
    return this._isDisabled;
  }
  set isDisabled(value) {
    if (this._isDisabled !== value) {
      this._isDisabled = value;

      if (value) {
        this.clearDelayTimeout();
        this.hideTooltip();
      }
    }
  }
  private _triggers = 'hover';
  get triggers() {
    return this._triggers;
  }
  set triggers(value) {
    if (this._triggers !== value) {
      this.updateTriggers(value);
    }
  }
  get isOpen() {
    return !!this.componentRef;
  }
  set isOpen(value) {
    if (value) {
      this.show();
    } else {
      this.hide();
    }
  }
  // TODO: isOpenChange
  private componentRef: ComponentRef<TooltipContainer> | undefined = undefined;
  private componentFactory: ComponentFactory<TooltipContainer> | undefined = undefined;
  private contentRef: ContentRef | undefined = undefined;
  private lastTouch = 0;
  private delayTimeout: any = 0;
  private hideDelayTimeout: any = 0;
  private interval: any = 0;
  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private zone: NgZone,
    private injector: Injector,
    private applicationRef: ApplicationRef,
    private positionService: PositioningService,
    private containerType: Type<TooltipContainer>
  ) {
    this.updateTriggers(this.triggers);
  }
  destroy() {
    this.clearDelayTimeout();
    this.hideTooltip();
  }
  toggle() {
    if (this.isOpen) {
      this.hide();
    } else {
      this.show();
    }
  }
  show() {
    if (this.isOpen || this.isDisabled || this.delayTimeout || !this.tooltip) return;

    this.clearDelayTimeout();

    if (this.delay) {
      this.delayTimeout = setTimeout(this.showTooltip, this.delay);
    } else {
      this.showTooltip();
    }
  }
  hide() {
    if (this.hideDelayTimeout) return;

    this.clearDelayTimeout();

    if (!this.componentRef) return;

    if (this.closeDelay) {
      this.hideDelayTimeout = setTimeout(this.hideTooltip, this.closeDelay);
    } else {
      this.hideTooltip();
    }
  }
  private updateTriggers(triggers: string) {
    this.zone.runOutsideAngular(() => {
      this.setupTriggers(this.elementRef.nativeElement, this._triggers, triggers);
      this._triggers = triggers;
    });
  }
  private setupTriggers(e: HTMLElement, oldTriggers: string, newTriggers: string) {
    switch (oldTriggers) {
      case 'hover':
        if (typeof PointerEvent !== 'undefined') {
          e.removeEventListener('pointerdown', this.pointerdown);
          e.removeEventListener('pointerleave', this.pointerleave);
          e.removeEventListener('pointermove', this.pointermove);
        } else {
          e.removeEventListener('touchstart', this.touchstart);
          e.removeEventListener('touchend', this.touchend);
          e.removeEventListener('touchcancel', this.touchend);
          e.removeEventListener('mousemove', this.mousemove);
          e.removeEventListener('mouseleave', this.mouseleave);
        }
        break;
      case 'click':
        e.removeEventListener('click', this.click);
        break;
    }

    switch (newTriggers) {
      case 'hover':
        if (typeof PointerEvent !== 'undefined') {
          e.addEventListener('pointerdown', this.pointerdown);
          e.addEventListener('pointerleave', this.pointerleave);
          e.addEventListener('pointermove', this.pointermove);
          window.addEventListener('pointerup', this.poinerup);
          window.addEventListener('pointercancel', this.poinerup);
        } else {
          e.addEventListener('touchstart', this.touchstart);
          e.addEventListener('touchend', this.touchend);
          e.addEventListener('touchcancel', this.touchend);
          e.addEventListener('mousemove', this.mousemove);
          e.addEventListener('mouseleave', this.mouseleave);
        }
        break;
      case 'click':
        e.addEventListener('click', this.click);
        break;
    }
  }
  private click = () => {
    this.toggle();
  };
  private pointerId = 0;
  private pointerdown = (e: PointerEvent) => {
    if (this.pointerId) return;
    if (e.pointerType !== 'touch') return;

    this.pointerId = e.pointerId;
    this.clearDelayTimeout();
    this.show();

    window.addEventListener('pointerup', this.poinerup);
    window.addEventListener('pointercancel', this.poinerup);
  };
  private poinerup = (e: PointerEvent) => {
    if (e.pointerId !== this.pointerId) return;
    if (e.pointerType !== 'touch') return;

    this.pointerId = 0;
    this.hide();

    window.removeEventListener('pointerup', this.poinerup);
    window.removeEventListener('pointercancel', this.poinerup);
  };
  private pointerleave = (e: PointerEvent) => {
    if (this.pointerId) return;
    if (e.pointerType === 'touch' && e.pointerId !== this.pointerId) return;

    this.hide();
  };
  private pointermove = (e: PointerEvent) => {
    if (this.pointerId) return;
    if (e.pointerType === 'touch') return;

    this.clearDelayTimeout();
    this.show();
  };
  private touchStarted = false;
  private mouseStarted = false;
  private touchstart = () => {
    if (this.mouseStarted) return;
    this.touchStarted = true;
    this.clearDelayTimeout();
    this.show();
  };
  private touchend = () => {
    if (this.mouseStarted) return;
    this.touchStarted = false;
    this.lastTouch = performance.now();
    this.hide();
  };
  private mousemove = () => {
    if (this.touchStarted) return;
    if ((performance.now() - this.lastTouch) < 500) return; // fake mousemove is sent after touchend
    this.mouseStarted = true;
    this.clearDelayTimeout();
    this.show();
  };
  private mouseleave = () => {
    if (this.touchStarted || !this.mouseStarted) return;
    this.mouseStarted = false;
    this.hide();
  };
  private clearDelayTimeout() {
    if (this.delayTimeout) {
      clearTimeout(this.delayTimeout);
      this.delayTimeout = 0;
    }
    if (this.hideDelayTimeout) {
      clearTimeout(this.hideDelayTimeout);
      this.hideDelayTimeout = 0;
    }
  }
  private showTooltip = () => this.zone.run(() => {
    this.delayTimeout = 0;
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory<TooltipContainer>(this.containerType);

    if (this.componentRef || !this.componentFactory) return;

    this.beforeShow();

    this.contentRef = this.getContentRef(this.tooltip);
    this.componentRef = this.componentFactory.create(
      Injector.create({ providers: [], parent: this.injector }), this.contentRef.nodes);
    this.applicationRef.attachView(this.componentRef.hostView);

    this.componentRef.instance.data = this.data;
    this.componentRef.instance.className =
      `tooltip in show tooltip-${this.placement} bs-tooltip-${this.placement} ${this.placement} ${this.containerClass}`;

    if (this.container instanceof ElementRef) {
      this.container.nativeElement.appendChild(this.componentRef.location.nativeElement);
    } else if (this.container === 'body') {
      document.body.appendChild(this.componentRef.location.nativeElement);
    } else if (this.container) {
      const element = document.querySelector(this.container) || document.body;
      element?.appendChild(this.componentRef.location.nativeElement);
    } else if (this.elementRef.nativeElement.parentElement) {
      this.elementRef.nativeElement.parentElement.appendChild(this.componentRef.location.nativeElement);
    }

    // we need to manually invoke change detection since events registered via
    // Renderer::listen() are not picked up by change detection with the OnPush strategy
    this.contentRef.componentRef?.changeDetectorRef.markForCheck();
    this.contentRef.componentRef?.changeDetectorRef.detectChanges();
    this.componentRef.changeDetectorRef.markForCheck();
    this.componentRef.changeDetectorRef.detectChanges();

    const element = this.componentRef.location.nativeElement as HTMLElement;
    const target = this.elementRef.nativeElement;

    if (this.keepOpenWhenHovered) {
      this.setupTriggers(element, '', this._triggers);
    }

    element.style.pointerEvents = 'none';

    this.positionService.addPositionElement({
      element,
      target,
      attachment: this.placement,
      appendToBody: this.container === 'body',
      options: {
        flip: {
          enabled: this.adaptivePosition
        },
        preventOverflow: {
          enabled: this.adaptivePosition,
          boundariesElement: this.boundariesElement,
        },
      },
    });

    if (this._triggers === 'hover') {
      document.addEventListener('mousemove', this.documentMouseMove);
    }

    if (this.keepOpenWhenHovered) {
      this.zone.runOutsideAngular(() => setTimeout(() => {
        element.style.pointerEvents = 'initial';
      }));
    }

    this.isOpenChange();
    // just calling getBoundingClientRect seems to cause the tooltip to randomly hide
  });
  private documentMouseMove = (e: MouseEvent) => {
    for (let elem = e.target as HTMLElement | null; elem; elem = elem.parentElement) {
      if (this.componentRef && elem == this.componentRef.location.nativeElement) return;
      if (elem == this.elementRef.nativeElement) return;
    }

    this.hide();
  };
  private hideTooltip = () => this.zone.run(() => {
    this.hideDelayTimeout = 0;

    if (!this.componentRef) return;

    this.positionService.deletePositionElement(this.componentRef.location.nativeElement);

    const componentEl = this.componentRef.location.nativeElement;
    componentEl.parentNode?.removeChild(componentEl);

    this.contentRef?.componentRef?.destroy();

    if (this.viewContainerRef && this.contentRef?.viewRef) {
      this.viewContainerRef.remove(this.viewContainerRef.indexOf(this.contentRef.viewRef));
    }

    this.contentRef?.viewRef?.destroy();
    this.contentRef = undefined;
    this.componentRef = undefined;
    this.clearDelayTimeout();
    clearInterval(this.interval);
    document.removeEventListener('mousemove', this.documentMouseMove);
    this.isOpenChange();
  });
  private getContentRef(content: string | TemplateRef<any> | any): ContentRef {
    if (!content) {
      return { nodes: [] };
    } else if (content instanceof TemplateRef) {
      if (this.viewContainerRef) {
        const viewRef = this.viewContainerRef.createEmbeddedView<TemplateRef<AgTooltipContainer>>(content);
        viewRef.markForCheck();
        return { nodes: [viewRef.rootNodes], viewRef };
      } else {
        const viewRef = content.createEmbeddedView({});
        this.applicationRef.attachView(viewRef);
        return { nodes: [viewRef.rootNodes], viewRef };
      }
    } else if (typeof content === 'function') { // not used
      const factory = this.componentFactoryResolver.resolveComponentFactory(content);
      const componentRef = factory.create(Injector.create({ providers: [], parent: this.injector }));
      this.applicationRef.attachView(componentRef.hostView);
      return { nodes: [[componentRef.location.nativeElement]], viewRef: componentRef.hostView, componentRef };
    } else {
      return { nodes: [[document.createTextNode(`${content}`)]] };
    }
  }
}

export const TOOLTIP_COMPONENTS = [AgTooltip, AgTooltipContainer];
