import { getContext2d } from '../common/canvasUtils';
import { Drawing, Point, User, Viewport } from '../common/interfaces';
import { isLayerVisible } from '../common/layer';
import { getSurfaceBounds } from '../common/toolSurface';
import { getPixelRatio } from '../common/utils';
import { clamp } from '../common/mathUtils';
import { applyViewportTransform } from '../common/viewport';
import { Renderer } from './renderer';
import { createCanvas } from 'canvas';
import { rectToString } from '../common/rect';

const canvasesToDebug = new Map<string, HTMLCanvasElement>();

export function drawDebugLayerBounds(renderer: Renderer, drawing: Drawing, view: Viewport, user: User) {
  if (!renderer.canvas) return;

  const ratio = getPixelRatio();

  const context = getContext2d(renderer.canvas);
  context.save();
  applyViewportTransform(context, view, ratio);

  context.beginPath();
  context.lineWidth = 1 / view.scale;
  context.strokeStyle = 'red';
  for (const layer of drawing.layers) {
    if (!isLayerVisible(layer)) continue;
    context.rect(layer.rect.x - drawing.x, layer.rect.y - drawing.y, layer.rect.w, layer.rect.h);
  }

  context.strokeStyle = 'green';
  const r = getSurfaceBounds(user.surface);
  context.rect(r.x - drawing.x, r.y - drawing.y, r.w, r.h);

  context.stroke();
  context.restore();
}

export function cloneAndDebugCanvas(canvas: HTMLCanvasElement, id: string) {
  if (canvas.width === 0 || canvas.height === 0) return;
  const c = createCanvas(canvas.width, canvas.height);
  c.id = id;

  const ctx = getContext2d(c);
  ctx.clearRect(0, 0, c.width, c.height);
  ctx.drawImage(canvas, 0, 0);

  canvasesToDebug.delete(id);
  canvasesToDebug.set(id, c);
}

export function drawDebugAllocatedCanvases(renderer: Renderer, view: Viewport, drawing: Drawing, user: User) {
  if (view.rotation) return;
  const drawingCanvas = drawing.canvas;
  if (!drawingCanvas) return;
  if (!renderer.canvas) return;

  const ratio = getPixelRatio();
  const canvases: HTMLCanvasElement[] = [];

  canvases.push(...canvasesToDebug.values());
  // if (webgl.maskTexture) textures.push(webgl.maskTexture);
  if (user.surface.canvas) canvases.push(user.surface.canvas);

  drawing.layers.forEach(l => {
    if (l.canvas) {
      l.canvas.id = `Layer ${l.id}`;
      canvases.push(l.canvas);
    }
  });

  const coords: Point[] = [];
  const maxY = drawingCanvas.height * 1;
  let maxX = 0;
  let x = drawingCanvas.width + 100, y = 0;

  for (const c of canvases) {
    coords.push({ x, y });
    maxX = Math.max(maxX, x + c.width);
    y += c.height + 200;

    if (y > maxY) {
      y = 0;
      x = maxX + 100;
    }
  }

  const context = getContext2d(renderer.canvas);
  context.save();
  context.imageSmoothingEnabled = false;
  applyViewportTransform(context, view, ratio);

  context.fillStyle = 'lime';
  context.font = `bold ${clamp(Math.round(drawingCanvas.width * 0.05), 20, 80)}px Arial`;
  context.textBaseline = 'top';

  context.fillText(`${drawing.x},${drawing.y} ${drawing.w}x${drawing.h}`, 0, - 80);

  // draw textures
  for (const [i, c] of canvases.entries()) {
    if (c === user.surface.canvas) {
      context.fillText(`surface rect:${rectToString(user.surface.rect)} ${user.surface.textureX},${user.surface.textureY}`, coords[i].x, coords[i].y - 80);
    } else {
      context.fillText(`${c.id} ${c.width}x${c.height}`, coords[i].x, coords[i].y - 80);
    }
    if (c.width > 0 && c.height > 0) context.drawImage(c, coords[i].x, coords[i].y);
  }

  context.beginPath();
  context.lineWidth = 1;
  context.lineWidth = 1 / view.scale;
  context.strokeStyle = 'red';
  for (const [i, c] of canvases.entries()) {
    context.rect(coords[i].x, coords[i].y, c.width, c.height);
  }
  context.stroke();

  context.restore();
}
