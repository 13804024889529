import { Injectable } from '@angular/core';
import { Model } from './model';
import { UserFlowId } from '../common/constants';
import type { Userflow } from 'userflow.js';

@Injectable({ providedIn: 'root' })
export class OnboardingService {
  userflow: Userflow | undefined = undefined;
  model: Model | undefined = undefined;

  ensureAuthenticated() { return Promise.resolve(); }

  startConferenceMode(idleTimeToLogout: number) { idleTimeToLogout; }

  startConferenceCycle() { }

  startFlow(id: UserFlowId) { id; }

  stopConferenceMode() { }

  get isConferenceMode() { return false; }

  get canStartConferenceMode (){ return false; }

  get hasOnboarding() { return false; }

}
