import { random } from 'lodash';
import { colorToCSS, colorToFloatArray } from './color';
import { StableDiffusionInput, IToolData, Rect, LayerData, AiToolPipeline, AiToolSetting } from './interfaces';
import { clamp } from './mathUtils';
import { createCanvas, getContext2d } from './canvasUtils';
import { fillPath } from './path';
import { createShapePath } from './shapes';

export const DREAMBOOTH_JOB_TYPE = 'magma:stable-diffusion:dreambooth';

export const enum AiJob {
  AI_A1111 = 'magma:stable-diffusion:automatic1111-v5',
  AI_A1111_SD21 = 'magma:stable-diffusion:automatic1111-v5:sd21'
}

export type AiModelName = 'Stable Diffusion 1.5' | 'Stable Diffusion 2.1' | 'F222' | 'RPGv4' | 'Anything' | 'MoDi' | 'Inkpunk Diffusion' | 'Robo Diffusion' | 'Openjourney';
export const AI_BOUNDING_BOX_WIDTH_MIN = 16;
export const AI_BOUNDING_BOX_HEIGHT_MIN = 16;
export const AI_BOUNDING_BOX_COLOR_1_ACTIVE = colorToFloatArray(0xA8D5FFFF);
export const AI_BOUNDING_BOX_COLOR_1_ACTIVE_STR = colorToCSS(0xA8D5FFFF);
export const AI_BOUNDING_BOX_COLOR_2_ACTIVE = colorToFloatArray(0x2E6DE1FF);
export const AI_BOUNDING_BOX_COLOR_2_ACTIVE_STR = colorToCSS(0x2E6DE1FF);
export const AI_BOUNDING_BOX = colorToFloatArray(0x000000FF);
export const AI_BOUNDING_BOX_STR = colorToCSS(0x000000FF);
export const AI_SELECTION_COLOR_1 = '#00000012';
export const AI_SELECTION_COLOR_2 = '#4F89F3C4';
export const AI_SELECTION_COLOR_1_FLOAT = colorToFloatArray(0x00000012);
export const AI_SELECTION_COLOR_2_FLOAT = colorToFloatArray(0x4F89F3C4);

let selectionPatternCanvas: HTMLCanvasElement | undefined;
export const getAiSelectionPatternCanvas = () => {
  if (selectionPatternCanvas) return selectionPatternCanvas;

  const shape = createShapePath(8, 16, 'M0 0V8L4 0H0ZM8 8V0L0 16H4L8 8Z');
  let patternWidth = 1, patternHeight = 1;
  const patternSize = 16;
  if (shape.width > shape.height) {
    patternWidth = clamp(Math.round(patternSize), 1, 256);
    patternHeight = clamp(Math.round(patternWidth * shape.height / shape.width), 1, 256);
  } else {
    patternHeight = clamp(Math.round(patternSize), 1, 256);
    patternWidth = clamp(Math.round(patternHeight * shape.width / shape.height), 1, 256);
  }
  selectionPatternCanvas = createCanvas(patternWidth, patternHeight);
  const patternContext = getContext2d(selectionPatternCanvas);
  patternContext.scale(selectionPatternCanvas.width / shape.width, selectionPatternCanvas.height / shape.height);
  patternContext.fillStyle = AI_SELECTION_COLOR_2;
  fillPath(patternContext, shape);

  return selectionPatternCanvas;
};

export const AI_BOUNDING_BOX_MASK_ALPHA = 0.3;
export const AI_MODELS_AVAILABLE_FOR_ALL_USERS: AiModelName[] = ['Stable Diffusion 2.1'];
export const AI_CHECKPOINTS_AVAILABLE_FOR_ALL_USERS: AiCheckpointFileName[] = [AiCheckpointFileName.StableDiffusion21, AiCheckpointFileName.StableDiffusion21_768px, AiCheckpointFileName.StableDiffusion21_Inpaint];
export const AI_NUMBER_OF_RETRIES_AFTER_NSFW = 1;
export const AI_MODELS: AiModelName[] = ['Stable Diffusion 1.5', 'Stable Diffusion 2.1', 'F222', 'RPGv4', 'Anything', 'MoDi', 'Inkpunk Diffusion', 'Robo Diffusion', 'Openjourney'];
export const AI_SAMPLERS = [
  'Euler a', 'Euler', 'LMS', 'Heun', 'DPM2', 'DPM2 a', 'DPM++ 2S a', 'DPM++ 2M', 'DPM++ SDE', 'DPM fast', 'DPM adaptive',
  'LMS Karras', 'DPM2 Karras', 'DPM2 a Karras', 'DPM++ 2S a Karras', 'DPM++ 2M Karras', 'DPM++ SDE Karras', 'DDIM',
];


const SoftEdgePreprocessors = ['softedge_hed' , 'softedge_hedsafe' , 'softedge_pidinet' , 'softedge_pidisafe'] as const;
type SoftEdgePreprocessorName = typeof SoftEdgePreprocessors[number];

const DepthPreprocessors = ['depth_leres', 'depth_leres++' , 'depth_midas' , 'depth_zoe'] as const;
type DepthPreprocessorName = typeof DepthPreprocessors[number];

const NormalPreprocessors = ['normal_bae' , 'normal_midas'] as const;
type NormalPreprocessorName = typeof NormalPreprocessors[number];

const OpenPosePreprocessors = ['openpose' , 'openpose_face' , 'openpose_faceonly' , 'openpose_full', 'openpose_hand'] as const;
type OpenPosePreprocessorName = typeof OpenPosePreprocessors[number];

const MldsPreprocessors = ['mlsd'] as const;
type MldsPreprocessorName = typeof MldsPreprocessors[number];

const LineartPreprocessors = ['lineart_anime', 'lineart_anime_denoise', 'lineart_coarse', 'lineart_realistic', 'lineart_standard (from white bg & black line)'] as const;
type LineartPreprocessorName = typeof LineartPreprocessors[number];

const ScribblePreprocessors = ['scribble_hed', 'scribble_pidinet', 'scribble_xdog'] as const;
type ScribblePreprocessorName = typeof ScribblePreprocessors[number];

const SegmentationPreprocessors = ['seg_ofade20k', 'seg_ofcoco', 'seg_ufade20k'] as const;
type SegmentationPreprocessorName = typeof SegmentationPreprocessors[number];

const ShufflePreprocessors = ['shuffle'] as const;
type ShufflePreprocessorName = typeof ShufflePreprocessors[number];

const TilePreprocessors = ['tile_colorfix', 'tile_colorfix+sharp', 'tile_resample'] as const;
type TilePreprocessorName = typeof TilePreprocessors[number];

const InpaintPreprocessors = ['inpaint_only', 'inpaint_global_harmonious'] as const;
type InpaintPreprocessorName = typeof InpaintPreprocessors[number];


const CannyPreprocessors = ['canny'] as const;
type CannyPreprocessorName = typeof CannyPreprocessors[number];


export type AiControlNetPreprocessorName = 'None' | CannyPreprocessorName | SoftEdgePreprocessorName | DepthPreprocessorName | NormalPreprocessorName | OpenPosePreprocessorName | MldsPreprocessorName |
  LineartPreprocessorName | ScribblePreprocessorName | SegmentationPreprocessorName | ShufflePreprocessorName | TilePreprocessorName | InpaintPreprocessorName;


export type AiControlNetModelName = 'Disable' | 
  'control_v11e_sd15_ip2p' | 'control_v11f1p_sd15_depth' | 'control_v11p_sd15_lineart' | 'control_v11p_sd15_openpose' | 'control_v11p_sd15_softedge' |
  'control_v11e_sd15_shuffle' | 'control_v11p_sd15_canny' | 'control_v11p_sd15_mlsd' | 'control_v11p_sd15_scribble' | 'control_v11p_sd15s2_lineart_anime' |
  'control_v11f1e_sd15_tile' | 'control_v11p_sd15_inpaint' | 'control_v11p_sd15_normalbae' | 'control_v11p_sd15_seg' |

  'control_v11p_sd21_ade20k' | 'control_v11p_sd21_depth' | 'control_v11p_sd21_normalbae' | 'control_v11p_sd21_scribble' |
  'control_v11p_sd21_canny' | 'control_v11p_sd21_hed' | 'control_v11p_sd21_openpose' | 'control_v11p_sd21_zoedepth' |
  'control_v11p_sd21_color' | 'control_v11p_sd21_lineart' | 'control_v11p_sd21_openposev2';

export interface AiControlNetModel {
  name: string;
  baseModel: AiModelBase;
  modelName: AiControlNetModelName;
  preprocessors: AiControlNetPreprocessorName[];
}

// TODO add field to define if cn is for txt2img or img2img
export const AI_CONTROLNET_MODELS: AiControlNetModel[] = [
  { baseModel: AiModelBase.SD_2_1, name: 'Disable', modelName: 'Disable', preprocessors: [] },
  { baseModel: AiModelBase.SD_1_5, name: 'Disable', modelName: 'Disable', preprocessors: [] },

  { baseModel: AiModelBase.SD_1_5, modelName: 'control_v11e_sd15_ip2p', name: 'IP2P', preprocessors: ['None'] }, // this is also empty in a1111
  { baseModel: AiModelBase.SD_1_5, modelName: 'control_v11f1p_sd15_depth', name: 'Depth', preprocessors: ['None', ...DepthPreprocessors] },
  { baseModel: AiModelBase.SD_1_5, modelName: 'control_v11p_sd15_lineart', name: 'Lineart', preprocessors: ['None', ...LineartPreprocessors] },
  { baseModel: AiModelBase.SD_1_5, modelName: 'control_v11p_sd15_openpose', name: 'Open pose', preprocessors: ['None', ...OpenPosePreprocessors] },
  { baseModel: AiModelBase.SD_1_5, modelName: 'control_v11p_sd15_softedge', name: 'SoftEdge', preprocessors: ['None', ...SoftEdgePreprocessors] }, 
  { baseModel: AiModelBase.SD_1_5, modelName: 'control_v11e_sd15_shuffle', name: 'Shuffle', preprocessors: ['None', ...ShufflePreprocessors] },
  { baseModel: AiModelBase.SD_1_5, modelName: 'control_v11p_sd15_canny', name: 'Canny', preprocessors: ['None', ...CannyPreprocessors] },
  { baseModel: AiModelBase.SD_1_5, modelName: 'control_v11p_sd15_mlsd', name: 'MLSD', preprocessors: ['None', ...MldsPreprocessors] },
  { baseModel: AiModelBase.SD_1_5, modelName: 'control_v11p_sd15_scribble', name: 'Scribble', preprocessors: ['None', ...ScribblePreprocessors] },
  { baseModel: AiModelBase.SD_1_5, modelName: 'control_v11p_sd15s2_lineart_anime', name: 'Lineart anime', preprocessors: ['None', ...LineartPreprocessors] }, 
  { baseModel: AiModelBase.SD_1_5, modelName: 'control_v11f1e_sd15_tile', name: 'Tile', preprocessors: ['None', ...TilePreprocessors] }, 
  { baseModel: AiModelBase.SD_1_5, modelName: 'control_v11p_sd15_inpaint', name: 'Inpaint', preprocessors: ['None', ...InpaintPreprocessors] },
  { baseModel: AiModelBase.SD_1_5, modelName: 'control_v11p_sd15_normalbae', name: 'Normal', preprocessors: ['None', ...NormalPreprocessors] },
  { baseModel: AiModelBase.SD_1_5, modelName: 'control_v11p_sd15_seg', name: 'Segmentation', preprocessors: ['None', ...SegmentationPreprocessors] },

  { baseModel: AiModelBase.SD_2_1, modelName: 'control_v11p_sd21_ade20k', name: 'Ade20k', preprocessors: ['None', ...SegmentationPreprocessors] },
  { baseModel: AiModelBase.SD_2_1, modelName: 'control_v11p_sd21_depth', name: 'Depth', preprocessors: ['None', ...DepthPreprocessors] },
  { baseModel: AiModelBase.SD_2_1, modelName: 'control_v11p_sd21_normalbae', name: 'Normal', preprocessors: ['None', ...NormalPreprocessors] },
  { baseModel: AiModelBase.SD_2_1, modelName: 'control_v11p_sd21_scribble', name: 'Scribble', preprocessors: ['None', ...ScribblePreprocessors] },
  { baseModel: AiModelBase.SD_2_1, modelName: 'control_v11p_sd21_canny', name: 'Canny', preprocessors: ['None', ...CannyPreprocessors] },
  { baseModel: AiModelBase.SD_2_1, modelName: 'control_v11p_sd21_hed', name: 'Hed', preprocessors: ['None', ...SoftEdgePreprocessors] },
  { baseModel: AiModelBase.SD_2_1, modelName: 'control_v11p_sd21_openpose', name: 'Open pose', preprocessors: ['None', ...OpenPosePreprocessors] },
  { baseModel: AiModelBase.SD_2_1, modelName: 'control_v11p_sd21_zoedepth', name: 'Zoe depth', preprocessors: ['None', ...DepthPreprocessors] },
  // { baseModel: AiModelBase.SD_2_1, modelName: 'control_v11p_sd21_color', name: 'Color', preprocessors: [] },
  { baseModel: AiModelBase.SD_2_1, modelName: 'control_v11p_sd21_lineart', name: 'Lineart', preprocessors: ['None', ...LineartPreprocessors] },
  { baseModel: AiModelBase.SD_2_1, modelName: 'control_v11p_sd21_openposev2', name: 'Open pose v2', preprocessors: ['None', ...OpenPosePreprocessors] },
];

export const AI_OPTIMUM_SIZE_STEP = 16;
export const AI_DEFAULT_MODEL: AiModelName = 'Stable Diffusion 2.1';
export const AI_DEFAULT_NEGATIVE_PROMPT = 'lowres, bad anatomy, bad hands, error, missing fingers, extra digit, fewer digits, cropped, worst quality, low quality, normal quality, jpeg artifacts, signature, watermark, username, blurry, poorly drawn face, poorly drawn hands, poorly drawn feet, deformed, blurry, disfigured, out of frame, poo art, tiling, bad hands, bad art';
export const AI_DEFAULT_SAMPLER = 'Euler a';
export const AI_DEFAULT_PROMPT_STRENGTH = 0.8;
export const AI_DEFAULT_NUM_INFERENCE_STEPS = 30;
export const AI_DEFAULT_GUIDANCE_SCALE = 7.5;
export const AI_DEFAULT_RESOLUTION = 512;
export const AI_DEFAULT_RESULT_NUMBER = 3;

export const enum AiModelBase {
  SD_1_5 = 'SD1.5',
  SD_2_1 = 'SD2.1',
}

export const AI_MODEL_BASES = [AiModelBase.SD_1_5, AiModelBase.SD_2_1];

export const AI_MODEL_DATA: { [key in AiModelName]: AiCheckpoint[] } = {
  'Stable Diffusion 2.1' :  [
    { base: AiModelBase.SD_2_1, file: AiCheckpointFileName.StableDiffusion21, default: true },
    { base: AiModelBase.SD_2_1, file: AiCheckpointFileName.StableDiffusion21_Inpaint, inpaint: true },
    { base: AiModelBase.SD_2_1, file: AiCheckpointFileName.StableDiffusion21_768px, resolutions: [768] }
  ],
  'Stable Diffusion 1.5': [
    { base: AiModelBase.SD_1_5, file: AiCheckpointFileName.StableDiffusion15, default: true },
    { base: AiModelBase.SD_1_5, file: AiCheckpointFileName.StableDiffusion15_Inpaint, inpaint: true },
  ],
  'Anything': [ { base: AiModelBase.SD_1_5, file: AiCheckpointFileName.AnythingV3, default: true } ],
  'F222': [ { base: AiModelBase.SD_1_5, file: AiCheckpointFileName.F2222, default: true } ],
  'Inkpunk Diffusion': [ { base: AiModelBase.SD_1_5, file: AiCheckpointFileName.InkpunkDiffusion, default: true }, ],
  'MoDi': [ { base: AiModelBase.SD_1_5, file: AiCheckpointFileName.MoDi, default: true }, ],
  'RPGv4': [ { base: AiModelBase.SD_1_5, file: AiCheckpointFileName.RPGv4, default: true }, ],
  'Robo Diffusion': [ { base: AiModelBase.SD_2_1, file: AiCheckpointFileName.RoboDiffusion, default: true }, ],
  'Openjourney': [ { base: AiModelBase.SD_1_5, file: AiCheckpointFileName.Openjourney, default: true } ],
};

export enum InpaintFill {
  Fill = 0,
  Original = 1,
  LatentNoise = 2,
  LatentNothing = 3
}

export type AiUpdateRequest = {
  type: 'request';
  jobId: string;
  request: StableDiffusionInput;
};

export type AiStopRequest = {
  type: 'stop';
};

export type AiUpdateResult = {
  type: 'result';
  resultId: number;
  isActive: boolean;
  data: Uint8Array;
  nsfw: boolean;
};

export type AiUpdateProgress = {
  type: 'progress';
  status: AiStatusReport;
};

export type AiUpdateFinish = {
  type: 'finished';
  duration: number;
  workerDuration: number;
};

export type AiUpdateActiveChange = {
  type: 'activeChange';
  resultId: number;
  replace?: boolean;
};

export type AiUpdateRemoveResult = {
  type: 'removeResult';
  resultId: number;
};

export type AiUpdateSaveResultToLayer = {
  type: 'saveResultToLayer';
  resultId: number;
  layerId: number;
  layerIndex: number;
};

export type AiUpdateError = {
  type: 'error';
  message: string;
};

export type AiUpdateValidationError = {
  type: 'validationError';
  field: string;
  items: string[];
};

export type AiUpdateData = AiUpdateFinish | AiUpdateValidationError | AiUpdateSaveResultToLayer | AiUpdateError | AiStopRequest | AiUpdateRemoveResult | AiUpdateActiveChange | AiUpdateResult | AiUpdateRequest | AiUpdateProgress;

export interface AiPasteToolData extends IToolData {
  jobId: string;
  pipeline: AiToolPipeline;
  rect: Rect;
  layer: LayerData;
  index: number;
  created: boolean;
  chunkId?: number;
}

export interface AiToolData extends IToolData {
  resultId: number;
  rect: Rect;
  layerId: number;
  resultsToRemove: number[];
}

export interface AiResponse {
  chunkId: number;
  layer: LayerData;
  index: number;
}

export interface AiStatusReport {
  status: 'queued' | 'running' | 'finished' | 'canceled';
  progress: number;
}

export interface AiCheckpoint {
  file: AiCheckpointFileName;
  default?: boolean;
  resolutions?: number[];
  inpaint?: boolean;
  base: AiModelBase;
}

export const enum AiCheckpointFileName {
  StableDiffusion15 = 'v1-5-pruned-emaonly.safetensors',
  StableDiffusion15_Inpaint = 'sd-v1-5-inpainting.safetensors',

  StableDiffusion21 = 'v2-1_512-ema-pruned.safetensors',
  StableDiffusion21_768px = 'v2-1_768-ema-pruned.safetensors',
  StableDiffusion21_Inpaint = '512-inpainting-ema.safetensors',

  F2222 = 'f222.safetensors',
  RPGv4 = 'RPG-v4.safetensors',
  AnythingV3 = 'anything-v3-fp16-pruned.safetensors',

  MoDi = 'moDi-v1-pruned.safetensors',
  InkpunkDiffusion = 'Inkpunk-Diffusion-v2.safetensors',
  RoboDiffusion = 'robo-diffusion-v2-base.safetensors',

  Openjourney = 'mdjrny-v4.safetensors',
}

export type AiScript = 'None' | 'magma-outpainting2';

export interface AiToolSettings {
  prompt: string;
  negativePrompt: string;
  seed: number;
  generateSeedOnRun: boolean;
  numInferenceSteps: number;
  guidanceScale: number;
  resolution: number;
  numberResults: number;
  scriptName: AiScript;

  // this is optional for some pipelines, so values will be ignored (kept like this to make it compatible)
  promptStrength: number;
  inpaintMaskBlur: number;
  inpaintFill: InpaintFill;

  controlnet_preprocessor: AiControlNetPreprocessorName | null;
  controlnet_model: AiControlNetModelName | null;
}

const AI_DEFAULT_SETTINGS_ALL: AiToolSettings = {
  resolution: AI_DEFAULT_RESOLUTION,
  numberResults: AI_DEFAULT_RESULT_NUMBER,
  prompt: '',
  negativePrompt: AI_DEFAULT_NEGATIVE_PROMPT,
  seed: random(0x7fffffff),
  generateSeedOnRun: true,
  numInferenceSteps: AI_DEFAULT_NUM_INFERENCE_STEPS,
  guidanceScale: AI_DEFAULT_GUIDANCE_SCALE,
  scriptName: 'None',

  promptStrength: AI_DEFAULT_PROMPT_STRENGTH,
  inpaintMaskBlur: 4,
  inpaintFill: InpaintFill.LatentNoise,

  controlnet_preprocessor: 'None',
  controlnet_model: 'Disable'
};

const AI_DEFAULT_SETTINGS_CREATE: AiToolSettings = {
  ...AI_DEFAULT_SETTINGS_ALL
};

const AI_DEFAULT_SETTINGS_ENHANCE: AiToolSettings = {
  ...AI_DEFAULT_SETTINGS_CREATE,
  promptStrength: AI_DEFAULT_PROMPT_STRENGTH,
};

const AI_DEFAULT_SETTINGS_INPAINT: AiToolSettings = {
  ...AI_DEFAULT_SETTINGS_CREATE,
  promptStrength: AI_DEFAULT_PROMPT_STRENGTH,
  inpaintMaskBlur: 4,
  inpaintFill: InpaintFill.LatentNoise,
};

const AI_DEFAULT_SETTINGS_OUTPAINT: AiToolSettings = {
  ...AI_DEFAULT_SETTINGS_CREATE,
  promptStrength: 1.0,
  inpaintMaskBlur: 0,
  scriptName: 'magma-outpainting2',
  inpaintFill: InpaintFill.Fill
};

export const AI_DEFAULT_SETTINGS: { [key in AiToolSetting]: AiToolSettings } = {
  'all': AI_DEFAULT_SETTINGS_ALL,
  'create': AI_DEFAULT_SETTINGS_CREATE,
  'enhance': AI_DEFAULT_SETTINGS_ENHANCE,
  'inpaint': AI_DEFAULT_SETTINGS_INPAINT,
  'outpaint': AI_DEFAULT_SETTINGS_OUTPAINT,
};

// pixels per second
export const AI_SPEED = 4_800_000;

// most of number here are experimentally selected
export function estimateGenerationTime(pixelCount: number, inputPixelCount: number, upscale: boolean, steps: number, numberOutputs: number) {
  const nsfwTime = 1 + 1.5*numberOutputs;
  if (upscale) {
    const firstPass = steps / (AI_SPEED / inputPixelCount);
    const upscalePassTime = ((steps / 2) * 1.5) / (AI_SPEED / pixelCount);
    return (firstPass + upscalePassTime) * numberOutputs + nsfwTime;
  } else {
    const firstPass = steps / (AI_SPEED / pixelCount);
    return firstPass * numberOutputs + nsfwTime;
  }
}

export function updateAiMaskTransform(matrix: DOMMatrix) {
  matrix.f = -performance.now() / 500 * 16;
}

export interface AsyncJobStatus {
  progress: number;
  status: string;
  offset: number;
}

export interface AsyncJobStatusUpdate {
  jobId: string;
  userId: string;
  teamId: string | null;
  progress: number;
  status: string;
  error?: string;
  output?: DreamboothOutput[];
}

export type AsyncJobStatusClientUpdate = Pick<AsyncJobStatusUpdate, 'jobId' | 'output' | 'progress' | 'status' | 'error'>;

export interface AsyncJobRequest {
  jobType: string;
  jobId: string;
  userId: string;
  origin: string;
}

export interface JobOutput {
  id: string;
  type: string;
}

export interface DreamboothJobLoraOutput extends JobOutput {
  type: 'dreambooth:output:lora';
  checkpoint: number;
  thumbnails: Buffer[];
  size: number; // in bytes

  model: Buffer;
  pytorch_model: Buffer;
  optimizer: Buffer;
  scheduler: Buffer;
  random_states_0: Buffer;
  custom_checkpoint_0?: Buffer;
}


export type DreamboothJobLoraOutputChunks = {
  id: string;
  type: 'dreambooth:output:lora';
  checkpoint: number;
  thumbnails: Buffer[];
  size: number;
} | {
  id: string;
  type: 'dreambooth:output:lora:model';
  model: Buffer;
} | {
  id: string;
  type: 'dreambooth:output:lora:pytorch_model';
  pytorch_model: Buffer;
} | {
  id: string;
  type: 'dreambooth:output:lora:optimizer';
  optimizer: Buffer;
} | {
  id: string;
  type: 'dreambooth:output:lora:scheduler';
  scheduler: Buffer;
} | {
  id: string;
  type: 'dreambooth:output:lora:random_states_0';
  random_states_0: Buffer;
} | {
  id: string;
  type: 'dreambooth:output:lora:custom_checkpoint_0';
  custom_checkpoint_0: Buffer;
};

export interface DreamboothJobSummaryOutput extends JobOutput {
  type: 'dreambooth:output:summary';
  duration: number;
}

export type DreamboothJobOutput = DreamboothJobLoraOutputChunks | DreamboothJobSummaryOutput;

// TODO fix naming convention for client
export type DreamboothLoraOutput = Omit<DreamboothJobLoraOutput, 'thumbnails' | 'model' | 'thumbnails' | 'pytorch_model' |
 'optimizer' | 'scheduler' | 'random_states_0' | 'custom_checkpoint_0' > & { 
  thumbnails: string[],
  model: string,
  pytorch_model: string,
  optimizer: string,
  scheduler: string,
  random_states_0: string,
  custom_checkpoint_0?: string,
}; // clients gets ids instead of buffers
export type DreamboothSummaryOutput = DreamboothJobSummaryOutput;
export type DreamboothOutput = DreamboothLoraOutput | DreamboothSummaryOutput;

export interface DreamboothInput {
  script: string;
  validationPrompt: string;
  resolution: number;
  steps: number;
  baseModel: string;
  checkpointingSteps: number;
  numValidationImages: number;
  instancePrompt: string;
  classPrompt: string;
  numClassImages: number;
  gradientAccumulationSteps: number;

  resumeFromCheckpoint?: {
    jobId: string;
    checkpoint: number;
  };
}

export interface DreamboothResumeInput {
  checkpoint: number;
  pytorch_model: Buffer;
  optimizer: Buffer;
  scheduler: Buffer;
  random_states_0: Buffer;
  custom_checkpoint_0?: Buffer; // exists only for dreambooth
}

export interface DreamboothInputImage {
  text: string;
  data: Buffer;
}

export interface DreamboothAsyncJobRequest extends AsyncJobRequest {
  input: DreamboothInput;
  images: DreamboothInputImage[];
}

export interface AsyncJob {
  name: string;
  jobId: string;
  type: string;

  team: string | null;
  user: { name: string, avatar: string } | null;

  createdAt: Date;
  updatedAt: Date;

  inputImageFiles: { [key: string]: string }; // shortId: minio rushId
  inputImageMetadata: { [key: string]: string }; // shortId : text description
  input: DreamboothInput;

  status: AsyncJobStatus;
  output?: DreamboothOutput[];
  error?: string;
}

export enum AiModelType {
  Model = 'model',
  Lora = 'lora',
  Embedding = 'embedding'
}

export interface AiModelPermissions {
  users: string[];
  teams: string[];
}

export interface AiModel {
  rId: string;
  name: string;
  description: string;
  owner: string;
  team: string | null;
  tag: string;
  type: AiModelType;

  enabled: boolean;

  baseModel: string;
  thumbnails: string[];

  permissions: AiModelPermissions;

  createdAt: Date;
}

export type AiModelUpdate = Partial<Pick<AiModel, 'name' | 'description' | 'tag' | 'type' | 'enabled' | 'permissions' | 'baseModel' | 'thumbnails'>>

export enum RenderTypeId {
  SimpleSketch,
  DetailedDrawing,
  RealisticPhoto,
  HumanHand,
  HumanBody,
  EmptyLayer,
}

export interface RenderType {
  id: RenderTypeId;
  name: string;
  model: AiControlNetModelName | null;
  preprocesor: AiControlNetPreprocessorName | null;
}

export const AI_RENDER_TYPES: RenderType[] = [
  {
    id: RenderTypeId.DetailedDrawing,
    name: 'Drawing',
    model: 'control_v11p_sd21_canny',
    preprocesor: 'canny'
  },
  {
    id: RenderTypeId.RealisticPhoto,
    name: 'Photo',
    model: 'control_v11p_sd21_depth',
    preprocesor: 'depth_midas'
  },
  {
    id: RenderTypeId.EmptyLayer,
    name: 'Empty layer',
    model: null,
    preprocesor: null
  }
];

export const AI_DEFAULT_RENDER_TYPE = AI_RENDER_TYPES.find(t => t.id === RenderTypeId.DetailedDrawing)!;
