import { Injectable, Injector } from '@angular/core';
import { logAction } from '../common/actionLog';
import { copyPropertyWithGetter, findById } from '../common/baseUtils';
import { emptyIcon } from '../common/icons';
import { Command, CommandEvent, CommandParams, ToolSource } from '../common/interfaces';
import { setLastToolSource } from '../common/toolUtils';
import { Editor } from './editor';
import { FeatureFlagService } from './feature-flag.service.interface';

export const baseCommand: Command = {
  group: '',
  id: '',
  name: '',
  description: '',
  video: undefined,
  icons: [emptyIcon],
  dontPreventDefault: false,
  allowRepeatedKey: false,
  highlight: false,
  nonFatal: false,
  canExecute: () => true,
  execute: () => { },
};

export function canExecute(command: Command | undefined, params: CommandParams, featureFlags: FeatureFlagService): command is Command {
  if (!command) return false;
  if (command.feature && !featureFlags.isFeatureSupported(command.feature)) return false;
  return command.canExecute(params);
}

@Injectable({ providedIn: 'any' })
export class CommandService {
  constructor(private injector: Injector, private featureFlags: FeatureFlagService) { }
  onError = (_message: string, _error: Error) => { };
  private commands: Command[] = [];
  add(command: Partial<Command>) {
    const tempCommand: Partial<Command> = {};

    for (const key of Object.keys(baseCommand)) {
      copyPropertyWithGetter(baseCommand, tempCommand, key);
    }

    for (const key of Object.keys(command)) {
      copyPropertyWithGetter(command, tempCommand, key);
    }

    this.commands.push(tempCommand as Command);
  }
  get(id: string): Command | undefined {
    return findById(this.commands, id);
  }
  getAll() {
    return this.commands;
  }
  highlight(commandId: string, time = 5000) {
    const command = this.get(commandId);

    if (command && !command.highlight) {
      command.highlight = true;
      setTimeout(() => command.highlight = false, time);
    }
  }
  canExecute(command: Command | undefined, params: CommandParams) {
    return canExecute(command, params, this.featureFlags);
  }
  async executeCommand(command: Command | undefined, params: CommandParams, source: ToolSource, event?: CommandEvent) {
    const paramsWithLayer = params;
    try {
      if (canExecute(command, paramsWithLayer, this.featureFlags)) {
        logAction(`[local] command: ${command.id}`);
        setLastToolSource(source);
        const editor = this.injector.get(Editor);
        editor.textTool.confirmDeferredChange();
        await command.execute(paramsWithLayer, event);
      }
    } catch (e) {
      if (command && command.nonFatal) {
        this.onError(`Failed to ${command.name} (${e.message || e})`, e);
      } else {
        this.onError(e.message, e);
      }
    }
  }
  async executeCommandById(id: string, params: CommandParams, source: ToolSource) {
    const command = findById(this.commands, id);

    if (!command) {
      throw new Error(`Invalid command Id: ${id}`);
    } else {
      await this.executeCommand(command, params, source);
    }
  }
}
