import { IToolEditor, IToolModel, IToolData, ToolId, Rect, CompositeOp, IPasteTool, SelectionMode, PasteLayerData, AnyImageType, LayerData, User, LayerFlag } from '../interfaces';
import { getLayer, getLayerSafe } from '../drawing';
import { addRect, cloneRect, copyRect, createRect, isRectEmpty, rectToString, safeRect } from '../rect';
import { clearMask } from '../mask';
import { commitedLayerRect, setTransform, setupSurface } from '../toolSurface';
import { imageDataToBitmapData, loadImageOrImageDataFromData } from '../canvasUtils';
import { redraw, redrawDrawing } from '../../services/editorUtils';
import { logAction } from '../actionLog';
import type { Editor } from '../../services/editor';
import { addLayerToDrawing, sendLayerOrder } from '../layerToolHelpers';
import { layerChanged, layerFromState, updateLayerState } from '../layer';
import { processPsdFileForPasting } from '../psdHelpers';
import { finishTransform, safeTransform } from '../toolUtils';
import { throwIfTextLayer } from '../text/text-utils';

export const enum PasteToolMode {
  Image = 0,
  ImageToNewLayer = 1,
  Layers = 2,
}

export const PASTE_TOOL_MODES = ['existing-layer', 'new-layer', 'multiple-layers'];

export interface PasteToolImageData extends IToolData {
  mode: PasteToolMode.Image;
  rect: Rect;
  transform: number[];
  deselect: boolean;
}

export interface PasteToolImageToNewLayerData extends IToolData {
  mode: PasteToolMode.ImageToNewLayer;
  rect: Rect;
  transform: number[];
  layer: LayerData;
  index: number;
}

export interface PasteToolLayersData extends IToolData {
  mode: PasteToolMode.Layers;
  sync: boolean;
  layers: Omit<PasteLayerData, 'imageData'>[];
  layersOrder: number[];
}

export type PasteToolData = PasteToolImageData | PasteToolImageToNewLayerData | PasteToolLayersData;

function now() {
  if (DEVELOPMENT && !SERVER) {
    return performance.now();
  } else {
    return 0;
  }
}

export class PasteTool implements IPasteTool {
  id = ToolId.Paste;
  name = '';
  constructor(public editor: IToolEditor, public model: IToolModel) {
  }
  async doAsync(data: PasteToolData, binaryData?: Uint8Array, debugInfo?: string) {
    if (data.mode === PasteToolMode.Image || data.mode === PasteToolMode.ImageToNewLayer) {
      await this.doImage(data, binaryData, debugInfo);
    } else {
      await this.doLayers(data, binaryData, debugInfo);
    }
  }
  private async doImage(data: PasteToolImageData | PasteToolImageToNewLayerData, binaryData?: Uint8Array, debugInfo?: string) {
    const layer = this.model.user.activeLayer;
    if (data.mode === PasteToolMode.Image && !layer) throw new Error(`[PasteTool] Missing activeLayer (${debugInfo})`);
    if (!binaryData) throw new Error(`[PasteTool] Missing binaryData (${debugInfo})`);

    if (!data.bounds) throw new Error('Missing drawing bounds');

    try {
      const start = now();
      const image = await loadImageOrImageDataFromData(binaryData);
      if (!image) throw new Error('Failed to decode image'); // `image` was undefined in production

      !SERVER && DEVELOPMENT && console.log(`decompressed in ${(now() - start).toFixed(2)}ms`);

      const rect = safeRect(data.rect);
      const transform = safeTransform(data.transform);

      if ((image.width !== rect.w || image.height !== rect.h) && !SERVER) {
        this.model.errorWithData('Invalid paste image size', `image: ${image.width}x${image.height}, rect: ${rectToString(rect)}`, binaryData);
      }

      logAction(`[remote] paste (mode: ${data.mode}, rect: ${rectToString(rect)})`);

      if (data.mode === PasteToolMode.Image) {
        await this.paste(data.bounds, layer!.id, rect, transform, binaryData, image, data.deselect);
        if (layer) layer.flags = layer.flags | LayerFlag.External;
      } else {
        // TODO: validate `data.layer`
        await this.pasteOnNewLayer(data.bounds, data.layer, data.index, rect, transform, binaryData, image, true);
        const layer = getLayer(this.editor.drawing, data.layer.id);
        if (layer) layer.flags = layer.flags | LayerFlag.External;
      }
      if ('close' in image) image.close();
    } catch (e) {
      logAction(`paste catch (${e.message})`);
      if (this.editor.type === 'client') {
        logAction(`report invalid_paste`);
        if (!/failed to create texture|An attempt was made to use an object that is not/i.test(e.message)) {
          const model = (this.editor as Editor).model;
          model?.server?.debug(model?.connId, 'invalid_paste', JSON.stringify({ localId: this.model.user.localId, t: data.t, error: e.message }));
        }
        // (this.editor as Editor).model?.errorWithData(e.message, binaryData || new Uint8Array([0]));
      }
      throw e;
    }
  }
  private async doLayers(data: PasteToolLayersData, binaryData?: Uint8Array, debugInfo?: string) {
    if (!binaryData) throw new Error(`[PasteTool] Missing binaryData (${debugInfo})`);

    const psdLayers = processPsdFileForPasting(binaryData);
    const layers = data.layers.map<PasteLayerData>(l => {
      const psdLayer = psdLayers[l.srcLayerIndex | 0];
      return { ...l, imageData: psdLayer.imageData };
    });

    await this.pasteLayers(data.sync, layers, data.layersOrder, binaryData);
  }
  async paste(drawingBounds: Rect, layerId: number, rect: Rect, transform: number[], binaryData: Uint8Array, image: AnyImageType, deselect: boolean) {
    finishTransform(this.editor, this.model.user, 'paste');

    const layer = getLayerSafe(this.editor.drawing, layerId);
    throwIfTextLayer(layer);
    const user = this.model.user;
    const beforeRect = cloneRect(layer.rect);

    user.history.execTransaction(history => {
      history.pushLayerId('paste', layerId);
      if (deselect) history.pushSelection('paste');
      history.pushTool('paste', true);
      history.pushLayerState(layerId);
    });

    if (deselect) clearMask(user.selection);
    setupSurface(user.surface, ToolId.Move, CompositeOp.Move, layer, drawingBounds);
    copyRect(user.surface.rect, rect);
    setTransform(user.surface, transform);
    layer.flags = layer.flags | LayerFlag.External;

    putImage(this.editor, user, image, rect);

    redrawDrawing(this.editor);
    redraw(this.editor); // redraw transform cage
    const afterRect = commitedLayerRect(user.surface, layer);

    await this.model.doToolWithData<PasteToolImageData>(layerId, {
      id: this.id, mode: PasteToolMode.Image, rect, transform, deselect,
      selection: deselect ? SelectionMode.Keep : SelectionMode.Break,
      br: beforeRect, ar: afterRect, bounds: cloneRect(drawingBounds)
    }, binaryData);
  }
  async pasteOnNewLayer(drawingBounds: Rect, layerData: LayerData, index: number, rect: Rect, transform: number[], binaryData: Uint8Array, image: AnyImageType, remote = false): Promise<void> {
    finishTransform(this.editor, this.model.user, 'pasteOnNewLayer');

    const user = this.model.user;
    const beforeRect = createRect(0, 0, 0, 0);

    user.history.execTransaction(history => {
      history.pushAddLayer(layerData, index);
      history.pushSelection('paste');
      history.pushTool('paste', true);
    });

    const layer = layerFromState(layerData);
    layer.flags = LayerFlag.External;
    addLayerToDrawing(this.editor, layer, index);
    layer.owner = user;

    clearMask(user.selection);
    setupSurface(user.surface, ToolId.Move, CompositeOp.Move, layer, drawingBounds);
    copyRect(user.surface.rect, rect);
    setTransform(user.surface, transform);

    putImage(this.editor, user, image, rect);

    redrawDrawing(this.editor);
    redraw(this.editor); // redraw transform cage
    const afterRect = commitedLayerRect(user.surface, layer);

    await this.model.doToolWithData<PasteToolImageToNewLayerData>(layerData.id, {
      id: this.id, mode: PasteToolMode.ImageToNewLayer, rect, transform, selection: SelectionMode.Break,
      br: beforeRect, ar: afterRect, layer: layerData, index, otherLayerIds: [layerData.id], bounds: cloneRect(drawingBounds)
    }, binaryData);

    if (!remote) sendLayerOrder(this.editor);
  }
  async pasteLayers(syncExistingLayers: boolean, layersData: PasteLayerData[], layersOrder: number[], binaryData: Uint8Array) {
    finishTransform(this.editor, this.model.user, 'pasteLayers');

    this.model.user.history.execTransaction(history => {
      // TODO: update drawing background ?

      for (const layerData of layersData) {
        let layer = getLayer(this.editor.drawing, layerData.id);

        if (!layer) {
          layer = layerFromState(layerData);
          addLayerToDrawing(this.editor, layer, 0);
          history.pushAddLayer(layerData, this.editor.drawing.layers.indexOf(layer));
        } else {
          history.pushLayerState(layer.id);
          updateLayerState(layer, layerData);
        }

        if (layerData.rect) {
          const rect = addRect(cloneRect(layer.rect), layerData.rect);
          if (!isRectEmpty(rect)) history.pushDirtyRect('paste layers', layer.id, rect);

          this.editor.renderer.releaseLayer(layer);
          copyRect(layer.rect, layerData.rect);

          if (layerData.imageData && !isRectEmpty(layer.rect)) {
            this.editor.renderer.initLayerFromBitmap(layer, imageDataToBitmapData(layerData.imageData), this.editor.drawing);
          }
        }

        layer.flags = layer.flags | LayerFlag.External;

        layerChanged(layer, true);
      }
    });

    this.editor.drawing.layers.sort((a, b) => layersOrder.indexOf(a.id) - layersOrder.indexOf(b.id));

    redrawDrawing(this.editor);
    redraw(this.editor);

    await this.model.doToolWithData<PasteToolLayersData>(0, {
      id: this.id, mode: PasteToolMode.Layers, sync: syncExistingLayers, selection: SelectionMode.Break,
      otherLayerIds: layersData.map(l => l.id),
      layers: layersData.map(({ imageData, ...rest }) => rest),
      layersOrder,
    }, binaryData);
  }
}

function putImage(editor: IToolEditor, user: User, image: AnyImageType, rect: Rect) {
  if ('data' in image) {
    editor.renderer.putImageData(user, image, rect);
  } else {
    editor.renderer.putImage(user, image, rect);
  }
}
