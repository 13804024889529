import { faInfoCircle, farCheckCircle, farExclamationCircle, farExclamationTriangle, infoIcon } from 'magma/common/icons';
import { IMsg, MsgBackground, MsgType, MsgVariant, MsgPosition } from 'magma/common/interfaces';

export const BASE_MSG = {
  message: '',
  timeout: 5000,
  background: MsgBackground.Light,
  icon: faInfoCircle,
  closable: true,
  variant: MsgVariant.Toast,
  position: MsgPosition.BottomLeft,
};

export const SUCCESS_MSG: IMsg = { ...BASE_MSG, type: MsgType.Success, icon: farCheckCircle };
export const ERROR_MSG: IMsg = { ...BASE_MSG, type: MsgType.Error, icon: farExclamationCircle };
export const INFO_MSG: IMsg = { ...BASE_MSG, type: MsgType.Info };
export const WARNING_MSG: IMsg = { ...BASE_MSG, icon: farExclamationTriangle, type: MsgType.Warning };
export const ALERT_MSG: IMsg = { ...BASE_MSG, type: MsgType.Alert };
export const NOTIFICATION_MSG: IMsg = { ...BASE_MSG, type: MsgType.Notification, icon: infoIcon };
export const LIMIT_WARNING_MSG: IMsg = { ...BASE_MSG, type: MsgType.LimitWarning, icon: farExclamationTriangle };


const BOXED_MSG = { variant: MsgVariant.Boxed, timeout: 0, closable: false };
const EMBEDDED_MSG = { variant: MsgVariant.Embedded, timeout: 0, closable: false };

export class AlertBoxed {
  static Success(toast: Partial<IMsg>) {
    return { ...SUCCESS_MSG, ...BOXED_MSG, ...toast };
  }
  static Error(toast: Partial<IMsg>) {
    return { ...ERROR_MSG, ...BOXED_MSG, ...toast };
  }
  static Info(toast: Partial<IMsg>) {
    return { ...INFO_MSG, ...BOXED_MSG, ...toast };
  }
  static Warning(toast: Partial<IMsg>) {
    return { ...WARNING_MSG, ...BOXED_MSG, ...toast };
  }
  static Alert(toast: Partial<IMsg>) {
    return { ...ALERT_MSG, ...BOXED_MSG, ...toast };
  }
  static Notification(toast: Partial<IMsg>) {
    return { ...NOTIFICATION_MSG, ...BOXED_MSG, ...toast };
  }
}

export class AlertEmbedded {
  static Success(toast: Partial<IMsg>) {
    return { ...SUCCESS_MSG, ...EMBEDDED_MSG, ...toast };
  }
  static Error(toast: Partial<IMsg>) {
    return { ...ERROR_MSG, ...EMBEDDED_MSG, ...toast };
  }
  static Info(toast: Partial<IMsg>) {
    return { ...INFO_MSG, ...EMBEDDED_MSG, ...toast };
  }
  static Warning(toast: Partial<IMsg>) {
    return { ...WARNING_MSG, ...EMBEDDED_MSG, ...toast };
  }
  static Alert(toast: Partial<IMsg>) {
    return { ...ALERT_MSG, ...EMBEDDED_MSG, ...toast };
  }
  static Notification(toast: Partial<IMsg>) {
    return { ...NOTIFICATION_MSG, ...EMBEDDED_MSG, ...toast };
  }
}
