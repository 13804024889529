import { blobToArrayBuffer } from './canvasUtils';
import { getMaxLayers, getMaxImportSize, MAX_IMAGE_HEIGHT_FREE, MAX_IMAGE_WIDTH_FREE, MAX_IMAGE_WIDTH_PRO, MIN_IMAGE_HEIGHT, MIN_IMAGE_WIDTH, MAX_LAYER_WIDTH, MAX_IMAGE_WIDTH, MAX_LAYER_HEIGHT } from './constants';
import { getImageInfo, ImageType, isImageTypeSupportedForImport } from './imageUtils';
import { Drawing, Layer, LayerData, CommonDrawingData, User, Allows, Feature } from './interfaces';
import { hasPermission } from './userRole';
import { fullName } from './userUtils';
import { formatBytes } from './utils';

export function isValidDrawingId(id: string): boolean {
  return !!id && typeof id === 'string' && /^[0-9a-zA-Z_-]{1,40}$/.test(id);
}

export function isValidLayerImage(id: string): boolean {
  return !!id && typeof id === 'string' && /^[!@$a-zA-Z0-9_.-]+$/.test(id);
}

export function isEmptyDrawing(drawing: CommonDrawingData | Drawing) {
  return !drawing.layers.some((l: Layer | LayerData) => !!l.image);
}

export function canOwnLayer(drawing: Drawing, user: User, layer: Layer) {
  return !canOwnLayerMessage(drawing, user, layer);
}

const LAYER_ALREADY_OWNED = `This layer already has an owner. Ask the owner to leave it or create new layer.`;

export function canOwnLayerMessage(drawing: Drawing, user: User, layer: Layer): string | undefined {
  if (!hasPermission(drawing, user, 'ownLayer')) {
    return `You don't have permission to own layers. You need permission from an admin first.`;
  }

  const canTakeOver = hasPermission(drawing, user, 'takeOver');

  if (canTakeOver) {
    return undefined;
  } else if (layer.owner) {
    return LAYER_ALREADY_OWNED;
  } else if (drawing.respectOfflineOwners && layer.layerOwner && !layer.layerOwner.left && layer.layerOwner?.name !== fullName(user)) {
    return LAYER_ALREADY_OWNED;
  } else {
    return undefined;
  }
}

export async function verifyFileForImport(file: File | Blob, allows: Allows) {
  const maxSize = getMaxImportSize(allows);

  if (file.size > maxSize) {
    if (DEVELOPMENT) {
      console.warn(`File is too big (max ${formatBytes(maxSize)})`);
    } else {
      throw new Error(`File is too big (max ${formatBytes(maxSize)})`);
    }
  }

  const data = await blobToArrayBuffer(file);
  if (!data) throw new Error('Invalid data');

  const buffer = new Uint8Array(data);
  const imageInfo = getImageInfo(buffer);
  const { type, width, height, layers } = imageInfo;
  const maxLayers = getMaxLayers(allows);

  if (type === ImageType.Unknown)
    throw new Error('Invalid image file');

  if (!isImageTypeSupportedForImport(type))
    throw new Error('Image type is not supported');

  if (!isImageSizeValid(width, height, allows)) {
    throw new Error(`Image size is too large (max ${getMaxImageSize(allows)})`);
  }

  if (layers > maxLayers) {
    throw new Error(`Image has too many layers (max ${maxLayers})`);
  }
  return { buffer, imageInfo };
}

export function getMaxLayerWidth(drawingWidth: number) {
  return Math.min(drawingWidth * 2, MAX_LAYER_WIDTH);
}

export function getMaxLayerHeight(drawingHeight: number) {
  return Math.min(drawingHeight * 2, MAX_LAYER_HEIGHT);
}

export function getMaxImageWidth(allows: Allows) {
  const size = allows.pro ? MAX_IMAGE_WIDTH_PRO : MAX_IMAGE_WIDTH_FREE;
  return Math.min(MAX_LAYER_WIDTH, allows.features?.has(Feature.Drawing16k) ? MAX_IMAGE_WIDTH : size);
}

export function getMaxImageSize(allows: Allows) {
  return `${getMaxImageWidth(allows)}x${getMaxImageWidth(allows)}`;
}

export function isImageSizeValid(width: number, height: number, allows: Allows) {
  return width <= getMaxImageWidth(allows) && height <= getMaxImageWidth(allows) &&
    width >= MIN_IMAGE_WIDTH && height >= MIN_IMAGE_HEIGHT;
}

export function doesImageSizeRequirePro(width: number, height: number) {
  return IS_PORTAL && !IS_HOSTED && (width > MAX_IMAGE_WIDTH_FREE || height > MAX_IMAGE_HEIGHT_FREE);
}

export function canHaveLargeCanvas(allows: Allows) {
  return allows.pro;
}

export const invalidImageSizeError = (allows: Allows) =>
  `Invalid image size, width and height must be in the range of (${MIN_IMAGE_WIDTH}-${getMaxImageWidth(allows)})`;