import { getContext2d } from '../common/canvasUtils';
import { Drawing, Point, Texture, TextureFormat, User, Viewport, WebGLResources } from '../common/interfaces';
import { isLayerVisible } from '../common/layer';
import { createMat4 } from '../common/mat4';
import { cloneRect, isRectEmpty, rectToString } from '../common/rect';
import { getSurfaceBounds, isSurfaceEmpty } from '../common/toolSurface';
import { getPixelRatio } from '../common/utils';
import { clamp } from '../common/mathUtils';
import { invalidEnum } from '../common/baseUtils';
import { absoluteDocumentToDocumentRect, createViewportMatrix4 } from '../common/viewport';
import { bindTexture, createEmptyTexture, resizeTexture, unbindTexture } from './webgl';
import { flushBatch, pushQuad } from './webglBatch';
import { copyCanvasToTexture, getTempCanvas, identityViewMatrix, pushAntialiasedQuadInView, pushAntialiasedQuadInViewRect, pushRect, releaseTexture } from './webglRenderer';
import { getShader } from './webglRenderingContext';
import { colorToRGBA } from '../common/color';

const tempMat4 = createMat4();

export function drawDebugLayerBounds(webgl: WebGLResources, drawing: Drawing, view: Viewport, user: User) {
  const { gl, batch } = webgl;
  const ratio = getPixelRatio();

  const shader = getShader(webgl, 'line');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight));

  for (const layer of drawing.layers) {
    if (!isLayerVisible(layer)) continue;

    if (!isRectEmpty(layer.rect)) {
      const r = cloneRect(layer.rect);
      absoluteDocumentToDocumentRect(r, drawing);
      pushAntialiasedQuadInViewRect(batch, r, ratio, view, 1, 0, 0);
    }
  }

  if (!isSurfaceEmpty(user.surface)) {
    const r = getSurfaceBounds(user.surface);
    absoluteDocumentToDocumentRect(r, drawing);
    pushAntialiasedQuadInViewRect(batch, r, ratio, view, 0, 1, 0);
  }

  flushBatch(batch);
}

export function formatToString(format: TextureFormat) {
  switch (format) {
    case TextureFormat.RGBA: return 'RGBA';
    case TextureFormat.Alpha: return 'ALPHA';
    default: invalidEnum(format);
  }
}

export function cloneAndDebugTexture(webgl: WebGLResources, srcTexture: Texture, id: string) {
  const { gl } = webgl;
  const dstTexture = createEmptyTexture(webgl, srcTexture.width, srcTexture.height);
  dstTexture.id = id;

  gl.bindTexture(gl.TEXTURE_2D, dstTexture.handle);
  gl.copyTexSubImage2D(gl.TEXTURE_2D, 0, 0, 0, 0, 0, srcTexture.width, srcTexture.height);
  gl.bindTexture(gl.TEXTURE_2D, null);

  const i = webgl.texturesToDebug.findIndex(t => t.id === id);
  if (i != -1) {
    releaseTexture(webgl, webgl.texturesToDebug[i]);
    webgl.texturesToDebug[i] = dstTexture;
  } else {
    webgl.texturesToDebug.push(dstTexture);
  }
}

export function drawDebugAllocatedTextures(webgl: WebGLResources, view: Viewport, drawing: Drawing, user: User) {
  const textures = [];
  textures.push(...webgl.texturesToDebug);
  if (webgl.maskTexture) textures.push(webgl.maskTexture);
  if (user.surface.texture) textures.push(user.surface.texture);

  drawing.layers.forEach(l => {
    if (l.texture) textures.push(l.texture);
  });

  if(webgl.spritesTexture) textures.push(webgl.spritesTexture);

  const { gl, batch } = webgl;
  const ratio = getPixelRatio();

  if (view.rotation) return;

  let shader = getShader(webgl, 'basic');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, createViewportMatrix4(tempMat4, view, false));

  const coords: Point[] = [];
  const maxY = drawing.h * 6;
  let maxX = 0;
  let x = drawing.w + 100, y = 0;
  let textY = 0, TH = 100, i = 0;

  for (const texture of textures) {
    coords.push({ x, y });
    maxX = Math.max(maxX, x + texture.width);
    y += texture.height + 200;

    if (y > maxY) {
      y = 0;
      x = maxX + 100;
    }
  }

  const tempCanvas = getTempCanvas(webgl, 2000, TH * (textures.length + 1), false);
  const tempContext = getContext2d(tempCanvas);
  tempContext.clearRect(0, 0, tempCanvas.width, tempCanvas.height);
  tempContext.save();
  tempContext.fillStyle = 'lime';
  tempContext.font = `bold ${clamp(Math.round(drawing.w * 0.05), 20, 80)}px Arial`;
  tempContext.textBaseline = 'top';

  // draw textures
  for (const texture of textures) {
    bindTexture(gl, 0, texture);
    pushQuad(batch, coords[i].x, coords[i].y, texture.width, texture.height, 0, 0, 1, 1, 0, 0, 1, 1, 1, 1);
    flushBatch(batch);
    if (texture === user.surface.texture) {
      tempContext.fillText(`surface rect:${rectToString(user.surface.rect)} ${user.surface.textureX},${user.surface.textureY}`, 0, textY);
    } else {
      tempContext.fillText(`${texture.id} [${formatToString(texture.format)}] ${texture.width}x${texture.height}`, 0, textY);
    }
    textY += TH;
    i++;
  }

  tempContext.fillText(`${drawing.x},${drawing.y} ${drawing.w}x${drawing.h} scale=${drawing.lod} tiles=${drawing.tiles.tiles.reduce((sum, row) => sum + row.length, 0)}, size=${drawing.tileSize + drawing.tileMarginSize * 2}`, 0, textY);
  tempContext.restore();

  if (!webgl.tempDebugTexture) webgl.tempDebugTexture = createEmptyTexture(webgl, tempCanvas.width, tempCanvas.height);
  if (webgl.tempDebugTexture.width !== tempCanvas.width || webgl.tempDebugTexture.height !== tempCanvas.height) resizeTexture(webgl, webgl.tempDebugTexture, tempCanvas.width, tempCanvas.height);

  copyCanvasToTexture(gl, tempContext, webgl.tempDebugTexture, tempCanvas.width, tempCanvas.height);
  tempContext.clearRect(0, 0, tempCanvas.width, tempCanvas.height);
  bindTexture(gl, 0, webgl.tempDebugTexture);

  i = 0;
  let ty = 0, th = TH / webgl.tempDebugTexture.height;

  // draw labels
  for (const _ of textures) {
    if (!coords[i]) continue;
    pushQuad(batch, coords[i].x, coords[i].y - TH - 10, webgl.tempDebugTexture.width, TH, 0, ty, 1, th, 0, 0, 1, 1, 1, 1);
    ty += th;
    i++;
  }
  pushQuad(batch, 0, -100, webgl.tempDebugTexture.width, TH, 0, ty, 1, th, 0, 0, 1, 1, 1, 1);

  flushBatch(batch);
  unbindTexture(gl, 0);

  if (null) {
    gl.uniformMatrix4fv(shader.uniforms.transform, false, identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight));
    // draw tiles
    const margin = 10;
    const ox = 10;
    const oy = 50;
    const size = 256;
    const column = drawing.tiles.tiles;

    const tileSize = (drawing.tileSize + 2 * drawing.tileMarginSize);

    let offestX = ox;
    for (let x = 0; x < column.length; x++) {
      let offsetY = oy;
      const row = column[x];
      for (let y = 0; y < row.length; y++) {
        const tile = row[y];
        if (tile) {
          const xx = x === 0 ? size * (tileSize - tile.textureRect.w / drawing.lod) / (tileSize) : 0;
          const yy = y === 0 ? size * (tileSize - tile.textureRect.h / drawing.lod) / (tileSize) : 0;
          const w = size * (tile.textureRect.w / drawing.lod / tileSize);
          const h = size * (tile.textureRect.h / drawing.lod / tileSize);

          bindTexture(gl, 0, tile.texture);
          pushQuad(batch, offestX + xx, offsetY + yy, w, h, 0, 0, tileSize / (tile.texture.width), tileSize / (tile.texture.height), 0, 0, 1, 1, 1, 1);
          flushBatch(batch);
          unbindTexture(gl, 0);
        }

        offsetY += size + margin;
      }
      offestX += size + margin;
    }

    shader = getShader(webgl, 'line');
    gl.useProgram(shader.program);
    gl.uniformMatrix4fv(shader.uniforms.transform, false, identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight));

    offestX = ox;
    for (let x = 0; x < column.length; x++) {
      let offsetY = oy;
      const row = column[x];
      for (let y = 0; y < row.length; y++) {
        const tile = row[y];
        if (tile) {
          const xx = x === 0 ? size * (tileSize * drawing.lod - tile.textureRect.w) / (tileSize * drawing.lod) : 0;
          const yy = y === 0 ? size * (tileSize * drawing.lod - tile.textureRect.h) / (tileSize * drawing.lod) : 0;
          const w = size * tile.textureRect.w / (tileSize * drawing.lod);
          const h = size * tile.textureRect.h / (tileSize * drawing.lod);
          pushRect(batch, offestX + xx, offsetY + yy, w, h, 0, 1, 1, 1);
        }
        offsetY += size + margin;
      }
      offestX += size + margin;
    }
    flushBatch(batch);
  }

  // draw outlines
  i = 0;
  shader = getShader(webgl, 'line');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, identityViewMatrix(gl.drawingBufferWidth, gl.drawingBufferHeight));

  for (const texture of textures) {
    pushAntialiasedQuadInView(batch, coords[i].x, coords[i].y, texture.width, texture.height, ratio, view, 0, 0, 1);
    i++;
  }
  flushBatch(batch);
}



export function drawDebugMarkers(webgl: WebGLResources, view: Viewport) {
  if (!webgl.markers) return;

  const { gl, batch } = webgl;

  const shader = getShader(webgl, 'vertexColor');
  gl.useProgram(shader.program);
  gl.uniformMatrix4fv(shader.uniforms.transform, false, createViewportMatrix4(tempMat4, view, false));

  const s = 1 / view.scale;

  for (const { x, y, color } of webgl.markers) {
    const c = colorToRGBA(color);
    pushQuad(batch, x - s, y - s, s * 2, s * 2, 0, 0, 0, 0, 0, 0, c.r / 255, c.g / 255, c.b / 255, c.a / 255);
  }

  flushBatch(batch);
}
