import { Component, ChangeDetectionStrategy, Output, Input, EventEmitter } from '@angular/core';
import { faCheck, faTimes } from '../../../common/icons';

let id = 1;

@Component({
  selector: 'custom-switch',
  templateUrl: 'custom-switch.pug',
  styleUrls: ['custom-switch.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomSwitch {
  readonly timesIcon = faTimes;
  readonly checkIcon = faCheck;

  @Input() disabled = false;
  @Input() checked = false;
  @Input() size: 'sm' | 'md' = 'sm';

  @Output() checkedChange = new EventEmitter<boolean>();
  id = `custom-switch-${++id}`;
}
