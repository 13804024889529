import { IPredictor, ToolDecoder } from './interfaces';
import { clamp } from './mathUtils';
import { invalidEnum } from './baseUtils';

export function encodePressure(p: number) {
  return Math.round(p * 1023) | 0;
}

export function decodePressure(p: number) {
  return p / 1023;
}

export function roundCoord(value: number) {
  return Math.round(value * 16) / 16;
}

export function encodeCoord(value: number) {
  return Math.round(value * 16) | 0;
}

export function decodeCoord(value: number) {
  return value / 16;
}

function createPredictor(): IPredictor {
  return { a: 0, b: 0 };
}

function predictorInit(predictor: IPredictor, value: number) {
  predictor.a = value;
  predictor.b = value;
}

function predictorPush(predictor: IPredictor, value: number) {
  predictor.a = predictor.b;
  predictor.b = value;
}

function predictorNext(predictor: IPredictor) {
  return predictor.b + (predictor.b - predictor.a);
}

export const enum BufferedEntry {
  Zero = 0,
  U8 = 1,
  U16 = 2,
  U24 = 3,
  U32 = 4,
  U40 = 5,
  U48 = 6,
  U64 = 7,
}

export interface BufferedEncoder {
  x: IPredictor;
  y: IPredictor;
  p: IPredictor;
  offset: number;
  lastTypeOffset: number;
  buffer: Uint8Array;
}

export function createBufferedEncoder(): BufferedEncoder {
  const x = createPredictor();
  const y = createPredictor();
  const p = createPredictor();
  return { x, y, p, offset: 0, lastTypeOffset: 0, buffer: new Uint8Array(16) };
}

export function startBufferedEncoder(encoder: BufferedEncoder, x: number, y: number, pressure: number) {
  if (DEVELOPMENT && encoder.offset) {
    console.error(`Resetting uncommited encoder`);
  }

  encoder.offset = 0;

  predictorInit(encoder.x, encodeCoord(x));
  predictorInit(encoder.y, encodeCoord(y));
  predictorInit(encoder.p, pressure | 0);
}

function writeEntryType(encoder: BufferedEncoder, type: BufferedEntry) {
  const last = encoder.buffer[encoder.lastTypeOffset];

  if (encoder.offset && ((last & 0b111) === type) && ((last & 0b11111000) !== 0b11111000)) {
    encoder.buffer[encoder.lastTypeOffset] = (last & 0b111) | (((last >> 3) + 1) << 3);
  } else {
    encoder.lastTypeOffset = encoder.offset;
    encoder.buffer[encoder.offset] = type;
    encoder.offset += 1;
  }
}

export function moveBufferedEncoder(encoder: BufferedEncoder, xx: number, yy: number, pp: number) {
  let x = encodeCoord(xx);
  let y = encodeCoord(yy);
  let p = pp | 0;

  let dx = x - predictorNext(encoder.x);
  let dy = y - predictorNext(encoder.y);
  let dp = p - predictorNext(encoder.p);
  const left = encoder.buffer.length - encoder.offset;

  predictorPush(encoder.x, x);
  predictorPush(encoder.y, y);
  predictorPush(encoder.p, p);

  if (left < 9) doubleCapacity(encoder);

  if (dx === 0 && dy === 0 && dp === 0) {
    writeEntryType(encoder, BufferedEntry.Zero);
  } else if (dx >= -2 && dx <= 1 && dy >= -2 && dy <= 1 && dp >= -8 && dp <= 7) {
    // 2, 2, 4 (8 bits)
    dx += 2;
    dy += 2;
    dp += 8;
    writeEntryType(encoder, BufferedEntry.U8);
    encoder.buffer[encoder.offset] = (dx << 6) | (dy << 4) | dp;
    encoder.offset += 1;
  } else if (dx >= -8 && dx <= 7 && dy >= -8 && dy <= 7 && dp >= -128 && dp <= 127) {
    // 4, 4, 8 (16 bits)
    dx += 8;
    dy += 8;
    dp += 128;
    writeEntryType(encoder, BufferedEntry.U16);
    encoder.buffer[encoder.offset] = dp;
    encoder.buffer[encoder.offset + 1] = (dx << 4) | dy;
    encoder.offset += 2;
  } else if (dx >= -64 && dx <= 63 && dy >= -64 && dy <= 63) {
    // 7, 7, 10 (24 bits)
    dx += 64;
    dy += 64;
    const a = (dx << 9) | (dy << 2) | (p >> 8);
    writeEntryType(encoder, BufferedEntry.U24);
    encoder.buffer[encoder.offset] = a & 0xff;
    encoder.buffer[encoder.offset + 1] = (a >> 8) & 0xff;
    encoder.buffer[encoder.offset + 2] = p & 0xff;
    encoder.offset += 3;
  } else if (dx >= -1024 && dx <= 1023 && dy >= -1024 && dy <= 1023) {
    // 11, 11, 10 (32 bits)
    dx += 1024;
    dy += 1024;
    const a = ((dx << 21) | (dy << 10) | p) >>> 0;
    writeEntryType(encoder, BufferedEntry.U32);
    encoder.buffer[encoder.offset] = a & 0xff;
    encoder.buffer[encoder.offset + 1] = (a >>> 8) & 0xff;
    encoder.buffer[encoder.offset + 2] = (a >>> 16) & 0xff;
    encoder.buffer[encoder.offset + 3] = (a >>> 24) & 0xff;
    encoder.offset += 4;
  } else if (x >= -16384 && x <= 16383 && y >= -16384 && y <= 16383) {
    // 15, 15, 10 (40 bits)
    x += 16384;
    y += 16384;
    const a = ((x << 17) | (y << 2) | (p >> 8)) >>> 0;
    writeEntryType(encoder, BufferedEntry.U40);
    encoder.buffer[encoder.offset] = a & 0xff;
    encoder.buffer[encoder.offset + 1] = (a >>> 8) & 0xff;
    encoder.buffer[encoder.offset + 2] = (a >>> 16) & 0xff;
    encoder.buffer[encoder.offset + 3] = (a >>> 24) & 0xff;
    encoder.buffer[encoder.offset + 4] = p & 0xff;
    encoder.offset += 5;
  } else if (x >= -262144 && x <= 262143 && y >= -262144 && y <= 262143) {
    // 19, 19, 10 (48 bits)
    x += 262144;
    y += 262144;
    const a = ((x << 13) | (y >> 6)) >>> 0;
    const b = ((y << 10) | p) >>> 0;
    writeEntryType(encoder, BufferedEntry.U48);
    encoder.buffer[encoder.offset] = a & 0xff;
    encoder.buffer[encoder.offset + 1] = (a >>> 8) & 0xff;
    encoder.buffer[encoder.offset + 2] = (a >>> 16) & 0xff;
    encoder.buffer[encoder.offset + 3] = (a >>> 24) & 0xff;
    encoder.buffer[encoder.offset + 4] = b & 0xff;
    encoder.buffer[encoder.offset + 5] = (b >>> 8) & 0xff;
    encoder.offset += 6;
  } else {
    // 27, 27, 10 (64 bits)
    x = clamp(x, -67108864, 67108863);
    y = clamp(y, -67108864, 67108863);
    x += 67108864;
    y += 67108864;
    writeEntryType(encoder, BufferedEntry.U64);
    encoder.buffer[encoder.offset + 0] = (x >> 19) & 0xff;
    encoder.buffer[encoder.offset + 1] = (x >> 11) & 0xff;
    encoder.buffer[encoder.offset + 2] = (x >> 3) & 0xff;
    encoder.buffer[encoder.offset + 3] = ((x << 5) & 0b1110_0000) | ((y >> 22) & 0b0001_1111);
    encoder.buffer[encoder.offset + 4] = (y >> 14) & 0xff;
    encoder.buffer[encoder.offset + 5] = (y >> 6) & 0xff;
    encoder.buffer[encoder.offset + 6] = ((y << 2) & 0b1111_1100) | ((p >> 8) & 0b0000_0011);
    encoder.buffer[encoder.offset + 7] = p & 0xff;
    encoder.offset += 8;
  }
}

export function readBufferedEncoderBuffer(encoder: BufferedEncoder) {
  const result = encoder.buffer.subarray(0, encoder.offset);
  encoder.offset = 0;
  return result;
}

export function clearBufferedEncoder(encoder: BufferedEncoder) {
  encoder.offset = 0;
}

function doubleCapacity(encoder: BufferedEncoder) {
  const newSize = encoder.buffer.byteLength * 2;

  try {
    const newBuffer = new Uint8Array(newSize);
    newBuffer.set(encoder.buffer);
    encoder.buffer = newBuffer;
  } catch (e: any) {
    throw new Error(`Failed to allocate buffer (size: ${newSize}, error: ${e.stack || e.message || e})`);
  }
}

export function createDecoder(handler: (x: number, y: number, p: number) => void): ToolDecoder {
  const x = createPredictor();
  const y = createPredictor();
  const p = createPredictor();
  return { x, y, p, handler };
}

export function startDecoder(decoder: ToolDecoder, x: number, y: number, p: number) {
  predictorInit(decoder.x, encodeCoord(x));
  predictorInit(decoder.y, encodeCoord(y));
  predictorInit(decoder.p, p);
}

export function nextToolDecoder(decoder: ToolDecoder, x: number, y: number, p: number) {
  decoder.handler(x, y, p);
  predictorPush(decoder.x, encodeCoord(x));
  predictorPush(decoder.y, encodeCoord(y));
  predictorPush(decoder.p, p);
}

function nextMove(decoder: ToolDecoder, x: number, y: number, p: number) {
  const xx = decodeCoord(x);
  const yy = decodeCoord(y);

  decoder.handler(xx, yy, p);

  predictorPush(decoder.x, x);
  predictorPush(decoder.y, y);
  predictorPush(decoder.p, p);
}

export function nextToolArrayDecoder(decoder: ToolDecoder, moves: Uint8Array) {
  for (let i = 0; i < moves.byteLength;) {
    const header = moves[i];
    const type = (header & 0b111) as BufferedEntry;
    let count = (header >> 3) + 1;
    i++;
    switch (type) {
      case BufferedEntry.Zero: {
        while (count--) {
          const x = predictorNext(decoder.x);
          const y = predictorNext(decoder.y);
          const p = predictorNext(decoder.p);
          nextMove(decoder, x, y, p);
        }
        break;
      }
      case BufferedEntry.U8: {
        while (count--) {
          // 2, 2, 4
          const a = moves[i];
          const dx = (a >> 6) & 0x03;
          const dy = (a >> 4) & 0x03;
          const dp = a & 0x0f;
          const x = predictorNext(decoder.x) + (dx - 2);
          const y = predictorNext(decoder.y) + (dy - 2);
          const p = predictorNext(decoder.p) + (dp - 8);
          nextMove(decoder, x, y, p);
          i += 1;
        }
        break;
      }
      case BufferedEntry.U16: {
        while (count--) {
          // 4, 4, 8
          const a = moves[i];
          const b = moves[i + 1];
          const dx = (b >> 4) & 0x0f;
          const dy = b & 0x0f;
          const dp = a;
          const x = predictorNext(decoder.x) + (dx - 8);
          const y = predictorNext(decoder.y) + (dy - 8);
          const p = predictorNext(decoder.p) + (dp - 128);
          nextMove(decoder, x, y, p);
          i += 2;
        }
        break;
      }
      case BufferedEntry.U24: {
        while (count--) {
          // 7, 7, 10
          const a = (moves[i] << 8) | (moves[i + 1] << 16) | moves[i + 2];
          const dx = (a >> 17) & 0x7f;
          const dy = (a >> 10) & 0x7f;
          const p = a & 0x3ff;
          const x = predictorNext(decoder.x) + (dx - 64);
          const y = predictorNext(decoder.y) + (dy - 64);
          nextMove(decoder, x, y, p);
          i += 3;
        }
        break;
      }
      case BufferedEntry.U32: {
        while (count--) {
          // 11, 11, 10
          const a = (moves[i] | (moves[i + 1] << 8) | (moves[i + 2] << 16) | (moves[i + 3] << 24)) >>> 0;
          const dx = (a >> 21) & 0x7ff;
          const dy = (a >> 10) & 0x7ff;
          const p = a & 0x3ff;
          const x = predictorNext(decoder.x) + (dx - 1024);
          const y = predictorNext(decoder.y) + (dy - 1024);
          nextMove(decoder, x, y, p);
          i += 4;
        }
        break;
      }
      case BufferedEntry.U40: {
        while (count--) {
          // 15, 15, 10
          const a = (moves[i] | (moves[i + 1] << 8) | (moves[i + 2] << 16) | (moves[i + 3] << 24)) >>> 0;
          const b = moves[i + 4];
          const tx = (a >> 17) & 0x7fff;
          const ty = (a >> 2) & 0x7fff;
          const x = tx - 16384;
          const y = ty - 16384;
          const p = ((a << 8) | b) & 0x3ff;
          nextMove(decoder, x, y, p);
          i += 5;
        }
        break;
      }
      case BufferedEntry.U48: {
        while (count--) {
          // 19, 19, 10
          const a = (moves[i] | (moves[i + 1] << 8) | (moves[i + 2] << 16) | (moves[i + 3] << 24)) >>> 0;
          const b = (moves[i + 4] | (moves[i + 5] << 8)) >>> 0;
          const tx = (a >> 13) & 0x7ffff;
          const ty = ((a << 6) | (b >> 10)) & 0x7ffff;
          const x = tx - 262144;
          const y = ty - 262144;
          const p = b & 0x3ff;
          nextMove(decoder, x, y, p);
          i += 6;
        }
        break;
      }
      case BufferedEntry.U64: {
        while (count--) {
          // 27, 27, 10
          const tx = ((moves[i + 0] << 19) | (moves[i + 1] << 11) | (moves[i + 2] << 3) | ((moves[i + 3] >> 5) & 0b111)) >>> 0;
          const ty = (((moves[i + 3] & 0b1_1111) << 22) | (moves[i + 4] << 14) | (moves[i + 5] << 6) | (moves[i + 6] >> 2)) >>> 0;
          const p = ((moves[i + 6] & 0b11) << 8) | moves[i + 7];
          const x = tx - 67108864;
          const y = ty - 67108864;
          nextMove(decoder, x, y, p);
          i += 8;
        }
        break;
      }
      default:
        invalidEnum(type);
    }
  }
}
