import { Vec2, Mat2d } from './interfaces';

export function createVec2(): Vec2 {
  return new Float32Array(2);
}

export function createVec2FromValues(x: number, y: number): Vec2 {
  const out = createVec2();
  out[0] = x;
  out[1] = y;
  return out;
}

export function setVec2(out: Vec2, x: number, y: number) {
  out[0] = x;
  out[1] = y;
  return out;
}

export function copyVec2(out: Vec2, a: Vec2 | number[]) {
  out[0] = a[0];
  out[1] = a[1];
  return out;
}

export function cloneVec2(a: Vec2 | number[]) {
  const out = createVec2();
  out[0] = a[0];
  out[1] = a[1];
  return out;
}

export function addVec2(out: Vec2 | number[], a: Vec2 | number[], b: Vec2 | number[]) {
  out[0] = a[0] + b[0];
  out[1] = a[1] + b[1];
  return out;
}

export function transformVec2ByMat2d(out: Vec2 | number[], a: Vec2 | number[], m: Mat2d) {
  const x = a[0], y = a[1];
  out[0] = m[0] * x + m[2] * y + m[4];
  out[1] = m[1] * x + m[3] * y + m[5];
  return out;
}

export function distanceVec2(a: Vec2, b: Vec2): number {
  return Math.sqrt((b[0] - a[0]) ** 2 + (b[1] - a[1]) ** 2);
}

export function cloneBounds(bounds: Vec2[]) {
  return [cloneVec2(bounds[0]), cloneVec2(bounds[1]), cloneVec2(bounds[2]), cloneVec2(bounds[3])];
}

export function outsetBounds(bounds: Vec2[], d: number) {
  const minX = Math.min(bounds[0][0], bounds[1][0], bounds[2][0], bounds[3][0]);
  const minY = Math.min(bounds[0][1], bounds[1][1], bounds[2][1], bounds[3][1]);
  const maxX = Math.max(bounds[0][0], bounds[1][0], bounds[2][0], bounds[3][0]);
  const maxY = Math.max(bounds[0][1], bounds[1][1], bounds[2][1], bounds[3][1]);
  for (let j = 0; j < bounds.length; j++) {
    if (bounds[j][0] === minX) bounds[j][0] = minX - d;
    if (bounds[j][1] === minY) bounds[j][1] = minY - d;
    if (bounds[j][0] === maxX) bounds[j][0] = maxX + d;
    if (bounds[j][1] === maxY) bounds[j][1] = maxY + d;
  }
}

export function getParallelPoints(a: Vec2, b: Vec2, distance: number, ctxTranfsorm: Mat2d | undefined = undefined) {
  let flips = 1;
  if (ctxTranfsorm) {
    if (ctxTranfsorm[0] < 0) flips = -flips;
    if (ctxTranfsorm[3] < 0) flips = -flips;
  }
  const dx = b[0] - a[0];
  const dy = b[1] - a[1];
  const mag = distanceVec2(a, b);
  const u = createVec2FromValues(-dy / mag, dx / mag);
  const offset = createVec2FromValues(distance * u[0], distance * u[1]);
  const p1 = createVec2FromValues(a[0] + offset[0] * (flips), a[1] + offset[1] * (flips));
  const p2 = createVec2FromValues(b[0] + offset[0] * (flips), b[1] + offset[1] * (flips));
  return [ p1, p2 ];
}

export function getPointInOppositeDirection(a: Vec2, b: Vec2, distance: number) {
  const mag = distanceVec2(a, b);
  const uX = (b[0] - a[0]) / mag;
  const uY = (b[1] - a[1]) / mag;
  return createVec2FromValues(a[0] - uX * distance, a[1] - uY * distance);
}

export function vec2Equal(a: Vec2, b: Vec2): boolean {
  return a[0] === b[0] && a[1] === b[1];
}
