import { Rect } from './interfaces';

export function object(value: object) {
  if (!value || typeof value !== 'object') throw new Error('Invalid value');
}

export function integerRange(value: number, min: number, max: number) {
  if ((value | 0) !== value || value < min || value > max) throw new Error(`Invalid value`);
}

export function booleanOrUndefined(value: boolean | undefined) {
  if (value !== undefined && value !== true && value !== false) throw new Error(`Invalid value`);
}

export function number(value: any): asserts value is number {
  if (!Number.isFinite(value)) throw new Error('Invalid value');
}

export function numberRange(value: number, min: number, max: number, greaterEqual = false) {
  if (typeof value !== 'number') throw new Error(`Invalid value`);

  if (greaterEqual) {
    if (value < min || value > max) throw new Error(`Invalid value`);
  } else {
    if (value <= min || value >= max) throw new Error(`Invalid value`);
  }
}

export function numberRangeOrUndefined(value: number | undefined, min: number, max: number, greaterEqual = false) {
  if (value !== undefined) numberRange(value, min, max, greaterEqual);
}

export function string(value: string, maxLength: number) {
  if (typeof value !== 'string') throw new Error(`Invalid value`);
  if (value.length > maxLength) throw new Error(`Invalid string length ${value.length} / ${maxLength}`);
}

export function stringOrUndefined(value: string | undefined, maxLength: number) {
  if (value === undefined) return;
  string(value, maxLength);
}

export function stringEnumOrUndefined(value: string | undefined, values: string[]) {
  if (value !== undefined && typeof value !== 'string' && !values.includes(value)) throw new Error(`Invalid value`);
}

export function rectOrUndefined(value: Rect | undefined) {
  if (value === undefined) return;
  assertRect(value);
}

export function assertRect(value: Rect | undefined): asserts value is Rect {
  if (typeof value !== 'object' || value == null || typeof value.x !== 'number' || typeof value.y !== 'number' ||
    typeof value.w !== 'number' || typeof value.h !== 'number') {
    throw new Error(`Invalid value`);
  }
}

export function array(value: any[], maxLength = 0) {
  if (!Array.isArray(value)) throw new Error(`Invalid array`);
  if (maxLength && value.length > maxLength) throw new Error(`Invalid array length`);
}
