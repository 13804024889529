import { ChangeDetectionStrategy, Component, Directive, ElementRef, Host, HostBinding, HostListener, Input, OnDestroy, OnInit, Optional, ViewChild, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Command, isBetaFeature, ITool, Layer, ToolSource } from '../../../common/interfaces';
import { getCommandTitle, getFilteredShortcut, getShortcutsAsString } from '../../../common/settingsUtils';
import { toolIdToString } from '../../../common/toolIdUtils';
import { isMobile } from '../../../common/utils';
import { CommandService } from '../../../services/commandService';
import { Model } from '../../../services/model';
import { AgTooltip, DEFAULT_TOOLTIP_PLACEMENT, TooltipContainer, TooltipInstance, TooltipService } from './tooltip';
import { UserFlowId } from '../../../common/constants';

@Component({
  selector: 'command-menu-inner',
  templateUrl: 'command-menu-inner.pug',
})
export class CommandMenuInner {
  @Input() showShortcut = true;
  @Input() beta = false; // TODO: replace with feature flag on the Command
  constructor(@Host() private commandDirective: CommandDirective) { }

  get icons() {
    return this.commandDirective.icons;
  }
  get name() {
    return this.commandDirective.name;
  }
  get shortcut() {
    return this.commandDirective.shortcut;
  }
  get tooltip() {
    const command = this.commandDirective.theCommand.value;
    if (command && this.commandDirective.disabled) {
      return command.disabledTooltip?.(this.commandDirective);
    } else {
      return undefined;
    }
  }
}

@Component({
  selector: 'command-menu-item',
  template: `<a class="dropdown-item" [tooltip]="tooltip" [placement]="placement" [command]="name" #commandDirective=commandDirective [commandLayer]="layer" [commandDrawingId]="drawingId" commandClick>` +
    `<command-menu-inner [beta]="beta" [showShortcut]="showShortcut"></command-menu-inner></a>`
})
export class CommandMenuItem {
  @Input() name!: string;
  @Input() overideShowShortcut = false;
  @Input() layer?: Layer;
  @Input() drawingId?: string;
  @Input() beta = false; // TODO: replace with feature flag on the Command
  @Input() placement: AgTooltip['placement'] = DEFAULT_TOOLTIP_PLACEMENT;
  showShortcut = true;

  @ViewChild('commandDirective', { static: true }) commandDirective!: CommandDirective;
  get tooltip() {
    const command = this.commandDirective.theCommand.value;
    if (command && this.commandDirective.disabled) {
      return command.disabledTooltip?.(this.commandDirective);
    } else {
      return undefined;
    }
  }

  constructor(
    @Optional() @Host() private commandHideShortcuts: CommandHideShortcuts,
  ) {
    this.showShortcut = this.overideShowShortcut || !this.commandHideShortcuts;
  }
}

@Directive({ selector: '[commandTitle]' })
export class CommandTitle implements OnDestroy {
  private subscription: Subscription;
  constructor(private element: ElementRef, @Host() private commandDirective: CommandDirective) {
    element.nativeElement.title = this.commandDirective.title;
    this.subscription = this.commandDirective.theCommand.subscribe(() => {
      this.element.nativeElement.title = this.commandDirective.title;
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}


@Directive({ selector: '[commandHideShortcuts]' })
export class CommandHideShortcuts {
}

@Directive({ selector: 'button[command]' })
export class ButtonCommand {
  private target: Command | undefined = undefined;
  constructor(private commandService: CommandService) {
  }
  @Input() set command(value: string) {
    this.target = this.commandService.get(value);
  }
  @Input('commandLayer') layer?: Layer;
  @Input('commandDrawingId') drawingId?: string;
  @HostBinding('disabled')
  get disabled() {
    return !this.commandService.canExecute(this.target, this);
  }
}

@Directive({ selector: '[command]', exportAs: 'commandDirective' })
export class CommandDirective implements OnDestroy {
  //@Input() hasTitle = true;
  @Input('commandLayer') layer?: Layer;
  @Input('commandDrawingId') drawingId?: string;
  private target?: Command;
  theCommand = new BehaviorSubject<Command | undefined>(undefined);
  private subscription: Subscription;
  constructor(private commandService: CommandService, private model: Model) {
    this.subscription = model.settingsChanged.subscribe(() => this.theCommand.next(this.target));
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  @Input() set command(value: string) {
    this.target = this.commandService.get(value);
    this.theCommand.next(this.target);

    if (DEVELOPMENT && value && !this.target) {
      console.error(`invalid command: ${value}`);
    }
  }
  get icons() {
    return this.target?.icons ?? [];
  }
  get shortcut() {
    return this.target && getShortcutsAsString(this.model, this.target.id);
  }
  get name() {
    return this.target?.name;
  }
  //@HostBinding('title')
  get title() {
    return getCommandTitle(this.model, this.target);
  }
  @HostBinding('class.disabled')
  get disabled() {
    return !this.commandService.canExecute(this.target, this);
  }
  @HostBinding('class.has-disabled-tooltip')
  get hasDisabledTooltip() {
    return this.disabled && !!this.target?.disabledTooltip?.(this);
  }
  @HostBinding('class.help-highlight')
  get highlight() {
    return this.target?.highlight;
  }
  // @HostListener('click', ['$event'])
  // click(e: MouseEvent | KeyboardEvent) {
  //   this.commandService.executeCommand(this.target, this, e);
  // }
  exec(e: MouseEvent | KeyboardEvent | TouchEvent) {
    return this.commandService.executeCommand(this.target, this, ToolSource.ButtonPress, e);
  }
}

@Directive({ selector: '[commandClick]' })
export class CommandClick {
  private last = 0;
  constructor(@Host() private commandDirective: CommandDirective) {
  }
  @HostListener('click', ['$event'])
  click(e: Event) {
    const delay = 100;
    if ((performance.now() - this.last) > delay) {
      this.commandDirective.exec(e as MouseEvent | KeyboardEvent).catch(e => DEVELOPMENT && console.error(e));
      this.last = performance.now();
    } else {
      DEVELOPMENT && console.warn('commandClick prevented');
    }
  }
}

@Directive({ selector: '[commandPress]' })
export class CommandPress {
  constructor(element: ElementRef<HTMLElement>, @Host() commandDirective: CommandDirective) {
    if (typeof PointerEvent !== 'undefined') {
      element.nativeElement.addEventListener('pointerdown', e => commandDirective.exec(e));
    } else {
      element.nativeElement.addEventListener('mousedown', e => commandDirective.exec(e));
      element.nativeElement.addEventListener('touchstart', e => commandDirective.exec(e));
    }
  }
}

@Component({
  selector: 'command-icon',
  template: '<svg-icon *ngFor="let icon of icons; let i = index" [autoWidth]="!!i" [icon]="icon"></svg-icon>',
  styles: [':host { white-space: nowrap; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommandIcon implements OnInit, OnDestroy {
  private subscription?: Subscription;
  icons?: any[];
  constructor(private element: ElementRef<HTMLElement>, @Host() private commandDirective: CommandDirective) {
  }
  ngOnInit() {
    this.subscription = this.commandDirective.theCommand.subscribe(() => {
      this.icons = this.commandDirective.icons;
      this.element.nativeElement.setAttribute('role', 'img');
      this.element.nativeElement.setAttribute('aria-label', this.commandDirective.title);
    });
  }
  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}

@Component({
  selector: 'command-shortcut',
  template: '<kbd *ngIf="shortcut">{{shortcut}}</kbd>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommandShortcut {
  constructor(@Host() private commandDirective: CommandDirective) {
  }
  get shortcut() {
    return this.commandDirective.shortcut;
  }
}

function hasRichTooltip(model: Model, data: ITool | Command | undefined) {
  return !!(model.settings.richTooltips && data && (data.description || data.video || data.learnMore));
}

@Directive({
  selector: '[commandTip]',
  exportAs: 'command-tip'
})
export class CommandTip implements OnDestroy {
  @Input() get adaptivePosition() { return this.tip.adaptivePosition; }
  /*    */ set adaptivePosition(value) { this.tip.adaptivePosition = value; }
  @Input() get placement() { return this.tip.placement; }
  /*    */ set placement(value) { this.tip.placement = value; }
  @Input() get commandTipDisabled() { return this.tip.isDisabled; }
  /*    */ set commandTipDisabled(value) { this.tip.isDisabled = value; }
  private tip: TooltipInstance;
  constructor(
    elementRef: ElementRef<HTMLElement>,
    viewContainerRef: ViewContainerRef,
    tooltipService: TooltipService,
    @Host() commandDirective: CommandDirective,
    model: Model,
  ) {
    this.tip = tooltipService.create(elementRef, viewContainerRef, CommandTooltipContainer);
    this.tip.beforeShow = () => {
      this.tip.data = commandDirective.theCommand.value;
      this.tip.closeDelay = hasRichTooltip(model, this.tip.data) ? (isMobile ? 1000 : 300) : 0;
    };
    this.tip.delay = 500;
    this.tip.tooltip = '.';
    this.tip.boundariesElement = 'window';
    this.tip.containerClass = 'tooltip-command';
    this.tip.keepOpenWhenHovered = true;
  }
  ngOnDestroy() {
    this.tip.destroy();
  }
}

@Directive({
  selector: '[commandTooltip]',
  exportAs: 'command-tooltip'
})
export class CommandTooltip implements OnDestroy {
  @Input() get commandTooltip(): ITool | Command | undefined { return this.tip.data; }
  /*    */ set commandTooltip(value) { this.tip.data = value; this.tip.isDisabled = !value; }
  @Input() get adaptivePosition() { return this.tip.adaptivePosition; }
  /*    */ set adaptivePosition(value) { this.tip.adaptivePosition = value; }
  @Input() get placement() { return this.tip.placement; }
  /*    */ set placement(value) { this.tip.placement = value; }
  private tip: TooltipInstance;
  constructor(elementRef: ElementRef<HTMLElement>, viewContainerRef: ViewContainerRef, tooltipService: TooltipService) {
    this.tip = tooltipService.create(elementRef, viewContainerRef, CommandTooltipContainer);
    this.tip.beforeShow = () => {
      this.tip.closeDelay = isMobile ? 1000 : 300;
    };
    this.tip.delay = 500;
    this.tip.tooltip = '.';
    this.tip.boundariesElement = 'window';
    this.tip.containerClass = 'tooltip-command';
    this.tip.keepOpenWhenHovered = true;
  }
  ngOnDestroy() {
    this.tip.destroy();
  }
}

@Component({
  selector: 'command-tooltip-container',
  templateUrl: './command-tooltip-container.pug',
  styles: [`:host.tooltip { display: block; }`],
  host: {
    '[class]': 'className',
    'role': 'tooltip',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommandTooltipContainer implements TooltipContainer {
  className = '';
  data: ITool | Command | undefined = undefined;
  constructor(private model: Model) {
  }
  get name() {
    return this.data?.name;
  }
  get isBeta() {
    return !!(this.model.settings.richTooltips && this.data?.feature && isBetaFeature(this.data.feature));
  }
  get description() {
    return this.model.settings.richTooltips ? this.data?.description : undefined;
  }
  get video() {
    return this.model.settings.richTooltips ? this.data?.video : undefined;
  }
  get learnMore() {
    return this.model.settings.richTooltips ? this.data?.learnMore : undefined;
  }
  get onboardingTutorial() {
    return (this.model.settings.richTooltips && this.model.onboarding.hasOnboarding) ? this.data?.onboardingTutorial : undefined;
  }
  get shortcut() {
    const id = this.data ? (typeof this.data.id === 'string' ? this.data.id : toolIdToString(this.data.id)) : undefined;
    return id && getFilteredShortcut(this.model, id);
  }
  videoHeight(v: { width: number; height: number; }) {
    return Math.floor(250 * v.height / v.width);
  }
  async launchTutorial(onboardingId: UserFlowId) {
    const onboardingService = this.model.onboarding;
    await onboardingService.ensureAuthenticated();
    onboardingService.startFlow(onboardingId);
  }
}

export const COMMAND_COMPONENTS = [
  CommandHideShortcuts,
  ButtonCommand,
  CommandDirective,
  CommandClick,
  CommandPress,
  CommandIcon,
  CommandShortcut,
  CommandMenuInner,
  CommandMenuItem,
  CommandTitle,
  CommandTip,
  CommandTooltip,
  CommandTooltipContainer,
];
