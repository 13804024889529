import { ITool, IToolEditor, IToolModel, ToolId, Mask } from '../interfaces';
import { getPixelRatio } from '../utils';
import { addEllipseToMask, intersectEllipseWithMask, subtractEllipseFromMask } from '../mask';
import { SelectionTool, SelectionToolAction, SelectionToolActionModes } from './selectionTool';
import { selectEllipseIcon } from '../icons';
import { applyViewportTransform } from '../viewport';
import { isRectEmpty } from '../rect';
import { invalidEnum } from '../baseUtils';

export class CircleSelectionTool extends SelectionTool implements ITool {
  id = ToolId.CircleSelection;
  name = 'Circle Selection';
  description = 'Make a circular selection';
  learnMore = 'https://help.magma.com/en/articles/6790142-circle-selection';
  video = { url: '/assets/videos/circle-selection.mp4', width: 374, height: 210 };
  icon = selectEllipseIcon;
  constructor(editor: IToolEditor, model: IToolModel) {
    super(editor, model);
    this.restrictToBounds = false;
  }
  selectPath(selection: Mask, action: SelectionToolActionModes) {
    if (!isRectEmpty(this.rect)) {
      switch (action) {
        case SelectionToolAction.Select:
        case SelectionToolAction.Add:
          addEllipseToMask(selection, this.rect);
          break;
        case SelectionToolAction.Subtract:
          subtractEllipseFromMask(selection, this.rect);
          break;
        case SelectionToolAction.Intersect:
          intersectEllipseWithMask(selection, this.rect);
          break;
        default: invalidEnum(action);
      }
    }
  }
  drawPath(context: CanvasRenderingContext2D, xoffset = 0, yoffset = 0) {
    context.save();
    context.beginPath();
    applyViewportTransform(context, this.editor.view, getPixelRatio());
    context.ellipse(
      this.rect.x + xoffset + this.rect.w / 2,
      this.rect.y + yoffset + this.rect.h / 2,
      this.rect.w / 2,
      this.rect.h / 2,
      0, 0, Math.PI * 2, false
    );
    context.restore();
  }
}
