import { Rect } from '../common/interfaces';
import { createRect } from '../common/rect';
import { loadImage } from '../common/canvasUtils';
import { decodePNG } from '../common/png';
import { toByteArray } from 'base64-js';
import { SPRITE_PNG_CROP } from '../generated/crop';


export enum SpriteIcon {
  CropLeftTop = 'crop-left-top',
  CropRightTop = 'crop-right-top',
  CropRightBottom = 'crop-right-bottom',
  CropLeftBottom = 'crop-left-bottom',
  CropVertical = 'crop-vertical',
  CropHorizontal = 'crop-horizontal',
}

export const CROP_CORNER_ICON_SIZE = 24;
export const spriteIcons: { [key in SpriteIcon]: Rect } = {
  [SpriteIcon.CropLeftTop]: createRect(0, 0, CROP_CORNER_ICON_SIZE, CROP_CORNER_ICON_SIZE),
  [SpriteIcon.CropRightTop]: createRect(CROP_CORNER_ICON_SIZE + 1, 0, CROP_CORNER_ICON_SIZE, CROP_CORNER_ICON_SIZE),
  [SpriteIcon.CropRightBottom]: createRect((CROP_CORNER_ICON_SIZE +1)*2, 0, CROP_CORNER_ICON_SIZE, CROP_CORNER_ICON_SIZE),
  [SpriteIcon.CropLeftBottom]: createRect((CROP_CORNER_ICON_SIZE+1)*3, 0, CROP_CORNER_ICON_SIZE, CROP_CORNER_ICON_SIZE),

  [SpriteIcon.CropVertical]: createRect(0, CROP_CORNER_ICON_SIZE+1, 12, 40),
  [SpriteIcon.CropHorizontal]: createRect(13, CROP_CORNER_ICON_SIZE+1, 40, 12)
};

export const SPRITE_SCALE = 2;
export async function getSpritesImage() {
  return await loadImage(SPRITE_PNG_CROP);
}

export function getSpritesBitmap() {
  return decodePNG(toByteArray(SPRITE_PNG_CROP.replace(/^data:image\/png;base64,/, '')));
}