import {
  ITool, IToolEditor, IToolModel, TabletEvent, IToolData, CompositeOp, Layer, IRenderingContext, hasShiftKey,
  hasAltKey, Rect, ToolId,
} from '../interfaces';
import { copyRect, isRectEmpty, resetRect, addRect, outsetRect, createRect, cloneRect, normalizeRect, safeRect, moveRect } from '../rect';
import { absoluteDocumentToDocumentRect, createViewport } from '../viewport';
import { BLACK, isProCustomShape, MAX_STROKE_WIDTH, MIN_STROKE_WIDTH } from '../constants';
import { setupSurface } from '../toolSurface';
import { releaseToolRenderingContext } from '../user';
import { redrawLayer } from '../../services/editorUtils';
import { hasPro } from '../userRole';
import { invalidEnum, keys } from '../baseUtils';
import { safeAngle, safeBoolean, safeUintAny, safeFloat, safeOpacity, finishTransform } from '../toolUtils';
import { DEFAULT_SHAPE_ID, shapeShapesMap } from '../shapes';
import { throwIfTextLayer } from '../text/text-utils';
import { clipToDrawingRect } from '../drawing';

export interface ShapeToolSettings {
  name: string;
  shape: string;
}

export interface IShapeToolData extends IToolData {
  color: number;
  opacity: number;
  opacityLocked: boolean;
  shapeType: string;
  strokeWidth: number;
  shape: string;
  angle: number;
  x: number;
  y: number;
  w: number;
  h: number;
}

export const enum DrawShape {
  Rectangle,
  Ellipse,
  Custom,
}

const tempRect = createRect(0, 0, 0, 0);

function safeShapeType(value: string): 'stroke' | 'fill' {
  return value === 'stroke' ? 'stroke' : 'fill';
}

export abstract class BaseShapeTool implements ITool {
  id = ToolId.None;
  name = '';
  usesModifiers = true;
  opacityLocked = false;
  shapeType = 'fill';
  strokeWidth = 5;
  opacity = 1;
  angle = 0;
  shape = DEFAULT_SHAPE_ID;
  fixedRatio = false;
  fields = keys<BaseShapeTool>(['shapeType', 'strokeWidth', 'opacity']);
  drawShape = DrawShape.Rectangle;
  cancellableLocally = true;
  skipMoves = true;
  altTool = true;
  view = createViewport();
  private color = BLACK;
  private startX = 0;
  private startY = 0;
  private rect = createRect(0, 0, 0, 0);
  private lastRect = createRect(0, 0, 0, 0);
  drawingBounds = createRect(0, 0, 0, 0);
  protected layer?: Layer;
  constructor(public editor: IToolEditor, public model: IToolModel) {
  }
  canUseStroke() {
    return true;
  }
  checkShape() {
    if (!this.canUseStroke()) {
      this.shapeType = 'fill';
    }
  }
  private computeDirtyRect(out: Rect) {
    copyRect(out, this.rect);
    normalizeRect(out);
    moveRect(out, this.drawingBounds.x, this.drawingBounds.y);
    if (this.shapeType === 'stroke') outsetRect(out, Math.ceil(this.strokeWidth / 2) + 1);
    clipToDrawingRect(out, this.drawingBounds);
  }
  private fillOrStrokeShape(context: IRenderingContext) {
    const rect = this.rect;
    if (isRectEmpty(rect)) return;

    context.globalAlpha = this.opacity;

    switch (this.drawShape) {
      case DrawShape.Rectangle: {
        if (this.shapeType === 'fill') {
          context.fillRect(this.color, rect.x, rect.y, rect.w, rect.h);
        } else {
          const odd = (this.strokeWidth % 2) === 1;
          const x = rect.x + (odd ? 0.5 : 0);
          const y = rect.y + (odd ? 0.5 : 0);
          context.strokeRect(this.color, this.strokeWidth, x, y, rect.w, rect.h);
        }
        break;
      }
      case DrawShape.Ellipse: {
        const cx = rect.x + rect.w / 2;
        const cy = rect.y + rect.h / 2;
        const rx = Math.abs(rect.w / 2);
        const ry = Math.abs(rect.h / 2);

        if (this.shapeType === 'fill') {
          context.fillEllipse(this.color, cx, cy, rx, ry);
        } else {
          context.strokeEllipse(this.color, this.strokeWidth, cx, cy, rx, ry);
        }
        break;
      }
      case DrawShape.Custom: {
        const path = shapeShapesMap.get(this.shape)?.path;

        // TODO: angle

        if (path) {
          if (this.shapeType === 'fill') {
            context.fillPath(this.color, rect.x, rect.y, rect.w, rect.h, path);
          } else {
            context.strokePath(this.color, this.strokeWidth, rect.x, rect.y, rect.w, rect.h, path);
          }
        }
        break;
      }
      default: invalidEnum(this.drawShape);
    }

    context.globalAlpha = 1;
  }
  setup(data?: IShapeToolData) {
    this.layer = this.model.user.activeLayer;

    if (!data && !this.layer) throw new Error('[BaseShapeTool.setup] Missing layer');

    this.color = data ? safeUintAny(data.color) : this.editor.primaryColor;
    this.opacity = data ? safeOpacity(data.opacity) : this.opacity;
    this.opacityLocked = data ? safeBoolean(data.opacityLocked) : this.layer!.opacityLocked;
    this.shapeType = data ? safeShapeType(data.shapeType) : this.shapeType;
    this.strokeWidth = data ? safeFloat(data.strokeWidth, MIN_STROKE_WIDTH, MAX_STROKE_WIDTH) : this.strokeWidth;
    this.shape = data ? `${data.shape}` : this.shape;
    this.angle = data ? safeAngle(data.angle) : this.angle;

    if (!SERVER && this.model.type !== 'remote' && !hasPro(this.model.user) && isProCustomShape(this.shape)) {
      this.shape = DEFAULT_SHAPE_ID;
    }

    this.checkShape();

    if (data) {
      if (!data.bounds) throw new Error('Missing drawing bounds');
      copyRect(this.drawingBounds, data.bounds);
    }
  }
  do(data: IShapeToolData) {
    if (!this.layer) throw new Error('[BaseShapeTool.do] Missing layer');
    finishTransform(this.editor, this.model.user, 'BaseShapeTool:do');

    const user = this.model.user;
    const context = this.editor.renderer.getToolRenderingContext(user, this.drawingBounds);

    resetRect(this.lastRect);
    copyRect(this.rect, safeRect(data));
    setupSurface(user.surface, this.id, CompositeOp.Draw, this.layer, this.drawingBounds);
    this.computeDirtyRect(this.model.user.surface.rect);
    this.fillOrStrokeShape(context);
    context.flush();

    releaseToolRenderingContext(user);

    user.history.pushDirtyRect(this.name, this.layer.id, user.surface.rect);
    this.editor.renderer.commitTool(user, this.opacityLocked);
    redrawLayer(this.editor, this.layer, this.layer.rect);
  }
  start(x: number, y: number, _pressure: number) {
    if (!this.layer) throw new Error('[BaseShapeTool.do] Missing layer');
    throwIfTextLayer(this.layer);


    finishTransform(this.editor, this.model.user, 'BaseShapeTool:start');
    this.startX = Math.round(x - this.drawingBounds.x);
    this.startY = Math.round(y - this.drawingBounds.y);
    resetRect(this.rect);
    resetRect(this.lastRect);
    setupSurface(this.model.user.surface, this.id, CompositeOp.Draw, this.layer, this.drawingBounds);
  }
  move(_x: number, _y: number, _pressure: number, e?: TabletEvent) {

    const dw = Math.round(_x - this.drawingBounds.x - this.startX);
    const dh = Math.round(_y - this.drawingBounds.y - this.startY);

    let w = dw;
    let h = dh;
    let x = this.startX;
    let y = this.startY;

    if (this.fixedRatio || (!!e && hasShiftKey(e))) { // fixed ratio
      if (this.drawShape === DrawShape.Custom) {
        const path = shapeShapesMap.get(this.shape)?.path;
        if (!path) throw new Error(`Missing shape: ${this.shape}`);

        const aspect = path.width / path.height;

        if (Math.abs(w / h) < aspect) {
          w = (dw < 0 ? -1 : 1) * Math.abs(dw);
          h = (dh < 0 ? -1 : 1) * Math.abs(dw) / aspect;
        } else {
          w = (dw < 0 ? -1 : 1) * Math.abs(dh) * aspect;
          h = (dh < 0 ? -1 : 1) * Math.abs(dh);
        }

        w = Math.round(w);
        h = Math.round(h);
      } else {
        const s = Math.min(Math.abs(w), Math.abs(h));
        w = w < 0 ? -s : s;
        h = h < 0 ? -s : s;
      }
    }

    if (!!e && hasAltKey(e)) { // from center
      // TODO: use ratio of custom shape here ???
      x -= w;
      y -= h;
      w *= 2;
      h *= 2;
    }

    // TODO: single code path
    if (this.drawShape === DrawShape.Custom) {
      this.rect.x = x;
      this.rect.y = y;
      this.rect.w = w;
      this.rect.h = h;
    } else {
      this.rect.x = Math.min(x, x + w);
      this.rect.y = Math.min(y, y + h);
      this.rect.w = Math.abs(w);
      this.rect.h = Math.abs(h);
    }

    // TODO: keep one context from start to end
    const context = this.editor.renderer.getToolRenderingContext(this.model.user, this.drawingBounds);

    this.computeDirtyRect(this.model.user.surface.rect);

    if (!isRectEmpty(this.lastRect)) {
      const clearRect = cloneRect(this.lastRect);
      outsetRect(clearRect, 2);
      clipToDrawingRect(clearRect, this.drawingBounds);
      absoluteDocumentToDocumentRect(clearRect, this.drawingBounds);
      context.clearRect(clearRect.x, clearRect.y, clearRect.w, clearRect.h);
    }

    this.fillOrStrokeShape(context);
    context.flush();

    releaseToolRenderingContext(this.model.user);

    const rect = cloneRect(this.lastRect);
    addRect(rect, this.model.user.surface.rect);
    if (!isRectEmpty(rect)) {
      outsetRect(rect, 2);
      copyRect(this.lastRect, this.model.user.surface.rect);
      redrawLayer(this.editor, this.layer, rect);
    }
  }
  end(x: number, y: number, pressure: number, e: TabletEvent) {
    this.move(x, y, pressure, e);

    if (!this.layer) throw new Error('[BaseShapeTool.end] Missing layer');

    const user = this.model.user;

    this.computeDirtyRect(tempRect);

    if (!isRectEmpty(this.rect) && !isRectEmpty(tempRect)) { // checking both because tempRect is padded
      const beforeRect = cloneRect(this.layer.rect);
      user.history.pushDirtyRect(this.name, this.layer.id, user.surface.rect);
      this.editor.renderer.commitTool(user, this.opacityLocked);
      redrawLayer(this.editor, this.layer, this.layer.rect);

      this.model.doTool<IShapeToolData>(this.layer.id, {
        id: this.id,
        color: this.color,
        opacity: this.opacity,
        opacityLocked: this.opacityLocked,
        shapeType: this.shapeType,
        strokeWidth: this.strokeWidth,
        shape: this.shape,
        angle: this.angle,
        x: this.rect.x,
        y: this.rect.y,
        w: this.rect.w,
        h: this.rect.h,
        br: beforeRect,
        ar: cloneRect(this.layer.rect),
        bounds: cloneRect(this.drawingBounds)
      });
    } else {
      this.editor.renderer.releaseUserCanvas(user);
      user.history.unpre();
    }
  }
}
